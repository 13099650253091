import {axiosClient} from '../../../../../axios';
import {axiosErrorHandler} from '../../../../../reducers/notificationReducer';

export function getLibelleFromValue(dispatch, value, collectionName, callback) {
  axiosClient.get('autocomplete/' + collectionName + '/value/' + value)
    .then(response => {
      callback(response.data)
    })
    .catch(error => axiosErrorHandler(dispatch, error))
}

/**
 * Intérroge l'api pour avoir des suggestions d'autocomplete
 * @param  {[type]}   dispatch       [description]
 * @param  {[type]}   keyword        [description]
 * @param  {[type]}   collectionName [description]
 * @param  {Function} callback       [description]
 * @return {[type]}                  [description]
 */
export function getSuggestions(dispatch, keyword,params, collectionName, callback) {
  axiosClient.get('autocomplete/' + collectionName, {params: {keyword,...params}})
    .then(response => {
      callback(response.data)
    })
    .catch(error => axiosErrorHandler(dispatch, error))
}
