export default theme => ({
  container: {
    position: 'relative',
  },
  suggestionsContainerOpen: {
    width: '100%',
    position: 'absolute',
    zIndex: 1,
    marginTop: '-3px',
    minWidth: '7em',
  },
  suggestion: {
    display: 'block',
  },
  suggestionsList: {
    margin: 0,
    padding: 0,
    listStyleType: 'none',
  },
  autocompleteBold: {
    fontWeight: 'bold',
    backgroundColor: 'transparent!important'
  },
  loader: {
    marginTop: 0,
  },
  autoCompleteSuggestions: {
    maxHeight: '200px',
    overflowY: 'scroll',
  },
  clearable:{
    borderRadius: '50%',
    height: '1rem',
    width: '1rem',
    backgroundColor: '#afafaf',
    position: 'absolute',
    top: '2.2rem',
    right: 0,
    '&:before': {
      content: '"x"',
      display: 'block',
      textAlign: 'center',
      lineHeight: '1rem',
      color: '#fff',
      fontWeight: 'bold',
    }
  },
  inputAutocomplete: {
      position: "relative",
  },
});
