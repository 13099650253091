import React, {Component} from 'react'

import {Grid, Typography} from '@material-ui/core'
import {withStyles} from '@material-ui/core/styles'
import {Check, Settings} from '@material-ui/icons'

import CollectionCrud from '../../common/Components/CollectionCrud'
import TextInput from '../../common/Components/TextInput'
import TransportCss from './css/TransportCss'
import CheckboxInput from '../../common/Components/CheckboxInput'


class Transport extends Component {

  getDeleteModalTitle() {
    return (
      <div>
        <Settings/>
        <span>{"Suppression du transport"}</span>
      </div>
    )
  }

  getDeleteModalContent() {
    return (
      <Typography>
        {'Êtes-vous sûr de vouloir supprimer ce transport ?'}
      </Typography>
    )
  }

  getCreateUpdateModalTitle(transport) {
    let title = !!transport
      ? "Modification du transport : " + transport.nom
      : "Ajout d'un nouveau transport"
    return (
      <div>
        <Settings/>
        <span>{title}</span>
      </div>
    )
  }

  getCreateUpdateModalContent(
    transport, transportsStore, onChangeHandler
  ) {
    let {nom, has_procedure_chargement_contrainer,is_type_case} = transport
    return (
      <Grid container>
        <Grid item xs={12}>
          <TextInput
            id="nom"
            label="Nom"
            value={nom}
            margin="normal"
            collectionStore={transportsStore}
            name='nom'
            onChangeHandler={onChangeHandler}
            fullWidth
          />
        </Grid>
        <Grid item xs={12}>
          <CheckboxInput
            id="has_procedure_chargement_contrainer"
            label="Procédure de chargement de contrainer"
            value={has_procedure_chargement_contrainer}
            margin="normal"
            name="has_procedure_chargement_contrainer"
            onChangeHandler={onChangeHandler}
          />
           <CheckboxInput
            id="is_type_case"
            label="Stockable dans les cases"
            value={is_type_case}
            margin="normal"
            name="is_type_case"
            onChangeHandler={onChangeHandler}
          />
        </Grid>

      </Grid>
    )
  }

  render() {
    const {classes} = this.props
    return (
      <Grid container className={classes.container}>
        <CollectionCrud
          collectionName={'transports'}
          showBtnEdit={true}
          showBtnAdd={true}
          showBtnDelete={true}
          rights={{
            create: 'admin-cud-parametres',
            edit: 'admin-cud-parametres',
            delete: 'admin-cud-parametres'
          }}
          deleteModalTitle={this.getDeleteModalTitle}
          deleteModalContent={this.getDeleteModalContent}
          createUpdateModalTitle={this.getCreateUpdateModalTitle}
          createUpdateModalContent={
            this.getCreateUpdateModalContent.bind(this)
          }
          datatableConfig={{
            showPagination: true,
            showSearch: true,
            defaultSort: "nom",
            sortType: 'desc',
            rowsPerPageOptions: [10, 25, 50],
          }}
          cellsConfig={[
            {
              datakey: "nom",
              title: "Nom",
              sortable: true,
              searchable: true,
            },
            {
              datakey: "has_procedure_chargement_contrainer",
              title: "Procédure Chargement Container",
              sortable: true,
              searchable: false,
              format: transport => transport.has_procedure_chargement_contrainer ? <Check/> : null
            },
            {
              datakey: "is_type_case",
              title: "Stockable dans les cases",
              sortable: true,
              searchable: false,
              format: transport => transport.is_type_case ? <Check/> : null
            }
          ]}
        />
      </Grid>
    )
  }
}

Transport = withStyles(TransportCss)(Transport);

export default Transport
