import React, { Component } from "react";
import { connect } from "react-redux";
import classNames from "classnames";

import { withStyles } from "@material-ui/core/styles/index";
import { Divider, Drawer, List, Typography } from "@material-ui/core";
import BusinessIcon from "@material-ui/icons/Business";
import ChevronLeftIcon from "@material-ui/icons/ChevronLeft";
import DashboardIcon from "@material-ui/icons/Dashboard";
import {
  EuroSymbol,
  Grain,
  InsertChart,
  BusinessCenter,
  PhotoCamera,
  GroupWork,
  Map,
  AddShoppingCart,
  Warning,
} from "@material-ui/icons";
import IconButton from "@material-ui/core/IconButton";
import PersonIcon from "@material-ui/icons/Person";
import DeveloperBoard from "@material-ui/icons/DeveloperBoard";
import ImportExport from "@material-ui/icons/ImportExport";
import Calendar from "@material-ui/icons/CalendarToday";
import GridOn from "@material-ui/icons/GridOn";
import Assignment from "@material-ui/icons/Assignment";
import Gavel from "@material-ui/icons/Gavel";

import LeftMenuCss from "./css/LeftMenuCss";
import LeftMenuButton from "./LeftMenuButton";
import { hasRights } from "../../common/Tools/Tools";
import { axiosClient } from "../../../axios";

class LeftMenu extends Component {
  state = {
    open: false,
    storage_size: "",
  };

  componentDidMount() {
    axiosClient.get("/storage-size").then((response) => {
      this.setState({
        storage_size: response.data.size ? response.data.size : "0K",
      });
    });
  }

  static getDerivedStateFromProps(nextProps, prevState) {
    if (nextProps.open !== prevState.open) {
      return {
        ...prevState,
        open: nextProps.open,
      };
    }

    return null;
  }

  render() {
    const { classes, noContainer, user } = this.props,
      buttons = {
        dashboard: {
          label: "Tableau de bord",
          link: "/",
          matchingPath: ["/"],
          icon: <DashboardIcon />,
        },
        affaires: {
          label: "Affaires",
          link: "/affaires",
          matchingPath: ["/affaires/", "/affaires/:uuid"],
          icon: <EuroSymbol />,
        },
        contacts: {
          label: "Contacts",
          link: "/contacts",
          matchingPath: ["/contacts/"],
          icon: <PersonIcon />,
        },
        entreprises: {
          label: "Entreprises",
          link: "/entreprises",
          matchingPath: ["/entreprises/", "/entreprises/:uuid"],
          icon: <BusinessIcon />,
        },
        articles: {
          label: "Articles",
          link: "/articles",
          matchingPath: ["/articles/"],
          icon: <Grain />,
        },
        exports: {
          label: "Exports",
          link: "/exports",
          matchingPath: ["/exports/"],
          icon: <ImportExport />,
        },
        facturation: {
          label: "Facturation",
          link: "/facturation",
          matchingPath: [
            "/facturation",
            "/facturation/entrante/:entreprise_uuid",
            "/facturation/sortante/:entreprise_uuid",
            "/facturation/bordereau_achat/:entreprise_uuid",
          ],
          icon: <DeveloperBoard />,
          localStorageFilterKey: ["facturation_annexe_filtres"],
        },
        planification: {
          label: "Planification",
          link: "/planification",
          matchingPath: ["/planification"],
          icon: <Calendar />,
        },
        stock: {
          label: "Stock",
          link: "/stock/produits_transformes",
          matchingPath: [
            "/stock/produits_transformes",
            "/stock/matieres_premieres",
            "/stock/validation_bigbags",
          ],
          icon: <GridOn />,
        },
        pslists: {
          label: "P&S lists",
          link: "/pslists/packinglists",
          matchingPath: ["/pslists/packinglists", "/pslists/silolists"],
          icon: <Assignment />,
          localStorageFilterKey: ["SILOLISTS_FILTERS",'PACKING_LIST_FILTERS'],
        },
        supervision: {
          label: "Supervision",
          link: "/supervision",
          matchingPath: ["/supervision"],
          icon: <InsertChart />,
        },
        commercial: {
          label: "Module Commercial",
          link: "/commercial",
          matchingPath: ["/commercial"],
          icon: <BusinessCenter />,
          localStorageFilterKey:['MODULE_COMMERCIAl']
        },
        campagneTaf: {
          label: "Campagne TAF",
          link: "/campagne-tafs",
          matchingPath: ["/campagne-tafs"],
          icon: <Gavel />,
        },
        photo: {
          label: "Photo",
          link: "/photo",
          matchingPath: ["/photo"],
          icon: <PhotoCamera />,
        },
        articles_regroupement: {
          label: "Articles regroupement",
          link: "/articles-regroupement",
          matchingPath: ["/articles-regroupement"],
          icon: <GroupWork />,
        },
        zones_bb: {
          label: "Zones BB",
          link: "/zones-bb",
          matchingPath: ["/zones-bb"],
          icon: <Map />,
        },
        mise_a_dispositions: {
          label: "MaD",
          link: "/mise-a-dispositions",
          matchingPath: ["/mise-a-dispositions"],
          icon: <AddShoppingCart />,
          localStorageFilterKey: ["mise-a-disposition-filters"],
        },
        non_conformites: {
          label: "Non conformité",
          link: "/non-conformites",
          matchingPath: ["/non-conformites","/non-conformites/externes",'/non-conformites/internes'],
          icon: <Warning />,
          localStorageFilterKey: ["non-conformite-filters"],
        },
      },
      StyledDivider = withStyles({
        root: {
          backgroundColor: "#b8c7ce2e",
        },
      })(Divider);

    if (!user || noContainer) return null;

    return (
      <Drawer
        variant="permanent"
        classes={{
          paper: classNames(
            classes.drawerPaper,
            !this.state.open && classes.drawerPaperClose
          ),
        }}
        open={this.state.open}
      >
        <div className={classes.toolbar}>
          <IconButton
            onClick={this.props.handleLeftMenuToggle}
            className={classes.iconButton}
            title="Replier le menu"
          >
            <ChevronLeftIcon />
          </IconButton>
        </div>
        <StyledDivider />
        <List className={classes.liste}>
          <div>
            <LeftMenuButton button={buttons.dashboard} />
            <StyledDivider />
            <LeftMenuButton button={buttons.entreprises} />
            <LeftMenuButton button={buttons.contacts} />
            <LeftMenuButton button={buttons.articles} />
            <LeftMenuButton button={buttons.articles_regroupement} />
            <LeftMenuButton button={buttons.stock} />
            <LeftMenuButton button={buttons.pslists} />
            <LeftMenuButton button={buttons.campagneTaf} />
            <LeftMenuButton button={buttons.affaires} />
            <LeftMenuButton button={buttons.photo} />
            <LeftMenuButton button={buttons.zones_bb} />
            <LeftMenuButton button={buttons.mise_a_dispositions} />
            <LeftMenuButton button={buttons.non_conformites} />
            <StyledDivider />
            {hasRights(["admin-facturation-entrante"], this.props.user) ||
            hasRights(["admin-facturation-sortante"], this.props.user) ||
            hasRights(["admin-facturation-bordereau"], this.props.user) ? (
              <LeftMenuButton button={buttons.facturation} />
            ) : null}
            <LeftMenuButton button={buttons.commercial} />
            <StyledDivider />
            <LeftMenuButton button={buttons.exports} />
            <StyledDivider />
            <LeftMenuButton button={buttons.planification} />
            <LeftMenuButton button={buttons.supervision} />
          </div>
        </List>
        <div className={classes.copyrightContainer}>
          {user.is_admin ? (
            <div className={classes.storage_size}>
              {this.state.storage_size}/50Go
            </div>
          ) : null}
          <Typography
            title="© 2019 Oniti"
            variant="h6"
            color="inherit"
            className={classes.copyrightSimple}
            style={{
              opacity: this.state.open ? "0" : "1",
            }}
          >
            <a href="https://www.oniti.fr" className={classes.copyrightSimpleLink}>
              Oniti
            </a>
          </Typography>
        </div>
      </Drawer>
    );
  }
}

LeftMenu = withStyles(LeftMenuCss, { withTheme: true })(LeftMenu);

LeftMenu = connect((store) => {
  return {
    user: store.auth.user,
  };
})(LeftMenu);

export default LeftMenu;
