import React, { Component } from "react";
import { connect } from "react-redux";
import { withStyles } from "@material-ui/core/styles";
import { Grid, MenuItem, Typography } from "@material-ui/core";
import { Check, Person } from "@material-ui/icons";
import {
  collectionActions,
  loadCollectionAttribute,
} from "../../../reducers/collectionsReducer";
import TextInput from "../../common/Components/TextInput";
import UserCss from "./css/UserCss";
import CollectionCrud from "../../common/Components/CollectionCrud";
import CheckboxInput from "../../common/Components/CheckboxInput";


class User extends Component {
  /**
   * Lors du montage du composant
   */
  componentDidMount() {
    loadCollectionAttribute(
      this.props.dispatch,
      "list",
      "roles",
      this.props.rolesStore
    );
    collectionActions(this.props.dispatch, "users", "INDEX");
  }

  /**
   * Title de la modal de suppression
   * @return {[type]} [description]
   */
  getDeleteModalTitle() {
    return (
      <div>
        <Person /> <span>{"Suppression de l'utilisateur"}</span>
      </div>
    );
  }

  getDeleteModalContent() {
    return (
      <Typography>
        {"Êtes-vous sûr de vouloir supprimer cet utilisateur ?"}
      </Typography>
    );
  }

  getCreateUpdateModalTitle(user) {
    return (
      <div>
        <Person />
        <span>
          {!!user
            ? "Modification de l'utilisateur : " +
              user.firstname +
              " " +
              user.lastname
            : "Ajout d'un nouvel utilisateur"}
        </span>
      </div>
    );
  }

  /**
   * Construction de la liste des rôles
   * @returns {*}
   */
  getRoles() {
    if (!this.props.rolesStore.list) return [];

    return this.props.rolesStore.list.map((role) => {
      return (
        <MenuItem value={role.uuid} key={role.uuid}>
          {role.libelle}
        </MenuItem>
      );
    });
  }

  /**
   * Retourne le formulaire
   * @returns {*}
   */
  getCreateUpdateModalContent(user, usersStore, onChangeHandler) {
    let {
      firstname,
      lastname,
      email,
      role_uuid,
      mobile,
      locked,
      auth_2fa_by_email,
    } = user;
    const { classes } = this.props;

    return (
      <Grid container>
        <Grid item xs={6}>
          <TextInput
            id="firstname"
            label="Prénom"
            value={firstname}
            margin="normal"
            collectionStore={usersStore}
            name="firstname"
            onChangeHandler={onChangeHandler}
            fullWidth
          />
        </Grid>
        <Grid item xs={6}>
          <TextInput
            id="lastname"
            label="Nom"
            value={lastname}
            margin="normal"
            collectionStore={usersStore}
            name="lastname"
            onChangeHandler={onChangeHandler}
            fullWidth
          />
        </Grid>
        <Grid item xs={6}>
          <TextInput
            id="email"
            label="Email"
            value={email}
            margin="normal"
            collectionStore={usersStore}
            name="email"
            onChangeHandler={onChangeHandler}
            fullWidth
          />
        </Grid>
        <Grid item xs={6}>
          <TextInput
            id="mobile"
            label="Téléphone mobile"
            value={mobile}
            margin="normal"
            collectionStore={usersStore}
            name="mobile"
            onChangeHandler={onChangeHandler}
            fullWidth
          />
        </Grid>
        <Grid item xs={6}>
          <TextInput
            id="role_uuid"
            label="Rôle"
            value={role_uuid}
            margin="normal"
            collectionStore={usersStore}
            name="role_uuid"
            onChangeHandler={onChangeHandler}
            className={classes.selectContainer}
            select
          >
            {this.getRoles()}
          </TextInput>
        </Grid>
        <Grid item xs={6}>
          <div className={classes.checkboxContainer}>
            <CheckboxInput
              id="locked"
              label="Compte bloqué"
              value={locked}
              margin="normal"
              name="locked"
              onChangeHandler={onChangeHandler}
              fullWidth
            />
          </div>
        </Grid>
        <Grid item xs={6}>
          <div className={classes.checkboxContainer}>
            <CheckboxInput
              id="auth_2fa_by_email"
              label="2fa par email"
              value={auth_2fa_by_email}
              margin="normal"
              name="auth_2fa_by_email"
              onChangeHandler={onChangeHandler}
              fullWidth
            />
          </div>
        </Grid>
      </Grid>
    );
  }

  /**
   * retourne la configuration des cellules
   * @return {[type]} [description]
   */
  getCellsConfig() {
    return [
      {
        datakey: "firstname",
        title: "Prénom",
        sortable: true,
        searchable: true,
      },
      {
        datakey: "lastname",
        title: "Nom",
        sortable: true,
        searchable: true,
      },
      {
        datakey: "email",
        title: "Email",
        sortable: true,
        searchable: true,
      },
      {
        datakey: "auth_2fa_by_email",
        title: "2Fa",
        sortable: true,
        searchable: true,
        format: (obj, key) => {
          if (obj[key]) {
            return (
                <Check />
            );
          } else {
            return null;
          }
        },
      },
      {
        datakey: "role.libelle",
        title: "Rôle",
        sortable: true,
        searchable: true,
      },
    ];
  }

  /**
   * Fonction de rendu Final
   * @return {[type]} [description]
   */
  render() {
    const { classes } = this.props;
    return (
      <Grid container className={classes.container}>
        <CollectionCrud
          collectionName={"users"}
          showBtnEdit={true}
          showBtnAdd={true}
          showBtnDelete={true}
          loadDatas={false}
          rights={{
            create: "admin-cud-parametres",
            edit: "admin-cud-parametres",
            delete: "admin-cud-parametres",
          }}
          deleteModalTitle={this.getDeleteModalTitle}
          deleteModalContent={this.getDeleteModalContent}
          createUpdateModalTitle={this.getCreateUpdateModalTitle}
          createUpdateModalContent={this.getCreateUpdateModalContent.bind(this)}
          datatableConfig={{
            showPagination: true,
            showSearch: true,
            defaultSort: "lastname",
            sortType: "desc",
            rowsPerPageOptions: [10, 25, 50],
          }}
          cellsConfig={this.getCellsConfig()}
        />
      </Grid>
    );
  }
}

User = withStyles(UserCss)(User);

User = connect((store) => {
  return {
    rolesStore: store.collections.roles,
    usersStore: store.collections.users,
  };
})(User);

export default User;
