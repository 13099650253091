import React, { Component } from "react";

import { AppBar, Button, CircularProgress, DialogContent, DialogTitle, Grid, MenuItem, Tab, Tabs, Typography } from "@material-ui/core";
import { withStyles } from "@material-ui/core/styles";
import { Map } from "@material-ui/icons";

import TextInput from "../../common/Components/TextInput";
import ZoneBBCss from "./css/ZoneBBCss";
import { connect } from "react-redux";
import { collectionActions } from "../../../reducers/collectionsReducer";
import Modal from "../../common/Components/Modal";
import ZonePlan from "./ZonePlan";
import { axiosClient } from "../../../axios";
import SwipeableViews from "react-swipeable-views";
import CheckboxInput from "../../common/Components/CheckboxInput";
import ProduitTransforme from "../Stock/ProduitTransforme";

const TAB_PLAN = 'plan';
const TAB_LISTING = 'listing';
class ZoneBB extends Component {

  state = {
    big_bag_deplacement: null,
    etablissements: [],
    selectedEtablissements: [],
    selectedPackinglist: null,
    selectedSilotlist: null,
    openModal: false,
    loading: false,
    zones: [],
    clickedZone: null,
    selectedTab: 0,
    selectedTabMain: TAB_PLAN,
    selectedZones: [],
    selectedBigBag: {},
    timeOutRefresh: null,
  }

  componentDidMount() {
    const { match } = this.props;
    const currentTab = match.params.tab ?? TAB_PLAN;
    this.setState({selectedTabMain: currentTab});
    this.fetchEtablissementsAndZone();
  }

  fetchEtablissementsAndZone() {
    axiosClient.get("/etablissements?zone_bb=true")
    .then((response) => {
      let selectedEtablissements = this.state.selectedEtablissements;
      selectedEtablissements = selectedEtablissements.filter((uuid) => response.data.find((e) => e.uuid === uuid));

      if(selectedEtablissements.length === 0 && response.data.length > 0){
        selectedEtablissements.push(response.data[0].uuid);
      }

      this.setState({
        etablissements: response.data,
        selectedEtablissements: selectedEtablissements,
      },() => {
        this.fetchZoneBBs();
      });
    });
  }

  fetchZoneBBs(){
    this.setState({loading: true});
    collectionActions(this.props.dispatch, "zone-bbs", "INDEX",{
      params: {
        etablissements_uuid: this.state.selectedEtablissements,
        packinglist_uuid: this.state.selectedPackinglist,
        silolist_uuid: this.state.selectedSilotlist,
      },
    },() => {
      this.setState({loading: false});
    });
  }

  fetchDetailZoneBB(zoneBB, callback, loading = true){
    this.setState({loading: loading});
    collectionActions(this.props.dispatch, "zone-bbs", "INDEX_WITHOUT_DISPATCH",{params:{
      uuid: zoneBB?.uuid,
      with_cells: true,
      etablissements_uuid: this.state.selectedEtablissements,
      packinglist_uuid: this.state.selectedPackinglist,
      silolist_uuid: this.state.selectedSilotlist,
      zones_uuid: this.state.selectedZones.length > 0 ? this.state.selectedZones : null,
      bigbags_uuid: Object.keys(this.state.selectedBigBag).length > 0 ? Object.keys(this.state.selectedBigBag) : null,
    }}).then((response) => {
      this.setState({loading: false},() => {
        callback(response.data);
        // init setTimeout refresh
        this.setSetTimeoutRefresh(zoneBB,callback);
      });
    });
  }


  getDeleteModalTitle() {
    return (
      <div>
        <Map />
        <span>{"Suppression de la zone"}</span>
      </div>
    );
  }

  getDeleteModalContent() {
    return (
      <Typography>
        {"Êtes-vous sûr de vouloir supprimer cette zone ?"}
      </Typography>
    );
  }

  getCreateUpdateModalTitle() {
    return (
      <div>
        <Map />
        <span>Plan Big Bags</span>
      </div>
    );
  }

  getCreateUpdateModalContent() {
    const { zones } = this.state;
    return (
      <Grid container>
        <AppBar position="static" color="default">
          <Tabs
              value={this.state.selectedTab}
              onChange={(e,index)  => {
                this.setState({selectedTab: index});
              }}
              indicatorColor="primary"
              textColor="primary"
              variant="scrollable"
              scrollButtons="auto"
            >
              {zones.map(zone => {
                return (
                  <Tab label={zone.name + this.getNbBBHighlightedTitle(zone)} key={zone.uuid}/>
                )
              })}
          </Tabs>
        </AppBar>
        <SwipeableViews axis={'x'} index={this.state.selectedTab} >
          {zones.map(zone => {
            return (
              <Typography component="div" key={zone.uuid} dir={'ltr'} style={{padding: 8 * 3}}>
                <Grid item xs={12}>
                  {this.getPlan(zone)}
                </Grid>
              </Typography>
            )
          })}
        </SwipeableViews>
      </Grid>
    );
  }
  getNbBBHighlightedTitle(zoneBB){
    if(!zoneBB || !zoneBB.nb_bb_hightlight) return '';

    return ' (' + zoneBB.nb_bb_hightlight + ')';
  }

  initDeplacementBigBag(big_bag)
  {
    this.setState({big_bag_deplacement: big_bag});
  }

  setSetTimeoutRefresh(zoneBB,callback){
    const { timeOutRefresh } = this.state;
    if(timeOutRefresh) clearTimeout(timeOutRefresh);
    const refreshDelay = 1000 * 60 * 1; // 5 minutes
    this.setState({timeOutRefresh: setTimeout(() => {
      this.fetchDetailZoneBB(zoneBB,callback,false);
    },refreshDelay)});
  }

  getPlan(zoneBB){
    if(!zoneBB || !zoneBB.uuid) return null;
    const {
      selectedEtablissements,
      selectedPackinglist,
      selectedSilotlist,
      selectedZones,
      selectedBigBag,
      clickedZone
    } = this.state;
    return (
      <Grid item xs={12}>
        <ZonePlan
          key={zoneBB.uuid}
          cellules={zoneBB.front_cellules}
          big_bag_deplacement={this.state.big_bag_deplacement}
          initDeplacementBigBag={this.initDeplacementBigBag.bind(this)}
          getBigBagDeplacementTitle={this.getBigBagDeplacementTitle.bind(this)}
          zoneBB_uuid={zoneBB.uuid}
          callbackUpdate={this.callbackUpdate.bind(this)}
          wallPosition={zoneBB.wall_position}
          legends={zoneBB.legends}
          showButonDownload={true}
          getDownloadParameters={() => {
            return {
              with_cells: true,
              etablissements_uuid: selectedEtablissements,
              packinglist_uuid: selectedPackinglist,
              silolist_uuid: selectedSilotlist,
              zones_uuid: selectedZones.length > 0 ? selectedZones : null,
              uuid: clickedZone?.uuid ?? null,
              bigbags_uuid: Object.keys(selectedBigBag).length > 0 ? Object.keys(selectedBigBag) : null,
            }
          }}
        />
      </Grid>
    )
  }

  callbackUpdate(){
    const { clickedZone } = this.state;
    this.fetchZones(clickedZone, false);
  }

  getBigBagDeplacementTitle(){
    const { big_bag_deplacement } = this.state;
    if(!big_bag_deplacement) return null;

    const { classes } = this.props;
    return (
        <div className={classes.deplacementContainer}>
          <div className={classes.deplacementContainerTop}>
              <span className={classes.itemDeplacementTop}>
                Déplacement du big bag <strong>{big_bag_deplacement.reference}</strong> Zone de départ : <strong>{ big_bag_deplacement.cellule_bb }</strong>
              </span>
              <span>
              <Button
                className={classes.btnCancelDeplacement}
                onClick={() => {
                  this.initDeplacementBigBag(null);
                }}
                title="Annuler le déplacement"
                flat
              >
                  X
              </Button>
              </span>
          </div>
          <div>
            <p className={classes.cancelDeplacementParagraphe}></p>
            <p className={[classes.cancelDeplacementParagraphe,classes.cancelDeplacementParagrapheBottom].join(' ')}>Cliquez sur la cellule de destination</p>
          </div>
        </div>
    )
  }


  getEtablissementsFilterOptions(){
    const { etablissements } = this.state;
    if(!etablissements) return [];

    return etablissements.map((etablissement) => {
      return (
        <MenuItem value={etablissement.uuid} key={etablissement.uuid}>
          {etablissement.libelle}
        </MenuItem>
      );
    });
  }

  onChangeFilterHandler(name, e){
    const values = e.target.value;

    this.setState({[name]: values},() => {
      this.fetchZoneBBs();
    });
  }

  displayFilters(){
    const { classes, etablissementsStore } = this.props;
    const { selectedEtablissements, loading, selectedPackinglist, selectedSilotlist } = this.state;

    if(loading) return null;
    return (
      <Grid container>
        <Grid item xs={5}>
          <TextInput
            label="Etablissement"
            className={classes.selectContainer}
            margin="normal"
            name="selectedEtablissements"
            collectionStore={etablissementsStore}
            onChangeHandler={this.onChangeFilterHandler.bind(this)}
            fullWidth
            select
            SelectProps={{
              multiple: true,
              value: selectedEtablissements,
            }}
          >
            {this.getEtablissementsFilterOptions()}
          </TextInput>
        </Grid>
        <Grid item xs={3}>
          <TextInput
              id="selectedPackinglist"
              label="Packing list"
              className={classes.selectContainer}
              collectionStore={etablissementsStore}
              value={selectedPackinglist}
              margin="normal"
              name="selectedPackinglist"
              onChangeHandler={this.onChangeFilterHandler.bind(this)}
              type="autocomplete"
              autocompleteProps={{
                collectionName: "packinglists",
              }}
              fullWidth
              clearable={true}
          />
        </Grid>
        <Grid item xs={3}>
          <TextInput
              id="selectedSilotlist"
              label="Silot list"
              className={classes.selectContainer}
              collectionStore={etablissementsStore}
              value={selectedSilotlist}
              margin="normal"
              name="selectedSilotlist"
              onChangeHandler={this.onChangeFilterHandler.bind(this)}
              type="autocomplete"
              autocompleteProps={{
                collectionName: "silolists",
              }}
              fullWidth
              clearable={true}
          />
        </Grid>
      </Grid>
    )
  }

  fetchZones(zoneBB, loading = true){
    this.fetchDetailZoneBB(zoneBB,(zones) => {
      this.setState({openModal: true, zones: zones, clickedZone:zoneBB, fetching: false});
    },loading);
  }

  displayCard(zoneBB){
    const { classes } = this.props;
    const { selectedZones } = this.state;
    let selectDiv = null;
    if(zoneBB.uuid !== "all"){
      selectDiv = (
        <div className={classes.selectDiv+' override_svg_color'}>
          <CheckboxInput
            label=""
            value={selectedZones.includes(zoneBB.uuid)}
            margin="normal"
            formControlStyle={{ padding: 0 }}
            onClick={(e) => {
              e.stopPropagation();
            }}
            onChangeHandler={(name, e) => {
              let selectedZones = this.state.selectedZones;
              if(e.target.value){
                selectedZones.push(zoneBB.uuid);
              }else{
                selectedZones = selectedZones.filter((uuid) => uuid !== zoneBB.uuid);
              }
              this.setState({selectedZones: selectedZones});
            }}
          />
        </div>
      )
    }
    return (
      <div
        className={classes.card}
        key={zoneBB.uuid}
        style={{backgroundColor: zoneBB.color}}
        onClick={(e) => {
          this.fetchZones(zoneBB);
        }}
      >
        {selectDiv}
        <span style={{textAlign:'center'}}>{zoneBB.name}</span>
        {this.getNbBB(zoneBB)}
      </div>
    )
  }

  getNbBB(zoneBB){
    if(zoneBB.uuid === "all") return null;
    const { classes } = this.props;
    const is_highlighted = zoneBB.nb_bb_hightlight && zoneBB.nb_bb_hightlight > 0;
    if(zoneBB.nb_bb === null && zoneBB.nb_bb_hightlight === null) return null;

    return (
      <span className={classes.nbBB + (is_highlighted ? ' '+classes.nbBBHighlighted : '')}>
        {zoneBB.nb_bb_hightlight || zoneBB.nb_bb || 0}
      </span>
    )
  }

  displayZonesCards(){
    const { classes, zoneBBStore } = this.props;
    const { loading } = this.state;
    if(loading) return null;

    if(!zoneBBStore.list) return null;
    if(zoneBBStore.list.length === 0) {
      return (
        <div className={classes.zonesContainer}>
          <h4 className={classes.center}>
            Aucune zone Big Bag n'a été trouvée
          </h4>
        </div>
      )
    }
    const zonesFactice = []
    if(zoneBBStore.list.length > 1){
      zonesFactice.push({uuid: "all", name: "Toutes", color: "rgb(52 151 181)"})
    }

    return(
      <div className={classes.zonesContainer}>
        { zonesFactice.concat(zoneBBStore.list).map(this.displayCard.bind(this))}
      </div>
    )
  }
  onCloseModalHandler(){
    const { timeOutRefresh } = this.state;
    if(timeOutRefresh) clearTimeout(timeOutRefresh);
    this.setState({openModal: false, zones: [], clickedZone: null, selectedTab: 0, timeOutRefresh: null},() => {
      this.fetchZoneBBs();
    });

  }

  getModal(){
    const { openModal, loading } = this.state;
    if(!openModal || loading) return null;
    return (
      <Modal
        openModal={openModal}
        onCloseHandler={this.onCloseModalHandler.bind(this)}
        onSubmitHandler={this.onCloseModalHandler.bind(this)}
        fullScreen={true}
        maxWidth="lg"
      >
        <DialogTitle key="title" id="alert-dialog-slide-title">
          {this.getCreateUpdateModalTitle()}
        </DialogTitle>
        <DialogContent>
          {this.getCreateUpdateModalContent() }
        </DialogContent>
      </Modal>
    )
  }

  displayLoading(){
    const { loading } = this.state;
    if(!loading) return null;

    const { classes } = this.props;

    return (
      <div className={classes.loadingContainer}>
        <Grid item xs={12}>
          <h4 className={classes.center}>
            Récupération des données auprès du serveur
          </h4>
        </Grid>
        <Grid item xs={12} className={classes.center}>
          <CircularProgress />
        </Grid>
      </div>
    )
  }

  displayAfficherZones(){
    const { selectedZones } = this.state;
    if(selectedZones.length === 0) return null;

    return(
      <Grid item container>
        <Grid item xs={3}>
          <Button
            variant="contained"
            color="primary"
            onClick={() => {
              this.fetchDetailZoneBB(null,(zones) => {
                this.setState({openModal: true, zones: zones, clickedZone:null, fetching: false});
              },false);
            }}
          >
            Afficher les zones Sélectionnées
          </Button>
        </Grid>
        <Grid item xs={3}>
          <Button
            variant="contained"
            onClick={() => {
              this.setState({selectedZones: []});
            }}
          >
            Clear Selection
          </Button>
        </Grid>
      </Grid>
    )
  }

  displayPlan()
  {
    return (
      <>
        { this.displayLoading()}
        { this.displayFilters() }
        { this.displayAfficherZones()}
        { this.displayZonesCards() }
      </>
    )
  }

  getSelectCheckbox(data){
    const { selectedBigBag } = this.state;
    return (
      <CheckboxInput
        label=""
        value={selectedBigBag[data.uuid]}
        margin="normal"
        formControlStyle={{ padding: 0 }}
        onClick={(e) => {
          e.stopPropagation();
        }}
        onChangeHandler={(name, e) => {
          let selectedBigBag = this.state.selectedBigBag;
          if(e.target.value){
            selectedBigBag[data.uuid] = {
              reference: data.reference,
              uuid: data.uuid,
            };
          }else{
            delete selectedBigBag[data.uuid];
          }
          this.setState({selectedBigBag: selectedBigBag});
        }}
      />
    )
  }

  displaySelectBigBag()
  {
    const { classes } = this.props;
    const { selectedBigBag } = this.state;

    if(Object.keys(selectedBigBag).length === 0) return null;

    return (
      <Grid container>
        <Grid item xs={3}>
          <Button
            variant="contained"
            color="primary"
            onClick={() => {
              this.fetchDetailZoneBB({uuid:'all'},(zones) => {
                this.setState({openModal: true, zones: zones, clickedZone:null, fetching: false});
              },false);
            }}
          >
            Voir les BB sélectionnés sur le plan
          </Button>
        </Grid>
        <Grid item xs={3}>
          <Button
            variant="contained"
            onClick={() => {
              this.setState({selectedBigBag: {}});
            }}
          >
            Clear Selection
          </Button>
        </Grid>
        <Grid item xs={12}>
          <div className={classes.selectedBigBagContainer}>
            {Object.values(selectedBigBag).map((bigBag) => {
              return (
                <div className={classes.selectedBigBag} key={bigBag.uuid}>
                  <span>{bigBag.reference}</span>
                  <span
                    className={classes.selectedBigBagDelete}
                    onClick={() => {
                      let selectedBigBag = this.state.selectedBigBag;
                      delete selectedBigBag[bigBag.uuid];
                      this.setState({selectedBigBag: selectedBigBag});
                    }}
                  >
                    X
                  </span>
                </div>
              )
            })}
          </div>
        </Grid>
      </Grid>
    )
  }

  displayListing()
  {
    return(
      <>
        { this.displaySelectBigBag()}
        <ProduitTransforme
            columns={[
              "select",
              "reference",
              "pslist_reference",
              "nom_famille",
              "article_nom",
              "created_at",
              "poids",
              "zone_bb_libelle",
              "cellule_bb_code",
              "commentaire_interne",
              "commentaire_cariste",
              "comm_cariste_traite",
            ]}
            additionnalColumns={[
              {
                datakey: "select",
                title: "",
                sortable: false,
                searchable: false,
                format: this.getSelectCheckbox.bind(this),
              },
              {
                datakey: "zone_bb_libelle",
                title: "Zone BB",
                sortable: true,
                searchable: true,
              },
              {
                datakey: "cellule_bb_code",
                title: "Cellule BB",
                sortable: true,
                searchable: true,
              },
            ]}
            stock_bb={true}
            sortColumns
            hideBtnMassImport
            hideBtnEtiquette
            hideBtnEtatStock
            pagination={true}
            search={true}
          />
      </>
    )
  }

  getIndexSelectedTabMain(){
    const { selectedTabMain } = this.state;
    if(selectedTabMain === TAB_PLAN) return 0;
    if(selectedTabMain === TAB_LISTING) return 1;
    return 0;
  }

  render() {
    const { classes } = this.props;
    const { selectedTabMain } = this.state;
    return (
      <Grid container className={classes.container}>
         <AppBar position="static" color="default">
          <Tabs
              value={selectedTabMain}
              onChange={(e,name)  => {
                this.setState({
                  selectedTabMain: name,
                  selectedBigBag: {},
                  selectedZones: [],
                });
                this.props.history.push({
                  pathname: "/zones-bb/" + name,
                });
              }}
              indicatorColor="primary"
              textColor="primary"
              variant="scrollable"
              scrollButtons="auto"
            >
              <Tab label={"Plan"} value={TAB_PLAN}/>
              <Tab label={"Listing"} value={TAB_LISTING}/>

          </Tabs>
        </AppBar>
        <SwipeableViews axis={'x'} index={this.getIndexSelectedTabMain()}>
          <Typography component="div" dir={'ltr'} style={{padding: 8 * 3}}>
            <Grid item xs={12}>
              {this.displayPlan()}
            </Grid>
          </Typography>
          <Typography component="div" dir={'ltr'} style={{padding: 8 * 3}}>
            <Grid item xs={12}>
              {this.displayListing()}
            </Grid>
          </Typography>
        </SwipeableViews>
        { this.getModal()}
      </Grid>
    );
  }
}

ZoneBB = withStyles(ZoneBBCss)(ZoneBB);

ZoneBB = connect((store) => {
  return {
    etablissementsStore: store.collections.etablissements,
    zoneBBStore: store.collections["zone-bbs"],
    user: store.auth.user,
  };
})(ZoneBB);

export default ZoneBB;
