import React, { Component } from "react";
import PropTypes from "prop-types";

import { withStyles } from "@material-ui/core/styles";
import BonLivraisonBtnCss from "./css/BonLivraisonBtnCss";
import {
  Button,
  DialogContent,
  DialogTitle,
  Grid,
  Typography,
} from "@material-ui/core";
import { FileCopy } from "@material-ui/icons";
import Modal from "../../common/Components/Modal";
import TextInput from "../../common/Components/TextInput";
import { connect } from "react-redux";
import { updateAffaireBL } from "../../../reducers/collectionsReducer";

class BonLivraisonBtn extends Component {
  state = {
    open: false,
    affaire: this.props.affaire,
  };

  getD;
  getModalContent() {
    return [
      <DialogTitle key="title" id="alert-dialog-slide-title">
        <FileCopy /> <span>Édition bon de Livraison</span>
      </DialogTitle>,
      <DialogContent key="content">{this.getForm()}</DialogContent>,
    ];
  }
  getForm() {
    const { affaireStore, classes } = this.props;
    const { affaire } = this.state;
    const { bl_pesee_camion, bl_commentaire_expedition } = affaire;

    var lots = [];
    affaire.lots.forEach((lot) => {
      lots.push(
        <>
          <Grid container xs={12} key={lot.uuid}>
            <Grid item xs={12}>
              <Typography variant="subtitle1">
                {lot.conditionnement_nom} / {lot.article_nom}
              </Typography>
            </Grid>
            <Grid item xs={3}>
              <TextInput
                id="prix_achat"
                label="Prix Achat"
                value={lot.prix_unitaire_achat}
                margin="normal"
                collectionStore={affaireStore}
                name="prix_achat"
                disabled
                fullWidth
                type="number"
              />
            </Grid>
            <Grid item xs={3}>
              <TextInput
                id="bl_poids_brut"
                label="Poids Brut (T)"
                value={lot.bl_poids_brut}
                margin="normal"
                collectionStore={affaireStore}
                name="bl_poids_brut"
                onChangeHandler={this.onChangeLotHandler.bind(this, lot)}
                fullWidth
                type="number"
                InputProps={{
                  inputProps: {
                    min: 0,
                    step: 0.001,
                  },
                }}
              />
            </Grid>
            <Grid item xs={3}>
              <TextInput
                id="bl_poids_deduction"
                label="Déduction (T)"
                value={lot.bl_poids_deduction}
                margin="normal"
                collectionStore={affaireStore}
                name="bl_poids_deduction"
                onChangeHandler={this.onChangeLotHandler.bind(this, lot)}
                fullWidth
                type="number"
                InputProps={{
                  inputProps: {
                    min: 0,
                    step: 0.001,
                  },
                }}
              />
            </Grid>
            <Grid item xs={3}>
              <TextInput
                id="bl_poids_net"
                label="Poids net (T)"
                value={lot.bl_poids_net}
                margin="normal"
                collectionStore={affaireStore}
                name="bl_poids_net"
                fullWidth
                type="number"
                disabled
              />
            </Grid>

            <TextInput
              label="Remarque BL"
              value={lot.bl_commentaire}
              margin="normal"
              collectionStore={affaireStore}
              name="bl_commentaire"
              onChangeHandler={this.onChangeLotHandler.bind(this, lot)}
              multiline
              rows={2}
              fullWidth
            />
            <TextInput
              label="Remarque BL Interne"
              value={lot.bl_commentaire_interne}
              margin="normal"
              collectionStore={affaireStore}
              name="bl_commentaire_interne"
              onChangeHandler={this.onChangeLotHandler.bind(this, lot)}
              multiline
              rows={2}
              fullWidth
            />
          </Grid>
          <hr className={classes.hr} />
        </>
      );
    });
    return (
      <>
        <Grid item xs={12}>
          <TextInput
            id="bl_pesee_camion"
            label="Pesée camion (Kg)"
            value={bl_pesee_camion}
            margin="normal"
            collectionStore={affaireStore}
            name="bl_pesee_camion"
            onChangeHandler={this.onChangeHandler.bind(this)}
            fullWidth
            type="number"
            InputProps={{
              inputProps: {
                min: 0,
              },
            }}
          />
        </Grid>
        <Grid item xs={12}>
          <TextInput
            id="bl_commentaire_expedition"
            label="Commentaire Expedition"
            value={bl_commentaire_expedition}
            margin="normal"
            collectionStore={affaireStore}
            name="bl_commentaire_expedition"
            onChangeHandler={this.onChangeHandler.bind(this)}
            multiline
            rows={5}
            fullWidth
          />
        </Grid>
        <h5>Marchandises</h5>
        <hr className={classes.hr} />

        {lots}
      </>
    );
  }
  onChangeLotHandler(lot, name, event) {
    lot[name] = event.target.value;
    lot.bl_poids_net =
      (lot.bl_poids_brut ? lot.bl_poids_brut : 0) -
      (lot.bl_poids_deduction ? lot.bl_poids_deduction : 0);
    this.setState({
      affaire: this.state.affaire,
    });
  }
  onChangeHandler(name, event) {
    this.setState({
      affaire: {
        ...this.state.affaire,
        [name]: event.target.value,
      },
    });
  }
  onCloseHandler() {
    this.setState({ open: false });
  }
  onSubmitHandler() {
    this.onCloseHandler();
    updateAffaireBL(
      this.props.dispatch,
      this.state.affaire.uuid,
      this.state.affaire,
      (response) => {
        window.open(response.data, "_blank");
      }
    );
  }
  generateModal() {
    const { open } = this.state;

    return (
      <Modal
        openModal={open}
        onCloseHandler={this.onCloseHandler.bind(this)}
        onSubmitHandler={this.onSubmitHandler.bind(this)}
        fullWidth={true}
        maxWidth={"xl"}
        disabledEnter={true}
      >
        {this.getModalContent()}
      </Modal>
    );
  }
  render() {
    const { classes, affaire } = this.props;
    if (!affaire) return null;
    return (
      <>
        <Button
          className={
            classes.button + (affaire.bl_send ? " " + classes.everSend : "")
          }
          onClick={() => {
            this.setState({ open: true });
          }}
          title={"Bon de Livraison"}
          variant="outlined"
        >
          <FileCopy className={classes.leadIcon} /> Bon de Livraison
        </Button>
        {this.generateModal()}
      </>
    );
  }
}

BonLivraisonBtn = withStyles(BonLivraisonBtnCss)(BonLivraisonBtn);

BonLivraisonBtn.propTypes = {
  affaire: PropTypes.object.isRequired,
};

BonLivraisonBtn = connect((store) => {
  return {
    affaireStore: store.collections.affaires,
  };
})(BonLivraisonBtn);

export default BonLivraisonBtn;
