export default theme => ({
  formTextArea: {
    width: "calc(100% - 1em)",
    display: "flex",
    flexDirection: "column",
  },
  textarealabel: {
    color: "rgba(0, 0, 0, 0.54)"
  },
  textarea: {
    width: "100%",
    backgroundColor:"#FAFAFA",
  },
  errorContainer: {
    color: theme.palette.error.main,
  },
  error: {
    color: theme.palette.error.main,
  },

});
