import React, { Component } from "react";

import { Grid, Typography } from "@material-ui/core";
import { withStyles } from "@material-ui/core/styles";
import { Check, Settings } from "@material-ui/icons";

import TextInput from "../../common/Components/TextInput";
import NumberInput from "../../common/Components/NumberInput";
import CheckboxInput from "../../common/Components/CheckboxInput";
import CollectionCrud from "../../common/Components/CollectionCrud";
import EtatCss from "./css/EtatCss";

class Etat extends Component {
  getDeleteModalTitle() {
    return (
      <div>
        <Settings />
        <span>{"Suppression de l'état"}</span>
      </div>
    );
  }

  getDeleteModalContent() {
    return (
      <Typography>{"Êtes-vous sûr de vouloir supprimer cet état ?"}</Typography>
    );
  }

  getCreateUpdateModalTitle(etat) {
    let title = !!etat
      ? "Modification de l'état : " + etat.nom
      : "Ajout d'un nouvel état";
    return (
      <div>
        <Settings />
        <span>{title}</span>
      </div>
    );
  }

  getCreateUpdateModalContent(etat, etatsStore, onChangeHandler) {
    let {
      nom,
      end,
      start,
      is_previsionnel,
      is_attente_poids,
      display_on_stats,
      attente_facturation,
      order_number,
      is_transport,
      is_bloquant_for_ending,
      is_mad_vendu,
      in_export_dechets,
    } = etat;
    return (
      <Grid container>
        <Grid item xs={12}>
          <NumberInput
            id="order_number"
            label="Ordre"
            value={order_number}
            margin="normal"
            collectionStore={etatsStore}
            name="order_number"
            onChangeHandler={onChangeHandler}
            fullWidth
          />
        </Grid>
        <Grid item xs={12}>
          <TextInput
            id="nom"
            label="Nom"
            value={nom}
            margin="normal"
            collectionStore={etatsStore}
            name="nom"
            onChangeHandler={onChangeHandler}
            fullWidth
          />
        </Grid>
        <Grid item xs={12}>
          <CheckboxInput
            id="end"
            label="Statut de cloture d'une affaire"
            value={end}
            margin="normal"
            name="end"
            onChangeHandler={onChangeHandler}
          />
        </Grid>
        <Grid item xs={12}>
          <CheckboxInput
            id="start"
            label="Statut initial d'une affaire"
            value={start}
            margin="normal"
            name="start"
            onChangeHandler={onChangeHandler}
          />
        </Grid>
        <Grid item xs={12}>
          <CheckboxInput
            id="start"
            label="Statut prévisionnel"
            value={is_previsionnel}
            margin="normal"
            name="is_previsionnel"
            onChangeHandler={onChangeHandler}
          />
        </Grid>
        <Grid item xs={12}>
          <CheckboxInput
            id="start"
            label="Statut attente de poids"
            value={is_attente_poids}
            margin="normal"
            name="is_attente_poids"
            onChangeHandler={onChangeHandler}
          />
        </Grid>
        <Grid item xs={12}>
          <CheckboxInput
            id="start"
            label="Statut Attente de facturation"
            value={attente_facturation}
            margin="normal"
            name="attente_facturation"
            onChangeHandler={onChangeHandler}
          />
        </Grid>
        <Grid item xs={12}>
          <CheckboxInput
            id="start"
            label="Transport commandé"
            value={is_transport}
            margin="normal"
            name="is_transport"
            onChangeHandler={onChangeHandler}
          />
        </Grid>
        <Grid item xs={12}>
          <CheckboxInput
            id="start"
            label="Afficher dans la supervision"
            value={display_on_stats}
            margin="normal"
            name="display_on_stats"
            onChangeHandler={onChangeHandler}
          />
        </Grid>
        <Grid item xs={12}>
          <CheckboxInput
            id="start"
            label="Bloquant pour passage en terminé"
            value={is_bloquant_for_ending}
            margin="normal"
            name="is_bloquant_for_ending"
            onChangeHandler={onChangeHandler}
          />
        </Grid>
        <Grid item xs={12}>
          <CheckboxInput
            id="is_mad_vendu"
            label="Passer les MAD en vendu"
            value={is_mad_vendu}
            margin="normal"
            name="is_mad_vendu"
            onChangeHandler={onChangeHandler}
          />
        </Grid>
        <Grid item xs={12}>
          <CheckboxInput
            id="in_export_dechets"
            label="Etats présent dans l'export déchet"
            value={in_export_dechets}
            margin="normal"
            name="in_export_dechets"
            onChangeHandler={onChangeHandler}
          />
        </Grid>

      </Grid>
    );
  }

  render() {
    const { classes } = this.props;
    return (
      <Grid container className={classes.container}>
        <CollectionCrud
          collectionName={"etats"}
          showBtnEdit={true}
          showBtnAdd={true}
          showBtnDelete={true}
          rights={{
            create: "admin-cud-parametres",
            edit: "admin-cud-parametres",
            delete: "admin-cud-parametres",
          }}
          deleteModalTitle={this.getDeleteModalTitle}
          deleteModalContent={this.getDeleteModalContent}
          createUpdateModalTitle={this.getCreateUpdateModalTitle}
          createUpdateModalContent={this.getCreateUpdateModalContent.bind(this)}
          datatableConfig={{
            showPagination: true,
            showSearch: true,
            defaultSort: "order_number",
            sortType: "desc",
            rowsPerPageOptions: [10, 25, 50],
          }}
          cellsConfig={[
            {
              datakey: "order_number",
              title: "Ordre",
              sortable: true,
              searchable: true,
            },
            {
              datakey: "nom",
              title: "Nom",
              sortable: true,
              searchable: true,
            },
            {
              datakey: "end",
              title: "Etat Final",
              sortable: true,
              searchable: false,
              format: (etat) => (etat.end ? <Check /> : null),
            },
            {
              datakey: "start",
              title: "Etat Initial",
              sortable: true,
              searchable: false,
              format: (etat) => (etat.start ? <Check /> : null),
            },
            {
              datakey: "is_attente_poids",
              title: "Etat Attente poids",
              sortable: true,
              searchable: false,
              format: (etat) => (etat.is_attente_poids ? <Check /> : null),
            },
            {
              datakey: "attente_facturation",
              title: "Attente Facturation",
              sortable: true,
              searchable: false,
              format: (etat) => (etat.attente_facturation ? <Check /> : null),
            },
            {
              datakey: "is_previsionnel",
              title: "Etat Prévisionnel",
              sortable: true,
              searchable: false,
              format: (etat) => (etat.is_previsionnel ? <Check /> : null),
            },
            {
              datakey: "is_transport",
              title: "Transport Commandé",
              sortable: true,
              searchable: false,
              format: (etat) => (etat.is_transport ? <Check /> : null),
            },
            {
              datakey: "display_on_stats",
              title: "Supervision",
              sortable: true,
              searchable: false,
              format: (etat) => (etat.display_on_stats ? <Check /> : null),
            },
            {
              datakey: "is_bloquant_for_ending",
              title: "Bloquant pour passage 7",
              sortable: true,
              searchable: false,
              format: (etat) => (etat.is_bloquant_for_ending ? <Check /> : null),
            },
            {
              datakey: "is_mad_vendu",
              title: "MAD en vendu",
              sortable: true,
              searchable: false,
              format: (etat) => (etat.is_mad_vendu ? <Check /> : null),
            },
            {
              datakey: "in_export_dechets",
              title: "Dans l'export déchets",
              sortable: true,
              searchable: false,
              format: (etat) => (etat.in_export_dechets ? <Check /> : null),
            }
          ]}
        />
      </Grid>
    );
  }
}

Etat = withStyles(EtatCss)(Etat);

export default Etat;
