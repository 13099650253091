import React, { Component } from "react";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import { withRouter } from "react-router-dom";

import {
  DialogContent,
  DialogTitle,
  Grid,
  IconButton,
  MenuItem,
  TextField,
  Typography,
} from "@material-ui/core";
import { withStyles } from "@material-ui/core/styles";
import {
  Business,
  MoreHoriz,
  TrendingUp,
  History,
  Phone,
  NoteAdd,
  Email,
  Mail,
} from "@material-ui/icons";

import EmailButton from "../Email";


import { buildRoute } from "../../../router/Tools";
import {
  collectionActions,
  getAttestationValorisationMail,
  loadCollectionAttribute,
} from "../../../reducers/collectionsReducer";
import CollectionCrud from "../../common/Components/CollectionCrud";
import Etablissement from "../Etablissement";
import TextInput from "../../common/Components/TextInput";

import EntrepriseCss from "./css/EntrepriseCss";

import FormGroup from "@material-ui/core/FormGroup";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Checkbox from "@material-ui/core/Checkbox";
import NoteModal from "../Note/modal";
import { getNoteContent } from "../../tools/Tools";
import { hasRights } from "../../common/Tools/Tools";
import Modal from "../../common/Components/Modal";

class Entreprise extends Component {
  static defaultProps = {
    delete: true,
    pagination: true,
    search: true,
    filter_local_storage: "entreprise_filters",
  };

  static propTypes = {
    delete: PropTypes.bool,
    pagination: PropTypes.bool,
    search: PropTypes.bool,
    filter_local_storage: PropTypes.string,
  };

  state = {
    email_attestation_valorisation: null,
    openModalAttestationValorisation: false,
    attestation_valorisation_annee: null,
    openEtablissementModal: false,
    entrepriseNom: null,
    entrepriseUuid: null,
    entreprises: null,
    current_filter: {},
    openNoteModal: false,
    note_entreprise_uuid: null,
    note_entreprise_nom: null,
    note: null,
    filters: {
      has_relance: false,
      is_active_3_mois: false,
      is_active_6_mois: false,
      is_active_12_mois: false,
      autresEntreprises: false,
      is_prospect: false,
      commercial: this.props.commercial_uuid
        ? this.props.commercial_uuid
        : ["all"],
      activite_filter: ["all"],
      source_prospects_filter: ["all"],
      code_postal: null,
    },
    filters_loaded: false,
  };

  static getDerivedStateFromProps(props, state) {
    const { filter_local_storage } = props;

    if (!state.filters_loaded) {
      const filters = JSON.parse(localStorage.getItem(filter_local_storage));
      if (filters) {
        return {
          filters: {
            ...state.filters,
            ...filters,
          },
          filters_loaded: true,
        };
      }
    }
    if (props.entreprisesStore.list && !state.entreprises) {
      return {
        ...state,
        entreprises: props.entreprisesStore.list,
      };
    } else return null;
  }

  componentDidMount() {
    loadCollectionAttribute(
      this.props.dispatch,
      "list",
      "activites",
      this.props.activitesStore
    );

    loadCollectionAttribute(
      this.props.dispatch,
      "list",
      "langues",
      this.props.languesStore
    );

    collectionActions(this.props.dispatch, "users", "INDEX", {
      params: {
        commerciaux: true,
      },
    });


    loadCollectionAttribute(this.props.dispatch, "list", "source_prospects", this.props.sourceProspects);
  }

  onChangeHandlerFilters(name, e) {
    const { filter_local_storage } = this.props;

    let values = e.target.value;
    /**
     * Gestion de la value all
     * si on clique dessus on vide la liste et ne garde que all
     * si on clique sur un autre element on enleve all
     * si on vide la liste on rajoute all
     */
    if (values.length > 0) {
      if (this.state.filters[name].indexOf("all") !== -1) {
        values = values.filter((value) => value !== "all");
      } else if (values.indexOf("all") !== -1) {
        values = ["all"];
      }
    } else {
      values = ["all"];
    }

    this.setState(
      {
        filters: {
          ...this.state.filters,
          [name]: values,
        },
      },
      () => {
        localStorage.setItem(
          filter_local_storage,
          JSON.stringify(this.state.filters)
        );
        this.loadAsyncData(this.state.current_filter);
      }
    );
  }

  getCodePostalFilter() {
    const { classes, disabledCodePostalFilter } = this.props;
    if (disabledCodePostalFilter) return null;

    const { filter_local_storage } = this.props;

    return (
      <TextField
        label={"Code Postal"}
        margin="normal"
        className={classes.selectContainer}
        onChange={(e) => {
          this.setState({
            filters: {
              ...this.state.filters,
              code_postal: e.target.value,
            },
          },() => {
            localStorage.setItem(
              filter_local_storage,
              JSON.stringify(this.state.filters)
            );
            this.loadAsyncData(this.state.current_filter);
          });
        }}
        value={this.state.filters.code_postal}
      />
    )
  }

  getSourceProspectFilter() {
    const { sourceProspects, classes, disabledSourceProspectsFilter } = this.props;

    if (!sourceProspects.list || disabledSourceProspectsFilter) return null;
    const sources = [
      {
        uuid: "all",
        libelle: "Toutes",
      },
      ...sourceProspects.list,
    ];
    const options = sources.map((libelle) => {
      return (
        <MenuItem value={libelle.uuid} key={libelle.uuid}>
          {libelle.libelle}
        </MenuItem>
      );
    });

    return (
      <TextField
        label={"Source Prospect"}
        margin="normal"
        className={classes.selectContainer}
        onChange={this.onChangeHandlerFilters.bind(this, "source_prospects_filter")}
        SelectProps={{
          multiple: true,
          value: this.state.filters.source_prospects_filter,
        }}
        select
      >
        {options}
      </TextField>
    );
  }

  getActiviteFilter() {
    const { activitesStore, classes, disabledActiviteFilter } = this.props;

    if (!activitesStore.list || disabledActiviteFilter) return null;
    const activites = [
      {
        uuid: "all",
        nom: "Toutes",
      },
      ...activitesStore.list,
    ];
    const options = activites.map((activite) => {
      return (
        <MenuItem value={activite.uuid} key={activite.uuid}>
          {activite.nom}
        </MenuItem>
      );
    });

    return (
      <TextField
        label={"Activité"}
        margin="normal"
        className={classes.selectContainer}
        onChange={this.onChangeHandlerFilters.bind(this, "activite_filter")}
        SelectProps={{
          multiple: true,
          value: this.state.filters.activite_filter,
        }}
        select
      >
        {options}
      </TextField>
    );
  }

  generateCommercialSelect() {
    const { usersStore, classes, disabledCommercialFilter } = this.props;
    if (!usersStore.list || disabledCommercialFilter) return null;
    const commerciaux = [
      {
        uuid: "all",
        firstname: "Tous",
      },
      ...usersStore.list,
    ];
    const options = commerciaux.map((user) => {
      return (
        <MenuItem value={user.uuid} key={user.uuid}>
          {user.firstname}
        </MenuItem>
      );
    });

    return (
      <TextField
        label={"Commercial"}
        margin="normal"
        className={classes.selectContainer}
        onChange={this.onChangeHandlerFilters.bind(this, "commercial")}
        SelectProps={{
          multiple: true,
          value: this.state.filters.commercial,
        }}
        select
      >
        {options}
      </TextField>
    );
  }

  getFilters() {
    const { classes } = this.props;
    let {
      has_relance,
      is_active_3_mois,
      is_active_6_mois,
      is_active_12_mois,
      autresEntreprises,
      is_prospect,
    } = this.state.filters;
    return (
      <Grid container>
        <Grid item xs={12}>
          <Typography
            key="typo"
            variant="button"
            gutterBottom
            className={classes.crudTitle}
          >
            Filtres
          </Typography>
        </Grid>
        <Grid item xs={12}>
          <FormGroup row>
            {this.createFilterCheckBox("Relances", has_relance, "has_relance")}
            {this.createFilterCheckBox(
              "Active 3 mois",
              is_active_3_mois,
              "is_active_3_mois"
            )}
            {this.createFilterCheckBox(
              "Active 6 mois",
              is_active_6_mois,
              "is_active_6_mois"
            )}
            {this.createFilterCheckBox(
              "Active 12 mois",
              is_active_12_mois,
              "is_active_12_mois"
            )}
            {this.createFilterCheckBox("Prospects", is_prospect, "is_prospect")}
            {this.createFilterCheckBox(
              "Autres",
              autresEntreprises,
              "autresEntreprises"
            )}
          </FormGroup>
        </Grid>
        <Grid item xs={3}>
          {this.generateCommercialSelect()}
        </Grid>
        <Grid item xs={3}>
          {this.getActiviteFilter()}
        </Grid><Grid item xs={3}>
          {this.getSourceProspectFilter()}
        </Grid>
        <Grid item xs={3}>
          {this.getCodePostalFilter()}
        </Grid>
      </Grid>
    );
  }

  createFilterCheckBox(label, checked, name) {
    return (
      <FormControlLabel
        control={
          <Checkbox
            checked={checked}
            onChange={this.handleChange.bind(this)}
            name={name}
            color="primary"
          />
        }
        label={label}
      />
    );
  }

  handleChange(e) {
    const { filter_local_storage } = this.props;

    this.setState(
      {
        filters: {
          ...this.state.filters,
          [e.target.name]: e.target.checked,
        },
      },
      () => {
        localStorage.setItem(
          filter_local_storage,
          JSON.stringify(this.state.filters)
        );
        this.loadAsyncData(this.state.current_filter);
      }
    );
  }

  getDeleteModalTitle() {
    return (
      <div>
        <Business />
        <span>{"Suppression de l'entreprise"}</span>
      </div>
    );
  }

  getDeleteModalContent() {
    return (
      <Typography>
        {"Êtes-vous sûr de vouloir supprimer cette entreprise ?"}
      </Typography>
    );
  }

  getCreateUpdateModalTitle(entreprise) {
    let title = !!entreprise
      ? "Modification de l'entreprise : " + entreprise.nom
      : "Ajout d'une nouvelle entreprise";
    return (
      <div>
        <Business />
        <span>{title}</span>
      </div>
    );
  }

  getActivites() {
    if (!this.props.activitesStore.list) {
      return [];
    }
    return this.props.activitesStore.list.map((activite) => {
      return (
        <MenuItem value={activite.uuid} key={activite.uuid}>
          {activite.nom}
        </MenuItem>
      );
    });
  }

  getLangues() {
    if (!this.props.languesStore.list) {
      return [];
    }
    return this.props.languesStore.list.map((langue) => {
      return (
        <MenuItem value={langue.uuid} key={langue.uuid}>
          {langue.nom}
        </MenuItem>
      );
    });
  }

  getCreateUpdateModalContent(entreprise, entreprisesStore, onChangeHandler) {
    let { nom, siret, tva, ape, activite_uuid, langue_uuid, commentaires } =
      entreprise;
    const { classes } = this.props;
    return (
      <Grid container>
        <Grid item xs={6}>
          <TextInput
            id="nom"
            label="Raison sociale"
            value={nom}
            required={true}
            margin="normal"
            collectionStore={entreprisesStore}
            name="nom"
            onChangeHandler={onChangeHandler}
            fullWidth
          />
        </Grid>
        <Grid item xs={6}>
          <TextInput
            id="siret"
            label="N° SIRET"
            value={siret}
            margin="normal"
            collectionStore={entreprisesStore}
            name="siret"
            onChangeHandler={onChangeHandler}
            fullWidth
          />
        </Grid>
        <Grid item xs={6}>
          <TextInput
            id="langue_uuid"
            label="Langue parlée"
            value={langue_uuid}
            required={true}
            margin="normal"
            collectionStore={entreprisesStore}
            name="langue_uuid"
            onChangeHandler={onChangeHandler}
            className={classes.selectContainer}
            fullWidth
            select
          >
            {this.getLangues()}
          </TextInput>
        </Grid>
        <Grid item xs={6}>
          <TextInput
            id="tva"
            label="N° de TVA"
            value={tva}
            margin="normal"
            collectionStore={entreprisesStore}
            name="tva"
            onChangeHandler={onChangeHandler}
            fullWidth
          />
        </Grid>
        <Grid item xs={6}>
          <TextInput
            id="activite_uuid"
            label="Activité"
            value={activite_uuid}
            required={true}
            margin="normal"
            collectionStore={entreprisesStore}
            name="activite_uuid"
            onChangeHandler={onChangeHandler}
            className={classes.selectContainer}
            fullWidth
            select
          >
            {this.getActivites()}
          </TextInput>
        </Grid>
        <Grid item xs={6}>
          <TextInput
            id="ape"
            label="Code APE"
            value={ape}
            margin="normal"
            collectionStore={entreprisesStore}
            name="ape"
            onChangeHandler={onChangeHandler}
            fullWidth
          />
        </Grid>
        <Grid item xs={12}>
          <TextInput
            id="commentaires"
            label="Commentaires"
            value={commentaires}
            margin="normal"
            collectionStore={entreprisesStore}
            name="commentaires"
            onChangeHandler={onChangeHandler}
            fullWidth
          />
        </Grid>
      </Grid>
    );
  }

  getDetailButton(entreprise) {
    const { classes } = this.props;
    return (
      <IconButton
        key="detail"
        aria-label="Afficher"
        color="primary"
        onClick={() => {
          this.props.history.push(
            buildRoute("/entreprises/:uuid", { uuid: entreprise.uuid })
          );
        }}
        title="Afficher"
        className={classes.button}
      >
        <MoreHoriz />
      </IconButton>
    );
  }

  getEmailAttestationValorisationButton(entreprise) {
    const { classes, user } = this.props;
    if (!hasRights("entreprises-attesation-valorisation", user)) {
      return null;
    }
    return (
      <IconButton
        key="attestation_valorisation"
        aria-label="Email attestation valorisation"
        color="primary"
        onClick={() => {
          this.setState({
            openModalAttestationValorisation: true,
            entreprise_uuid: entreprise.uuid,
          });
        }}
        title="Email attestation valorisation"
        className={classes.button}
      >
        <Email />
      </IconButton>
    );
  }

  onSubmitAttestationValorisationHandler() {
    const { entreprise_uuid, attestation_valorisation_annee } = this.state;
    getAttestationValorisationMail(entreprise_uuid, attestation_valorisation_annee).then((response) => {
      this.setState({
        openModalAttestationValorisation: false,
        email_attestation_valorisation: response.data,
      },() => {

      });
    })
  }

  getNoteButton(entreprise) {
    const { classes } = this.props;
    return (
      <IconButton
        onClick={() => {
          this.setState({
            openNoteModal: true,
            note_entreprise_uuid: entreprise.uuid,
            note_entreprise_nom: entreprise.nom,
            note: null,
          });
        }}
        color="primary"
        title="Ajouter une note"
        className={classes.button}
      >
        <NoteAdd className={classes.accountIcon} />
      </IconButton>
    );
  }

  getCrudTitle() {
    const { classes } = this.props;
    return [
      <Typography
        key="typo"
        variant="button"
        gutterBottom
        className={classes.crudTitle}
      >
        Entreprises
      </Typography>,
    ];
  }

  getIconActive(entreprise) {
    const { classes } = this.props;
    let icon = null;
    let className = classes.is_inactive;
    let title = "";

    if (entreprise.is_active_3_mois) {
      className = classes.is_active_3_mois;
      title = "Actif 3 mois";
    } else if (entreprise.is_active_6_mois) {
      className = classes.is_active_6_mois;
      title = "Actif 6 mois";
    } else if (entreprise.is_active_12_mois) {
      className = classes.is_active_12_mois;
      title = "Actif 12 mois";
    }

    if (entreprise.is_prospect) {
      title = "Prospect";
      className = classes.is_prospect;
      icon = (
        <IconButton title={title} className={classes.nopointer}>
          <Phone className={className} />
        </IconButton>
      );
    } else if (
      entreprise.is_active_3_mois ||
      entreprise.is_active_6_mois ||
      entreprise.is_active_12_mois
    ) {
      icon = (
        <IconButton title={title} className={classes.nopointer}>
          <TrendingUp className={className} />
        </IconButton>
      );
    } else {
      title = "Autre";
      icon = (
        <IconButton title={title} className={classes.nopointer}>
          A
        </IconButton>
      );
    }

    return icon;
  }

  getCellsConfig() {
    const { classes } = this.props;
    var additionnal_cols = [];
    if (this.props.commercial_uuid) {
      additionnal_cols = [
        {
          datakey: "last_note",
          title: "Note",
          sortable: false,
          searchable: false,
          className: classes.cursorPointer,
          format: (entreprise) => {
            if (!entreprise.last_note) return null;
            return (
              <div
                onClick={() => {
                  this.setState({
                    openNoteModal: true,
                    note_entreprise_uuid: entreprise.uuid,
                    note_entreprise_nom: entreprise.nom,
                    note: entreprise.last_note,
                  });
                }}
                title="Cliquez pour Éditer la note."
              >
                {getNoteContent(entreprise.last_note.contenu)}
              </div>
            );
          },
        },
      ];
    } else {
      additionnal_cols = [
        {
          datakey: "commercial_full_name",
          title: "Commercial",
          sortable: true,
          searchable: true,
        },
      ];
    }
    return [
      {
        datakey: "status",
        title: "Statut",
        sortable: true,
        searchable: false,
        format: (e) => this.getIconActive(e),
        className: classes.center,
      },
      {
        datakey: "source_prospect_libelle",
        title: "Source prospect",
        sortable: true,
        searchable: true,
      },
      {
        datakey: "nom",
        title: "Raison sociale",
        sortable: true,
        searchable: true,
      },
      {
        datakey: "code",
        title: "Code",
        sortable: true,
        searchable: true,
      },
      {
        datakey: "ville",
        title: "Ville",
        sortable: true,
        searchable: true,
      },
      {
        datakey: "code_postal",
        title: "Code Postal",
        sortable: true,
        searchable: true,
      },
      {
        datakey: "activite_nom",
        title: "Activité",
        sortable: true,
        searchable: true,
      },
      {
        datakey: "has_relance",
        title: "Relance",
        sortable: true,
        searchable: false,
        format: (e) =>
          e.has_relance ? (
            <IconButton title="Relance en Cours" className={classes.nopointer}>
              <History color="primary" />
            </IconButton>
          ) : null,
        className: classes.center,
      },
      ...additionnal_cols,
    ];
  }

  /**
   * Traite les actions remontées par la modale de création d'Entreprise.
   *
   * @param {string}     action     - L'identifiant de l'action.
   * @param {Entreprise} entreprise - L'objet Entreprise retourné par l'action.
   */
  handleEntrepriseModalActions(action, entreprise) {
    if (action === "create") {
      this.setState({
        openEtablissementModal: true,
        entrepriseNom: entreprise.nom,
        entrepriseUuid: entreprise.uuid,
      });
    }
  }

  /**
   * Traite les actions remontées par la modale de création d'Établissement.
   *
   * @param {string}        action        - L'identifiant de l'action.
   * @param {Etablissement} etablissement - L'objet Établissement retourné.
   */
  handleEtablissementModalActions(action, etablissement) {
    if (action === "close") {
      this.setState({
        openEtablissementModal: false,
        entrepriseNom: null,
        entrepriseUuid: null,
      });
    }
  }

  getFilterCommercialValue() {
    if (this.state.filters.commercial.includes("all")) return null;
    return this.state.filters.commercial;
  }

  getFilterActiviteValue() {
    if (this.state.filters.activite_filter.includes("all")) return null;
    return this.state.filters.activite_filter;
  }

  getFilterSourceProspectValue() {
    if (this.state.filters.source_prospects_filter.includes("all")) return null;
    return this.state.filters.source_prospects_filter;
  }

  loadAsyncData(ccrud_filters) {
    let { dispatch } = this.props;
    const { filters } = this.state;
    let filters_params = {
      ...ccrud_filters,
      ...filters,
      commercial: this.getFilterCommercialValue(),
      activite_filter: this.getFilterActiviteValue(),
      source_prospects_filter: this.getFilterSourceProspectValue(),
    };
    this.setState({ loading: true });
    return collectionActions(
      dispatch,
      "entreprises",
      "INDEX",
      { params: { ...filters_params } },
      null,
      true
    );
  }

  getOptionAttestationValorisationAnnees(){
    const current_year = new Date().getFullYear();
    const nb_years = 5;
    const options = [];

    for (let i = 0; i < nb_years; i++) {
      options.push(<MenuItem key={current_year - i} value={current_year - i}>{current_year - i}</MenuItem>);
    }

    return options;
  }

  handleCloseAttestationValorisation(){
    this.setState({
      email_attestation_valorisation: null,
      entreprise_uuid: null,
    });
  }

  getEmailAttetationValorisationModal(){
    const { email_attestation_valorisation} = this.state;
    const { classes } = this.props;

    if(!email_attestation_valorisation) return null;
    return (
      <div style={{ display: "none"}}>
        <EmailButton
                open={true}
                className={classes.emailButton}
                email={email_attestation_valorisation}
                label={"Attestation de valorisation"}
                onCloseHandler={this.handleCloseAttestationValorisation.bind(this)}
                required=""
              />
      </div>
    )
  }

  render() {
    const { collectionCrud_options, classes } = this.props;
    const {
      openEtablissementModal,
      entrepriseNom,
      entrepriseUuid,
      openNoteModal,
      note_entreprise_uuid,
      note_entreprise_nom,
      note,
      openModalAttestationValorisation
    } = this.state;

    return (
      <>
        {this.getFilters()}
        <CollectionCrud
          collectionName={"entreprises"}
          asynchrone={true}
          asynchroneCallbackLoadData={this.loadAsyncData.bind(this)}
          getAsyncFilter={(filter) =>
            // eslint-disable-next-line
            (this.state.current_filter = filter)
          }
          showBtnEdit={false}
          showBtnAdd={true}
          showBtnDelete={this.props.delete}
          deleteModalTitle={this.getDeleteModalTitle}
          deleteModalContent={this.getDeleteModalContent}
          createUpdateModalTitle={this.getCreateUpdateModalTitle}
          createUpdateModalContent={this.getCreateUpdateModalContent.bind(this)}
          createUpdateModalSubmit={"Suivant"}
          actionsCallback={this.handleEntrepriseModalActions.bind(this)}
          persistDatatableOptions={
            !!collectionCrud_options
              ? collectionCrud_options.persistDatatableOptions
              : {
                  id: "entreprise_tab",
                  exipiration_minutes: 60 * 12, // 12 Heures
                }
          }
          additionnalControllers={[
            this.getDetailButton.bind(this),
            this.getNoteButton.bind(this),
            this.getEmailAttestationValorisationButton.bind(this),
          ]}
          datatableConfig={{
            showPagination: this.props.pagination,
            showSearch: this.props.search,
            defaultSort: "nom",
            sortType: "desc",
            rowsPerPageOptions: [25, 50],
          }}
          dataTableExtraNodes={[
            {
              element: this.getCrudTitle(),
              position: "top-left",
            },
          ]}
          defaultValues={{
            meg_facturation_auto: false,
          }}
          cellsConfig={this.getCellsConfig()}
        />
        <Etablissement
          actionsCallback={this.handleEtablissementModalActions.bind(this)}
          entrepriseUuid={entrepriseUuid}
          etablissementNom={entrepriseNom}
          principal={true}
          modal={{ only: true, open: openEtablissementModal }}
        />
        <NoteModal
          open={openNoteModal}
          entreprise_uuid={note_entreprise_uuid}
          entreprise_nom={note_entreprise_nom}
          note={note ? note : {
            entreprise_uuid: note_entreprise_uuid,
          }}
          onCloseClallback={() => {
            this.setState(
              {
                openNoteModal: false,
                note_entreprise_nom: null,
                note_entreprise_uuid: null,
                note: null,
              },
              () => {
                this.loadAsyncData(this.state.current_filter);
              }
            );
          }}
        />
        <Modal
          openModal={openModalAttestationValorisation}
          onCloseHandler={() => this.setState({
            openModalAttestationValorisation: false,
          })}
          onSubmitHandler={this.onSubmitAttestationValorisationHandler.bind(this)}
          disabledEnter={true}
        >
          <DialogTitle key="title">
            <Mail style={{ marginBottom: "-9px" }} /> Attestion de valorisation
          </DialogTitle>
          <DialogContent key="content">
            <Grid container>
              <Grid item xs={12}>
              <TextField
                label={"Année"}
                margin="normal"
                className={classes.selectContainer}
                onChange={(e) => {
                  this.setState({ attestation_valorisation_annee: e.target.value });
                }}
                select
              >
                {this.getOptionAttestationValorisationAnnees()}
              </TextField>
              </Grid>
            </Grid>
          </DialogContent>
        </Modal>
        {this.getEmailAttetationValorisationModal()}
      </>
    );
  }
}

Entreprise = withStyles(EntrepriseCss)(Entreprise);
Entreprise = withRouter(Entreprise);

Entreprise = connect((store) => {
  return {
    entreprisesStore: store.collections.entreprises,
    activitesStore: store.collections.activites,
    languesStore: store.collections.langues,
    usersStore: store.collections.users,
    user: store.auth.user,
    sourceProspects: store.collections.source_prospects,
  };
})(Entreprise);

export default Entreprise;
