import React, {Component} from 'react'
import PropTypes from 'prop-types'

import {Checkbox, FormControlLabel, FormGroup} from '@material-ui/core'
import {withStyles} from '@material-ui/core/styles/index'

import CheckboxInputCss from './css/CheckboxInputCss'

class CheckboxInput extends Component {

  static defaultProps = {
    placement: 'end',  // start, end, top, bottom
  }

  static propTypes = {
    collectionStore: PropTypes.object,
    label: PropTypes.string,
    name: PropTypes.string,
    onChangeHandler: PropTypes.func,
    placement: PropTypes.string,
    value: PropTypes.bool,
  }

  state = {
    value: this.props.value,
  }

  static getDerivedStateFromProps(nextProps, prevState) {
    if (nextProps.value !== prevState.value) {
      return {value: nextProps.value}
    }
    return null
  }

  handleChange(event) {
    const fakeEvent = {target: {value: !this.state.value}}
    this.setState(prevState => {
      return {value: !prevState.value}
    })
    this.props.onChangeHandler(this.props.name, fakeEvent)
  }

  render() {
    const filteredProps = [
      'classes', 'label', 'name', 'onChangeHandler', 'placement', 'value'
    ]
    let inputProps = Object.keys(this.props)
      .filter(
        k => !filteredProps.includes(k)
      )
      .reduce(
        (obj, key) => {
          obj[key] = this.props[key]
          return obj
        },
        {}
      )
    return (
      <FormGroup row className={this.props.className}>
        <FormControlLabel
          className={this.props.classes.formControlLabel}
          label={this.props.label}
          labelPlacement={this.props.placement}
          style={this.props.formControlStyle}
          control={
            <Checkbox
              checked={!!this.state.value}
              color="default"
              onChange={this.handleChange.bind(this)}
              {...inputProps}
            />
          }
        />
      </FormGroup>
    )
  }
}

export default withStyles(CheckboxInputCss)(CheckboxInput)
