import {
  CircularProgress,
  DialogContent,
  DialogTitle,
} from "@material-ui/core";
import { withStyles } from "@material-ui/core/styles";
import PropTypes from "prop-types";
import React, { Component } from "react";
import { connect } from "react-redux";
import {
  collectionActions,
  loadCollectionAttribute,
} from "../../../../reducers/collectionsReducer";
import Modal from "../Modal";

function isSame(next, prev) {
  return JSON.stringify(next) === JSON.stringify(prev);
}

class CreateUpdate extends Component {
  state = {
    detail: {},
    prevDefault: null,
    prevProps: null,
    storeDetail: null,
  };

  /**
   * Retourne le nouveau state en fonction des nextProps
   * @param  {[type]} nextProps [description]
   * @param  {[type]} prevState [description]
   * @return {[type]}           [description]
   */
  static getDerivedStateFromProps(nextProps, prevState) {
    const { collectionsStore, collectionName } = nextProps;
    const collectionNameStore = collectionsStore[collectionName];
    const propsFollowed = [
      "openModal",
      "uuidSelected",
      "extradatasForm",
      "defaultValues",
    ];

    // Fusionne le store avec les props suivies
    const props = { detail: collectionNameStore.detail };

    Object.keys(nextProps)
      .filter((key) => propsFollowed.includes(key))
      .forEach((key) => (props[key] = nextProps[key]));

    let nextState = { ...prevState, prevProps: props };

    if(nextProps.uuidSelected || !nextProps.disableLoadCollectionFromDerivedState){
      loadCollectionAttribute(
        nextProps.dispatch,
        "detail",
        collectionName,
        collectionNameStore,
        { uuid: nextProps.uuidSelected }
      );
    }

    // Rétablit le store dans le state
    if (!isSame(collectionNameStore.detail, prevState.storeDetail)) {
      nextState = {
        ...nextState,
        detail: collectionNameStore.detail
          ? collectionNameStore.detail
          : CreateUpdate.getDefautDetail(nextProps),
        storeDetail: collectionNameStore.detail,
      };
    }

    if (CreateUpdate.hasDefaultValues(nextProps)) {
      // Initialise le state avec la valeur par défaut quand elle est initialisée
      const defaultValues = CreateUpdate.getDefaultValues(nextProps);
      Object.keys(defaultValues).forEach((key) => {
        if (
          (prevState.detail[key] === null || prevState.detail[key] === undefined) &&
          (prevState.prevDefault === null ||
            (prevState.prevDefault[key] === null || prevState.prevDefault[key] === undefined)) &&
          defaultValues[key] !== null
        ) {
          nextState = {
            ...nextState,
            detail: {
              [key]: defaultValues[key],
              ...nextState.detail,
            },
          };
        }
      });
      nextState = {
        ...nextState,
        prevDefault: defaultValues,
      };
    }

    // Ne pas mettre a jour le state par ce biais si les props n'ont pas changé.
    // Cette méthode est appelée suite a l'update du state également.
    if (isSame(nextState.prevProps, prevState.prevProps)) {
      nextState = null;
    }

    return nextState;
  }

  static getDefaultValues(props) {
    if (props.defaultValuesCallback) {
      return props.defaultValuesCallback();
    }
    if (props.defaultValues) {
      return props.defaultValues;
    }
    return {};
  }

  static getDefautDetail(props) {
    return CreateUpdate.hasDefaultValues(props)
      ? CreateUpdate.getDefaultValues(props)
      : {};
  }

  static hasDefaultValues(props) {
    return props.defaultValuesCallback || props.defaultValues;
  }

  /**
   * Handler du changement sur les inputs
   * @param name
   * @param event
   * @param cb
   */
  onChangeHandler(name, event, cb) {
    const value = event.target.value;
    // Met à jour le state avec la valeur saisie
    this.setState(
      {
        detail: {
          ...this.state.detail,
          [name]: value,
        },
      },
      () => {
        // Notifie de la mise à jour du state
        if (cb) cb();
      }
    );
  }

  createUpdateModalGetNextState(detail)
  {
    const {
      createUpdateModalGetNextState,
    } = this.props;

    if (createUpdateModalGetNextState) {
      return createUpdateModalGetNextState(detail);
    }
    return {};
  }

  /**
   * Handler sur la modification
   * @return {[type]} [description]
   */
  onSubmitHandler(event, duplicate = false) {
    const {
      extradatasForm,
      collectionName,
      dispatch,
      actionsCallback,
      submitHandlerCreateUpdate,
      resetSelectedUuid,
    } = this.props;
    let data = this.state.detail;
    if (!!extradatasForm) {
      data = { ...data, ...extradatasForm };
    }

    if (submitHandlerCreateUpdate) {
      submitHandlerCreateUpdate(data, (resetLocal) => {
        this.props.onCloseHandler(event, "SUBMIT");
        if(resetLocal){
          const nextStateDetail = duplicate
                    ? this.createUpdateModalGetNextState(null)
                    : { reference: undefined };
          this.setState({
            detail: {
              ...this.state.detail,
              ...nextStateDetail,
            },
          });
        }
      });
    } else {
      collectionActions(
        dispatch,
        collectionName,
        !!data.uuid ? "UPDATE" : "CREATE",
        data,
        (detail) => {
          if (detail) {
            // Ferme la modale, sauf si l'utilisateur veut poursuivre avec un duplicat
            if (!duplicate) {
              this.props.onCloseHandler(event, "SUBMIT");
            }

            if(duplicate){
              resetSelectedUuid()
            }

            // Notifie le composant parent
            if (actionsCallback) {
              actionsCallback(!!data.uuid ? "update" : "create", detail, duplicate);
            }
            // Met à jour le state par différence avec le duplicat
            const nextStateDetail = duplicate
                  ? this.createUpdateModalGetNextState(detail)
                  : { reference: undefined };
            this.setState({
              detail: {
                ...this.state.detail,
                ...nextStateDetail,
              },
            });
          } else if(actionsCallback) {
            actionsCallback("error")
          }
        }
      );
    }
  }

  onNextHandler(event) {
    this.onSubmitHandler(event, true);
  }

  /**
   * Permet d'initialiser le state par défaut en cas de valeur préchargés
   * @param  {[type]} data [description]
   * @return {[type]}      [description]
   */
  initialize(data) {
    // eslint-disable-next-line
    this.state.detail = { ...this.state.detail, ...data };
  }

  /**
   * retourne le formulaire ou un loader
   * @return {[type]} [description]
   */
  getFormOrLoader() {
    const {
      collectionsStore,
      collectionName,
      createUpdateModalContent,
      customContext,
    } = this.props;
    let collectionNameStore = collectionsStore[collectionName];
    let { detail } = collectionNameStore;
    if ((!detail && collectionNameStore.fetching) || !this.state.detail)
      return <CircularProgress className={this.props.classes.progress} />;
    else {
      return createUpdateModalContent(
        this.state.detail,
        collectionNameStore,
        this.onChangeHandler.bind(this),
        this.initialize.bind(this),
        customContext
      );
    }
  }

  /**
   * Retourne le contenu de la modal
   * @return {[type]} [description]
   */
  getModalContent() {
    const { collectionsStore, collectionName, createUpdateModalTitle, modalContentStyle } =
      this.props;

    let collectionNameStore = collectionsStore[collectionName];
    let { detail } = collectionNameStore;

    return [
      <DialogTitle key="title" id="alert-dialog-slide-title">
        {createUpdateModalTitle(detail)}
      </DialogTitle>,
      <DialogContent
        key="content"
        style={modalContentStyle}
      >
          {this.getFormOrLoader()}
      </DialogContent>,
    ];
  }

  /**
   * Rendu Final
   * @return {[type]} [description]
   */
  render() {
    const {
      createUpdateModalNext,
      createUpdateModalSubmit,
      modalMaxWidth,
      onCloseHandler,
      openModal,
      disabledEnterModal,
    } = this.props;

    if (!openModal) return null;

    return (
      <Modal
        actionNext={createUpdateModalNext}
        actionSubmit={createUpdateModalSubmit}
        openModal={openModal}
        onCloseHandler={onCloseHandler}
        onNextHandler={this.onNextHandler.bind(this)}
        onSubmitHandler={this.onSubmitHandler.bind(this)}
        fullWidth={true}
        maxWidth={!!modalMaxWidth ? modalMaxWidth : "sm"}
        disabledEnter={disabledEnterModal}
        fullScreen={this.props.fullScreen}
        hideScroll={this.props.hideScroll}
      >
        {this.getModalContent()}
      </Modal>
    );
  }
}

CreateUpdate.propTypes = {
  openModal: PropTypes.bool,
  uuidSelected: PropTypes.string,
  onCloseHandler: PropTypes.func.isRequired,
  collectionName: PropTypes.string.isRequired,
  createUpdateModalContent: PropTypes.func,
  createUpdateModalGetNextState: PropTypes.func,
  createUpdateModalNext: PropTypes.string,
  createUpdateModalSubmit: PropTypes.string,
  createUpdateModalTitle: PropTypes.func,
  extradatasForm: PropTypes.object,
  actionsCallback: PropTypes.func,
  modalMaxWidth: PropTypes.string,
  disabledEnterModal: PropTypes.bool,
  defaultValues: PropTypes.object,
  defaultValuesCallback: PropTypes.func,
  fullScreen: PropTypes.bool,
  customContext: PropTypes.object,
  resetSelectedUuid: PropTypes.func,
  modalContentStyle: PropTypes.object,
  disableLoadCollectionFromDerivedState: PropTypes.bool,
  hideScroll: PropTypes.bool,
};

CreateUpdate = connect((store) => {
  return {
    collectionsStore: store.collections,
  };
})(CreateUpdate);

export default withStyles((theme) => ({}))(CreateUpdate);
