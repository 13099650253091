import React, { Component } from "react";
import { connect } from "react-redux";
import DocumentPickerCss from "./css/DocumentPickerCss";
import { withStyles } from "@material-ui/core/styles/index";
import {
  AppBar,
  DialogContent,
  DialogTitle,
  Grid,
  IconButton,
  MenuItem,
  Tab,
  Tabs,
  Typography,
} from "@material-ui/core";
import { Delete, Edit, FileCopy, PhotoCamera, ZoomIn, ZoomOut } from "@material-ui/icons";
import Modal from "../Modal";
import {
  collectionActions,
  loadCollectionAttribute,
} from "../../../../reducers/collectionsReducer";
import TextInput from "../TextInput";
import SwipeableViews from "react-swipeable-views";
import { FileUploader } from "react-drag-drop-files";

class DocumentPicker extends Component {
  state = {
    openModal: false,
    loading: false,
    currentTypeDocument: null,
    selectedPhotoTab: null,
    openDeleteModal: false,
    documentCurrent: null,
    openShowModal: false,
    openEditModal: false,
    zoomRatio: 1,
  };
  componentDidMount() {
    const { typeDocumentStore, dispatch } = this.props;
    loadCollectionAttribute(dispatch, "list", 'type_documents', typeDocumentStore);
  }

  onCloseHandler() {
    this.setState({
      openModal: false,
      selectedPhotoTab: null,
      currentTypeDocument: null,
      openDeleteModal: false,
      openShowModal: false,
      documentCurrent: null,
    });
  }

  onDropHandler(files) {
    // eslint-disable-next-line
    for (const [key, value] of Object.entries(files)) {
      this.sendFile(value)
    }
  }

  sendFile(file, promises = []){
    const { documentable_uuid, documentable_type } = this.props;
    const { currentTypeDocument } = this.state;

      let filename = file.name;
      const formData = new FormData();
      formData.append("documentable_type", documentable_type);
      formData.append("documentable_uuid", documentable_uuid);
      formData.append("filename", filename);
      formData.append("type_document_uuid", currentTypeDocument);
      formData.append("file", file);
      promises.push(
        new Promise((resolve) => {
          collectionActions(this.props.dispatch, "documents", "CREATE", formData,resolve);
        })
      );

    return promises;
  }

  getTopBodyModal() {
    const { classes, typeDocumentStore } = this.props;
    const { currentTypeDocument } = this.state;
    return (
      <Grid container key="top-body">
        <Grid item xs={12} lg={12}>
          <TextInput
            label="Type de Document"
            className={classes.selectContainer}
            value={currentTypeDocument}
            margin="normal"
            collectionStore={typeDocumentStore}
            onChangeHandler={(name, e) =>
              this.setState({ currentTypeDocument: e.target.value })
            }
            fullWidth
            select
          >
            {this.getTypesDocumentOptions()}
          </TextInput>
        </Grid>
        <Grid item xs={6}>
          <FileUploader
              name="file"
              multiple={true}
              disabled={!currentTypeDocument}
              label="Déposez vos fichier a uploader ici"
              onDrop={(files) => this.setState({files: null},() => {
                this.onDropHandler(files);
              })}
              onSelect={(files) => this.setState({files: null},() => {
                this.onDropHandler(files);
              })}
              classes={classes.dragrable}
              maxSize={50}
              onSizeError={(error) => {
                alert('Le fichier est trop volumineux (max 50Mo)');
              }}
            />
        </Grid>
        <Grid item xs={6} lg={3}  className={classes.iconContainer}>
          <IconButton
            disabled={!currentTypeDocument}
            variant="contained"
            component="label"
            className={classes.photoBtn}
          >
            <PhotoCamera fontSize="large" />
            <input
              type="file"
              accept="image/*"
              capture="environment"
              hidden
              onChange={this.onChangeFile.bind(this)}
            />
          </IconButton>
        </Grid>
        <Grid item xs={6} lg={3} className={classes.iconContainer}>
          <IconButton
            disabled={!currentTypeDocument}
            variant="contained"
            component="label"
            className={classes.photoBtn}

          >
            <FileCopy fontSize="large" />
            <input
              type="file"
              hidden
              multiple
              onChange={this.onChangeFile.bind(this)}
            />
          </IconButton>
          <Typography variant="caption">
            En cas de sélection multiple, les photos/documents auront toutes le même type
          </Typography>
        </Grid>
        <Grid item xs={12} lg={12}>
          <hr />
        </Grid>
      </Grid>
    )
  }

  displayDocument(document, classe){
    const { classes } = this.props;
    return (
      <object
          key={document.uuid}
          title={document.type_document_libelle}
          data={document.url}
          className={classes[classe]}
        >
          <p>Aucun aperçu disponible ici</p>
        </object>
    )
  }

  showThumbnail(obj, key) {
    const { classes } = this.props;

    const document = obj
    if(!document) return <p>Document absent</p>;

    const allowed_types = ["image/", "application/pdf"];

    if(!allowed_types.some((mime_type) => {
      return document.mime.startsWith(mime_type)
    })) return this.getPreviewDocuments(document);

    return (
        <div
          className={classes.hover}
          onClick={(e) => {
            e.stopPropagation();
              if(document.is_image){
                this.setState({
                  openShowModal: true,
                  documentCurrent: document,
                });
              }else{
                window.open(document.url, '_blank');
              }
          }}
        >

        {this.displayDocument(document, "imageApercu")}
        <Typography variant="caption">
            {document.filename}
        </Typography>
        </div>
    )
  }

  getDocuments(typeDoc){
    const { documentsStore, classes } = this.props;

    const list = documentsStore.list.filter((document) => document.type_document_libelle === typeDoc || typeDoc === 'Toutes');

    return (
      <div className={classes.documentContainer}>
        {list.map((document) => {
          return (
            <div>
              <div
                className={classes.actionContainer}
                key={document.uuid}
                onClick={() => {
                  if(document.is_image){
                    this.setState({
                      openShowModal: true,
                      documentCurrent: document,
                    });
                  }else{
                    window.open(document.url, '_blank');
                  }
                }}
                >
                {this.getActionsBtn(document)}
                <div className={classes.aperçuObject} >
                  {this.showThumbnail(document, document.uuid)}
                </div>
              </div>
            </div>
          );
        })}
      </div>
    )
  }
  getActionsBtn(document){
    const { classes } = this.props;
    if(document.is_system) return null;
    return (
      <div  className={classes.btnContainer}>
       <IconButton
          className={classes.editBtn}
          onClick={(e) => {
            e.stopPropagation();
            this.setState({
              openEditModal: true,
              documentCurrent: document,
            });
          }}
        >
          <Edit/>
        </IconButton>
        <IconButton
          className={classes.deleteBtn}
          onClick={(e) => {
            e.stopPropagation();
            this.setState({
              openDeleteModal: true,
              documentCurrent: document,
            });
          }}
        >
          <Delete/>
        </IconButton>
        </div>
    )
  }

  getPreviewDocuments(document) {
    const { classes } = this.props;
    if(document.is_image){
      return (
        <img
          src={document.url}
          alt={document.filename}
          className={classes.photoImg}
        />
      )
    }else{
      return (
        <Typography variant="caption">
          {document.filename}
        </Typography>
      )
    }
  }


  getListDocuments() {
    const { documentsStore, classes } = this.props;
    const { loading, selectedPhotoTab } = this.state;
    const list = documentsStore.list;
    if(loading){
      return <Typography variant="caption">Chargement en cours...</Typography>
    }
    if (!list) return null;

    const typesDocuments = [
      'Toutes',
    ].concat(list.map((document) => document.type_document_libelle).filter((x, i, a) => a.indexOf(x) === i));

    if(!selectedPhotoTab && typesDocuments.length > 0){
      this.setState({
        selectedPhotoTab: typesDocuments[0]
      })
    }

    return (
      <Grid container className={classes.container}>
         <AppBar position="static" color="default">
          <Tabs
                value={selectedPhotoTab}
                onChange={(e,name)  => {
                  this.setState({
                    selectedPhotoTab: name,
                  });
                }}
                indicatorColor="primary"
                textColor="primary"
                variant="scrollable"
                scrollButtons="auto"
              >
              {typesDocuments.map((typeDocument) => {
                return <Tab label={typeDocument} value={typeDocument} key={typeDocument}/>
              })}
            </Tabs>
         </AppBar>
         <SwipeableViews axis={'x'} index={typesDocuments.indexOf(selectedPhotoTab)} >
          {typesDocuments.map(typeDoc => {
            return (
              <Typography component="div" key={typeDoc} dir={'ltr'} style={{padding: 8 * 3}}>
                <Grid item xs={12}>
                  {this.getDocuments(typeDoc)}
                </Grid>
              </Typography>
            )
          })}
        </SwipeableViews>
      </Grid>
    )
  }


  getModalBodyContent() {

    return [
      this.getTopBodyModal(),
      this.getListDocuments()
    ];
  }

  onChangeFile(e) {
    const files = e.target.files;
    let promises = [];
    for (let i = 0; i < files.length; i++) {
      const file = files[i];
      promises = this.sendFile(file, promises);
    }

    Promise.all(promises).then(() => {
      this.fetchDocuments();
    });
  }

  getTypesDocumentOptions() {
    const { typeDocumentStore } = this.props;
    if (!typeDocumentStore.list) return [];

    return typeDocumentStore.list
      .map((typeDocument) => {
          return (
            <MenuItem value={typeDocument.uuid} key={typeDocument.uuid}>
              {typeDocument.libelle}
            </MenuItem>
          );
      });
  }

  getHeaderModal(){
    return (
      <DialogTitle key="title">
        <Typography
          style={{
            display: "flex",
            alignItems: "center",
            flexDirection: "row",
          }}
        >
          <PhotoCamera />
           <span style={{marginLeft:'0.5rem'}} >Module Documents</span>
        </Typography>
      </DialogTitle>
    )
  }
  getModalContent() {
    return [
     this.getHeaderModal(),
      <DialogContent key="content">{this.getModalBodyContent()}</DialogContent>,
    ];
  }

  fetchDocuments() {
    const { dispatch, documentable_uuid, documentable_type } = this.props;

    this.setState({ loading: true });
    collectionActions(dispatch, "documents", "INDEX", {
      params: {
        documentable_type: documentable_type,
        documentable_uuid: documentable_uuid,
      },
    }).then(() => this.setState({ loading: false }));
  }

  onClickIconButton() {
    this.setState({ openModal: true });
    this.fetchDocuments();
  }

  onSubmitDeleteHandler() {
    const { dispatch } = this.props;
    const { documentCurrent } = this.state;

    collectionActions(dispatch, "documents", "DELETE", {uuid:documentCurrent.uuid}).then(() => {
      this.setState({
        openDeleteModal: false,
        documentCurrent: null,
      });
      this.fetchDocuments();
    });
  }

  getModalDeleteContent(){
    return [
      <DialogTitle key="title">
        <Typography
          style={{
            display: "flex",
            alignItems: "center",
            flexDirection: "row",
          }}
        >
          <Delete />
          Confirmation de suppression
        </Typography>
      </DialogTitle>,
      <DialogContent key="content">
        <Typography>
          Voulez-vous vraiment supprimer ce document ?
        </Typography>
      </DialogContent>,
    ]
  }

  getModalShowContent(){
    const { documentCurrent, zoomRatio } = this.state;
    if(!documentCurrent) return null;

    const { classes } = this.props;

    return [
      <DialogTitle key="title">
        <Typography
          style={{
            display: "flex",
            alignItems: "center",
            flexDirection: "row",
          }}
        >
          <PhotoCamera />
          {documentCurrent.filename}
        </Typography>
      </DialogTitle>,
      <DialogContent
        key="content"
        style={{
          display: "flex",
          alignItems: "center",
          flexDirection: "row",
          justifyContent: "center",
        }}
      >
        <div className={classes.zoomContainer}>
          <IconButton
            onClick={() => this.setState({ zoomRatio: zoomRatio + 0.1 })}
          >
            <ZoomIn />
          </IconButton>
          <IconButton
            onClick={() => this.setState({ zoomRatio: zoomRatio - 0.1 })}
          >
            <ZoomOut />
          </IconButton>
        </div>
        <img
          src={documentCurrent.url}
          alt={documentCurrent.filename}
          style={{
            maxWidth:'100%',
            transform: 'scale('+(1 * zoomRatio)+')',
          }}
        />
      </DialogContent>,
    ]
  }

  getModalEditContent(){
    const { classes, typeDocumentStore } = this.props;
    const { documentCurrent } = this.state;

    if(!documentCurrent) return null;
    return [
      <DialogTitle key="title">
        <Typography
          style={{
            display: "flex",
            alignItems: "center",
            flexDirection: "row",
          }}
        >
          <Edit />
          Modification du document
        </Typography>
      </DialogTitle>,
      <DialogContent key="content">
        <Grid item xs={12} lg={12}>
          <TextInput
            label="Type de Document"
            className={classes.selectContainer}
            value={documentCurrent.type_document_uuid}
            margin="normal"
            name="type_document_uuid"
            collectionStore={typeDocumentStore}
            onChangeHandler={(name, e) =>
              this.setState({ documentCurrent: {
                  ...documentCurrent,
                  type_document_uuid: e.target.value
              } })
            }
            fullWidth
            select
          >
            {this.getTypesDocumentOptions()}
          </TextInput>
        </Grid>
      </DialogContent>,
    ]
  }

  onSubmitEditHandler(){

    const { dispatch } = this.props;
    const { documentCurrent } = this.state;

    collectionActions(dispatch, "documents", "UPDATE", documentCurrent).then(() => {
      this.setState({
        openEditModal: false,
        documentCurrent: null,
      });
      this.fetchDocuments();
    });

  }
  /**
   * Rendu Final
   * @return {[type]} [description]
   */
  render() {
    const { openModal, openDeleteModal, openShowModal, openEditModal } = this.state;
    const { classes, hasDocument } = this.props;

    return (
      <>
        <IconButton
          onClick={(e) => {
            e.stopPropagation();
            this.onClickIconButton();
          }}
          className={hasDocument ? classes.hasDocument : ""}
        >
          <PhotoCamera fontSize="large" color='red'/>
        </IconButton>
        <Modal
          openModal={openModal}
          onCloseHandler={this.onCloseHandler.bind(this)}
          onSubmitHandler={this.onCloseHandler.bind(this)}
          fullScreen={true}
          children={this.getModalContent()}
        />
        <Modal
          openModal={openDeleteModal}
          onCloseHandler={() => this.setState({ openDeleteModal: false, documentCurrent: null })}
          onSubmitHandler={this.onSubmitDeleteHandler.bind(this)}
          children={this.getModalDeleteContent()}
        />
        <Modal
          openModal={openEditModal}
          onCloseHandler={() => this.setState({ openEditModal: false, documentCurrent: null })}
          onSubmitHandler={this.onSubmitEditHandler.bind(this)}
          children={this.getModalEditContent()}
        />
        <Modal
          openModal={openShowModal}
          fullScreen={true}
          onCloseHandler={() => this.setState({ openShowModal: false, documentCurrent: null, zoomRatio: 1 })}
          onSubmitHandler={() => this.setState({ openShowModal: false, documentCurrent: null, zoomRatio: 1 })}
          children={this.getModalShowContent()}
        />
      </>
    );
  }
}

DocumentPicker = connect((store) => {
  return {
    typeDocumentStore: store.collections.type_documents,
    documentsStore: store.collections.documents,
  };
})(DocumentPicker);
export default withStyles(DocumentPickerCss)(DocumentPicker);
