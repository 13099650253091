export default theme => ({
  root: {
    flexGrow: 1
  },
  button: {
    float: 'right',
    padding: 12
  },
  crud_td: {
    minWidth: '144px'
  },
  crud_td_placeholder: {
    height: '48px',
  },
  addButton: {
    marginLeft: '2em',
    marginTop: '0.5em',
    marginRight: '0.5em',
    color: '#FFFFFF'
  },
  addButtonIcon: {
    fontSize: '32px'
  },
  error: {
    color: theme.palette.error.main
  },
  divider: {
    marginTop: '2em',
    marginBottom: '2em'
  },
  loader: {
    position: 'absolute',
    top: '8em',
    left: '50%',
  },
  hideScroll: {
    overflow: 'hidden'
  },
});
