export default theme => ({
	root: {
		flexGrow: 1
	},
	button: {
		float: 'right'
	},
	addButton: {
		marginLeft: '2em',
		marginTop: '0.5em',
		marginRight: '0.5em'
	},
	addButtonIcon: {
		fontSize: '32px'
	},
	error: {
		color: theme.palette.error.main
	},
	divider: {
		marginTop: '2em',
		marginBottom: '2em'
	}
});
