export default theme => ({
  root: {
    flexGrow: 1
  },
  crudTitle: {
    float: 'left',
    marginBottom: 0,
    padding: '8px 0',
    color: theme.palette.primary.main,
  },
  button: {
    float: 'right'
  },
  addButton: {
    marginLeft: '2em',
    marginTop: '0.5em',
    marginRight: '0.5em'
  },
  addButtonIcon: {
    fontSize: '32px'
  },
  error: {
    color: theme.palette.error.main
  },
  selectContainer: {
    width: 'calc(100% - 1em)'
  },
  container: {
    alignItems: 'end',
  },
});
