import React, { Component } from "react";
import { connect } from "react-redux";
import { withStyles } from "@material-ui/core/styles";
import {
  Tab,
  Tabs,
  Typography,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  CircularProgress,
  Grid,
  Button,
  MenuItem,
} from "@material-ui/core";
import EmailButton from "../Email";
import { Delete, Send, Warning, Close, Info, EuroSymbol} from "@material-ui/icons";

import {
  collectionActions,
  updateAffaireFacturation,
  getEntreprisesBorderauAchat,
  loadCollectionAttribute,
  sendToMeg,
} from "../../../reducers/collectionsReducer";

import Affaire from "../Affaire";
import Bordereau from "./Bordereau";
import FacturationCss from "./css/FacturationCss";

import CheckboxInput from "../../common/Components/CheckboxInput";
import { hasRights } from "../../common/Tools/Tools";
import FacturationAnnexe from "./FacturationAnnexe";
import { getFromLocalStorage, storeToLocalStorage } from "../../tools/Tools";
import ExportTransporteurGazoil from "../ExportTransporteurGazoil";
import Proforma from "./Profmas";
import TextInput from "../../common/Components/TextInput";

function TabContainer(props) {
  return (
    <Typography component="div" style={{ width: "100%" }}>
      {props.children}
    </Typography>
  );
}

const TAB_ENTRANTE = "entrante";
const TAB_SORTANTE = "sortante";
const TAB_BORDEREAU_ACHAT = "bordereau";
const TAB_FACTURATION_ANNEXE = "facturation_annexe";
const TAB_PROFORMA = "proforma";

class Facturation extends Component {
  state = {
    selectedTab: null,
    entreprises: null,
    selectedTabEntreprise: null,
    changingPage: false,
    selectedAffaireMeg: [],
    megAction: null,
    meg_with_facture_annexe: false,
  };

  componentDidMount() {
    const { dispatch } = this.props;
    collectionActions(dispatch, "affaires", "RESET_DETAIL");

    const facturation_tab_local = getFromLocalStorage("facturation_tab");
    getEntreprisesBorderauAchat().then((response) => {
      this.setState(
        {
          entreprises: response.data,
          selectedTabEntreprise:
            this.state.selectedTabEntreprise || response.data[0].uuid,
        },
        () => {

          if(facturation_tab_local){
            this.setState(
              {
                selectedTab: facturation_tab_local.selectedTab,
                selectedTabEntreprise: facturation_tab_local.selectedTabEntreprise,
              },
              () => {
                this.changePage(this.state.selectedTab.selectedTab);
              }
            );
          }else if (hasRights(["admin-facturation-entrante"], this.props.user)) {
            this.props.history.push("/facturation/entrante/" + this.state.selectedTabEntreprise);
            this.setState({
              selectedTab: TAB_ENTRANTE,
            });
          } else if (
            hasRights(["admin-facturation-sortante"], this.props.user)
          ) {
            this.props.history.push("/facturation/sortante/" + this.state.selectedTabEntreprise);
            this.setState({
              selectedTab: TAB_SORTANTE,
            });
          } else if (
            hasRights(["admin-facturation-bordereau"], this.props.user)
          ) {
            this.props.history.push("/facturation/bordereau_achat/" + this.state.selectedTabEntreprise);
            this.setState({
              selectedTab: TAB_BORDEREAU_ACHAT,
            });
          } else if (
            hasRights(["admin-crud-facturation-annexe"], this.props.user)
          ) {
            this.props.history.push("/facturation/facturation_annexe/" + this.state.selectedTabEntreprise);
            this.setState({
              selectedTab: TAB_FACTURATION_ANNEXE,
            });
          }else if (
            hasRights(["admin-proforma"], this.props.user)
          ) {
            this.props.history.push("/facturation/proforma/" + this.state.selectedTabEntreprise);
            this.setState({
              selectedTab: TAB_PROFORMA,
            });
          }
        }
      );
    });
    loadCollectionAttribute(dispatch, "list", "etats", this.props.etatsStore);
  }
  /**
   * Handler sur le click d'une des checkboxs
   * @param {*} affaire_uuid
   * @param {*} name
   * @param {*} event
   */
  onChangeHandlerFacturation(affaire_uuid, name, event) {
    updateAffaireFacturation(this.props.dispatch, affaire_uuid, {
      [name]: event.target.value,
      type: this.state.selectedTab === TAB_ENTRANTE ? "entrante" : "sortante",
    });
  }

  getCheckBox(field, affaire) {
    return (
      <CheckboxInput
        id={field + affaire.uuid}
        value={affaire[field]}
        margin="normal"
        name={field}
        onChangeHandler={this.onChangeHandlerFacturation.bind(
          this,
          affaire.uuid
        )}
        disabled={
          field === "facture_transporteur" &&
          (affaire.type_vente_depart || affaire.type_achat_rendu)
        }
      />
    );
  }

  /**
   * Configurations des cells pour le datatable
   */
  getFactEntranteCellsConfig() {
    return [
      {
        datakey: "reference_interne",
        title: "Référence",
        sortable: true,
        searchable: true,
      },
      {
        datakey: "date_enlevement",
        title: "Enlèvement",
        sortable: true,
        searchable: true,
        isDate: true,
        format: (obj, key) => {
          return this.getDate(obj[key]);
        },
      },
      {
        datakey: "fournisseur_nom",
        title: "Fournisseur",
        sortable: true,
        searchable: true,
      },
      {
        datakey: "transporteur_nom",
        title: "Transporteur",
        sortable: true,
        searchable: true,
      },
      {
        datakey: "etat_nom",
        title: "État",
        sortable: true,
        searchable: true,
      },
      {
        datakey: "facture_transporteur",
        title: "Transp.",
        sortable: true,
        searchable: false,
        format: this.getCheckBox.bind(this, "facture_transporteur"),
      },
      {
        datakey: "prix_transport_achat",
        title: "Trans. Achat (€)",
        sortable: true,
        searchable: true,
      },
      {
        datakey: "facture_fournisseur",
        title: "Fourn.",
        sortable: true,
        searchable: false,
        format: this.getCheckBox.bind(this, "facture_fournisseur"),
      },
      {
        datakey: "ca_achat",
        title: "Articles Achat (€)",
        sortable: false,
        searchable: false,
      },
      {
        datakey: "has_proforma_received",
        title: "Proforma",
        sortable: true,
        searchable: true,
        format: (obj, key) => {
          if (obj.has_proforma_received) {
            return (
              <span title="Au moins un proformat ouvert">
                <EuroSymbol style={{ color: obj.color_proforma_received ?? '' }} />
              </span>
            );
          } else {
            return null;
          }
        },
      },
      {
        datakey: "facturation_commentaire",
        title: "Commentaire",
        sortable: true,
        searchable: false,
      },
    ];
  }

  formatLots(affaire) {
    if (!affaire.lots || affaire.lots.length === 0)
      return "Aucun Article de saisi.";
    else {
      const { classes } = this.props;
      let totalVente = 0;
      return (
        <Table size="small">
          <TableHead>
            <TableRow>
              <TableCell>Article</TableCell>
              <TableCell>Famille</TableCell>
              <TableCell>Conditionnement</TableCell>
              <TableCell align="right">Poids Vente</TableCell>
              <TableCell align="right">Prix Unit. Vente</TableCell>
              <TableCell align="right">Prix Vente</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {affaire.lots.map((lot) => {
              totalVente += lot.prix_vente;
              return (
                <TableRow key={lot.uuid}>
                  <TableCell>{lot.article_nom}</TableCell>
                  <TableCell>{lot.article_famille_nom}</TableCell>
                  <TableCell>{lot.conditionnement_nom}</TableCell>
                  <TableCell align="right">
                    {lot.poids_vente ? lot.poids_vente : 0} T
                  </TableCell>
                  <TableCell align="right">
                    {lot.prix_unitaire_vente} €
                  </TableCell>
                  <TableCell align="right">{lot.prix_vente} €</TableCell>
                </TableRow>
              );
            })}
            <TableRow>
              <TableCell className={classes.noborderbottom} colSpan={2} />
              <TableCell className={classes.noborderbottom} align="right">
                Total Vente
              </TableCell>
              <TableCell className={classes.noborderbottom} align="right">
                {totalVente} €
              </TableCell>
            </TableRow>
          </TableBody>
        </Table>
      );
    }
  }

  formatEtat(affaire) {
    const { classes } = this.props;

    return (
      <div className={classes.EtatContainer}>
        <div className={classes.EtatLabel}>{affaire.etat_nom}</div>
        <EmailButton
          className={classes.emailButtonSortant}
          email={affaire.email_rendered}
          key={affaire.uuid + "_facturation_sortante"}
          required={""}
          label={"Demande de poids"}
          onSent={(email) => {
            // on met à jour la ligne dans la collection
            this.props.dispatch({
              type: "UPDATED",
              collection: "affaires",
              payload: {
                ...affaire,
                email_rendered: email,
              },
            });
          }}
        />
      </div>
    );
  }

  onChangeSelectMeg(affaire) {
    const { selectedAffaireMeg } = this.state;
    let newSelectedAffaireMeg = [];
    let index = -1;
    selectedAffaireMeg.forEach((affaire_selected, i) => {
      if (affaire_selected.uuid === affaire.uuid) {
        index = i;
      }
    })
    if (index === -1) {
      newSelectedAffaireMeg = [...selectedAffaireMeg, affaire];
    } else {
      selectedAffaireMeg.splice(index, 1);
      newSelectedAffaireMeg = [...selectedAffaireMeg];
    }
    this.setState({selectedAffaireMeg: newSelectedAffaireMeg})
  }

  isAffaireSelectedMeg(affaire) {
    const { selectedAffaireMeg } = this.state;
    let index = -1;
    selectedAffaireMeg.forEach((affaire_selected, i) => {
      if (affaire_selected.uuid === affaire.uuid) {
        index = i;
      }
    })
    return index !== -1;
  }

  getStatusClient(affaire) {
    const { classes } = this.props;

    if(!affaire.client_meg_facturation_auto){
      return (
        <span title="Facturation Automatique MEG désactivé"><Close className={classes.warning_code_client_meg} /></span>
      )
    }
    else if(!affaire.client_code_client_meg){
      return (
        <span title="Code client MEG Absent"><Warning className={classes.warning_code_client_meg} /></span>
      )
    }else{
      return null;
    }

  }

  getFactSortanteCellsConfig() {
    return [
      {
        datakey: "fake_osef",
        title: "",
        sortable: false,
        searchable: false,
        format: (affaire) => {
          if(!affaire.etat_is_attente) return null;
          return (
            <CheckboxInput
              id={"meg" + affaire.uuid}
              value={this.isAffaireSelectedMeg(affaire)}
              margin="normal"
              disabled={!affaire.can_send_to_meg}
              onChangeHandler={this.onChangeSelectMeg.bind(this, affaire)}
            />
          )
        },
      },
      {
        datakey: "reference_interne",
        title: "Référence",
        sortable: true,
        searchable: true,
        format: (affaire) => {
          const complement_meg_info = affaire.has_meg_complement ? (<span title="Complément description ou référence MEG présent sur l'affaire" style={{
            color: 'lightgrey',
          }}>
            <Info />
          </span>) : null;
          return (
            <div style={{position:'relative'}}>
              <span>{affaire.reference_interne}</span>
              {complement_meg_info}
            </div>
          )
        }
      },
      {
        datakey: "reference_client",
        title: "Référence Client",
        sortable: true,
        searchable: true,
      },
      {
        datakey: "date_livraison",
        title: "Livraison",
        sortable: true,
        searchable: true,
        isDate: true,
        format: (obj, key) => {
          return this.getDate(obj[key]);
        },
      },
      {
        datakey: "date_enlevement",
        title: "Enlèvement",
        sortable: true,
        searchable: true,
        isDate: true,
        format: (obj, key) => {
          return this.getDate(obj[key]);
        },
      },
      {
        datakey: "client_nom",
        title: "Client",
        sortable: true,
        searchable: true,
        format: (affaire) => {
          return (<div style={{position:'relative'}}>
            { this.getStatusClient(affaire) }
            <span>{affaire.client_nom}</span>
          </div>);
        },
      },
      {
        datakey: "facturation_commentaire",
        title: "Commentaire",
        sortable: true,
        searchable: false,
      },
      {
        datakey: "etat_nom",
        title: "État",
        sortable: true,
        searchable: true,
        format: this.formatEtat.bind(this),
        useDataKeyOnSearch: true,
      },
      {
        datakey: "lots",
        title: "Articles",
        sortable: false,
        searchable: false,
        format: this.formatLots.bind(this),
      },
      {
        datakey: "ca_vente",
        title: "Articles Vente (€)",
        sortable: false,
        searchable: false,
      },
      {
        datakey: "marge",
        title: "Marge (€)",
        sortable: false,
        searchable: false,
        format: (affaire) => {
          let classes = this.props.classes;
          return (
            <span className={affaire.marge <= 0 ? classes.error : null}>
              {affaire.marge}
            </span>
          );
        },
      },
      {
        datakey: "factures_annexe_facturation_sortante",
        title: "Factures Annexe",
        sortable: false,
        searchable: false,
        format: this.getFacturesAnnexe.bind(this),
      },
      {
        datakey: "meg_status",
        title: "MEG",
        sortable: true,
        searchable: false,
        format: (affaire) => {
          if(affaire.meg_status === "brouillon"){
            return (
              <span title="Statut Brouillon">B</span>
            )
          }
          return null;
        },
      },
      {
        datakey: "facture_client",
        title: "Client",
        sortable: false,
        searchable: false,
        format: this.getCheckBox.bind(this, "facture_client"),
      },
      {
        dataKey: "has_proforma_emited",
        title: "Proforma",
        sortable: true,
        searchable: true,
        format: (obj, key) => {
          if (obj.has_proforma_emited) {
            return (
              <span title="Au moins un proformat ouvert">
                <EuroSymbol style={{ color: obj.color_proforma_emited ?? '' }} />
              </span>
            );
          } else {
            return null;
          }
        },
      }

    ];
  }

  getFacturesAnnexe(affaire) {
    const { classes } = this.props;
    if(affaire.factures_annexe_facturation_sortante.nb_factures > 0) {
      const className = affaire.factures_annexe_facturation_sortante.has_unpaid ? classes.unpaid_factures : null;
      return (
        <span
          title={`${affaire.factures_annexe_facturation_sortante.nb_factures} Facture(s) annexe`}
          className={classes.cursor+' '+className}
        >
          <Warning />
        </span>
      );
    }

    return null
  }

  getFacturationEntrante() {
    const { classes } = this.props;
    const { selectedTabEntreprise } = this.state;
    return (
      <TabContainer>
        <Grid item xs={6} style={{marginTop:'0.5rem'}}>
          <ExportTransporteurGazoil />
        </Grid>
        <Affaire
          delete={false}
          add={false}
          duplicate={false}
          search={true}
          disabledEtatFilter={true}
          disabledCommercialFilter={true}
          data_params={{
            params: {
              facturation_entrante: true,
              entreprise_uuid: selectedTabEntreprise,
            },
          }}
          collectionCrud_options={{
            persistDatatableOptions: {
              id: "affaire_facturation_entrante",
              exipiration_minutes: 60 * 12, // 12 Heures
            },
          }}
          local_storage_key="affaire_facture_entrante_tab_filter"
          facturation_cells={this.getFactEntranteCellsConfig()}
          crudClass={classes.crudClass}
          disabledTabs={true}
        />
      </TabContainer>
    );
  }
  sendMegAffaires(){
    const { dispatch } = this.props;
    sendToMeg(dispatch,{
      affaires_uuid: this.state.selectedAffaireMeg.map(affaire => affaire.uuid),
      action: this.state.megAction,
      meg_with_facture_annexe: this.state.meg_with_facture_annexe,
    },
    () => {
      this.setState({
        selectedAffaireMeg: [],
        megAction: null,
        meg_with_facture_annexe: false,
      })
    })
  }

  getMegActionOptions(){
    return [
      <MenuItem key="brouillon" value="brouillon">Brouillon</MenuItem>,
      <MenuItem key="valider" value="valider">Valider</MenuItem>,
      <MenuItem key="envoyer" value="envoyer">Envoyer</MenuItem>,
    ]
  }

  getMegAffairesSelected() {
    const { selectedAffaireMeg, megAction, meg_with_facture_annexe } = this.state;
    const { classes, affairesStore } = this.props;
    if(selectedAffaireMeg.length === 0) return null;

    return (
      <Grid container>
        <Grid item xs={12} style={{marginBottom:'1rem'}}>
          <div className={classes.megAffairesSelected}>
            <div className={classes.megAffairesSelectedTitle}>Meg Affaires sélectionnées : </div>
            <div className={classes.megAffairesSelectedList}>
              {selectedAffaireMeg.map(affaire => {
                return (
                  <div className={classes.megAffairesSelectedItem} key={affaire.uuid}>
                    <span className={classes.megAffairesSelectedItemRef}>{affaire.reference_interne}</span>
                    <span className={classes.megAffairesSelectedItemRemove} onClick={this.onChangeSelectMeg.bind(this, affaire)}>
                      <Delete />
                    </span>
                  </div>
                )
              })}
            </div>
          </div>
        </Grid>
        <Grid item xs={4} style={{marginBottom:'1rem'}}>
          <TextInput
            label="Action Meg"
            value={megAction}
            onChangeHandler={(name, e) => {
              this.setState({megAction: e.target.value})
            }}
            collectionStore={affairesStore}
            select
            fullWidth
          >
            {this.getMegActionOptions()}
          </TextInput>
        </Grid>
        <Grid item xs={2} style={{marginBottom:'1rem'}}>
          <CheckboxInput
            id={"meg_with_facture_annexe"}
            value={meg_with_facture_annexe}
            margin="normal"
            name="meg_with_facture_annexe"
            label="Avec Facture Annexe (Uniquement les FA du client de l'affaire)"
            onChangeHandler={(name, e) => {
              this.setState({meg_with_facture_annexe: e.target.value})
            }}
          />
        </Grid>
        <Grid item xs={4} style={{marginBottom:'1rem'}}>
          <Button
            variant="contained"
            color="primary"
            onClick={this.sendMegAffaires.bind(this)}
          >
            <Send /> Envoyer
          </Button>
        </Grid>
      </Grid>
    )
  }

  getFacturationSortante() {
    const { classes } = this.props;
    const { selectedTabEntreprise } = this.state;
    return (
      <TabContainer>
        {this.getMegAffairesSelected()}
        <Affaire
          delete={false}
          add={false}
          duplicate={false}
          search={true}
          disabledCommercialFilter={true}
          data_params={{
            params: {
              facturation_sortante: true,
              entreprise_uuid: selectedTabEntreprise,
            },
          }}
          collectionCrud_options={{
            persistDatatableOptions: {
              id: "affaire_facturation_sortante",
              exipiration_minutes: 60 * 12, // 12 Heures
            },
          }}
          local_storage_key="affaire_facture_sortante_tab_filter"
          facturation_cells={this.getFactSortanteCellsConfig()}
          crudClass={classes.crudClass}
          disabledTabs={true}
          cancelUpdateCheck={true}
          enableMegFilter={true}
        />
      </TabContainer>
    );
  }

  getFacturationTabs() {
    let tabs = [];
    if (hasRights(["admin-facturation-entrante"], this.props.user)) {
      tabs.push(<Tab label="Facturation entrante" value="entrante" />);
    }
    if (hasRights(["admin-facturation-sortante"], this.props.user)) {
      tabs.push(<Tab label="Facturation sortante" value="sortante" />);
    }
    if (hasRights(["admin-facturation-bordereau"], this.props.user)) {
      tabs.push(<Tab label="Bordereau d'achat" value="bordereau" />);
    }
    if (hasRights(["admin-crud-facturation-annexe"], this.props.user)) {
      tabs.push(
        <Tab label="Facturation Annexe" value={TAB_FACTURATION_ANNEXE} />
      );
    }
    if (hasRights(["admin-proforma"], this.props.user)) {
      tabs.push(
        <Tab label="Proforma" value={TAB_PROFORMA} />
      );
    }
    return tabs;
  }

  handleChange = (event, selectedTab) => {
    const selectedTabState = {
      selectedTab: selectedTab,
      selectedTabEntreprise: this.state.selectedTabEntreprise,
    };
    storeToLocalStorage("facturation_tab", selectedTabState, 60 * 12);

    this.setState(selectedTabState);

    this.changePage(selectedTab);
  };

  changePage(selectedTab) {
    const { selectedTabEntreprise } = this.state;
    if (!selectedTabEntreprise) return;

    this.setState({changingPage: true},() => {
      switch (selectedTab) {
        case TAB_SORTANTE:
          this.props.history.push({
            pathname: "/facturation/sortante/" + selectedTabEntreprise,
            state: { selectedTab: selectedTab, selectedTabEntreprise },
          });
          break;
        case TAB_ENTRANTE:
          this.props.history.push({
            pathname: "/facturation/entrante/" + selectedTabEntreprise,
            state: { selectedTab: selectedTab, selectedTabEntreprise },
          });
          break;
        case TAB_BORDEREAU_ACHAT:
          this.props.history.push({
            pathname: "/facturation/bordereau_achat/" + selectedTabEntreprise,
            state: { selectedTab: selectedTab, selectedTabEntreprise },
          });
          break;
        case TAB_FACTURATION_ANNEXE:
          this.props.history.push({
            pathname: "/facturation/facturation_annexe/" + selectedTabEntreprise,
            state: { selectedTab: selectedTab, selectedTabEntreprise },
          });
          break;
        case TAB_PROFORMA:
          this.props.history.push({
            pathname: "/facturation/proforma/" + selectedTabEntreprise,
            state: { selectedTab: selectedTab, selectedTabEntreprise },
          });
          break;
        default:
          break;
      }

      this.setState({changingPage: false})
    })


  }

  getDate(string) {
    if (!string) {
      return "";
    }
    let date = new Date(string);
    let options = {
      day: "numeric",
      month: "long",
      year: "numeric",
    };
    return date.toLocaleDateString("fr-FR", options);
  }

  getFacturationTabsContent() {
    const { selectedTab, selectedTabEntreprise } = this.state;
    return (
      <>
        <Tabs value={selectedTab} onChange={this.handleChange}>
          {this.getFacturationTabs()}
        </Tabs>
        {selectedTab === TAB_ENTRANTE && this.getFacturationEntrante()}
        {selectedTab === TAB_SORTANTE && this.getFacturationSortante()}
        {selectedTab === TAB_BORDEREAU_ACHAT && (
          <TabContainer>
            <Bordereau entreprise_uuid={selectedTabEntreprise} />
          </TabContainer>
        )}
        {selectedTab === TAB_FACTURATION_ANNEXE && (
          <TabContainer>
            <FacturationAnnexe entreprise_uuid={selectedTabEntreprise} />
          </TabContainer>
        )}
        {selectedTab === TAB_PROFORMA && (
          <TabContainer>
            <Proforma entreprise_uuid={selectedTabEntreprise} />
          </TabContainer>
        )}
      </>
    );
  }

  getEntrepriseTabs() {
    const { entreprises, selectedTabEntreprise } = this.state;
    return (
      <Tabs
        value={selectedTabEntreprise}
        onChange={this.handleChangeEntreprise.bind(this)}
        variant="scrollable"
        scrollButtons="auto"
      >
        {entreprises.map((entreprise) => (
          <Tab
            key={entreprise.uuid}
            value={entreprise.uuid}
            label={entreprise.nom}
          />
        ))}
      </Tabs>
    );
  }

  handleChangeEntreprise(event, selectedTab) {
    this.setState({ selectedTabEntreprise: selectedTab }, () => {
      const selectedTabState = {
        selectedTab: this.state.selectedTab,
        selectedTabEntreprise: this.state.selectedTabEntreprise,
      };
      storeToLocalStorage("facturation_tab", selectedTabState, 60 * 12);
      this.changePage(this.state.selectedTab);
    });
  }

  render() {
    const { entreprises, changingPage } = this.state;

    if (!entreprises || changingPage)
      return (
        <div className="loading">
          <h5>Chargement des données</h5>
          <CircularProgress />
        </div>
      );

    return (
      <Grid container>
        <Grid item xs={12}>
          {this.getEntrepriseTabs()}
        </Grid>
        <Grid item xs={12}>
          {this.getFacturationTabsContent()}
        </Grid>
      </Grid>
    );
  }
}

Facturation = withStyles(FacturationCss)(Facturation);

Facturation = connect((store) => {
  return {
    location: store.router.location,
    user: store.auth.user,
    etatsStore: store.collections.etats,
    affairesStore: store.collections.affaires,
  };
})(Facturation);

export default Facturation;
