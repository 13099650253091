import React, { Component } from "react";
import { connect } from "react-redux";

import {
  Typography,
  Grid,
  IconButton,
  Fab,
  TextField,
  MenuItem,
} from "@material-ui/core";
import { withStyles } from "@material-ui/core/styles";
import { Place, Edit, Add } from "@material-ui/icons";
import CollectionCrud from "../../common/Components/CollectionCrud";

import NoteCss from "./css/NoteCss";

import { collectionActions } from "../../../reducers/collectionsReducer";

import { getNoteContent, getFromLocalStorage, storeToLocalStorage } from "../../tools/Tools";
import NoteModal from "./modal";

const LOCAL_STORAGE_KEY = "MODULE_COMMERCIAl";

class Note extends Component {
  state = {
    sliderColor: null,
    cloture: null,
    action_requise: null,
    openNoteModal: false,
    note_entreprise_uuid: null,
    note_entreprise_nom: null,
    note: null,
    filters: {
      type_filter: ["all"],
      assigned_to_filter: ["all"],
      commercial_filter: ["all"],
      closed_filter: "non",
    },
    local_storage_key: LOCAL_STORAGE_KEY,
  };

  componentDidMount() {

    if(this.props.from_store) {
      this.setState({
        note_entreprise_uuid: this.props.entreprisesStore.detail.uuid,
        note_entreprise_nom: this.props.entreprisesStore.detail.nom,
        note:{
          entreprise_uuid: this.props.entreprisesStore.detail.uuid,
        }
      });
    }
    collectionActions(this.props.dispatch, "type_notes", "INDEX", null);
    collectionActions(this.props.dispatch, "users", "INDEX", {
      params: { commerciaux: true },
    });

    const { local_storage_key } = this.props;
    if (local_storage_key) {
      this.setState({ local_storage_key: local_storage_key });
    }
    let filter_localStorage = getFromLocalStorage(
      local_storage_key ? local_storage_key : LOCAL_STORAGE_KEY
    );
    if (filter_localStorage) {
      this.setState(filter_localStorage, () => {
        this.loadAsyncData(this.state.filters, true);
      });
    } else {
      this.loadAsyncData(this.state.filters, true);
    }
  }

  getDeleteModalTitle() {
    return (
      <div>
        <Place />
        <span>{"Suppression de la note"}</span>
      </div>
    );
  }

  getDeleteModalContent() {
    return (
      <Typography>
        {"Êtes-vous sûr de vouloir supprimer cette note ?"}
      </Typography>
    );
  }

  getCrudTitle() {
    const { classes } = this.props;
    return (
      <Typography variant="button" gutterBottom className={classes.crudTitle}>
        Notes
      </Typography>
    );
  }

  // Retourn la date formatée
  getDate(string, options = null) {
    if(!string) return null;

    let date = new Date(string);
    options = options || {
      day: "numeric",
      month: "long",
      year: "numeric",
    };
    return date.toLocaleDateString("fr-FR", options);
  }

  getCellsConfig() {
    let col_entreprises = {
      datakey: "entreprise_nom",
      title: "Raison Social",
      sortable: true,
      searchable: true,
    };

    let col_commercial = {
      datakey: "commercial_initials",
      title: "Commercial",
      sortable: true,
      searchable: true,
    };

    if (this.props.entreprise_uuid) {
      col_entreprises = null;
      col_commercial = null;
    }

    return [
      {
        datakey: "date",
        title: "Date",
        sortable: true,
        searchable: true,
        format: (obj) => this.getDate(obj.date),
      },
      {
        datakey: "user_initials",
        title: "Auteur",
        sortable: true,
        searchable: true,
      },
      col_entreprises,
      col_commercial,
      {
        datakey: "cloture",
        title: "Cloturé",
        sortable: true,
        searchable: true,
        format: (obj) => (obj.cloture ? "Oui" : "Non"),
      },
      {
        datakey: "type_libelle",
        title: "Type",
        sortable: true,
        searchable: true,
      },
      {
        datakey: "assigned_user_initials",
        title: "Assigné à",
        sortable: true,
        searchable: true,
      },
      {
        datakey: "remind_at",
        title: "Date de rappel",
        sortable: true,
        searchable: true,
        format: (obj) => this.getDate(obj.remind_at),
      },
      {
        datakey: "contenu",
        title: "Contenu",
        sortable: true,
        searchable: true,
        format: (obj) => getNoteContent(obj.contenu),
      },
    ];
  }

  getEditBtn(note) {
    const { classes } = this.props;

    return (
      <IconButton
        key="edit"
        aria-label="Éditer"
        color="primary"
        onClick={() => {
          this.setState({
            openNoteModal: true,
            note_entreprise_uuid: note.entreprise_uuid,
            note_entreprise_nom: note.entreprise_nom,
            note: note,
          });
        }}
        title="Éditer"
        className={classes.button}
      >
        <Edit />
      </IconButton>
    );
  }

  getCreateBtn() {
    const { classes } = this.props;

    return (
      <Fab
        size="small"
        color="primary"
        aria-label="add"
        onClick={() => {
          this.setState({
            openNoteModal: true,
            note: null,
          });
        }}
        className={classes.buttonAdd}
        title="Ajouter"
      >
        <Add style={{ fontSize: 32 }} />
      </Fab>
    );
  }

  loadAsyncData(paginationFilters) {
    let { dispatch, entreprise_uuid, notesStore } = this.props;

    if (notesStore.fetching) return;

    const { filters } = this.state;

    const { type_filter, assigned_to_filter, closed_filter, commercial_filter } = filters;

    if(paginationFilters) {
      // eslint-disable-next-line
      this.state.paginationFilters = paginationFilters;
    }

    var data_params = {};
    data_params.params = {
      ...this.state.paginationFilters,
      entreprise_uuid: entreprise_uuid ? entreprise_uuid : null,
      type_filter:
        type_filter.filter((t) => t !== "all").length > 0 ? type_filter : null,
      assigned_to_filter:
        assigned_to_filter.filter((t) => t !== "all").length > 0
          ? assigned_to_filter
          : null,
      commercial_filter: commercial_filter.filter((t) => t !== "all").length > 0 ? commercial_filter : null,
      closed_filter: closed_filter !== "all" ? closed_filter : null,
    };

    // Gardes fou pour éviter que des requêtes partent sans pagination
    if (!data_params.params.oniti_pagination) {
      data_params.params.oniti_pagination = 1;
    }
    if (!data_params.params.oniti_perpage) {
      data_params.params.oniti_perpage = 25;
    }

    return collectionActions(
      dispatch,
      "notes",
      "INDEX",
      data_params,
      null,
      true
    );
  }

  onChangeHandlerFilters(name, e) {
    let { filters } = this.state;
    let values = e.target.value;
    if (Array.isArray(values)) {
      /**
       * Gestion de la value all
       * si on clique dessus on vide la liste et ne garde que all
       * si on clique sur un autre element on enleve all
       * si on vide la liste on rajoute all
       */
      if (values.length > 0) {
        if (filters[name].indexOf("all") !== -1) {
          values = values.filter((value) => value !== "all");
        } else if (values.indexOf("all") !== -1) {
          values = ["all"];
        }
      } else {
        values = ["all"];
      }
    }

    filters[name] = values;
    this.setState({ filters }, () => {
        storeToLocalStorage(
          this.state.local_storage_key,
          {
            filters: this.state.filters,
          },
          60 * 12
        );
      this.loadAsyncData();
    });
  }

  getFilters() {
    const { classes, typeNotesStore } = this.props;
    const { filters } = this.state;
    const { type_filter, assigned_to_filter, closed_filter, commercial_filter } = filters;

    const optionsType = [
      <MenuItem key="all" value="all">
        Tous
      </MenuItem>,
    ];

    if (typeNotesStore.list) {
      typeNotesStore.list.forEach((type) => {
        optionsType.push(
          <MenuItem key={type.uuid} value={type.uuid}>
            {type.libelle}
          </MenuItem>
        );
      });
    }

    const optionsAssignedTo = [
      <MenuItem key="all" value="all">
        Tous
      </MenuItem>,
    ];

    if (this.props.usersStore.list) {
      this.props.usersStore.list.forEach((user) => {
        optionsAssignedTo.push(
          <MenuItem key={user.uuid} value={user.uuid}>
            {user.firstname}
          </MenuItem>
        );
      });
    }

    const optionsClosed = [
      <MenuItem key="all" value="all">
        Tous
      </MenuItem>,
      <MenuItem key="true" value="oui">
        Oui
      </MenuItem>,
      <MenuItem key="false" value="non">
        Non
      </MenuItem>,
    ];

    return (
      <Grid container>
        <Grid item xs={12}>
          <Typography
            key="typo"
            variant="button"
            gutterBottom
            className={classes.crudTitle}
          >
            Filtres
          </Typography>
        </Grid>
        <Grid item xs={2}>
          <TextField
            id="type_filter"
            label="Type"
            margin="normal"
            onChange={this.onChangeHandlerFilters.bind(this, "type_filter")}
            className={classes.selectContainer}
            SelectProps={{
              multiple: true,
              value: type_filter,
            }}
            select
          >
            {optionsType}
          </TextField>
        </Grid>
        <Grid item xs={2}>
          <TextField
            id="assigned_to_filter"
            label="Assignée à"
            margin="normal"
            onChange={this.onChangeHandlerFilters.bind(
              this,
              "assigned_to_filter"
            )}
            className={classes.selectContainer}
            SelectProps={{
              multiple: true,
              value: assigned_to_filter,
            }}
            select
          >
            {optionsAssignedTo}
          </TextField>
        </Grid>
        <Grid item xs={2}>
          <TextField
            id="commercial_filter"
            label="Commercial"
            margin="normal"
            onChange={this.onChangeHandlerFilters.bind(
              this,
              "commercial_filter"
            )}
            className={classes.selectContainer}
            SelectProps={{
              multiple: true,
              value: commercial_filter,
            }}
            select
          >
            {optionsAssignedTo}
          </TextField>
        </Grid>
        <Grid item xs={2}>
          <TextField
            id="closed_filter"
            label="Cloturée"
            margin="normal"
            onChange={this.onChangeHandlerFilters.bind(this, "closed_filter")}
            className={classes.selectContainer}
            value={closed_filter}
            select
          >
            {optionsClosed}
          </TextField>
        </Grid>
      </Grid>
    );
  }

  render() {
    const { actionsCallback, classes, value } = this.props;
    const { openNoteModal, note_entreprise_uuid, note_entreprise_nom, note } =
      this.state;

    return (
      <Grid
        container
        className={value === "prospection" ? null : classes.container}
      >
        {!value ? this.getCrudTitle() : null}
        {this.getFilters()}
        <CollectionCrud
          collectionName={"notes"}
          asynchrone={true}
          asynchroneCallbackLoadData={this.loadAsyncData.bind(this)}
          showBtnEdit={false}
          showBtnAdd={false}
          showBtnDelete={true}
          deleteModalTitle={this.getDeleteModalTitle}
          deleteModalContent={this.getDeleteModalContent.bind(this)}
          disabledEnterModal={true}
          additionnalControllers={[this.getEditBtn.bind(this)]}
          datatableConfig={{
            showPagination: true,
            showSearch: true,
            defaultSort: "date",
            sortType: "desc",
            rowsPerPageOptions: [25, 50],
          }}
          cellsConfig={this.getCellsConfig()}
          actionsCallback={actionsCallback}
          dataTableExtraNodes={[
            {
              element: this.getCreateBtn(),
              position: "top-right",
            },
          ]}
        />
        <NoteModal
          open={openNoteModal}
          entreprise_uuid={note_entreprise_uuid}
          entreprise_nom={note_entreprise_nom}
          note={note}
          onCloseClallback={() => {
            if(this.props.from_store){
              this.setState({
                openNoteModal: false,
                note: {
                  entreprise_uuid: this.props.entreprisesStore.detail.uuid,
                },
              });
            }else{
              this.setState({
                openNoteModal: false,
                note_entreprise_nom: null,
                note_entreprise_uuid: null,
                note: null,
              });
            }
            this.loadAsyncData()
          }}
        />
      </Grid>
    );
  }
}

Note = withStyles(NoteCss)(Note);

Note = connect((store) => {
  return {
    activitesStore: store.collections.activites,
    languesStore: store.collections.langues,
    paiementsStore: store.collections.paiements,
    entreprisesStore: store.collections.entreprises,
    user: store.auth.user,
    notesStore: store.collections.notes,
    typeNotesStore: store.collections.type_notes,
    usersStore: store.collections.users,
  };
})(Note);

export default Note;
