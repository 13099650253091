import React, {Component} from 'react'
import PropTypes from 'prop-types'

import {AppBar, Grid, Tab, Tabs, Typography} from '@material-ui/core'
import {withStyles} from '@material-ui/core/styles'

import SwipeableViews from 'react-swipeable-views'
import User from '../User'
import Role from '../Role'
import Activite from '../Activite'
import Conditionnement from '../Conditionnement'
import Etat from '../Etat'
import Famille from '../Famille'
import Langue from '../Langue'
import Paiement from '../Paiement'
import Pays from '../Pays'
import Transport from '../Transport'
import Litige from '../Litige'
import Traitement from '../Traitement'
import TypeDocument from '../TypeDocument'
import TypeNote from '../TypeNote'
import RaisonFacturation from '../RaisonFacturation'
import ProduitsOrigines from '../ProduitsOrigines'
import Logisticien from '../Logisticien'
import ParametresCss from './css/ParametresCss'
import EmailBloacagePlanning from '../EmailBloacagePlanning'
import Cdc from '../CahierDesCharges'
import CaseStockage from '../CaseStockage'
import ZoneBBParameters from '../ZoneBB/ZoneBBParameters'
import MegArticles from '../Meg/MegArticles'
import MegModelTva from '../Meg/MegModelTva'
import TypeNonConformite from '../TypeNonConformite'
import SourceProspect from '../SourceProspect'
import EtablissementContactNonConformite from '../EtablissementContactNonConformite'

class TabContainer extends Component {

  static propTypes = {
    children: PropTypes.node.isRequired,
    dir: PropTypes.string.isRequired,
  }

  render() {
    let {children, dir} = this.props
    return (
      <Typography component="div" dir={dir} style={{padding: 8 * 3}}>
        {children}
      </Typography>
    )
  }
}


class Parametres extends Component {

  state = {
    value: 0,
  }

  handleChange = (event, value) => {
    this.setState({value})
  }

  handleChangeIndex = index => {
    this.setState({value: index})
  }

  render() {
    return (
      <Grid item xs={12}>
        <AppBar position="static" color="default">
          <Tabs
            value={this.state.value}
            onChange={this.handleChange}
            indicatorColor="primary"
            textColor="primary"
            variant="scrollable"
            scrollButtons="auto"
          >
            <Tab label="NC Contact Site" classes={this.props.classes}/>
            <Tab label="Source Prospect" classes={this.props.classes}/>
            <Tab label="Type Non Conformite" classes={this.props.classes}/>
            <Tab label="Meg Model Tva" classes={this.props.classes}/>
            <Tab label="Meg Articles" classes={this.props.classes}/>
            <Tab label="Zones BB" classes={this.props.classes}/>
            <Tab label="Activités" classes={this.props.classes}/>
            <Tab label="Conditionnements" classes={this.props.classes}/>
            <Tab label="Cdc" classes={this.props.classes}/>
            <Tab label="Origines" classes={this.props.classes}/>
            <Tab label="États" classes={this.props.classes}/>
            <Tab label="Familles" classes={this.props.classes}/>
            <Tab label="Langues" classes={this.props.classes}/>
            <Tab label="Paiements" classes={this.props.classes}/>
            <Tab label="Pays" classes={this.props.classes}/>
            <Tab label="Transports" classes={this.props.classes}/>
            <Tab label="Cases" classes={this.props.classes}/>
            <Tab label="Litiges" classes={this.props.classes}/>
            <Tab label="Traitements" classes={this.props.classes}/>
            <Tab label="Types de documents" classes={this.props.classes}/>
            <Tab label="Types notes" classes={this.props.classes}/>
            <Tab label="Raison Facturation" classes={this.props.classes}/>
            <Tab label="Logisticiens" classes={this.props.classes}/>
            <Tab label="Email Planning" classes={this.props.classes}/>
            <Tab label="Rôles" classes={this.props.classes}/>
            <Tab label="Utilisateurs" classes={this.props.classes}/>
          </Tabs>
        </AppBar>
        <SwipeableViews axis={'x'}
                        index={this.state.value}
                        onChangeIndex={this.handleChangeIndex}
        >
          <TabContainer dir={'ltr'}>
            <EtablissementContactNonConformite/>
          </TabContainer>
          <TabContainer dir={'ltr'}>
            <SourceProspect />
          </TabContainer>
          <TabContainer dir={'ltr'}>
            <TypeNonConformite />
          </TabContainer>
          <TabContainer dir={'ltr'}>
            <MegModelTva/>
          </TabContainer>
          <TabContainer dir={'ltr'}>
            <MegArticles/>
          </TabContainer>
          <TabContainer dir={'ltr'}>
            <ZoneBBParameters/>
          </TabContainer>
          <TabContainer dir={'ltr'}>
            <Activite/>
          </TabContainer>
          <TabContainer dir={'ltr'}>
            <Conditionnement/>
          </TabContainer>
          <TabContainer dir={'ltr'}>
            <Cdc/>
          </TabContainer>
          <TabContainer dir={'ltr'}>
            <ProduitsOrigines/>
          </TabContainer>
          <TabContainer dir={'ltr'}>
            <Etat/>
          </TabContainer>
          <TabContainer dir={'ltr'}>
            <Famille/>
          </TabContainer>
          <TabContainer dir={'ltr'}>
            <Langue/>
          </TabContainer>
          <TabContainer dir={'ltr'}>
            <Paiement/>
          </TabContainer>
          <TabContainer dir={'ltr'}>
            <Pays/>
          </TabContainer>
          <TabContainer dir={'ltr'}>
            <Transport/>
          </TabContainer>
          <TabContainer dir={'ltr'}>
            <CaseStockage/>
          </TabContainer>
          <TabContainer dir={'ltr'}>
            <Litige/>
          </TabContainer>
          <TabContainer dir={'ltr'}>
            <Traitement/>
          </TabContainer>
          <TabContainer dir={'ltr'}>
            <TypeDocument/>
          </TabContainer>
          <TabContainer dir={'ltr'}>
            <TypeNote/>
          </TabContainer>
          <TabContainer dir={'ltr'}>
            <RaisonFacturation/>
          </TabContainer>
          <TabContainer dir={'ltr'}>
            <Logisticien/>
          </TabContainer>
          <TabContainer dir={'ltr'}>
            <EmailBloacagePlanning />
          </TabContainer>
          <TabContainer dir={'ltr'}>
            <Role/>
          </TabContainer>
          <TabContainer dir={'ltr'}>
            <User/>
          </TabContainer>
        </SwipeableViews>
      </Grid>
    )
  }
}

Parametres = withStyles(ParametresCss)(Parametres)

export default Parametres
