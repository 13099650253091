import React, {Component} from 'react'

import {withStyles} from '@material-ui/core/styles/index'
import {ChevronLeft, ChevronRight} from '@material-ui/icons'

import DateFnsUtils from '@date-io/date-fns'
import format from 'date-fns/format'
import frLocale from 'date-fns/locale/fr'
import {DateTimePicker, DatePicker, MuiPickersUtilsProvider} from 'material-ui-pickers'

import DateInputCss from './css/DateInputCss'

import moment from 'moment'

class FrDateFnsUtils extends DateFnsUtils {
  startOfMonth(date) {
    return moment(date).startOf('month').toDate();
  }

  getDatePickerHeaderText(date) {
    return format(date, 'd MMMM', {locale: this.locale})
  }
}


class DateInput extends Component {

  state = {}

  static getDerivedStateFromProps(nextProps, prevState) {
    let newState = {...prevState}
    if (nextProps.name && nextProps.value !== prevState[nextProps.name]) {
      newState = {
        ...newState,
        [nextProps.name]: nextProps.value,  // format ISO pour parent
        dateValue: new Date(nextProps.value),  // type date pour this
      }
    }
    return newState
  }

  getErrorMessage(name) {
    const {collectionStore} = this.props
    if (collectionStore.errors && collectionStore.errors[name]) {
      return collectionStore.errors[name]
        .map((err, index) =>
          <span style={{display: 'block'}} key={index}>{err}</span>
        )
    } else {
      return null
    }
  }

  onChangeHandler(name, date) {
    const isoDate = date ? format(date, 'yyyy-MM-dd HH:mm:ss') : null
    this.setState({
      [name]: isoDate,  // format ISO pour parent
      dateValue: date,  // type date pour this
    })
    const fakeEvent = {target: {value: isoDate}}
    this.props.onChangeHandler(name, fakeEvent)
  }

  getPicker() {
    const {collectionStore, name, value, format, pickertype} = this.props;
    const {dateValue} = this.state;
    const filteredProps = [
      'name', 'collectionStore', 'onChangeHandler', 'value'
    ];
    let inputProps = Object.keys(this.props)
      .filter(k => !filteredProps.includes(k))
      .reduce(
        (obj, key) => {
          obj[key] = this.props[key];
          return obj
        },
        {}
      );

    const error = !!collectionStore.errors && !!collectionStore.errors[name];

    if(pickertype === 'datetime') return <DateTimePicker
        value={value ? dateValue : ''}
        onChange={this.onChangeHandler.bind(this, name)}
        cancelLabel="Annuler"
        error={error}
        format={!!format ? format : "d MMMM yyyy HH:mm"}
        helperText={this.getErrorMessage(name)}
        invalidLabel=""
        leftArrowIcon={<ChevronLeft/>}
        okLabel="Valider"
        rightArrowIcon={<ChevronRight/>}
        clearable
        clearLabel="Vider"
        ampm={false}
        {...inputProps}
      />;

    return <DatePicker
      cancelLabel="Annuler"
      error={error}
      format={!!format ? format : "d MMMM yyyy HH:mm"}
      helperText={this.getErrorMessage(name)}
      invalidLabel=""
      leftArrowIcon={<ChevronLeft/>}
      okLabel="Valider"
      onChange={this.onChangeHandler.bind(this, name)}
      rightArrowIcon={<ChevronRight/>}
      value={value ? dateValue : ''}
      clearable
      clearLabel="Vider"
      {...inputProps}
    />
  }

  render() {
    return (
      <MuiPickersUtilsProvider
        locale={frLocale}
        utils={FrDateFnsUtils}
      >
        {this.getPicker()}
      </MuiPickersUtilsProvider>
    )
  }
}

export default withStyles(DateInputCss)(DateInput)
