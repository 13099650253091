import React, { Component } from "react";

import { Grid, MenuItem, Typography } from "@material-ui/core";
import { withStyles } from "@material-ui/core/styles";
import { Settings } from "@material-ui/icons";

import CollectionCrud from "../../common/Components/CollectionCrud";
import TextInput from "../../common/Components/TextInput";
import MegCss from "./css/MegCss";

class MegArticles extends Component {
  getDeleteModalTitle() {
    return (
      <div>
        <Settings />
        <span>{"Suppression du commentaire"}</span>
      </div>
    );
  }

  getDeleteModalContent() {
    return (
      <Typography>
        {"Êtes-vous sûr de vouloir supprimer ce commentaire ?"}
      </Typography>
    );
  }

  getCreateUpdateModalTitle(commentaire) {
    let title = !!commentaire
      ? "Modification du commentaire : " + commentaire.famille_libelle + ' - ' + commentaire.article_type_libelle
      : "Ajout d'un nouveau commentaire";
    return (
      <div>
        <Settings />
        <span>{title}</span>
      </div>
    );
  }

  getCreateUpdateModalContent(
    meg_commentaire,
    meg_commentairesStore,
    onChangeHandler
  ) {
    let { famille_uuid, article_type, commentaire } = meg_commentaire;
    return (
      <Grid container>
        <Grid item xs={12}>
          <TextInput
            id="famille_uuid"
            label="Famille"
            value={famille_uuid}
            margin="normal"
            collectionStore={meg_commentairesStore}
            name="famille_uuid"
            onChangeHandler={onChangeHandler}
            fullWidth
            type="autocomplete"
            autocompleteProps={{
              collectionName: "familles",
            }}
          />
        </Grid>
        <Grid item xs={12}>
          <TextInput
            id="article_type"
            label="Type article"
            value={article_type}
            margin="normal"
            collectionStore={meg_commentairesStore}
            name="article_type"
            onChangeHandler={onChangeHandler}
            select
            fullWidth
          >
            {this.getTypeArticlesOptions()}
          </TextInput>
        </Grid>
        <Grid item xs={12}>
          <TextInput
            id="commentaire"
            label="Code"
            value={commentaire}
            required={true}
            margin="normal"
            collectionStore={meg_commentairesStore}
            name="commentaire"
            onChangeHandler={onChangeHandler}
            fullWidth
          />
        </Grid>
      </Grid>
    );
  }

  getTypeArticlesOptions(){
    return [
      <MenuItem key="en_etat" value="en_etat">En l'état</MenuItem>,
      <MenuItem key="broye" value="broye">Broyé</MenuItem>,
      <MenuItem key="granule" value="granule">Granulé</MenuItem>,
    ]
  }

  render() {
    const { classes } = this.props;
    return (
      <Grid container className={classes.container}>
        <CollectionCrud
          collectionName={"meg-commentaire-articles"}
          showBtnEdit={true}
          showBtnAdd={true}
          showBtnDelete={true}
          rights={{
            create: "admin-crud-meg",
            edit: "admin-crud-meg",
            delete: "admin-crud-meg",
          }}
          deleteModalTitle={this.getDeleteModalTitle}
          deleteModalContent={this.getDeleteModalContent}
          createUpdateModalTitle={this.getCreateUpdateModalTitle}
          createUpdateModalContent={this.getCreateUpdateModalContent.bind(this)}
          datatableConfig={{
            showPagination: true,
            showSearch: true,
            defaultSort: "famille_libelle",
            sortType: "desc",
            rowsPerPageOptions: [10, 25, 50],
          }}
          cellsConfig={[
            {
              datakey: "famille_libelle",
              title: "Famille",
              sortable: true,
              searchable: true,
            },
            {
              datakey: "article_type_libelle",
              title: "Type article",
              sortable: true,
              searchable: true,
            },
            {
              datakey: "commentaire",
              title: "Commentaire",
              sortable: true,
              searchable: true,
            },
          ]}
        />
      </Grid>
    );
  }
}

MegArticles = withStyles(MegCss)(MegArticles);

export default MegArticles;
