import {
  DialogContent,
  DialogTitle,
  Grid,
  IconButton,
  MenuItem,
  Typography,
  withStyles,
} from "@material-ui/core";
import React, { Component } from "react";
import CdcCss from "./css/CdcCss";
import { connect } from "react-redux";
import {
  collectionActions,
  loadCollectionAttribute,
} from "../../../reducers/collectionsReducer";

import { FileUploader } from "react-drag-drop-files";
import TextInput from "../../common/Components/TextInput";
import CollectionCrud from "../../common/Components/CollectionCrud";
import { FileCopy, CloudDownload } from "@material-ui/icons";

class CahierDesCharges extends Component {
  state = {
    file: null,
    filename: null,
    file_index: 0
  };
  componentDidMount() {
    loadCollectionAttribute(
      this.props.dispatch,
      "list",
      "conditionnements",
      this.props.conditionnementStore
    );
    loadCollectionAttribute(
      this.props.dispatch,
      "list",
      "langues",
      this.props.languesStore
    );
  }

  getDeleteModalTitle() {
    return (
      <div>
        <FileCopy />
        <span>{"Suppression du cahier des charges"}</span>
      </div>
    );
  }

  getDeleteModalContent() {
    return (
      <Typography>
        {"Êtes-vous sûr de vouloir supprimer ce cahier des charges ?"}
      </Typography>
    );
  }

  getCreateUpdateModalTitle(cdc) {
    let title = cdc ? cdc.libelle : 'Ajouter un cahier des charges';
    return (
      <div>
        <FileCopy />
        <span>{title}</span>
      </div>
    );
  }

  getCreateUpdateModalContent(cdc, cdcStore, onChangeHandler) {
    const { libelle, code, chargeable_uuid, langue_uuid } = cdc;
    const { classes } = this.props;
    return (
      <Grid container>
        <Grid item xs={12}>
          <TextInput
            id="libelle"
            label="Libelle"
            value={libelle}
            margin="normal"
            collectionStore={cdcStore}
            name="libelle"
            onChangeHandler={onChangeHandler}
            fullWidth
          />
        </Grid>
        <Grid item xs={12}>
          <TextInput
            id="code"
            label="Code"
            value={code}
            margin="normal"
            collectionStore={cdcStore}
            name="code"
            onChangeHandler={onChangeHandler}
            fullWidth
          />
        </Grid>
        <Grid item xs={12}>
          <FileUploader
              name="file"
              label="Déposez votre fichier a uploader ici"
              onDrop={(file) => this.setState({file: null},() => {
                this.onDropHandler(file);
              })}
              onSelect={(file) => this.setState({file: null},() => {
                this.onDropHandler(file);
              })}
              classes={classes.dragrable}
              maxSize={50}
              onSizeError={(error) => {
                alert('Le fichier est trop volumineux (max 50Mo)');
              }}
            />
        </Grid>
        <Grid item xs={12}>
          <TextInput
            id="chargeable_uuid"
            label="Conditionnement"
            value={chargeable_uuid}
            margin="normal"
            collectionStore={cdcStore}
            name="chargeable_uuid"
            onChangeHandler={onChangeHandler}
            fullWidth
            select
          >
            {this.props.conditionnementStore.list.map((conditionnement) => (
              <MenuItem key={conditionnement.uuid} value={conditionnement.uuid}>
                {conditionnement.nom}
              </MenuItem>
            ))}
          </TextInput>
        </Grid>
        <Grid item xs={12}>
          <TextInput
            id="langue_uuid"
            label="Langue"
            value={langue_uuid}
            margin="normal"
            collectionStore={cdcStore}
            name="langue_uuid"
            onChangeHandler={onChangeHandler}
            fullWidth
            select
          >
            {this.props.languesStore.list.map((langue) => (
              <MenuItem key={langue.uuid} value={langue.uuid}>
                {langue.nom}
              </MenuItem>
            ))}
          </TextInput>
        </Grid>
      </Grid>
    );
  }

  onChangeHandler(name, event) {
    this.setState({ [name]: event.target.value });
  }

  getModalContent() {
    if (!this.state.file) return null;
    const { documentsStore } = this.props;
    return [
      <DialogTitle key="title" id="alert-dialog-slide-title">
        <Typography>Upload du fichier {this.state.file.name}</Typography>
      </DialogTitle>,
      <DialogContent key="content">
        {this.getCreateUpdateModalContent(
          this.state,
          documentsStore,
          this.onChangeHandler.bind(this)
        )}
      </DialogContent>,
    ];
  }

  onDropHandler(file, callback) {
      if(file){
        this.setState({ file, filename: file.name });
      }else{
        this.setState({file:null,filename:null} , callback);
      }
  }

  onSubmitHandler(data, callback) {
    const { file, filename } = this.state;
    const formData = new FormData();
    Object.keys(data).forEach((key) => {
      formData.append(key, data[key]);
    });
    formData.append("chargeable_type", 'App\\Models\\Conditionnement');
    formData.append("filename", filename);
    formData.append("file", file);
    collectionActions(
      this.props.dispatch,
      "cahiers-des-charges",
      data.uuid ? "UPDATE_FORMDATA" : "CREATE",
      formData,
      (response) => {
        if (response) {
          this.setState({ file: null, filename: null }, () => {
            callback(true)
          });
        }
      }
    );
  }

  getDownloadButton(document) {
    const { classes } = this.props;
    return (
      <IconButton
        color="primary"
        key={document.uuid}
        href={document.url}
        target="_blank"
        className={classes.button}
      >
        <CloudDownload />
      </IconButton>
    );
  }

  getCellsConfig() {
    return [
      {
        datakey: "code",
        title: "Code",
        sortable: true,
        searchable: true,
      },
      {
        datakey: "libelle",
        title: "Libelle",
        sortable: true,
        searchable: true,
      },
      {
        datakey: "chargeable_label",
        title: "Conditionnement",
        sortable: true,
        searchable: true,
      },
      {
        datakey: "langue_nom",
        title: "Langue",
        sortable: true,
        searchable: true,
      }
    ];
  }

  render() {
    const { classes } = this.props;
    return (
      <>
        <Grid item xs={12} className={classes.section}>
          <Typography
            variant="button"
            gutterBottom
            className={classes.sectionTitle}
          >
            Cahier des charges
          </Typography>
          <Grid item xs={12} style={{ marginTop: "1em" }}>
            <CollectionCrud
              collectionName={"cahiers-des-charges"}
              showBtnEdit={true}
              showBtnAdd={true}
              showBtnDelete={true}
              deleteModalTitle={this.getDeleteModalTitle}
              deleteModalContent={this.getDeleteModalContent.bind(this)}
              createUpdateModalTitle={this.getCreateUpdateModalTitle}
              createUpdateModalContent={this.getCreateUpdateModalContent.bind(this)}
              datatableConfig={{
                showPagination: true,
                showSearch: true,
                defaultSort: "created_at",
                sortType: "asc",
                nodatalabel: "Aucun cahier des charges trouvé",
              }}
              additionnalControllers={[this.getDownloadButton.bind(this)]}
              cellsConfig={this.getCellsConfig()}
              submitHandlerCreateUpdate={this.onSubmitHandler.bind(this)}
            />
          </Grid>
        </Grid>
      </>
    );
  }
}

CahierDesCharges = withStyles(CdcCss)(CahierDesCharges);


CahierDesCharges = connect((store) => {
  return {
    cdcStore: store.collections['cahiers-des-charges'],
    conditionnementStore: store.collections.conditionnements,
    languesStore: store.collections.langues,
  };
})(CahierDesCharges);

export default CahierDesCharges;
