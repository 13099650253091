import {axiosClient} from '../axios';
import {axiosErrorHandler} from './notificationReducer';

/**
 * ###########################################################################
 * ##########################  FUNCTIONS #####################################
 * ###########################################################################
 */

/**
 * Fonction pour changer de mot de passe
 * @param dispatch
 * @param data
 * @param cb
 */
export function getPortefeuillePerformances(dispatch, data) {
  axiosClient.get('/home/portefeuille/performances', data)
    .then(response => {
      dispatch({
        type: 'PORTEFEUILLE_PERFORMANCES_FULLFILED',
        payload: response.data,
      });
    })
    .catch(error => {
      axiosErrorHandler(dispatch, error);
    })
}

export function getPerformancesSynthese(dispatch, data) {
  axiosClient.get('home/performances/synthese', data)
    .then(response => {
      dispatch({
        type: 'PERFORMANCES_SYNTHESE_FULLFILED',
        payload: response.data,
      });
    })
    .catch(error => {
      axiosErrorHandler(dispatch, error);
    })
}

/**
 * ###########################################################################
 * ##########################  REDUCER   #####################################
 * ###########################################################################
 */

let initialState = {
  fetching: false,
  fetched: false,
  portefeuille: {
    performances: null,
  },
  performances: {
    synthese: null
  }

};

export default (state = initialState, action) => {
  switch (action.type) {
    case 'PORTEFEUILLE_PERFORMANCES_FULLFILED': {
      return {...initialState, portefeuille: {...state.portefeuille, performances: action.payload}};
    }
    case 'PERFORMANCES_SYNTHESE_FULLFILED': {
      return {...initialState, performances: {...state.performances, synthese: action.payload}};
    }
    default:
      return state
  }
}
