import React, { Component } from "react";
import { connect } from "react-redux";
import {
  Button,
  Checkbox,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Divider,
  FormControlLabel,
  Grid,
  IconButton,
  InputLabel,
  List,
  ListItem,
  ListItemAvatar,
  ListItemText,
  MenuItem,
  Select,
  TextField,
  Typography,
} from "@material-ui/core";
import { withStyles } from "@material-ui/core/styles";
import {
  PhotoCamera,
  FileCopy,
  Clear,
  LocalShipping,
  Person,
  BusinessCenter,
  SkipPrevious,
  SkipNext,
} from "@material-ui/icons";
import TextInput from "../../common/Components/TextInput";
import { storeToLocalStorage } from "../../tools/Tools";
import PhotoCss from "./css/PhotoCss";
import {
  collectionActions,
  loadCollectionAttribute,
} from "../../../reducers/collectionsReducer";
import moment from "moment";
import Planification from "../Planification";
import { axiosClient } from "../../../axios";
import DocumentPicker from "../../common/Components/DocumentPicker";

class Photo extends Component {
  state = {
    module: "planning",
    current_filter: null,
    date_filter_date_debut: "",
    date_filter_date_fin: "",
    date_filter: ["all"],
    site_filter: ["all"],
    type_date_filter: "date_livraison",
    type_date_filter_Checkbox: [],
    openModalDatePicker: false,
    client_uuid: null,
    reference_affaire: null,
    entreprise_uuid: null,
    disable_photo: true,
    openPhotoDialog: false,
    localAffaire: null,
    type_document: null,
    internalSite: [],
    sites_loaded: false,
    result_current_page: null,
    result_last_page: null,
    wanted_page: null,
  };

  onChangeFile(e) {
    const {
      target: { files },
    } = e;

    this.sendFile(files[0]);
  }

  sendFile(file) {
    const { module } = this.state;

    const { localAffaire, entreprise_uuid } = this.state;

    let documentable_type = null;
    let documentable_uuid = null;
    switch (module) {
      case "planning":
        documentable_type = "App\\Models\\Affaire";
        documentable_uuid = localAffaire.uuid;
        break;
      case "affaire":
        documentable_type = "App\\Models\\Affaire";
        documentable_uuid = localAffaire.uuid;
        break;
      case "entreprise":
        documentable_type = "App\\Models\\Entreprise";
        documentable_uuid = entreprise_uuid;
        break;
      default:
        break;
    }

    //set filename has date/hour/minute/second
    let filename = moment().format("YYYY-MM-DD-HH-mm-ss");

    const formData = new FormData();
    formData.append("documentable_type", documentable_type);
    formData.append("documentable_uuid", documentable_uuid);
    formData.append("filename", filename);
    formData.append("type_document_uuid", this.state.type_document.uuid);
    formData.append("file", file);
    collectionActions(this.props.dispatch, "documents", "CREATE", formData);
  }

  onChangeEntrepriseHandler(name, event) {
    const { value } = event.target;
    this.setState(
      {
        [name]: value,
      },
      () => {
        this.loadAsyncData(this.state.current_filter, true);
      }
    );
  }

  onChangeReferenceHandler(name, event) {
    const { value } = event.target;
    this.setState(
      {
        [name]: value,
      },
      () => {
        this.loadAsyncData(this.state.current_filter, true);
      }
    );
  }

  handleClearClient(name, test) {
    this.setState(
      {
        [name]: null,
      },
      () => {
        this.loadAsyncData(this.state.current_filter, true);
      }
    );
  }

  onChangeSitesHandler(name, event) {
    const { value } = event.target;
    this.setState(
      {
        [name]: value,
      },
      () => {
        this.loadAsyncData(this.state.current_filter, true);
      }
    );
  }

  getClientFilter() {
    const { entreprisesStore, classes } = this.props;
    const { client_uuid } = this.state;

    return (
      <>
        <Grid item xs={12}>
          <TextInput
            id="client_uuid"
            label="Client"
            className={classes.selectContainer}
            value={client_uuid}
            margin="normal"
            collectionStore={entreprisesStore}
            name="client_uuid"
            onChangeHandler={this.onChangeEntrepriseHandler.bind(this)}
            type="autocomplete"
            autocompleteProps={{
              collectionName: "entreprises",
            }}
            key={client_uuid}
            fullWidth
            InputProps={{
              endAdornment: (
                <IconButton
                  sx={{ visibility: client_uuid ? "visible" : "hidden" }}
                  onClick={this.handleClearClient.bind(this, "client_uuid")}
                >
                  <Clear />
                </IconButton>
              ),
            }}
          />
        </Grid>
      </>
    );
  }

  getFournisseurFilter() {
    const { entreprisesStore, classes } = this.props;
    const { fournisseur_uuid } = this.state;

    return (
      <>
        <Grid item xs={12}>
          <TextInput
            id="fournisseur_uuid"
            label="fournisseur"
            className={classes.selectContainer}
            value={fournisseur_uuid}
            margin="normal"
            collectionStore={entreprisesStore}
            name="fournisseur_uuid"
            onChangeHandler={this.onChangeEntrepriseHandler.bind(this)}
            type="autocomplete"
            autocompleteProps={{
              collectionName: "entreprises",
            }}
            key={fournisseur_uuid}
            fullWidth
            InputProps={{
              endAdornment: (
                <IconButton
                  sx={{ visibility: fournisseur_uuid ? "visible" : "hidden" }}
                  onClick={this.handleClearClient.bind(
                    this,
                    "fournisseur_uuid"
                  )}
                >
                  <Clear />
                </IconButton>
              ),
            }}
          />
        </Grid>
      </>
    );
  }

  getReferenceFilter() {
    const { entreprisesStore, classes } = this.props;
    const { reference_affaire } = this.state;

    return (
      <>
        <Grid item xs={12}>
          <TextInput
            id="reference"
            label="reference"
            className={classes.selectContainer}
            value={reference_affaire}
            margin="normal"
            collectionStore={entreprisesStore}
            name="reference_affaire"
            onChangeHandler={this.onChangeReferenceHandler.bind(this)}
            type="autocomplete"
            autocompleteProps={{
              collectionName: "reference_affaires",
            }}
            key={reference_affaire}
            fullWidth
            InputProps={{
              endAdornment: (
                <IconButton
                  sx={{ visibility: reference_affaire ? "visible" : "hidden" }}
                  onClick={this.handleClearClient.bind(
                    this,
                    "reference_affaire"
                  )}
                >
                  <Clear />
                </IconButton>
              ),
            }}
          />
        </Grid>
      </>
    );
  }

  getSitesFilter() {
    const { classes } = this.props;
    const { site_filter, internalSite } = this.state;

    const sites = [
      {
        uuid: "all",
        label: "Tous",
      },
      ...internalSite,
    ];
    const options = sites.map((site) => {
      return (
        <MenuItem value={site.uuid} key={site.uuid}>
          {site.label}
        </MenuItem>
      );
    });

    return (
      <TextField
        id="site_filter_affaire"
        label="Sites"
        margin="normal"
        onChange={this.onChangeSitesHandler.bind(this, "site_filter")}
        className={classes.selectContainer}
        SelectProps={{
          multiple: true,
          value: site_filter,
        }}
        select
      >
        {options}
      </TextField>
    );
  }

  setDateToday() {
    this.setState({
      date_filter_date_debut: moment().format("YYYY-MM-DD"),
      date_filter_date_fin: moment().format("YYYY-MM-DD"),
    });
  }

  onClickDatePeriode(nb_months) {
    var date_debut = moment().subtract(nb_months, "months");
    if (nb_months === 0 || nb_months === 1) {
      date_debut = date_debut.startOf("month");
    }
    date_debut = date_debut.format("YYYY-MM-DD");

    var date_fin = moment();
    if (nb_months === 1 || nb_months === 0) {
      date_fin = date_fin.subtract(nb_months, "months").endOf("month");
    }
    date_fin = date_fin.format("YYYY-MM-DD");

    this.setState({
      date_filter_date_debut: date_debut,
      date_filter_date_fin: date_fin,
    });
  }

  onChangeHandlerFilters(name, e) {
    let values = e.target.value;
    /**
     * Gestion de la value all
     * si on clique dessus on vide la liste et ne garde que all
     * si on clique sur un autre element on enleve all
     * si on vide la liste on rajoute all
     */
    if (values.length > 0) {
      if (this.state[name].indexOf("all") !== -1) {
        values = values.filter((value) => value !== "all");
      } else if (values.indexOf("all") !== -1) {
        values = ["all"];
      }
    } else {
      values = ["all"];
    }

    this.setState(
      {
        [name]: values,
      },
      () => {
        storeToLocalStorage(
          this.state.local_storage_key,
          {
            date_filter: this.state.date_filter,
            type_date_filter: this.state.type_date_filter,
          },
          60 * 12
        );
        // this.state.current_filter.page = 1; // On force la page 1
        // eslint-disable-next-line
        this.state.current_filter = { ...this.state.current_filter, page: 1 };
        this.loadAsyncData(this.state.current_filter, true);
      }
    );
  }

  handleChecked(value, e) {
    if (this.state.type_date_filter_Checkbox.includes(value)) {
      this.setState({
        type_date_filter_Checkbox: this.state.type_date_filter_Checkbox.filter(
          (item) => item !== value
        ),
      });
    } else {
      this.setState({
        type_date_filter_Checkbox: [
          ...this.state.type_date_filter_Checkbox,
          value,
        ],
      });
    }
  }

  getDateFilter() {
    const { classes } = this.props;
    const {
      date_filter,
      openModalDatePicker,
      date_filter_date_debut,
      date_filter_date_fin,
    } = this.state;

    const date_filter_new =
      date_filter_date_debut === "" || date_filter_date_fin === ""
        ? ["all"]
        : [date_filter_date_debut, date_filter_date_fin];
    const dates_values = date_filter.filter((date) => date !== "all");

    return (
      <>
        <TextField
          id="date_filter"
          label="Date"
          margin="normal"
          onClick={() =>
            this.setState(
              {
                date_filter_date_debut: moment().format("YYYY-MM-DD"),
                date_filter_date_fin: moment().format("YYYY-MM-DD"),
              },
              () => {
                this.setState({ openModalDatePicker: true });
              }
            )
          }
          className={classes.selectContainer}
          value={
            dates_values.length > 0
              ? dates_values
                  .map((date) => moment(date).format("DD/MM/YYYY"))
                  .join(" au ")
              : "Toutes"
          }
        />
        <Dialog
          open={openModalDatePicker}
          onClose={() => {
            this.setState({ openModalDatePicker: false });
          }}
        >
          <DialogTitle key="title" id="alert-dialog-slide-title">
            <Typography>Sélectionner les Dates</Typography>
          </DialogTitle>
          <DialogContent>
            <Button
              color="primary"
              onClick={this.onClickDatePeriode.bind(this, 0)}
            >
              M
            </Button>
            <Button
              color="primary"
              onClick={this.onClickDatePeriode.bind(this, 1)}
            >
              M-1
            </Button>
            <Button
              color="primary"
              onClick={this.onClickDatePeriode.bind(this, 3)}
            >
              3 mois
            </Button>
            <Button
              color="primary"
              onClick={this.onClickDatePeriode.bind(this, 6)}
            >
              6 mois
            </Button>
            <Button
              color="primary"
              onClick={this.onClickDatePeriode.bind(this, 12)}
            >
              12 mois
            </Button>
            <Button
              color="primary"
              onClick={() => {
                this.setState({
                  date_filter_date_debut: "",
                  date_filter_date_fin: "",
                });
              }}
            >
              Toutes
            </Button>
            <Grid container spacing={2}>
              <TextField
                label="Date début"
                margin="normal"
                className={classes.dateInput}
                type="date"
                value={date_filter_date_debut}
                onChange={(e) => {
                  this.setState({ date_filter_date_debut: e.target.value });
                }}
                InputLabelProps={{
                  shrink: true,
                }}
                variant="standard"
              />
              <TextField
                label="Date fin"
                margin="normal"
                className={classes.dateInput}
                type="date"
                value={date_filter_date_fin}
                onChange={(e) => {
                  this.setState({ date_filter_date_fin: e.target.value });
                }}
                InputLabelProps={{
                  shrink: true,
                }}
                variant="standard"
              />
            </Grid>
            <div className={classes.radioGroup}>
              <FormControlLabel
                control={
                  <Checkbox
                    color="primary"
                    checked={this.state.type_date_filter_Checkbox.includes(
                      "date_livraison"
                    )}
                    onClick={(e) => this.handleChecked("date_livraison", e)}
                  />
                }
                label={"date_livraison"}
              />
              <FormControlLabel
                control={
                  <Checkbox
                    color="primary"
                    checked={this.state.type_date_filter_Checkbox.includes(
                      "date_enlevement"
                    )}
                    onClick={(e) => this.handleChecked("date_enlevement", e)}
                  />
                }
                label={"date_enlevement"}
              />
            </div>
          </DialogContent>
          <DialogActions>
            <Button
              onClick={() => this.setState({ openModalDatePicker: false })}
            >
              Annuler
            </Button>
            <Button
              onClick={() =>
                this.setState(
                  { openModalDatePicker: false, date_filter: date_filter_new },
                  () => {
                    this.onChangeHandlerFilters("date_filter", {
                      target: { value: date_filter_new },
                    });
                  }
                )
              }
              color="primary"
            >
              Valider
            </Button>
          </DialogActions>
        </Dialog>
      </>
    );
  }

  getAffairesFilters() {
    const { classes } = this.props;

    const clientFilter = this.getClientFilter();
    const dateFilter = this.getDateFilter();
    const fournisseurFilter = this.getFournisseurFilter();
    const referencesFilter = this.getReferenceFilter();
    const sitesFilter = this.getSitesFilter();

    const date_grid = dateFilter ? (
      <Grid item xs={12}>
        {dateFilter}
      </Grid>
    ) : null;
    return (
      <Grid container>
        <Grid item xs={12}>
          <Typography
            key="typo"
            variant="button"
            gutterBottom
            className={classes.crudTitle}
          >
            Filtres
          </Typography>
        </Grid>
        <Grid item xs={12}>
          {clientFilter}
        </Grid>
        <Grid item xs={12}>
          {fournisseurFilter}
        </Grid>
        <Grid item xs={12}>
          {referencesFilter}
        </Grid>
        <Grid item xs={12}>
          {sitesFilter}
        </Grid>
        {date_grid}
      </Grid>
    );
  }

  getFilterValue(value) {
    return value.length === 1 && value[0] === "all" ? null : value;
  }

  loadAsyncData(filters) {
    let { dispatch, data_params, affairesStore, entreprise_uuid } = this.props;
    if (affairesStore.fetching) return;
    const {
      date_filter,
      selectedTab,
      client_uuid,
      fournisseur_uuid,
      reference_affaire,
      site_filter,
      type_date_filter_Checkbox,
      wanted_page,
    } = this.state;
    if (!data_params) data_params = {};
    data_params.params = {
      ...data_params.params,
      photo_date_filter: this.getFilterValue(date_filter),
      // type_date_filter: type_date_filter,
      client_uuid: client_uuid,
      fournisseur_uuid: fournisseur_uuid,
      reference_affaire: reference_affaire,
      site_filter: site_filter,
      type_date_filter_Checkbox: type_date_filter_Checkbox,
      page: wanted_page,
      ...filters,
      orderBy: "reference_interne",
    };
    if (entreprise_uuid) {
      data_params.params.entreprise_uuid = entreprise_uuid;
    }
    if (selectedTab !== "logistique") {
      data_params.params.logisticien_filter = null;
    }

    // Gardes fou pour éviter que des requêtes partent sans pagination
    if (!data_params.params.oniti_pagination) {
      data_params.params.oniti_pagination = 1;
    }
    if (!data_params.params.oniti_perpage) {
      data_params.params.oniti_perpage = 15;
    }

    return collectionActions(
      dispatch,
      "affaires",
      "INDEX",
      data_params,
      null,
      true
    ).then((res) => {
      this.setState({
        result_last_page: res.data.last_page,
        result_current_page: res.data.current_page,
      });
    });
  }

  affaireForm() {
    return (
      <>
        {this.getAffairesFilters()}
        {this.displayAffaireList()}
      </>
    );
  }

  onChangegetEntrepriseHandler(name, event) {
    this.setState({
      entreprise_uuid: event.target.value,
      disable_photo: false,
    });

    collectionActions(this.props.dispatch, "entreprises", "SHOW", {
      uuid: event.target.value,
    }).then((res) => {
      if (res && res.data) {
        this.setState({
          entreprise: res.data,
        });
      }
    });
  }

  getEntreprisesFilters() {
    const { entreprisesStore } = this.props;
    const { entreprise_uuid } = this.state;

    return (
      <>
        <Grid item xs={12}>
          <TextInput
            id="entreprise_uuid"
            label="Entreprise"
            value={entreprise_uuid}
            margin="normal"
            collectionStore={entreprisesStore}
            name="entreprise_uuid"
            onChangeHandler={this.onChangegetEntrepriseHandler.bind(this)}
            type="autocomplete"
            autocompleteProps={{
              collectionName: "entreprises",
            }}
            InputProps={{
              endAdornment: (
                <IconButton
                  sx={{ visibility: entreprise_uuid ? "visible" : "hidden" }}
                  onClick={this.handleClearClient.bind(this, "entreprise_uuid")}
                >
                  <Clear />
                </IconButton>
              ),
            }}
            fullWidth
          />
        </Grid>
      </>
    );
  }

  entrepriseForm() {
    const { entreprise_uuid } = this.state;

    return (
      <>
        {this.getEntreprisesFilters()}
        <DocumentPicker
          documentable_type="App\Models\Entreprise"
          documentable_uuid={entreprise_uuid}
        />
      </>
    );
  }

  planningForm() {
    return (
      <>
        <Planification
          forModulePhoto={true}
          callPhotoDialog={this.handleModalPhoto.bind(this)}
        />
      </>
    );
  }

  displayForm() {
    switch (this.state.module) {
      case "affaire":
        return this.affaireForm();
      case "entreprise":
        return this.entrepriseForm();
      case "planning":
        return this.planningForm();
      default:
        return null;
    }
  }

  handleClick(moduleName) {
    this.setState({ module: moduleName });
  }

  displayButtons() {
    const { module } = this.state;

    return (
      <>
        <Button
          variant="contained"
          component="label"
          onClick={this.handleClick.bind(this, "planning")}
          color={module === "planning" ? "primary" : "default"}
        >
          Planning
        </Button>
        <Button
          variant="contained"
          component="label"
          onClick={this.handleClick.bind(this, "affaire")}
          color={module === "affaire" ? "primary" : "default"}
        >
          Affaire
        </Button>
        <Button
          variant="contained"
          component="label"
          onClick={this.handleClick.bind(this, "entreprise")}
          color={module === "entreprise" ? "primary" : "default"}
        >
          Entreprise
        </Button>
      </>
    );
  }

  handleModalPhoto(affaire) {
    const { module, entreprise } = this.state;
    const { dispatch } = this.props;

    let documentableUuid = null;
    let documentableType = null;

    if (module === "affaire" || module === "planning") {
      this.setState({ localAffaire: affaire });
      documentableUuid = affaire.uuid;
      documentableType = "App\\Models\\Affaire";
    }
    if (module === "entreprise") {
      documentableUuid = entreprise.uuid;
      documentableType = "App\\Models\\Entreprise";
    }
    collectionActions(dispatch, "documents", "INDEX", {
      params: {
        type_document: "photo",
        documentable_type: documentableType,
        documentable_uuid: documentableUuid,
      },
    }).then(() => this.setState({ openPhotoDialog: true }));
  }

  getDate(string) {
    if (!string) {
      return "";
    }
    let date = new Date(string);
    let options = {
      day: "numeric",
      month: "long",
      year: "numeric",
      hour: "2-digit",
      minute: "2-digit",
    };

    return date.toLocaleDateString("fr-FR", options);
  }

  handleNavigation(direction) {
    let page = this.state.result_current_page;
    if (direction === "next") {
      page = page + 1;
    } else {
      page = page - 1;
    }
    this.setState({ wanted_page: page }, () => {
      this.loadAsyncData(this.state.current_filter, true);
    });
  }

  displayAffaireList() {
    const { affairesStore, classes } = this.props;
    const { result_last_page, result_current_page } = this.state;
    const affaires = affairesStore.list;
    const listAffaires = affaires?.map((affaire) => {
    const matieres = (
        <ul key={"ul-" + affaire.reference_interne}>
          {affaire.lots.map((index, key) => {
            let lot = affaire.lots[key];
            return (
              <li key={lot.uuid}>
                {lot.conditionnement_nom} / {lot.article_famille_nom} /{" "}
                {lot.article_nom}
              </li>
            );
          })}
        </ul>
      );
      return (
        <>
          <ListItem key={affaire.uuid}>
            <ListItemAvatar>
              <DocumentPicker
                documentable_type="App\Models\Affaire"
                documentable_uuid={affaire.uuid}
                hasDocument={affaire.has_document}
              />
            </ListItemAvatar>
            <ListItemText
              primary={affaire.fournisseur_nom}
              secondary={
                <>
                  <List>
                    <ListItem>Réf : {affaire.reference_interne}</ListItem>
                    {matieres}
                    <span>
                      <ListItem>
                        <LocalShipping className={classes.iconeList} />{" "}
                        {affaire.transporteur_nom} ({affaire.transport_nom})
                      </ListItem>
                    </span>
                    <span>
                      <ListItem>
                        <BusinessCenter fontSize={"small"} />
                        {affaire.planning_commercial_initiales}
                      </ListItem>
                    </span>
                    <ListItem>
                      <Person className={classes.iconeList} />{" "}
                      {affaire.client_nom}
                    </ListItem>
                    <ListItem>
                      {" "}
                      Livraison : {this.getDate(affaire.date_livraison)}
                    </ListItem>
                    <ListItem>
                      {" "}
                      Enlèvement : {this.getDate(affaire.date_enlevement)}
                    </ListItem>
                  </List>
                </>
              }
            />
          </ListItem>
          <Divider />
        </>
      );
    });

    const navigationBtn = (
      <div className={classes.navigationButton}>
        <IconButton onClick={this.handleNavigation.bind(this, "previous")}>
          <SkipPrevious />
        </IconButton>
        {result_current_page ? (
          <>
            page {result_current_page} sur {result_last_page}
          </>
        ) : null}
        {result_current_page === result_last_page ? (
          <IconButton disabled>
            <SkipNext />
          </IconButton>
        ) : (
          <IconButton onClick={this.handleNavigation.bind(this, "next")}>
            <SkipNext />
          </IconButton>
        )}
      </div>
    );

    return (
      <>
        <List
          sx={{ width: "100%", maxWidth: 360, bgcolor: "background.paper" }}
        >
          {listAffaires}
          {navigationBtn}
        </List>
      </>
    );
  }

  getTypesDocument() {
    const { typeDocuments } = this.props;

    const options = typeDocuments?.list?.map((typeDocument) => {
      if (typeDocument.type_photo) {
        return (
          <MenuItem value={typeDocument} key={typeDocument}>
            {typeDocument.libelle}
          </MenuItem>
        );
      }
    });
    return options;
  }

  onChangeSelectDocumentype(name, event) {
    this.setState({
      [name]: event.target.value,
    });
    if (event.target.value) {
      this.setState({ disable_photo: false });
    }
  }

  displayPhotos() {
    const { documentsStore, classes } = this.props;

    const photoList = documentsStore.list ?? [];

    const photos = photoList.map((photo) => {
      return (
        <ListItem key={photo.uuid} className={classes.photoList}>
          {/* <ListItemAvatar > */}
          <img
            alt={photo.type_document_libelle}
            src={photo.url}
            className={classes.photoListImg}
          />
          {/* </ListItemAvatar> */}
          <ListItemText primary={photo.type_document_libelle} />
        </ListItem>
      );
    });
    return (
      <>
        <List
          sx={{ width: "100%", maxWidth: 360, bgcolor: "background.paper" }}
        >
          {photos}
        </List>
      </>
    );
  }

  handleClosePhotoDialog() {
    this.setState({ openPhotoDialog: false });
  }

  photoDialog() {
    const { classes } = this.props;
    const { openPhotoDialog, localAffaire, module, entreprise } = this.state;

    let title = "";
    let infoContent = null;

    switch (module) {
      case "affaire":
        title = "Affaire :" + localAffaire?.reference_interne;
        infoContent = (
          <>
            <DialogContentText>
              <LocalShipping className={classes.iconeList} />{" "}
              {localAffaire?.fournisseur_nom}
            </DialogContentText>
            <DialogContentText>
              <Person className={classes.iconeList} />{" "}
              {localAffaire?.client_nom}
            </DialogContentText>
            <DialogContentText>
              {localAffaire?.date_enlevement}
            </DialogContentText>
          </>
        );
        break;
      case "entreprise":
        title = "Entreprise : " + entreprise?.nom;
        break;
      default:
        break;
    }

    return (
      <Dialog
        open={openPhotoDialog}
        onClose={this.handleClosePhotoDialog.bind(this)}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">
          <PhotoCamera className={classes.iconeList} />
          {title}
        </DialogTitle>
        <div className={classes.affaireInformations}>
          {infoContent}
          <InputLabel>Type de document</InputLabel>
          <Select
            id="type_document"
            value={this.state.type_document}
            onChange={this.onChangeSelectDocumentype.bind(
              this,
              "type_document"
            )}
            fullWidth
          >
            {this.getTypesDocument()}
          </Select>
        </div>
        {this.displayCameraBtn()}
        <DialogContent>
          <DialogContentText>
            <Typography variant="h6">Photos</Typography>
          </DialogContentText>
          <Divider />
          {this.displayPhotos()}
        </DialogContent>
        <DialogActions>
          <Button onClick={this.handleClosePhotoDialog.bind(this)} autoFocus>
            Fermer
          </Button>
        </DialogActions>
      </Dialog>
    );
  }

  displayCameraBtn() {
    const { classes } = this.props;
    const { type_document } = this.state;

    return (
      <div style={{ display: "flex", justifyContent: "space-evently" }}>
        <IconButton
          disabled={!type_document}
          variant="contained"
          component="label"
          className={classes.photoBtn}
        >
          <PhotoCamera className={classes.iconBtn} />
          <input
            type="file"
            accept="image/*"
            capture="environment"
            hidden
            onChange={this.onChangeFile.bind(this)}
          />
        </IconButton>
        <IconButton
          disabled={!type_document}
          variant="contained"
          component="label"
          className={classes.photoBtn}
        >
          <FileCopy className={classes.iconBtn} />
          <input
            type="file"
            accept="image/*"
            hidden
            onChange={this.onChangeFile.bind(this)}
          />
        </IconButton>
      </div>
    );
  }

  componentDidMount() {
    loadCollectionAttribute(
      this.props.dispatch,
      "list",
      "type_documents",
      this.props.typeDocuments
    );

    //on récupère la listes des sites ?
    axiosClient.get("/flux/getInternalSites").then((response) => {
      this.setState({
        internalSite: response.data,
        sites_loaded: true,
      });
    });
  }

  render() {
    const { classes } = this.props;

    return (
      <>
        <Grid className={classes.BtnModuleContainer}>
          {this.displayButtons()}
        </Grid>
        {this.displayForm()}
        {this.photoDialog()}
      </>
    );
  }
}

Photo = withStyles(PhotoCss)(Photo);

Photo = connect((store) => {
  return {
    affairesStore: store.collections.affaires,
    entreprisesStore: store.collections.entreprises,
    user: store.auth.user,
    typeDocuments: store.collections.type_documents,
    documentsStore: store.collections.documents,
  };
})(Photo);

export default Photo;
