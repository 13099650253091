import React, { Component } from "react";
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";
import { ListItem, ListItemIcon, ListItemText } from "@material-ui/core";
import LeftMenuButtonCss from "./css/LeftMenuButtonCss";
import { withStyles } from "@material-ui/core/styles/index";
import PropTypes from "prop-types";
import { isMatchingRoute } from "../../../router/Tools";
import { routes } from "./routes/routes";
import { hasRightsForRoute } from "../../common/Tools/Tools";

class LeftMenuButton extends Component {
  state = {
    currentRoute: false,
  };

  routes_allowed = [];

  /**
   * Retourne le nouveau state en fonction des nextProps
   * @param  {[type]} nextProps [description]
   * @param  {[type]} prevState [description]
   * @return {[type]}           [description]
   */
  static getDerivedStateFromProps(nextProps, prevState) {
    let oneMatching = false;
    nextProps.button.matchingPath.forEach((path) => {
      if (isMatchingRoute(path, nextProps.location.pathname) && !oneMatching) {
        oneMatching = true;
      }
    });

    if (prevState.currentRoute !== oneMatching) {
      return {
        currentRoute: oneMatching,
      };
    } else return null;
  }

  onClickTodoHandler() {
    this.props.history.push(this.getLink());
    // supprime les infos de filtres enregistrées en local storage
    if(this.props.button.localStorageFilterKey && this.props.button.localStorageFilterKey.length > 0){
      for(let i = 0; i < this.props.button.localStorageFilterKey.length; i++){
        localStorage.removeItem(this.props.button.localStorageFilterKey[i]);
      }
    }
  }

  getLink() {
    return this.routes_allowed[0].path;
  }

  hasRight() {
    const { currentUser } = this.props;
    this.routes_allowed = routes.filter((route) =>
      this.props.button.matchingPath.some(
        (path) =>
          isMatchingRoute(route.path, path) &&
          (route.public ||
            (currentUser && currentUser.rights.includes(route.right)) ||
            hasRightsForRoute(route, currentUser))
      )
    );
    return this.routes_allowed.length > 0;
  }

  render() {
    const { classes } = this.props,
      currentRoute = this.state.currentRoute;

    if (this.hasRight()) {
      return (
        <ListItem
          button
          onClick={this.onClickTodoHandler.bind(this)}
          title={this.props.button.label}
          classes={{
            root: currentRoute ? classes.listItemCurrent : classes.listItem,
          }}
        >
          <ListItemIcon
            classes={{
              root: currentRoute
                ? classes.listItemIconCurrent
                : classes.listItemIcon,
            }}
          >
            {this.props.button.icon}
          </ListItemIcon>
          <ListItemText
            primary={this.props.button.label}
            classes={{
              primary: currentRoute
                ? classes.listItemTextCurrent
                : classes.listItemText,
            }}
          />
        </ListItem>
      );
    } else return null;
  }
}

LeftMenuButton.propTypes = {
  button: PropTypes.object.isRequired,
};

LeftMenuButton = withStyles(LeftMenuButtonCss, { withTheme: true })(
  LeftMenuButton
);
LeftMenuButton = withRouter(LeftMenuButton);

LeftMenuButton = connect((store) => {
  return {
    location: store.router.location,
    currentUser: store.auth.user,
  };
})(LeftMenuButton);

export default LeftMenuButton;
