import React, { Component } from "react";

import { Grid, Typography } from "@material-ui/core";
import { withStyles } from "@material-ui/core/styles";
import { Settings } from "@material-ui/icons";

import CollectionCrud from "../../common/Components/CollectionCrud";
import TextInput from "../../common/Components/TextInput";
import ConditionnementCss from "./css/ConditionnementCss";

class Conditionnement extends Component {
  getDeleteModalTitle() {
    return (
      <div>
        <Settings />
        <span>{"Suppression du conditionnement"}</span>
      </div>
    );
  }

  getDeleteModalContent() {
    return (
      <Typography>
        {"Êtes-vous sûr de vouloir supprimer ce conditionnement ?"}
      </Typography>
    );
  }

  getCreateUpdateModalTitle(conditionnement) {
    let title = !!conditionnement
      ? "Modification du conditionnement : " + conditionnement.nom
      : "Ajout d'un nouveau conditionnement";
    return (
      <div>
        <Settings />
        <span>{title}</span>
      </div>
    );
  }

  getCreateUpdateModalContent(
    conditionnement,
    conditionnementsStore,
    onChangeHandler
  ) {
    let { libelle_fr, libelle_en, annexe_VII_libelle, code } = conditionnement;
    return (
      <Grid container>
        <Grid item xs={12}>
          <TextInput
            id="libelle_fr"
            label="Libelle FR"
            value={libelle_fr}
            margin="normal"
            collectionStore={conditionnementsStore}
            name="libelle_fr"
            onChangeHandler={onChangeHandler}
            fullWidth
          />
        </Grid>
        <Grid item xs={12}>
          <TextInput
            id="libelle_en"
            label="Libelle EN"
            value={libelle_en}
            margin="normal"
            collectionStore={conditionnementsStore}
            name="libelle_en"
            onChangeHandler={onChangeHandler}
            fullWidth
          />
        </Grid>
        <Grid item xs={6}>
          <TextInput
            id="code"
            label="Code"
            value={code}
            required={true}
            margin="normal"
            collectionStore={conditionnementsStore}
            name="code"
            onChangeHandler={onChangeHandler}
            fullWidth
          />
        </Grid>
        <Grid item xs={12}>
          <TextInput
            id="annexe_VII_libelle"
            label="Libelle Annexe VII"
            value={annexe_VII_libelle}
            margin="normal"
            collectionStore={conditionnementsStore}
            name="annexe_VII_libelle"
            onChangeHandler={onChangeHandler}
            fullWidth
          />
        </Grid>
      </Grid>
    );
  }

  render() {
    const { classes } = this.props;
    return (
      <Grid container className={classes.container}>
        <CollectionCrud
          collectionName={"conditionnements"}
          showBtnEdit={true}
          showBtnAdd={true}
          showBtnDelete={true}
          rights={{
            create: "admin-cud-parametres",
            edit: "admin-cud-parametres",
            delete: "admin-cud-parametres",
          }}
          deleteModalTitle={this.getDeleteModalTitle}
          deleteModalContent={this.getDeleteModalContent}
          createUpdateModalTitle={this.getCreateUpdateModalTitle}
          createUpdateModalContent={this.getCreateUpdateModalContent.bind(this)}
          datatableConfig={{
            showPagination: true,
            showSearch: true,
            defaultSort: "libelle_fr",
            sortType: "desc",
            rowsPerPageOptions: [10, 25, 50],
          }}
          cellsConfig={[
            {
              datakey: "libelle_fr",
              title: "Libelle FR",
              sortable: true,
              searchable: true,
            },
            {
              datakey: "libelle_en",
              title: "Libelle EN",
              sortable: true,
              searchable: true,
            },
            {
              datakey: "annexe_VII_libelle",
              title: "Libelle Annexe VII",
              sortable: true,
              searchable: true,
            },
          ]}
        />
      </Grid>
    );
  }
}

Conditionnement = withStyles(ConditionnementCss)(Conditionnement);

export default Conditionnement;
