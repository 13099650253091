import React, { Component } from "react";
import { connect } from "react-redux";
import { withStyles } from "@material-ui/core/styles";
import {
  CircularProgress,
  DialogContent,
  DialogTitle,
  FormControl,
  IconButton,
  InputLabel,
  Link,
  MenuItem,
  Select,
} from "@material-ui/core";
import {
  Grid,
  Typography,
  Table,
  TableHead,
  TableBody,
  TableCell,
  TableRow,
  Tabs,
  Tab,
  Paper,
} from "@material-ui/core";
import {
  getStockInfosByMois,
  getStockInfosByPeriod,
} from "./actions/NegoceSupervisionActions";

import HomeCss from "./css/SupervisionCss";
import { buildRoute } from "../../../router/Tools";
import { Cell, DataTable } from "@oniti/datatable-material";
import { Info, InfoSharp } from "@material-ui/icons";
import Modal from '../../common/Components/Modal';

class StockSupervision extends Component {
  state = {
    datas: [],
    selectedTab: 0,
    prix_transport_total: null,
    openedList: [],
    loading: false,
    category: "raison-sociale",
    openModalDetail: false,
    currentDetail: null,
    currentDetailName: null,
    col_ca_title:null,
    col_prix_transport:null,
  };

  componentDidMount() {
    this.fetchData(this.props, this.state);
  }

  componentDidUpdate(prevProps) {
    if (
      prevProps.date_debut !== this.props.date_debut ||
      prevProps.date_fin !== this.props.date_fin ||
      prevProps.mois !== this.props.mois ||
      prevProps.commercial_uuid !== this.props.commercial_uuid
    ) {
      this.fetchData(this.props, this.state);
    }
  }

  // Handler sur le changement d'onglet
  handleChangeTab(event, selectedTab) {
    this.setState({ selectedTab });
  }

  // Créer un tableau de valeurs
  createData(name, value, unit) {
    return { name, value, unit };
  }

  // Formattage des prix
  formatPrix(prix) {
    if (!prix) {
      return 0;
    }
    const inf = new Intl.NumberFormat("fr-FR", {
      minimumFractionDigits: 0,
      maximumFractionDigits: 0,
    });
    return inf.format(prix);
  }

  toUpperCase(text) {
    return text.toUpperCase();
  }

  // Retourne le tableau d'informations générales
  getInfosGeneralesEntrees() {
    if (this.state.datas.find((data) => data.nom === "entrees") === undefined)
      return;

    let infosEntrees = this.state.datas.find(
      (data) => data.nom === "entrees"
    ).data;

    if (!infosEntrees) return null;
  }

  getArticles(values) {
    return values.map((article) => {
      return (
        <TableRow>
          <TableCell>{article.nom}</TableCell>
          <TableCell>{this.formatPrix(article.ca_achat)}</TableCell>
          <TableCell>{article.tonnage}</TableCell>
          <TableCell>{this.formatPrix(article.prix_transport)}</TableCell>
        </TableRow>
      );
    });
  }

  extractSorted(sorted) {
    return Object.keys(sorted).map((key) =>
      this.createData(key, sorted[key], "")
    );
  }
  // Retourne le tableau des infos principales des Entrées Stock
  getInfosEntreesTab() {
    const { classes } = this.props;

    if (this.state.datas.find((data) => data.nom === "entrees") === undefined)
      return;

    let infos = this.state.datas.find((data) => data.nom === "entrees").data;

    if (!infos) return null;

    const rowsAffaire = [
      this.createData("Nombre d'Affaires", infos.nb_affaires),
      ...this.extractSorted(infos.sorted),
    ];
    const rowsCA = [
      this.createData(
        "Cout matière première",
        this.formatPrix(infos.ca_achat_matiere),
        "€"
      ),
      this.createData(
        "Transport sur achats",
        this.formatPrix(infos.ca_achat_transport),
        "€"
      ),
      this.createData(
        "Tonnage Achats",
        this.formatPrix(infos.tonnage_achat),
        "T"
      ),
    ];

    return (
      <>
        <Typography variant="h6" color="primary" className={classes.tableTitle}>
          Entrées
        </Typography>
        <Paper>
          <Table style={{ height: "100%" }}>
            <TableBody>
              {rowsAffaire.map((row) => (
                <TableRow key={row.name} className={classes.rowA}>
                  <TableCell className={classes.tableCell}>
                  { row.name === "Nombre d'Affaires" ? (<span title="Affaires Hors Consigne et hors sans articles" style={{cursor:'pointer'}} ><Info className={classes.tooltip} /></span>) : null } {row.name}
                  </TableCell>
                  <TableCell align="right">
                    {row.value} {row.unit}
                  </TableCell>
                </TableRow>
              ))}
              {rowsCA.map((row) => (
                <TableRow key={row.name} className={classes.rowB}>
                  <TableCell className={classes.tableCell}>
                    {row.name}
                  </TableCell>
                  <TableCell align="right">
                    {row.value} {row.unit}
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </Paper>
      </>
    );
  }

  filterCallback(conf, datas, order) {
    const key = conf ? conf.datakey : "nom";
    const total = datas.find((d) => d.nom === "TOTAL");
    let data_filtered = datas.filter((d) => d.nom !== "TOTAL");
    data_filtered.sort(function (a, b) {
      var valueA = a[key],
        valueB = b[key];

      if (key !== "nom") {
        if (typeof valueA === "string" && typeof valueB === "string") {
          valueA = parseFloat(
            valueA
              .replace("€", "")
              .replace("%", "")
              .replace("T", "")
              .replace(" ", "")
              .replace(",", ".")
          );
          valueB = parseFloat(
            valueB
              .replace("€", "")
              .replace("%", "")
              .replace("T", "")
              .replace(" ", "")
              .replace(",", ".")
          );
        }
      }

      if (valueA < valueB) return order === "asc" ? 1 : -1;
      if (valueA > valueB) return order === "asc" ? -1 : 1;

      return 0;
    });

    if(total) data_filtered.unshift(total);

    return data_filtered;
  }

  // Retourne le tableau des infos principales des Sorties Stock
  getInfosSortiesTab() {
    const { classes } = this.props;

    if (this.state.datas.find((data) => data.nom === "sorties") === undefined)
      return;

    let infos = this.state.datas.find((data) => data.nom === "sorties").data;

    if (!infos) return null;

    const rowsAffaire = [
      this.createData("Nombre d'Affaires", infos.nb_affaires),
      ...this.extractSorted(infos.sorted),
    ];
    const rowsCA = [
      this.createData("CA Vente", this.formatPrix(infos.ca_vente_matiere), "€"),
      this.createData(
        "Transport sur vente",
        this.formatPrix(infos.ca_vente_transport),
        "€"
      ),
      this.createData(
        "Tonnage Vente",
        this.formatPrix(infos.tonnage_vente),
        "T"
      ),
    ];
    return (
      <>
        <Typography variant="h6" color="primary" className={classes.tableTitle}>
          Sorties
        </Typography>
        <Paper>
          <Table style={{ height: "100%" }}>
            <TableBody>
              {rowsAffaire.map((row) => (
                <TableRow key={row.name} className={classes.rowA}>
                  <TableCell className={classes.tableCell}>
                  { row.name === "Nombre d'Affaires" ? (<span title="Affaires Hors Consigne et hors sans articles" style={{cursor:'pointer'}} ><Info className={classes.tooltip} /></span>) : null } {row.name}
                  </TableCell>
                  <TableCell align="right">
                    {row.value} {row.unit}
                  </TableCell>
                </TableRow>
              ))}
              {rowsCA.map((row) => (
                <TableRow key={row.name} className={classes.rowB}>
                  <TableCell className={classes.tableCell}>
                    {row.name}
                  </TableCell>
                  <TableCell align="right">
                    {row.value} {row.unit}
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </Paper>
      </>
    );
  }

  // Retourne le tableau des totaux Stock
  getInfosTotauxTab() {
    const { classes } = this.props;
    const data = this.state.datas.find((data) => data.nom === "totaux");
    if (data === undefined) return;
    const info = data.data;

    const rowsAffaire = [
      this.createData(
        "Factures annexes entrantes",
        this.formatPrix(info.facture_annexes_entrantes),
        "€"
      ),
      this.createData(
        "Factures annexes sortantes",
        this.formatPrix(info.facture_annexes_sortantes),
        "€"
      ),
      this.createData(
        "Transport Vente",
        this.formatPrix(info.total_transport_vente),
        "€"
      ),
      this.createData(
        "Marge - annexe inclus",
        this.formatPrix(info.marge_annexe_inclus),
        "€"
      ),

    ];

    return (
      <>
        <Typography variant="h6" color="primary" className={classes.tableTitle}>
          Totaux
        </Typography>
        <Paper>
          <Table style={{ height: "100%" }}>
            <TableBody>
              {rowsAffaire.map((row) => (
                <TableRow key={row.name} className={classes.rowA}>
                  <TableCell className={classes.tableCell}>
                    {row.name}
                  </TableCell>
                  <TableCell align="right">
                    {row.value} {row.unit}
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </Paper>
      </>
    );
  }

  // Retourne les tableaux détaillés des Entrées/Sorties stock
  getTabs(selectedTab) {
    return (
      <Grid container spacing={2}>
        <Grid item xs={5}>
          {this.getAttenteTab()}
        </Grid>
        <Grid item xs={7}>
          {this.getMainTab(selectedTab)}
        </Grid>
      </Grid>
    );
  }

  // Retourne le tableau d'attente facturation des Entrées Stock
  getAttenteTab() {
    const { classes } = this.props;

    let attentes = null;
    if (this.state.selectedTab === 0) {
      if (
        this.state.datas.find((data) => data.nom === "attentes_entrees") ===
        undefined
      )
        return;
      attentes = this.state.datas.find(
        (data) => data.nom === "attentes_entrees"
      ).data;
    } else {
      if (
        this.state.datas.find((data) => data.nom === "attentes_sorties") ===
        undefined
      )
        return;
      attentes = this.state.datas.find(
        (data) => data.nom === "attentes_sorties"
      ).data;
    }

    if (!attentes || attentes.length === 0) return null;

    let max_nb = 0;
    let max_array_key = null;

    if (attentes.fournisseur) {
      if (attentes.fournisseur.length > max_nb) {
        max_nb = attentes.fournisseur.length;
        max_array_key = "fournisseur";
      }
    }
    if (attentes.transporteur) {
      if (attentes.transporteur.length > max_nb) {
        max_nb = attentes.transporteur.length;
        max_array_key = "transporteur";
      }
    }
    if (attentes.client) {
      if (attentes.client.length > max_nb) {
        max_nb = attentes.client.length;
        max_array_key = "client";
      }
    }

    if (max_array_key === null) return null;

    return (
      <>
        <Typography variant="h6" color="primary" className={classes.tableTitle}>
          Attente Facturation
        </Typography>
        <Paper className={classes.attenteTab}>
          <Table className={classes.inPaperTab} stickyHeader>
            <TableHead>
              <TableRow>
                {this.state.selectedTab === 0 ? (
                  <TableCell align="center">Fournisseurs</TableCell>
                ) : (
                  <TableCell align="center">6 - Attente facturation & facture client à établir</TableCell>
                )}
                <TableCell align="center">Transporteurs</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {attentes[max_array_key].map((value, index) => {
                return (
                  <TableRow key={value.reference}>
                    {this.state.selectedTab === 0 ? (
                      <TableCell align="center" className={classes.tableCell}>
                        {attentes.fournisseur && attentes.fournisseur[index]
                          ? this.createLinkAffaire(attentes.fournisseur[index])
                          : null}
                      </TableCell>
                    ) : (
                      <TableCell align="center" className={classes.tableCell}>
                        {attentes.client && attentes.client[index]
                          ? this.createLinkAffaire(attentes.client[index])
                          : null}
                      </TableCell>
                    )}
                    <TableCell align="center" className={classes.tableCell}>
                      {attentes.transporteur && attentes.transporteur[index]
                        ? this.createLinkAffaire(attentes.transporteur[index])
                        : null}
                    </TableCell>
                  </TableRow>
                );
              })}
            </TableBody>
          </Table>
        </Paper>
      </>
    );
  }

  createLinkAffaire(affaire) {
    return (
      <Link
        href={buildRoute("/affaires/:affaires", {
          affaires: affaire.uuid,
        })}
        target="_blank"
      >
        {affaire.reference_interne}
      </Link>
    );
  }

  // Retourne le tableau Principal des Entrées/Sorties Stock
  getMainTab(selectedTab) {
    let rows = null;
    const { classes } = this.props;
    if (selectedTab === 0) {
      rows = this.state.datas.find((data) => data.nom === "stock_mains_entrees")
        ? this.state.datas.find((data) => data.nom === "stock_mains_entrees")
            .data
        : null;
    } else {
      rows = this.state.datas.find((data) => data.nom === "stock_mains_sorties")
        ? this.state.datas.find((data) => data.nom === "stock_mains_sorties")
            .data
        : null;
    }

    if (!rows || rows.length === 0 || rows === undefined) return null;

    /* eslint-disable-next-line */
    this.state.col_ca_title = selectedTab === 0 ? "Cout matière première (€)" : "CA vente (€)";
    /* eslint-disable-next-line */
    this.state.col_prix_transport = selectedTab === 0 ? "Prix Transport (€)" : "Prix transport net (€)";

    const { col_ca_title, col_prix_transport } = this.state;

    return (
      <Paper>
        <DataTable
          datas={rows}
          defaultSort="name"
          filterCallback={this.filterCallback}
          rowClassName={(row) => {
            if (row.nom === "TOTAL") return classes.rowTotal;
            return "";
          }}
        >
          <Cell
            title=""
            sortable={false}
            className={classes.tableCell}
            datakey="nom"
            format={(obj) => {
              if(obj.nom === "TOTAL") return null;
              return (
                <IconButton
                  aria-label="expand row"
                  size="small"
                  onClick={() => {
                    this.setState({
                      openModalDetail: true,
                      currentDetail: obj.details,
                      currentDetailName: obj.nom,
                    });
                  }}
                >
                  <InfoSharp />
                </IconButton>
              );
            }}
          />
          <Cell
            className={classes.tableCell}
            datakey="nom"
            sortable={true}
            title="Nom"
          />
          <Cell
            className={classes.tableCell}
            datakey="total_ca"
            sortable={true}
            title={col_ca_title}
            format={(obj, key) => {
              if (!obj) return null;
              return this.formatPrix(obj[key]);
            }}
          />
          <Cell
            className={classes.tableCell}
            datakey="tonnage"
            sortable={true}
            title="Tonnes"
            format={(obj, key) => {
              if (!obj) return null;
              return this.formatPrix(obj[key]);
            }}
          />
          <Cell
            className={classes.tableCell}
            datakey="prix_transport"
            sortable={true}
            title={col_prix_transport}
            format={(obj, key) => {
              if (!obj) return null;
              return this.formatPrix(obj[key]);
            }}
          />
        </DataTable>
      </Paper>
    );
  }

  fetchData(props, state) {
    if (props.selectedValue === "mois") {
      this.setState({
        loading: true,
      });
      getStockInfosByMois(
        props.mois,
        props.commercial_uuid,
        state.category
      ).then((datas) => {
        this.setState({ datas: datas, loading: false });
      });
    } else {
      this.setState({
        loading: true,
      });
      getStockInfosByPeriod(
        props.date_debut,
        props.date_fin,
        props.commercial_uuid,
        state.category
      ).then((datas) => {
        this.setState({ datas: datas, loading: false });
      });
    }
  }

  // Handler sur le changement de catégorie
  handleChangeCategory = (event) => {
    this.setState({ category: event.target.value }, () => {
      this.fetchData(this.props, this.state);
    });
  };
  getCategories() {
    const { classes } = this.props;
    return (
      <>
        <FormControl className={classes.formControl}>
          <InputLabel>Catégorie</InputLabel>
          <Select
            value={this.state.category}
            onChange={this.handleChangeCategory}
            title="Ce filtre n'affectera que le tableau principal en bas de page."
          >
            <MenuItem value={"raison-sociale"}>Raison Sociale</MenuItem>
            <MenuItem value={"article"}>Articles</MenuItem>
            <MenuItem value={"famille"}>Familles d'articles</MenuItem>
            <MenuItem value={"activite"}>Activité</MenuItem>
          </Select>
        </FormControl>
      </>
    );
  }

  getDetailDataTable(currentDetail){
    if(!currentDetail) return null
    const { classes } = this.props;
    const {col_ca_title, col_prix_transport} = this.state
    return (

      <DataTable
          datas={currentDetail}
          defaultSort="name"
          filterCallback={this.filterCallback}
        >
          <Cell
            className={classes.tableCell}
            datakey="nom"
            sortable={true}
            title="Nom"
          />
          <Cell
            className={classes.tableCell}
            datakey="total_ca"
            sortable={true}
            title={col_ca_title}
            format={(obj, key) => {
              if (!obj) return null;
              return this.formatPrix(obj[key]);
            }}
          />
          <Cell
            className={classes.tableCell}
            datakey="tonnage"
            sortable={true}
            title="Tonnes"
            format={(obj, key) => {
              if (!obj) return null;
              return this.formatPrix(obj[key]);
            }}
          />
          <Cell
            className={classes.tableCell}
            datakey="prix_transport"
            sortable={true}
            title={col_prix_transport}
            format={(obj, key) => {
              if (!obj) return null;
              return this.formatPrix(obj[key]);
            }}
          />
        </DataTable>
    )
  }

  /*
   * Rendu Final
   */
  render() {
    const { classes } = this.props;
    const { loading,currentDetailName, currentDetail,openModalDetail } = this.state;

    if (loading) {
      return (
        <Grid container>
          <Grid item xs={12}>
            <h4 className={classes.center}>
              Récupération des données auprès du serveur
            </h4>
          </Grid>
          <Grid item xs={12} className={classes.center}>
            <CircularProgress />
          </Grid>
        </Grid>
      );
    }

    return (
      <>
        {this.getCategories()}
        <Grid container>
          <Grid
            container
            xs={12}
            className={classes.infosStockContainer}
            spacing={2}
          >
            <Grid item xs={4}>
              {this.getInfosEntreesTab()}
            </Grid>
            <Grid item xs={4}>
              {this.getInfosSortiesTab()}
            </Grid>
            <Grid item xs={4}>
              {this.getInfosTotauxTab()}
            </Grid>
          </Grid>
          <Grid item xs={12}>
            <Tabs
              value={this.state.selectedTab}
              onChange={this.handleChangeTab.bind(this)}
              indicatorColor="primary"
              textColor="primary"
              centered
              style={{ marginBottom: "1em" }}
            >
              <Tab label="Entrées" />
              <Tab label="Sorties" />
            </Tabs>
            <Grid
              container
              xs={12}
              className={classes.infosStockContainer}
              spacing={2}
            >
              {this.state.selectedTab === 0 ? this.getTabs(0) : null}
              {this.state.selectedTab === 1 ? this.getTabs(1) : null}

              <Modal
                openModal={openModalDetail}
                onCloseHandler={() => {
                  this.setState({ openModalDetail: false, currentDetail: null });
                }}
                fullWidth={true}
                maxWidth="lg"
                actionSubmit="NONE"
              >
                <DialogTitle key="title" id="alert-dialog-slide-title">
                  Détails : {currentDetailName}
                </DialogTitle>
                <DialogContent key="content">
                  <Grid container>
                    <Grid item xs={12}>
                      <Paper>
                        { this.getDetailDataTable(currentDetail) }
                      </Paper>
                    </Grid>
                  </Grid>
                </DialogContent>
              </Modal>
            </Grid>
          </Grid>
        </Grid>
      </>
    );
  }
}

StockSupervision = withStyles(HomeCss)(StockSupervision);
StockSupervision = connect((store) => {
  return {
    affairesStore: store.collections.affaires,
    user: store.auth.user,
  };
})(StockSupervision);

export default StockSupervision;
