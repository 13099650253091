import React, { Component } from "react";
import { connect } from "react-redux";

import {
  Button,
  CircularProgress,
  Grid,
  MenuItem,
  Typography,
  Fab,
  DialogTitle,
  DialogContent,
} from "@material-ui/core";
import { withStyles } from "@material-ui/core/styles";
import { ChevronLeft, Done, PersonAdd } from "@material-ui/icons";
import { hasRights } from "../../common/Tools/Tools";
import {
  collectionActions,
  loadCollectionAttribute,
} from "../../../reducers/collectionsReducer";
import TextInput from "../../common/Components/TextInput";
import Modal from "../../common/Components/Modal";
import EntrepriseCss from "./css/EntrepriseCss";

import Affaire from "../Affaire";
import Contact from "../Contact";
import Etablissement from "../Etablissement";
import Potentiel from "../Potentiel";
import Note from "../Note";
import Documents from "../Documents";
import CheckboxInput from "../../common/Components/CheckboxInput";
import NonConformite from "../NonConformite";

class Detail extends Component {
  state = {
    entreprise: null,
    updated: false,
    openCommercialModal: false,
  };

  static getDerivedStateFromProps(nextProps, prevState) {
    const { entreprisesStore } = nextProps;
    if (
      !prevState.updated &&
      !!entreprisesStore.detail &&
      JSON.stringify(entreprisesStore.detail) !==
        JSON.stringify(prevState.entreprise)
    ) {
      return {
        entreprise: entreprisesStore.detail,
      };
    }
    return null;
  }

  componentDidMount() {
    let { dispatch } = this.props;

    this.refreshEntreprise();

    loadCollectionAttribute(
      dispatch,
      "list",
      "activites",
      this.props.activitesStore
    );
    loadCollectionAttribute(
      dispatch,
      "list",
      "langues",
      this.props.languesStore
    );
    loadCollectionAttribute(
      dispatch,
      "list",
      "paiements",
      this.props.paiementsStore
    );
    loadCollectionAttribute(dispatch, "list", "users", this.props.usersStore);

    loadCollectionAttribute(dispatch, "list", "source_prospects", this.props.sourceProspects);

  }

  componentWillUnmount() {
    let { dispatch } = this.props;
    collectionActions(dispatch, "entreprises", "RESET_ERRORS");
    collectionActions(dispatch, "entreprises", "RESET_DETAIL");

    collectionActions(dispatch, "documents", "RESET_LIST");
    collectionActions(dispatch, "contacts", "RESET_LIST");
    collectionActions(dispatch, "affaires", "RESET_LIST");
    collectionActions(dispatch, "notes", "RESET_LIST");
    collectionActions(dispatch, "non-conformites", "RESET_LIST");

  }

  refreshEntreprise() {
    collectionActions(this.props.dispatch, "entreprises", "SHOW", {
      uuid: this.props.match.params.uuid,
    });
  }

  onClickReturnHandler() {
    this.props.history.goBack();
  }

  onChangeEntrepriseHandler(name, event) {
    const { value } = event.target;
    const { entreprise } = this.state;
    this.setState({
      entreprise: { ...entreprise, [name]: value !== "" ? value : null },
      updated: true,
    });
  }

  onKeyPressEntrepriseHandler(event) {
    if (event.which === 13 || event.keyCode === 13 || event.key === "Enter") {
      this.onClickUpdateEntrepriseHandler();
    }
  }

  onClickUpdateEntrepriseHandler() {
    const { dispatch } = this.props;
    const { entreprise } = this.state;
    this.setState({ updated: false });
    collectionActions(dispatch, "entreprises", "UPDATE", entreprise);
  }

  getActivites() {
    if (!this.props.activitesStore.list) {
      return [];
    }
    return this.props.activitesStore.list.map((activite) => {
      return (
        <MenuItem value={activite.uuid} key={activite.uuid}>
          {activite.nom}
        </MenuItem>
      );
    });
  }

  getLangues() {
    if (!this.props.languesStore.list) {
      return [];
    }
    return this.props.languesStore.list.map((langue) => {
      return (
        <MenuItem value={langue.uuid} key={langue.uuid}>
          {langue.nom}
        </MenuItem>
      );
    });
  }

  getPaiements() {
    if (!this.props.paiementsStore.list) {
      return [];
    }
    return this.props.paiementsStore.list.map((paiement) => {
      return (
        <MenuItem value={paiement.uuid} key={paiement.uuid}>
          {paiement.nom}
        </MenuItem>
      );
    });
  }

  /**
   * Recharge l'Entreprise suite à sa mise à jour.
   *
   * @param object updated - L'Entreprise reçue après mise à jour.
   */
  updateEntreprise(updated) {
    const { entreprise } = this.state;
    this.setState({
      entreprise: { ...entreprise, principal: updated.principal },
    });
  }

  /**
   * Recharge le détail de l'Entreprise après modification des Établissements
   * afin de mettre à jour les champs du site principal en tête de page
   */
  handleChangeEtablissements() {
    const { dispatch } = this.props;
    const { entreprise } = this.state;
    collectionActions(
      dispatch,
      "entreprises",
      "SHOW",
      entreprise,
      this.updateEntreprise.bind(this)
    );
  }

  getUsers() {
    if (!this.props.usersStore.list) return;
    return this.props.usersStore.list.map((user) => {
      return (
        <MenuItem value={user.uuid} key={user.uuid}>
          {user.firstname}{" "}
          <span style={{ color: "grey", paddingLeft: "10px" }}>
            ({user.email})
          </span>
        </MenuItem>
      );
    });
  }

  getSourceProspect() {
    if (!this.props.sourceProspects.list) return;
    return this.props.sourceProspects.list.map((sourceProspect) => {
      return (
        <MenuItem value={sourceProspect.uuid} key={sourceProspect.uuid}>
          {sourceProspect.libelle}
        </MenuItem>
      );
    });
  }

  getCommercial(entreprise) {
    if (entreprise.user_uuid || this.state.entreprise.user_uuid) {
      return (
        <TextInput
          label="Commercial"
          value={
            entreprise.user_uuid
              ? entreprise.user_uuid
              : this.state.entreprise.user_uuid
          }
          margin="normal"
          collectionStore={this.props.entreprisesStore}
          name="user_uuid"
          onChangeHandler={this.onChangeEntrepriseHandler.bind(this)}
          fullWidth
          select
        >
          {this.getUsers()}
        </TextInput>
      );
    } else {
      return (
        <Fab
          variant="extended"
          style={{ backgroundColor: "white" }}
          onClick={() => {
            this.setState({
              openCommercialModal: true,
            });
          }}
        >
          <PersonAdd color="primary" />{" "}
          <p style={{ color: "#51B0FF", marginLeft: "10px" }}>
            Ajouter un commercial
          </p>
        </Fab>
      );
    }
  }

  onCloseCommercialModal() {
    this.setState({
      openCommercialModal: false,
    });
  }

  onSubmitHandlerCommercialModal() {
    this.setState({
      openCommercialModal: false,
    });
  }

  getContentCommercialModal(entreprise) {
    const { classes } = this.props;
    return (
      <>
        <DialogTitle className={classes.modalTitle}>
          <PersonAdd className={classes.ico} /> Selection du commercial
        </DialogTitle>
        <DialogContent>
          <TextInput
            label="Commercial"
            value={
              entreprise.user_uuid
                ? entreprise.user_uuid
                : this.state.entreprise.user_uuid
            }
            margin="normal"
            collectionStore={this.props.entreprisesStore}
            name="user_uuid"
            onChangeHandler={this.onChangeEntrepriseHandler.bind(this)}
            fullWidth
            select
          >
            {this.getUsers()}
          </TextInput>
        </DialogContent>
      </>
    );
  }

  getPartialDocumentFiles() {
    const { entreprise } = this.state;
    if(!entreprise) return null;

    return (
      <>
        <Documents documentable_type="App\Models\Entreprise" documentable_uuid={entreprise.uuid} />
      </>
    );
  }

  render() {
    const { entreprisesStore, classes, user } = this.props;
    const { entreprise } = this.state;
    const hasEditRight = hasRights("admin-cud-entreprises", user);

    if (!entreprise) {
      return <CircularProgress />;
    }
    const principal = entreprise.principal;
    const submitBtn = hasEditRight ? (
      <Button
        title="Valider les modifications"
        color="primary"
        variant="contained"
        size="small"
        onClick={this.onClickUpdateEntrepriseHandler.bind(this)}
        className={classes.doneButton}
      >
        <Done className={classes.buttonIcon} /> Valider
      </Button>
    ) : null;
    const etablissement_fields = (
      <>
        <Grid item xs={4}>
          <TextInput
            className={classes.totauxDisabled}
            disabled={true}
            id="adresse"
            label="Adresse"
            value={!!principal ? principal.adresse : " "}
            margin="normal"
            collectionStore={entreprisesStore}
            name="adresse"
            fullWidth
          />
        </Grid>
        <Grid item xs={4}>
          <TextInput
            className={classes.totauxDisabled}
            disabled={true}
            id="code_postal"
            label="Code postal"
            value={!!principal ? principal.code_postal : " "}
            margin="normal"
            collectionStore={entreprisesStore}
            name="adresse"
            fullWidth
          />
        </Grid>
        <Grid item xs={4}>
          <TextInput
            className={classes.totauxDisabled}
            disabled={true}
            id="ville"
            label="Ville"
            value={!!principal ? principal.ville.toUpperCase() : " "}
            margin="normal"
            collectionStore={entreprisesStore}
            name="adresse"
            fullWidth
          />
        </Grid>

        <Grid item xs={4}>
          <TextInput
            className={classes.totauxDisabled}
            disabled={true}
            id="telephone"
            label="Téléphone"
            value={
              !!principal && !!principal.telephone ? principal.telephone : " "
            }
            margin="normal"
            collectionStore={entreprisesStore}
            name="adresse"
            fullWidth
          />
        </Grid>
        <Grid item xs={4}>
          <TextInput
            className={classes.totauxDisabled}
            disabled={true}
            id="horaires"
            label="Horaires"
            value={
              !!principal && !!principal.horaires ? principal.horaires : " "
            }
            margin="normal"
            collectionStore={entreprisesStore}
            name="adresse"
            fullWidth
          />
        </Grid>
        <Grid item xs={4}>
          <TextInput
            className={classes.totauxDisabled}
            disabled={true}
            id="pays_uuid"
            label="Pays"
            value={!!principal ? principal.pays.nom : " "}
            required={true}
            margin="normal"
            collectionStore={entreprisesStore}
            name="adresse"
            fullWidth
          />
        </Grid>
      </>
    );
    return (
      <Grid container className={classes.root}>
        <Grid item xs={12}>
          <Typography
            variant="button"
            gutterBottom
            className={classes.crudTitle}
          >
            Entreprise
          </Typography>
          <Button
            title="Retour à la liste des entreprises"
            color="primary"
            className={classes.returnButton}
            onClick={this.onClickReturnHandler.bind(this, "/entreprises")}
          >
            <ChevronLeft /> Retour
          </Button>
        </Grid>
        <Grid item xs={12}>
          <CheckboxInput
            className={classes.checkbox_align}
            id="is_sep"
            label="SEP"
            value={entreprise.is_sep}
            disabled
            collectionStore={entreprisesStore}
            name="is_sep"
          />
        </Grid>
        <Grid item xs={4}>
          <TextInput
            id="nom"
            label="Raison sociale"
            className={classes.labelInput}
            value={!!entreprise.nom ? entreprise.nom.toUpperCase() : ""}
            disabled={!hasEditRight}
            margin="normal"
            collectionStore={entreprisesStore}
            name="nom"
            onChangeHandler={this.onChangeEntrepriseHandler.bind(this)}
            onKeyPress={this.onKeyPressEntrepriseHandler.bind(this)}
            fullWidth
          />
        </Grid>
        <Grid item xs={4}>
          <TextInput
            id="siret"
            label="SIRET"
            className={classes.labelInput}
            value={entreprise.siret}
            disabled={!hasEditRight}
            margin="normal"
            collectionStore={entreprisesStore}
            name="siret"
            onChangeHandler={this.onChangeEntrepriseHandler.bind(this)}
            onKeyPress={this.onKeyPressEntrepriseHandler.bind(this)}
            fullWidth
          />
        </Grid>
        <Grid item xs={4}>
          <TextInput
            id="tva"
            label="TVA"
            className={classes.labelInput}
            value={entreprise.tva}
            disabled={!hasEditRight}
            margin="normal"
            collectionStore={entreprisesStore}
            name="tva"
            onChangeHandler={this.onChangeEntrepriseHandler.bind(this)}
            onKeyPress={this.onKeyPressEntrepriseHandler.bind(this)}
            fullWidth
          />
        </Grid>

        {etablissement_fields}

        <Grid item xs={4}>
          <TextInput
            className={classes.labelInput}
            id="groupe"
            label="Groupe"
            value={entreprise.groupe}
            margin="normal"
            collectionStore={entreprisesStore}
            onChangeHandler={this.onChangeEntrepriseHandler.bind(this)}
            onKeyPress={this.onKeyPressEntrepriseHandler.bind(this)}
            name="groupe"
            fullWidth
          />
        </Grid>
        <Grid item xs={4}>
        <TextInput
            id="traite_by_entreprise_uuid"
            label="Traité via"
            className={classes.selectContainer}
            value={entreprise.traite_by_entreprise_uuid}
            disabled={!hasEditRight}
            margin="normal"
            collectionStore={entreprisesStore}
            name="traite_by_entreprise_uuid"
            type="autocomplete"
            onChangeHandler={this.onChangeEntrepriseHandler.bind(this)}
            autocompleteProps={{
              collectionName: "entreprises",
            }}
            fullWidth
          />
        </Grid>
        <Grid item xs={4}></Grid>

        <Grid item xs={4}>
          <TextInput
            id="langue_uuid"
            label="Langue parlée"
            className={classes.selectContainer}
            value={entreprise.langue_uuid}
            margin="normal"
            disabled={!hasEditRight}
            collectionStore={entreprisesStore}
            name="langue_uuid"
            onChangeHandler={this.onChangeEntrepriseHandler.bind(this)}
            onKeyPress={this.onKeyPressEntrepriseHandler.bind(this)}
            fullWidth
            select
          >
            {this.getLangues()}
          </TextInput>
        </Grid>
        <Grid item xs={4}>
          <TextInput
            id="activite_uuid"
            label="Activité"
            className={classes.selectContainer}
            value={entreprise.activite_uuid}
            disabled={!hasEditRight}
            margin="normal"
            collectionStore={entreprisesStore}
            name="activite_uuid"
            onChangeHandler={this.onChangeEntrepriseHandler.bind(this)}
            onKeyPress={this.onKeyPressEntrepriseHandler.bind(this)}
            fullWidth
            select
          >
            {this.getActivites()}
          </TextInput>
        </Grid>
        <Grid item xs={2}>
          <TextInput
            id="ape"
            label="APE"
            className={classes.labelInput}
            value={entreprise.ape}
            disabled={!hasEditRight}
            margin="normal"
            collectionStore={entreprisesStore}
            name="ape"
            onChangeHandler={this.onChangeEntrepriseHandler.bind(this)}
            onKeyPress={this.onKeyPressEntrepriseHandler.bind(this)}
            fullWidth
          />
        </Grid>
        <Grid item xs={2}>
          <TextInput
            id="code"
            label="Code interne"
            className={classes.labelInput}
            value={entreprise.code}
            disabled={true}
            margin="normal"
            collectionStore={entreprisesStore}
            name="code"
            fullWidth
          />
        </Grid>
        <Grid item xs={4}>
          <TextInput
            id="paiement_uuid"
            label="Conditions de paiement"
            value={entreprise.paiement_uuid}
            disabled={!hasEditRight}
            margin="normal"
            collectionStore={entreprisesStore}
            name="paiement_uuid"
            onChangeHandler={this.onChangeEntrepriseHandler.bind(this)}
            onKeyPress={this.onKeyPressEntrepriseHandler.bind(this)}
            fullWidth
            select
          >
            {this.getPaiements()}
          </TextInput>
        </Grid>
        <Grid item xs={4}>
          <TextInput
            id="num_recepice_transport"
            label="Num. récépicé transport"
            className={classes.labelInput}
            value={entreprise.num_recepice_transport}
            disabled={!hasEditRight}
            margin="normal"
            collectionStore={entreprisesStore}
            name="num_recepice_transport"
            onChangeHandler={this.onChangeEntrepriseHandler.bind(this)}
            onKeyPress={this.onKeyPressEntrepriseHandler.bind(this)}
            fullWidth
          />
        </Grid>
        <Grid item xs={4}></Grid>
        <Grid item xs={4}>
          <CheckboxInput
            className={classes.checkbox_align}
            id="reference_client_obligatoire"
            label="Référence Client/Fournisseur obligatoire"
            value={entreprise.reference_client_obligatoire}
            disabled={!hasEditRight}
            collectionStore={entreprisesStore}
            name="reference_client_obligatoire"
            onChangeHandler={this.onChangeEntrepriseHandler.bind(this)}
          />
        </Grid>
        <Grid item xs={12}>
          <TextInput
            id="commentaires"
            label="Commentaires"
            className={classes.labelInput}
            value={entreprise.commentaires}
            disabled={!hasEditRight}
            margin="normal"
            collectionStore={entreprisesStore}
            name="commentaires"
            onChangeHandler={this.onChangeEntrepriseHandler.bind(this)}
            onKeyPress={this.onKeyPressEntrepriseHandler.bind(this)}
            fullWidth
          />
        </Grid>
        <Grid item xs={8}>
          <TextInput
            id="commentaire_facturation"
            label="Commentaire facturation"
            className={classes.labelInput}
            value={entreprise.commentaire_facturation}
            disabled={!hasEditRight}
            margin="normal"
            collectionStore={entreprisesStore}
            name="commentaire_facturation"
            onChangeHandler={this.onChangeEntrepriseHandler.bind(this)}
            onKeyPress={this.onKeyPressEntrepriseHandler.bind(this)}
            fullWidth
          />
        </Grid>
        <Grid item xs={4} className={classes.checkbox_container}>
          <CheckboxInput
            className={classes.checkbox_align}
            id="display_commentaire_facturation"
            label="Afficher le commentaire dans l'affaire"
            value={entreprise.display_commentaire_facturation}
            disabled={!hasEditRight}
            collectionStore={entreprisesStore}
            name="display_commentaire_facturation"
            onChangeHandler={this.onChangeEntrepriseHandler.bind(this)}
          />
        </Grid>
        <Grid item xs={4} className={classes.commercialSection}>
          {this.getCommercial(entreprise)}
        </Grid>
        <Grid item xs={4} className={classes.commercialSection}>
          <TextInput
            id="source_prospect_uuid"
            label="Source prospect"
            className={classes.labelInput}
            value={entreprise.source_prospect_uuid}
            disabled={!hasEditRight}
            margin="normal"
            collectionStore={entreprisesStore}
            name="source_prospect_uuid"
            onChangeHandler={this.onChangeEntrepriseHandler.bind(this)}
            onKeyPress={this.onKeyPressEntrepriseHandler.bind(this)}
            fullWidth
            select
          >
            {this.getSourceProspect()}
          </TextInput>
        </Grid>
        <Grid item xs={4}></Grid>
        <Grid item xs={4}>
          <TextInput
            id="meg_code_client"
            label="MEG Code client"
            className={classes.labelInput}
            value={entreprise.meg_code_client}
            disabled={!hasRights("affaire-edit-compta", user)}
            margin="normal"
            collectionStore={entreprisesStore}
            name="meg_code_client"
            onChangeHandler={this.onChangeEntrepriseHandler.bind(this)}
            onKeyPress={this.onKeyPressEntrepriseHandler.bind(this)}
            fullWidth
          />
        </Grid>
        <Grid item xs={4} className={classes.checkbox_container}>
          <CheckboxInput
            className={classes.checkbox_align}
            id="meg_facturation_auto"
            label="MEG Facturation auto"
            value={entreprise.meg_facturation_auto}
            disabled={!hasRights("affaire-edit-compta", user)}
            collectionStore={entreprisesStore}
            name="meg_facturation_auto"
            onChangeHandler={this.onChangeEntrepriseHandler.bind(this)}
          />
        </Grid>
        <Grid item xs={12} className={classes.section}>
          {submitBtn}
        </Grid>
        <hr className={classes.hr} />
        <Grid item xs={12} className={classes.section}>
          <Contact
            datas={entreprise.contacts}
            entrepriseUuid={entreprise.uuid}
            actionsCallback={this.refreshEntreprise.bind(this)}
            withCheckboxes={true}
          />
        </Grid>
        <hr className={classes.hr} />
        <Grid item xs={12} className={classes.section}>
          <Affaire
            datas={entreprise.affaires}
            disabledTypeAffaireFilter={true}
            disabledEtatFilter={true}
            disabledCommercialFilter={true}
            entreprise_uuid={entreprise.uuid}
            actionsCallback={this.refreshEntreprise.bind(this)}
            collectionCrud_options={{
              persistDatatableOptions: {
                id: "affaire_entreprise_tab_v2",
                exipiration_minutes: 60 * 12, // 12 Heures
              },
              rowsPerPageOptions: [5, 15, 25],
            }}
            local_storage_key="affaire_entreprise_tab_filter"
            defaut_orderBy="date_enlevement"
            defaut_orderDirection="desc"
            disabledTabs={true}
          />
        </Grid>
        <hr className={classes.hr} />
        <Grid item xs={12} className={classes.section}>
          <Typography
            variant="button"
            gutterBottom
            className={classes.sectionTitle}
          >
            Non Conformités
          </Typography>
          <NonConformite
            entreprise_uuid={entreprise.uuid}
            defaultClotureActive={true}
            withoutEntrepriseFilter={true}
          />
        </Grid>
        <hr className={classes.hr} />
        <Grid item xs={12} className={classes.section}>
          <Potentiel entreprise={entreprise} mode="entreprise" />
        </Grid>
        <hr className={classes.hr} />
        <Grid item xs={12} className={classes.section}>
          <Etablissement
            entrepriseUuid={entreprise.uuid}
            actionsCallback={this.handleChangeEtablissements.bind(this)}
            datas={entreprise.etablissements}
            isCapeco={entreprise.is_capeco || entreprise.is_sep}
          />
        </Grid>
        <hr className={classes.hr} />
        <Grid item xs={12} className={classes.section}>
          <Note
            entreprise_uuid={entreprise.uuid}
            from_store
          />
        </Grid>
        <hr className={classes.hr} />
        <Grid item xs={12} className={classes.section}>
          { this.getPartialDocumentFiles() }
        </Grid>
        <Modal
          openModal={this.state.openCommercialModal}
          onCloseHandler={this.onCloseCommercialModal.bind(this)}
          onSubmitHandler={this.onSubmitHandlerCommercialModal.bind(this)}
          fullWidth={true}
          maxWidth={"sm"}
          children={this.getContentCommercialModal(entreprise)}
          disabledEnter={true}
        />
      </Grid>
    );
  }
}

Detail = withStyles(EntrepriseCss)(Detail);

Detail = connect((store) => {
  return {
    activitesStore: store.collections.activites,
    languesStore: store.collections.langues,
    paiementsStore: store.collections.paiements,
    entreprisesStore: store.collections.entreprises,
    user: store.auth.user,
    usersStore: store.collections.users,
    sourceProspects: store.collections.source_prospects,
  };
})(Detail);

export default Detail;
