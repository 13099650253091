import {themeComplement} from '../../AppAdmin/css/theme';

export default theme => ({
  crudTitle: {
    color: theme.palette.primary.main,
    marginBottom: '1em',
    paddingLeft: '0.5em',
  },
  packinglist: {
    backgroundColor: '#18b0ff29',
  },
  packinglist_disponible:{
    backgroundColor: '#cec2f3ad',
  },
  packinglist_stockage_ext:{
    backgroundColor: '#f7a040ad',
  },
  silolist: {
    backgroundColor: '#09a34529',
  },
  divider: {
    marginTop: '1em',
    marginBottom: '1em',
  },
  autoPrint:{
    "margin-left": '0.5em',
    bottom: "0.4em",
    right: "17.5em",
    position: "absolute",
  },
  formControl: {
    width: 'calc(100% - 1em)!important',
  },
  avatar: {
    backgroundColor: 'rgba(0, 0, 0, 0.26)',
    color: 'white',
  },
  matieresPremieresContainer: {
    padding: '.5em 0 0 .5em',
    border: '1px solid rgba(224, 224, 224, 1)',
    borderRadius: '5px',
    position: 'absolute',
    left: 0,
    right: 0,
    bottom: '.5em',
    minHeight: '38.5px',
  },
  chips: {
    marginRight: '.5em',
    marginBottom: '.5em',
  },
  autocomplete: {
    textAlign: 'center',
  },
  statsButton: {
    color: 'white',
  },
  container: {
    '& th:nth-child(1)': {
      textAlign: 'center',
    },
    '& td:nth-child(1)': {
      position: 'relative',
      textAlign: 'center',
    },
    '& th:nth-child(2)': {
      textAlign: 'center',
    },
    '& td:nth-child(2)': {
      textAlign: 'center',
    },
    '& th:nth-child(3)': {
      textAlign: 'center',
    },
    '& td:nth-child(3)': {
      textAlign: 'center',
    },
    '& th:nth-child(5)': {
      textAlign: 'left',
    },
    '& td:nth-child(5)': {
      textAlign: 'left',
    },
    '& th:nth-child(6)': {
      textAlign: 'center',
    },
    '& td:nth-child(6)': {
      textAlign: 'center',
    },
    '& th:nth-child(7)': {
      textAlign: 'center',
    },
    '& td:nth-child(7)': {
      textAlign: 'center',
    },
    '& th:nth-child(8)': {
      textAlign: 'center',
    },
    '& td:nth-child(8)': {
      textAlign: 'center',
    },
  },
  warning: {
    top: '3px',
    color: '#ff00001c',
    width: '1.8em',
    height: '1.8em',
    position: 'absolute',
    right: '10px',
  },
  tabs: {
    marginBottom: '1em',
  },
  selectContainer: {
    width: 'calc(100% - 1em)',
  },
  ico: {
    verticalAlign: 'middle',
    marginRight: '0.4em',
  },
  statutLabel: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    fontWeight: 'bold',
    cursor: 'pointer',
  },
  in: {
    color: themeComplement.palette.notifications.success.color
  },
  out: {
    color: themeComplement.palette.notifications.error.color
  },
  incomplet: {
    alignSelf: 'flex-end',
    marginBottom: '0.15em',
  },
  loader: {
    position: 'absolute',
    top: '8em',
    left: '50%',
  },
  buttonExport: {
    marginLeft: '.5em',
    marginBottom: '.5em',
    textAlign: 'center',
    float:'right',
  },
  file_ico: {
    margin: 'auto',
    padding: '0.3em'
  },
  rowBB: {
    "&:last-child": {
      minWidth: '200px',
    },
  },
  withDocument:{
    color:'green',
  }

});
