import React, { Component } from "react";
import { withStyles } from "@material-ui/core/styles";
import HomeCss from "./css/HomeCss";
import { connect } from "react-redux";
import Supervision from "../Supervision";

class MesPerformances extends Component {

  render() {
    return ( <Supervision commercial_uuid={this.props.user ? this.props.user.uuid : null}/>)
  }
}

MesPerformances = withStyles(HomeCss)(MesPerformances);

MesPerformances = connect((store) => ({
  user: store.auth.user,
}))(MesPerformances);

export default MesPerformances;
