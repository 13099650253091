import React, { Component } from "react";

import { connect } from "react-redux";
import { Grid, Typography } from "@material-ui/core";
import { withStyles } from "@material-ui/core/styles";
import { GroupWork } from "@material-ui/icons";

import CollectionCrud from "../../common/Components/CollectionCrud";
import TextInput from "../../common/Components/TextInput";

import ArticleRegroupementCss from "./css/ArticleRegroupementCss";
import DateInput from "../../common/Components/DateInput";
import { collectionActions } from "../../../reducers/collectionsReducer";

class ArticleRegroupement extends Component {
  state = {
    date_debut: new Date(),
    prix: null,
    local_price: null,
    focus_input: null,
  };

  componentDidMount() {
    const { dispatch } = this.props;
    collectionActions(dispatch, "article-regroupements", "INDEX", {
      params: {
        date_debut: this.state.date_debut,
      },
    });
  }

  getDeleteModalTitle() {
    return (
      <div>
        <GroupWork />
        <span>{"Suppression de l'article de regroupement"}</span>
      </div>
    );
  }

  getDeleteModalContent() {
    return (
      <Typography>
        {"Êtes-vous sûr de vouloir supprimer cet article de regroupement ?"}
      </Typography>
    );
  }

  getCreateUpdateModalTitle(article) {
    let title = !!article
      ? "Modification de l'article de regroupement : " + article.libelle
      : "Ajout d'un nouvel article de regroupement";
    return (
      <div>
        <GroupWork />
        <span>{title}</span>
      </div>
    );
  }

  getCreateUpdateModalContent(article, articlesStore, onChangeHandler) {
    let { libelle } = article;

    return (
      <Grid container>
        <Grid item xs={12}>
          <TextInput
            id="libelle"
            label="Libellé"
            value={libelle}
            required={true}
            margin="normal"
            collectionStore={articlesStore}
            name="libelle"
            onChangeHandler={onChangeHandler}
            fullWidth
          />
        </Grid>
      </Grid>
    );
  }

  getInputPeriode(article_regroupement, name, sub_month) {
    const { articlesStore, dispatch } = this.props;
    const { focus_input, local_price } = this.state;
    const input_key = name + article_regroupement.uuid;
    const value = article_regroupement[name];

    return (
      <TextInput
        id={input_key}
        key={input_key}
        value={input_key === focus_input ? local_price : value}
        name="prix_m"
        onFocus={() => {
          this.setState({ focus_input: input_key, local_price: value });
        }}
        type="number"
        onBlur={() => {
          this.setState({ focus_input: null, local_price: null });
          if (local_price !== value) {
            collectionActions(
              dispatch,
              "article-regroupement-prixs",
              "CREATE",
              {
                article_regroupement_uuid: article_regroupement.uuid,
                date_debut: this.state.date_debut,
                prix: local_price,
                sub_month: sub_month,
              },
              (article_regroupement) => {
                if (article_regroupement) {
                  dispatch({
                    type: "UPDATED",
                    collection: "article-regroupements",
                    payload: article_regroupement,
                  });
                }
              }
            );
          }
        }}
        onChangeHandler={(name, e) => {
          this.setState({ local_price: e.target.value });
        }}
        collectionStore={articlesStore}
      />
    );
  }

  getCellsConfig() {
    const { date_debut } = this.state;
    const dateDeRef = new Date(date_debut);
    const month_names_short = [
      "Jan",
      "Fev",
      "Mars",
      "Avr",
      "Mai",
      "Juin",
      "Juil",
      "Aout",
      "Sept",
      "Oct",
      "Nov",
      "Dec",
    ];
    let M = month_names_short[dateDeRef.getMonth()];
    let M1 = month_names_short[dateDeRef.getMonth() - 1];
    let M2 = month_names_short[dateDeRef.getMonth() - 2];

    return [
      {
        datakey: "libelle",
        title: "Libellé",
        sortable: true,
        searchable: true,
      },
      {
        datakey: "periode_m",
        title: M,
        sortable: false,
        searchable: false,
        format: (obj, key) => {
          return this.getInputPeriode(obj, key, 0);
        },
      },
      {
        datakey: "periode_m-1",
        title: M1,
        sortable: false,
        searchable: false,
        format: (obj, key) => {
          return this.getInputPeriode(obj, key, 1);
        },
      },
      {
        datakey: "periode_m-2",
        title: M2,
        sortable: false,
        searchable: false,
        format: (obj, key) => {
          return this.getInputPeriode(obj, key, 2);
        },
      },
    ];
  }

  getCrudTitle() {
    const { classes } = this.props;
    return [
      <Grid container className={classes.container}>
        <Grid item xs={3}>
          <Typography
            key="typo"
            variant="button"
            gutterBottom
            className={classes.crudTitle}
          >
            Articles de regroupement
          </Typography>
        </Grid>
        <Grid item xs={1}></Grid>
        <Grid item xs={6}>
          <DateInput
            views={["year", "month"]}
            id="date_debut"
            label="Période M"
            value={this.state.date_debut}
            autoOk={true}
            required={true}
            margin="normal"
            name="date_debut"
            onChangeHandler={(name, e) => {
              this.setState({ date_debut: e.target.value }, () => {
                const { dispatch } = this.props;
                collectionActions(dispatch, "article-regroupements", "INDEX", {
                  params: {
                    date_debut: this.state.date_debut,
                  },
                });
              });
            }}
            fullWidth
            collectionStore={this.props.articlesStore}
            format="MMMM yyyy"
          />
        </Grid>
      </Grid>,
    ];
  }

  render() {
    return (
      <>
        <CollectionCrud
          collectionName={"article-regroupements"}
          showBtnEdit={true}
          showBtnAdd={true}
          showBtnDelete={true}
          loadDatas={false}
          deleteModalTitle={this.getDeleteModalTitle}
          deleteModalContent={this.getDeleteModalContent.bind(this)}
          createUpdateModalTitle={this.getCreateUpdateModalTitle}
          createUpdateModalContent={this.getCreateUpdateModalContent.bind(this)}
          modalMaxWidth="md"
          cancelUpdateCheck={true}
          datatableConfig={{
            showPagination: true,
            showSearch: true,
            defaultSort: "nom",
            sortType: "desc",
            nodatalabel: "Aucun article de regroupement",
            rowsPerPageOptions: [50,100],
          }}
          dataTableExtraNodes={[
            {
              element: this.getCrudTitle(),
              position: "top-left",
            },
          ]}
          cellsConfig={this.getCellsConfig()}
        />
      </>
    );
  }
}

ArticleRegroupement = withStyles(ArticleRegroupementCss)(ArticleRegroupement);

ArticleRegroupement = connect((store) => {
  return {
    articlesStore: store.collections["article-regroupements"],
  };
})(ArticleRegroupement);

export default ArticleRegroupement;
