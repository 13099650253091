import React, { Component } from "react";

import { Grid, MenuItem, Typography } from "@material-ui/core";
import { withStyles } from "@material-ui/core/styles";
import { Settings } from "@material-ui/icons";

import CollectionCrud from "../../common/Components/CollectionCrud";
import TextInput from "../../common/Components/TextInput";
import FamilleCss from "./css/FamilleCss";
import { getFamilleOPtionValorisations } from "../../../reducers/collectionsReducer";
import Modal from "../../common/Components/Modal";
import { SketchPicker } from "react-color";

class Famille extends Component {

  state = {
    options_valorisations: [],
    openModalSketchPicker: false,
  };


  componentDidMount() {
    getFamilleOPtionValorisations().then((options_valorisations) => {
      this.setState({ options_valorisations: options_valorisations.data });
    });
  }

  getDeleteModalTitle() {
    return (
      <div>
        <Settings />
        <span>{"Suppression de la famille"}</span>
      </div>
    );
  }

  getDeleteModalContent() {
    return (
      <Typography>
        {"Êtes-vous sûr de vouloir supprimer cette famille ?"}
      </Typography>
    );
  }

  getCreateUpdateModalTitle(famille) {
    let title = !!famille
      ? "Modification de la famille : " + famille.nom
      : "Ajout d'une nouvelle famille";
    return (
      <div>
        <Settings />
        <span>{title}</span>
      </div>
    );
  }

  getOptionsValorisations() {
    const { options_valorisations } = this.state;
    const options = [];
    Object.keys(options_valorisations).forEach((key) => {
      let option = options_valorisations[key];
      options.push(
        <MenuItem key={key} value={key}>
          {option}
        </MenuItem>
      );
    });

    return options;
  }

  getCreateUpdateModalContent(famille, famillesStore, onChangeHandler) {
    let { libelle_fr, libelle_en, annexe_VII_libelle, code_dechet_intl, attestation_valorisation_type, hs_code, color } = famille;
    const { openModalSketchPicker } = this.state;
    return (
      <Grid container>
        <Grid item xs={12}>
          <TextInput
            id="libelle_fr"
            label="Libelle FR"
            value={libelle_fr}
            margin="normal"
            collectionStore={famillesStore}
            name="libelle_fr"
            onChangeHandler={onChangeHandler}
            fullWidth
          />
        </Grid>
        <Grid item xs={12}>
          <TextInput
            id="libelle_en"
            label="Libelle EN"
            value={libelle_en}
            margin="normal"
            collectionStore={famillesStore}
            name="libelle_en"
            onChangeHandler={onChangeHandler}
            fullWidth
          />
        </Grid>
        <Grid item xs={12}>
          <TextInput
            id="annexe_VII_libelle"
            label="Libelle Annexe VII"
            value={annexe_VII_libelle}
            margin="normal"
            collectionStore={famillesStore}
            name="annexe_VII_libelle"
            onChangeHandler={onChangeHandler}
            fullWidth
          />
        </Grid>
        <Grid item xs={12}>
          <TextInput
            id="code_dechet_intl"
            label="Code déchet international"
            value={code_dechet_intl}
            margin="normal"
            collectionStore={famillesStore}
            name="code_dechet_intl"
            onChangeHandler={onChangeHandler}
            fullWidth
          />
        </Grid>
        <Grid item xs={12}>
          <TextInput
            id="attestation_valorisation_type"
            label="Type attestation valorisation"
            value={attestation_valorisation_type}
            margin="normal"
            collectionStore={famillesStore}
            name="attestation_valorisation_type"
            onChangeHandler={onChangeHandler}
            fullWidth
            select
          >
            {this.getOptionsValorisations()}
          </TextInput>
        </Grid>
        <Grid item xs={12}>
          <TextInput
            id="hs_code"
            label="Code HS"
            value={hs_code}
            margin="normal"
            collectionStore={famillesStore}
            name="hs_code"
            onChangeHandler={onChangeHandler}
            fullWidth
          />
        </Grid>
        <Grid item xs={12}>
          <TextInput
            id="color"
            label="Couleur"
            value={color}
            margin="normal"
            collectionStore={famillesStore}
            name="color"
            fullWidth
            onClick={() => {
              this.setState({openModalSketchPicker: true})
            }}
            style={{backgroundColor:color}}
          />
          <Modal
            openModal={openModalSketchPicker}
            onCloseHandler={() => {
              this.setState({openModalSketchPicker: false})
            }}
            onSubmitHandler={() => {}}
            disableActionButtons={true}
            maxWidth={"sm"}
            children={this.getModalSketchPicker(color, onChangeHandler)}
          />
        </Grid>
      </Grid>
    );
  }

  getModalSketchPicker(color, onChangeHandler)
  {
    return (
      <div>
        <SketchPicker
          color={color}
          onChangeComplete={(e) => {
            onChangeHandler("color", {target: {value: e.hex}});
          }}
        />
      </div>
    );
  }

  render() {
    const { classes } = this.props;
    return (
      <Grid container className={classes.container}>
        <CollectionCrud
          collectionName={"familles"}
          showBtnEdit={true}
          showBtnAdd={true}
          showBtnDelete={true}
          rights={{
            create: "admin-cud-parametres",
            edit: "admin-cud-parametres",
            delete: "admin-cud-parametres",
          }}
          deleteModalTitle={this.getDeleteModalTitle}
          deleteModalContent={this.getDeleteModalContent}
          createUpdateModalTitle={this.getCreateUpdateModalTitle}
          createUpdateModalContent={this.getCreateUpdateModalContent.bind(this)}
          datatableConfig={{
            showPagination: true,
            showSearch: true,
            defaultSort: "libelle_fr",
            sortType: "desc",
            rowsPerPageOptions: [10, 25, 50],
          }}
          cellsConfig={[
            {
              datakey: "libelle_fr",
              title: "Libelle FR",
              sortable: true,
              searchable: true,
            },
            {
              datakey: "libelle_en",
              title: "Libelle EN",
              sortable: true,
              searchable: true,
            },
            {
              datakey: "annexe_VII_libelle",
              title: "Libelle Annexe VII",
              sortable: true,
              searchable: true,
            },
            {
              datakey: "code_dechet_intl",
              title: "Code déchet international",
              sortable: true,
              searchable: true,
            },
            {
              datakey: "attestation_valorisation_type",
              title: "Type attestation valorisation",
              sortable: true,
              searchable: true,
              format:(obj,key) => {
                const { options_valorisations } = this.state;
                return options_valorisations[obj[key]];
              }
            },
            {
              datakey: "hs_code",
              title: "code HS",
              sortable: true,
              searchable: true,
            },
            {
              dataKey:'color',
              title:'Couleur',
              sortable: true,
              searchable: true,
              format:(obj,key) => {
                return <div style={{width:'20px',height:'20px',backgroundColor:obj.color}}></div>
              }
            }
          ]}
        />
      </Grid>
    );
  }
}

Famille = withStyles(FamilleCss)(Famille);

export default Famille;
