import { withStyles } from "@material-ui/core/styles";
import React, { Component } from "react";
import { connect } from "react-redux";
import StockCss from "./css/StockCss";
import ProduitTransforme from "./ProduitTransforme";

class BigBagsSortieStock extends Component {
  render() {
    return (
      <>
        <ProduitTransforme
          out_of_stock={true}
          hideDeleteBtn={true}
          readOnly={true}
        />
      </>
    );
  }
}

BigBagsSortieStock = withStyles(StockCss)(BigBagsSortieStock);

BigBagsSortieStock = connect((store) => {
  return {
    produitsTransformesStore: store.collections["stock/produits_transformes"],
  };
})(BigBagsSortieStock);

export default BigBagsSortieStock;
