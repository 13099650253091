import React, { Component } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";

import {
  Button,
  Chip,
  CircularProgress,
  DialogContent,
  DialogTitle,
  Grid,
  MenuItem,
  TextField,
} from "@material-ui/core";
import { withStyles } from "@material-ui/core/styles/index";
import DoneIcon from "@material-ui/icons/Done";
import MailOutlineIcon from "@material-ui/icons/MailOutline";

import EmailButtonCss from "./css/EmailButtonCss";
import Modal from "../../common/Components/Modal";
import {
  collectionActions,
  getEmailByType,
  getEmailByTypeEmailable,
} from "../../../reducers/collectionsReducer";
import TextInput from "../../common/Components/TextInput";
import { Warning, Edit } from "@material-ui/icons";
import { FileUploader } from "react-drag-drop-files";
import ReactQuill from "react-quill";

class EmailButton extends Component {
  static propTypes = {
    className: PropTypes.string,
    email: PropTypes.object,
    label: PropTypes.string,
    onSent: PropTypes.func,
    required: PropTypes.string,
    asynchrone: PropTypes.bool,
    send: PropTypes.bool,
    showPopupInfo: PropTypes.bool,
    popupMessage: PropTypes.string,
    open: PropTypes.bool,
    showAdditionalParamsAsyncForm: PropTypes.bool,
    formAdditionAsyncParams: PropTypes.func,
    asyncParams: PropTypes.object,
    emailable_type: PropTypes.string,
    emailable_uuid: PropTypes.string,
  };

  state = {
    email: this.props.email,
    send: this.props.send,
    transporteurDestinataire: null,
    message:
      this.props.email && this.props.email.can_resend
        ? this.addResendHeader(this.props.email)
        : this.props.email
        ? this.props.email.message
        : "",
    objet:
      this.props.email && this.props.email.can_resend
        ? this.props.email.object_resend_prefix + this.props.email.objet
        : this.props.email
        ? this.props.email.objet
        : "",
    open: this.props.open,
    destinataires_uuid:
      this.props.email && this.props.email.destinataires_uuid
        ? this.props.email.destinataires_uuid.split(",")
        : [],
    entreprises_uuid: [],
    copies_uuid:
      this.props.email && this.props.email.copies_uuid
        ? this.props.email.copies_uuid.split(",")
        : [],

    email_sending: false,

    // Permet de démonter->remontér l'Input d'autocomplete du mail de cotation
    contact_cotation_monted: true,
    openPopupInfo: false,
    manual_pj: null,
    openFormModal: false,
    asyncParams: {},
  };

  addResendHeader(email) {
    const { forceDisabled } = this.props;
    if (forceDisabled) {
      return email.message;
    }
    return (email.resend_message ? email.resend_message : "") + email.message;
  }

  handleClose() {
    const { dispatch, onCloseHandler } = this.props;
    this.setState({ open: false, openFormModal:false, asyncParams:{} },() => {
      if (onCloseHandler) {
        onCloseHandler();
      }
    });
    collectionActions(dispatch, "emails", "RESET_ERRORS");
  }

  handleOpen(e, formSubmited = false) {
    const { asynchrone, showPopupInfo, email, showAdditionalParamsAsyncForm, asynchroByType, asynchroByTypeParams } = this.props;
    if(showAdditionalParamsAsyncForm && !formSubmited) {
      this.setState({ openFormModal: true });
    }
    else if (asynchrone && ((showAdditionalParamsAsyncForm && formSubmited) || !showAdditionalParamsAsyncForm)) {
      this.setState({ email: null });
      if(asynchroByType){
        getEmailByType(this.props.type, asynchroByTypeParams).then(this.onEmailAsyncResponse.bind(this));
      }else{
        getEmailByTypeEmailable(this.props.type, {
          ...this.state.asyncParams,
          emailable_uuid:email.emailable_uuid,
          emailable_type:email.emailable_type
        }).then(this.onEmailAsyncResponse.bind(this));
      }
    } else {
      if (showPopupInfo && (email["state"] === "TODO" || email.can_resend)) {
        this.setState({ openPopupInfo: true });
      } else {
        this.setState({ open: true });
      }
    }
  }

  onEmailAsyncResponse(response){
    const email = response.data;
    this.setState({
      email,
      message: email.can_resend
        ? this.addResendHeader(email)
        : email.message,
      objet:
        email && email.can_resend
          ? email.object_resend_prefix + email.objet
          : email.objet,
      open: true,
      destinataires_uuid: email.destinataires_uuid
        ? email.destinataires_uuid.split(",")
        : [],
      copies_uuid: email.copies_uuid ? email.copies_uuid.split(",") : [],
      asyncParams: {},
      openFormModal: false,
    });
  }

  handleSent(sent) {
    const { onSent } = this.props;

    this.setState({
      email_sending: false, manual_pj: null
    });

    if (onSent) {
      onSent(sent);
    }
    if (sent && sent.type !== "demande_cotation_transport") {
      this.setState({
        email: sent,
        message: sent.can_resend
        ? this.addResendHeader(sent)
        : sent.message,
        objet:
        sent && sent.can_resend
            ? sent.object_resend_prefix + sent.objet
            : sent.objet,
      });
    } else if (sent) {
      this.setState(
        {
          email: this.props.email,
          destinataires_uuid: [],
          contact_cotation_monted: false,
        },
        () => {
          this.setState({
            contact_cotation_monted: true,
            entreprises_uuid: [],
          });
        }
      );
    }
    this.handleClose();
  }

  handleSubmit() {
    const { dispatch } = this.props;
    const {
      message,
      objet,
      destinataires_uuid,
      copies_uuid,
      email,
      is_entreprise,
      entreprises_uuid,
      manual_pj,
    } = this.state;

    let modified = {
      ...email,
      objet,
      message,
      destinataires_uuid: destinataires_uuid.join(","),
      copies_uuid: copies_uuid.join(","),
      is_entreprise: is_entreprise,
      manual_pj,
    };
    if (entreprises_uuid) {
      modified.entreprises_uuid = entreprises_uuid.map((item) =>
        typeof item === "object" ? item.value : item
      );
    }

    const ALLOWED_EMAIL_FIELDS = [
      "type",
      "destinataires_uuid",
      "copies_uuid",
      "objet",
      "message",
      "envoi",
      "expediteur_uuid",
      "langue_uuid",
      "entreprise_uuid",
      "uuid",
      "emailable_type",
      "emailable_uuid",
    ];

    //parse to FormData
    let formData = new FormData();
    for (let key in modified) {
      if (ALLOWED_EMAIL_FIELDS.includes(key)) {
        if(modified[key] !== null && modified[key] !== undefined) {
          formData.append(key, modified[key]);
        }
      }
      if(["manual_pj",'pdfs'].includes(key) && modified[key]){
        modified[key].forEach(value => {
          formData.append(key+"[]", value);
        });
      }
    }
    this.setState({ email_sending: true });
    collectionActions(
      dispatch,
      "emails",
      "CREATE",
      formData,
      this.handleSent.bind(this)
    );
  }

  handleChange(event) {
    const { name, value } = event.target;
    this.setState({ [name]: value });
  }

  handleAutocompleteChange(field_name, event) {
    const { value } = event.target;
    this.setState(
      {
        is_entreprise: true,
        transporteurDestinataire: null,
        contact_cotation_monted: false,
        destinataires_uuid: [value.value],
      },
      () => {
        this.setState({
          contact_cotation_monted: true,
          entreprises_uuid: this.state.entreprises_uuid
            .concat([value])
            .filter((item, index, self) => self.indexOf(item) === index), // remove duplicates
        });
      }
    );
  }

  hasError(field) {
    const { emailsStore } = this.props;
    return !!emailsStore.errors && !!emailsStore.errors[field];
  }

  getErrorMessage(field) {
    const { emailsStore } = this.props;
    if (this.hasError(field)) {
      return emailsStore.errors[field].map((error, index) => (
        <span style={{ display: "block" }} key={index}>
          {error}
        </span>
      ));
    } else {
      return null;
    }
  }

  formatDate(string) {
    let date = new Date(string);
    let options = {
      weekday: "long",
      day: "numeric",
      month: "long",
      year: "numeric",
    };
    return (
      "Envoyé le " +
      date.toLocaleDateString("fr-FR", options) +
      " à " +
      date.getHours() +
      ":" +
      date.getMinutes().toString().padStart(2, "0")
    );
  }

  getEmailAddress(contact, type = null) {
      return contact ? contact.name + " <" + contact.email + ">" : "";
  }

  getEmailAddresses(contacts, type = null) {
    if (contacts) {
      const addresses = contacts.map((c) => this.getEmailAddress(c));
      return addresses.join(", ");
    } else {
      // Si mail demande cotation, pas de valeur pré-saisie
      return type !== "demande_cotation_transport" ? "(Aucun contact)" : null;
    }
  }

  getBasename(pathname) {
    const path = pathname.split("/");
    return path[path.length - 1];
  }

  getSelectedDestinataires() {
    return (
      <>
        À :
        {this.state.entreprises_uuid.map((option) => {
          return (
            <Chip
              key={option.value}
              label={option.label}
              onDelete={() => {
                const entreprises_uuid = this.state.entreprises_uuid.filter(
                  (item) => item.value !== option.value
                );
                this.setState({ entreprises_uuid });
              }}
            />
          );
        })}
      </>
    );
  }

  getFieldDestinataireCopies(
    email,
    disabled,
    values,
    contacts,
    field_name,
    label
  ) {
    const { classes, emailsStore } = this.props;

    if (
      this.isDemandeCotation(this.props.email) &&
      field_name !== "copies_uuid" &&
      this.state.contact_cotation_monted
    ) {
      return (
        <>
          <div className={classes.selected_destinataires}>
            {this.getSelectedDestinataires()}
          </div>
          <TextInput
            autocompleteFullValue={true}
            required
            id="contact_uuid"
            name={field_name}
            className={classes.selectContainer}
            collectionStore={emailsStore}
            value={this.state.transporteurDestinataire}
            margin="normal"
            onChangeHandler={this.handleAutocompleteChange.bind(this)}
            type="autocomplete"
            autocompleteProps={{
              collectionName: "transporteurs",
            }}
            fullWidth
          />
        </>
      );
    } else {
      if (disabled || !contacts || contacts.length === 0) {
        return (
          <TextField
            className={disabled ? classes.disabledInput : null}
            disabled={disabled}
            error={this.hasError(field_name)}
            fullWidth
            helperText={this.getErrorMessage(field_name)}
            name={field_name}
            label={label}
            margin="normal"
            onChange={this.handleChange.bind(this)}
            value={this.getEmailAddresses(values, email.type)}
          />
        );
      } else {
        const options = contacts.map((contact) => {
          return (
            <MenuItem
              value={contact.uuid}
              key={contact.uuid}
              className={
                this.state[field_name].includes(contact.uuid)
                  ? classes.optionSelected
                  : null
              }
            >
              {this.getEmailAddress(contact)}
            </MenuItem>
          );
        });
        return (
          <TextField
            className={disabled ? classes.disabledInput : null}
            error={this.hasError(field_name)}
            fullWidth
            helperText={this.getErrorMessage(field_name)}
            name={field_name}
            label={label}
            margin="normal"
            onChange={this.onChangeHandlerEmails.bind(this, field_name)}
            SelectProps={{
              multiple: true,
              value: this.state[field_name],
            }}
            select
          >
            {options}
          </TextField>
        );
      }
    }
  }

  onChangeHandlerEmails(name, e) {
    this.setState({
      [name]: e.target.value,
    });
  }

  renderButton() {
    const { classes, className, label, required, send, forceDisabled } = this.props;
    const { email } = this.state;
    let color = "default";
    let title = "Relire le mail envoyé";
    let icon = <DoneIcon className={classes.leadIcon} />;

    if (!email || !email.emailable_uuid) {
      if (!send) {
        icon = <MailOutlineIcon className={classes.leadIcon} />;
        color = "primary";
        title = "Préparer le mail à envoyer";
      }
    } else if (email["state"] === "TODO" || (email.can_resend && !forceDisabled)) {
      icon = <MailOutlineIcon className={classes.leadIcon} />;
      if (required !== "") {
        title = required;
      } else {
        color = "primary";
        title = "Préparer le mail à envoyer";
      }
    }

    return (
      <Button
        className={
          className +
          ((email && email.can_resend) && !forceDisabled ? " " + classes.emailresendable : "")
        }
        disabled={this.props.disabled}
        color={color}
        onClick={this.handleOpen.bind(this)}
        title={title}
        variant="outlined"
      >
        {icon} {label}
      </Button>
    );
  }

  isDemandeCotation(email) {
    return email && email.type === "demande_cotation_transport";
  }

  isLiasseDocumentaire(email) {
    return (email && email.type === "liasse_documentaire_reception") || (email && email.type === "liasse_documentaire_envoi");
  }

  onDropHandler(files) {
    let { manual_pj } = this.state;

    if (!manual_pj) {
      manual_pj = [];
    }
    Array.from(files).forEach((file) => {
      manual_pj.push(file);
    });

    this.setState({ manual_pj });
  }

  removeManualPj(pj) {
    let { manual_pj } = this.state;
    manual_pj = manual_pj.filter((p) => p !== pj);
    this.setState({ manual_pj });
  }

  onChangeReactQuillHandler(e) {
    this.setState({
      message: e,
    });
  }

  getModalAsyncParamsForm() {
    const { openFormModal, asyncParams } = this.state;
    const { classes, formAdditionAsyncParams } = this.props;
    if(!formAdditionAsyncParams || !openFormModal) return null;

    return (
      <Modal
          fullWidth={true}
          maxWidth="sm"
          onCloseHandler={() => this.setState({ openFormModal: false })}
          onSubmitHandler={() =>
            this.setState({ openFormModal: false }, () => {
              this.handleOpen(null,true);
            })
          }
          openModal={openFormModal}
          disabledEnter
        >
          <DialogTitle key="title">
            <Edit className={classes.titleIcon} />
            <span>Complément d'information</span>
          </DialogTitle>
          <DialogContent>
            { formAdditionAsyncParams(asyncParams, (name,e) => {
                this.setState({ asyncParams: { ...asyncParams, [name]: e.target.value } });
              })
            }
          </DialogContent>
        </Modal>
    )
  }

  renderWithEmail() {
    const { classes, label, required, popupMessage, forceDisabled } =
      this.props;
    const { open, objet, message, email, openPopupInfo } = this.state;

    // État par défaut === 'DONE' si le mail ne concerne pas une demande de cotation de transport
    let disabled = true;
    if (this.isDemandeCotation(this.props.email)) {
      disabled = false;
    }

    let created_at = email ? this.formatDate(email["created_at"]) : "";
    let cancel = "Fermer";
    let ok = "NONE";

    // État 'TODO' : à compléter ou prêt à l'envoi
    if (
      email &&
      (email["state"] === "TODO" ||
        email.can_resend ||
        this.isDemandeCotation(email) || this.isLiasseDocumentaire(email))
    ) {
      created_at = "";
      if (required !== "") {

      } else {
        disabled = false;
        cancel = "Annuler";
        ok = "Envoyer";
      }
    }


    if (forceDisabled) {
      disabled = true;
      cancel = "Fermer";
      ok = "NONE";
    }

    let attachment = null;
    if (email && email["pdfs"]) {
      attachment = email["pdfs"].map((pdf, i) => {
        return (
          <Grid container xs={12} key={pdf}>
            <Grid item xs={9}>
              <TextField
                className={disabled ? classes.disabledInput : null}
                disabled={true}
                error={this.hasError("pdf")}
                fullWidth
                helperText={this.getErrorMessage("pdf")}
                name="pdf"
                label="Pièce jointe"
                margin="normal"
                onChange={this.handleChange.bind(this)}
                required={true}
                value={this.getBasename(pdf)}
              />
            </Grid>
            <Grid item xs={3}>
              <Button
                className={classes.openButton}
                color="primary"
                href={email.download[i]}
                target="_blank"
              >
                Ouvrir
              </Button>
            </Grid>
          </Grid>
        );
      });
    }

    let inputPdf = null;
    if (email && (email["state"] === "TODO" || email.can_resend)) {
      let pjs = [];
      if (this.state.manual_pj) {
        this.state.manual_pj.forEach((pj) => {
          pjs.push(
            <Grid container xs={12} key={pj.name}>
              <Grid item xs={9}>
                <TextField
                  className={disabled ? classes.disabledInput : null}
                  disabled={true}
                  fullWidth
                  name="pdf"
                  label="Pièce jointe"
                  margin="normal"
                  required={true}
                  value={pj.name}
                />
              </Grid>
              <Grid item xs={3}>
                <Button
                  className={classes.openButton + " " + classes.danger}
                  href={pj.preview}
                  target="_blank"
                  onClick={this.removeManualPj.bind(this, pj)}
                >
                  Supprimer
                </Button>
              </Grid>
            </Grid>
          );
        });
      }
      inputPdf = (
        <Grid container xs={12}>
          <FileUploader
            multiple={true}
            name="file"
            label="Déposez vos pièces jointes ici"
            onDrop={this.onDropHandler.bind(this)}
            onSelect={this.onDropHandler.bind(this)}
            classes={classes.dragrable}
            type={["PDF"]}
          />
          {pjs}
        </Grid>
      );
    }
    let content = email ? (
      <Grid container>
        <Grid item xs={12}>
          <TextField
            className={disabled ? classes.disabledInput : null}
            disabled={true}
            error={this.hasError("expediteur_uuid")}
            fullWidth
            helperText={this.getErrorMessage("expediteur_uuid")}
            name="expediteur"
            label="De"
            margin="normal"
            onChange={this.handleChange.bind(this)}
            required={true}
            value={this.getEmailAddress(email.expediteur,email.type)}
          />
        </Grid>
        <Grid item xs={12}>
          {this.getFieldDestinataireCopies(
            email,
            email.type === "demande_cotation_transport" ? false : disabled,
            email.copies,
            email.enabled_copies,
            "copies_uuid",
            "Copie"
          )}
        </Grid>
        <Grid item xs={12}>
          {this.getFieldDestinataireCopies(
            email,
            email.type === "demande_cotation_transport" ? false : disabled,
            email.destinataires,
            email.enabled_detinataires,
            "destinataires_uuid",
            "À"
          )}
        </Grid>
        <Grid item xs={12}>
          <TextField
            className={disabled ? classes.disabledInput : null}
            disabled={disabled}
            error={this.hasError("objet")}
            fullWidth
            helperText={this.getErrorMessage("objet")}
            name="objet"
            label="Objet"
            margin="normal"
            onChange={this.handleChange.bind(this)}
            required={true}
            value={objet}
          />
        </Grid>
        {inputPdf}

        {attachment}

        <Grid item xs={12}>
          <ReactQuill
              className={disabled ? classes.disabledInput : null}
              theme="snow"
              margin="normal"
              error={this.hasError("message")}
              value={message}
              onChange={this.onChangeReactQuillHandler.bind(this)}
              required={true}
              readOnly={disabled}
          />
        </Grid>
      </Grid>
    ) : (
      <CircularProgress />
    );

    if (this.state.email_sending) {
      content = (
        <div className={classes.center}>
          <h4>Envoie des emails en cours.</h4>
          <CircularProgress />
        </div>
      );
    }
    return (
      <>
        {this.renderButton()}
        <Modal
          actionCancel={cancel}
          actionMessage={
            !!this.props.emailsStore.errors
              ? "L'email n'a pas pu être envoyé"
              : created_at
          }
          actionSubmit={ok}
          error={!!this.props.emailsStore.errors}
          fullWidth={true}
          maxWidth="sm"
          onCloseHandler={this.handleClose.bind(this)}
          onSubmitHandler={this.handleSubmit.bind(this)}
          openModal={open}
          disabledEnter
        >
          <DialogTitle key="title">
            <MailOutlineIcon className={classes.titleIcon} />
            <span>{label}</span>
          </DialogTitle>
          <DialogContent>{content}</DialogContent>
        </Modal>

        <Modal
          fullWidth={true}
          maxWidth="sm"
          onCloseHandler={() => this.setState({ openPopupInfo: false })}
          onSubmitHandler={() =>
            this.setState({ openPopupInfo: false, open: true })
          }
          openModal={openPopupInfo}
          disabledEnter
        >
          <DialogTitle key="title">
            <Warning className={classes.titleIcon} />
            <span>Message d'information</span>
          </DialogTitle>
          <DialogContent>
            <p>{popupMessage}</p>
          </DialogContent>
        </Modal>
        { this.getModalAsyncParamsForm()}
      </>
    );
  }

  render() {
    return this.renderWithEmail();
  }
}

EmailButton = connect((store) => {
  return {
    emailsStore: store.collections.emails,
    user: store.auth.user,
  };
})(EmailButton);
export default withStyles(EmailButtonCss)(EmailButton);
