import {Tab, Tabs, Typography} from '@material-ui/core'
import {withStyles} from '@material-ui/core/styles'
import React, {Component} from 'react'
import {connect} from 'react-redux'
import {collectionActions} from '../../../reducers/collectionsReducer'
import PSListsCss from './css/PSListsCss'
import Packinglist from '../Packinglist'
import Silolist from '../Silolist'

const TAB_PACKING_LISTS = 0
const TAB_SILO_LISTS = 1
const URL_PACKING_LISTS = '/pslists/packinglists'
const URL_SILO_LISTS = '/pslists/silolists'
// URL to TAB map
const TABS = {
  [URL_PACKING_LISTS]: TAB_PACKING_LISTS,
  [URL_SILO_LISTS]: TAB_SILO_LISTS,
}
// TAB to URL reverse map
const URLS = Object.entries(TABS)
      .reduce((acc, cur) => ({...acc, [cur[1]]: cur[0]}), {})

function TabContainer(props) {
  return (
    <Typography component="div" style={{width: '100%'}}>
      {props.children}
    </Typography>
  )
}

class PSLists extends Component {
  state = {
    selectedTab: TABS[this.props.location.pathname]
  }

  handleChange = (event, selectedTab) => {
    this.setState({selectedTab})
    switch (selectedTab) {
    case TAB_PACKING_LISTS:
      this.props.history.push(URLS[selectedTab])
      break
    case TAB_SILO_LISTS:
      this.props.history.push(URLS[selectedTab])
      collectionActions(
        this.props.dispatch,
        URLS[selectedTab].replace(/\/[^/]+\//, ''),  // remove leading path
        'RESET_LIST',
      )
      break
    default:
      // Do nothing
    }
  }

  getPackingLists() {
    return (
      <TabContainer>
        <Packinglist history={this.props.history}/>
      </TabContainer>
    )
  }

  getSiloLists() {
    return (
      <TabContainer>
        <Silolist/>
      </TabContainer>
    )
  }

  render() {
    const {selectedTab} = this.state
    const { currentUser } = this.props
    if (selectedTab === undefined) {
      return null
    }

    return (
      <>
        <Tabs value={selectedTab} onChange={this.handleChange} className={this.props.classes.tabs}>
          <Tab label="Packing lists" disabled={!currentUser.rights.includes('admin-cud-packinglists')}/>
          <Tab label="Silo lists" disabled={!currentUser.rights.includes('admin-cud-silolists')}/>
        </Tabs>
        {selectedTab === TAB_PACKING_LISTS && this.getPackingLists()}
        {selectedTab === TAB_SILO_LISTS &&  this.getSiloLists()}
      </>
    )
  }
}

PSLists = withStyles(PSListsCss)(PSLists)

PSLists = connect(store => ({
  location: store.router.location,
  currentUser: store.auth.user,
}))(PSLists)

export default PSLists
