import {axiosClient as axios} from '../../../../axios'

export function getInfosByMois(date, category, commercial_uuid) {
  if(commercial_uuid){
    return axios.get(`/negoce_stats_month/${date}/${category}/${commercial_uuid}`)
    .then((response) => {
      return response.data
    })
  }else{
    return axios.get(`/negoce_stats_month/${date}/${category}`)
    .then((response) => {
      return response.data
    })
  }
}

export function getInfosByPeriod(date_debut, date_fin, category, commercial_uuid) {
  if(commercial_uuid){
    return axios.get(`/negoce_stats_period/${date_debut}/${date_fin}/${category}/${commercial_uuid}`)
    .then((response) => {
      return response.data
    })
  }else{
    return axios.get(`/negoce_stats_period/${date_debut}/${date_fin}/${category}`)
    .then((response) => {
      return response.data
    })
  }
}

export function getStockInfosByMois(date, commercial_uuid,category) {
  return axios.get(`/stock_stats/${date}`,{params: {commercial_uuid, category}})
    .then((response) => {
      return response.data
    })
}

export function getStockInfosByPeriod(date_debut, date_fin, commercial_uuid, category) {
  return axios.get(`/stock_stats/${date_debut}/${date_fin}`,{params: {commercial_uuid, category}})
    .then((response) => {
      return response.data
    })
}
