import { themeComplement } from "../../AppAdmin/css/theme";

export default theme => ({
    button:{
        marginLeft: theme.spacing.unit,
    },
    everSend:{
        color: themeComplement.palette.notifications.warning.color,
        borderColor: themeComplement.palette.notifications.warning.color,
        "&:hover": {
        borderColor: themeComplement.palette.notifications.warning.color,
        },
    },
    hr: {
        border: '0.5px solid #18b0ff'
    },
});
