import React, {Component} from 'react';
import {withStyles} from '@material-ui/core/styles';
import {Grid, Typography} from '@material-ui/core';
import {Settings} from '@material-ui/icons';
import TextInput from '../../common/Components/TextInput';
import LitigeCss from './css/LitigeCss';
import CollectionCrud from '../../common/Components/CollectionCrud';
import CheckboxInput from "../../common/Components/CheckboxInput";
class Litige extends Component {

  getDeleteModalTitle() {
    return (
      <div>
        <Settings/>
        <span>{"Suppression du type de litige"}</span>
      </div>
    );
  }

  getDeleteModalContent() {
    return (
      <Typography>
        {'Êtes-vous sûr de vouloir supprimer ce type de litige ?'}
      </Typography>
    );
  }

  getCreateUpdateModalTitle(litige) {
    let title = !!litige
      ? "Modification du type de litige : " + litige.nom
      : "Ajout d'un nouveau type de litige"
    return (
      <div>
        <Settings/>
        <span>{title}</span>
      </div>
    );
  }

  getCreateUpdateModalContent(litige, litigesStore, onChangeHandler) {
    let {
      nom,
      description,
      verbatim,
      verification_qualite,
    } = litige;
    return (
      <Grid container>
        <Grid item xs={12}>
          <TextInput
            id="nom"
            label="Nom"
            value={nom}
            margin="normal"
            collectionStore={litigesStore}
            name='nom'
            onChangeHandler={onChangeHandler}
            fullWidth
          />
        </Grid>
        <Grid item xs={12}>
          <label htmlFor="description" style={{ marginLeft:'5px' }}>Description</label>
          <textarea
              id="description"
              onChange={(e) => onChangeHandler("description", e)}
              rows="6"
              style={{ marginLeft:'5px', width: "99%" }}
              value={description}
          >
          </textarea>
        </Grid>
        <Grid item xs={12}>
        <label htmlFor="verbatim" style={{ marginLeft:'5px' }}>Verbatim</label>
          <textarea
              id="verbatim"
              onChange={(e) => onChangeHandler("verbatim", e)}
              rows="6"
              style={{ marginLeft:'5px', width: "99%" }}
              value={verbatim}
          >
          </textarea>
        </Grid>
        <Grid item xs={12}>
          <CheckboxInput
            id="verification_qualite"
            label="Vérification qualité"
            checked={verification_qualite}
            margin="normal"
            name='verification_qualite'
            onChangeHandler={onChangeHandler}
          />
        </Grid>
      </Grid>
    );
  }

  render() {
    const {classes} = this.props;
    return (
      <Grid container className={classes.container}>
        <CollectionCrud
          collectionName={'litiges'}
          showBtnEdit={true}
          showBtnAdd={true}
          showBtnDelete={true}
          disabledEnterModal={true}
          rights={{
            create: 'admin-cud-parametres',
            edit: 'admin-cud-parametres',
            delete: 'admin-cud-parametres'
          }}
          deleteModalTitle={this.getDeleteModalTitle}
          deleteModalContent={this.getDeleteModalContent}
          createUpdateModalTitle={this.getCreateUpdateModalTitle}
          createUpdateModalContent={this.getCreateUpdateModalContent.bind(this)}
          datatableConfig={{
            showPagination: true,
            showSearch: true,
            defaultSort: "nom",
            sortType: 'desc',
            rowsPerPageOptions: [10, 25, 50],
          }}
          cellsConfig={[
            {
              datakey: "nom",
              title: "Nom",
              sortable: true,
              searchable: true,
            },
            {
              datakey: "description",
              title: "Description",
              sortable: true,
              searchable: true,
              format: (obj, key) => obj[key] && obj[key].length > 50 ? obj[key].substring(0, 50) + "..." : obj[key]
            },
            {
              datakey: "verbatim",
              title: "Verbatim",
              sortable: true,
              searchable: true,
              format: (obj, key) => obj[key] && obj[key].length > 50 ? obj[key].substring(0, 50) + "..." : obj[key]
            },
            {
              datakey: "verification_qualite",
              title: "Vérification qualité",
              sortable: true,
              searchable: true,
              format: (obj, key) => obj[key] ? "Oui" : "Non"
            },

        ]}
        />
      </Grid>
    );
  }
}

Litige = withStyles(LitigeCss)(Litige);

export default Litige
