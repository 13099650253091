import { axiosClient as axios } from "../../../../axios";

export function getStatuts(callback) {
  return axios.get("/silolists/statuts").then((response) => {
    callback(response.data);
  });
}

export function getUsers() {
  return axios.get("/silolists/users").then((response) => {
    return response.data;
  });
}
