
export default theme => ({
  infosContainer:{
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-evenly',
    alignItems: 'stretch',
    marginTop: '3em',
  },
  infosItem:{
    flex: 1,
  },
  middle: {
    margin: '0 3em',
    maxHeight: '-webkit-fill-available',
  },
  categoriesTabContainer: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-evenly',
    marginBottom: '1.5em',
    marginTop: '1.5em'
  },
  filtersContainer: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
  },
  periodInputs: {
    display: 'flex',
    flexDirection: 'row',
  },
  radioGroup: {
    display: 'flex',
    flexDirection: 'row',
    marginLeft: '1em'
  },
  // tableCell: {
  //   padding: '.3em'
  // },
  rowB:{
    backgroundColor: '#EDEDED'
  },
  categories: {
    marginLeft: '5em',
    marginTop: '2em'
  },
  attenteTab: {
    display: 'flex',
    flexDirection: 'row',
    marginTop: '1em',
    maxHeight: '354px',
    overflowY: 'scroll',

  },
  inPaperTab: {
    marginTop: '0em',
    overflow:'scroll'
  },
  exportButton: {
    color: '#216f42',
    '&:hover': {
      backgroundColor: '#216f42',
      color: 'white'
    },
    float: 'right',
    right: '1.7em',
    position: 'fixed',
    zIndex: 1000
  },
  seeMoreButton:{
    marginLeft: '45%',
    marginRight: '65%',
    marginTop: '-2em'
  },

  mainTab: {

    '& th':{
      textAlign: 'right',
    },
    '& th:nth-child(1)': {
      textAlign: 'left',
    },
    '& td':{
      textAlign: 'right',
    },
    '& td:nth-child(1)': {
      textAlign: 'left',
    },
    '& tr:first-child':{
      '& td:nth-child(9)':{
        display: 'none'
      },
      '& td:nth-child(10)':{
        display: 'none'
      },
      '& td:nth-child(11)':{
        display: 'none'
      },
      '& td:nth-child(12)':{
        display: 'none'
      },
    },
    '& tr:first-child td': {
      fontWeight: 'bold',
      paddingTop: '1em',
      paddingBottom: '1em',
      fontSize: '1em'
    }
  },
  rowTotal:{
    fontWeight: 'bold',
    fontSize: '1em'
  },
  transportTab: {
    '& td':{
      textAlign: 'right',
    },
    '& td:nth-child(1)': {
      textAlign: 'left',
    },
  },

  formControl: {
    marginTop: '18px',
    left: '-220px',
  },

  dataEmpty: {
    color: 'grey',
    fontSize: '2rem',
  },

  commercialInput: {
    width: '150px',
  },

  infosStockContainer: {
    display: 'flex',
    marginBottom: '30px',
    marginTop: '30px'
  },

  mainTabsStockContainer: {
    display: 'flex',
  },

  stockRow: {
    '& > *': {
      borderBottom: 'unset',
    },
  },
  center: {
    textAlign: 'center',
  },
  date_container:{
    display: 'flex',
    width:'18rem',
  },
  tooltip: {
    color: 'lightgrey',
      width: '1rem',
      fontSize: '1rem',
      height: '1rem',
  },
  marginTop3REM: {
    marginTop: '3rem',
  },

  tablePerformance: {
    width: '100%',
    '& tr': {
      '& td:not(:first-child)': {
        textAlign: 'center'
      }
    },
  },
  tablePortefeuilleHeader: {
    fontSize: '1.2em'
  },

  tablePortefeuilleRowTitle: {
    fontSize: '1.1em'
  },

  table: {
    marginBottom: '40px',
    '&td': {
      fontSize: '1.1em'
    }
  },
});
