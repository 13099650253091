export default theme => ({
  root: {
    flexGrow: 1
  },
  button: {
    float: 'right'
  },
  addButton: {
    marginLeft: '2em',
    marginTop: '0.5em',
    marginRight: '0.5em'
  },
  addButtonIcon: {
    fontSize: '32px'
  },
  error: {
    color: theme.palette.error.main
  },
  selectContainer: {
    width: 'calc(100% - 1em)'
  },
  sectionTitle: {
    marginBottom: '1em',
    color: theme.palette.primary.main,
    paddingTop :'8px',
  },
  section: {
    paddingTop:'2em',
  },
  inputContainer: {
    alignSelf: 'center',
  }
});
