import React, { Component } from "react";

import { Grid, Tab, TableContainer, Tabs } from "@material-ui/core";
import { withStyles } from "@material-ui/core/styles";

import Affaire from "../Affaire";
import HomeCss from "./css/HomeCss";
import { connect } from "react-redux";
import MonPortefeuille from "./MonPortefeuille";
import MesPerformances from "./MesPerformances";
import Articles from '../Article';

import LogoCapeco from "../../../assets/img/cap-eco-logo-1900.jpg";
import NonConformite from "../NonConformite";

class Home extends Component {
  state = {
    selectedTab: localStorage.getItem("home_tab_filter") || "affaires",
  };
  getAffaires() {
    const { classes, user } = this.props;
    return (
      <Grid item xs={12} className={classes.homeSection}>
        <Affaire
          delete={false}
          search={true}
          duplicate={false}
          add={false}
          data_params={{ params: { effectue: false } }}
          commercial_achat_filter={[
            user.is_commercial ? user.uuid : "all"
          ]}
          collectionCrud_options={{
            persistDatatableOptions: {
              id: "affaire_home",
            },
            rowsPerPageOptions: [5, 15, 25],
          }}
          disabledTabs={true}
          local_storage_key="affaire_home_tab_filter"
          infoText=" Affichage uniquement des affaires non terminées"
        />
      </Grid>
    );
  }

  getPortefeuille() {
    return <MonPortefeuille />;
  }

  getPerformances() {
    return <MesPerformances />;
  }

  handleChange = (event, selectedTab) => {
    this.setState({ selectedTab: selectedTab });
    localStorage.setItem("home_tab_filter", selectedTab);
  };

  defaultHome() {
    const { classes } = this.props;
    return (
      <Grid container className={classes.defaultHomeContainer} >
          <img src={LogoCapeco} alt="logo" className={classes.logoHome} />
      </Grid>
    );
  }

  getPoentiels() {
    return (
      <Articles
        showBtnAdd={false}
        showBtnEdit={false}
      />
    )
  }

  getNonConformites(){
    return (
      <NonConformite
        commercial_responsable_uuid={this.props.user.uuid}
      />
    )
  }

  render(){
    const { user } = this.props;
    if(user.is_commercial || user.is_admin){
      return this.getHomeCommercial();
    }
    return this.defaultHome();
  }

  getHomeCommercial() {
    const { selectedTab } = this.state;
    return (
      <>
        <Tabs indicatorColor="primary" value={selectedTab} onChange={this.handleChange}>
          <Tab label="Mes affaires" value={"affaires"} />
          <Tab label="Mon portefeuille" value={"portefeuille"} />
          <Tab label="Mes performances" value={"performances"} />
          <Tab label="Potentiels" value={"potentiels"} />
          <Tab label="Mes NC" value={"ncs"} />
        </Tabs>
        <TableContainer>
          {selectedTab === "affaires" && this.getAffaires()}
          {selectedTab === "portefeuille" && this.getPortefeuille()}
          {selectedTab === "performances" && this.getPerformances()}
          {selectedTab === "potentiels" && this.getPoentiels()}
          {selectedTab === "ncs" && this.getNonConformites()}
        </TableContainer>
      </>
    );
  }
}

Home = withStyles(HomeCss)(Home);

Home = connect((store) => ({
  user: store.auth.user,
}))(Home);

export default Home;
