import {axiosClient as axios} from '../../../../axios'

export function getProduitsTransformesEtat() {
  return axios.get(`/stock/produits_transformes/etat`)
    .then((response) => {
      return response.data
    })
}
export function getProduitsTransformesOrigines() {
  return axios.get(`/stock/produits_transformes/origines`)
    .then((response) => {
      return response.data
    })
}
export function getProduitsTransformesOptions() {
  return axios.get(`/stock/produits_transformes/options`)
    .then((response) => {
      return response.data
    })
}
export function getUsers() {
  return axios.get(`/stock/produits_transformes/users`)
    .then((response) => {
      return response.data
    })
}
