import React, { Component } from "react";
import { connect } from "react-redux";
import { withStyles } from "@material-ui/core/styles";
import {
  Grid,
  FormControl,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableRow,
  Typography,
  TableHead,
  InputLabel,
  Select,
  MenuItem,
  Link,
  CircularProgress,
} from "@material-ui/core";
import { DataTable, Cell } from "@oniti/datatable-material";

import HomeCss from "./css/SupervisionCss";
import {
  getInfosByMois,
  getInfosByPeriod,
} from "./actions/NegoceSupervisionActions";
import { buildRoute } from "../../../router/Tools";
import { Info } from "@material-ui/icons";
class NegoceSupervision extends Component {
  state = {
    category: "client",
    datas: [],
    initialLoad: true,
    date_debut: this.props.date_debut,
    date_fin: this.props.date_fin,
    mois: this.props.mois,
    loading: false
  };

  componentDidMount() {
    this.fetchData(this.props, this.state);
  }

  fetchData(props, state) {
    if (props.selectedValue === "mois") {
      this.setState({ loading: true });
      getInfosByMois(props.mois, state.category, props.commercial_uuid).then(
        (datas) => {
          this.setState({ datas: datas, loading: false });
        }
      );
    } else {
      this.setState({ loading: true });
      getInfosByPeriod(
        props.date_debut,
        props.date_fin,
        state.category,
        props.commercial_uuid
      ).then((datas) => {
        this.setState({ datas: datas, loading: false });
      });
    }
  }

  componentDidUpdate(prevProps) {
    if (
      prevProps.date_debut !== this.props.date_debut ||
      prevProps.date_fin !== this.props.date_fin ||
      prevProps.mois !== this.props.mois ||
      prevProps.commercial_uuid !== this.props.commercial_uuid
    ) {
      this.fetchData(this.props, this.state);
    }
  }

  // Formattage des prix
  formatPrix(prix) {
    if (!prix) {
      return 0;
    }
    const inf = new Intl.NumberFormat("fr-FR", {
      minimumFractionDigits: 0,
      maximumFractionDigits: 0,
    });
    return inf.format(prix);
  }

  formatPercent(percent) {
    if (!percent) {
      return 0 + "%";
    }
    return percent + "%";
  }

  toUpperCase(text) {
    return text ? text.toUpperCase() : text;
  }

  // Créer un tableau de valeurs
  createData(name, value, unit) {
    return { name, value, unit };
  }

  // Handler sur le changement de catégorie
  handleChangeCategory = (event) => {
    this.setState({ category: event.target.value }, () => {
      this.fetchData(this.props, this.state);
    });
  };

  extractSorted(sorted) {
    return Object.keys(sorted).map((key) =>
      this.createData(key, sorted[key], "")
    );
  }

  // Retourne le tableau d'informations générales
  getInfosGenerales() {
    const { classes } = this.props;
    if (this.state.datas.find((data) => data.nom === "infos") === undefined)
      return;

    let infos = this.state.datas.find((data) => data.nom === "infos").data;

    if (!infos) return null;

    if (!infos) return null;

    const rowsAffaire = [
      this.createData("Nombre d'Affaires", infos.nb_affaires),
      ...this.extractSorted(infos.sorted),
    ];
    const rowsCA = [
      this.createData("CA Vente", this.formatPrix(infos.ca_vente), "€"),
      this.createData(
        "Coût matière première",
        this.formatPrix(infos.ca_achat_matiere),
        "€"
      ),
      this.createData(
        "Transports sur affaire",
        this.formatPrix(infos.ca_achat_transport),
        "€"
      ),
    ];
    const rowsTotal = [
      this.createData("Marge - annexe inclus", this.formatPrix(infos.marge_totale), "€"),
      this.createData("Taux de marge total", infos.taux_marge_totale + "%"),
      this.createData(
        "Marge moyenne par affaire",
        this.formatPrix(infos.marge_moyenne_affaire),
        "€"
      ),
    ];

    return (
      <>
        <Typography variant="h6" color="primary">
          Informations Générales
        </Typography>
        <Paper>
          <Table>
            <TableBody>
              {rowsAffaire.map((row) => (
                <TableRow key={row.name} className={classes.rowA}>
                  <TableCell className={classes.tableCell}>
                  { row.name === "Nombre d'Affaires" ? (<span title="Affaires Hors Consigne et hors sans articles" style={{cursor:'pointer'}} ><Info className={classes.tooltip} /></span>) : null } {row.name}
                  </TableCell>
                  <TableCell align="right">
                    {row.value} {row.unit}
                  </TableCell>
                </TableRow>
              ))}
              {rowsCA.map((row) => (
                <TableRow key={row.name} className={classes.rowB}>
                  <TableCell className={classes.tableCell}>
                    {row.name}
                  </TableCell>
                  <TableCell align="right">
                    {row.value} {row.unit}
                  </TableCell>
                </TableRow>
              ))}
              {rowsTotal.map((row) => (
                <TableRow key={row.name} className={classes.rowA}>
                  <TableCell className={classes.tableCell}>
                    {row.name}
                  </TableCell>
                  <TableCell align="right">
                    {row.value} {row.unit}
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
          { this.getInfosTotauxTab()}
        </Paper>
      </>
    );
  }

  // Retourne le tableau d'attentes facturations
  getAttenteFacturation() {
    const { classes } = this.props;

    if (this.state.datas.find((data) => data.nom === "attentes") === undefined)
      return;
    let attentes = this.state.datas.find(
      (data) => data.nom === "attentes"
    ).data;
    if (!attentes || attentes.length === 0) return null;

    let max_array_key =
      attentes.poids.length > attentes.facturation.length
        ? "poids"
        : "facturation";

    return (
      <>
        <Typography variant="h6" color="primary">
          Détail avancement affaires
        </Typography>
        <Paper className={classes.attenteTab}>
          <Table className={classes.inPaperTab} stickyHeader>
            <TableHead>
              <TableRow>
                <TableCell align="center" style={{fontSize:"1.1rem"}}>Poids à Valider</TableCell>
                <TableCell align="center" style={{fontSize:"1.1rem"}}>Attente facturation client</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {attentes[max_array_key].map((value, index) => {
                return (
                  <TableRow key={value.reference_interne}>
                    <TableCell align="center" className={classes.tableCell}>
                      {attentes.poids && attentes.poids[index]
                        ? this.createLinkAffaire(attentes.poids[index])
                        : null}
                    </TableCell>
                    <TableCell align="center" className={classes.tableCell}>
                      {attentes.facturation && attentes.facturation[index]
                        ? this.createLinkAffaire(attentes.facturation[index])
                        : null}
                    </TableCell>
                  </TableRow>
                );
              })}
            </TableBody>
          </Table>
        </Paper>
      </>
    );
  }

  createLinkAffaire(affaire) {
    return (
      <Link
        href={buildRoute("/affaires/:affaires", {
          affaires: affaire.uuid,
        })}
        target="_blank"
      >
        {affaire.reference_interne}
      </Link>
    );
  }

  // Retourne le tableau des Transporteurs
  getTransporteurs() {
    const { classes } = this.props;
    let total = 0;

    if (
      this.state.datas.find((data) => data.nom === "transports") === undefined
    )
      return;
    let transporteurs = this.state.datas.find(
      (data) => data.nom === "transports"
    ).data;
    if (!transporteurs || transporteurs.length === 0) return null;

    // Calcul du prix total des transports
    transporteurs.forEach((transporteur) => {
      total += transporteur.price;
    });

    if (!transporteurs) return;
    return (
      <Grid item xs={12}>
        <Typography variant="h6" color="primary">
          Transporteurs
        </Typography>
        <Paper>
          <DataTable
            datas={transporteurs}
            defaultSort="price"
            className={classes.transportTab}
          >
            <Cell
              className={classes.tableCell}
              datakey="name"
              sortable={true}
              title="Nom"
            />
            <Cell
              className={classes.tableCell}
              datakey="price"
              sortable={true}
              title="Prix (€)"
              format={(obj, key) => {
                return this.formatPrix(obj[key]) + "€";
              }}
            />
          </DataTable>
          <Table>
            <TableBody>
              <TableCell align="left">
                <strong>TOTAL</strong>
              </TableCell>
              <TableCell align="right">
                <strong>{this.formatPrix(total) + "€"}</strong>
              </TableCell>
            </TableBody>
          </Table>
        </Paper>
      </Grid>
    );
  }

  // Retourne les checkboxes de choix de catégories pour le tableau main
  getCategories() {
    const { classes } = this.props;
    return (
      <>
        <FormControl className={classes.formControl}>
          <InputLabel>Catégorie</InputLabel>
          <Select
            value={this.state.category}
            onChange={this.handleChangeCategory}
            title="Ce filtre n'affectera que le tableau principal en bas de page."
          >
            <MenuItem value={"client"}>Client</MenuItem>
            <MenuItem value={"fournisseur"}>Fournisseur</MenuItem>
            <MenuItem value={"article"}>Articles</MenuItem>
            <MenuItem value={"famille"}>Familles d'articles</MenuItem>
            <MenuItem value={"activite_client"}>Activité Client</MenuItem>
            <MenuItem value={"activite_fournisseur"}>
              Activité Fournisseur
            </MenuItem>
          </Select>
        </FormControl>
      </>
    );
  }

  filterCallback(conf, datas, order) {
    const key = conf ? conf.datakey : "name";
    const total = datas.find((d) => d.name === "total");
    let data_filtered = datas.filter((d) => d.name !== "total");
    data_filtered.sort(function (a, b) {
      var valueA = a[key],
        valueB = b[key];

      if (key !== "name") {
        if(typeof valueA === "string" && typeof valueB === "string" ) {
          valueA = parseFloat(
            valueA
              .replace("€", "")
              .replace("%", "")
              .replace(" ", "")
              .replace(",", ".")
          );
          valueB = parseFloat(
            valueB
              .replace("€", "")
              .replace("%", "")
              .replace(" ", "")
              .replace(",", ".")
          );
        }

      }

      if (valueA < valueB) return order === "asc" ? 1 : -1;
      if (valueA > valueB) return order === "asc" ? -1 : 1;

      return 0;
    });
    data_filtered.unshift(total);
    return data_filtered;
  }

  // Retourne le tableau principal en fonction de la catégorie selectionnée
  getMainTable() {
    const { classes } = this.props;

    if (this.state.datas.find((data) => data.nom === "mains") === undefined)
      return;
    let main = this.state.datas.find((data) => data.nom === "mains").data;

    if (!main || main.length === 0) return null;

    return (
      <Grid item xs={12}>
        <Paper>
          <DataTable
            datas={main}
            defaultSort="name"
            className={classes.mainTab}
            filterCallback={this.filterCallback}
          >
            <Cell
              className={classes.tableCell}
              datakey="name"
              sortable={true}
              title="Nom"
              format={(obj, key) => {
                return this.toUpperCase(obj[key]);
              }}
            />
            <Cell
              className={classes.tableCell}
              datakey="ca_vente"
              sortable={true}
              title="CA Vente"
              format={(obj, key) => {
                return obj[key] + "€";
              }}
            />
            <Cell
              className={classes.tableCell}
              datakey="ca_achat"
              sortable={true}
              title="Coût matière première"
              format={(obj, key) => {
                return obj[key] + "€";
              }}
            />
            <Cell
              className={classes.tableCell}
              datakey="poids_vente"
              sortable={true}
              title="Tonnage Vente"
            />
            <Cell
              className={classes.tableCell}
              datakey="poids_achat"
              sortable={true}
              title="Tonnage Achat"
            />
            <Cell
              className={classes.tableCell}
              datakey="prix_transport"
              sortable={true}
              title="Prix Transport Net"
              format={(obj, key) => {
                return obj[key] + "€";
              }}
            />
            <Cell
              className={classes.tableCell}
              datakey="marge"
              sortable={true}
              title="Marge - annexe inclus"
              format={(obj, key) => {
                return obj[key] + "€";
              }}
            />
            <Cell
              className={classes.tableCell}
              datakey="taux_marge"
              sortable={true}
              title="Taux de Marge"
              format={(obj, key) => {
                return obj[key] ? obj[key] + "%" : "";
              }}
            />
            <Cell
              className={classes.tableCell}
              datakey="percent_ca_vente"
              sortable={true}
              title="% CA Vente"
              format={(obj, key) => {
                return this.formatPercent(obj[key]);
              }}
            />
            <Cell
              className={classes.tableCell}
              datakey="percent_ca_achat"
              sortable={true}
              title="% Coût matière première"
              format={(obj, key) => {
                return this.formatPercent(obj[key], key);
              }}
            />
            <Cell
              className={classes.tableCell}
              datakey="percent_poids_vente"
              sortable={true}
              title="% Tonnage Vente"
              format={(obj, key) => {
                return this.formatPercent(obj[key]);
              }}
            />
            <Cell
              className={classes.tableCell}
              datakey="percent_poids_achat"
              sortable={true}
              title="% Tonnage Achat"
              format={(obj, key) => {
                return this.formatPercent(obj[key]);
              }}
            />
          </DataTable>
        </Paper>
      </Grid>
    );
  }

  getInfosTotauxTab() {
    const { classes } = this.props;
    const data = this.state.datas.find((data) => data.nom === "totaux");
    if (data === undefined) return;
    const info = data.data;

    const rowsAffaire = [
      this.createData(
        "Factures annexes entrantes",
        this.formatPrix(info.facture_annexes_entrantes),
        "€"
      ),
      this.createData(
        "Factures annexes sortantes",
        this.formatPrix(info.facture_annexes_sortantes),
        "€"
      ),
      this.createData(
        "Transport Vente",
        this.formatPrix(info.total_transport_vente),
        "€"
      ),
    ];

    return (
      <>
        <Typography variant="h6" color="primary" className={classes.tableTitle+' '+classes.marginTop3REM}>
          Totaux
        </Typography>
        <Paper>
          <Table style={{ height: "100%" }}>
            <TableBody>
              {rowsAffaire.map((row) => (
                <TableRow key={row.name} className={classes.rowA}>
                  <TableCell className={classes.tableCell}>
                    {row.name}
                  </TableCell>
                  <TableCell align="right">
                    {row.value} {row.unit}
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </Paper>
      </>
    );
  }

  /*
   * Rendu Final
   */
  render() {
    const { classes } = this.props;

    const {loading} = this.state;

    if (loading) {
      return (
        <Grid container>
          <Grid item xs={12}>
            <h4 className={classes.center}>
              Récupération des données auprès du serveur
            </h4>
          </Grid>
          <Grid item xs={12} className={classes.center}>
            <CircularProgress />
          </Grid>
        </Grid>
      );
    }

    return (
      <>
        {this.getCategories()}

        <Grid item xs={12} className={classes.infosContainer}>
          {this.getInfosGenerales() === null &&
          this.getAttenteFacturation() === null &&
          this.getTransporteurs() === null &&
          this.getMainTable() === null ? (
            <Grid item xs={12} style={{ textAlign: "center" }}>
              <Typography variant="h3" className={classes.dataEmpty}>
                Pas de données sur cette période
              </Typography>
            </Grid>
          ) : null}
          <Grid item xs={4} className={classes.infosItem}>
            {this.getInfosGenerales()}
          </Grid>
          <Grid
            item
            xs={4}
            className={[classes.infosItem, classes.middle].join(" ")}
          >
            {this.getAttenteFacturation()}
          </Grid>
          <Grid item xs={4} className={classes.infosItem}>
            {this.getTransporteurs()}
          </Grid>
        </Grid>
        <Grid item xs={12} className={classes.categoriesTabContainer}>
          <Grid item xs={12}>
            {this.getMainTable()}
          </Grid>
        </Grid>
      </>
    );
  }
}

NegoceSupervision = withStyles(HomeCss)(NegoceSupervision);
NegoceSupervision = connect((store) => {
  return {
    affairesStore: store.collections.affaires,
    user: store.auth.user,
  };
})(NegoceSupervision);

export default NegoceSupervision;
