import React, { Component } from "react";
import { connect } from "react-redux";
import {
  Button,
  Grid,
  Typography,
  Radio,
  RadioGroup,
  FormControlLabel,
} from "@material-ui/core";
import { withStyles } from "@material-ui/core/styles";
import {
  Done,
} from "@material-ui/icons";

import AchatGroupeCss from "./css/AchatGroupeCss";

import TextInput from "../../common/Components/TextInput";
import DateInput from "../../common/Components/DateInput";
import { addNotification } from "../../../reducers/notificationReducer";
import { themeComplement } from "../AppAdmin/css/theme";

class AchatGroupe extends Component {
  state = {
    entreprise_uuid: null,
    mois: new Date(),
    date_debut: new Date(),
    date_fin: new Date(),
    selectedValue: "mois",
    openModal: false,
    href: null
  };

  handleChange = (event) => {
    this.setState({ selectedValue: event.target.value });
  };
    /**
   * OnChange Handler
   */
    onChangeHandler(name, event) {
      const { value } = event.target;
      this.setState({
        [name]: value,
      });
    }

  /**
   * Partie Bordeau d'achat
   * @return {[type]} [description]
   */
  getBordereauAchatGroupePartial() {
    const { classes, affairesStore } = this.props;
    let { entreprise_uuid, selectedValue } = this.state;

    return (
      <>
      <Grid item xs={12} className={classes.section}>
        <Typography
          variant="button"
          gutterBottom
          className={classes.sectionTitle}
        >
          Bordereau d'achat groupé
        </Typography>
        </Grid>
        <Grid container>
          <Grid item xs={3}>
            <TextInput
              id="entreprise_uuid"
              label="Fournisseur"
              className={classes.selectContainer}
              collectionStore={affairesStore}
              value={entreprise_uuid}
              margin="normal"
              name="entreprise_uuid"
              onChangeHandler={this.onChangeHandler.bind(this)}
              type="autocomplete"
              autocompleteProps={{
                collectionName: "entreprises",
              }}
              fullWidth
            />
          </Grid>
          <Grid item xs={3} className={classes.inputContainer}>
            <RadioGroup
              name="dates"
              className={classes.group}
              value={selectedValue}
              onChange={this.handleChange}
              row
            >
              <FormControlLabel
                value="mois"
                control={<Radio color="primary" />}
                label="Mois"
              />
              <FormControlLabel
                value="periode"
                control={<Radio color="primary" />}
                label="Période"
              />
            </RadioGroup>
          </Grid>
          <Grid item xs={3}>
            {this.getPeriodInputs()}
          </Grid>
            <Grid item xs={3} className={classes.inputContainer}>
              <Button
                title="Valider les modifications"
                color="primary"
                variant="contained"
                size="small"
                onClick={this.onSubmitHandler.bind(this)}
                className={classes.doneButton}
              >
                <Done className={classes.buttonIcon} /> Valider
              </Button>
            </Grid>
      </Grid>
      </>
    );
  }

  getPeriodInputs() {
    const { affairesStore } = this.props;
    let { date_debut, date_fin, mois, selectedValue } = this.state;

    if (selectedValue === "mois") {
      return (
        <DateInput
          id="mois"
          label="Mois d'export"
          format={"MMMM yyyy"}
          value={mois}
          margin="normal"
          collectionStore={affairesStore}
          name="mois"
          onChangeHandler={this.onChangeHandler.bind(this)}
          fullWidth
        />
      );
    } else {
      return (
        <>
          <DateInput
            id="date_debut"
            label="Date de début d'export"
            value={date_debut}
            margin="normal"
            collectionStore={affairesStore}
            name="date_debut"
            onChangeHandler={this.onChangeHandler.bind(this)}
            fullWidth
          />
          <DateInput
            id="date_fin"
            label="Date de fin d'export"
            value={date_fin}
            margin="normal"
            collectionStore={affairesStore}
            name="date_fin"
            onChangeHandler={this.onChangeHandler.bind(this)}
            fullWidth
          />
        </>
      );
    }
  }

    /**
   * Soumission du formulaire
   */
    onSubmitHandler() {
      let { mois, date_debut, date_fin, entreprise_uuid, selectedValue } =
          this.state,
        fields = { entreprise_uuid };
      if (!entreprise_uuid) {
        addNotification(this.props.dispatch, {
          message: "Fournisseur obligatoire",
          bgColor: themeComplement.palette.notifications.error.color,
        });
      } else {
        if (selectedValue === "mois") {
          mois =
            mois instanceof Date
              ? mois.getFullYear() +
                "-" +
                ((mois.getMonth() < 9 ? "0" : "") + (mois.getMonth() + 1)) +
                "-" +
                (mois.getDay() < 10 ? "0" : "") +
                mois.getDay()
              : mois;

          fields = {
            ...fields,
            mois,
          };
        } else {
          date_debut =
            date_debut instanceof Date
              ? date_debut.getFullYear() +
                "-" +
                ((date_debut.getMonth() < 9 ? "0" : "") +
                  (date_debut.getMonth() + 1)) +
                "-" +
                (date_debut.getDate() < 10 ? "0" : "") +
                date_debut.getDate()
              : date_debut;

          date_fin =
            date_fin instanceof Date
              ? date_fin.getFullYear() +
                "-" +
                ((date_fin.getMonth() < 9 ? "0" : "") +
                  (date_fin.getMonth() + 1)) +
                "-" +
                (date_fin.getDate() < 10 ? "0" : "") +
                date_fin.getDate()
              : date_fin;

          fields = {
            ...fields,
            date_debut,
            date_fin,
          };
        }

        let params = Object.entries({
            ...fields,
          })
            .map((kv) => (kv[1] ? kv.map(encodeURIComponent).join("=") : null))
            .join("&"),
          url =
            process.env.REACT_APP_API_URL +
            "/entreprises/bordereau-achat-groupe?" +
            params;

        window.open(url, "_blank");
      }
    }

  /*
   * Rendu Final
   */
  render() {
    return (
      <Grid container>
        {this.getBordereauAchatGroupePartial()}
      </Grid>
    );
  }
}

  AchatGroupe = withStyles(AchatGroupeCss)(AchatGroupe);
AchatGroupe = connect((store) => {
  return {
    affairesStore: store.collections.affaires,
    user: store.auth.user,
  };
})(AchatGroupe);

export default AchatGroupe;
