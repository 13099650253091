import { themeComplement } from "../../AppAdmin/css/theme";
export default (theme) => ({
  disabledInput: {
    "& label": {
      color: theme.palette.text.secondary + " !important",
    },
    "& input[disabled]": {
      backgroundColor: "#e7e7e7",
      color: theme.palette.text.primary,
    },
    "& textarea": {
      backgroundColor: "#e7e7e7",
      color: theme.palette.text.primary,
    },
    "& div": {
      backgroundColor: "#e7e7e7",
      color: theme.palette.text.primary,
    },
  },
  emailresendable: {
    color: themeComplement.palette.notifications.warning.color,
    borderColor: themeComplement.palette.notifications.warning.color,
    "&:hover": {
      borderColor: themeComplement.palette.notifications.warning.color,
    },
  },
  leadIcon: {
    marginRight: theme.spacing(1),
  },
  openButton: {
    marginTop: "2.0em",
  },
  titleIcon: {
    marginRight: theme.spacing(1),
  },
  optionSelected: {
    backgroundColor:
      themeComplement.palette.notifications.success.color + "!important",
  },
  center: {
    textAlign: "center",
  },
  dragrable: {
    width: '100%',
    margin: '0 auto',
    border: 'dashed 2px ' + theme.palette.primary.main+'!important',
  },
  danger:{
    color: themeComplement.palette.notifications.error.color,
  }
});
