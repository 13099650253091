import {
  Button,
  DialogContent,
  DialogTitle,
  Grid,
  IconButton,
  MenuItem,
  Typography,
  withStyles,
} from "@material-ui/core";
import React, { Component } from "react";
import PropTypes from "prop-types";
import DocumentCss from "./css/DocumentsCss";
import { connect } from "react-redux";
import {
  collectionActions,
  loadCollectionAttribute,
} from "../../../reducers/collectionsReducer";

import { FileUploader } from "react-drag-drop-files";
import Modal from "../../common/Components/Modal";
import TextInput from "../../common/Components/TextInput";
import CollectionCrud from "../../common/Components/CollectionCrud";
import { FileCopy, CloudDownload } from "@material-ui/icons";
import DocumentPicker from "../../common/Components/DocumentPicker";

class Documents extends Component {
  state = {
    openModal: false,
    openModalPhoto: false,
    documentToShow: null,
    file: null,
    filename: null,
    type_document_uuid: null,
    file_index: 0,
    files: null,
    fakerender: 0,
    showPhoto: false,
    localDocuments: [],
    selected_documents: this.props.selected_documents || [],
  };
  componentDidMount() {
    loadCollectionAttribute(
      this.props.dispatch,
      "list",
      "type_documents",
      this.props.typeDocumentsStore
    );

    this.getDocumentsFromBackend();
  }

  getDocumentsFromBackend() {
    collectionActions(this.props.dispatch, "documents", "INDEX", {
      params: {
        documentable_type: this.props.documentable_type,
        documentable_uuid: this.props.documentable_uuid,
      },
    },
    (data) => {
      const localDocuments = [
        ...data
      ]
      if(this.props.additionnal_documents){
        this.props.additionnal_documents.forEach((doc) => {
          if(!localDocuments.some((localDoc) => localDoc.uuid === doc.uuid)){
            localDocuments.push(doc);
          }
        })
      }
      this.setState(({
        localDocuments
      }))
    }
  );
  }

  getDeleteModalTitle() {
    return (
      <div>
        <FileCopy />
        <span>{"Suppression du document"}</span>
      </div>
    );
  }

  getDeleteModalContent() {
    return (
      <Typography>
        {"Êtes-vous sûr de vouloir supprimer ce document ?"}
      </Typography>
    );
  }

  getCreateUpdateModalTitle(document) {
    if (!document) return null;
    let title = document.filename;
    return (
      <div>
        <FileCopy />
        <span>{title}</span>
      </div>
    );
  }

  getCreateUpdateModalContent(document, documentsStore, onChangeHandler) {
    const { filename, type_document_uuid } = document;
    return (
      <Grid container>
        <Grid item xs={12}>
          <TextInput
            id="filename"
            label="Nom"
            value={filename}
            margin="normal"
            collectionStore={documentsStore}
            name="filename"
            onChangeHandler={onChangeHandler}
            fullWidth
          />
        </Grid>
        <Grid item xs={12}>
          <TextInput
            id="type_document_uuid"
            label="Type Document"
            value={type_document_uuid}
            margin="normal"
            collectionStore={documentsStore}
            name="type_document_uuid"
            onChangeHandler={onChangeHandler}
            fullWidth
            select
          >
            {this.props.typeDocumentsStore.list.map((typeDocument) => (
              <MenuItem key={typeDocument.uuid} value={typeDocument.uuid}>
                {typeDocument.libelle}
              </MenuItem>
            ))}
          </TextInput>
        </Grid>
      </Grid>
    );
  }

  onChangeHandler(name, event) {
    this.setState({ [name]: event.target.value });
  }

  getModalContent() {
    if (!this.state.file) return null;
    const { documentsStore } = this.props;
    return [
      <DialogTitle key="title" id="alert-dialog-slide-title">
        <Typography>Upload du fichier {this.state.file.name}</Typography>
      </DialogTitle>,
      <DialogContent key="content">
        {this.getCreateUpdateModalContent(
          this.state,
          documentsStore,
          this.onChangeHandler.bind(this)
        )}
      </DialogContent>,
    ];
  }

  onDropHandler(files, callback) {
    const file = files ? files[this.state.file_index] : null;
    if (file) {
      this.setState({
        openModal: true,
        file,
        filename: file.name,
        files,
        file_index: this.state.file_index,
      });
    } else {
      this.setState(
        {
          file_index: 0,
          files: null,
          file: null,
          filename: null,
          type_document_uuid: null,
        },
        callback
      );
    }
  }

  onSubmitHandler() {
    const { file, filename, type_document_uuid } = this.state;
    const formData = new FormData();
    formData.append("documentable_type", this.props.documentable_type);
    formData.append("documentable_uuid", this.props.documentable_uuid);
    formData.append("filename", filename);
    formData.append("type_document_uuid", type_document_uuid);
    formData.append("file", file);
    collectionActions(
      this.props.dispatch,
      "documents",
      "CREATE",
      formData,
      (response) => {
        if (response) {
          this.setState(
            {
              openModal: false,
              file: null,
              filename: null,
              type_document_uuid: null,
              file_index: this.state.file_index + 1,
            },
            () => {
              this.onDropHandler(this.state.files, () => {
                this.getDocumentsFromBackend();
              });
            }
          );
        }
      }
    );
  }

  onCloseHandler(name) {
    this.setState({ [name]: false, documentToShow: name === "openModalPhoto" ? null : this.state.documentToShow });
  }

  getDownloadButton(document) {
    const { classes } = this.props;
    return (
      <IconButton
        color="primary"
        key={document.uuid}
        href={document.url}
        target="_blank"
        className={classes.button}
      >
        <CloudDownload />
      </IconButton>
    );
  }
  showThumbnail(obj, key) {
    const { classes } = this.props;
    if(!this.state.showPhoto) return null;

    const document = obj
    if(!document) return <div className={classes.imageApercuContainer}>Aucun aperçu disponible</div>;

    const allowed_types = ["image/", "application/pdf"];

    if(!allowed_types.some((mime_type) => {
      return document.mime.startsWith(mime_type)
    })) return <div className={classes.imageApercuContainer}>Aucun aperçu disponible</div>;

    return (
      <div
        className={classes.imageApercuContainer}
        onClick={(e) => {
          e.stopPropagation();
          this.setState({ openModalPhoto: true, documentToShow: document });
        }}
      >
        <div className={classes.hover}></div>
        {this.displayDocument(document, "imageApercu")}
      </div>
    )
  }

  getCellsConfig() {
    const { showPhoto, selected_documents } = this.state;
    const { selectable, select_callback } = this.props;

    const seletable_cell = [
      {
        datakey: "select",
        title: "Sélectionner",
        sortable: false,
        searchable: false,
        format: (obj, key) => {
          return (
            <input
              key={key+'_'+this.state.fakerender}
              type="checkbox"
              onChange={(e) => {
                select_callback(obj);
                if(selected_documents.some((doc) => doc.filename === obj.filename)){
                  this.setState({
                    selected_documents: selected_documents.filter((doc) => doc.filename !== obj.filename),
                    fakerender: this.state.fakerender + 1
                  })
                } else {
                  this.setState({
                    selected_documents: [...selected_documents, obj],
                    fakerender: this.state.fakerender + 1
                  })
                }
              }}
              style={{ cursor: "pointer" }}
              checked={selected_documents.some((doc) => doc.filename === obj.filename)}
            />
          );
        },
      },
    ]

    const cells = [
      ...(selectable ? seletable_cell : []),
      {
        datakey: "filename",
        title: "Nom Fichier",
        sortable: true,
        searchable: true,
      },
      {
        datakey: "type_document_libelle",
        title: "Type Document",
        sortable: true,
        searchable: true,
      },
      {
        datakey: "created_at",
        title: "Date de création",
        sortable: true,
        searchable: true,
        isDate: true,
      },
    ]

    if (showPhoto) {
      cells.push({
        datakey: "url",
        title: "Image",
        sortable: false,
        searchable: false,
        format: (obj, key) => this.showThumbnail(obj, key),
      })
    }

    return cells;
  }

  displayDocumentToShow() {
    const { documentToShow } = this.state;
    if(!documentToShow) return null;
    return this.displayDocument(documentToShow, "fullSize");
  }

  displayDocument(document, classe){
    const { classes } = this.props;
    return (
      <object
          key={document.uuid}
          title={document.type_document_libelle}
          data={document.url}
          className={classes[classe]}
        >
          <p>Aucun aperçu disponible</p>
        </object>
    )
  }

  addDocumentInStore() {
    const { documentsStore } = this.props;
    if (!documentsStore.list) return null;
    return documentsStore.list.length > 0 ?? null;
  }


  displayShowPhotoBtn() {
    const { documentable_type, documentable_uuid, disable_upload } = this.props;
    const { showPhoto, fakerender } = this.state;
    const btn_show_photo = (
      <Button
        variant="contained"
        color="primary"
        onClick={() => {
          let newfakeRerender = fakerender + 1;
          this.setState(
            { showPhoto: !this.state.showPhoto, fakerender: newfakeRerender },
            () => {
              this.getCellsConfig();
            }
          );
        }}
      >
        {showPhoto ? "Masquer les photos" : "Voir les photos"}
      </Button>
    )

    if(disable_upload){
      return btn_show_photo;
    }

    return (
      <>
      {btn_show_photo}
      <DocumentPicker
        documentable_type={documentable_type}
        documentable_uuid={documentable_uuid}
        hasDocument={this.addDocumentInStore()}
      />
    </>
    )
  }

  getUploaderInput(){
    const { classes, disable_upload } = this.props;

    if(disable_upload) return null;

    return (
      <Grid item xs={12} style={{ marginTop: "1em" }}>
          <FileUploader
            multiple={true}
            name="file"
            label="Déposez vos fichiers a uploader ici"
            onDrop={(files) =>
              this.setState({ file_index: 0, files: null }, () => {
                this.onDropHandler(files);
              })
            }
            onSelect={(files) =>
              this.setState({ file_index: 0, files: null }, () => {
                this.onDropHandler(files);
              })
            }
            classes={classes.dragrable}
            maxSize={50}
            onSizeError={(error) => {
              alert("Le fichier est trop volumineux (max 50Mo)");
            }}
          />
      </Grid>
    )

  }


  uploadPart(){
    const { classes, disable_upload } = this.props;
    const typography = disable_upload ? null :(
      <Typography
            variant="button"
            gutterBottom
            className={classes.sectionTitle}
          >
            Documents
      </Typography>
    )

    return (
      <>
        <div className={classes.documentsTitleContainer}>
          {typography}
          {this.displayShowPhotoBtn()}
        </div>
        {this.getUploaderInput()}
      </>
    )
  }

  render() {
    const { openModal, openModalPhoto } = this.state;

    const { classes,rowsPerPageOptions, rowsPerPage } = this.props;
    return (
      <>
        <Grid item xs={12} className={classes.section}>
            {this.uploadPart()}
            <CollectionCrud
              key={this.state.fakerender}
              collectionName={"documents"}
              showBtnEdit={true}
              showBtnAdd={false}
              showBtnDelete={true}
              loadDatas={false}
              datas={this.state.localDocuments}
              deleteModalTitle={this.getDeleteModalTitle}
              deleteModalContent={this.getDeleteModalContent.bind(this)}
              createUpdateModalTitle={this.getCreateUpdateModalTitle}
              createUpdateModalContent={this.getCreateUpdateModalContent.bind(
                this
              )}
              showBtnDeleteCallBack={(document) => !document.is_system  && document.documentable_type === this.props.documentable_type && document.documentable_uuid === this.props.documentable_uuid}
              showBtnEditCallBack={(document) => !document.is_system && document.documentable_type === this.props.documentable_type && document.documentable_uuid === this.props.documentable_uuid}
              datatableConfig={{
                showPagination: true,
                showSearch: true,
                defaultSort: "created_at",
                sortType: "asc",
                nodatalabel: "Aucun document",
                rowsPerPageOptions: rowsPerPageOptions || [5, 10, 25],
                rowsPerPage: rowsPerPage || 5,
              }}
              additionnalControllers={[this.getDownloadButton.bind(this)]}
              cellsConfig={this.getCellsConfig()}
              cancelUpdateCheck={true}
            />
        </Grid>
        <Modal
          openModal={openModal}
          onCloseHandler={this.onCloseHandler.bind(this, "openModal")}
          onSubmitHandler={this.onSubmitHandler.bind(this)}
          fullWidth={true}
          maxWidth={"sm"}
          disabledEnter={false}
        >
          {this.getModalContent()}
        </Modal>

        <Modal
          openModal={openModalPhoto}
          onCloseHandler={this.onCloseHandler.bind(this, "openModalPhoto")}
          fullWidth={true}
          maxWidth={"lg"}
          disabledEnter={false}
          disableActionButtons={true}
        >
          {this.displayDocumentToShow()}
        </Modal>
      </>
    );
  }
}

Documents = withStyles(DocumentCss)(Documents);

Documents.prototype = {
  documentable_type: PropTypes.string.isRequired,
  documentable_uuid: PropTypes.string.isRequired,
  disable_upload: PropTypes.bool,
  seletable: PropTypes.bool,
  select_callback: PropTypes.func,
  additionnal_documents: PropTypes.array,
  selected_documents: PropTypes.array,
};

Documents = connect((store) => {
  return {
    documentsStore: store.collections.documents,
    typeDocumentsStore: store.collections.type_documents,
  };
})(Documents);

export default Documents;
