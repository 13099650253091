/**
 * Références toutes les routes de l'application.
 * Utile pour Common/App/reducers/appReducer qui doit trouver la configuration de chaque route pour afficher ou non le container par exemple
 * @type {[type]}
 */

import {routes} from '../router/routes';
import {routes as routesAdmin} from './admin/AppAdmin/routes/routes';
import {routes as routesAdminLogin} from './admin/Auth/routes/routes';

export const allRoutes = routes.concat(routesAdmin)
  .concat(routesAdminLogin)
