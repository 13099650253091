import {
  Button,
  CircularProgress,
  DialogTitle,
  Divider,
  Grid,
  MenuItem,
  withStyles,
} from "@material-ui/core";
import { Person } from "@material-ui/icons";
import React, { Component } from "react";
import { connect } from "react-redux";
import { collectionActions } from "../../../reducers/collectionsReducer";

import DateInput from "../../common/Components/DateInput";
import TextInput from "../../common/Components/TextInput";
import { getStatuts } from "./actions/PackinglistActions";

import PackinglistCss from "./css/PackinglistCss";
import SelectProduitTransforme from "./SelectProduitTransforme";
import format from "date-fns/format";

class PackinglistDetail extends Component {
  state = {
    statuts: null,
    selection: [],
    isDateSortieAvailable: false,
    packinglist: null,
    createMode: false,
  };

  componentDidMount() {
    let { dispatch } = this.props;
    getStatuts((statuts) => {
      this.setState({
        statuts,
      });
    });

    if (this.props.match.params.uuid === "create") {
      this.setState({
        createMode: true,
        packinglist:{
          statut: "disponible",
          reference:null,
          date : format(new Date(), "yyyy-MM-dd HH:mm:ss"),
          affaire_uuid : null,
          observations : null,
          commentaire_interne : null,
          date_sortie : null,
        }
      });
    } else {
      collectionActions(
        dispatch,
        "packinglists",
        "SHOW",
        {
          uuid: this.props.match.params.uuid,
        },
        (packinglist) => {
          this.setState({
            packinglist,
            selection: packinglist.produits,
          });
        }
      );
    }
  }

  onChangeCurrentStatutHandler(el, event) {
    let isDateSortieAvailable = false;
    if (event.target.value === "vendu") {
      isDateSortieAvailable = true;
    }
    this.setState({
      packinglist: {
        ...this.state.packinglist,
        statut: event.target.value,
        date_sortie: isDateSortieAvailable
          ? this.state.packinglist.date_sortie
          : null,
      },
      isDateSortieAvailable: isDateSortieAvailable,
    });
  }

  compare(a, b) {
    const referenceA = a.reference.toUpperCase();
    const referenceB = b.reference.toUpperCase();

    let comparison = 0;
    if (referenceA > referenceB) {
      comparison = 1;
    } else if (referenceA < referenceB) {
      comparison = -1;
    }
    return comparison;
  }

  onClickCardHandler(produit) {
    let selection = this.state.selection;

    if (selection.findIndex((p) => p.uuid === produit.uuid) === -1)
      selection.push(produit);
    else selection = selection.filter((p) => p.uuid !== produit.uuid);

    selection.sort(this.compare);

    this.setState({ selection });
  }

  onClickRemoveProduitHandler(produit) {
    let selection = this.state.selection;

    if (selection.findIndex((p) => p.uuid === produit.uuid) !== -1)
      selection = selection.filter((p) => p.uuid !== produit.uuid);

    selection.sort(this.compare);

    this.setState({ selection });
  }

  onChangeHandler(name, event) {
    this.setState({
      packinglist: {
        ...this.state.packinglist,
        [name]: event.target.value,
      },
    });
  }

  getStatutsList() {
    let { statuts } = this.state;

    if (!statuts) return [];
    return Object.keys(statuts).map((key) => {
      return (
        <MenuItem value={key} key={key}>
          {statuts[key]}
        </MenuItem>
      );
    });
  }

  onSubmitHandler() {
    let { dispatch } = this.props;
    collectionActions(
      dispatch,
      "packinglists",
      this.state.createMode ? "CREATE" : "UPDATE",
      {
        ...this.state.packinglist,
        produits: this.state.selection.map((p) => p.uuid),
      },
      (packinglist) => {
        if (packinglist) {
          this.setState({
            packinglist: null,
            selection: [],
            isDateSortieAvailable: false,
          });
          this.onCloseHandler();
        }
      }
    );
  }
  onCloseHandler() {
    this.props.history.push("/pslists/packinglists");
  }

  render() {
    const { packinglistsStore, classes } = this.props;
    const { packinglist, statuts } = this.state;

    if (!packinglist || !statuts) {
      return (
        <>
          <h4 className={classes.center}>
            Récupération des données actualisées auprès du serveur
          </h4>
          <CircularProgress />
        </>
      );
    }

    const {
      reference,
      statut,
      date,
      affaire_uuid,
      observations,
      commentaire_interne,
      date_sortie,
    } = packinglist;

    const title = reference
      ? `Modification de la packing list : "  ${reference}`
      : "Ajout d'une nouvelle packing list";

    return (
      <Grid container className={classes.noMargin}>
        <Grid item xs={12}>
          <DialogTitle className={classes.header}>
            <Person />
            <span>{title}</span>
          </DialogTitle>
        </Grid>

        <Grid item xs={3}>
          <TextInput
            id="statut"
            label="Statut"
            value={statut}
            required
            margin="normal"
            collectionStore={packinglistsStore}
            name="statut"
            onChangeHandler={this.onChangeCurrentStatutHandler.bind(this)}
            select
            fullWidth
          >
            {this.getStatutsList()}
          </TextInput>
        </Grid>
        <Grid item xs={1}>
          <DateInput
            id="date"
            label="Date"
            value={date}
            margin="normal"
            collectionStore={packinglistsStore}
            name="date"
            onChangeHandler={this.onChangeHandler.bind(this)}
            fullWidth
            pickertype="date"
            format={"d MMMM yyyy"}
            required
          />
        </Grid>
        <Grid item xs={3}>
          <TextInput
            value={affaire_uuid}
            id="affaire_uuid"
            label="Affaire"
            margin="normal"
            collectionStore={packinglistsStore}
            name="affaire_uuid"
            onChangeHandler={this.onChangeHandler.bind(this)}
            className={classes.autocomplete}
            type="autocomplete"
            autocompleteProps={{
              collectionName: "affaires",
            }}

            clearAutoCompleteCallBack={() => {
              this.setState({
                packinglist: {
                  ...this.state.packinglist,
                  affaire_uuid: null,
                },
              });
            }}
          />
        </Grid>
        <Grid item xs={3}>
          <TextInput
            id="observations"
            label="Observations"
            value={observations}
            margin="normal"
            collectionStore={packinglistsStore}
            name="observations"
            onChangeHandler={this.onChangeHandler.bind(this)}
            fullWidth
          />
        </Grid>
        <Grid item xs={2}>
          <DateInput
            id="date_sortie"
            label="Date de sortie"
            value={date_sortie}
            margin="normal"
            collectionStore={packinglistsStore}
            name="date_sortie"
            onChangeHandler={this.onChangeHandler.bind(this)}
            fullWidth
            pickertype="date"
            format={"d MMMM yyyy"}
            disabled={!this.state.isDateSortieAvailable}
          />
        </Grid>
        <Grid item xs={6}>
          <TextInput
            id="commentaire_interne"
            label="Commentaire interne"
            value={commentaire_interne}
            margin="normal"
            collectionStore={packinglistsStore}
            name="commentaire_interne"
            onChangeHandler={this.onChangeHandler.bind(this)}
            fullWidth
          />
        </Grid>
        <Grid item xs={12} className={classes.divider}>
          <Divider />
        </Grid>
        <SelectProduitTransforme
          selectCb={this.onClickCardHandler.bind(this)}
          removeCb={this.onClickRemoveProduitHandler.bind(this)}
          selection={this.state.selection}
          packinglist_uuid={
            this.props.packinglistsStore.detail
              ? this.props.packinglistsStore.detail.uuid
              : null
          }
        />
        <Grid item xs={10}></Grid>
        <Grid item xs={2} className={classes.divider}>
          <Button onClick={this.onCloseHandler.bind(this)}>
            Annuler
          </Button>
          <Button
            color="primary"
            onClick={this.onSubmitHandler.bind(this)}
            style={{ marginLeft: "3rem" }}
          >
            Valider
          </Button>
        </Grid>
      </Grid>
    );
  }
}

PackinglistDetail = withStyles(PackinglistCss)(PackinglistDetail);

PackinglistDetail = connect((store) => {
  return {
    packinglistsStore: store.collections.packinglists,
    user: store.auth.user,
  };
})(PackinglistDetail);

export default PackinglistDetail;
