import React, { Component } from "react";
import { connect } from "react-redux";
import EmailButton from "../Email";
import { Button, Grid, Link, Typography } from "@material-ui/core";
import { withStyles } from "@material-ui/core/styles";
import CollectionCrud from "../../common/Components/CollectionCrud";
import DateInput from "../../common/Components/DateInput";
import CheckboxInput from "../../common/Components/CheckboxInput";
import {
  collectionActions,
  sendBordereauAchatMensuel,
} from "../../../reducers/collectionsReducer";
import FacturationCss from "./css/FacturationCss";
import { addNotification } from "../../../reducers/notificationReducer";
import { themeComplement } from "../AppAdmin/css/theme";
import { ArrowLeft, ArrowRight, Warning } from "@material-ui/icons";
import { TextField, MenuItem } from "@material-ui/core";
import { buildRoute } from "../../../router/Tools";
import { withRouter } from "react-router";
import AchatGroupe from "../AchatGroupe";
import moment from "moment";


const LOCALSTORAGE_KEY = "bordereau_achat_filters";

class Bordereau extends Component {
  state = {
    date_debut: new Date(),
    date_debut_string: this.initDateString(new Date()),
    commercial_uuid: null,
    loaddatatimer: null,
    sending: null,
  };

  componentDidMount() {
    this.loadFilters();

    collectionActions(this.props.dispatch, "users", "INDEX", {
      params: {
        commerciaux: true,
      },
    });
  }

  initDateString(date_debut) {
    return (
      date_debut.getFullYear() +
      "-" +
      ((date_debut.getMonth() < 9 ? "0" : "") + (date_debut.getMonth() + 1)) +
      "-" +
      (date_debut.getDate() < 10 ? "0" : "") +
      date_debut.getDate()
    );
  }

  /**
   * Charge les données en fonction de la date passé en paramètre.
   */
  loadDatas() {
    let { date_debut_string, commercial_uuid,sending } = this.state;
    collectionActions(this.props.dispatch, "bordereau_achat", "INDEX", {
      params: {
        date_debut: date_debut_string,
        entreprise_uuid: this.props.entreprise_uuid,
        commercial_uuid: commercial_uuid,
        sending: sending
      },
    });
  }

  /**
   * OnChange Handler
   */
  onChangeHandler(name, event) {
    const { value } = event.target;
    let date =
      value instanceof Date
        ? value.getFullYear() +
          "-" +
          ((value.getMonth() < 10 ? "0" : "") + (value.getMonth() + 1)) +
          "-" +
          (value.getDate() < 10 ? "0" : "") +
          value.getDate()
        : value;
    this.setState(
      {
        [name]: value,
        date_debut_string: date,
      },
      () => {
        this.loadDatas();
        this.saveFilters();
      }
    );
  }

  loadFilters(){
    const { entreprise_uuid } = this.props;
    const filters = localStorage.getItem(LOCALSTORAGE_KEY+entreprise_uuid);
    if(filters){

      const { date_debut, commercial_uuid, updated_at,sending } = JSON.parse(filters);
      const now = new Date();
      const diff = now.getTime() - (updated_at ? moment(updated_at).toDate() : new Date()).getTime();
      const diffDays = Math.ceil(diff / (1000 * 3600 * 24));

      if(diffDays > 1){ // Si la date de mise à jour est supérieur à 1 jour, on ne charge pas les filtres
        this.loadDatas();
      }else{
        this.setState({
          date_debut: new Date(date_debut),
          date_debut_string: this.initDateString(new Date(date_debut)),
          commercial_uuid: commercial_uuid,
          sending: sending
        }, () => {
          this.loadDatas();
        })
      }
    }else{
      this.loadDatas();
    }
  }

  saveFilters(){
    const { date_debut, commercial_uuid, sending } = this.state;
    const { entreprise_uuid } = this.props;
    localStorage.setItem(LOCALSTORAGE_KEY+entreprise_uuid, JSON.stringify({
      date_debut: date_debut,
      commercial_uuid: commercial_uuid,
      updated_at: new Date(),
      sending: sending
    }));
  }

    /**
   * Retourne le sélecteur d'envoye
   */
    getSendingSelector() {
      const { classes } = this.props;
      const { sending } = this.state

      const sends = [
        {
          value: "all",
          label: "Tous",
        },
        {
          value: true,
          label: "oui",
        },
        {
          value: false,
          label: "non",
        },

      ];
      const options = sends.map((send) => {
        return (
          <MenuItem value={send.value} key={send.value}>
            {send.label}
          </MenuItem>
        )
      })

      return (
        <TextField
          id="commercial_uuid"
          label="ENVOYE"
          value={sending}
          margin="normal"
          onChange={this.onChangeHandlerFilters.bind(this, "sending")}
          className={classes.CommercialselectTitle}
          select
          fullWidth
        >
            {options}
        </TextField>
      );
    }


  /**
   * Retourne le titre le sélecteur de date et de commercial
   */
  getCrudTitle() {
    const { classes, bordereauAchatStore } = this.props;
    const { date_debut } = this.state;
    return [
      <>
      <Grid container>
        <Grid item xs={6}>
          <Typography
            key="typo"
            variant="button"
            gutterBottom
            className={classes.crudTitle}
          >
            <span className={classes.spanTitle}>Filtres</span>
              </Typography>
            <Button onClick={() => this.changeDate(-1)}>
              <ArrowLeft />
            </Button>
            <DateInput
              className={classes.dateselectTitle}
              label="Mois"
              format={"MMMM yyyy"}
              value={date_debut}
              margin="normal"
              collectionStore={bordereauAchatStore}
              name="date_debut"
              onChangeHandler={this.onChangeHandler.bind(this)}
              fullWidth
            />
            <Button onClick={() => this.changeDate(1)}>
              <ArrowRight />
            </Button>
            {this.getCommercialSelector()}
            {this.getSendingSelector()}
        </Grid>
        <Grid item xs={6} className={classes.crudTitle}>
          <AchatGroupe />
        </Grid>
      </Grid>
      </>
    ];
  }

  changeDate(nb_month) {
    let { date_debut } = this.state;
    var date_debut_moment = moment(date_debut);
    date_debut_moment.add(nb_month, "months");
    this.setState(
      {
        date_debut: date_debut_moment.toDate(),
        date_debut_string: this.initDateString(date_debut_moment.toDate())
      },
      () => {
        clearTimeout(this.loaddatatimer);
        this.saveFilters();
        this.loaddatatimer = setTimeout(() => {
          this.loadDatas();
        }, 500);
      }
    );
  }

  onChangeHandlerFilters(name, event) {
    const { value } = event.target;
    this.setState(
      {
        [name]: value,
      },
      () => {
        this.saveFilters();
        this.loadDatas();
      }
    );
  }


  generateCommercialSelect() {
    const { usersStore, } = this.props;
    if (!usersStore.list) return null;
    const commerciaux = [
      {
        uuid: "all",
        firstname: "Tous",
      },
      ...usersStore.list,
    ];
    const options = commerciaux.map((user) => {
      return (
        <MenuItem value={user.uuid} key={user.uuid}>
          {user.firstname}
        </MenuItem>
      );
    })
    return options;
  }


  /**
   * Retourne le sélecteur de commercial
   */
  getCommercialSelector() {
    const { classes } = this.props;
    const { commercial_uuid } = this.state

    return (
      <TextField
        id="commercial_uuid"
        label="COMMERCIAL"
        value={commercial_uuid}
        margin="normal"
        onChange={this.onChangeHandlerFilters.bind(this, "commercial_uuid")}
        className={classes.CommercialselectTitle}
        select
        fullWidth
      >
          {this.generateCommercialSelect()}
      </TextField>
    );
  }

  /**
   * Changement de valeur de la checkbox.
   * @param entreprise
   * @param name
   * @param event
   */
  onChangeHandlerFacturation(entreprise, name, event) {
    sendBordereauAchatMensuel(this.props.dispatch, {
      entreprise_uuid: entreprise.uuid,
      date_debut: this.state.date_debut_string,
      already_exists: !!entreprise.current_bordereau,
      entreprise_emettrice_uuid: this.props.entreprise_uuid,
    });
  }

  /**
   * Retourne la checkbox par ligne
   * @param {*} field
   * @param {*} entreprise
   */
  getCheckBox(field, entreprise) {
    return (
      <CheckboxInput
        value={!!entreprise.current_bordereau}
        margin="normal"
        name={field}
        onChangeHandler={this.onChangeHandlerFacturation.bind(this, entreprise)}
        disabled={
          !!entreprise.current_bordereau && !!entreprise.current_bordereau.email
        }
      />
    );
  }

  getEmailBtn(entreprise) {
    if (!entreprise.email_rendered) return null;

    const { classes } = this.props;

    return (
      <EmailButton
        className={classes.emailButton}
        email={entreprise.email_rendered}
        key={entreprise.uuid + "_bordereau_mensuel"}
        required={""}
        label={"Email"}
        onSent={(email) => {
          if (!email) {
            addNotification(this.props.dispatch, {
              message: "Impossible d’envoyer cet email.",
              bgColor: themeComplement.palette.notifications.error.color,
            });
            return;
          }
          sendBordereauAchatMensuel(this.props.dispatch, {
            entreprise_uuid: entreprise.uuid,
            date_debut: this.state.date_debut_string,
            already_exists: false,
            email_uuid: email.uuid,
            entreprise_emettrice_uuid: this.props.entreprise_uuid,
          });
        }}
        disabled={!entreprise.email_rendered}
      />
    );
  }
  /**
   * Configuration des cellules
   */
  getCellsConfig() {
    const { classes } = this.props;
    return [
      {
        datakey: "nom",
        title: "Nom",
        sortable: true,
        searchable: true,
      },
      {
        datakey: "commercial_fullname",
        title: "Commercial",
        sortable: true,
        searchable: true,
      },
      {
        datakey: "facture_fournisseur_waiting",
        title: "Prêt à envoyer BA ?",
        sortable: true,
        searchable: false,
        isBoolean: true,
        className: classes.thBASend,
        format: (entreprise) =>
          entreprise.facture_fournisseur_waiting.length > 0 ? (
            <Link
              style={{
                cursor: "pointer",
                color: "#941111",
              }}
              href={buildRoute("/affaires/liste/:affaires", {
                affaires: entreprise.facture_fournisseur_waiting.join(","),
              })}
            >
              <Warning />
            </Link>
          ) : null,
      },
      {
        datakey: "is_send",
        title: "Envoyé",
        sortable: true,
        searchable: false,
        isBoolean: true,
        format: this.getCheckBox.bind(this, "is_send"),
        className: classes.thEnvoye,
      },
      {
        datakey: "email",
        title: "",
        sortable: false,
        searchable: false,
        format: this.getEmailBtn.bind(this),
        // className: classes.thPdf,
      },
    ];
  }

  /**
   * Rendu final
   */
  render() {
    return (
      <>
      {this.getCrudTitle()}
      <CollectionCrud
        collectionName={"bordereau_achat"}
        showBtnEdit={false}
        showBtnAdd={false}
        showBtnDelete={false}
        loadDatas={false}
        datatableConfig={{
          showPagination: true,
          showSearch: true,
          defaultSort: "nom",
          sortType: "desc",
          nodatalabel: "Aucune affaire sur la période sélectionnée",
          rowsPerPageOptions: [25, 50],
        }}
        cancelUpdateCheck={true}
        persistDatatableOptions={{
          id: "bordereau_achat_tab",
          exipiration_minutes: 60 * 12, // 12 Heures
        }}
        // dataTableExtraNodes={[
        //   {
        //     element: this.getCrudTitle(),
        //     position: "top-left",
        //   },
        // ]}
        cellsConfig={this.getCellsConfig()}
      />
      </>
    );
  }
}

Bordereau = withStyles(FacturationCss)(Bordereau);
Bordereau = withRouter(Bordereau);
Bordereau = connect((store) => {
  return {
    bordereauAchatStore: store.collections.bordereau_achat,
    usersStore: store.collections.users,
  };
})(Bordereau);

export default Bordereau;
