import React, { Component } from "react";
import { connect } from "react-redux";
import PropTypes from "prop-types";

import { Grid, IconButton, MenuItem, TextField, Typography } from "@material-ui/core";
import { withStyles } from "@material-ui/core/styles";
import { Grain, ZoomIn } from "@material-ui/icons";

import { collectionActions, loadCollectionAttribute } from "../../../reducers/collectionsReducer";
import CollectionCrud from "../../common/Components/CollectionCrud";
import TextInput from "../../common/Components/TextInput";

import ArticleCss from "./css/ArticleCss";
import Potentiel from "../Potentiel";
import CheckboxInput from "../../common/Components/CheckboxInput";
import { hasRights } from "../../common/Tools/Tools";

const LOCAL_STORAGE_KEY = "articles_filters_v1";

class Article extends Component {
  static defaultProps = {
    delete: true,
    pagination: true,
    search: true,
  };

  static propTypes = {
    articles: PropTypes.array,
    delete: PropTypes.bool,
    pagination: PropTypes.bool,
    search: PropTypes.bool,
  };

  state = {
    showModalPotentiel: false,
    article: null,
    filters: {
      famille: ["all"],
      is_broye: ["all"],
      is_desactive: "all",
    },
  };

  onChangeHandlerFilters(name, e) {
    let values = e.target.value;
    /**
     * Gestion de la value all
     * si on clique dessus on vide la liste et ne garde que all
     * si on clique sur un autre element on enleve all
     * si on vide la liste on rajoute all
     */
    if(values.length > 0) {
      if(this.state.filters[name].indexOf('all') !== -1) {
        values = values.filter(value => value !== "all");
      }else if(values.indexOf('all') !== -1) {
        values = ['all'];
      }
    }else{
      values = ['all'];
    }

    this.setState(
      {
        filters:{
          ...this.state.filters,
          [name]: values,
        }
      },
      () => {
        localStorage.setItem(LOCAL_STORAGE_KEY, JSON.stringify(this.state.filters));
      }
    );
  }

  handleBoolSelectChange(e) {
    this.setState({
      filters: {
        ...this.state.filters,
        [e.target.name]: e.target.value,
      },
    }, () => {
      localStorage.setItem(LOCAL_STORAGE_KEY, JSON.stringify(this.state.filters));
    });
  }

  createFilterBoolSelect(label, name) {
    let classes = this.props.classes;
    return (
      <TextField
        name={name}
        label={label}
        margin="normal"
        onChange={this.handleBoolSelectChange.bind(this)}
        className={classes.selectContainer}
        SelectProps={{
          value: this.state.filters[name],
        }}
        select
      >
        <MenuItem value={'all'} key={'all'}>Tous</MenuItem>
        <MenuItem value={'yes'} key={'yes'}>Oui</MenuItem>
        <MenuItem value={'no'} key={'no'}>Non</MenuItem>
      </TextField>
    );

  }

  withFamille(article) {
    if (this.state.filters.famille.includes("all")) return true;
    if (this.state.filters.famille.includes(article.famille_uuid)) return true;
    return false;
  }

  withIsBroye(article) {
    if (this.state.filters.is_broye.includes("all")) return true;
    if (this.state.filters.is_broye.includes(article.broye)) return true;
    return false;
  }

  withIsDesactive(article) {
    if (this.state.filters.is_desactive === "all") return true;
    if (this.state.filters.is_desactive === "yes" && article.deleted_at) return true;
    if (this.state.filters.is_desactive === "no" && !article.deleted_at) return true;
    return false;
  }

  getArticlesList() {
    if (!this.props.articlesStore.list) return [];

    return this.props.articlesStore.list.filter((article) => {
      return this.withFamille(article) && this.withIsBroye(article) && this.withIsDesactive(article);
    });
  }

  componentDidMount() {
    loadCollectionAttribute(
      this.props.dispatch,
      "list",
      "familles",
      this.props.famillesStore
    );
    loadCollectionAttribute(
      this.props.dispatch,
      "list",
      "articles",
      this.props.articlesStore,
      {
        params:{
          with_trashed: true,
        }
      }
    );

    let filters = localStorage.getItem(LOCAL_STORAGE_KEY);
    if(filters) {
      this.setState({
        filters: JSON.parse(filters),
      });
    }
  }

  getDeleteModalTitle() {
    return (
      <div>
        <Grain />
        <span>{"Suppression de l'article"}</span>
      </div>
    );
  }

  getDeleteModalContent() {
    return (
      <Typography>
        {"Êtes-vous sûr de vouloir supprimer cet article ?"}
      </Typography>
    );
  }

  getCreateUpdateModalTitle(article) {
    let title = !!article
      ? "Modification de l'article : " + article.nom
      : "Ajout d'un nouvel article";
    return (
      <div>
        <Grain />
        <span>{title}</span>
      </div>
    );
  }

  getFamilles() {
    if (!this.props.famillesStore.list) {
      return [];
    }
    return this.props.famillesStore.list.map((famille) => {
      return (
        <MenuItem value={famille.uuid} key={famille.uuid}>
          {famille.nom}
        </MenuItem>
      );
    });
  }

  getCreateUpdateModalContent(article, articlesStore, onChangeHandler) {
    let { libelle_fr, libelle_en, commentaires, famille_uuid, deleted_at, broye, conso_type } =
      article;
    const { classes } = this.props;
    return (
      <Grid container>
        <Grid item xs={12}>
          <TextInput
            id="libelle_fr"
            label="Libelle FR"
            value={libelle_fr}
            margin="normal"
            collectionStore={articlesStore}
            name="libelle_fr"
            onChangeHandler={onChangeHandler}
            fullWidth
          />
        </Grid>
        <Grid item xs={12}>
          <TextInput
            id="libelle_en"
            label="Libelle EN"
            value={libelle_en}
            margin="normal"
            collectionStore={articlesStore}
            name="libelle_en"
            onChangeHandler={onChangeHandler}
            fullWidth
          />
        </Grid>
        <Grid item xs={12}>
          <TextInput
            id="famille_uuid"
            label="Famille"
            value={famille_uuid}
            required={true}
            margin="normal"
            collectionStore={articlesStore}
            name="famille_uuid"
            onChangeHandler={onChangeHandler}
            className={classes.selectContainer}
            select
          >
            {this.getFamilles()}
          </TextInput>
        </Grid>
        <Grid item xs={12}>
          <TextInput
            id="commentaires"
            label="Commentaires"
            value={commentaires}
            margin="normal"
            collectionStore={articlesStore}
            name="commentaires"
            onChangeHandler={onChangeHandler}
            fullWidth
          />
        </Grid>
        <Grid item xs={12}>
          <TextInput
            id="broye"
            label="Broyé"
            value={broye}
            required={true}
            margin="normal"
            collectionStore={articlesStore}
            name="broye"
            onChangeHandler={onChangeHandler}
            className={classes.selectContainer}
            select
          >
            <MenuItem value="broye">Broyé</MenuItem>
            <MenuItem value="en_etat">En l'état</MenuItem>
            <MenuItem value="granule">Granulé</MenuItem>
          </TextInput>
        </Grid>
        <Grid item xs={12}>
          <TextInput
            id="conso_type"
            label="Conso"
            value={conso_type}
            required={true}
            margin="normal"
            collectionStore={articlesStore}
            name="conso_type"
            onChangeHandler={onChangeHandler}
            className={classes.selectContainer}
            select
          >
            <MenuItem value="pre">Pré</MenuItem>
            <MenuItem value="post">Post</MenuItem>
          </TextInput>
        </Grid>
        <Grid item xs={6}>
          <CheckboxInput
            id="deleted_at"
            label="Désactivé"
            value={!!deleted_at}
            margin="normal"
            collectionStore={articlesStore}
            name="deleted_at"
            onChangeHandler={(name,e) => {
              e.target.value = e.target.value ? new Date() : null
              onChangeHandler(name,e)
            }}
            type="checkbox"
          />
        </Grid>
      </Grid>
    );
  }

  formatDate(date) {
    if(!date) return ""

    const options = {day: 'numeric', month: 'long', year: 'numeric'};
    let dateObj = new Date(date);
    return dateObj.toLocaleDateString('fr-FR', options)
  }

  onChangeHandlerArticleRegroupement(article, name, e) {
    collectionActions(this.props.dispatch, "articles", "UPDATE", {
      ...article,
      article_regroupement_uuid: e.target.value,
    })
  }

  getCellsConfig() {
    var article_regroupement = null
    const { classes, articlesStore } = this.props;

    if(hasRights("admin-articles-regroupement", this.props.authStore.user)) {
      article_regroupement = {
        datakey: "article_regroupement_nom",
        title: "Regroupement",
        sortable: true,
        searchable: true,
        useDataKeyOnSearch: true,
        format: (obj, key) => {
          const { article_regroupement_uuid } = obj
          return (
            <TextInput
              label="Article regroupement"
              className={classes.selectContainer}
              collectionStore={articlesStore}
              value={article_regroupement_uuid}
              margin="normal"
              name="article_regroupement_uuid"
              onChangeHandler={this.onChangeHandlerArticleRegroupement.bind(this, obj)}
              type="autocomplete"
              autocompleteProps={{
                collectionName: "article_regroupements",
              }}
              fullWidth
              key={'article_regroupement_uuid_'+obj.uuid}
            />
          )
        }
      }
    }

    return [
      {
        datakey: "libelle_fr",
        title: "Libelle FR",
        sortable: true,
        searchable: true,
      },
      {
        datakey: "libelle_en",
        title: "Libelle EN",
        sortable: true,
        searchable: true,
      },
      {
        datakey: "famille_nom",
        title: "Famille",
        sortable: true,
        searchable: true,
      },
      {
        datakey: "broye_libelle",
        title: "Broyé",
        sortable: true,
        searchable: false,
      },
      {
        datakey: "conso_type_libelle",
        title: "Conso",
        sortable: true,
        searchable: false,
      },
      {
        datakey: "deleted_at",
        title: "Désactivé le",
        sortable: true,
        searchable: true,
        format: (obj, key) => {
          return this.formatDate(obj[key])
        }
      },
      article_regroupement,
      {
        datakey: "potentiel",
        title: "Potentiels",
        sortable: false,
        searchable: false,
        format: (article) => (
          <IconButton
            key="potentiel"
            aria-label="Potentiel"
            color="primary"
            onClick={() => {
              this.setState({
                showModalPotentiel: true,
                article: article,
              });
            }}
            title="Potentiel"
          >
            <ZoomIn />
          </IconButton>
        ),
      },
    ];
  }

  getCrudTitle() {
    const { classes } = this.props;
    return (
      <Typography variant="button" gutterBottom className={classes.crudTitle}>
        Articles
      </Typography>
    );
  }

  getFamilleFilter() {
    const { famillesStore, classes } = this.props;
    if (!famillesStore.list) return null;
    const familles = [
      {
        uuid: "all",
        nom: "Toutes",
      },
      ...famillesStore.list,
    ];
    const options = familles.map((famille) => {
      return (
        <MenuItem value={famille.uuid} key={famille.uuid}>
          {famille.nom}
        </MenuItem>
      );
    });

    return (
      <TextField
        label="Famille"
        margin="normal"
        onChange={this.onChangeHandlerFilters.bind(this, "famille")}
        className={classes.selectContainer}
        SelectProps={{
          multiple: true,
          value: this.state.filters.famille,
        }}
        select
      >
        {options}
      </TextField>
    );
  }

  getBroyeFilter() {
    const { classes } = this.props;
    return (
      <TextField
        label="Broye"
        margin="normal"
        onChange={this.onChangeHandlerFilters.bind(this, "is_broye")}
        className={classes.selectContainer}
        SelectProps={{
          multiple: true,
          value: this.state.filters.is_broye,
        }}
        select
      >
        <MenuItem value={'all'} key={'all'}>Tous</MenuItem>
        <MenuItem value={'broye'} key={'broye'}>Broye</MenuItem>
        <MenuItem value={'en_etat'} key={'en_etat'}>En l'état</MenuItem>
        <MenuItem value={'granule'} key={'granule'}>Granule</MenuItem>
      </TextField>
    );
  }

  getConsoBtn() {
    const { classes } = this.props;
    return (
      <TextField
        label="Conso"
        margin="normal"
        onChange={this.onChangeHandlerFilters.bind(this, "conso_type")}
        className={classes.selectContainer}
        SelectProps={{
          value: this.state.filters.conso_type,
        }}
        select
      >
        <MenuItem value={'pre'} key={'pre'}>Pré</MenuItem>
        <MenuItem value={'post'} key={'post'}>Post</MenuItem>
      </TextField>
    );
  }

  getFilters() {
    const { classes } = this.props;
    return (
      <Grid container className={classes.filtersContainer}>
        <Grid item xs={12}>
          <Typography
            key="typo"
            variant="button"
            gutterBottom
            className={classes.crudTitle}
          >
            Filtres
          </Typography>
        </Grid>
        <Grid item xs={2}>
          {this.getFamilleFilter()}
        </Grid>
        <Grid item xs={1}>
          {this.getBroyeFilter()}
        </Grid>
        <Grid item xs={1}>
          {this.createFilterBoolSelect("Désactivé", "is_desactive")}
        </Grid>
      </Grid>
    );
  }

  render() {
    let showBtnAdd = this.props.hasOwnProperty("showBtnAdd") ? this.props.showBtnAdd : true;
    let showBtnEdit = this.props.hasOwnProperty("showBtnEdit") ? this.props.showBtnEdit : true;
    return (
      <>
        {this.getFilters()}
        <CollectionCrud
          collectionName={"articles"}
          datas={this.getArticlesList()}
          rights={{
            create: "admin-cud-articles",
            edit: "admin-cud-articles",
            delete: "admin-cud-articles",
          }}
          loadDatas={false}
          showBtnEdit={showBtnEdit}
          showBtnAdd={showBtnAdd}
          showBtnDelete={false}
          deleteModalTitle={this.getDeleteModalTitle}
          deleteModalContent={this.getDeleteModalContent.bind(this)}
          createUpdateModalTitle={this.getCreateUpdateModalTitle}
          createUpdateModalContent={this.getCreateUpdateModalContent.bind(this)}
          datatableConfig={{
            showPagination: this.props.pagination,
            showSearch: this.props.search,
            defaultSort: "libelle_fr",
            sortType: "desc",
            nodatalabel: "Aucun article",
            rowsPerPageOptions: [25, 50],
          }}
          persistDatatableOptions={{
            id: "articles",
            exipiration_minutes: 60 * 12, // 12 Heures
          }}
          dataTableExtraNodes={[
            {
              element: this.getCrudTitle(),
              position: "top-left",
            },
          ]}
          cellsConfig={this.getCellsConfig()}
        />
        <Potentiel
          open={this.state.showModalPotentiel}
          article={this.state.article}
          onCloseHandler={() => {
            this.setState({ showModalPotentiel: false, article_uuid: null });
          }}
          mode="article"
        />
      </>
    );
  }
}

Article = withStyles(ArticleCss)(Article);

Article = connect((store) => {
  return {
    famillesStore: store.collections.familles,
    articlesStore: store.collections.articles,
    authStore: store.auth,
  };
})(Article);

export default Article;
