export default (theme) => ({
  button: {
    "& span": {
      display: "block",
    },
    marginLeft: ".5em",
    marginBottom: ".5em",
    textAlign: "center",
    width: "calc(50% - .5em)",
  },
  file_ico: {
    display: "block",
    margin: "auto",
    padding: "0.3em",
  },
});
