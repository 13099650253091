import {Avatar, Button, Grid, TextField, Typography} from '@material-ui/core';
import {withStyles} from '@material-ui/core/styles';
import React, {Component} from 'react';
import {connect} from 'react-redux';
import {withRouter} from 'react-router-dom';
import {getUser, login} from '../../../reducers/authReducer';
import AuthCss from './css/AuthCss';
import Logo from '../../../assets/img/cap-eco-logo-103px.png';
import { addNotification } from '../../../reducers/notificationReducer';
import { themeComplement } from '../AppAdmin/css/theme';
import AuthCode from 'react-auth-code-input';

class Login extends Component {
  state = {
    email: '',
    password: '',
    logged: false,
    code_2fa: '',
    asking_2fa: false,
    method: '',
  };

  /**
   * Retourne le nouveau state en fonction des nextProps
   * @param  {[type]} nextProps [description]
   * @param  {[type]} prevState [description]
   * @return {[type]}           [description]
   */
  static getDerivedStateFromProps(nextProps, prevState) {
    if (!prevState.logged && !!nextProps.auth.user) {
      let previousPathRoute = nextProps.pathRouteHistory.find(path => !path.startsWith('/auth'));
      nextProps.history.push(previousPathRoute ? previousPathRoute : '/');
      return {
        ...prevState,
        logged: true
      };
    } else if (!nextProps.auth.user && prevState.logged) {
      return {
        ...prevState,
        logged: false
      };
    }

    return null;
  }

  /**
   * Handler de changement de valeur pour les formulaires
   * @param {*} event
   */
  handleChange(event) {
    let {value, name} = event.target;
    this.setState({
      [name]: value,
    });
  };

  /**
   * Handler pour le composant de saisie du code 2FA
   * @param {*} value
   */
  handle2FAChange(value) {
    this.setState({
      code_2fa: value,
    });
  }

  /**
   * Soumission du formulaire
   * @return {[type]} [description]
   */
  onClickSubmit() {
    login(this.props.dispatch, {
      email: this.state.email,
      password: this.state.password,
      code_2fa: this.state.code_2fa,
    }, (data) => {
      switch (data.auth) {
        case 'success':
          getUser(this.props.dispatch)
          break;

        case 'waiting_2fa':
          this.setState({
            asking_2fa: true,
            method: data.method,
          })
          break;

        default:
          addNotification(this.props.dispatch, {
            message: "Réponse serveur invalide",
            bgColor: themeComplement.palette.notifications.error.color
          })
      }
    })
  }

  /**
   * Handle du mot de passe perdu
   * @param  {[type]} e [description]
   * @return {[type]}   [description]
   */
  onClickPasswordLost(e) {
    e.preventDefault();
    this.props.history.push("/auth/password/lost");
  }

  /**
   * Pour valider le formulaire avec Enter
   * @param  {[type]} e [description]
   * @return {[type]}   [description]
   */
  onKeyPress(e) {
    if (e.which === 13 || e.keyCode === 13 || e.key === 'Enter') { // Différents modes de détection pour prendre en charge l'ensemble des navigateurs
      this.onClickSubmit()
    }
  }


  getLoginPasswordForm() {
    const {classes} = this.props;
    return (
        <>
          <Grid item>
            <TextField
              name="email"
              label="Email"
              className={classes.textField}
              value={this.state.email}
              onChange={this.handleChange.bind(this)}
              onKeyPress={this.onKeyPress.bind(this)}
              helperText=""
              error={false}
              type={"email"}
              autoComplete={"off"}
              margin="normal"
            />
          </Grid>
          <Grid item>
            <TextField
              name="password"
              label="Mot de passe"
              className={classes.textField}
              value={this.state.password}
              onChange={this.handleChange.bind(this)}
              onKeyPress={this.onKeyPress.bind(this)}
              type={"password"}
              autoComplete={"off"}
              margin="normal"
            />
          </Grid>
        </>
    )
  }

  get2FAForm() {
    const {classes} = this.props;
    return (
      <>
        <Typography variant="body1" className={classes.msg2FA}>
          Un message avec un code de vérification a été envoyé par <strong>{ this.state.method === 'sms' ? 'SMS' : 'mail' }</strong>,
          veuillez le saisir pour continuer.
        </Typography>
        <AuthCode
          characters={5}
          value={this.state.code_2fa}
          onChange={this.handle2FAChange.bind(this)}
          containerClassName={classes.container2FA}
          inputClassName={classes.input2FA}
        />
      </>
    )
  }

  getMdpOublieBtn() {
    const {classes} = this.props;
    return (
      <Button
        color="primary"
        onClick={this.onClickPasswordLost.bind(this)}
        className={classes.button}
      >
        Mot de passe oublié ?
      </Button>
    )
  }

  /**
   * Fonction de rendu final
   * @return {[type]} [description]
   */
  render() {
    const {classes} = this.props;
    return (
      <div>
        <Grid item>
          <Avatar className={classes.avatar}>
            <img src={Logo} alt='Logo'/>
          </Avatar>
        </Grid>
        { this.state.asking_2fa ? this.get2FAForm() : this.getLoginPasswordForm() }
        <Grid item className={classes.buttonsLign}>
          <Button
            variant="contained"
            color={"primary"}
            onClick={this.onClickSubmit.bind(this)}
            className={classes.button}
          >
            Valider
          </Button>
          { this.state.asking_2fa ? null : this.getMdpOublieBtn() }
        </Grid>
      </div>
    );
  }
}


Login = connect((store) => {
  return {
    auth: store.auth,
    pathRouteHistory: store.app.pathRoutesHistory,
    currentRouteConfig: store.app.currentRouteConfig
  }
})(Login);

Login = withStyles(AuthCss)(Login);
Login = withRouter(Login);

export default Login;
