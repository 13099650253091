import { Tab, Tabs, Typography } from "@material-ui/core";
import { withStyles } from "@material-ui/core/styles";
import React, { Component } from "react";
import { connect } from "react-redux";
import { collectionActions } from "../../../reducers/collectionsReducer";
import StockCss from "./css/StockCss";
import StockMatierePremiere from "./MatierePremiere";
import ProduitTransforme from "./ProduitTransforme";
import BigBagsAValider from "./BigBagsAValider";
import { hasRights } from "../../common/Tools/Tools";
import BigBagsSortieStock from "./BigBagsSortieStock";

function TabContainer(props) {
  return (
    <Typography component="div" style={{ width: "100%" }}>
      {props.children}
    </Typography>
  );
}

const TAB_PRODUITS_TRANSFORMES = 0;
const TAB_MATIERES_PREMIERES = 1;
const TAB_BIGBAGS_A_VALIDER = 2;
const TAB_OUT_OF_STOCK = 3;

const URL_PRODUITS_TRANSFORMES = "/stock/produits_transformes";
const URL_PRODUITS_TRANSFORMES_OUT_OF_STOCK = "/stock/produits_transformes/out_of_stock";
const URL_MATIERES_PREMIERES = "/stock/matieres_premieres";
const URL_BIGBAGS_A_VALIDER = "/stock/validation_bigbags";

function getSelectedTab(pathname) {
  switch (pathname) {
    case URL_PRODUITS_TRANSFORMES:
      return TAB_PRODUITS_TRANSFORMES;
    case URL_MATIERES_PREMIERES:
      return TAB_MATIERES_PREMIERES;
    case URL_BIGBAGS_A_VALIDER:
      return TAB_BIGBAGS_A_VALIDER;
    case URL_PRODUITS_TRANSFORMES_OUT_OF_STOCK:
      return TAB_OUT_OF_STOCK;
    default:
      return -1;
  }
};

class Stock extends Component {
  state = {
    selectedTab:getSelectedTab(this.props.location.pathname),
  };

  componentDidMount() {}

  handleChange = (event, selectedTab) => {
    this.setState({ selectedTab });

    switch (selectedTab) {
      case TAB_PRODUITS_TRANSFORMES:
        this.props.history.push(URL_PRODUITS_TRANSFORMES);
        break;
      case TAB_MATIERES_PREMIERES:
        this.props.history.push(URL_MATIERES_PREMIERES);
        collectionActions(
          this.props.dispatch,
          "stock/matieres_premieres",
          "RESET_LIST"
        );
        break;
      case TAB_BIGBAGS_A_VALIDER:
        this.props.history.push(URL_BIGBAGS_A_VALIDER);
        collectionActions(
          this.props.dispatch,
          "stock/produits_transformes",
          "RESET_LIST"
        );
        break;
      case TAB_OUT_OF_STOCK:
        this.props.history.push(URL_PRODUITS_TRANSFORMES_OUT_OF_STOCK);
        collectionActions(
          this.props.dispatch,
          "stock/produits_transformes",
          "RESET_LIST"
        );
        break;
      default:
        console.log("Vous ne devriez jamais voir ça.");
    }
  };

  getProduitsTransformes() {
    return (
      <TabContainer key={TAB_PRODUITS_TRANSFORMES}>
        <ProduitTransforme
          allowFromBBEntame={true}
        />
      </TabContainer>
    );
  }

  getProduitsTransformesSortieStock() {
    return (
      <TabContainer key={TAB_OUT_OF_STOCK}>
        <BigBagsSortieStock />
      </TabContainer>
    );
  }

  getMatieresPremieres() {
    return (
      <TabContainer key={TAB_MATIERES_PREMIERES}>
        <StockMatierePremiere />
      </TabContainer>
    );
  }

  getBigbagsAValider() {
    return (
      <TabContainer key={TAB_BIGBAGS_A_VALIDER}>
        <BigBagsAValider />
      </TabContainer>
    );
  }

  render() {
    const { selectedTab } = this.state;
    const { user } = this.props;

    if (selectedTab < 0) {
      return null;
    }

    return (
      <>
        <Tabs
          value={selectedTab}
          onChange={this.handleChange}
          className={this.props.classes.tabs}
        >
          {hasRights(["admin-c-bigbags", "admin-stock", 'stock-pdttransf-lecture-seulement'], user) ? (
            <Tab label="Produits transformés" value={0} />
          ) : null}
          {hasRights(["admin-stock"], user) ? (
            <Tab label="Matières premières" value={1} />
          ) : null}
          {hasRights(["admin-stock"], user) ? (
            <Tab label="Bigbags à valider" value={2} />
          ) : null}
          {hasRights(["admin-stock"], user) ? (
            <Tab label="Bigbags sortis du stock" value={3} />
          ) : null}
        </Tabs>
        {selectedTab === TAB_PRODUITS_TRANSFORMES && this.getProduitsTransformes()}
        {selectedTab === TAB_MATIERES_PREMIERES && this.getMatieresPremieres()}
        {selectedTab === TAB_BIGBAGS_A_VALIDER && this.getBigbagsAValider()}
        {selectedTab === TAB_OUT_OF_STOCK && this.getProduitsTransformesSortieStock()}
      </>
    );
  }
}

Stock = withStyles(StockCss)(Stock);

Stock = connect((store) => {
  return {
    location: store.router.location,
    user: store.auth.user,
  };
})(Stock);

export default Stock;
