export default theme => ({
    iconContainer: {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'stretch',
        flexDirection: 'column',
    },
    documentContainer: {
        display: 'flex',
        alignItems: 'stretch',
        flexDirection: 'row',
        flexWrap: 'wrap',
        gap: '.5rem',
    },
    actionContainer: {
        cursor: 'pointer',
        border : '1px solid #ccc',
        padding:'5px',
        borderRadius: '5px',
        position: 'relative',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'end',
        width: '15rem',
        minHeight: '5rem',
        '& img': {
            maxWidth: '100%',
            maxHeight: '100%',
        },
        '@media only screen and (min-device-width : 320px) and (max-device-width : 480px)': {
            maxWidth: '100vw',
        },
    },
    btnContainer: {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        flexDirection: 'row',
        gap: '.5rem',
    },
    deleteBtn: {
        position: 'absolute',
        top: '0',
        right: '1rem',
        color: '#b90000',
        cursor: 'pointer',
    },
    editBtn: {
        position: 'absolute',
        top: '0',
        right: '4rem',
        color: theme.palette.primary.main,
        cursor: 'pointer',
    },
    zoomContainer: {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        position: 'absolute',
        top: '4rem',
        right: '0',
    },
    hasDocument: {
        color: 'green',
    },
    imageApercu: {
      width: '100%',
      height: '100%',
      maxHeight: '150px',
    },
    imageApercuContainer: {
        textAlign: 'center',
        with:"fit-content",
        height: 'fit-content',
    },
    hover:{
        cursor: 'pointer',
        top: 0,
        left: 0,
        width: '100%',
        height: 'fit-content',
        wordWrap: 'break-word',
    },
    aperçuObject: {
        width: '100%',
        height: '100%',
        objectFit: "scale-down",
    },
});
