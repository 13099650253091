export default theme => ({
  root: {
    flexGrow: 1
  },
  crudTitle: {
    float: 'left',
    marginBottom: 0,
    padding: '8px 0',
    color: theme.palette.primary.main,
  },
  button: {
    float: 'right'
  },
  addButton: {
    marginLeft: '2em',
    marginTop: '0.5em',
    marginRight: '0.5em'
  },
  addButtonIcon: {
    fontSize: '32px'
  },
  error: {
    color: theme.palette.error.main
  },
  selectContainer: {
    width: 'calc(100% - 1em)'
  },
  photoBtn: {
   margin: "auto",
  },
  iconBtn: {
    width: "50px",
    height: "50px",
 },
 BtnModuleContainer: {
    width: "100%",
    display: "flex",
    justifyContent: "space-around"
 },
 iconeList: {
  marginRight: "15px",
 },
 cameraIcon: {
  margin: "auto",
  marginTop: "25px",
  },
  affaireInformations:{
    margin: "24px",
  },
  photoList: {
    display: 'flex',
    flexWrap: 'wrap',
    width: '100%',
  },
  photoListImg: {
    width: '100%',
  },
  navigationButton: {
    "text-align-last": "center",
  },
});
