import React, {Component} from 'react';
import {connect} from 'react-redux';
import {Grid} from '@material-ui/core';
import {Redirect, Switch, withRouter} from 'react-router-dom';
import {MuiThemeProvider} from '@material-ui/core/styles';
import {withStyles} from "@material-ui/core/styles/index";
import AppAdminCss from "./css/AppAdminCss";
import TopBar from './TopBar';
import LeftMenu from "./LeftMenu";
import {redirectLoginDone} from '../../../reducers/authReducer';
import {theme} from './css/theme';
import {classesNames, hasRightsForRoute} from '../../common/Tools/Tools';

import {Route} from 'react-router';
import Error404 from '../../common/ErrorsPages/Error404';
import Error403 from '../../common/ErrorsPages/Error403';
import {routes} from './routes/routes';
import RouterConfig from '../../../router/config';

class AppAdmin extends Component {
  state = {
    goToLogin: false,
    leftMenuOpen: false,
  };

  /**
   * Retourne le nouveau state en fonction des nextProps
   * @param  {[type]} nextProps [description]
   * @param  {[type]} prevState [description]
   * @return {[type]}           [description]
   */
  static getDerivedStateFromProps(nextProps, prevState) {
    if (nextProps.goToLogin) {
      if (nextProps.location[RouterConfig.pathname] !== RouterConfig.prefixRoutes + "/auth") nextProps.history.push("/auth");
      redirectLoginDone(nextProps.dispatch)
    }

    return null;
  }

  handleLeftMenuToggle() {
    this.setState({leftMenuOpen: !this.state.leftMenuOpen});
  }

  /**
   * Gestion de la redirection sur le login pour les utilisateurs non authentifiés
   * @param r
   * @param props
   * @returns {*}
   */
  renderComponent(r, props) {
    const {user} = this.props;
    if (r.public || hasRightsForRoute(r, user)) {
      return <r.component {...props} />
    }
    // si l'utilisateur n'a que le droit de prendre des photos, on le redirige directement vers la page de prise de photo
    if (!!user && user.rights[0] === 'admin-prise-de-photo') {
      return <Redirect from={r.path} to="/photo"/>;
    }
    if (!!user && user.rights.indexOf(r.right) === -1) {
      return <Error403/>
    }

    if (!!user && user.rights.indexOf(r.right) === -1) {
      return <Error403/>
    }

    return <Redirect from={r.path} to="/auth"/>;
  }

  getContent() {
    const {classes, currentRouteConfig} = this.props;

    let switchComponent = <Switch>
      {routes.map(r => <Route path={r.path} exact={r.exact} key={r.path} render={this.renderComponent.bind(this, r)}/>)}
      <Route component={Error404}/>
    </Switch>;

    if (currentRouteConfig.noContainer) {
      return (
        <div className={classes.root}>
          {switchComponent}
        </div>
      );
    } else
      return (
        <main
          className={this.state.leftMenuOpen ? classes.content : classes.content + ' ' + classes.contentCollapsedLeftMargin}
        >
          <div className={classes.toolbar}/>
          <Grid
            container
            className={classes.root}
            style={{padding: '24px 24px 0 24px'}}
          >
            {switchComponent}
          </Grid>
        </main>
      );
  }

  /**
   * Fonction de rendu final du composant
   */
  render() {
    const {classes, currentRouteConfig} = this.props;
    return (
      <MuiThemeProvider theme={theme}>
        <div className={classesNames({[classes.root]: true, [classes.height100]: currentRouteConfig.noContainer})}>
          <TopBar leftMenuOpen={this.state.leftMenuOpen}
                  handleLeftMenuToggle={this.handleLeftMenuToggle.bind(this)}
                  noContainer={currentRouteConfig.noContainer}
          />
          <LeftMenu open={this.state.leftMenuOpen}
                    handleLeftMenuToggle={this.handleLeftMenuToggle.bind(this)}
                    noContainer={currentRouteConfig.noContainer}
          />
          {this.getContent()}
        </div>
      </MuiThemeProvider>
    );
  }
}

AppAdmin = connect((store) => {
  return {
    goToLogin: store.auth.goToLogin,
    location: store.router.location,
    user: store.auth.user,
    currentRouteConfig: store.app.currentRouteConfig
  }
})(AppAdmin);

AppAdmin = withStyles(AppAdminCss)(AppAdmin);
AppAdmin = withRouter(AppAdmin);

export default AppAdmin
