export default (theme) => ({
    container: {
        display: "flex",
        flexDirection: "column",
        width: "95vw",
        overflow: "auto",
        padding: '0.5rem',
        maxHeight: '50rem',
    },
    row: {
        display: "flex",
        flexDirection: "row",
    },
    cellule:{
        display: "flex",
        flexDirection: "row",
        justifyContent: "center",
        alignItems: "center",
        border: "0.5px solid black",
        height: "2rem",
        width: "7rem",
        "&:hover" :{
            backgroundColor: "#ccc",
        },
        "&.stockable": {
            cursor: "pointer",
            position: "relative",
        },
    },
    loadingContainer:{
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        height: '100%',
        width: '100%',
        flexDirection: 'column',
        position: 'absolute',
        backgroundColor: 'lightgray',
        opacity: 0.8,
        zIndex: 2,
    },
    wall: {
        position: 'sticky',
    },
    stickyTop: {
        top: 0,
    },
    stickyBottom: {
        bottom: 0,
    },
    stickyLeft: {
        left: 0,
    },
    stickyRight: {
        right: 0,
    },
    tableZone:{
        borderCollapse: "collapse",
        position: "relative",
    },
    selectContainer: {
        width: 'calc(100% - 1em)'
    },
    legendContainer:{
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'center',
        alignItems: 'flex-start',
        textAlign: 'center',
        flexWrap: 'wrap',
        marginBottom: '1rem',
        width: '28rem',
    },
    legend:{
        width: '7rem',
    },
    buttonInfo:{
        position: 'absolute',
        top: '-0.5rem',
        right: '5rem',
        color:'#FFF',
    },
    warning_cell:{
        color: '#b56767',
        position: 'absolute',
        opacity: 0.6,
        left: 0,
        paddingTop: '0.5rem',
    }
});
