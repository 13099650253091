import React, { Component } from "react";

import { Button, DialogContent, DialogTitle, Fab, Grid, IconButton, Link, List, ListItem, ListItemText, MenuItem, Typography } from "@material-ui/core";
import { withStyles } from "@material-ui/core/styles";
import { Add, AddShoppingCart, CheckCircle, Delete, Settings } from "@material-ui/icons";

import TextInput from "../../common/Components/TextInput";
import CollectionCrud from "../../common/Components/CollectionCrud";
import MiseADispositionCss from "./css/MiseADispositionCss";
import {
  collectionActions,
  loadCollectionAttribute,
} from "../../../reducers/collectionsReducer";
import { connect } from "react-redux";
import ArticleSelect from "../../common/Components/ArticleSelect";
import NumberInput from "../../common/Components/NumberInput";
import CheckboxInput from "../../common/Components/CheckboxInput";
import { axiosClient } from "../../../axios";
import Modal from "../../common/Components/Modal";
import { axiosErrorHandler } from "../../../reducers/notificationReducer";
import { buildRoute } from "../../../router/Tools";
import Affaire from "../Affaire";
import DocumentPicker from "../../common/Components/DocumentPicker";
import EmailButton from "../Email";
import moment from "moment";

const LOCALSTORAGE_FILTERS_KEY = "mise-a-disposition-filters";

class MiseADisposition extends Component {
  state = {
    selectedMads: [],
    selectedMadsUuids: [],
    filterRecherche: "",
    filterSelectedStatus: ["disponible", "pre-affecte", "affecte"],
    filterSelectedFamilles: ["all"],
    filterSelectedPriorities: ["all"],
    filterSelectedSitesUuid: ["all"],
    filterSortBy: ["all"],
    openModalSelectedMads: false,
    etablissements: [],
    selectedAffaireUuid: null,
    fetchRequest: null,
    openModalAffaire: false,
    createdDefaultValues: null,
    ccrudOptions: null,
    viewMobile: false,
    localData: [],
    lastScrollPosition: null,
    enableRegisterScroll:true
  };
  componentDidMount() {
    loadCollectionAttribute(
      this.props.dispatch,
      "list",
      "conditionnements",
      this.props.conditionnementsStore
    );
    loadCollectionAttribute(
      this.props.dispatch,
      "list",
      "familles",
      this.props.famillesStore
    );
    collectionActions(this.props.dispatch, "articles", "INDEX", {});

    this.loadFilters();
    this.fetchEtablissements();

    document.addEventListener("scroll", this.handleScroll.bind(this));
  }

  handleScroll() {
    if(this.state.enableRegisterScroll){
      // eslint-disable-next-line
      this.state.lastScrollPosition = window.scrollY;
    }
  }

  scrollToPosition(){
    if(this.state.lastScrollPosition){
      window.scrollTo(0, this.state.lastScrollPosition);
    }
  }

  fetchEtablissements() {
    axiosClient.get("/etablissements?mad=true")
    .then((response) => {
      let filterSelectedSitesUuid = this.state.filterSelectedSitesUuid;
      filterSelectedSitesUuid = filterSelectedSitesUuid.filter((uuid) => response.data.find((e) => e.uuid === uuid));

      if(filterSelectedSitesUuid.length === 0 && response.data.length > 0){
        filterSelectedSitesUuid.push(response.data[0].uuid);
      }

      this.setState({
        etablissements: response.data,
        // filterSelectedSitesUuid: filterSelectedSitesUuid,
      }, () => {
        this.fetchMads();
      });
    });
  }

  saveFilters() {
    const {
      filterSelectedStatus,
      filterSelectedFamilles,
      filterSelectedPriorities,
      filterSelectedSitesUuid,
      filterRecherche,
      viewMobile,
    } = this.state;
    const filters = {
      filterSelectedStatus,
      filterSelectedFamilles,
      filterSelectedPriorities,
      filterSelectedSitesUuid,
      filterRecherche,
      viewMobile,
    };
    localStorage.setItem(
      LOCALSTORAGE_FILTERS_KEY,
      JSON.stringify(filters)
    );
  }

  loadFilters() {
    const filters = JSON.parse(
      localStorage.getItem(LOCALSTORAGE_FILTERS_KEY)
    );
    if (filters) {
      this.setState(filters);
    }
  }

  fetchMads(timeout = 0) {
    const {
      filterSelectedStatus,
      filterSelectedFamilles,
      filterSelectedPriorities,
      filterSelectedSitesUuid,
      filterSortBy,
    } = this.state;
    const params = {
      filterSelectedStatus,
      filterSelectedFamilles,
      filterSelectedPriorities,
      filterSelectedSitesUuid,
      filterSortBy,
    };
    clearTimeout(this.state.fetchRequest);
    // eslint-disable-next-line
    this.state.fetchRequest = setTimeout(() => {
      collectionActions(this.props.dispatch, "mise-a-dispositions", "INDEX_WITHOUT_DISPATCH", {
        params,
      }).then((response) => {
        this.setState({ localData: response.data });
      });
    }, timeout);
  }

  getDeleteModalTitle() {
    return (
      <div>
        <Settings />
        <span>{"Suppression de la MaD"}</span>
      </div>
    );
  }

  getDeleteModalContent() {
    return (
      <Typography>
        {"Êtes-vous sûr de vouloir supprimer cette mise à disposition ?"}
      </Typography>
    );
  }

  getCreateUpdateModalTitle(mad) {
    let title = !!mad
      ? mad.reference
      : "Ajout d'une nouvelle mise à disposition";
    return (
      <div>
        <Settings />
        <span>{title}</span>
      </div>
    );
  }

  getCreateUpdateModalContent(mad, madsStore, onChangeHandler) {
    const { classes } = this.props;

    const { selectedMadsUuids } = this.state;

    const {
      priorite,
      entreprise_uuid,
      etablissement_uuid,
      conditionnement_uuid,
      article_uuid,
      famille_uuid,
      poids_to,
      surface_m2,
      nb_unite,
      complet,
      commentaire_preaffectation,
      commentaire_client,
      commentaire_interne,
      affaire_uuid,
      uuid,
      has_document,
    } = mad;

    return (
      <Grid container>
        <Grid item xs={12}>
          <TextInput
            label="Priorité"
            className={classes.selectContainer}
            margin="normal"
            name="priorite"
            collectionStore={madsStore}
            onChangeHandler={onChangeHandler}
            fullWidth
            select
            value={priorite}
          >
            {this.getPrioritiesOptions()}
          </TextInput>
        </Grid>
        <Grid item xs={12} lg={6}>
          <TextInput
            id="entreprise_uuid"
            label="Entreprise"
            className={classes.selectContainer}
            value={entreprise_uuid}
            margin="normal"
            collectionStore={madsStore}
            name="entreprise_uuid"
            type="autocomplete"
            onChangeHandler={(name, e) => {
              onChangeHandler(name, e);
              this.getSitesFromUuids(e.target.value);
            }}
            autocompleteProps={{
              collectionName: "entreprises",
            }}
            fullWidth
          />
        </Grid>
        <Grid item xs={12} lg={6}>
          <TextInput
            id="etablissement_uuid"
            label="Etablissement"
            className={classes.selectContainer}
            value={etablissement_uuid}
            margin="normal"
            collectionStore={madsStore}
            name="etablissement_uuid"
            onChangeHandler={onChangeHandler}
            fullWidth
            select
          >
            {this.getEtablissements(entreprise_uuid)}
          </TextInput>
        </Grid>
        <Grid item xs={12}>
          <TextInput
            label="Conditionnement"
            value={conditionnement_uuid}
            required={true}
            margin="normal"
            collectionStore={madsStore}
            name="conditionnement_uuid"
            onChangeHandler={onChangeHandler}
            select
            style={{ margin: "0 0 0 5px", width: "calc(99% - 5px)" }}
          >
            {this.getConditionnements()}
          </TextInput>
        </Grid>
        <ArticleSelect
          onChangeHandler={(name, e) => onChangeHandler(name, e)}
          store={madsStore}
          famille_uuid={famille_uuid}
          article_uuid={article_uuid}
          gridFamilleXs={12}
        />
        <Grid item xs={3}>
          <NumberInput
            label="Poids (t)"
            value={poids_to}
            margin="normal"
            collectionStore={madsStore}
            name="poids_to"
            onChangeHandler={onChangeHandler}
            fullWidth
          />
        </Grid>
        <Grid item xs={3} style={{
          display: "flex",
          alignItems: "end",
          justifyContent: "center",
        }}>
          <CheckboxInput
            label="Complet"
            value={complet}
            margin="normal"
            name="complet"
            collectionStore={madsStore}
            onChangeHandler={onChangeHandler}
          />
        </Grid>
        <Grid item xs={3}>
          <NumberInput
            label="Nombre d'unités"
            value={nb_unite}
            margin="normal"
            collectionStore={madsStore}
            name="nb_unite"
            onChangeHandler={onChangeHandler}
            fullWidth
          />
        </Grid>
        <Grid item xs={3}>
          <NumberInput
            label="Surface (m sol)"
            value={surface_m2}
            margin="normal"
            collectionStore={madsStore}
            name="surface_m2"
            onChangeHandler={onChangeHandler}
            fullWidth
          />
        </Grid>
        <Grid item xs={12}>
          <TextInput
            label="Commentaire Préaffectation"
            value={commentaire_preaffectation}
            margin="normal"
            collectionStore={madsStore}
            name="commentaire_preaffectation"
            onChangeHandler={onChangeHandler}
            fullWidth
          />
        </Grid>
        <Grid item xs={3} style={{
              display: "flex",
              alignItems: "center",
            }}
        >
          {this.getBtnNewAffaire()}
          <TextInput
            label="Affaire"
            value={affaire_uuid}
            type="autocomplete"
            autocompleteProps={{
              collectionName: "affaires",
            }}
            margin="normal"
            collectionStore={madsStore}
            name="affaire_uuid"
            clearConfirmCallback={() => {
              if(!affaire_uuid) return true;
              return this.confirm("Êtes-vous sûr de vouloir supprimer le lien de l'affaire avec cette MAD ? Ceci supprimera les lots provenant des cette MAD")
            }}
            onChangeHandler={onChangeHandler}
            clearable={true}
            fullWidth
          />
        </Grid>
        <Grid item lg={1}>
          <DocumentPicker
            documentable_uuid={uuid}
            documentable_type="App\Models\MiseADisposition"
            hasDocument={has_document}
          />
        </Grid>
        <Grid item lg={8}>
          <EmailButton
              className={classes.emailButton}
              label={"Email Mise à disposition"}
              onSent={() => {}}
              asynchrone={true}
              type="mad"
              asynchroByType
              asynchroByTypeParams={{
                mads_uuid: selectedMadsUuids,
              }}
              required=""
            />
        </Grid>
        <Grid item xs={12}>
          <label htmlFor="commentaire_client" style={{ marginLeft:'5px' }}>Commentaire client</label>
          <textarea
            id="commentaire_client"
            onChange={(e) => onChangeHandler("commentaire_client", e)}
            rows="6"
            style={{ marginLeft:'5px', width: "99%" }}
            placeholder="Commentaire client"
            value={commentaire_client}
          >
          </textarea>
        </Grid>
        <Grid item xs={12}>
          <label htmlFor="commentaire_interne" style={{ marginLeft:'5px' }}>Commentaire interne</label>
          <textarea
            id="commentaire_interne"
            onChange={(e) => onChangeHandler("commentaire_interne", e)}
            rows="6"
            style={{ marginLeft:'5px', width: "99%" }}
            placeholder="Commentaire interne"
            value={commentaire_interne}
          >
          </textarea>
        </Grid>
      </Grid>
    );
  }

  getSitesFromUuids(entreprise_uuid) {
    const entreprise_uuids = [entreprise_uuid];
    collectionActions(this.props.dispatch, "etablissements", "INDEX", {
      params: { entreprise_uuids },
    });
  }

  getEtablissements(entreprise_uuid) {
    if (!entreprise_uuid) return [];
    if (!this.props.etablissementsStore.list) {
      return [];
    }
    // Fait une 'shallow copy' pour ne pas modifier 'this.props'
    let etablissements = this.props.etablissementsStore.list.slice();
    if (entreprise_uuid) {
      etablissements = etablissements.filter(
        (e) => e.entreprise_uuid === entreprise_uuid
      );
    }
    return etablissements.map((etablissement) => {
      return (
        <MenuItem value={etablissement.uuid} key={etablissement.uuid}>
          {etablissement.nom}
        </MenuItem>
      );
    });
  }

  getConditionnements() {
    if (!this.props.conditionnementsStore.list) {
      return [];
    }
    return this.props.conditionnementsStore.list.map((conditionnement) => {
      return (
        <MenuItem value={conditionnement.uuid} key={conditionnement.uuid}>
          {conditionnement.nom}
        </MenuItem>
      );
    });
  }

  getPrioritiesOptions(withAll = false) {
    let priorities = [
      { value: "all", label: "Toutes" },
      { value: "p1", label: "P1" },
      { value: "p2", label: "P2" },
      { value: "p3", label: "P3" },
    ];

    if (!withAll) {
      priorities = priorities.filter((priority) => priority.value !== "all");
    }


    return priorities.map((priority) => (
      <MenuItem key={priority.value} value={priority.value}>
        {priority.label}
      </MenuItem>
    ));
  }

  renderDataCustomCallback(data, options) {
    const { ccrudOptions } = this.state;
    if(!ccrudOptions) {
      this.setState({ ccrudOptions: options });
    }

    return this.listing(data, options)
  }

  listing(data, options) {
    const { classes } = this.props;
    return (
      <Grid container className={classes.madContainer}>
        {data.map((mad) => this.displayMad(mad, options))}
      </Grid>
    );
  }

  getPriorityDiv(priority) {
    const { classes } = this.props;
    const label = priority.toUpperCase();
    let color = null;
    switch (priority) {
      case "p1":
        color = "#ff0000";
        break;
      case "p2":
        color = "#ff9900";
        break;
      case "p3":
        color = "#ffff00";
        break;
      default:
        color = null;
    }
    return (
      <div
        className={classes.headerDivInfo}
        style={{
          backgroundColor: color,
          marginLeft: "0.1rem",
        }}
      >
        {label}
      </div>
    );
  }

  getToDiv(to) {
    const { classes } = this.props;
    return <div className={classes.headerDivInfo}>{to} To</div>;
  }

  getSuperficieDiv(superficie) {
    const { classes } = this.props;
    return <div className={classes.headerDivInfo}>{superficie} M</div>;
  }

  getNbunitsDiv(nb) {
    const { classes } = this.props;
    return <div className={classes.headerDivInfo}>{nb}</div>;
  }

  getCommentaire(commentaire, length = 50) {
    if (!commentaire) return "";
    if (commentaire.length > length) {
      return commentaire.substring(0, length) + "...";
    }
    return commentaire;
  }

  getSelectCheckbox(mad, in_table = false) {
    const { selectedMadsUuids } = this.state;
    const { classes } = this.props;

    if(['vendu','affecte'].includes(mad.statut)){
      return (
        <div className={classes.selectCheckbox}>
        </div>
      );
    }
    return (
      <div className={in_table ? '' : classes.selectCheckbox }>
        <CheckboxInput
          label=""
          value={selectedMadsUuids.includes(mad.uuid)}
          margin="normal"
          onClick={(e) => {
            e.stopPropagation();
          }}
          onChangeHandler={(name, e) => {
            let selectedMadsUuids = this.state.selectedMadsUuids;
            let selectedMads = this.state.selectedMads;
            if (e.target.value) {
              if(selectedMadsUuids.includes(mad.uuid)) return;
              selectedMadsUuids.push(mad.uuid);
              selectedMads.push(mad);
            } else {
              selectedMadsUuids = selectedMadsUuids.filter(
                (list_uuid) => list_uuid !== mad.uuid
              );
              selectedMads = selectedMads.filter( (list_mad) => list_mad.uuid !== mad.uuid);
            }
            this.setState({ selectedMadsUuids: selectedMadsUuids, selectedMads: selectedMads });
          }}
        />
      </div>
    );
  }

  getHeader(mad) {
    const { classes } = this.props;
    return (
      <div className={classes.header + " " + classes.marginBottom}>
        {this.getStatutDiv(mad.statut)}
        {this.getPriorityDiv(mad.priorite)}
        {this.getToDiv(mad.poids_to)}
        {this.getSuperficieDiv(mad.surface_m2)}
        {this.getNbunitsDiv(mad.nb_unite)}
      </div>
    );
  }

  getStatutDiv(statut) {
    if(!statut) return null;
    let color = null;
    switch (statut) {
      case "disponible":
        color = "rgb(15 143 15)";
        break;
      case "pre-affecte":
        color = "rgb(239 129 34)";
        break;
      case "affecte":
        color = "rgb(15 113 143)";
        break;
      case "vendu":
        color = "rgb(213 15 15)";
        break;
      default:
        color = null;
    }
    const label = statut.split("-").map((word) => word[0].toUpperCase()).join("-");
    const { classes } = this.props;
    return (
      <div
        className={classes.headerDivInfo}
        style={{
          backgroundColor: color,
        }}
        title={statut}
      >
        {label}
      </div>
    );
  }

  getComplet(complet) {
    if (!complet) return null;
    const { classes } = this.props;
    return (
      <div className={classes.complete}>
        <CheckCircle title="Mad Complète" />
      </div>
    );
  }

  getImagesPreview(images) {
    const { classes } = this.props;
    return (
      <div className={classes.preview_image_container}>
        {images.map((image, index) => (
          <img
            key={index}
            className={classes.preview_image}
            src={image.url}
            alt={image.filename}
          />
        ))}
      </div>
    )
  }

  displayMad(mad, options) {
    const { onClickEditHandler } = options;
    const { classes } = this.props;

    return (
      <div
        key={mad.uuid}
        className={classes.madCard}
        onClick={() => {
          this.setState({
            enableRegisterScroll: false,
          })
          onClickEditHandler(mad);
        }}
      >
        <div className={classes.infos}
          style={{
            height: "100%",
          }}
        >
          <div className={classes.reference + " " + classes.marginBottom}>
            {this.getSelectCheckbox(mad)}
            <span>{mad.reference}</span>
            {this.getComplet(mad.complet)}
          </div>
          <div className={classes.body}>
            <div className={classes.article + " " + classes.marginBottom}>
              {mad.conditionnement_libelle} / {mad.article_libelle}
            </div>
            <hr />
            {this.getHeader(mad)}
            <hr />
            {this.getAffaireReference(mad)}
            {this.getCommentairePart('Commentaire Préaffectation', mad.commentaire_preaffectation)}
            {this.getCommentairePart('Commentaire Client', mad.commentaire_client)}
            {this.getCommentairePart('Commentaire Interne', mad.commentaire_interne)}
            {this.getImagesPreview(mad.firsts_images)}
            <div className={classes.photoIconContainer}>
              <DocumentPicker
                documentable_uuid={mad.uuid}
                documentable_type="App\Models\MiseADisposition"
                hasDocument={mad.has_document}
              />
            </div>
          </div>
        </div>
      </div>
    );
  }

  getAffaireReference(mad) {
    if(!mad.affaire_uuid) return null;
    return (
      <>

        Affaire : <Link
                    href={buildRoute("/affaires/:uuid", { uuid: mad.affaire_uuid })}
                    target="_blank"
                    onClick={(e) => {e.stopPropagation(); }}
                  >
                    {mad.affaire_reference_interne}
                  </Link>
        <br />
        Client : <Link
                    href={buildRoute("/entreprises/:uuid", { uuid: mad.client_uuid })}
                    target="_blank"
                    onClick={(e) => {e.stopPropagation(); }}
                  >
                    {mad.client_libelle}
                  </Link>

        <hr />
      </>
    )
  }

  getCommentairePart(title, commentaire, length = 50) {
    if (!commentaire) return null;
    const { classes } = this.props;
    return (
      <div className={classes.commentaireContainer}>
        <div>{title}</div>
        <div>{this.getCommentaire(commentaire, length)}</div>
      </div>
    );
  }

  getSiteOptions(){
    const { etablissements } = this.state;

    return [
      { uuid: "all", libelle: "Tous"}
    ].concat(etablissements).map((etablissement) => (
      <MenuItem key={etablissement.uuid} value={etablissement.uuid}>
        {etablissement.libelle}
      </MenuItem>
    ));
  }

  getFilterByOptions(){
    const options = [
      { value: "all", label: "Aucun tri"},
      { value: "familles.libelle_fr", label: "Familles" },
      { value: "articles.libelle_fr", label: "Articles" },
    ];

    return options.map((option) => (
      <MenuItem key={option.value} value={option.value}>
        {option.label}
      </MenuItem>
    ));
  }

  header() {
    const { ccrudOptions } = this.state;

    const { onClickAddHandler } = ccrudOptions || {};
    const {
      filterSelectedStatus,
      filterSelectedFamilles,
      filterSelectedPriorities,
      filterSelectedSitesUuid,
      filterSortBy,
    } = this.state;
    const { classes, madsStore } = this.props;
    return (
      <Grid container >
        <Grid item lg={3} xs={12}>
          <TextInput
            label="Site"
            className={classes.selectContainer}
            margin="normal"
            name="filterSelectedSitesUuid"
            collectionStore={madsStore}
            onChangeHandler={this.onChangeFilterHandler.bind(this)}
            inputProps={{
              multiple: true,
              value: filterSelectedSitesUuid,
            }}
            fullWidth
            select
          >
            {this.getSiteOptions()}
          </TextInput>
        </Grid>
        <Grid item lg={1} xs={12}>
          <TextInput
            label="Status"
            className={classes.selectContainer}
            margin="normal"
            name="filterSelectedStatus"
            collectionStore={madsStore}
            onChangeHandler={this.onChangeFilterHandler.bind(this)}
            fullWidth
            inputProps={{
              multiple: true,
              value: filterSelectedStatus,
            }}
            select
          >
            {this.getStatusOptions()}
          </TextInput>
        </Grid>
        <Grid item lg={1} xs={12}>
          <TextInput
            label="Priorité"
            className={classes.selectContainer}
            margin="normal"
            name="filterSelectedPriorities"
            collectionStore={madsStore}
            onChangeHandler={this.onChangeFilterHandler.bind(this)}
            fullWidth
            inputProps={{
              multiple: true,
              value: filterSelectedPriorities,
            }}
            select
          >
            {this.getPrioritiesOptions(true)}
          </TextInput>
        </Grid>
        <Grid item lg={2} xs={12}>
          <TextInput
            label="Familles"
            className={classes.selectContainer}
            margin="normal"
            name="filterSelectedFamilles"
            collectionStore={madsStore}
            onChangeHandler={this.onChangeFilterHandler.bind(this)}
            fullWidth
            inputProps={{
              multiple: true,
              value: filterSelectedFamilles,
            }}
            select
          >
            {this.getFamillesOptions()}
          </TextInput>
        </Grid>
        <Grid item lg={2} xs={12}>
          <TextInput
            label="Trier par"
            className={classes.selectContainer}
            margin="normal"
            name="filterSortBy"
            collectionStore={madsStore}
            onChangeHandler={this.onChangeFilterHandler.bind(this)}
            fullWidth
            inputProps={{
              multiple: true,
              value: filterSortBy,
            }}
            select
          >
            {this.getFilterByOptions()}
          </TextInput>
        </Grid>
        {this.getFilterRecherche()}
        <Grid item lg={3} xs={12}>
         {this.getAddBtn(onClickAddHandler)}
        </Grid>
      </Grid>
    );
  }

  getFilterRecherche(){
    const { classes, madsStore } = this.props;
    const { filterRecherche, viewMobile } = this.state;
    if(!viewMobile) return null;
    return (
      <Grid item lg={2} xs={12}>
        <TextInput
          label="Recherche"
          className={classes.selectContainer}
          margin="normal"
          name="filterRecherche"
          value={filterRecherche}
          collectionStore={madsStore}
          onChangeHandler={(name, e) => {
            this.setState({ [name]: e.target.value },() => {
              this.saveFilters();
            });
          }}
          fullWidth
        />
      </Grid>
    )
  }

  getAddBtn(onClickAddHandler) {
    if(!onClickAddHandler) return null;
    const { classes } = this.props;
    return (
      <div className={classes.contentCenter}>
      <Fab
        color="primary"
        size="small"
        aria-label="Add"
        onClick={onClickAddHandler}
        className={classes.addButton}
      >
        <Add />
      </Fab>
    </div>
    )
  }

  onChangeFilterHandler(name, e) {
    let values = e.target.value;

    if (values.length > 0) {
      if (this.state[name].indexOf("all") !== -1) {
        values = values.filter((value) => value !== "all");
      } else if (values.indexOf("all") !== -1) {
        values = ["all"];
      }
    } else {
      values = ["all"];
    }

    this.setState({ [name]: values },() =>{
      this.fetchMads();
      this.saveFilters();
    });
  }

  getFamillesOptions(){
    const { famillesStore } = this.props;
    const familles = famillesStore.list || [];

    return [
      { uuid: "all", nom: "Toutes"}
    ].concat(familles).map((famille) => (
      <MenuItem key={famille.uuid} value={famille.uuid}>
        {famille.nom}
      </MenuItem>
    ));
  }

  getStatusOptions(){
    const status = [
      { value: "all", label: "Tous"},
      { value: "disponible", label: "Disponible" },
      { value: "pre-affecte", label: "Pré Affecté" },
      { value: "affecte", label: "Affecté" },
      { value: "vendu", label: "Vendu" },
    ];

    return status.map((status) => (
      <MenuItem key={status.value} value={status.value}>
        {status.label}
      </MenuItem>
    ));
  }

  actionsCallback(action, data) {
    switch (action) {
      case "delete":
      case "create":
      case "update":
        this.fetchEtablissements();
        break;
      case "click-edit":
        this.getSitesFromUuids(data.entreprise_uuid)
        break;
      case "close":
        this.scrollToPosition();
        this.setState({
          enableRegisterScroll: true,
        });
        break;
      default:
        break;
    }
  }

  getSelectedMads(){
    const { selectedMads } = this.state;
    const { classes } = this.props;
    if(selectedMads.length === 0) return null;
    return (
      <div className={classes.selectedMads}>
        <Button
          variant="contained"
          color="primary"
          onClick={() => {
            this.setState({ openModalSelectedMads: true });
          }}
        >
          {"Associer les Mads"}
        </Button>
        {selectedMads.map((mad) => this.selectedMad(mad)) }
      </div>
    );
  }

  selectedMad(mad){
    const { classes } = this.props;
    return (
      <div key={mad.uuid} className={classes.madSelected}>
        <span>{mad.reference}</span>
        <IconButton
          className={classes.deleteBtnMadSelected}
          onClick={(e) => {
            e.stopPropagation();
            let selectedMadsUuids = this.state.selectedMadsUuids;
            let selectedMads = this.state.selectedMads;
            selectedMadsUuids = selectedMadsUuids.filter(
              (list_uuid) => list_uuid !== mad.uuid
            );
            selectedMads = selectedMads.filter( (list_mad) => list_mad.uuid !== mad.uuid);
            this.setState({ selectedMadsUuids: selectedMadsUuids, selectedMads: selectedMads });
          }}
        >
          <Delete/>
        </IconButton>
      </div>
    );
  }
  onSubmitModalSelectedMads(){
    const { selectedMadsUuids, selectedAffaireUuid } = this.state;
    axiosClient.post("/mise-a-dispositions/attach-affaire", {
      mads_uuids: selectedMadsUuids,
      affaire_uuid: selectedAffaireUuid,
    }).then((res) => {
      const affaire = res.data;
      this.props.history.push(
        buildRoute("/affaires/:uuid", { uuid: affaire.uuid })
      );
    }).catch((error) => {
      axiosErrorHandler(this.props.dispatch, error);
    });
  }

  getBtnNewAffaire(){
    const { selectedMads } = this.state;
    return (
      <Fab
        color="primary"
        size="small"
        aria-label="Add"
        style={{
          marginLeft: '1em',
          marginTop: '0.5em',
          marginRight: '0.5em',
          color: '#FFFFFF'
        }}
        onClick={() => {
          if(this.confirm("Êtes-vous sûr de vouloir créer une nouvelle affaire pour la rattacher aux Mads Sélectionnées ?")){
            this.setState({
              openModalAffaire: true,
                createdDefaultValues: {
                  fournisseur_uuid: selectedMads[0].entreprise_uuid,
                  lieu_enlevement_uuid: selectedMads[0].etablissement_uuid,
                  poids_estime: selectedMads.reduce((acc, mad) =>  acc + parseFloat(mad.poids_to), 0),
                }
              });
          }
        }}
      >
        <Add />
      </Fab>
    )
  }

  confirm(message){
    return window.confirm(message);
  }

  getModalSelectedMadContent(){
    const { classes, madsStore } = this.props;
    const { selectedAffaireUuid, selectedMads, selectedMadsUuids } = this.state;
    return [
      <DialogTitle key="title">
        <Typography
          style={{
            display: "flex",
            alignItems: "center",
            flexDirection: "row",
          }}
        >
          <AddShoppingCart />
          <span>
            Associer les Mads a une affaire
          </span>
        </Typography>
      </DialogTitle>,
       <DialogContent key="content">
        <Grid container>
          <Grid item xs={12}>
            Associer les mads suivant a une affaire
            <List>
              {selectedMads.map((mad) => (
                <ListItem key={mad.uuid}>
                  <ListItemText primary={mad.reference} />
                </ListItem>
              ))}
            </List>
          </Grid>
          <Grid container>
            <Grid item xs={12} lg={6} style={{
              display: "flex",
              alignItems: "center",
            }}>
              {this.getBtnNewAffaire()}
              <TextInput
                id="affaire_uuid"
                label="Affaire"
                className={classes.selectContainer}
                value={selectedAffaireUuid}
                margin="normal"
                collectionStore={madsStore}
                name="affaire_uuid"
                onChangeHandler={(name, e) => {
                  this.setState({ selectedAffaireUuid: e.target.value });
                }}
                type="autocomplete"
                autocompleteProps={{
                  collectionName: "affaires",
                }}
                fullWidth
                clearable
              />
            </Grid>
            <Grid item xs={12} lg={6}>
              <EmailButton
                  className={classes.emailButton}
                  label={"Email Mise à disposition"}
                  onSent={() => {}}
                  asynchrone={true}
                  type="mad"
                  asynchroByType
                  asynchroByTypeParams={{
                    mads_uuid: selectedMadsUuids,
                  }}
                  required=""
                />
            </Grid>
          </Grid>
        </Grid>
       </DialogContent>
    ]
  }

  overrideAffaireActionCallback(action, affaire){
    if(action === 'create'){
      this.setState({selectedAffaireUuid: affaire.uuid},() => {
        this.onSubmitModalSelectedMads();
      });
    }
    if(action === 'close'){
      this.setState({ openModalAffaire: false });
    }
  }

  getCommentaireTd(commentaire, length = 20){
    if(!commentaire) return null;
    return <span
      title={commentaire}
      style={{
        cursor: "help",
      }}
    >
      {commentaire.substring(0, length)}{commentaire.length > length ? "..." : ""}
    </span>;
  }

  getCellsConfig(){
    return [
      {
        datakey: "reference",
        title: "Référence",
        sortable: true,
        searchable: true,
      },
      {
        datakey: "statut_libelle",
        title: "Statut",
        sortable: true,
        searchable: true,
      },
      {
        datakey: "etablissement_libelle",
        title: "Site stockage",
        sortable: true,
        searchable: true,
      },
      {
        datakey: "conditionnement_libelle",
        title: "Conditionnement",
        sortable: true,
        searchable: true,
      },
      {
        datakey: "article_libelle",
        title: "Article",
        sortable: true,
        searchable: true,
      },
      {
        datakey: "poids_to",
        title: "To",
        sortable: true,
        searchable: true,
      },
      {
        datakey: "complet",
        title: "Complet",
        sortable: true,
        searchable: true,
        format(data,key){
          return data.complet ? "Oui" : "Non";
        }
      },
      {
        datakey: "nb_unite",
        title: "Nb unités",
        sortable: true,
        searchable: true,
      },
      {
        datakey: "surface_m2",
        title: "M Plancher",
        sortable: true,
        searchable: true,
      },
      {
        datakey: "fake",
        title: "Selection",
        sortable: true,
        searchable: true,
        format: (data,key) =>  this.getSelectCheckbox(data, true),
      },
      {
        datakey: "commentaire_preaffectation",
        title: "Préaffectation",
        sortable: true,
        searchable: true,
        format:(data,key) =>{
          return this.getCommentaireTd(data[key], 20)
        }
      },
      {
        datakey: "client_libelle",
        title: "Client",
        sortable: true,
        searchable: true,
        format(data,key){
          if(!data.affaire_uuid) return null;
          return (
            <Link
              href={buildRoute("/entreprises/:uuid", { uuid: data.client_uuid })}
              target="_blank"
              onClick={(e) => {e.stopPropagation(); }}
            >
              {data.client_libelle}
            </Link>
          )
        }
      },
      {
        datakey: "affaire_reference_interne",
        title: "Affaire",
        sortable: true,
        searchable: true,
        useDataKeyOnSearch: true,
        format(data,key){
          if(!data.affaire_uuid) return null;
          return (
            <Link
              href={buildRoute("/affaires/:uuid", { uuid: data.affaire_uuid })}
              target="_blank"
              onClick={(e) => {e.stopPropagation(); }}
            >
              {data.affaire_reference_interne}
            </Link>
          )
        }
      },
      {
        datakey: "date_enlevement_affaire",
        title: "Date enlevement",
        sortable: true,
        searchable: true,
        format(data,key){
          if(!data.affaire_uuid) return null;
          return moment(data.date_enlevement_affaire).format("DD/MM/YYYY");
        }
      },
      {
        datakey: "commentaire_client",
        title: "Description Client",
        sortable: true,
        searchable: true,
        format:(data,key) =>{
          return this.getCommentaireTd(data[key], 20)
        }
      },
      {
        datakey: "commentaire_interne",
        title: "Commentaire Interne",
        sortable: true,
        searchable: true,
        format:(data,key) =>{
          return this.getCommentaireTd(data[key], 20)
        }
      },
    ]
  }

  getModeView(){
    return (
      <Grid container>
        <Grid item xs={3}>
          <CheckboxInput
            label="Vue mobile"
a            value={this.state.viewMobile}
            onChangeHandler={(name, e) => {
              this.setState({ viewMobile: e.target.value, ccrudOptions: null },() => {
                this.saveFilters();
              });
            }}
            fullWidth
          />
        </Grid>
      </Grid>
    )
  }

  getExportBtn() {
    return (
      <Grid container>
        <Grid item xs={3}>
          <Button
            title={"Export des Mad"}
            variant="contained"
            color="primary"
            href={process.env.REACT_APP_API_URL + "/mise-a-dispositions/export"}
          >
            <AddShoppingCart />
            Export
          </Button>
        </Grid>
      </Grid>
    );
  }

  filterLocalData(){
    const { filterRecherche, viewMobile } = this.state;
    const { localData } = this.state;
    if(!filterRecherche || !viewMobile) return localData;
    return localData.filter((mad) => {
      const keys = Object.keys(mad);
      return keys.some((key) => {
        if(typeof mad[key] === 'string'){
          return mad[key].toLowerCase().includes(filterRecherche.toLowerCase());
        }
        return false;
      });
    });
  }

  getDocumentBtn(mad){
    const { classes } = this.props;
    return (
      <DocumentPicker
        documentable_uuid={mad.uuid}
        documentable_type="App\Models\MiseADisposition"
        className={classes.documentBtn}
        hasDocument={mad.has_document}
      />
    )
  }

  render() {
    const { classes } = this.props;
    const { openModalSelectedMads, openModalAffaire, createdDefaultValues, viewMobile } = this.state;
    return (
      <Grid container className={classes.container}>
        {this.getModeView()}
        {this.getExportBtn()}
        {this.header()}
        <CollectionCrud
          collectionName={"mise-a-dispositions"}
          loadDatas={false}
          datas={this.filterLocalData()}
          showBtnEdit={true}
          showBtnAdd={true}
          showBtnDelete={true}
          fullScreenModal={true}
          cancelUpdateCheck={true}
          rights={{
            create: "admin-crud-mises-a-disposition",
            edit: "admin-crud-mises-a-disposition",
            delete: "admin-crud-mises-a-disposition",
          }}
          deleteModalTitle={this.getDeleteModalTitle}
          deleteModalContent={this.getDeleteModalContent}
          createUpdateModalTitle={this.getCreateUpdateModalTitle}
          createUpdateModalContent={this.getCreateUpdateModalContent.bind(this)}
          renderDataCustomCallback={viewMobile ? this.renderDataCustomCallback.bind(this) : null}
          defaultValues={{
            priorite: "p1",
            complet: false,
          }}
          modalContentStyle={{padding: "0"}}
          actionsCallback={this.actionsCallback.bind(this)}
          cellsConfig={this.getCellsConfig()}
          datatableConfig={{
            showPagination: true,
            showSearch: true,
            defaultSort: "reference",
            sortType: "desc",
            nodatalabel: "Aucune Mad",
            rowsPerPageOptions: [25, 50],
          }}
          additionnalControllers={[this.getDocumentBtn.bind(this)]}
          disabledEnterModal={true}
          persistDatatableOptions={{
            id: "mad_list",
            exipiration_minutes: 60 * 12, // 12 Heures
        }}
        />
        {this.getSelectedMads()}
        <Modal
          openModal={openModalSelectedMads}
          onCloseHandler={() => this.setState({ openModalSelectedMads: false, createdDefaultValues: null})}
          onSubmitHandler={this.onSubmitModalSelectedMads.bind(this)}
          children={this.getModalSelectedMadContent()}
        />
        <Affaire
          modalOnly={true}
          modalOpen={openModalAffaire}
          facturation_cells={[]} // to hide tabs
          createdDefaultValues={createdDefaultValues}
          overrideAffaireActionCallback={this.overrideAffaireActionCallback.bind(this)}
          createUpdateModalSubmit={"Valider"}
        />
      </Grid>
    );
  }
}

MiseADisposition = withStyles(MiseADispositionCss)(MiseADisposition);

MiseADisposition = connect((store) => {
  return {
    etablissementsStore: store.collections.etablissements,
    conditionnementsStore: store.collections.conditionnements,
    famillesStore: store.collections.familles,
    articlesStore: store.collections.articles,
    madsStore: store.collections["mise-a-dispositions"],
    user: store.auth.user,
  };
})(MiseADisposition);

export default MiseADisposition;
