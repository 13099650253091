import {themeComplement} from '../../AppAdmin/css/theme'

export default theme => ({
  root: {
    flexGrow: 1
  },
  crudTitle: {
    float: 'left',
    marginBottom: 0,
    padding: '8px 0',
    color: theme.palette.primary.main,
  },
  title: {
    marginBottom: 0,
    padding: '.5em',
    color: theme.palette.primary.main,
    display: 'block',
  },

  button: {
    float: 'right'
  },
  addButton: {
    marginLeft: '2em',
    marginTop: '0.5em',
    marginRight: '0.5em'
  },
  addButtonIcon: {
    fontSize: '32px'
  },
  error: {
    color: theme.palette.error.main
  },
  divider: {
    marginTop: '1em',
    marginBottom: '1em',
  },
  produitsContainer: {
    marginRight: '.5em',
    maxWidth: 'calc(83.333333% - .5em)'
  },
  produits: {
    borderRadius: '5px',
  },
  selected: {
    borderLeft: '1px solid rgba(224, 224, 224, 1)',
    padding: '0 .5em',
    position: 'relative',
  },
  infos: {
    position: 'absolute',
    top: '1.2em',
    right: '.8em',
    color: 'rgba(0, 0, 0, 0.54)',
    fontSize: '.7rem',
    fontFamily: '"Roboto", "Helvetica", "Arial", sans-serif',
    fontWeight: '400',
    letterSpacing: '.00938em',
  },
  cardContainer: {
    width: 'calc(8.333333333% - 1em)',
    padding: '.5em',
  },
  cardDisabled: {
    '&:hover': {
      cursor: 'not-allowed',
    },
  },
  cardHover: {
    '&:hover': {
      border: '2px solid ' + theme.palette.primary.main + '!important',
      cursor: 'pointer',
      transform: 'scale(1.15)',
      boxShadow: '0 20px 30px -16px rgba(9,9,16,.2)',
    },
  },
  card: {
    border: '2px solid white',
    position: 'relative',
    textAlign: 'center',
    transition: 'all 0.1s ease-out',
    boxShadow: '0px 2px 1px -1px rgba(0,0,0,0.2), 0px 1px 1px 0px rgba(0,0,0,0.14), 0px 1px 3px 0px rgba(0,0,0,0.12)',
    borderRadius: '4px',
    color: 'rgba(0, 0, 0, 0.87)',
    backgroundColor: '#fff',
    fontSize: '.875rem',
    fontFamily: '"Roboto", "Helvetica", "Arial", sans-serif',
    fontWeight: '400',
    lineHeight: '1.43',
    letterSpacing: '.01071em',
    padding: '.5em 2px',
    overflow: 'hidden',
  },
  selectorContainer: {
    overflowY: 'auto',
    maxHeight: 'calc(100vh - 62.59px - 24px - 72px - 1em - 1px - 1em - 72px - 38px - 24px - 52px)',
  },
  selectionItemContainer: {
    overflowY: 'auto',
    maxHeight: 'calc(100vh - 62.59px - 24px - 1em - 1px - 1em - 72px - 24px - 52px - 38px)',
  },
  selectionItemDisabled: {
    '&:hover': {
      cursor: 'not-allowed',
    },
  },
  selectionItemHover: {
    '&:hover': {
      border: '1px solid ' + themeComplement.palette.notifications.error.color,
      cursor: 'pointer',
      '& $selectionItemIcon': {
        opacity: 1,
      }
    },
  },
  selectionItem: {
    border: '1px solid rgba(224, 224, 224, 1)',
    padding: '2px 15px',
    borderRadius: '4px',
    fontSize: '.7rem',
    minWidth: '64px',
    color: 'rgba(0, 0, 0, 0.87)',
    fontFamily: '"Roboto", "Helvetica", "Arial", sans-serif',
    fontWeight: 500,
    lineHeight: 1.75,
    letterSpacing: '.02857em',
    marginBottom: '.5em',
    position: 'relative',
  },
  selectionItemTitleRef: {
    fontWeight: 'bold',
  },
  selectionItemIcon: {
    position: 'absolute',
    right: '.5em',
    top: '.15em',
    fontSize: '1.5em',
    lineHeight: 1,
    opacity: 0,
    color: themeComplement.palette.notifications.error.color,
    backgroundColor: 'white',
    height: 'calc(100% - .4em)',
    padding: '0 .5em',
  },
  warning: {
    top: '-9px',
    color: '#ff00001c',
    width: '2.5em',
    height: '2.5em',
    position: 'absolute',
    right: '-11px',
  },
  link: {
    fontSize: '.875rem',
    textAlign: 'left',
    fontFamily: '"Roboto", "Helvetica", "Arial", sans-serif',
    fontWeight: '400',
    lineHeight: '1.43',
    cursor: 'pointer',
    color: theme.palette.primary.main,
    '&:hover': {
      textDecoration: 'underline',
    }
  },
  packinglist: {
    backgroundColor: '#C5EBFF',
    border: '2px solid #C5EBFF',
  },
  packinglist_disponible: {
    backgroundColor: '#6d40f7ad',
    border: '2px solid #6d40f7ad',
  },
  silolist: {
    backgroundColor: '#b6e4c8',
    border: '2px solid #b6e4c8',
  },
  overloaded: {
    color: themeComplement.palette.notifications.warning.color,
  },
  selection_container: {
    display: 'flex',
  },
  center: {
    textAlign: 'center',
    width: '100%',
  },
  noMargin: {
    margin: 0,
  },
  buttonAdd: {
    color: 'white',
  },
  selectContainer: {
    width: 'calc(100% - 1em)'
  },
  multiSelectContainer:{
    marginTop: "16px",
    marginBottom: "8px",
  },
  massImpression: {
    padding: 0,
    marginLeft: '10px',
  },
  btnContainer: {
    display: 'flex',
    justifyContent: 'space-between',
  },
  filterStatusLabel:{
    transition: "color 200ms cubic-bezier(0.0, 0, 0.2, 1) 0ms,transform 200ms cubic-bezier(0.0, 0, 0.2, 1) 0ms",
    transform: "translate(0, 1.5px) scale(0.75)",
    transformOrigin: "top left",
  }

})
