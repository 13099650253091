import React, { Component } from "react";
import { withStyles } from "@material-ui/core/styles";
import HomeCss from "./css/HomeCss";
import { connect } from "react-redux";
import { CircularProgress, Grid, Table, TableBody, TableCell, TableHead, TableRow } from "@material-ui/core";
import { getPortefeuillePerformances } from "../../../reducers/homeReducer";
import DateInput from "../../common/Components/DateInput";
import Entreprise from "../Entreprise";

class MonPortefeuille extends Component {
  state = {
    performance_params: {
      params: {
        user_uuid: this.props.user.uuid,
        date: new Date(),
      },
    },
  };
  componentDidMount() {
    this.fetchPerformances();
  }

  fetchPerformances() {
    getPortefeuillePerformances(this.props.dispatch, this.state.performance_params);
  }

  onChangeDateHandler(name,event) {
    this.setState({
      performance_params: {
        params: {
          ...this.state.performance_params.params,
          [name]: event.target.value,
        },
      },
    },() => {
      this.fetchPerformances();
    })
  }

  getPerformances() {
    const { homeStore } = this.props;
    if (!homeStore.portefeuille.performances) {
      return (
        <div style={{ textAlign: "center" }}>
          <h5>Chargement des performances...</h5>
          <CircularProgress />
        </div>
      );
    }

    const { date } = this.state.performance_params.params;
    const {performances} = homeStore.portefeuille;
    const { classes } = this.props;

    return (
      <Table className={classes.table}>
        <TableHead>
          <TableRow>
            <TableCell></TableCell>
            <TableCell align="center" className={classes.tablePortefeuilleHeader}>Jour-J</TableCell>
            <TableCell align="center" className={classes.tablePortefeuilleHeader}>M-1</TableCell>
            <TableCell align="center" className={classes.tablePortefeuilleHeader}>M-3</TableCell>
            <TableCell align="center">
              <DateInput
                value={date}
                id="date_livraison"
                label="Date libre"
                variant="outlined"
                margin="normal"
                collectionStore={homeStore}
                name="date"
                onChangeHandler={this.onChangeDateHandler.bind(this)}
                pickertype="date"
                format="d MMM yyyy"
              />
            </TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
            <TableRow key="prospects">
              <TableCell className={classes.tablePortefeuilleRowTitle}>Nombre prospects</TableCell>
              <TableCell align="center">{performances.nb_prospects.now}</TableCell>
              <TableCell align="center">{performances.nb_prospects.m1}</TableCell>
              <TableCell align="center">{performances.nb_prospects.m3}</TableCell>
              <TableCell align="center">{performances.nb_prospects.date}</TableCell>
            </TableRow>
            <TableRow key="actifs_3m">
              <TableCell className={classes.tablePortefeuilleRowTitle}>Nombre actifs 3 mois</TableCell>
              <TableCell align="center">{performances.nb_actif_3_months.now}</TableCell>
              <TableCell align="center">{performances.nb_actif_3_months.m1}</TableCell>
              <TableCell align="center">{performances.nb_actif_3_months.m3}</TableCell>
              <TableCell align="center">{performances.nb_actif_3_months.date}</TableCell>
            </TableRow>
            <TableRow key="actifs_6m">
              <TableCell className={classes.tablePortefeuilleRowTitle}>Nombre actifs 6 mois</TableCell>
              <TableCell align="center">{performances.nb_actif_6_months.now}</TableCell>
              <TableCell align="center">{performances.nb_actif_6_months.m1}</TableCell>
              <TableCell align="center">{performances.nb_actif_6_months.m3}</TableCell>
              <TableCell align="center">{performances.nb_actif_6_months.date}</TableCell>
            </TableRow>
            <TableRow key="actifs_12m">
              <TableCell className={classes.tablePortefeuilleRowTitle}>Nombre actifs 12 mois</TableCell>
              <TableCell align="center">{performances.nb_actif_12_months.now}</TableCell>
              <TableCell align="center">{performances.nb_actif_12_months.m1}</TableCell>
              <TableCell align="center">{performances.nb_actif_12_months.m3}</TableCell>
              <TableCell align="center">{performances.nb_actif_12_months.date}</TableCell>
            </TableRow>
        </TableBody>
      </Table>
    );
  }
  render() {
    const { classes, user } = this.props;
    return (
      <Grid item xs={12} className={classes.homeSection}>
        {this.getPerformances()}
        <hr />
        <Entreprise commercial_uuid={[user.is_commercial ? user.uuid : "all"]} filter_local_storage="portefeuille_entreprises" />
      </Grid>
    );
  }
}

MonPortefeuille = withStyles(HomeCss)(MonPortefeuille);

MonPortefeuille = connect((store) => ({
  user: store.auth.user,
  homeStore: store.home,
}))(MonPortefeuille);

export default MonPortefeuille;
