import React, { Component } from "react";
import { withStyles } from "@material-ui/core/styles";
import { Grid, Typography } from "@material-ui/core";
import { Settings } from "@material-ui/icons";
import TextInput from "../../common/Components/TextInput";
import LogisticienCss from "./css/LogisticienCss";
import CollectionCrud from "../../common/Components/CollectionCrud";

class Logisticien extends Component {
  getDeleteModalTitle() {
    return (
      <div>
        <Settings />
        <span>{"Suppression du logisticien"}</span>
      </div>
    );
  }

  getDeleteModalContent() {
    return (
      <Typography>
        {"Êtes-vous sûr de vouloir supprimer ce logisticien ?"}
      </Typography>
    );
  }

  getCreateUpdateModalTitle(logisticien) {
    let title = !!logisticien
      ? "Modification du logisticien : " + logisticien.libelle
      : "Ajout d'un nouveau logisticien";
    return (
      <div>
        <Settings />
        <span>{title}</span>
      </div>
    );
  }

  getCreateUpdateModalContent(logisticien, logisticiensStore, onChangeHandler) {
    let { code,full_name } = logisticien;
    return (
      <Grid container>
        <Grid item xs={12}>
          <TextInput
            id="full_name"
            label="Nom complet"
            value={full_name}
            margin="normal"
            collectionStore={logisticiensStore}
            name="full_name"
            onChangeHandler={onChangeHandler}
            fullWidth
          />
        </Grid>
        <Grid item xs={12}>
          <TextInput
            id="code"
            label="Code"
            value={code}
            margin="normal"
            collectionStore={logisticiensStore}
            name="code"
            onChangeHandler={onChangeHandler}
            fullWidth
          />
        </Grid>
      </Grid>
    );
  }

  render() {
    const { classes } = this.props;
    return (
      <Grid container className={classes.container}>
        <CollectionCrud
          collectionName={"logisticiens"}
          showBtnEdit={true}
          showBtnAdd={true}
          showBtnDelete={true}
          rights={{
            create: "admin-cud-parametres",
            edit: "admin-cud-parametres",
            delete: "admin-cud-parametres",
          }}
          deleteModalTitle={this.getDeleteModalTitle}
          deleteModalContent={this.getDeleteModalContent}
          createUpdateModalTitle={this.getCreateUpdateModalTitle}
          createUpdateModalContent={this.getCreateUpdateModalContent.bind(this)}
          datatableConfig={{
            showPagination: true,
            showSearch: true,
            defaultSort: "code",
            sortType: "desc",
            rowsPerPageOptions: [10, 25, 50],
          }}
          cellsConfig={[
            {
              datakey: "code",
              title: "Code",
              sortable: true,
              searchable: true,
            },
            {
              datakey: "full_name",
              title: "Nom Complet",
              sortable: true,
              searchable: true,
            },
          ]}
        />
      </Grid>
    );
  }
}

Logisticien = withStyles(LogisticienCss)(Logisticien);

export default Logisticien;
