export default theme => ({
  container: {
    margin: 0
  },
  homeSection: {
    marginBottom: '3em',
  },
  sectionTitle: {
    marginBottom: '1em',
    color: theme.palette.primary.main
  },
  hr: {
    border: '0.5px solid #18b0ff',
    width: '100%'
  },
  tablePerformance: {
    width: '100%',
    '& tr': {
      '& td:not(:first-child)': {
        textAlign: 'center'
      }
    },
  },
  tablePortefeuilleHeader: {
    fontSize: '1.2em'
  },

  tablePortefeuilleRowTitle: {
    fontSize: '1.1em'
  },

  table: {
    marginBottom: '40px',
    '&td': {
      fontSize: '1.1em'
    }
  },

  subOngletsPerformances: {
    marginTop: '30px',
    marginBottom: '30px',
  },

  periodInputs: {
    display: 'flex',
    flexDirection: 'row',
  },

  defaultHomeContainer: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    height: '100vh',
  },
  logoHome: {
    width: '30rem',
  }

});
