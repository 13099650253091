import React, { Component } from "react";
import { connect } from "react-redux";

import { withStyles } from "@material-ui/core/styles";

import NoteCss from "./css/NoteCss";
import Modal from "../../common/Components/Modal";
import { DialogContent, DialogTitle, Grid, MenuItem } from "@material-ui/core";
import { NoteAdd } from "@material-ui/icons";
import TextInput from "../../common/Components/TextInput";
import {
  collectionActions,
  loadCollectionAttribute,
} from "../../../reducers/collectionsReducer";
import DateInput from "../../common/Components/DateInput";
import CheckboxInput from "../../common/Components/CheckboxInput";

import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";
import "../../../assets/css/react-quill.css";

const DEFAULT_NOTE = {
  entreprise_uuid: null,
  date: new Date().toISOString(),
  contenu: null,
  user_uuid: null,
  cloture: false,
  need_assign: false,
};

class NoteModal extends Component {
  state = {
    open: false,
    note: { ...DEFAULT_NOTE },
    sliderColor: null,
    entreprise_uuid: null,
    entreprise_nom: null,
  };

  static getDerivedStateFromProps(nextProps, prevState) {
    let newstate = { ...prevState };
    if (nextProps.open !== prevState.open) {
      newstate.open = nextProps.open;
    }

    newstate.entreprise_uuid = nextProps.entreprise_uuid || null;
    const props_note = nextProps.note ? { ...nextProps.note } : {};
    if(newstate.note.uuid !== props_note.uuid || (props_note.entreprise_uuid && newstate.note.entreprise_uuid !== props_note.entreprise_uuid)) {
      const { typeNotesStore } = nextProps
      if (
        !props_note.type_note_uuid &&
        typeNotesStore.list &&
        typeNotesStore.list.length > 0
      ) {
        props_note.type_note_uuid = typeNotesStore.list[0].uuid
      }
      newstate.note = {
        ...prevState.note,
        ...props_note,
        entreprise_uuid: nextProps.entreprise_uuid || null,
      };
      if (nextProps.entreprise_nom !== prevState.entreprise_nom) {
        newstate.entreprise_nom = nextProps.entreprise_nom;
      }
    }


    return newstate;
  }

  componentDidMount() {


    const { dispatch, typeNotesStore, usersStore } = this.props;
    loadCollectionAttribute(dispatch, "list", "type_notes", typeNotesStore);
    loadCollectionAttribute(dispatch, "list", "users", usersStore, {
      params: { commerciaux: true },
    });
  }

  onChangeNoteHandler(name, e) {
    this.setState({
      note: {
        ...this.state.note,
        [name]: e.target.value,
      },
    });
  }

  onChangeReactQuillHandler(e) {
    this.setState({
      note: {
        ...this.state.note,
        contenu: e,
      },
    });
  }

  onCloseAddNoteModalHandler() {
    const { onCloseClallback } = this.props;
    collectionActions(this.props.dispatch, "notes", "RESET_ERRORS");
    this.setState(
      {
        entreprise_uuid: null,
        note: DEFAULT_NOTE ,
      },
      onCloseClallback()
    );

  }

  onSubmitAddNoteHandler() {
    this.setState(
      {
        note: {
          ...this.state.note,
          user_uuid: this.props.user.uuid,
        },
      },
      () => {
        collectionActions(
          this.props.dispatch,
          "notes",
          this.state.note.uuid ? "UPDATE" : "CREATE",
          this.state.note,
          (note) => {
            if (!note) return;

            this.onCloseAddNoteModalHandler();
          }
        )
      }
    );
  }

  getEntrepriseInput() {
    const { entreprise_uuid, entreprise_nom, note } = this.state;
    const { entreprisesStore } = this.props;

    if (entreprise_uuid) {
      return (
        <TextInput
          id="entreprise_uuid"
          label="Entreprise"
          margin="normal"
          collectionStore={entreprisesStore}
          name="entreprise_uuid"
          type="text"
          fullWidth
          value={entreprise_nom}
          disabled={true}
        />
      );
    } else {
      return (
        <TextInput
          id="entreprise_uuid"
          label="Entreprise"
          value={note.entreprise_uuid}
          margin="normal"
          collectionStore={entreprisesStore}
          name="entreprise_uuid"
          onChangeHandler={this.onChangeNoteHandler.bind(this)}
          type="autocomplete"
          autocompleteProps={{
            collectionName: "entreprises",
          }}
          fullWidth
        />
      );
    }
  }

  getTypeNoteOptions() {
    const { typeNotesStore } = this.props;
    if (!typeNotesStore.list) return [];

    return typeNotesStore.list.map((type) => {
      return (
        <MenuItem value={type.uuid} key={type.uuid}>
          {type.libelle}
        </MenuItem>
      );
    });
  }

  getCommerciauxOptions() {
    const usersStore = this.props.usersStore;
    if (!usersStore.list) return [];

    return usersStore.list.map((user) => {
      return (
        <MenuItem value={user.uuid} key={user.uuid}>
          {user.firstname} {user.lastname}
        </MenuItem>
      );
    });
  }

  getAssigned(note, noteStore) {
    if (!note.need_assign) return <Grid item xs={4}></Grid>;
    return (
      <>
        <Grid item xs={2}>
          <TextInput
            id="assigned_user_uuid"
            label="Assigné à"
            value={note.assigned_user_uuid}
            margin="normal"
            collectionStore={noteStore}
            name="assigned_user_uuid"
            onChangeHandler={this.onChangeNoteHandler.bind(this)}
            fullWidth
            select
          >
            {this.getCommerciauxOptions()}
          </TextInput>
        </Grid>
        <Grid item xs={2}>
          <DateInput
            id="remind_at"
            label="Rappel"
            value={note.remind_at}
            format="d MMMM yyyy"
            margin="normal"
            collectionStore={noteStore}
            name="remind_at"
            onChangeHandler={this.onChangeNoteHandler.bind(this)}
            fullWidth
            pickertype="date"
          />
        </Grid>
      </>
    );
  }

  render() {
    const { noteStore, classes } = this.props;
    let { open, note } = this.state;


    if (note && !note.date) note.date = new Date().toISOString();
    if (!open) return null;

    return (
      <Modal
        openModal={open}
        onCloseHandler={this.onCloseAddNoteModalHandler.bind(this)}
        onSubmitHandler={this.onSubmitAddNoteHandler.bind(this)}
        fullWidth={true}
        disabledEnter={true}
        maxWidth="xl"
      >
        <DialogTitle key="title" id="alert-dialog-slide-title">
          <NoteAdd style={{ marginBottom: "-9px" }} />{" "}
          {note.uuid ? "Éditer la note" : "Ajouter une note"}
        </DialogTitle>
        <DialogContent key="content">
          <Grid container>
            <Grid item xs={2}>
              <DateInput
                id="date_note"
                label="Date"
                value={note.date}
                format="d MMMM yyyy"
                margin="normal"
                collectionStore={noteStore}
                name="date"
                onChangeHandler={this.onChangeNoteHandler.bind(this)}
                fullWidth
                pickertype="date"
              />
            </Grid>
            <Grid item xs={2}>
              <TextInput
                id="user"
                label="Auteur"
                value={this.props.user.firstname}
                margin="normal"
                collectionStore={noteStore}
                name="type"
                onChangeHandler={this.onChangeNoteHandler.bind(this)}
                fullWidth
                disabled
              />
            </Grid>
            <Grid item xs={2}>
              <TextInput
                id="type_note_uuid"
                label="Type"
                value={note.type_note_uuid}
                margin="normal"
                collectionStore={noteStore}
                name="type_note_uuid"
                onChangeHandler={(name, e) => {
                  const types = this.props.typeNotesStore.list;
                  const type = types.find(
                    (type) => type.uuid === e.target.value
                  );
                  this.setState({
                    note: {
                      ...this.state.note,
                      [name]: type.uuid,
                      need_assign: type.need_assign,
                      assigned_user_uuid: type.need_assign
                        ? this.state.note.assigned_user_uuid
                        : null,
                      remind_at: type.need_assign
                        ? this.state.note.remind_at
                        : null,
                    },
                  });
                }}
                fullWidth
                select
              >
                {this.getTypeNoteOptions()}
              </TextInput>
            </Grid>
            {this.getAssigned(note, noteStore)}
            <Grid item xs={4}>
              {this.getEntrepriseInput()}
            </Grid>
            <Grid item xs={4} className={classes.flexBottom}>
              <CheckboxInput
                id="cloture"
                label="Cloturé"
                value={note.cloture}
                margin="normal"
                name="cloture"
                onChangeHandler={this.onChangeNoteHandler.bind(this)}
              />
            </Grid>
            <Grid item xs={12}>
              <ReactQuill
                theme="snow"
                value={note.contenu}
                onChange={this.onChangeReactQuillHandler.bind(this)}
              />
            </Grid>
          </Grid>
        </DialogContent>
      </Modal>
    );
  }
}

NoteModal = withStyles(NoteCss)(NoteModal);

NoteModal = connect((store) => ({
  user: store.auth.user,
  usersStore: store.collections.users,
  entreprisesStore: store.collections.entreprises,
  noteStore: store.collections.notes,
  typeNotesStore: store.collections.type_notes,
}))(NoteModal);

export default NoteModal;
