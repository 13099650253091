import {createTheme} from '@material-ui/core/styles';
import {assetsForCss} from '../../../tools/Tools';

const primaryColor = '#18b0ff',
  terceraryColor = '#263238',
  textColorLight = '#b8c7ce',
  successColorMain = '#5bd790',
  warningColorMain = '#fb911a',
  errorColorMain = 'rgba(255,57,54,0.71)';

export const theme = createTheme({
  typography: {
    useNextVariants: true,
  },
  palette: {
    primary: {
      main: primaryColor
    },
    error: {
      main: errorColorMain
    }
  },
  overrides: {
    MuiDrawer: {
      docked: {
        minHeight: '100vh'
      }
    },
    MuiDialogTitle: {
      root: {
        padding: '10px 15px',
        position: 'relative',
        backgroundColor: terceraryColor,
        '& h2': {
          color: 'white',
          '& svg': {
            width: '1.4em',
            height: '1.4em'
          },
          '& span': {
            position: 'absolute',
            top: '16px',
            left: '60px'
          }
        },
      }
    },
    MuiFormControl: {
      root: {
        marginLeft: '.5em',
        marginRight: '.5em',
      },
      fullWidth: {
        width: 'calc(100% - 1em)'
      }
    },
    MuiDialogContent: {
      root: {
        padding: '24px',
        overflowY: 'auto',
      }
    },
    MuiCircularProgress: {
      root: {
        marginLeft: 'auto',
        marginRight: 'auto',
        marginTop: '2em'
      }
    },
    MuiInput: {
      underline: {
        '&:after': {
          borderBottom: '2px solid ' + primaryColor
        },
        '&:hover:not($disabled):not($error):not($focused):before': {
          borderBottom: '2px solid ' + primaryColor
        }
      }
    },
    textField: {
      focusColor: primaryColor
    },
    MuiButton: {
      containedPrimary: {
        color: 'white'
      }
    },
    MuiTable: {
      root: {
        marginTop: '1em'
      }
    },
    MuiTableCell: {
      head: {
        padding: '.5em',
        fontSize: '.75rem',
        color: 'rgba(0, 0, 0, 0.54)'
      },
      root: {
        padding: '0 .5em'
      },
      footer: {
        borderBottom: 'none',
      },
    },
    MuiTableSortLabel: {
      icon: {
        opacity: 0.1,
        marginLeft: 0,
        marginRight: 0,
      }
    }
  }
});

export const themeComplement = {
  palette: {
    leftBarDefaultWidth: 240,
    leftBarCollapsedWidth: '62px!important',
    tercerary: terceraryColor,
    textColorLight: textColorLight,
    backgroundPath: assetsForCss('/img/default-background.jpg'),
    appBar: {
      color: '#b7b7b7',
    },
    notifications: {
      success: {
        color: successColorMain,
      },
      warning: {
        color: warningColorMain,
      },
      error: {
        color: errorColorMain,
      }
    }
  }
};
