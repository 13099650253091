import React, {Component} from 'react'

import {connect} from 'react-redux'
import {Grid, MenuItem, Typography} from '@material-ui/core'
import {withStyles} from '@material-ui/core/styles'
import {Person} from '@material-ui/icons'

import {updateContact} from '../../../reducers/collectionsReducer'

import CollectionCrud from '../../common/Components/CollectionCrud'
import CheckboxInput from '../../common/Components/CheckboxInput'
import TextInput from '../../common/Components/TextInput'

import ContactCss from './css/ContactCss'

class Contact extends Component {
  getDeleteModalTitle(contact) {
    return (
      <div>
        <Person/>
        <span>{'Suppression du contact'}</span>
      </div>
    )
  }

  getDeleteModalContent() {
    return (
      <Typography>
        {'Êtes-vous sûr de vouloir supprimer ce contact ?'}
      </Typography>
    )
  }

  getCreateUpdateModalTitle(contact) {
    let title = !!contact
      ? 'Modification du contact : ' + contact.prenom + ' ' + contact.nom
      : "Ajout d'un nouveau contact"
    return (
      <div>
        <Person/>
        <span>{title}</span>
      </div>
    )
  }

  getCivilites() {
    return ['madame', 'monsieur'].map(civilite => {
      return (
        <MenuItem value={civilite} key={civilite}>
          {civilite.charAt(0).toUpperCase() + civilite.slice(1)}
        </MenuItem>
      )
    })
  }

  getCreateUpdateModalContent(contact, contactsStore, onChangeHandler) {
    let {
      civilite, prenom, nom, poste, telephone, email,
      logistique, bordereau, entreprise_uuid, annexe_VII, valorisation,
      non_conformite
    } = contact
    const {classes} = this.props
    let poste_width = 12
    let entreprise_input
    if (!this.props.entrepriseUuid) {
      poste_width = 6
      entreprise_input =
        <Grid item xs={6}>
          <TextInput
            id="entreprise_uuid"
            label="Entreprise"
            value={entreprise_uuid}
            required={true}
            margin="normal"
            collectionStore={contactsStore}
            name="entreprise_uuid"
            onChangeHandler={onChangeHandler}
            className={classes.selectContainer}
            type="autocomplete"
            autocompleteProps={{
              collectionName: "entreprises",
            }}
          />
        </Grid>
    }
    return (
      <Grid container>
        <Grid item xs={12}>
          <TextInput
            id="civilite"
            label="Civilité"
            value={civilite}
            margin="normal"
            collectionStore={contactsStore}
            name='civilite'
            onChangeHandler={onChangeHandler}
            className={classes.selectContainer}
            select
            fullWidth
          >
            {this.getCivilites()}
          </TextInput>
        </Grid>
        <Grid item xs={6}>
          <TextInput
            id="prenom"
            label="Prénom"
            value={prenom}
            required={true}
            margin="normal"
            collectionStore={contactsStore}
            name='prenom'
            onChangeHandler={onChangeHandler}
            fullWidth
          />
        </Grid>
        <Grid item xs={6}>
          <TextInput
            id="nom"
            label="Nom"
            value={nom}
            required={true}
            margin="normal"
            collectionStore={contactsStore}
            name='nom'
            onChangeHandler={onChangeHandler}
            fullWidth
          />
        </Grid>
        <Grid item xs={poste_width}>
          <TextInput
            id="poste"
            label="Poste"
            value={poste}
            margin="normal"
            collectionStore={contactsStore}
            name='poste'
            onChangeHandler={onChangeHandler}
            fullWidth
          />
        </Grid>
        {entreprise_input}
        <Grid item xs={6}>
          <TextInput
            id="telephone"
            label="Téléphone"
            value={telephone}
            margin="normal"
            collectionStore={contactsStore}
            name='telephone'
            onChangeHandler={onChangeHandler}
            fullWidth
          />
        </Grid>
        <Grid item xs={6}>
          <TextInput
            id="email"
            label="Email"
            value={email}
            required={true}
            margin="normal"
            collectionStore={contactsStore}
            name='email'
            onChangeHandler={onChangeHandler}
            fullWidth
          />
        </Grid>
        <Grid item xs={6}>
          <CheckboxInput
            id="logistique"
            label="Logistique"
            value={logistique}
            margin="normal"
            name="logistique"
            onChangeHandler={onChangeHandler}
          />
        </Grid>
        <Grid item xs={6}>
          <CheckboxInput
            id="bordereau"
            label="Bordereau d'achat / Demande de poids"
            value={bordereau}
            margin="normal"
            name="bordereau"
            onChangeHandler={onChangeHandler}
          />
        </Grid>
        <Grid item xs={6}>
          <CheckboxInput
            id="annexe_VII"
            label="Annexe VII"
            value={annexe_VII}
            margin="normal"
            name="annexe_VII"
            onChangeHandler={onChangeHandler}
          />
        </Grid>
        <Grid item xs={6}>
          <CheckboxInput
            id="valorisation"
            label="Attestation valorisation"
            value={valorisation}
            margin="normal"
            name="valorisation"
            onChangeHandler={onChangeHandler}
          />
        </Grid>
        <Grid item xs={6}>
          <CheckboxInput
            id="non_conformite"
            label="Non conformité"
            value={non_conformite}
            margin="normal"
            name="non_conformite"
            onChangeHandler={onChangeHandler}
          />
        </Grid>
      </Grid>
    )
  }

  getCellsConfig() {
    let {entrepriseUuid, withCheckboxes} = this.props
    let cells = [
      {
        datakey: "prenom",
        title: "Prénom",
        sortable: true,
        searchable: true,
      },
      {
        datakey: "nom",
        title: "Nom",
        sortable: true,
        searchable: true,
      },
      {
        datakey: "poste",
        title: "Poste",
        sortable: true,
        searchable: true,
      },
      {
        datakey: "telephone",
        title: "Téléphone",
        sortable: true,
        searchable: true,
      },
      {
        datakey: "email",
        title: "Email",
        sortable: true,
        searchable: true,
      },
    ]
    const entreprise = [
      {
        datakey: "entreprise_nom",
        title: "Entreprise",
        sortable: true,
        searchable: true
      },
    ]
    if (!entrepriseUuid) {
      cells = cells.concat(entreprise)
    }
    const checkboxes = [
      {
        datakey: "annexe_VII",
        title: "A. 7",
        sortable: false,
        searchable: false,
        format: this.getCheckbox.bind(this, "annexe_VII"),
      },
      {
        datakey: "valorisation",
        title: "Val.",
        sortable: false,
        searchable: false,
        format: this.getCheckbox.bind(this, "valorisation"),
      },
      {
        datakey: "logistique",
        title: "Log.",
        sortable: false,
        searchable: false,
        format: this.getCheckbox.bind(this, "logistique"),
      },
      {
        datakey: "bordereau",
        title: "Bord.",
        sortable: false,
        searchable: false,
        format: this.getCheckbox.bind(this, "bordereau"),
      },
      {
        datakey: "non_conformite",
        title: "NC.",
        sortable: false,
        searchable: false,
        format: this.getCheckbox.bind(this, "non_conformite"),
      },
    ]
    if (withCheckboxes) {
      cells = cells.concat(checkboxes)
    }
    return cells
  }

  getCheckbox(field, contact) {
    return (
      <CheckboxInput
        id={field + contact.uuid}
        value={contact[field]}
        margin="normal"
        name={field}
        onChangeHandler={this.onChangeHandlerCheckbox.bind(this, contact)}
      />
    )
  }

  onChangeHandlerCheckbox(contact, name, event) {
    const { dispatch, entreprisesStore } = this.props
    const data = { ...contact, [name]: event.target.value }
    const callback = updated => {
      // Met à jour le state de l'entreprise avec les contacts modifiés
      const entreprise = entreprisesStore.detail
      let others = entreprise.contacts.filter(item => item.uuid !== updated.uuid)
      if ('annexe_VII_prev_uuid' in updated) {
        const previous = entreprise.contacts.find(
          item => item.uuid === updated.annexe_VII_prev_uuid
        )
        previous.annexe_VII = false
        others = [...others.filter(item => item.uuid !== previous.uuid), previous]
      }
      if ('valorisation_prev_uuid' in updated) {
        const previous = entreprise.contacts.find(
          item => item.uuid === updated.valorisation_prev_uuid
        )
        previous.valorisation = false
        others = [...others.filter(item => item.uuid !== previous.uuid), previous]
      }
      const payload = { ...entreprise, contacts: [...others, updated] }
      dispatch({type: 'DETAIL_FULFILLED', collection: 'entreprises', payload})
    }
    updateContact(dispatch, contact.uuid, data, callback)
  }

  getCrudTitle() {
    const {classes} = this.props
    return [
      <Typography
        key="typo"
        variant="button"
        gutterBottom
        className={classes.crudTitle}
      >
        Contacts
      </Typography>
    ]
  }

  getExtraDataForm() {
    let {entrepriseUuid} = this.props
    if (entrepriseUuid) {
      return {
        entreprise_uuid: entrepriseUuid
      }
    }
    return {}
  }

  render() {
    const {datas, actionsCallback} = this.props
    return (
      <>
        <CollectionCrud
          collectionName={'contacts'}
          showBtnEdit={true}
          showBtnAdd={true}
          showBtnDelete={true}
          rights={{
            create: 'admin-cud-contacts',
            edit: 'admin-cud-contacts',
            delete: 'admin-cud-contacts'
          }}
          actionsCallback={actionsCallback}
          datas={datas}
          loadDatas={!!datas ? false : true}
          deleteModalTitle={this.getDeleteModalTitle}
          deleteModalContent={this.getDeleteModalContent.bind(this)}
          createUpdateModalTitle={this.getCreateUpdateModalTitle}
          createUpdateModalContent={this.getCreateUpdateModalContent.bind(this)}
          extradatasForm={this.getExtraDataForm()}
          datatableConfig={{
            showPagination: true,
            showSearch: true,
            defaultSort: "nom",
            sortType: "desc",
            nodatalabel: "Aucun contact",
            rowsPerPageOptions: [25, 50]
          }}
          dataTableExtraNodes={[
            {
              element: this.getCrudTitle(),
              position: 'top-left'
            }
          ]}
          cellsConfig={this.getCellsConfig()}
        />
      </>
    )
  }
}

Contact = withStyles(ContactCss)(Contact)

Contact = connect((store) => {
  return {
    entreprisesStore: store.collections.entreprises,
  }
})(Contact);

export default Contact
