import React, { Component } from "react";

import { Grid, MenuItem, TextField, Typography } from "@material-ui/core";
import { withStyles } from "@material-ui/core/styles";
import { Settings } from "@material-ui/icons";

import CollectionCrud from "../../common/Components/CollectionCrud";
import EtablissementContactNonConformiteCss from "./css/EtablissementContactNonConformiteCss";
import { axiosClient } from "../../../axios";

class EtablissementContactNonConformite extends Component {

  state = {
    contacts: [],
    etablissements: [],
  };

  componentDidMount() {
    axiosClient.get('/etablissement_contact_non_conformites/etablissements')
      .then(response => {
        this.setState({ etablissements: response.data });
      });
    axiosClient.get('/etablissement_contact_non_conformites/contacts')
      .then(response => {
        this.setState({ contacts: response.data });
      });
  }

  getDeleteModalTitle() {
    return (
      <div>
        <Settings />
        <span>{"Suppression du Contact Établissement"}</span>
      </div>
    );
  }

  getDeleteModalContent() {
    return (
      <Typography>
        {"Êtes-vous sûr de vouloir supprimer cette relation ?"}
      </Typography>
    );
  }

  getCreateUpdateModalTitle(etablissementContact) {
    let title = "Ajout d'un nouveau Contact Établissement";
    return (
      <div>
        <Settings />
        <span>{title}</span>
      </div>
    );
  }

  getCreateUpdateModalContent(
    etablissementContact,
    etablissementContactStore,
    onChangeHandler
  ) {
    const { classes} = this.props;
    let { etablissement_uuid, contact_uuid } = etablissementContact;
    return (
      <Grid container>
        <Grid item xs={12}>
          <TextField
              id="etablissement_uuid"
              name="etablissement_uuid"
              label="Site"
              margin="normal"
              onChange={(e) => onChangeHandler('etablissement_uuid',e)}
              className={classes.selectContainer}
              select
              value={etablissement_uuid}
            >
              {this.getOptionsEtablissements()}
            </TextField>
        </Grid>
        <Grid item xs={12}>
          <TextField
              id="contact_uuid"
              name="contact_uuid"
              label="Contact"
              margin="normal"
              onChange={(e) => onChangeHandler('contact_uuid',e)}
              className={classes.selectContainer}
              select
              value={contact_uuid}
            >
              {this.getOptionsContacts()}
            </TextField>
        </Grid>
      </Grid>
    );
  }

  getOptionsContacts() {
    return this.state.contacts.map((contact) => {
      return (
        <MenuItem key={contact.uuid} value={contact.uuid}>
          {contact.full_name_email}
        </MenuItem>
      );
    });
  }
  getOptionsEtablissements() {
    return this.state.etablissements.map((etablissement) => {
      return (
        <MenuItem key={etablissement.uuid} value={etablissement.uuid}>
          {etablissement.nom}
        </MenuItem>
      );
    });
  }

  render() {
    const { classes } = this.props;
    return (
      <Grid container className={classes.container}>
        <CollectionCrud
          collectionName={"etablissement_contact_non_conformites"}
          showBtnEdit={false}
          showBtnAdd={true}
          showBtnDelete={true}
          rights={{
            create: "admin-crud-etablissement_contact_non_conformites",
            edit: "admin-crud-etablissement_contact_non_conformites",
            delete: "admin-crud-etablissement_contact_non_conformites",
          }}
          deleteModalTitle={this.getDeleteModalTitle}
          deleteModalContent={this.getDeleteModalContent}
          createUpdateModalTitle={this.getCreateUpdateModalTitle}
          createUpdateModalContent={this.getCreateUpdateModalContent.bind(this)}
          datatableConfig={{
            showPagination: true,
            showSearch: true,
            defaultSort: "etablissement_nom",
            sortType: "desc",
            rowsPerPageOptions: [10, 25, 50],
          }}
          cellsConfig={[
            {
              datakey: "etablissement_nom",
              title: "Établissement",
              sortable: true,
              searchable: true,
            },
            {
              datakey: "contact_fullname",
              title: "Contact",
              sortable: true,
              searchable: true,
            },
          ]}
        />
      </Grid>
    );
  }
}

EtablissementContactNonConformite = withStyles(EtablissementContactNonConformiteCss)(EtablissementContactNonConformite);

export default EtablissementContactNonConformite;
