import React, {Component} from 'react';
import PropTypes from 'prop-types';
import {Button, Dialog, DialogActions, Slide, Typography} from '@material-ui/core';
import {themeComplement} from "../../../admin/AppAdmin/css/theme";

const DefaultTransition = React.forwardRef((props, ref) => (
  <Slide {...props} ref={ref} />
))

class Modal extends Component {

  static defaultProps = {
    actionCancel: 'Annuler',
    actionMessage: null,
    actionSubmit: 'Valider',
  }

  state = {
    open: false
  };

  /**
   * Retourne le nouveau state en fonction des nextProps
   * @param  {[type]} nextProps [description]
   * @param  {[type]} prevState [description]
   * @return {[type]}           [description]
   */
  static getDerivedStateFromProps(nextProps, prevState) {
    let newState = {...prevState};
    if (nextProps.openModal !== prevState.open) {
      newState = {
        ...newState,
        open: nextProps.openModal
      }
    }

    return newState;
  }

  /**
   * Pour valider le formulaire avec Enter
   * @param  {[type]} e [description]
   * @return {[type]}   [description]
   */
  onKeyPress(e) {
    if (e.which === 13 || e.keyCode === 13 || e.key === 'Enter') { // Différents modes de détection pour prendre en charge l'ensemble des navigateurs
      if (e.target.classList.contains('no-enter') || this.props.disabledEnter) {
        return
      }
      this.props.onSubmitHandler()
    }
  }

  getAdditionalButtons(){
    if(this.props.additionalButtons){
      return this.props.additionalButtons.map((button, index) => {
        return (
          <Button key={index} onClick={button.action} color={button.color} className="no-enter">
            {button.label}
          </Button>
        )
      })
    }
  }

  render() {
    const {
      actionCancel, actionMessage, actionNext, actionSubmit, error,
      onCloseHandler, onNextHandler, onSubmitHandler, disableCancelButton,
      disableActionButtons
    } = this.props
    const {open} = this.state
    let message = null
    if (actionMessage) {
      let messageStyle = {marginRight: 'auto', paddingLeft: '1em'}
      if (error) {
        messageStyle = {
          color: themeComplement.palette.notifications.error.color,
          ...messageStyle
        }
      }
      message = (
        <Typography
          style={messageStyle}
          variant="body2"
        >
          {actionMessage}
        </Typography>
      )
    }
    let submitButton = null
    if (actionSubmit !== 'NONE') {
      submitButton = (
        <Button onClick={onSubmitHandler} color="primary" className="no-enter">
          {actionSubmit}
        </Button>
      )
    }
    let nextButton = null
    if (actionNext) {
      nextButton = (
        <Button onClick={onNextHandler} color="primary" className="no-enter">
          {actionNext}
        </Button>
      )
    }

    return (
      <Dialog
        onKeyPress={this.onKeyPress.bind(this)}
        open={!!open ? open : false}
        TransitionComponent={DefaultTransition}
        keepMounted
        onClose={onCloseHandler}
        aria-labelledby="alert-dialog-slide-title"
        aria-describedby="alert-dialog-slide-description"
        fullWidth={this.props.fullWidth}
        maxWidth={this.props.maxWidth}
        fullScreen={this.props.fullScreen}
        style={this.props.style}
        disableBackdropClick={this.props.disableBackdropClick}
        PaperProps={this.props.hideScroll ? {
          style: {
            overflow: 'hidden'
          }
        }: {}}
        onClick={(e) => {
          e.stopPropagation()
        }}
      >
        {this.props.children}
        {
          disableActionButtons ? null : (
            <DialogActions>
              {message}
              {disableCancelButton ? null : <Button onClick={event => onCloseHandler(event, 'CANCEL')} color={actionSubmit === 'NONE' ? 'primary' : undefined} className="no-enter">
                {actionCancel}
              </Button>}
              {submitButton}
              {nextButton}
              {this.getAdditionalButtons()}
            </DialogActions>
          )
        }
      </Dialog>
    );
  }
}

Modal.propTypes = {
  actionCancel: PropTypes.string,
  disableCancelButton: PropTypes.bool,
  disableBackdropClick: PropTypes.bool,
  actionMessage: PropTypes.string,
  actionNext: PropTypes.string,
  actionSubmit: PropTypes.string,
  error: PropTypes.bool,
  openModal: PropTypes.bool,
  onCloseHandler: PropTypes.func.isRequired,
  onNextHandler: PropTypes.func,
  onSubmitHandler: PropTypes.func.isRequired,
  children: PropTypes.oneOfType([
    PropTypes.element,
    PropTypes.arrayOf(PropTypes.element)
  ]),
  fullWidth: PropTypes.bool,
  maxWidth: PropTypes.string,
  disabledEnter: PropTypes.bool,
  fullScreen: PropTypes.bool,
  disableActionButtons: PropTypes.bool,
};

export default Modal
