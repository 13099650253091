import {
  DialogContent,
  DialogTitle,
  Fab,
  Fade,
  Grid,
  IconButton,
} from "@material-ui/core";
import CircularProgress from "@material-ui/core/CircularProgress";
import { withStyles } from "@material-ui/core/styles";
import { Add, Delete, Edit } from "@material-ui/icons";
import { Cell, DataTable } from "@oniti/datatable-material";
import PropTypes from "prop-types";
import React, { Component } from "react";
import { connect } from "react-redux";
import {
  collectionActions,
  loadCollectionAttribute,
} from "../../../../reducers/collectionsReducer";
import { hasRights } from "../../Tools/Tools";
import Modal from "../Modal";
import CreateUpdate from "./CreateUpdate";
import CollectionCrudCss from "./css/CollectionCrudCss";

import packageJson from "../../../../../package.json";

class CollectionCrud extends Component {

  refCreateUpdate = null;

  constructor(props) {
    super(props);
    this.state = {
      datatable: null,
      list: null,
      asyncTimer: null,
      total: 0,
      openModal: !!props.modal ? props.modal.open : false,
      openDeleteModal: false,
      uuidSelected: !!props.modal ? props.modal.uuid : null,
      detail: null,
    };

    if (!props.collectionsStore[props.collectionName])
      throw new Error("Collection inconnue : " + props.collectionName);
  }

  /**
   * Retourne le nouveau state en fonction des nextProps
   * @param  {[type]} nextProps [description]
   * @param  {[type]} prevState [description]
   * @return {[type]}           [description]
   */
  static getDerivedStateFromProps(nextProps, prevState) {
    // Met à jour l'état de la modale de création/modification
    if (
      nextProps.hasOwnProperty("modal") &&
      !!nextProps.modal &&
      nextProps.modal.open !== prevState.openModal
    ) {
      return { openModal: nextProps.modal.open };
    }

    // Met à jour l'état de la collection
    const { collectionsStore, collectionName, datas, asynchrone } = nextProps;
    const propsList = datas ? datas : collectionsStore[collectionName].list;
    const asyncData = collectionsStore[collectionName].asyncData;
    if (propsList !== prevState.list) {
      return {
        list: propsList,
        total: asyncData && asynchrone ? asyncData.total : 0,
      };
    }
    return null;
  }

  /**
   * Lors du montage du composant
   */
  componentDidMount() {
    const { collectionsStore, collectionName, dispatch, asynchrone } =
      this.props;

    if (this.canLoadData()) {
      if (!asynchrone) {
        loadCollectionAttribute(
          dispatch,
          "list",
          collectionName,
          collectionsStore[collectionName],
          null,
          false,
          null,
          asynchrone
        );
      }
    }
  }

  canLoadData() {
    const { rights, user, loadDatas } = this.props;
    let readRight = true;
    if (!!rights && !!rights.read) {
      readRight = hasRights(rights.read, user);
    }
    let need_load_datas = this.props.hasOwnProperty("loadDatas")
      ? loadDatas
      : true;

    return need_load_datas && readRight && !Array.isArray(this.props.datas);
  }

  setDatatablePage(page) {
    this.datatable.setPage(page);
  }

  /**
   * Handler sur edit
   * @param  {[type]} detail [description]
   * @return {[type]}      [description]
   */
  onClickEditHandler(detail) {
    this.setState({
      openModal: true,
      uuidSelected: detail.uuid,
    });
    const { actionsCallback } = this.props;
    if (actionsCallback) {
      actionsCallback("click-edit", detail, null);
    }
  }

  /**
   * Fermeture de la modal de delete
   * @return {[type]} [description]
   */
  onCloseDeleteModalHandler() {
    this.setState({
      openDeleteModal: false,
      detail: null,
    });
  }

  /**
   * Validation de l'action delete
   * @return {[type]} [description]
   */
  onSubmitHandler() {
    const { dispatch, collectionName, actionsCallback } = this.props;
    collectionActions(
      dispatch,
      collectionName,
      "DELETE",
      this.state.detail,
      () => {
        if (actionsCallback) actionsCallback("delete", this.state.detail);
      }
    );
    this.setState({
      openDeleteModal: false,
      user: null,
    });
  }

  /**
   * Clic sur le bouton Delete
   * @param  {[type]} detail [description]
   * @return {[type]}        [description]
   */
  onClickDeleteHandler(detail) {
    this.setState({
      openDeleteModal: true,
      detail,
    });
  }

  /**
   * Handler pour ajouter un detail de la collection
   */
  addBtnCallBack() {
    this.setState({
      openModal: true,
      uuidSelected: null,
    });
    const { actionsCallback } = this.props;
    if (actionsCallback) {
      actionsCallback("click-add", null, null);
    }
  }

  /**
   * Handler sur la fermeture de la modal
   * @return {[type]} [description]
   */
  onCloseModalHandler(event, reason) {
    const { actionsCallback, collectionName, dispatch } = this.props;
    this.setState({
      openModal: false,
      uuidSelected: null,
    });
    collectionActions(dispatch, collectionName, "RESET_ERRORS");
    collectionActions(dispatch, collectionName, "RESET_DETAIL");
    if (actionsCallback) actionsCallback("close", this.state.detail, reason);
  }

  /**
   * Rendu de la cellule des actions
   * @param  {[type]} object [description]
   * @return {[type]}       [description]
   */
  formatActions(object) {
    let {
      classes,
      additionnalControllers,
      showBtnDeleteCallBack,
      showBtnEditCallBack,
    } = this.props;

    let showBtnEdit = this.showBtnEdit();
    let showBtnDelete = this.showBtnDelete();

    if (showBtnDelete && showBtnDeleteCallBack)
      showBtnDelete = showBtnDeleteCallBack(object);

    if (showBtnEdit && showBtnEditCallBack)
      showBtnEdit = showBtnEditCallBack(object);


    additionnalControllers = additionnalControllers
      ? additionnalControllers
      : [];

    let btnEdit = (
        <IconButton
          aria-label="Edit"
          key="edit"
          color="primary"
          onClick={this.onClickEditHandler.bind(this, object)}
          className={classes.button}
          title="Modifier"
        >
          <Edit />
        </IconButton>
      ),
      btnDelete = (
        <IconButton
          aria-label="Delete"
          onClick={this.onClickDeleteHandler.bind(this, object)}
          className={classes.button}
          key="delete"
          title="Supprimer"
        >
          <Delete />
        </IconButton>
      );

    let result = [];

    additionnalControllers.forEach((f) => result.push(f(object)));

    return [
      showBtnDelete ? btnDelete : null,
      showBtnEdit ? btnEdit : null,
    ].concat(result);
  }

  showBtnEdit(){
    const { rights, user, showBtnEdit } = this.props;
    let showBtn = showBtnEdit;
    if (!!rights && !!rights.edit) {
      showBtn = showBtn && hasRights(rights.edit, user);
    }
    return showBtn;
  }

  showBtnDelete(){
    const { rights, user, showBtnDelete } = this.props;
    let showBtn = showBtnDelete;
    if (!!rights && !!rights.delete) {
      showBtn = showBtn && hasRights(rights.delete, user);
    }
    return showBtn;
  }

  showBtnAdd(){
    const { rights, user, showBtnAdd, showBtnCreateCallBack } = this.props;
    let showBtn = showBtnAdd;
    if (!!rights && !!rights.create) {
      showBtn = showBtn && hasRights(rights.create, user);
    }
    if(showBtn && showBtnCreateCallBack){
      showBtn = showBtnCreateCallBack();
    }
    return showBtn;
  }
  /**
   * Bouton d'ajout d'un utilisateur
   * @return {[type]} [description]
   */
  getBtnAdd() {
    let { classes } = this.props;
    let showBtn = this.showBtnAdd();
    if (!showBtn) return false;
    return (
      <Fab
        size="small"
        color="primary"
        aria-label="add"
        onClick={this.addBtnCallBack.bind(this)}
        className={classes.addButton}
        title="Ajouter"
      >
        <Add style={{ fontSize: 32 }} />
      </Fab>
    );
  }

  /**
   * Retourne les Cells du datatable
   * @return {[type]} [description]
   */
  getCells() {
    const {
      showBtnEdit,
      showBtnDelete,
      additionnalControllers,
      cellsConfig,
      classes,
    } = this.props;
    let cells = cellsConfig.map((conf, index) => (
      <Cell key={index} {...conf} />
    ));
    cells.push(
      showBtnEdit || showBtnDelete || additionnalControllers ? (
        <Cell
          key="crud"
          format={this.formatActions.bind(this)}
          sortable={false}
          className={
            classes.crud_td +
            (this.props.crudClass ? " " + this.props.crudClass : "")
          }
        />
      ) : (
        <Cell className={classes.crud_td_placeholder} />
      )
    );

    return cells;
  }

  getStorageKeyDatatable() {
    const { persistDatatableOptions, collectionName } = this.props;

    return (
      "datatable_" +
      (persistDatatableOptions.id
        ? persistDatatableOptions.id
        : collectionName) +
      "_" +
      packageJson.version
    );
  }

  //Save state on unmount Datatable
  saveDatatableState(state) {
    const { persistDatatableOptions, collectionsStore, collectionName } =
      this.props;
    if (
      !!persistDatatableOptions &&
      !collectionsStore[collectionName].fetching
    ) {
      localStorage.setItem(
        this.getStorageKeyDatatable(),
        JSON.stringify(state)
      );
    }
  }

  //Restore state for datatable
  getInitValue() {
    const { persistDatatableOptions } = this.props;
    let initValue = null;

    if (!!persistDatatableOptions) {
      let storagekey = this.getStorageKeyDatatable();
      if (localStorage[storagekey]) {
        initValue = JSON.parse(localStorage[storagekey]);
        let now = new Date().getTime(),
          expiration_time = !!persistDatatableOptions.exipiration_minutes
            ? initValue.timestamp +
              persistDatatableOptions.exipiration_minutes * 60000
            : null;
        //Vérification de l'expiration
        if (
          !!persistDatatableOptions.exipiration_minutes &&
          now >= expiration_time
        ) {
          localStorage.removeItem(storagekey);
          initValue = null;
        }
      }
    }

    return initValue;
  }

  updatePageAscync(page) {
    this.refCollectionCrud.current.handleChangePage({}, page);
  }

  updateDataAsync(filters, force = false) {
    if (this.props.getAsyncFilter)
      this.props.getAsyncFilter({
        oniti_pagination: 1,
        ...filters,
      });

    if (this.state.asyncTimer) clearTimeout(this.state.asyncTimer);
    if (!force) {
      // Tips pour éviter les appels asynchrones trop fréquents on les délais de 200ms
      // utile lors d'une recherche par exemple, pour ne pas faire une requête par touche ;)
      // eslint-disable-next-line
      this.state.asyncTimer = setTimeout(() => {
        this.updateDataAsync(filters, true);
      }, 100);
    } else if (this.canLoadData()) {
      if (this.props.asynchroneCallbackLoadData) {
        return this.props.asynchroneCallbackLoadData({
          oniti_pagination: 1,
          ...filters,
        });
      } else {
        return collectionActions(
          this.props.dispatch,
          this.props.collectionName,
          "INDEX",
          {
            params: {
              oniti_pagination: 1,
              ...filters,
            },
          },
          null,
          this.props.asynchrone
        );
      }
    } else if (this.props.asynchroneCallbackLoadData) {
      return this.props.asynchroneCallbackLoadData({
        oniti_pagination: 1,
        ...filters,
      });
    }
  }

  getTotalAsyncResult() {
    if (this.props.asynchrone) {
      return this.state.total;
    } else return 0;
  }

  renderDataCustom() {
    const { renderDataCustomCallback } = this.props;
    const { list } = this.state;
    const options = {
      onClickEditHandler: this.onClickEditHandler.bind(this),
      onClickDeleteHandler: this.onClickDeleteHandler.bind(this),
      onClickAddHandler: this.addBtnCallBack.bind(this),
      showBtnAdd: this.showBtnAdd(),
      showBtnEdit: this.showBtnEdit(),
      showBtnDelete: this.showBtnDelete(),
    }

    return renderDataCustomCallback(list|| [], options);
  }

  renderDatatable() {
    const {
      cancelUpdateCheck,
      asynchrone,
      datatableConfig,
      collectionsStore,
      collectionName,
      showBtnAdd,
      dataTableExtraNodes,
      modal,
      renderDataCustomCallback
    } = this.props;


    if (renderDataCustomCallback) {
      return this.renderDataCustom();
    }

    const { list } = this.state;

    let extraNodes = null;

    if (showBtnAdd) {
      extraNodes = [
        {
          element: this.getBtnAdd(),
          position: "top-right",
        },
      ];
    }

    if (!!dataTableExtraNodes) {
      if (extraNodes === null) extraNodes = dataTableExtraNodes;
      else extraNodes = extraNodes.concat(dataTableExtraNodes);
    }
    if(modal && modal.only){
      return null;
    }

    return (
      <Grid container spacing={0}>
        <Grid item xs={12}>
          <DataTable
            innerRef={(ref) => (this.datatable = ref)}
            datas={list || []}
            {...datatableConfig}
            updateDataAsync={this.updateDataAsync.bind(this)}
            totalAsyncResult={this.getTotalAsyncResult()}
            extraNodes={extraNodes}
            cancelUpdateCheck={cancelUpdateCheck}
            getStateOnUnmount={this.saveDatatableState.bind(this)}
            initialValues={this.getInitValue()}
            asynchrone={asynchrone}
            loading={asynchrone && collectionsStore[collectionName].list_fetching}
          >
            {this.getCells()}
          </DataTable>
        </Grid>
      </Grid>
    );
  }

  /**
   * Rendu Final
   * @return {[type]} [description]
   */
  render() {
    const {
      classes,
      collectionName,
      showBtnAdd,
      showBtnEdit,
      showBtnDelete,
      deleteModalTitle,
      deleteModalContent,
      createUpdateModalContent,
      createUpdateModalGetNextState,
      createUpdateModalNext,
      createUpdateModalSubmit,
      createUpdateModalTitle,
      defaultValues,
      defaultValuesCallback,
      customContext,
      extradatasForm,
      actionsCallback,
      modalMaxWidth,
      modal,
      rights,
      user,
      disabledEnterModal,
      collectionsStore,
      modalContentStyle,
      disableLoadCollectionFromDerivedState,
    } = this.props;

    let showDataTable = true;
    if (!!rights && !!rights.read) {
      showDataTable = hasRights(rights.read, user);
    }

    if (!showDataTable) return null;

    // Loader
    if (collectionsStore[collectionName].fetching) {
      return (
        <Grid container style={{ position: "relative" }}>
          <CircularProgress className={classes.loader} size={28} />
        </Grid>
      );
    }

    return (
      <Grid container>
        <Fade in={true}>
          <div className={classes.root}>
            {this.renderDatatable()}
            {!modal && showBtnDelete ? (
              <Modal
                openModal={this.state.openDeleteModal}
                onCloseHandler={this.onCloseDeleteModalHandler.bind(this)}
                onSubmitHandler={this.onSubmitHandler.bind(this)}
                fullWidth={true}
                maxWidth="sm"
              >
                <DialogTitle key="title" id="alert-dialog-slide-title">
                  {deleteModalTitle(this.state.detail)}
                </DialogTitle>
                <DialogContent>
                  {deleteModalContent(this.state.detail)}
                </DialogContent>
              </Modal>
            ) : null}

            {showBtnAdd || showBtnEdit ? (
              <CreateUpdate
                defaultValues={defaultValues}
                defaultValuesCallback={defaultValuesCallback}
                customContext={customContext}
                disabledEnterModal={disabledEnterModal}
                openModal={this.state.openModal}
                uuidSelected={this.state.uuidSelected}
                onCloseHandler={this.onCloseModalHandler.bind(this)}
                collectionName={collectionName}
                createUpdateModalTitle={createUpdateModalTitle}
                createUpdateModalContent={createUpdateModalContent}
                createUpdateModalSubmit={createUpdateModalSubmit}
                createUpdateModalNext={createUpdateModalNext}
                createUpdateModalGetNextState={createUpdateModalGetNextState}
                extradatasForm={extradatasForm}
                actionsCallback={actionsCallback}
                modalMaxWidth={modalMaxWidth}
                fullScreen={this.props.fullScreenModal}
                submitHandlerCreateUpdate={this.props.submitHandlerCreateUpdate}
                resetSelectedUuid={() => this.setState({ uuidSelected: null })}
                modalContentStyle={modalContentStyle}
                disableLoadCollectionFromDerivedState={disableLoadCollectionFromDerivedState}
                hideScroll={this.props.hideScroll}
              />
            ) : null}
          </div>
        </Fade>
      </Grid>
    );
  }
}

CollectionCrud = withStyles(CollectionCrudCss)(CollectionCrud);

CollectionCrud = connect((store) => {
  return {
    collectionsStore: store.collections,
    user: store.auth.user,
  };
})(CollectionCrud);

CollectionCrud.propTypes = {
  collectionName: PropTypes.string.isRequired,
  cellsConfig: PropTypes.array.isRequired,
  datas: PropTypes.array,
  loadDatas: PropTypes.bool,
  datatableConfig: PropTypes.object.isRequired,
  showBtnEdit: PropTypes.bool,
  showBtnAdd: PropTypes.bool,
  showBtnDelete: PropTypes.bool,
  deleteModalTitle: PropTypes.func,
  deleteModalContent: PropTypes.func,
  createUpdateModalContent: PropTypes.func,
  createUpdateModalGetNextState: PropTypes.func,
  createUpdateModalNext: PropTypes.string,
  createUpdateModalSubmit: PropTypes.string,
  createUpdateModalTitle: PropTypes.func,
  extradatasForm: PropTypes.object,
  additionnalControllers: PropTypes.array,
  actionsCallback: PropTypes.func,
  dataTableExtraNodes: PropTypes.arrayOf(PropTypes.object),
  modalMaxWidth: PropTypes.string,
  modal: PropTypes.object,
  rights: PropTypes.object,
  disabledEnterModal: PropTypes.bool,
  // désactive les vérifications sur le rendu du datatable
  cancelUpdateCheck: PropTypes.bool,
  showBtnDeleteCallBack: PropTypes.func,
  showBtnEditCallBack: PropTypes.func,
  showBtnCreateCallBack: PropTypes.func,
  persistDatatableOptions: PropTypes.object,
  crudClass: PropTypes.string,
  // permet d'alimenter la modale de création avec des valeurs par défaut
  defaultValues: PropTypes.object,
  defaultValuesCallback: PropTypes.func,
  fullScreenModal: PropTypes.bool,

  // envoyé à la modale (pour l'instant), utile quand le CollectionCrud est utilisé depuis
  // plusieurs endroits et que la modale doit prendre des visages différents
  customContext: PropTypes.object,

  //Permet de passer en mode asynchrone
  asynchrone: PropTypes.bool,
  asynchroneCallbackLoadData: PropTypes.func,
  getAsyncFilter: PropTypes.func,

  /**
   * Hook pour intercepter de submit de la modal de création et édition
   */
  submitHandlerCreateUpdate: PropTypes.func,

  renderDataCustomCallback: PropTypes.func,

  modalContentStyle: PropTypes.object,

  disableLoadCollectionFromDerivedState: PropTypes.bool,

  /**
   * masque la scrollbar de la modale d'édition
   */
  hideScroll: PropTypes.bool,
};

export default CollectionCrud;
