import React, { Component } from "react";

import { Grid, MenuItem, Typography } from "@material-ui/core";
import { withStyles } from "@material-ui/core/styles";
import { Map } from "@material-ui/icons";

import TextInput from "../../common/Components/TextInput";
import CollectionCrud from "../../common/Components/CollectionCrud";
import ZoneBBCss from "./css/ZoneBBCss";
import { connect } from "react-redux";
import { collectionActions } from "../../../reducers/collectionsReducer";
import NumberInput from "../../common/Components/NumberInput";
import { SketchPicker } from 'react-color';
import Modal from "../../common/Components/Modal";
import ZonePlan from "./ZonePlan";
import { hasRights } from "../../common/Tools/Tools";
import CheckboxInput from "../../common/Components/CheckboxInput";

class ZoneBB extends Component {

  state = {
    openModalSketchPicker : false,
    big_bag_deplacement: null
  }

  getDeleteModalTitle() {
    return (
      <div>
        <Map />
        <span>{"Suppression de la zone"}</span>
      </div>
    );
  }

  getDeleteModalContent() {
    return (
      <Typography>
        {"Êtes-vous sûr de vouloir supprimer cette zone ?"}
      </Typography>
    );
  }

  getCreateUpdateModalTitle(zoneBB) {
    let title = !!zoneBB
      ? zoneBB.name
      : "Ajout d'une nouvelle zone";
    return (
      <div>
        <Map />
        <span>{title}</span>
      </div>
    );
  }

  getCreateUpdateModalContent(zoneBB, zoneBBStore, onChangeHandler) {
    return (
      <Grid container>
          {this.getForm(zoneBB, zoneBBStore, onChangeHandler)}
          <Grid item xs={12}>
              <hr></hr>
          </Grid>
          {this.getPlan(zoneBB)}
      </Grid>
    );
  }

  getForm(zoneBB, zoneBBStore, onChangeHandler){
    const { user } = this.props;
    if(!hasRights(["admin-cud-zone-bb"],user)) return null;
    let {
      name,
      entreprise_uuid,
      etablissement_uuid,
      nb_rows,
      nb_columns,
      prefix_code,
      wall_position,
      color,
      order,
      is_zone_restockage,
      is_zone_sortie_silot,
      impression_mode
    } = zoneBB;

    const { openModalSketchPicker } = this.state;
    const { classes } = this.props;
    return (
      <>
        <Grid item xs={4}>
          <TextInput
            id="name"
            label="Nom"
            value={name}
            margin="normal"
            collectionStore={zoneBBStore}
            name="name"
            onChangeHandler={onChangeHandler}
            fullWidth
          />
        </Grid>
        <Grid item xs={4}>
          <TextInput
            id="entreprise_uuid"
            label="Entreprise"
            className={classes.selectContainer}
            value={entreprise_uuid}
            margin="normal"
            collectionStore={zoneBBStore}
            name="entreprise_uuid"
            type="autocomplete"
            onChangeHandler={(name, e) => {
              onChangeHandler(name, e);
              this.getSitesFromUuids(e.target.value);
            }}
            autocompleteProps={{
              collectionName: "entreprises",
            }}
            fullWidth
          />
        </Grid>
        <Grid item xs={4}>
          <TextInput
            id="etablissement_uuid"
            label="Etablissement"
            className={classes.selectContainer}
            value={etablissement_uuid}
            margin="normal"
            collectionStore={zoneBBStore}
            name="etablissement_uuid"
            onChangeHandler={onChangeHandler}
            fullWidth
            select
          >
            {this.getEtablissements(entreprise_uuid)}
          </TextInput>
        </Grid>
        <Grid item xs={3}>
          <TextInput
            id="prefix_code"
            label="Préfixe code"
            value={prefix_code}
            margin="normal"
            collectionStore={zoneBBStore}
            name="prefix_code"
            onChangeHandler={onChangeHandler}
            fullWidth
          />
        </Grid>

        <Grid item xs={3}>
          <NumberInput
            id="nb_columns"
            label="Nombre de colonnes"
            value={nb_columns}
            margin="normal"
            collectionStore={zoneBBStore}
            name="nb_columns"
            onChangeHandler={onChangeHandler}
            fullWidth
          />
        </Grid>
        <Grid item xs={3}>
          <NumberInput
            id="nb_rows"
            label="Nombre de rangées"
            value={nb_rows}
            margin="normal"
            collectionStore={zoneBBStore}
            name="nb_rows"
            onChangeHandler={onChangeHandler}
            fullWidth
          />
        </Grid>
        <Grid item xs={3}>
          <TextInput
            id="wall_position"
            label="Position mur"
            className={classes.selectContainer}
            value={wall_position}
            margin="normal"
            collectionStore={zoneBBStore}
            name="wall_position"
            onChangeHandler={onChangeHandler}
            fullWidth
            select
          >
            {this.getWallPositionsOptions()}
          </TextInput>
        </Grid>
        <Grid item xs={3}>
          <NumberInput
            id="order"
            label="Ordre"
            value={order}
            margin="normal"
            collectionStore={zoneBBStore}
            name="order"
            onChangeHandler={onChangeHandler}
            fullWidth
          />
        </Grid>
        <Grid item xs={2}>
          <TextInput
            id="color"
            label="Couleur"
            value={color}
            margin="normal"
            collectionStore={zoneBBStore}
            name="color"
            fullWidth
            onClick={() => {
              this.setState({openModalSketchPicker: true})
            }}
            style={{backgroundColor:color}}
          />
          <Modal
            openModal={openModalSketchPicker}
            onCloseHandler={() => {
              this.setState({openModalSketchPicker: false})
            }}
            onSubmitHandler={() => {}}
            disableActionButtons={true}
            maxWidth={"sm"}
            children={this.getModalSketchPicker(color, onChangeHandler)}
          />
        </Grid>
        <Grid item xs={3}>
          <TextInput
            id="impression_mode"
            label="Mode d'impression"
            className={classes.selectContainer}
            value={impression_mode}
            margin="normal"
            collectionStore={zoneBBStore}
            name="impression_mode"
            onChangeHandler={onChangeHandler}
            fullWidth
            select
          >
            {this.getModeImpressionOptions()}
          </TextInput>
        </Grid>
        <Grid item xs={2}>
          <CheckboxInput
            id="is_zone_restockage"
            label="Zone de restockage"
            value={is_zone_restockage}
            margin="normal"
            name="is_zone_restockage"
            onChangeHandler={(name, e) => {
              onChangeHandler(name, e)
            }}
          />
        </Grid>
        <Grid item xs={2}>
          <CheckboxInput
            id="is_zone_sortie_silot"
            label="Zone de sortie silot"
            value={is_zone_sortie_silot}
            margin="normal"
            name="is_zone_sortie_silot"
            onChangeHandler={(name, e) => {
              onChangeHandler(name, e)
            }}
          />
        </Grid>
      </>
    )
  }

  initDeplacementBigBag(big_bag)
  {
    this.setState({big_bag_deplacement: big_bag});
  }

  getPlan(zoneBB){
    if(!zoneBB || !zoneBB.uuid) return null;

    return (
      <Grid item xs={12}>
        <ZonePlan
          cellules={zoneBB.front_cellules}
          editCelluleMode
          big_bag_deplacement={this.state.big_bag_deplacement}
          initDeplacementBigBag={this.initDeplacementBigBag.bind(this)}
          zoneBB_uuid={zoneBB.uuid}
          wallPosition={zoneBB.wall_position}
          legends={zoneBB.legends}
        />
      </Grid>
    )
  }

  getModalSketchPicker(color, onChangeHandler)
  {
    return (
      <div>
        <SketchPicker
          color={color}
          onChangeComplete={(e) => {
            onChangeHandler("color", {target: {value: e.hex}});
          }}
        />
      </div>
    );
  }

  getModeImpressionOptions(){
    const modes = [
      {value: 'landscape', label: 'Paysage'},
      {value: 'portrait', label: 'Portrait'},
    ]

    return modes.map((mode) => {
      return (
        <MenuItem value={mode.value} key={mode.value}>
          {mode.label}
        </MenuItem>
      );
    });
  }

  getWallPositionsOptions(){
    const positions = [
      {value: 'none', label: 'Aucun'},
      {value: 'left', label: 'Gauche'},
      {value: 'right', label: 'Droite'},
      {value: 'top', label: 'Haut'},
      {value: 'bottom', label: 'Bas'},
    ]

    return positions.map((position) => {
      return (
        <MenuItem value={position.value} key={position.value}>
          {position.label}
        </MenuItem>
      );
    });
  }

  getSitesFromUuids(entreprise_uuid) {
    const entreprise_uuids = [entreprise_uuid];
    collectionActions(this.props.dispatch, "etablissements", "INDEX", {
      params: { entreprise_uuids },
    });
  }

  getEtablissements(entreprise_uuid) {
    if (!entreprise_uuid) return [];
    if (!this.props.etablissementsStore.list) {
      return [];
    }
    // Fait une 'shallow copy' pour ne pas modifier 'this.props'
    let etablissements = this.props.etablissementsStore.list.slice();
    if (entreprise_uuid) {
      etablissements = etablissements.filter(
        (e) => e.entreprise_uuid === entreprise_uuid
      );
    }
    return etablissements.map((etablissement) => {
      return (
        <MenuItem value={etablissement.uuid} key={etablissement.uuid}>
          {etablissement.nom}
        </MenuItem>
      );
    });
  }

  render() {
    const { classes } = this.props;
    return (
      <Grid container className={classes.container}>
        <CollectionCrud
          collectionName={"zone-bbs"}
          showBtnEdit={true}
          showBtnAdd={true}
          showBtnDelete={true}
          rights={{
            create: "admin-cud-zone-bb",
            edit: "admin-cud-zone-bb",
            delete: "admin-cud-zone-bb",
          }}
          defaultValues={{
            is_zone_restockage: false,
            is_zone_sortie_silot: false,
            wall_position: "none",
            impression_mode: "portrait",
          }}
          fullScreenModal
          modalMaxWidth="lg"
          deleteModalTitle={this.getDeleteModalTitle}
          deleteModalContent={this.getDeleteModalContent}
          createUpdateModalTitle={this.getCreateUpdateModalTitle}
          createUpdateModalContent={this.getCreateUpdateModalContent.bind(this)}
          datatableConfig={{
            showPagination: true,
            showSearch: true,
            defaultSort: "etablissement_nom",
            sortType: "desc",
            rowsPerPageOptions: [10, 25, 50],
          }}
          cellsConfig={[
            {
              datakey: "name",
              title: "Nom",
              sortable: true,
              searchable: true,
            },
            {
              datakey: "entreprise_nom",
              title: "Entreprise",
              sortable: true,
              searchable: true,
            },
            {
              datakey: "etablissement_nom",
              title: "Etablissement",
              sortable: true,
              searchable: true,
            },
            {
              datakey: "order",
              title: "order",
              sortable: true,
              searchable: true,
            },
            {
              datakey: "color",
              title: "Couleur",
              sortable: true,
              searchable: true,
              format: (obj) => {
                return <div style={{width:'20px',height:'20px',backgroundColor:obj.color}}></div>
              }
            }
          ]}
        />
      </Grid>
    );
  }
}

ZoneBB = withStyles(ZoneBBCss)(ZoneBB);

ZoneBB = connect((store) => {
  return {
    etablissementsStore: store.collections.etablissements,
    user: store.auth.user,
  };
})(ZoneBB);

export default ZoneBB;
