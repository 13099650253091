export default theme => ({
  container: {
    display: 'flex',
    alignItems: 'stretch',
    flexDirection: 'row',
    flexWrap: 'nowrap',
    width: 'calc(100% + 24px)',
  },
  column: {
    margin: '.5em',
    padding: '.5em',
  },
  mainContainer: {
    display: 'flex',
    flexDirection: 'column',
    // height: 'calc(100% - 1.4em)',
    justifyContent: 'space-between',
    width: '100%',
  },
  comment: {
    padding: '.5em',
    overflow: 'hidden',
    backgroundColor: '#51b0ff0f',
    position: 'relative',
    margin: '.5em -.5em -.5em -.5em',
    boxShadow: 'none',
    textAlign: 'left',
    paddingTop: '1.2em',
  },
  backIcon:{
    position: 'absolute',
    bottom: '-15px',
    right: '0px',
    fontSize: '6em',
    color: '#51b0ff12'
  },
  arrivee: {
    backgroundColor: '#28A344'
  },
  depart: {
    backgroundColor: '#2084C0'
  },
  arriveeCapEco: {
    backgroundColor: 'white',
    color: '#28A344',
    border: 'solid 2px #28A344'
  },
  departCapEco: {
    backgroundColor: 'white',
    color: '#2084C0',
    border: 'solid 2px #2084C0'
  },
  date: {
    textAlign: 'center',
    marginLeft: '-.35em',
    marginTop: '-.35em',
    backgroundColor: '#d4d4d4',
    marginRight: '-.5em',
    width: '30%',
    color: 'white',
    borderTopLeftRadius: '4px',
    borderTopRightRadius: 0,
    borderBottomRightRadius: 0,
    borderBottomLeftRadius: 0,
    display: 'inline-block'
  },
  jour: {
    width: '70%',
    display: 'inline-block',
    marginTop: '-.35em',
    textAlign: 'left',
    float: 'right'
  },
  liste: {
    paddingInlineStart: '17px'
  },
  listItem: {
    borderBottom: '1px solid #d4d4d4',
  },
  listItemCommentIcon: {
    fontSize: '2em',
    color: '#FFBE4B'
  },
  ListItemPL:{
    color: 'white',
    height: '1.4rem',
    width: '1.4rem',
    display:' inline-block',
    padding: '0.3rem',
    borderRadius: '5px',
    cursor: 'pointer',
    marginTop:'0.2rem',
  },
  listItemComment: {

  },
  listItemText: {
    marginTop: 0
  },
  primaryTypography: {
    display: 'inline-flex',
    fontSize: 'inherit',
    marginLeft: '6px'
  },
  secondaryTypography: {
    marginLeft: '6px'
  },
  heures: {
    fontWeight: 'bold'
  },
  entreprise: {
    paddingLeft: '.5em',
  },
  reference: {
    '& a': {
      color: 'grey'
    },
    fontWeight: 'bold',
    display: 'inline-block',
  },
  checkboxPasse:{
    display: 'inline-block',
    marginLeft: '0.2em',
    padding: '0.2em 0.2em 0.2em 0.5em',
      '& label':{
        marginRight: '0.5em',
      }
  },
  additionnalAddBtn: {
    padding: '0',
    margin: '0',
  },
  transportNom: {
    fontWeight: 'normal',
    marginTop:'.5em',
    display: 'block',
  },
  transportIcon: {
    verticalAlign: 'middle',
    marginRight: '.3em',
  },
  dayWrapper: {
    position: "relative",
  },
  day: {
    width: '36px',
    height: 36,
    fontSize: theme.typography.caption.fontSize,
    margin: "0 2px",
    color: "inherit",
    textAlign: 'center',
  },
  customDayHighlight: {
    position: "absolute",
    top: 0,
    bottom: 0,
    left: "2px",
    right: "2px",
    border: `1px solid ${theme.palette.secondary.main}`,
    borderRadius: "50%",
  },
  nonCurrentMonthDay: {
    color: theme.palette.text.disabled,
  },
  highlightNonCurrentMonthDay: {
    color: "#676767",
  },
  highlight: {
    background: theme.palette.primary.main,
    color: theme.palette.common.white,
  },
  firstHighlight: {
    extend: "highlight",
    borderTopLeftRadius: "50%",
    borderBottomLeftRadius: "50%",
  },
  endHighlight: {
    extend: "highlight",
    borderTopRightRadius: "50%",
    borderBottomRightRadius: "50%",
  },
  min_width: {
    minWidth: '20em'
  },
  saisieTemps: {
    width: '10%',
  },
  selectBar: {
    textAlign: 'center',
    borderLeft: '1px solid #d4d4d4'
  },
  rightDatebutton: {
    marginLeft: '.5em',
  },
  selectedDate: {
    width: '200px',
    marginTop: 0
  },
  leftDatebutton: {
    marginRight: '.5em',
  },
  addCommentButton: {
    borderRadius: '5em',
  },
  ico: {
    verticalAlign: 'middle',
    marginRight: '0.4em'
  },
  commentaire: {
    padding: '.5em',
    position: 'relative',
    minHeight: '40px',
    '&:hover': {
      cursor: 'pointer',
      backgroundColor: '#0000000a',
      borderRadius: '5px'
    }
  },
  commentHour: {
    fontWeight: 'bold',
  },
  editDeleteComments:{
    position: 'absolute',
    top: '50%',
    backgroundColor: '#d4d4d4',
    borderRadius: '4em',
    transform: 'translateY(-50%)',
    right: '.5em',
    padding: '.3em .5em'
  },
  editCommentButton: {
    color: 'white',
    '&:hover': {
      color: '#51b0ff',
    },
  },
  deleteCommentButton: {
    color: 'white',
    '&:hover': {
      color: 'red',
    },
  },
  poidsAndCommercialContainer: {
    display: 'flex',
    '& span': {
      marginRight: '10px'
    }
  },
  statutAndTypeContainer: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    '& #statut': {
      fontSize: '1.8em',
      marginTop: '5px',
      color: 'grey'
    }
  },
  syntheseDay: {
    textAlign: 'left',
    display: 'inline-block',
    color: '#000',
    fontSize: '0.7rem',
    width: '40%'
  },
  libelleDay: {
    textAlign: 'right',
    width: '50%',
    display: 'inline-block',
  },
  blocageDay:{
    display: 'inline-block',
    width:'10%',
  },
  bloqued:{
    margin: 0,
    color: 'red',
    backgroundColor: '#FFFFFF'
  },
  taf:{
    backgroundColor: '#8b8b8b'
  },
  totauxContainer:{
    display: 'flex',
    flexDirection:'row',
    alignItems: 'center',
    justifyContent: 'space-around',
  },
  totaux:{
    display: 'flex',
    flexDirection:'column',
  },
  total:{
    display: 'flex',
    flexDirection:'row',
  },
  label:{
    marginRight: '0.2rem',
  },
  center:{
    textAlign: 'center',
    width: '100%',
  },
  tabsFlexContainer: {
    '& div:nth-child(1)':{
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
    },
  },
  btnPhoto: {
    float:'left',
    margin: '0.5em',
  },
});
