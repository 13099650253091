import React, { Component } from "react";
import { withStyles } from "@material-ui/core/styles";
import { Grid, MenuItem, Typography } from "@material-ui/core";
import { Settings } from "@material-ui/icons";
import TextInput from "../../common/Components/TextInput";
import CaseStockageCss from "./css/CaseStockageCss";
import CollectionCrud from "../../common/Components/CollectionCrud";
import { connect } from "react-redux";
import { loadCollectionAttribute } from "../../../reducers/collectionsReducer";

class CaseStockage extends Component {
  componentDidMount() {
    loadCollectionAttribute(
      this.props.dispatch,
      "list",
      "etablissements",
      this.props.etablissementsStore,
      {
        params: {
          display_into_planning: true,
        },
      },
      true
    );
  }

  getDeleteModalTitle() {
    return (
      <div>
        <Settings />
        <span>{"Suppression de la case de stockage"}</span>
      </div>
    );
  }

  getDeleteModalContent() {
    return (
      <Typography>
        {"Êtes-vous sûr de vouloir supprimer cette case ?"}
      </Typography>
    );
  }

  getCreateUpdateModalTitle(caseStockage) {
    let title = !!caseStockage
      ? "Modification de la case : " + caseStockage.reference
      : "Ajout d'une nouvelle case";
    return (
      <div>
        <Settings />
        <span>{title}</span>
      </div>
    );
  }

  getCreateUpdateModalContent(
    caseStockage,
    caseStockageStore,
    onChangeHandler
  ) {
    let { reference, etablissement_uuid } = caseStockage;

    return (
      <Grid container>
        <Grid item xs={12}>
          <TextInput
            id="reference"
            label="reference"
            value={reference}
            margin="normal"
            collectionStore={caseStockageStore}
            name="reference"
            onChangeHandler={onChangeHandler}
            fullWidth
          />
        </Grid>
        <Grid item xs={12}>
          <TextInput
            id="etablissement_uuid"
            label="Etablissement"
            value={etablissement_uuid}
            margin="normal"
            collectionStore={caseStockageStore}
            name="etablissement_uuid"
            onChangeHandler={onChangeHandler}
            select
          >
            {this.getEtablissementList()}
          </TextInput>
        </Grid>
      </Grid>
    );
  }

  /**
   * Retourne la liste des sites.
   */
  getEtablissementList() {
    if (!this.props.etablissementsStore.list) {
      return [];
    }
    return this.props.etablissementsStore.list.map((siteItem) => {
      return (
        <MenuItem value={siteItem.uuid} key={siteItem.uuid}>
          {siteItem.nom}
        </MenuItem>
      );
    });
  }

  render() {
    const { classes } = this.props;
    return (
      <Grid container className={classes.container}>
        <CollectionCrud
          collectionName={"caseStockage"}
          showBtnEdit={true}
          showBtnAdd={true}
          showBtnDelete={true}
          rights={{
            create: "admin-cud-case-stockage",
            edit: "admin-cud-case-stockage",
            delete: "admin-cud-case-stockage",
          }}
          deleteModalTitle={this.getDeleteModalTitle}
          deleteModalContent={this.getDeleteModalContent.bind(this)}
          createUpdateModalTitle={this.getCreateUpdateModalTitle}
          createUpdateModalContent={this.getCreateUpdateModalContent.bind(this)}
          datatableConfig={{
            showPagination: true,
            showSearch: true,
            defaultSort: "reference",
            sortType: "desc",
            rowsPerPageOptions: [10, 25, 50],
          }}
          cellsConfig={[
            {
              datakey: "reference",
              title: "Référence",
              sortable: true,
              searchable: true,
            },
            {
              datakey: "site_nom",
              title: "Site",
            },
          ]}
        />
      </Grid>
    );
  }
}

CaseStockage = withStyles(CaseStockageCss)(CaseStockage);
CaseStockage = connect((store) => {
  return {
    etablissementsStore: store.collections.etablissements,
  };
})(CaseStockage);
export default CaseStockage;
