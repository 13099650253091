import React, {Component} from 'react';
import {connect} from "react-redux";
import {TextField} from '@material-ui/core';
import TextInputCss from "./css/TextInputCss";
import {withStyles} from "@material-ui/core/styles/index";
import Autosuggest from 'react-autosuggest';
import Paper from '@material-ui/core/Paper';
import MenuItem from '@material-ui/core/MenuItem';
import Highlighter from "react-highlight-words";
import CircularProgress from '@material-ui/core/CircularProgress';
import {getLibelleFromValue, getSuggestions} from './actions/AutocompleteActions'

class TextInput extends Component {
  state = {
    keyword: '',
    suggestions: [],
    loading: false
  };

  /**
   * Retourne le nouveau state en fonction des nextProps
   * @param  {[type]} nextProps [description]
   * @param  {[type]} prevState [description]
   * @return {[type]}           [description]
   */
  static getDerivedStateFromProps(nextProps, prevState) {
    let newState = {...prevState};
    if (nextProps.name && nextProps.value !== prevState[nextProps.name]) {
      newState = {
        ...newState,
        [nextProps.name]: nextProps.value
      }
    }

    if(nextProps.reset_field){
      newState = {
        ...newState,
        keyword: '',
        suggestions: []
      }
    }

    return newState;
  }

  componentDidMount() {
    const {type, name} = this.props
    if (type && type === 'autocomplete' && this.state[name]) {
      getLibelleFromValue(this.props.dispatch, this.state[name], this.props.autocompleteProps.collectionName, (data) => {
        if (data) this.setState({keyword: data.label})
      })
    }
  }

  /**
   * Retourne le champ de recherche de l'autocomplete
   * @param  {[type]} inputProps [description]
   * @return {[type]}            [description]
   */
  renderInputComponent(props) {
    const {
      classes, inputRef = () => {
      }, ref, ...other
    } = props;

    const {collectionStore, name} = this.props;
    let inputProps = Object.keys(this.props).filter(k => !['name', 'collectionStore', 'onChangeHandler', 'value', 'autocompleteProps', 'type', 'classes', 'dispatch'].includes(k)).reduce((obj, key) => {
      obj[key] = this.props[key];
      return obj;
    }, {})

    return (
      <TextField
        error={!!collectionStore.errors && !!collectionStore.errors[name]}
        helperText={this.getErrorMessage(name)}
        value={this.state[name] ? this.state[name] : ''}
        InputProps={{
          inputRef: node => {
            ref(node);
            inputRef(node);
          },
          classes: {
            input: (classes.input ? classes.input : '') + ' no-enter',
          },
        }}
        {...inputProps}
        {...other}
      />
    );
  }

  /**
   * Retourne la valeur Suggérée
   * @param  {[type]} suggestion [description]
   * @return {[type]}            [description]
   */
  getSuggestionValue(suggestion) {
    const {name, autocompleteFullValue} = this.props;
    this.props.onChangeHandler(name, {
      target: {
        value: autocompleteFullValue ? suggestion : suggestion.value,
        name: name
      }
    })
    return suggestion.label;
  }

  /**
   * Renvoie le container pour les suggestions
   * @param  {[type]} options [description]
   * @return {[type]}         [description]
   */
  renderSuggestionsContainer(options) {
    const { autoCompleteSuggestions} = this.props.classes;
    return (
      <Paper {...options.containerProps} style={{textAlign: this.state.loading ? 'center' : 'left'}} square className={autoCompleteSuggestions}>
        {options.children}
      </Paper>
    )
  }

  /**
   * Retourne la liste des résultats
   * @param  {[type]}  suggestion    [description]
   * @param  {[type]}  query         [description]
   * @param  {Boolean} isHighlighted [description]
   * @return {[type]}                [description]
   */
  renderSuggestion(suggestion) {
    let {loader} = this.props.classes;

    if (this.state.loading) return (
      <MenuItem component="div">
        <CircularProgress className={loader} size={28}/>
      </MenuItem>
    );
    return (
      <MenuItem component="div">
        <Highlighter
          highlightClassName={this.props.classes.autocompleteBold}
          searchWords={this.state.keyword.split(' ')}
          autoEscape={true}
          textToHighlight={suggestion.label}
        />
      </MenuItem>
    );
  }

  /**
   * HAndler changement de keyword
   * @param name
   * @returns {function(*, {newValue?: *}): void}
   */
  handleChange = name => (event, {newValue}) => {
    this.setState({
      keyword: newValue,
    }, () => {
      if (this.state.keyword === '' && this.props.clearAutoCompleteCallBack) this.props.clearAutoCompleteCallBack()
      if(this.props.onChangeHandlerKeyword) this.props.onChangeHandlerKeyword(this.state.keyword)
    });
  }

  /**
   * Remet à 0 les suggestions
   * @return {[type]} [description]
   */
  handleSuggestionsClearRequested() {
    this.setState({
      suggestions: [],
    });
  }

  /**
   * Point d'entrée pour aller intéroger l'api
   * @param  {[type]} value [description]
   * @return {[type]}       [description]
   */
  handleSuggestionsFetchRequested({value}) {
    if (this.state.SuggestionTimeoutHandle) clearTimeout(this.state.SuggestionTimeoutHandle);
    // eslint-disable-next-line
    this.state.SuggestionTimeoutHandle = setTimeout(() => {
      let handle = setTimeout(() => {
        this.setState({loading: true, suggestions: ['loading']});
      }, 300);
      const params =  this.props.autocompleteProps.params ?  this.props.autocompleteProps.params : {};
      getSuggestions(this.props.dispatch, value,params, this.props.autocompleteProps.collectionName, (suggestions) => {
        clearTimeout(handle);
        this.setState({
          suggestions,
          loading: false,
        });
      });
    }, 100);
  }

  /**
   * Retourne le message d'erreur pour le champ demandé
   * @param  {[type]} name [description]
   * @return {[type]}      [description]
   */
  getErrorMessage(name) {
    const {collectionStore} = this.props;
    if (collectionStore && collectionStore.errors && collectionStore.errors[name]) {
      return collectionStore.errors[name].map((err, index) => <span style={{display: 'block'}}
                                                                    key={index}>{err}</span>);
    } else return null;
  }

  /**
   * Handler du changement sur les inputs
   * @param  {[type]} name [description]
   * @return {[type]}      [description]
   */
  onChangeHandler(name, event) {
    this.setState({
      [name]: event.target.value
    });
    this.props.onChangeHandler(name, event)
  };

  getClearBtn(){
    const { clearable, classes, clearConfirmCallback } = this.props;
    if(!clearable) return null;
    return (
        <div
            onClick={() => {
              if(!clearConfirmCallback || clearConfirmCallback()){
                this.setState({keyword: ''})
                this.onChangeHandler(this.props.name, {target: {value: ''}})
              }
            }}
            className={classes.clearable}
        />
    )
  }

  /**
   * Rendu Final
   * @return {[type]} [description]
   */
  render() {
    const {collectionStore, name, type, classes, label, placeholder} = this.props;
    let inputProps = Object.keys(this.props).filter(k => !['name', 'collectionStore', 'onChangeHandler', 'value', 'classes', 'dispatch'].includes(k)).reduce((obj, key) => {
      obj[key] = this.props[key];
      return obj;
    }, {});
    if (type && type === 'autocomplete') {
      const autosuggestProps = {
        renderInputComponent: this.renderInputComponent.bind(this),
        suggestions: this.state.suggestions,
        onSuggestionsFetchRequested: this.handleSuggestionsFetchRequested.bind(this),
        onSuggestionsClearRequested: this.handleSuggestionsClearRequested.bind(this),
        getSuggestionValue: this.getSuggestionValue.bind(this),
        renderSuggestion: this.renderSuggestion.bind(this),
      };

      return (
        <div className={classes.inputAutocomplete}>
          <Autosuggest
            ref={this.props.ref}
            {...autosuggestProps}
            inputProps={{
              label,
              classes,
              placeholder: placeholder,
              value: this.state.keyword,
              onChange: this.handleChange(),
            }}
            theme={{
              container: classes.container,
              suggestionsContainerOpen: classes.suggestionsContainerOpen,
              suggestionsList: classes.suggestionsList,
              suggestion: classes.suggestion,
            }}
            renderSuggestionsContainer={this.renderSuggestionsContainer.bind(this)}
          />
          {this.getClearBtn()}
        </div>
      )
    } else {
      return (
        <TextField
          error={collectionStore && !!collectionStore.errors && !!collectionStore.errors[name]}
          helperText={this.getErrorMessage(name)}
          value={this.state[name] ? this.state[name] : ''}
          onChange={this.onChangeHandler.bind(this, name)}
          {...inputProps}
          theme={{}}
        >
          {this.props.children}
        </TextField>
      );
    }
  }
}

TextInput = connect((store) => {
  return {}
})(TextInput);
export default withStyles(TextInputCss)(TextInput)
