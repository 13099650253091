import React from 'react';
import {applyMiddleware, combineReducers, compose, createStore} from 'redux';
import {Switch} from 'react-router-dom';
import {Provider} from 'react-redux';
import {createBrowserHistory as createHistory} from 'history';
import {Route} from 'react-router';
import {ConnectedRouter, connectRouter, routerMiddleware} from 'connected-react-router';
import DevTools from './DevTools';
import * as reducers from '../reducers';
import {routes} from './routes';

import App from '../components/common/App';
import Error404 from '../components/common/ErrorsPages/Error404';

import RouterConfig from './config';

const devMode = process.env.NODE_ENV === 'development';

const history = createHistory();

const middleware = applyMiddleware(routerMiddleware(history));
const rootReducer = combineReducers({
  ...reducers,
  router:connectRouter(history)
});
const store = createStore(
  connectRouter(history)(rootReducer),
  devMode ? compose(middleware, DevTools.instrument()) : compose(middleware)
);

class MainRouter extends React.Component {
  /**
   * Retourne l'application
   * @return {[type]} [description]
   */
  getApp(){
    return (
      <App>

        <Switch>
          {routes.map(r => {
            return <Route path={r.path} exact={r.exact} key={r.path} component={r.component}/>
          })}
          <Route component={Error404}/>
        </Switch>
        {devMode ? <DevTools/> : null}
      </App>
    );
  }
  /**
   * Retourne le router spécifique si besoin
   * @return {[type]} [description]
   */
  getRouter(){
    if(!!RouterConfig.routerComponent){
      return (
        <RouterConfig.routerComponent >
          {this.getApp()}
        </RouterConfig.routerComponent >
      );
    }else{
      return this.getApp();
    }
  }
  render() {
    return (
      <Provider store={store}>
        <ConnectedRouter history={history}>
          {this.getRouter()}
        </ConnectedRouter>
      </Provider>
    );
  }
}

export default MainRouter;
