export default theme => ({
  container: {
    margin: 0
  },
  section: {
    marginBottom: '3em',
  },
  button: {
    '& span': {
      display: 'block'
    },
    marginLeft: '.5em',
    marginBottom: '.5em',
    textAlign: 'center',
    width: 'calc(50% - .5em)',
  },
  buttonSmall: {
    '& span': {
      display: 'block'
    },
    marginLeft: '.5em',
    marginBottom: '.5em',
    textAlign: 'center',
    width: 'calc(33.3% - .5em)',
  },
  buttonSimple: {
    '& span': {
      display: 'block'
    },
    marginLeft: '.5em',
    marginBottom: '.5em',
    textAlign: 'center',
    width: '100%',
  },
  no_margin: {
    margin: 0,
    marginBottom: '.5em',
  },
  file_ico: {
    display: 'block',
    margin: 'auto',
    padding: '0.3em'
  },
  doneButton: {
    float: 'right'
  },
  buttonIcon: {
    marginRight: '0.3em'
  },
  sectionTitle: {
    marginBottom: '1em',
    color: theme.palette.primary.main
  },
  btn:{
    display:'inline-block',
  },
  margin_top:{
    marginTop:'2em',
  },
  selectContainer: {
    width: 'calc(100% - 1em)'
  }
});
