import {Avatar, Button, Grid, TextField, Typography} from '@material-ui/core';
import ChevronLeft from '@material-ui/icons/ChevronLeft';
import {withStyles} from "@material-ui/core/styles/index";
import React, {Component} from 'react';
import {connect} from 'react-redux';
import {withRouter} from 'react-router-dom';
import {passwordLost} from '../../../reducers/authReducer';
import {getErrorsMessage} from '../../tools/Tools';
import AuthCss from "./css/AuthCss";
import Logo from '../../../assets/img/default-logo-63px.png';

class PasswordLost extends Component {
  state = {
    email: '',
    prefix: ''
  };

  /**
   * Soumission du formulaire
   * @return {[type]} [description]
   */
  onClickSubmit() {
    passwordLost(this.props.dispatch, this.state, () => {
      this.props.history.push('/auth')
    })
  }

  /**
   * Clic sur le btn retour
   */
  OnClickBack() {
    this.props.history.push('/auth')
  }

  /**
   * Handler de changement de valeur pour les formulaires
   * @param {*} event
   */
  handleChange(event) {
    let {value, name} = event.target;
    this.setState({
      [name]: value,
    });
  }

  /**
   * Pour valider le formulaire avec Entrer
   * @param  {[type]} e [description]
   * @return {[type]}   [description]
   */
  onKeyPress(e) {
    if (e.key === 'Enter') {
      this.onClickSubmit()
    }
  }

  /**
   * Fonction de rendu final
   * @return {[type]} [description]
   */
  render() {
    const {classes} = this.props;
    return (
      <div>
        <Grid item>
          <Avatar className={classes.avatar}>
            <img src={Logo} alt='Logo'/>
          </Avatar>
        </Grid>
        <Grid item>
          <Typography
            variant="h6"
            gutterBottom
            className={classes.title}
            color='primary'
          >
            Mot de passe oublié
          </Typography>
        </Grid>
        <Grid item>
          <TextField
            name="email"
            label="Email"
            className={classes.textField}
            value={this.state.email}
            onChange={this.handleChange.bind(this)}
            helperText={getErrorsMessage(this.props.auth.errors, 'email')}
            error={!!this.props.auth.errors && !!this.props.auth.errors.email}
            type={"email"}
            margin="normal"
            onKeyPress={this.onKeyPress.bind(this)}
          />
        </Grid>
        <Grid item className={classes.buttonsLign}>
          <Button
            variant="contained"
            color={"primary"}
            onClick={this.onClickSubmit.bind(this)}
            className={classes.button}
          >
            Valider
          </Button>
          <Button
            color={"primary"}
            onClick={this.OnClickBack.bind(this)}
            className={classes.button}
          >
            <ChevronLeft/> Retour
          </Button>
        </Grid>
      </div>
    );
  }
}

PasswordLost = connect((store) => {
  return {
    auth: store.auth,
    currentRouteConfig: store.app.currentRouteConfig
  }
})(PasswordLost);

PasswordLost = withStyles(AuthCss)(PasswordLost);
PasswordLost = withRouter(PasswordLost);

export default PasswordLost
