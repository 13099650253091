import React, { Component } from "react";

import { Grid, MenuItem, Typography } from "@material-ui/core";
import { withStyles } from "@material-ui/core/styles";
import { Settings } from "@material-ui/icons";

import TextInput from "../../common/Components/TextInput";
import NumberInput from "../../common/Components/NumberInput";
import CollectionCrud from "../../common/Components/CollectionCrud";
import FactureAnnexeCss from "./css/FactureAnnexeCss";
import {
  collectionActions,
  loadCollectionAttribute,
} from "../../../reducers/collectionsReducer";
import { connect } from "react-redux";
import CheckboxInput from "../../common/Components/CheckboxInput";
import { hasRights } from "../../common/Tools/Tools";

class FactureAnnexe extends Component {
  state = {
    affaire_uuid: null,
    type: null,
    onlyUnPaid: null,
    date_filter:null,
    type_date_filter:null,
    etat_filter:null,
  };

  componentDidMount() {
    this.getFactures();
    loadCollectionAttribute(
      this.props.dispatch,
      "list",
      "raison_facturations",
      this.props.raison_facturationssStore
    );
  }
  getFactures() {
    var update = false;
    if (this.state.type !== this.props.typeFacture) {
      /* eslint-disable-next-line */
      this.state.type = this.props.typeFacture;
      update = true;
    }
    if (this.state.onlyUnPaid !== this.props.onlyUnPaid) {
      /* eslint-disable-next-line */
      this.state.onlyUnPaid = this.props.onlyUnPaid;
      update = true;
    }
    if (this.state.date_filter !== this.props.date_filter) {
      /* eslint-disable-next-line */
      this.state.date_filter = this.props.date_filter;
      update = true;
    }
    if (this.state.type_date_filter !== this.props.type_date_filter) {
      /* eslint-disable-next-line */
      this.state.type_date_filter = this.props.type_date_filter;
      update = true;
    }
    if (this.state.etat_filter !== this.props.etat_filter) {
      /* eslint-disable-next-line */
      this.state.etat_filter = this.props.etat_filter;
      update = true;
    }
    if (update) {
      this.updateFactureList();
    }
  }
  updateFactureList(){
    const { affaire } = this.props;
    collectionActions(this.props.dispatch, "facture_annexes", "INDEX", {
      params: {
        affaire_uuid: affaire ? affaire.uuid : null,
        type:
          this.state.type === "all" || !this.state.type
            ? null
            : this.state.type,
        onlyUnPaid: !!this.state.onlyUnPaid ? true : null,
        entreprise_uuid: this.props.entreprise_uuid || null,
        date_filter: this.state.date_filter,
        type_date_filter: this.state.type_date_filter,
        etat_filter: this.state.etat_filter,
      },
    });
  }
  componentDidUpdate() {
    this.getFactures();
  }

  getDeleteModalTitle() {
    return (
      <div>
        <Settings />
        <span>{"Suppression de la facture annexe"}</span>
      </div>
    );
  }

  getDeleteModalContent() {
    return (
      <Typography>
        {"Êtes-vous sûr de vouloir supprimer cette facture annexe ?"}
      </Typography>
    );
  }

  getCreateUpdateModalTitle(facture) {
    let title =
      !!facture && facture.raison
        ? "Modification de la facture annexe : " + facture.raison.libelle
        : "Ajout d'un nouvelle facture annexe";
    return (
      <div>
        <Settings />
        <span>{title}</span>
      </div>
    );
  }

  getOptionsRaisons() {
    return this.props.raison_facturationssStore.list.map(
      (raison_facturation) => {
        return (
          <MenuItem
            value={raison_facturation.uuid}
            key={raison_facturation.uuid}
          >
            {raison_facturation.libelle}
          </MenuItem>
        );
      }
    );
  }

  getCreateUpdateModalContent(
    facture_annexe,
    factureAnnexesStore,
    onChangeHandler
  ) {
    const { classes } = this.props;
    let {
      prix_unitaire,
      quantite,
      entreprise_uuid,
      type_facturation,
      raison_facturation_uuid,
      commentaire_interne,
      commentaire_facturation,
    } = facture_annexe;

    return (
      <Grid container>
        <Grid item xs={12}>
          <TextInput
            id="type_facturation"
            label="Type"
            value={type_facturation}
            margin="normal"
            name="type_facturation"
            collectionStore={factureAnnexesStore}
            onChangeHandler={onChangeHandler}
            select
            fullWidth
          >
            <MenuItem value={"entrante"}>Facturation Entrante</MenuItem>
            <MenuItem value={"sortante"}>Facturation Sortante</MenuItem>
          </TextInput>
        </Grid>
        <Grid item xs={12}>
          <TextInput
            id="entreprise_uuid"
            label="Entreprise"
            value={entreprise_uuid}
            required={true}
            margin="normal"
            collectionStore={factureAnnexesStore}
            name="entreprise_uuid"
            onChangeHandler={onChangeHandler}
            className={classes.selectContainer}
            type="autocomplete"
            autocompleteProps={{
              collectionName: "entreprises",
            }}
            fullWidth
          />
        </Grid>
        <Grid item xs={12}>
          <TextInput
            id="raison_facturation_uuid"
            label="Raison"
            value={raison_facturation_uuid}
            margin="normal"
            name="raison_facturation_uuid"
            collectionStore={factureAnnexesStore}
            onChangeHandler={onChangeHandler}
            select
            fullWidth
          >
            {this.getOptionsRaisons()}
          </TextInput>
        </Grid>
        <Grid item xs={12}>
          <NumberInput
            id="prix_unitaire"
            label="Prix unitaire"
            value={prix_unitaire}
            required={true}
            margin="normal"
            collectionStore={factureAnnexesStore}
            name="prix_unitaire"
            onChangeHandler={onChangeHandler}
            fullWidth
          />
        </Grid>
        <Grid item xs={12}>
          <NumberInput
            id="quantite"
            label="Quantite"
            value={quantite}
            required={true}
            margin="normal"
            collectionStore={factureAnnexesStore}
            name="quantite"
            onChangeHandler={onChangeHandler}
            fullWidth
          />
        </Grid>
        <Grid item xs={12}>
          <NumberInput
            id="total"
            label="Total"
            value={(quantite * prix_unitaire).toFixed(2)}
            required={true}
            margin="normal"
            collectionStore={factureAnnexesStore}
            name="total"
            onChangeHandler={onChangeHandler}
            fullWidth
            disabled
          />
        </Grid>
        <Grid item xs={12}>
          <TextInput
            id="commentaire_interne"
            label="Commentaire interne"
            value={commentaire_interne}
            margin="normal"
            collectionStore={factureAnnexesStore}
            name="commentaire_interne"
            onChangeHandler={onChangeHandler}
            fullWidth
          />
        </Grid>
        <Grid item xs={12}>
          <TextInput
            id="commentaire_facturation"
            label="Commentaire facturation"
            value={commentaire_facturation}
            margin="normal"
            collectionStore={factureAnnexesStore}
            name="commentaire_facturation"
            onChangeHandler={onChangeHandler}
            fullWidth
          />
        </Grid>
      </Grid>
    );
  }

  getCellsConfig() {
    const { cellsConfig } = this.props;
    if (cellsConfig) return cellsConfig;

    return [
      {
        datakey: "type",
        title: "Type",
        sortable: true,
        searchable: true,
      },
      {
        datakey: "entreprise.nom",
        title: "Entreprise",
        sortable: true,
        searchable: true,
      },
      {
        datakey: "quantite",
        title: "Quantité",
        sortable: true,
        searchable: true,
      },
      {
        datakey: "prix_unitaire",
        title: "Prix unitaire",
        sortable: true,
        searchable: true,
        format: (facture) => {
          const inf = new Intl.NumberFormat("fr-FR", {
            minimumFractionDigits: 2,
            maximumFractionDigits: 2,
          });
          return inf.format(facture.prix_unitaire) + " €";
        },
      },
      {
        datakey: "montant",
        title: "Montant HT",
        sortable: true,
        searchable: true,
        format: (facture) => {
          const inf = new Intl.NumberFormat("fr-FR", {
            minimumFractionDigits: 2,
            maximumFractionDigits: 2,
          });
          return inf.format(facture.montant) + " €";
        },
      },
      {
        datakey: "raison_libelle",
        title: "Raison",
        sortable: true,
        searchable: true,
      },
      {
        datakey: "commentaire_facturation",
        title: "Commentaire Facturation",
        sortable: true,
        searchable: true,
      },
      {
        datakey: "commentaire_interne",
        title: "Commentaire Interne",
        sortable: true,
        searchable: true,
      },
    ];
  }

  getRecievedBtn(facture) {
    const { classes,user,update_facture_update } = this.props;
    if(!hasRights('admin-crud-facturation-annexe', user)) return null

    return (
      <div key={facture.uuid} className={classes.button}>
        <CheckboxInput
          value={facture.paid_at}
          margin="normal"
          name="paid_at"
          onChangeHandler={(name, e) => {
            collectionActions(
              this.props.dispatch,
              "facture_annexes",
              "UPDATE",
              {
                uuid: facture.uuid,
                paid_at: e.target.value ? new Date() : null,
              },
              update_facture_update
            );
          }}
        />
      </div>
    );
  }
  showActionsBtnCallBack(facture)
  {
    return !facture.paid_at
  }

  render() {
    const { classes, affaire, showActionsBtn, collectionCrud_options, update_facture_update } = this.props;
    return (
      <Grid container className={classes.container}>
        <CollectionCrud
          collectionName={"facture_annexes"}
          showBtnEdit={showActionsBtn && true}
          showBtnAdd={showActionsBtn && true}
          showBtnDelete={showActionsBtn && true}
          loadDatas={false}
          rights={{
            create: "admin-cud-affaires",
            edit: "admin-cud-affaires",
            delete: "admin-cud-affaires",
          }}
          deleteModalTitle={this.getDeleteModalTitle}
          deleteModalContent={this.getDeleteModalContent}
          createUpdateModalTitle={this.getCreateUpdateModalTitle}
          createUpdateModalContent={this.getCreateUpdateModalContent.bind(this)}
          additionnalControllers={[this.getRecievedBtn.bind(this)]}
          showBtnEditCallBack={this.showActionsBtnCallBack.bind(this)}
          showBtnDeleteCallBack={this.showActionsBtnCallBack.bind(this)}
          datatableConfig={{
            showPagination: true,
            showSearch: true,
            defaultSort: "type",
            sortType: "desc",
            rowsPerPageOptions: [this.props.rowsPerPage ? this.props.rowsPerPage : 10,25,100],
          }}
          cellsConfig={this.getCellsConfig()}
          extradatasForm={{
            affaire_uuid: affaire ? affaire.uuid : null,
          }}
          actionsCallback={(action, data) => {
            if(action === 'create' || action === 'update' || action === 'delete'){
              if(update_facture_update){
                update_facture_update()
              }
            }
          }}
          cancelUpdateCheck={this.props.cancelUpdateCheck}
          persistDatatableOptions={
            !!collectionCrud_options
              ? collectionCrud_options.persistDatatableOptions
              : {
                  id: "facture_annexe",
                  exipiration_minutes: 60 * 12, // 12 Heures
                }
          }
        />
      </Grid>
    );
  }
}

FactureAnnexe = withStyles(FactureAnnexeCss)(FactureAnnexe);

FactureAnnexe = connect((store) => {
  return {
    raison_facturationssStore: store.collections.raison_facturations,
    user: store.auth.user,
  };
})(FactureAnnexe);

export default FactureAnnexe;
