import React, {Component} from 'react'

import {Grid, Typography} from '@material-ui/core'
import {withStyles} from '@material-ui/core/styles'
import {Settings} from '@material-ui/icons'

import TextInput from '../../common/Components/TextInput'
import CollectionCrud from '../../common/Components/CollectionCrud'
import EmailBloacagePlanningCss from './css/EmailBloacagePlanningCss'

class EmailBloacagePlanning extends Component {

  getDeleteModalTitle() {
    return (
      <div>
        <Settings/>
        <span>{"Suppression de l'email"}</span>
      </div>
    )
  }

  getDeleteModalContent() {
    return (
      <Typography>
        {'Êtes-vous sûr de vouloir supprimer cet email ?'}
      </Typography>
    )
  }

  getCreateUpdateModalTitle(pays) {
    let title = !!pays
      ? "Modification de l'email : " + pays.nom
      : "Ajout d'un nouveau email"
    return (
      <div>
        <Settings/>
        <span>{title}</span>
      </div>
    )
  }

  getCreateUpdateModalContent(emailBlocage, emailBlocageStore, onChangeHandler) {
    let {email } = emailBlocage;
    return (
      <Grid container>
        <Grid item xs={12}>
          <TextInput
            id="email"
            label="Email"
            value={email}
            margin="normal"
            collectionStore={emailBlocageStore}
            name='email'
            onChangeHandler={onChangeHandler}
            fullWidth
          />
        </Grid>
      </Grid>
    )
  }

  render() {
    const {classes} = this.props
    return (
      <Grid container className={classes.container}>
        <CollectionCrud
          collectionName={'email-blocage-planning'}
          showBtnEdit={true}
          showBtnAdd={true}
          showBtnDelete={true}
          rights={{
            create: 'admin-cud-parametres',
            edit: 'admin-cud-parametres',
            delete: 'admin-cud-parametres'
          }}
          deleteModalTitle={this.getDeleteModalTitle}
          deleteModalContent={this.getDeleteModalContent}
          createUpdateModalTitle={this.getCreateUpdateModalTitle}
          createUpdateModalContent={this.getCreateUpdateModalContent.bind(this)}
          datatableConfig={{
            showPagination: true,
            showSearch: true,
            defaultSort: "email",
            sortType: 'desc',
            rowsPerPageOptions: [10, 25, 50],
          }}
          cellsConfig={[{
            datakey: "email",
            title: "Email",
            sortable: true,
            searchable: true,
          }
        ]}
        />
      </Grid>
    )
  }
}

EmailBloacagePlanning = withStyles(EmailBloacagePlanningCss)(EmailBloacagePlanning);

export default EmailBloacagePlanning
