import { Button, Dialog, DialogContent, DialogTitle, Typography } from "@material-ui/core";
import { withStyles } from "@material-ui/core/styles";
import { GridOn } from "@material-ui/icons";
import React, { Component } from "react";
import { connect } from "react-redux";
import { collectionActions } from "../../../reducers/collectionsReducer";
import StockCss from "./css/StockCss";
import ProduitTransforme from "./ProduitTransforme";

class BigBagsAValider extends Component {
  state = {
    openModal: false,
    href: null
  };

  componentDidMount() {
    this.refreshList()
  }

  refreshList()
  {
    collectionActions(
      this.props.dispatch,
      "stock/produits_transformes",
      "INDEX",
      { params: { to_validate: true } }
    );
  }

  render() {
    const { classes } = this.props;
    const { openModal } = this.state;
    // Récupérer les bigbags à valider pour les passer en props au composant <ProduitTransforme/>
    // Rajouter une props permettant de définir qu'on est sur l'écran des "bigbags à valider"
    return (
      <>
        <Button
            title={"Export des Produits Transformés"}
            variant="contained"
            color="primary"
            className={classes.buttonExport}
            onClick={() => this.setState({ openModal: true, href: process.env.REACT_APP_API_URL + "/produits_transformes/export" })}
          >
            <GridOn className={classes.file_ico} />
            Produits transformés
        </Button>
        <Dialog
          open={openModal}
          onClose={() => {
            this.setState({ openModal: false, href: null });
          }}
        >
          <DialogTitle key="title" id="alert-dialog-slide-title">
            <Typography>Export</Typography>
          </DialogTitle>
          <DialogContent key="content">
            <Button className={classes.btn} target="_blank" color="primary"  href={this.state.href + '?periode=n-2'}>Export N -2</Button>
            <Button className={classes.btn} target="_blank" color="secondary" href={this.state.href} >Export Complet</Button>
          </DialogContent>
        </Dialog>
        <ProduitTransforme
          datas={this.props.produitsTransformesStore.list}
          validate_bb={true}
          refreshList={this.refreshList.bind(this)}
          allowFromBBEntame={true}
        />
      </>
    );
  }
}

BigBagsAValider = withStyles(StockCss)(BigBagsAValider);

BigBagsAValider = connect((store) => {
  return {
    produitsTransformesStore: store.collections["stock/produits_transformes"],
  };
})(BigBagsAValider);

export default BigBagsAValider;
