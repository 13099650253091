import React, { Component } from "react";
import PropTypes from "prop-types";

import { FormControlLabel, Grid, Radio, RadioGroup, Typography } from "@material-ui/core";
import { withStyles } from "@material-ui/core/styles";
import { Place } from "@material-ui/icons";

import CollectionCrud from "../../common/Components/CollectionCrud";
import TextInput from "../../common/Components/TextInput";

import EtablissementCss from "./css/EtablissementCss";
import CheckboxInput from "../../common/Components/CheckboxInput";

class Etablissement extends Component {
  static defaultProps = {
    delete: true,
    pagination: true,
    search: true,
  };

  static propTypes = {
    actionsCallback: PropTypes.func,
    entrepriseUuid: PropTypes.string,
    principal: PropTypes.bool,
    etablissementNom: PropTypes.string,
    delete: PropTypes.bool,
    modal: PropTypes.object,
    pagination: PropTypes.bool,
    search: PropTypes.bool,
  };

  getDeleteModalTitle(etablissement) {
    return (
      <div>
        <Place />
        <span>{"Suppression du site"}</span>
      </div>
    );
  }

  getDeleteModalContent() {
    return (
      <Typography>{"Êtes-vous sûr de vouloir supprimer ce site ?"}</Typography>
    );
  }

  getCreateUpdateModalTitle(etablissement) {
    let title = !!etablissement
      ? "Modification du site : " + etablissement.nom
      : "Ajout d'un nouveau site";
    return (
      <div>
        <Place />
        <span>{title}</span>
      </div>
    );
  }

  getCreateUpdateModalContent(
    etablissement,
    etablissementsStore,
    onChangeHandler,
    initialize
  ) {
    const { etablissementNom } = this.props;
    let {
      nom,
      adresse,
      code_postal,
      ville,
      pays_uuid,
      telephone,
      horaires,
      commentaires,
      principal,
      display_into_planning,
      code_r,
    } = etablissement;

    if (!nom) {
      initialize({ nom: etablissementNom });
    }
    return (
      <Grid container>
        <Grid item xs={12}>
          <TextInput
            id="nom"
            label="Nom"
            value={!!nom ? nom : etablissementNom}
            required={true}
            margin="normal"
            collectionStore={etablissementsStore}
            name="nom"
            onChangeHandler={onChangeHandler}
            fullWidth
          />
        </Grid>
        <Grid item xs={12}>
          <TextInput
            id="adresse"
            label="Adresse"
            value={adresse}
            required={true}
            margin="normal"
            collectionStore={etablissementsStore}
            name="adresse"
            onChangeHandler={onChangeHandler}
            fullWidth
          />
        </Grid>
        <Grid item xs={6}>
          <TextInput
            id="code_postal"
            label="Code postal"
            value={code_postal}
            required={true}
            margin="normal"
            collectionStore={etablissementsStore}
            name="code_postal"
            onChangeHandler={onChangeHandler}
            fullWidth
          />
        </Grid>
        <Grid item xs={6}>
          <TextInput
            id="ville"
            label="Ville"
            value={!!ville ? ville.toUpperCase() : ""}
            required={true}
            margin="normal"
            collectionStore={etablissementsStore}
            name="ville"
            onChangeHandler={onChangeHandler}
            fullWidth
          />
        </Grid>
        <Grid item xs={6}>
          <TextInput
            id="telephone"
            label="Téléphone"
            value={telephone}
            margin="normal"
            collectionStore={etablissementsStore}
            name="telephone"
            onChangeHandler={onChangeHandler}
            fullWidth
          />
        </Grid>
        <Grid item xs={6}>
          <TextInput
            id="pays_uuid"
            label="Pays"
            value={pays_uuid}
            required={true}
            margin="normal"
            collectionStore={etablissementsStore}
            name="pays_uuid"
            onChangeHandler={onChangeHandler}
            type="autocomplete"
            autocompleteProps={{
              collectionName: "pays",
            }}
            fullWidth
          />
        </Grid>
        <Grid item xs={12}>
          <TextInput
            id="horaires"
            label="Horaires"
            value={horaires}
            margin="normal"
            collectionStore={etablissementsStore}
            name="horaires"
            onChangeHandler={onChangeHandler}
            fullWidth
          />
        </Grid>
        <Grid item xs={12}>
          <TextInput
            id="commentaires"
            label="Commentaires"
            value={commentaires}
            margin="normal"
            collectionStore={etablissementsStore}
            name="commentaires"
            onChangeHandler={onChangeHandler}
            fullWidth
          />
        </Grid>
        <Grid item xs={6}>
          <CheckboxInput
            id="principal"
            label="Site Principal"
            value={principal}
            required={true}
            margin="normal"
            collectionStore={etablissementsStore}
            name="principal"
            onChangeHandler={onChangeHandler}
            fullWidth
          />
        </Grid>
        <Grid item xs={6}>
          <CheckboxInput
            id="principal"
            label="Rendre visible dans le planning interne"
            value={display_into_planning}
            required={true}
            margin="normal"
            collectionStore={etablissementsStore}
            name="display_into_planning"
            onChangeHandler={onChangeHandler}
            fullWidth
          />
        </Grid>
        <Grid item xs={12}>
        <RadioGroup
              defaultValue="R3"
              name="code_r"
              collectionStore={etablissementsStore}
              value={code_r ?? "R3"}
              onChange={(e) => {
                onChangeHandler("code_r",e)
              }}
            >
              <FormControlLabel
                value="R3"
                control={<Radio />}
                label="R3"
              />
              <FormControlLabel
                value="R12"
                control={<Radio />}
                label="R12"
              />
            </RadioGroup>
        </Grid>
      </Grid>
    );
  }

  getCrudTitle() {
    const { classes } = this.props;
    return (
      <Typography variant="button" gutterBottom className={classes.crudTitle}>
        Sites
      </Typography>
    );
  }

  getCellsConfig() {
    return [
      {
        datakey: "nom",
        title: "Nom",
        sortable: true,
        searchable: true,
        format: (obj, key) => {
          return obj[key] + (obj["principal"] ? " ⚫" : "");
        },
      },
      {
        datakey: "code_postal",
        title: "Code postal",
        sortable: true,
        searchable: true,
      },
      {
        datakey: "ville",
        title: "Ville",
        sortable: true,
        searchable: true,
        format: (obj, key) => {
          return !!obj[key] ? obj[key].toUpperCase() : "";
        },
      },
      {
        datakey: "telephone",
        title: "Téléphone",
        sortable: true,
        searchable: true,
      },
      {
        datakey: "commentaires",
        title: "Commentaires",
        sortable: true,
        searchable: true,
      },
      {
        datakey: "horaires",
        title: "Horaires",
        sortable: true,
        searchable: true,
        format: (e) =>
          !!e.horaires ? e.horaires : "Pas d'horaires renseignés",
      },
    ];
  }

  getExtraDataForm() {
    let { entrepriseUuid } = this.props;
    if (entrepriseUuid) {
      return {
        entreprise_uuid: entrepriseUuid,
      };
    }
    return {};
  }

  render() {
    const {
      modal,
      actionsCallback,
      edit,
      create,
      deleteBtn,
      datas,
    } = this.props;
    return (
      <>
        <CollectionCrud
          collectionName={"etablissements"}
          datas={datas}
          loadDatas={!!datas ? false : true}
          showBtnEdit={this.props.hasOwnProperty("edit") ? edit : true}
          showBtnAdd={this.props.hasOwnProperty("create") ? create : true}
          rights={{
            create: "admin-cud-entreprises",
            edit: "admin-cud-entreprises",
            delete: "admin-cud-entreprises",
          }}
          showBtnDelete={
            this.props.hasOwnProperty("deleteBtn") ? deleteBtn : true
          }
          deleteModalTitle={this.getDeleteModalTitle}
          deleteModalContent={this.getDeleteModalContent.bind(this)}
          createUpdateModalTitle={this.getCreateUpdateModalTitle}
          createUpdateModalContent={this.getCreateUpdateModalContent.bind(this)}
          defaultValues={{
            principal: this.props.principal || false,
            display_into_planning: false,
          }}
          extradatasForm={this.getExtraDataForm()}
          datatableConfig={{
            showPagination: this.props.pagination,
            showSearch: this.props.search,
            defaultSort: "nom",
            sortType: "desc",
            nodatalabel: "Aucun site",
          }}
          dataTableExtraNodes={[
            {
              element: this.getCrudTitle(),
              position: "top-left",
            },
          ]}
          cellsConfig={this.getCellsConfig()}
          actionsCallback={actionsCallback}
          modal={modal}
        />
      </>
    );
  }
}

Etablissement = withStyles(EtablissementCss)(Etablissement);

export default Etablissement;
