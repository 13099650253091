import Affaire from '../../Affaire'
import AffaireDetail from '../../Affaire/Detail'
import Article from '../../Article'
import Export from '../../Export'
import Contact from '../../Contact'
import Note from '../../Note'
import Entreprise from '../../Entreprise'
import EntrepriseDetail from '../../Entreprise/Detail'
import Parametres from '../../Parametres'
import PSLists from '../../PSLists'
import PackinglistDetail from '../../Packinglist/detail.js'
import Facturation from '../../Facturation'
import Planification from '../../Planification'
import Commercial from '../../Commercial'

import HomeAdmin from "../../Home";
import AuthAdmin from "../../Auth";
import Stock from "../../Stock";
import Supervision from "../../Supervision";
import CampagneTaf from '../../CampagneTaf'
import Photo from '../../Photo'

import ArticleRegroupement from '../../ArticleRegroupement'
import ZoneBB from '../../ZoneBB/index.js'
import MiseADisposition from '../../MiseADisposition/index.js'
import NonConformite from '../../NonConformite/index.js'

export const routes = [
  {
    path: "/facturation",
    component: Facturation,
    exact: false,
    public: false,
    right: "admin-facturation",
  },
  {
    path: "/facturation/entrante/:entreprise_uuid",
    component: Facturation,
    exact: true,
    public: false,
    right: "admin-facturation",
  },
  {
    path: "/facturation/sortante/:entreprise_uuid",
    component: Facturation,
    exact: true,
    public: false,
    right: "admin-facturation",
  },
  {
    path: "/facturation/bordereau_achat/:entreprise_uuid",
    component: Facturation,
    exact: true,
    public: false,
    right: "admin-facturation",
  },
  {
    path: "/facturation/facturation_annexe/:entreprise_uuid",
    component: Facturation,
    exact: true,
    public: false,
    right: "admin-facturation",
  },
  {
    path: "/facturation/proforma/:entreprise_uuid",
    component: Facturation,
    exact: true,
    public: false,
    right: "admin-facturation",
  },
  {
    path: "/exports",
    component: Export,
    exact: true,
    public: false,
    right: "admin-exports",
  },
  {
    path: "/affaires",
    component: Affaire,
    exact: true,
    public: false,
    right: "admin-cud-affaires",
  },
  {
    path: "/affaires/commerciaux",
    component: Affaire,
    exact: true,
    public: false,
    right: "admin-cud-affaires",
  },
  {
    path: "/affaires/logistique",
    component: Affaire,
    exact: true,
    public: false,
    right: "admin-cud-affaires",
  },
  {
    path: "/affaires/supprimees",
    component: Affaire,
    exact: true,
    public: false,
    right: "admin-cud-affaires-supprimees",
  },
  {
    path: "/affaires/liste/:affaires",
    component: Affaire,
    exact: true,
    public: false,
    right: "admin-cud-affaires",
  },
  {
    path: "/affaires/:uuid",
    component: AffaireDetail,
    exact: true,
    public: false,
    right: "admin-cud-affaires",
  },
  {
    path: "/articles",
    component: Article,
    exact: true,
    public: false,
    rights: ["admin-cud-articles", "admin-read-articles"],
  },
  {
    path: "/contacts",
    component: Contact,
    exact: true,
    public: false,
    right: "admin-cud-contacts",
  },
  {
    path: "/entreprises",
    component: Entreprise,
    exact: true,
    public: false,
    right: "admin-cud-entreprises",
  },
  {
    path: "/entreprises/:uuid",
    component: EntrepriseDetail,
    exact: true,
    public: false,
    rights: ["admin-cud-entreprises", "admin-cud-affaires"],
  },
  {
    path: "/parametres",
    component: Parametres,
    exact: true,
    public: false,
    right: "admin-cud-parametres",
  },
  {
    path: "/auth",
    component: AuthAdmin,
    exact: false,
    public: true,
    noContainer: true,
  },
  {
    path: "/",
    component: HomeAdmin,
    exact: true,
    public: false,
    right: "admin-home",
  },
  {
    path: "/planification",
    component: Planification,
    exact: true,
    public: false,
    right: "admin-planification-production",
    topBarVisible: true,
  },
  {
    path: "/stock/produits_transformes",
    component: Stock,
    exact: true,
    public: false,
    rights: ["admin-stock", "admin-c-bigbags", 'stock-pdttransf-lecture-seulement'],
    topBarVisible: true,
  },
  {
    path: "/stock/matieres_premieres",
    component: Stock,
    exact: true,
    public: false,
    right: "admin-stock",
    topBarVisible: true,
  },
  {
    path: "/stock/validation_bigbags",
    component: Stock,
    exact: true,
    public: false,
    right: "admin-stock",
    topBarVisible: true,
  },
  {
    path: "/stock/produits_transformes/out_of_stock",
    component: Stock,
    exact: true,
    public: false,
    right: "admin-stock",
    topBarVisible: true,
  },
  {
    path: '/pslists/packinglists',
    component: PSLists,
    exact: true,
    public: false,
    right: 'admin-cud-packinglists',
    topBarVisible: true,
  },
  {
    path: '/pslists/packinglists/:uuid',
    component: PackinglistDetail,
    exact: true,
    public: false,
    right: 'admin-cud-packinglists',
    topBarVisible: true,
  },
  {
    path: '/pslists/silolists',
    component: PSLists,
    exact: true,
    public: false,
    right: 'admin-cud-silolists',
    topBarVisible: true,
  },
  {
    path: "/supervision",
    component: Supervision,
    exact: true,
    public: false,
    right: "admin-supervision",
    topBarVisible: true,
  },
  {
    path: "/commercial",
    component: Commercial,
    exact: true,
    public: false,
    right: "admin-commercial",
    topBarVisible: true,
  },
  {
    path: "/notes",
    component: Note,
    exact: true,
    public: false,
    right: "admin-commercial",
    topBarVisible: true,
  },
  {
    path: "/campagne-tafs",
    component: CampagneTaf,
    exact: true,
    public: false,
    right: "admin-crud-campagne-tafs",
    topBarVisible: true,
  },
  {
    path: "/photo",
    component: Photo,
    exact: true,
    public: false,
    right: "admin-prise-de-photo",
    topBarVisible: true,
  },
  {
    path: "/articles-regroupement",
    component: ArticleRegroupement,
    exact: true,
    public: false,
    right: "admin-articles-regroupement",
    topBarVisible: true,
  },
  {
    path: "/zones-bb",
    component: ZoneBB,
    exact: true,
    public: false,
    right: "admin-ru-cellule-bb",
    topBarVisible: true,
  },
  {
    path: "/zones-bb/:tab",
    component: ZoneBB,
    exact: true,
    public: false,
    right: "admin-ru-cellule-bb",
    topBarVisible: true,
  },
  {
    path: "/mise-a-dispositions",
    component: MiseADisposition,
    exact: true,
    public: false,
    right: "admin-crud-mises-a-disposition",
    topBarVisible: true,
  },
  {
    path: "/non-conformites",
    component: NonConformite,
    exact: true,
    public: false,
    rights: ["admin-nc-creation","admin-nc-suppression","admin-nc-validation"],
    topBarVisible: true,
  },
  {
    path: "/non-conformites/externes",
    component: NonConformite,
    exact: true,
    public: false,
    rights: ["admin-nc-creation","admin-nc-suppression","admin-nc-validation"],

    topBarVisible: true,
  },
  {
    path: "/non-conformites/internes",
    component: NonConformite,
    exact: true,
    public: false,
    rights: ["admin-nc-creation","admin-nc-suppression","admin-nc-validation"],

  }

];
