import React, {Component} from "react";
import {Grid, IconButton, withStyles} from "@material-ui/core";
import PlanificationCss from "./css/PlanificationCss";
import KeyboardArrowLeft from '@material-ui/icons/KeyboardArrowLeft';
import KeyboardArrowRight from '@material-ui/icons/KeyboardArrowRight';
import DateInput from "../../common/Components/DateInput";
import clsx from "clsx";
import isValid from "date-fns/isValid";
import format from "date-fns/format";
import isSameDay from "date-fns/isSameDay";
import subDays from "date-fns/subDays";
import addDays from "date-fns/addDays";
import startOfWeek from "date-fns/startOfWeek";
import endOfWeek from "date-fns/endOfWeek";
import isWithinInterval from "date-fns/isWithinInterval";
import {cloneDate} from '../../tools/Tools';
import frLocale from 'date-fns/locale/fr';
import PropTypes from "prop-types";

class Selecteur extends Component {
  /**
   * Permet de sélectionner une semaine complète via le datePicker
   */
  renderWrappedWeekDay = (date, selectedDate, dayInCurrentMonth) => {
    const {classes} = this.props;
    let dateClone = cloneDate(date);
    let selectedDateClone = cloneDate(selectedDate);

    const start = startOfWeek(selectedDateClone, {weekStartsOn: 1});
    const end = endOfWeek(selectedDateClone, {weekStartsOn: 1});

    const dayIsBetween = isWithinInterval(dateClone, {start, end});
    const isFirstDay = isSameDay(dateClone, start);
    const isLastDay = isSameDay(dateClone, end);

    const wrapperClassName = clsx({
      [classes.highlight]: dayIsBetween,
      [classes.firstHighlight]: isFirstDay,
      [classes.endHighlight]: isLastDay,
    });

    const dayClassName = clsx(classes.day, {
      [classes.nonCurrentMonthDay]: !dayInCurrentMonth,
      [classes.highlightNonCurrentMonthDay]: !dayInCurrentMonth && dayIsBetween,
    });

    return (
      <div className={wrapperClassName}>
        <IconButton className={dayClassName}>
          <span> {format(dateClone, "d")} </span>
        </IconButton>
      </div>
    )
  };

  /**
   * Formatage du libelle pour le datePicker (un peu particulier car on sélectionne une semaine)
   */
  formatWeekSelectLabel = (date, invalidLabel) => {
    let dateClone = cloneDate(date);

    if(this.props.forModulePhoto){
      return dateClone && isValid(dateClone)
        ? `${format(dateClone, "d MMMM", {locale: frLocale})}`
        : invalidLabel;
    }


    return dateClone && isValid(dateClone)
      ? `Semaine du ${format(startOfWeek(dateClone, {weekStartsOn: 1}), "d MMMM", {locale: frLocale})}`
      : invalidLabel;
  };

  getTopBar() {
    const {classes, forModulePhoto} = this.props;
    let selectedDate = this.props.selectedDate;

    return (
      <Grid item xs={12} className={classes.selectBar}>
        <IconButton
          className={classes.leftDatebutton}
          color="primary"
          onClick={() => {
             // si on est en mode module photo, on ne recule que d'un jour et une semaine sinon
            let date =  forModulePhoto ?  subDays(selectedDate, 1) : startOfWeek(subDays(selectedDate, 7), {weekStartsOn: 1});
            this.props.callback(date)
          }}
        >
          <KeyboardArrowLeft/>
        </IconButton>
        <DateInput
          id="selectedDate"
          label={forModulePhoto ? "Journée du" : "Période"}
          format={'WW yyyy'}
          value={this.props.selectedDate}
          margin="normal"
          collectionStore={{}}
          name='selectedDate'
          onChangeHandler={(name, e) => {
            // si on est en mode module photo, on considère la semaine ne durant qu'un jour donc elle commence le jour selectionné
            let date = forModulePhoto ? startOfWeek(cloneDate(e.target.value), {weekStartsOn: cloneDate(e.target.value).getDay()}) : startOfWeek(cloneDate(e.target.value), {weekStartsOn: 1});
            this.props.callback(date)
          }}
          renderDay={forModulePhoto ? null: this.renderWrappedWeekDay}
          labelFunc={this.formatWeekSelectLabel}
          clearable={false}
          className={classes.selectedDate}
        />
        <IconButton
          className={classes.rightDatebutton}
          color="primary"
          onClick={() => {
                 // si on est en mode module photo, on ne recule que d'un jour et une semaine sinon
                 let date =  forModulePhoto ? addDays(selectedDate,1) : startOfWeek(addDays(selectedDate, 7), {weekStartsOn: 1});
            this.props.callback(date)
          }}
        >
          <KeyboardArrowRight/>
        </IconButton>
      </Grid>
    );
  }

  render() {
    return this.getTopBar()
  }
}

Selecteur.propTypes = {
  selectedDate: PropTypes.instanceOf(Date).isRequired
};

Selecteur = withStyles(PlanificationCss)(Selecteur);

export default Selecteur
