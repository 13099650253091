export default (theme) => {
  return {
    section: {
      marginBottom: "3em",
    },
    sectionTitle: {
      marginBottom: "1em",
      color: theme.palette.primary.main,
      position: 'absolute',
      left: '2rem',
    },
    button: {
      float: 'right',
      padding: 12
    },
    dragrable: {
      maxWidth: '90%!important',
      margin: '0 auto',
      border: 'dashed 2px ' + theme.palette.primary.main+'!important',
    },
    imageApercu: {
      width: 'auto',
      height: '200px',
      maxHeight: '150px',
    },
    fullSize: {
      width: "800px",
      height: '800px',
      margin: 'auto',
      objectFit: "scale-down",
    },
    imageApercuContainer: {
      textAlign: 'center',
      position: 'relative',
    },
    document: {
      cursor: 'pointer',
      border : '1px solid #ccc',
      padding:'5px',
      borderRadius: '5px',
      position: 'relative',
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      width: '20%',
      minHeight: '5rem',
      height: '150px',
    },
    hover:{
      position: 'absolute',
      cursor: 'pointer',
      top: 0,
      left: 0,
      width: '100%',
      height: '100%',
    },
    documentsTitleContainer:{
      display: 'flex',
      justifyContent: 'end',
      alignItems: 'center',
      flexDirection: 'row',
    },
    pointer:{
      cursor: 'pointer',
    },
  };
};
