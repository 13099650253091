import {withStyles} from '@material-ui/core/styles'
import PropTypes from 'prop-types'
import {connect} from 'react-redux'
import {collectionActions, loadCollectionAttribute} from '../../../../reducers/collectionsReducer'
import TextInput from '../TextInput'
import ArticleSelectCss from './css/ArticleSelectCss'

import {Grid, MenuItem} from '@material-ui/core'
import React, {Component} from 'react'

class ArticleSelect extends Component {

  static propTypes = {
    onChangeHandler: PropTypes.func,
    store: PropTypes.object,
    famille_uuid: PropTypes.string,
    article_uuid: PropTypes.string,
  }

  state = {
    famille_uuid: this.props.famille_uuid ? this.props.famille_uuid : null,
    article_uuid: this.props.article_uuid ? this.props.article_uuid : null,
    dismountArticle: false
  }

  componentDidMount() {
    collectionActions(
      this.props.dispatch,
      'familles',
      'INDEX',
    )
    loadCollectionAttribute(
      this.props.dispatch,
      'list',
      'articles',
      this.props.articlesStore
    )
  }

  getFamilles() {
    if (!this.props.famillesStore.list) {
      return [];
    }
    return this.props.famillesStore.list.map(famille => {
      return (
        <MenuItem value={famille.uuid} key={famille.uuid}>
          {famille.nom}
        </MenuItem>
      )
    })
  }

  getArticles(famille_uuid) {
    if (!this.props.articlesStore.list) {
      return [];
    }
    let list = this.props.articlesStore.list.slice()  // shallow copy
    if (famille_uuid) {
      list = list.filter(
        a => a.famille_uuid === famille_uuid
      )
    }
    return list.map(article => {
      return (
        <MenuItem value={article.uuid} key={article.uuid}>
          {article.nom}
        </MenuItem>
      )
    })
  }

  onChangeHandler(name, e) {
    const {onChangeHandler} = this.props


    if(name === 'article_uuid') {
      onChangeHandler(name, e)
    }else if(name === 'famille_uuid' && this.state.famille_uuid !== e.target.value) {
      this.setState({
        article_uuid: null,
        dismountArticle: true
      })
      onChangeHandler('article_uuid', {target: {value: null}})

    }
    this.setState({
      [name]: e.target.value
    })

  }

  getArticleAutocommplete()
  {
    const {
      classes,
      store,
    } = this.props

    const {
      article_uuid,
      famille_uuid,
      dismountArticle
    } = this.state

    if(dismountArticle) {
      setTimeout(() => {
        this.setState({
          dismountArticle: false
        })
      }, 0)
      return null
    }

    return (
      <TextInput
            label="Article"
            disabled={!famille_uuid}
            value={article_uuid}
            required={true}
            margin="normal"
            collectionStore={store}
            name="article_uuid"
            onChangeHandler={this.onChangeHandler.bind(this)}
            type="autocomplete"
            className={classes.selectContainer}
            autocompleteProps={{
              collectionName: "articles",
              params: {
                famille_uuid: famille_uuid
              }
            }}
          >
            {this.getArticles(famille_uuid)}
          </TextInput>
    )
  }

  render() {
    const {
      classes,
      store,
      gridFamilleXs,
      gridArticleXs,
      familleStyle
    } = this.props

    const {
      famille_uuid
    } = this.state

    return (
      <>
        <Grid item xs={gridFamilleXs || 6}>
          <TextInput
            id="famille_uuid"
            label="Famille"
            value={famille_uuid}
            required={true}
            margin="normal"
            collectionStore={store}
            name='famille_uuid'
            onChangeHandler={this.onChangeHandler.bind(this)}
            className={classes.selectContainer}
            select
            style={familleStyle}
          >
            {this.getFamilles()}
          </TextInput>
        </Grid>
        <Grid item xs={gridArticleXs || 12}>
          {this.getArticleAutocommplete()}
        </Grid>
      </>
    )
  }
}
ArticleSelect = connect((store) => {
  return {
    famillesStore: store.collections.familles,
    articlesStore: store.collections.articles,
  }
})(ArticleSelect)

export default withStyles(ArticleSelectCss)(ArticleSelect)
