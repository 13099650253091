import React, {Component} from 'react'
import {connect} from 'react-redux'
import {withStyles} from '@material-ui/core/styles'
import {Grid} from '@material-ui/core';

import Note from '../Note'

import CommercialCss from './css/CommercialCss'

class Commercial extends Component {
  state={
    selectedTab: 0,
  }

  // Handler sur le changement d'onglet
  handleChangeTab(event, selectedTab) {
    this.setState({ selectedTab });
  }

  /*
   * Rendu Final
   */
  render() {
    const {classes} = this.props
    return (
      <Grid container className={classes.container}>
         <Note/>
      </Grid>
    )
  }
}

Commercial = withStyles(CommercialCss)(Commercial);
Commercial = connect((store) => {
  return {
    notesStore: store.collections.notes
  }
})(Commercial);

export default Commercial
