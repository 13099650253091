import {assetsForCss} from '../../../tools/Tools';

export default theme => ({
  loginContainer: {
    background: 'url(' + assetsForCss('/img/cap-eco-background.jpg') + ')',
    backgroundSize: 'cover',
    height: '100vh',
    width: '100%'
  },
  paper: {
    padding: '2em'
  },
  title: {
    textAlign: 'center',
    marginTop: theme.spacing(2)
  },
  avatar: {
    backgroundColor: '#26323800',
    borderRadius: '5px',
    marginLeft: 'auto',
    marginRight: 'auto',
    height: '5em',
    width: '7em'
  },
  textField: {
    width: '100%'
  },
  buttonsLign: {
    marginTop: '3em'
  },
  button: {
    width: '100%',
    marginBottom: theme.spacing(1)
  },
  msg2FA: {
    marginTop: '1em',
    marginBottom: '2em',
    maxWidth: '26em',
    textAlign: 'center',
  },
  container2FA: {
    textAlign: 'center',
  },
  input2FA: {
    width: '2ch',
    padding: '8px',
    borderRadius: '8px',
    fontSize: '40px',
    textAlign: 'center',
    marginRight: '12px',
    border: '1px solid grey',
    textTransform: 'uppercase',
    outlineColor: theme.palette.primary.main,
  },
})
