import React, { Component } from "react";

import { withStyles } from "@material-ui/core/styles";
import ExportTransporteurGazoilCss from "./css/ExportTransporteurGazoilCss.js";
import { Button, DialogContent, DialogTitle, Grid } from "@material-ui/core";
import { TrackChangesOutlined } from "@material-ui/icons";
import Modal from "../../common/Components/Modal/index.js";
import moment from "moment";
import DateInput from "../../common/Components/DateInput/index.js";
import TextInput from "../../common/Components/TextInput/index.js";

class ExportTransporteurGazoil extends Component {
  state = {
    openModal: false,
    date_debut: moment().startOf("month"),
    date_fin: moment().endOf("month"),
    transporteur_uuid: null,
  };

  onChangeHandler(name, e) {
    this.setState({ [name]: e.target.value });
  }

  onSubmitHandler() {
    const uri =
      process.env.REACT_APP_API_URL + "/entreprises/export_transporteur_gazoil";
    const { date_debut, date_fin, transporteur_uuid } = this.state;
    const data = {
      date_debut: moment(date_debut).format("YYYY-MM-DD"),
      date_fin: moment(date_fin).format("YYYY-MM-DD"),
      transporteur_uuid: transporteur_uuid,
    };
    const search_params = new URLSearchParams(data).toString();
    const url = uri + "?" + search_params;
    window.open(url, "_blank");
    this.setState({ openModal: false });
  }

  render() {
    const { classes } = this.props;
    const { openModal, date_debut, date_fin, transporteur_uuid } = this.state;
    return (
      <>
        <Button
          title={"Export Facturation transporteur"}
          variant="contained"
          color="primary"
          className={classes.button}
          onClick={() => this.setState({ openModal: true })}
        >
          <TrackChangesOutlined className={classes.file_ico} />
          Facturation transporteur
        </Button>
        <Modal
          fullWidth={true}
          maxWidth="sm"
          onCloseHandler={() => this.setState({ openModal: false })}
          onSubmitHandler={this.onSubmitHandler.bind(this)}
          openModal={openModal}
          disabledEnter
        >
          <DialogTitle key="title">
            <TrackChangesOutlined className={classes.titleIcon} />
            <span>Sélection transporteur / dates</span>
          </DialogTitle>
          <DialogContent>
            <Grid container spacing={2}>
              <Grid item xs={12}>
                <TextInput
                  id="transporteur_uuid"
                  label="Fournisseur"
                  className={classes.selectContainer}
                  value={transporteur_uuid}
                  margin="normal"
                  collectionStore={{}}
                  name="transporteur_uuid"
                  onChangeHandler={this.onChangeHandler.bind(this)}
                  type="autocomplete"
                  autocompleteProps={{
                    collectionName: "entreprises",
                  }}
                  fullWidth
                />
              </Grid>
              <Grid item xs={12}>
                <DateInput
                  id="date_debut"
                  label="Date de début d'export"
                  format="dd/MM/yyyy"
                  value={date_debut}
                  collectionStore={{}}
                  margin="normal"
                  name="date_debut"
                  onChangeHandler={this.onChangeHandler.bind(this)}
                  fullWidth
                />
                <DateInput
                  id="date_fin"
                  label="Date de fin d'export"
                  format="dd/MM/yyyy"
                  value={date_fin}
                  collectionStore={{}}
                  margin="normal"
                  name="date_fin"
                  onChangeHandler={this.onChangeHandler.bind(this)}
                  fullWidth
                />
              </Grid>
            </Grid>
          </DialogContent>
        </Modal>
      </>
    );
  }
}

ExportTransporteurGazoil = withStyles(ExportTransporteurGazoilCss)(
  ExportTransporteurGazoil
);

export default ExportTransporteurGazoil;
