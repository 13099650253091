import React, { Component } from "react";

import { connect } from "react-redux";
import { Grid, IconButton, MenuItem, TextField, Typography } from "@material-ui/core";
import { withStyles } from "@material-ui/core/styles";
import { Gavel, VerticalAlignBottom } from "@material-ui/icons";

import CollectionCrud from "../../common/Components/CollectionCrud";
import CheckboxInput from "../../common/Components/CheckboxInput";
import TextInput from "../../common/Components/TextInput";
import NumberInput from "../../common/Components/NumberInput";

import CampagneTafCss from "./css/CampagneTafCss";
import { collectionActions } from "../../../reducers/collectionsReducer";

class CampagneTaf extends Component {

  state = {
    current_filter: {},
    open_filter: "true",
  };

  getDeleteModalTitle() {
    return (
      <div>
        <Gavel />
        <span>{"Suppression de la campagne"}</span>
      </div>
    );
  }

  getDeleteModalContent() {
    return (
      <Typography>
        {"Êtes-vous sûr de vouloir supprimer cette campagne ?"}
      </Typography>
    );
  }

  getCreateUpdateModalTitle(campagne) {
    let title = !!campagne
      ? "Modification de la campagne TAF : " + campagne.numero
      : "Ajout une nouvelle campagne TAF";
    return (
      <div>
        <Gavel />
        <span>{title}</span>
      </div>
    );
  }

  getTypeFacturation() {
    return [
      <MenuItem key={"sortante"} value="sortante" >Sortante</MenuItem>,
      <MenuItem key={"entrante"} value="entrante" >Entrante</MenuItem>,
    ];
  }

  getCreateUpdateModalContent(campagne, campagneStore, onChangeHandler) {
    let {
      nom,
      type_facturation,
      prix_taf_tonne,
      etablissement_uuid,
      description,
      is_open,
      tonnage_perte,
    } = campagne;
    const { classes } = this.props;
    return (
      <Grid container>
        <Grid item xs={12}>
          <TextInput
            id="nom"
            label="Nom"
            value={nom}
            required={true}
            margin="normal"
            collectionStore={campagneStore}
            name="nom"
            onChangeHandler={onChangeHandler}
            fullWidth
          />
        </Grid>
        <Grid item xs={12}>
          <TextInput
            id="etablissement_uuid"
            label="Taffeur"
            value={etablissement_uuid}
            required={true}
            margin="normal"
            collectionStore={campagneStore}
            name="etablissement_uuid"
            onChangeHandler={onChangeHandler}
            type="autocomplete"
            autocompleteProps={{
              collectionName: "etablissements",
            }}
            fullWidth
          />
        </Grid>
        <Grid item xs={6}>
          <TextInput
            id="type_facturation"
            label="Type de facturation"
            value={type_facturation}
            margin="normal"
            collectionStore={campagneStore}
            name="type_facturation"
            onChangeHandler={onChangeHandler}
            className={classes.selectContainer}
            select
            fullWidth
          >
            {this.getTypeFacturation()}
          </TextInput>
        </Grid>

        <Grid item xs={6}>
          <NumberInput
            id="prix_taf_tonne"
            label="Prix TAF / Tonne"
            value={prix_taf_tonne}
            margin="normal"
            collectionStore={campagneStore}
            name="prix_taf_tonne"
            onChangeHandler={onChangeHandler}
            fullWidth
          />
        </Grid>

        <Grid container xs={12} style={{lineHeight:'2rem'}}>
          <Grid item xs={4}>Tonnage In : {campagne.total_tonnage_in ?? 0}</Grid>
          <Grid item xs={4}>Tonnage Out : {campagne.total_tonnage_out ?? 0}</Grid>
          <Grid item xs={4}>Tonnage Solde : {campagne.solde_tonnage ?? 0}</Grid>
        </Grid>
        <Grid item xs={6}>
          <NumberInput
            id="tonnage_perte"
            label="Perte en TAF (+ perte / - gain)"
            value={tonnage_perte}
            margin="normal"
            collectionStore={campagneStore}
            name="tonnage_perte"
            onChangeHandler={(name,e) => {
              campagne.solde_tonnage = Math.round((campagne.total_tonnage_in - campagne.total_tonnage_out - parseFloat(e.target.value)) *100) / 100;
              onChangeHandler(name,e);
            }}
            fullWidth
          />
        </Grid>
        <Grid item xs={12}>
          <TextInput
            id="description"
            label="Description"
            value={description}
            required={true}
            margin="normal"
            collectionStore={campagneStore}
            name="description"
            onChangeHandler={onChangeHandler}
            multiline
            rows={4}
            fullWidth
          />
        </Grid>
        <Grid item xs={6}>
          <CheckboxInput
            id="is_open"
            label="Ouverte"
            value={is_open}
            margin="normal"
            name="is_open"
            onChangeHandler={onChangeHandler}
          />
        </Grid>
      </Grid>
    );
  }

  getCellsConfig() {
    let cells = [
      {
        datakey: "numero",
        title: "Numéro",
        sortable: true,
        searchable: true,
      },
      {
        datakey: "nom",
        title: "Nom",
        sortable: true,
        searchable: true,
      },
      {
        datakey: "entreprise_nom",
        title: "Entreprise",
        sortable: true,
        searchable: true,
      },
      {
        datakey: "site_nom",
        title: "Site",
        sortable: true,
        searchable: true,
      },
      {
        datakey: "type_facturation",
        title: "Facturation",
        sortable: true,
        searchable: true,
        format: (obj,key) => {
          return obj[key] === "sortante" ? "Sortante" : "Entrante";
        }
      },
      {
        datakey: "prix_taf_tonne",
        title: "Prix TAF/T",
        sortable: true,
        searchable: true,
      },
      {
        datakey: "total_tonnage_in",
        title: "Tonnage In",
        sortable: false,
        searchable: false,
      },
      {
        datakey: "total_tonnage_out",
        title: "Tonnage Out",
        sortable: false,
        searchable: false,
      },
      {
        datakey: "tonnage_perte",
        title: "Perte",
        sortable: false,
        searchable: false,
      },
      {
        datakey: "solde_tonnage",
        title: "Solde",
        sortable: false,
        searchable: false,
      },
      {
        datakey: "description",
        title: "Description",
        sortable: true,
        searchable: true,
        format: (obj,key) => {
          const text = obj[key] ? obj[key].substring(0,100) : "";
          return text + (text.length === 100 ? "..." : "");
        }
      },
      {
        datakey: "is_open",
        title: "Ouverte",
        sortable: true,
        searchable: true,
        format: (obj,key) => {
          return obj[key] ? "Oui" : "Non";
        },
      },
    ];

    return cells;
  }

  loadAsyncData(filters) {
    // eslint-disable-next-line
    if (filters !== undefined) this.state.current_filter = filters;

    return collectionActions(
      this.props.dispatch,
      "campagne-tafs",
      "INDEX",
      {
        params: {
          ...this.state.current_filter,
          open_filter: this.getFilterValues(this.state.open_filter),
        },
      },
      null,
      true
    );
  }

  getFilterValues(filter) {
    if(filter === 'all') return null;
    if(Array.isArray(filter) && filter.includes('all')) return null;

    return filter;
  }

  getOpenFilter() {
    const { classes } = this.props;

    const typesAffaires = [
      {
        slug: "all",
        libelle: "Tous",
      },
      {
        slug: "true",
        libelle: "Oui",
      },
      {
        slug: "false",
        libelle: "Non",
      },
    ];


    const options = typesAffaires.map((typeAffaire) => {
      return (
        <MenuItem value={typeAffaire.slug} key={typeAffaire.slug}>
          {typeAffaire.libelle}
        </MenuItem>
      );
    });

    return (
      <TextField
        id="open_filter"
        label="Ouverte"
        margin="normal"
        onChange={this.onChangeHandlerFilters.bind(this, "open_filter")}
        className={classes.selectContainer}
        value={this.state.open_filter}
        select
      >
        {options}
      </TextField>
    );
  }

  onChangeHandlerFilters(name, e) {
    this.setState({
        [name]: e.target.value,
    }, () => {
      this.loadAsyncData(this.state.current_filter);
    });
  }

  getFilters() {
    const { classes } = this.props;

    return (
      <Grid container>
        <Grid item xs={12}>
          <Typography
            key="typo"
            variant="button"
            gutterBottom
            className={classes.crudTitle}
          >
            Filtres
          </Typography>
        </Grid>
        <Grid item xs={2}>
          {this.getOpenFilter()}
        </Grid>
      </Grid>
    );
  }

  getDownloadButton(campagneTaf) {
    const { classes } = this.props;

    return (
      <IconButton
        key={campagneTaf.uuid}
        title={"Télécharger le rapport de campagne"}
        variant="contained"
        color="primary"
        href={
          process.env.REACT_APP_API_URL +
          "/campagne-tafs/" +
          campagneTaf.uuid +
          "/rapport"
        }
        className={classes.button}
        target="_blank"
      >
        <VerticalAlignBottom />
      </IconButton>
    );
  }

  getCrudTitle() {
    const { classes } = this.props;
    return [
      <Typography
        key="typo"
        variant="button"
        gutterBottom
        className={classes.crudTitle}
      >
        Campagnes TAF
      </Typography>,
    ];
  }
  render() {
    return (
      <>
        {this.getFilters()}
        <CollectionCrud
          collectionName={"campagne-tafs"}
          asynchrone={true}
          asynchroneCallbackLoadData={this.loadAsyncData.bind(this)}
          getAsyncFilter={(filter) =>
            // eslint-disable-next-line
            (this.state.current_filter = filter)
          }
          additionnalControllers={[
            this.getDownloadButton.bind(this),
          ]}
          showBtnEdit={true}
          showBtnAdd={true}
          showBtnDelete={true}
          deleteModalTitle={this.getDeleteModalTitle}
          deleteModalContent={this.getDeleteModalContent.bind(this)}
          createUpdateModalTitle={this.getCreateUpdateModalTitle}
          createUpdateModalContent={this.getCreateUpdateModalContent.bind(this)}
          disabledEnterModal={true}
          datatableConfig={{
            showPagination: true,
            showSearch: true,
            defaultSort: "numero",
            sortType: "desc",
            nodatalabel: "Aucune Campagne TAF",
            rowsPerPageOptions: [25, 50],
          }}
          defaultValues={{
            is_open: true,
            tonnage_perte: 0,
          }}
          dataTableExtraNodes={[
            {
              element: this.getCrudTitle(),
              position: "top-left",
            },
          ]}
          cellsConfig={this.getCellsConfig()}
        />
      </>
    );
  }
}

CampagneTaf = withStyles(CampagneTafCss)(CampagneTaf);

CampagneTaf = connect((store) => {
  return {
    entreprisesStore: store.collections.entreprises,
  };
})(CampagneTaf);

export default CampagneTaf;
