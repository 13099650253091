import React, {Component} from 'react'

import {Grid, Typography} from '@material-ui/core'
import {withStyles} from '@material-ui/core/styles'
import {Settings} from '@material-ui/icons'

import TextInput from '../../common/Components/TextInput'
import CollectionCrud from '../../common/Components/CollectionCrud'
import LangueCss from './css/LangueCss'

class Langue extends Component {

  getDeleteModalTitle() {
    return (
      <div>
        <Settings/>
        <span>{"Suppression de la langue"}</span>
      </div>
    )
  }

  getDeleteModalContent() {
    return (
      <Typography>
        {'Êtes-vous sûr de vouloir supprimer cette langue ?'}
      </Typography>
    )
  }

  getCreateUpdateModalTitle(langue) {
    let title = !!langue
      ? "Modification de la langue : " + langue.nom
      : "Ajout d'une nouvelle langue"
    return (
      <div>
        <Settings/>
        <span>{title}</span>
      </div>
    )
  }

  getCreateUpdateModalContent(langue, languesStore, onChangeHandler) {
    let {nom} = langue;
    return (
      <Grid container>
        <Grid item xs={12}>
          <TextInput
            id="nom"
            label="Nom"
            value={nom}
            margin="normal"
            collectionStore={languesStore}
            name='nom'
            onChangeHandler={onChangeHandler}
            fullWidth
          />
        </Grid>
      </Grid>
    )
  }

  render() {
    const {classes} = this.props
    return (
      <Grid container className={classes.container}>
        <CollectionCrud
          collectionName={'langues'}
          showBtnEdit={true}
          showBtnAdd={true}
          showBtnDelete={true}
          rights={{
            create: 'admin-cud-parametres',
            edit: 'admin-cud-parametres',
            delete: 'admin-cud-parametres'
          }}
          deleteModalTitle={this.getDeleteModalTitle}
          deleteModalContent={this.getDeleteModalContent}
          createUpdateModalTitle={this.getCreateUpdateModalTitle}
          createUpdateModalContent={this.getCreateUpdateModalContent.bind(this)}
          datatableConfig={{
            showPagination: true,
            showSearch: true,
            defaultSort: "nom",
            sortType: 'desc',
            rowsPerPageOptions: [10, 25, 50],
          }}
          cellsConfig={[{
            datakey: "nom",
            title: "Nom",
            sortable: true,
            searchable: true,
          }]}
        />
      </Grid>
    )
  }
}

Langue = withStyles(LangueCss)(Langue);

export default Langue
