import React, {Component} from 'react'

import {Grid, MenuItem, Typography} from '@material-ui/core'
import {withStyles} from '@material-ui/core/styles'
import {Settings} from '@material-ui/icons'

import TextInput from '../../common/Components/TextInput'
import CollectionCrud from '../../common/Components/CollectionCrud'
import PaysCss from './css/PaysCss'
import { getZonesGeographique } from '../../../reducers/collectionsReducer'

class Pays extends Component {

  state = {
    zonesGeographique: []
  }

  getDeleteModalTitle() {
    return (
      <div>
        <Settings/>
        <span>{"Suppression du pays"}</span>
      </div>
    )
  }

  getDeleteModalContent() {
    return (
      <Typography>
        {'Êtes-vous sûr de vouloir supprimer ce pays ?'}
      </Typography>
    )
  }

  getCreateUpdateModalTitle(pays) {
    let title = !!pays
      ? "Modification du pays : " + pays.nom
      : "Ajout d'un nouveau pays"
    return (
      <div>
        <Settings/>
        <span>{title}</span>
      </div>
    )
  }


  componentDidMount(){
    getZonesGeographique().then(reponse => {
      this.setState({zonesGeographique: reponse.data})
    })
  }

  getZonesOptions(){
    return this.state.zonesGeographique.map(zone => {
      return (
        <MenuItem key={zone} value={zone}>{zone}</MenuItem>
      )
    })
  }

  getCreateUpdateModalContent(pays, paysStore, onChangeHandler) {
    let {nom, code, zone_geographique} = pays;

    return (
      <Grid container>
        <Grid item xs={12}>
          <TextInput
            id="nom"
            label="Nom"
            value={nom}
            margin="normal"
            collectionStore={paysStore}
            name='nom'
            onChangeHandler={onChangeHandler}
            fullWidth
          />
        </Grid>
        <Grid item xs={12}>
          <TextInput
            id="code"
            label="Code"
            value={code}
            margin="normal"
            collectionStore={paysStore}
            name='code'
            onChangeHandler={onChangeHandler}
            fullWidth
          />
        </Grid>
        <Grid item xs={12}>
          <TextInput
            id="zone-geographique"
            label="Zone géographique"
            value={zone_geographique}
            margin="normal"
            collectionStore={paysStore}
            name='zone_geographique'
            onChangeHandler={onChangeHandler}
            fullWidth
            select
          >
            {this.getZonesOptions()}
          </TextInput>
        </Grid>
      </Grid>
    )
  }

  render() {
    const {classes} = this.props
    return (
      <Grid container className={classes.container}>
        <CollectionCrud
          collectionName={'pays'}
          showBtnEdit={true}
          showBtnAdd={true}
          showBtnDelete={true}
          rights={{
            create: 'admin-cud-parametres',
            edit: 'admin-cud-parametres',
            delete: 'admin-cud-parametres'
          }}
          deleteModalTitle={this.getDeleteModalTitle}
          deleteModalContent={this.getDeleteModalContent}
          createUpdateModalTitle={this.getCreateUpdateModalTitle}
          createUpdateModalContent={this.getCreateUpdateModalContent.bind(this)}
          datatableConfig={{
            showPagination: true,
            showSearch: true,
            defaultSort: "nom",
            sortType: 'desc',
            rowsPerPageOptions: [10, 25, 50],
          }}
          cellsConfig={[{
            datakey: "nom",
            title: "Nom",
            sortable: true,
            searchable: true,
          },
          {
            datakey: "code",
            title: "Code",
            sortable: true,
            searchable: true,
          },
          {
            datakey: "zone_geographique",
            title: "Zone géographique",
            sortable: true,
            searchable: true,
          }
        ]}
        />
      </Grid>
    )
  }
}

Pays = withStyles(PaysCss)(Pays);

export default Pays
