export default theme => ({
  is_active_3_mois: {
    color: '#33A345'
  },
  is_active_6_mois: {
    color: '#ff9800'
  },
  is_active_12_mois: {
    color: '#ff0000'
  },
  is_prospect: {
    color: theme.palette.primary.main,
  },
  is_inactive: {
    width:'24px',
    height:'24px',
  },
  nopointer: {
    cursor: 'default'
  },
  root: {
    flexGrow: 1
  },
  center: {
    textAlign: 'center',
    padding: '4px'
  },
  crudTitle: {
    float: 'left',
    marginBottom: 0,
    padding: '8px 0',
    color: theme.palette.primary.main,
  },
  button: {
    float: 'right'
  },
  addButton: {
    marginLeft: '2em',
    marginTop: '0.5em',
    marginRight: '0.5em'
  },
  addButtonIcon: {
    fontSize: '32px'
  },
  returnButton: {
    float: 'right',
    padding: '8px 8px 8px 0',
  },
  doneButton: {
    float: 'right'
  },
  buttonIcon: {
    marginRight: '0.3em'
  },
  section: {
    marginBottom: '3em'
  },
  error: {
    color: theme.palette.error.main
  },
  totauxDisabled: {
    '& label': {
      color: theme.palette.text.secondary + ' !important',
    },
    '& input[disabled]': {
      backgroundColor: '#e7e7e7',
      color: theme.palette.text.primary,
    },
  },
  hr: {
    border: '0.5px solid #18b0ff',
    width: '100%'
  },
  commercialSection: {
    marginTop: '20px'
  },
  ico: {
    verticalAlign: 'middle',
    marginRight: '0.4em'
  },

  container: {
    '& tr': {
      '& td:first-child': {
        textAlign: 'center'
      },
      '& td:nth-child(2)': {
        textAlign: 'center'
      },
    }
  },
  selectContainer: {
    width: 'calc(100% - 1em)'
  },
  checkbox_align:{
    marginLeft: '0.5rem'
  },
  cursorPointer:{
    cursor: "pointer"
  },
  checkbox_container:{
    alignSelf: 'end',
  },
  sectionTitle: {
    marginBottom: "1em",
    color: theme.palette.primary.main,
  },
})
