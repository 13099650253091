import React from "react";
import moment from "moment";

/**
 * extrait les messages d'erreur pour le champ concerné
 * @param  {[type]} errors [description]
 * @param  {[type]} field  [description]
 * @return {[type]}        [description]
 */
export function getErrorsMessage(errors, field) {
  if (!errors || !field || !errors[field]) return null;

  if (Array.isArray(errors[field])) {
    return errors[field].map((err, index) => {
      return <span key={index}>{err}</span>;
    });
  } else return errors[field];
}

/**
 * Génère le path pour le css afin d'éviter des erreur de répertoire
 * @param  {[type]} path [description]
 * @return {[type]}      [description]
 */
export function assetsForCss(path) {
  let prefix = process.env.NODE_ENV === "production" ? "/client" : "";
  return prefix.concat(path);
}

export function cloneDate(date) {
  return moment(date).clone().toDate();
}

export function storeToLocalStorage(key, value, duration) {
  localStorage.setItem(key, JSON.stringify(value));
  if (duration) {
    const now = new Date().getTime();
    const expiration_time = (duration * 60000) + now;
    localStorage.setItem(key + "_expiration", expiration_time);
  }
}

export function getFromLocalStorage(key){
  const valueLocalStorage = localStorage.getItem(key);

  if(valueLocalStorage){
    const now = new Date().getTime();
    const expiration_localStorage = localStorage.getItem(key + "_expiration")
    if(expiration_localStorage && now >= expiration_localStorage){
      localStorage.removeItem(key);
      localStorage.removeItem(key+ "_expiration");
      return null
    }

    return JSON.parse(valueLocalStorage)
  }
  else return null
}


export function getNoteContent(content){
  if(!content) return null
  content = content.replace(/<\/?[^>]+(>|$)/g, "") // Supprime les balises HTML
  content = content ? content.substring(0, 180) : ''
  if(content.length > 90){
    const first_part = content.substring(0,90)
    const last_part = content.substring(90)
    content = first_part + '</br >' + last_part
  }
  return (<p dangerouslySetInnerHTML={{__html:content.length >= 180 ? content + "..." : content}}></p>)
}

/**
 * Retourne l'URL de l'API, y compris en dev local
 * TODO: utiliser cette fonction partout où on utilise directement REACT_APP_API_URL
 * @returns
 */
export function getApiUrl() {
  return process.env.REACT_APP_API_URL
  ? process.env.REACT_APP_API_URL
  : "http://localhost:8000/api";
}

export function getDate(string) {
  if (!string) {
    return "";
  }
  let date = new Date(string);
  let options = {
    day: "numeric",
    month: "long",
    year: "numeric",
  };

  return date.toLocaleDateString("fr-FR", options);
}
