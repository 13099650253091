import React, { Component } from "react";
import { withStyles } from "@material-ui/core/styles";
import SupervisionCss from "./css/SupervisionCss";
import { connect } from "react-redux";
import { CircularProgress,Table, TableBody, TableCell, TableHead, TableRow, Typography } from "@material-ui/core";
import { getPerformancesSynthese } from "../../../reducers/homeReducer";
import moment from "moment";

class Synthese extends Component {

  state = {
    synthese_params: {
      params: {
        user_uuid: this.props.user_uuid,
        mois: this.props.mois,
        date_debut: this.props.date_debut,
        date_fin: this.props.date_fin
      },
    },
  };

  componentDidMount() {
    this.fetchSynthese();
  }

  componentDidUpdate(prevProps, prevState) {
    if (prevProps.mois !== this.props.mois || prevProps.date_debut !== this.props.date_debut || prevProps.date_fin !== this.props.date_fin || prevProps.user_uuid !== this.props.user_uuid) {
      this.setState({
        synthese_params: {
          params: {
            user_uuid: this.props.user_uuid,
            mois: this.props.mois,
            date_debut: this.props.date_debut,
            date_fin: this.props.date_fin
          },
        },
      }, () => {
        this.fetchSynthese();
      });
    }
  }


  fetchSynthese() {
    getPerformancesSynthese(this.props.dispatch, this.state.synthese_params);
  }

  getAchatTable() {
    const { homeStore, classes } = this.props;
    let { date_debut, date_fin, mois } = this.state.synthese_params.params;
    const { synthese } = homeStore.performances;

    const moment_mois = moment(mois, 'YYYY-MM');
    const moment_date_debut = moment(date_debut, 'YYYY-MM-DD');
    const moment_date_fin = moment(date_fin, 'YYYY-MM-DD');
    const now = moment();
    const diff_months = moment_mois.month()- now.month()- + (12 * (now.year() - moment_mois.year()));

    if (!homeStore.performances.synthese) {
      return (
        <div style={{ textAlign: "center" }}>
          <h5>Chargement des données...</h5>
          <CircularProgress />
        </div>
      );
    }
    return (
      <Table className={classes.table}>
        <TableHead>
          <TableRow>
            <TableCell></TableCell>
            <TableCell align="center" className={classes.tablePortefeuilleHeader}>Mois actuel</TableCell>
            <TableCell align="center" className={classes.tablePortefeuilleHeader}>M-1</TableCell>
            <TableCell align="center" className={classes.tablePortefeuilleHeader}>
              M {diff_months}
            </TableCell>
            <TableCell align="center">
              {moment_date_debut.format('DD/MM/YYYY')} - {moment_date_fin.format('DD/MM/YYYY')}
            </TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
            <TableRow key="tonnage_exploitation_broyage">
              <TableCell className={classes.tablePortefeuilleRowTitle}>Tonnage exploitation broyage</TableCell>
              <TableCell align="center">{synthese.achat.tonnage_exploitation_broyage.now}</TableCell>
              <TableCell align="center">{synthese.achat.tonnage_exploitation_broyage.m1}</TableCell>
              <TableCell align="center">{synthese.achat.tonnage_exploitation_broyage.mois}</TableCell>
              <TableCell align="center">{synthese.achat.tonnage_exploitation_broyage.periode}</TableCell>
            </TableRow>
            <TableRow key="tonnage_exploitation_regroupement">
              <TableCell className={classes.tablePortefeuilleRowTitle}>Tonnage exploitation regroupement</TableCell>
              <TableCell align="center">{synthese.achat.tonnage_exploitation_regroupement.now}</TableCell>
              <TableCell align="center">{synthese.achat.tonnage_exploitation_regroupement.m1}</TableCell>
              <TableCell align="center">{synthese.achat.tonnage_exploitation_regroupement.mois}</TableCell>
              <TableCell align="center">{synthese.achat.tonnage_exploitation_regroupement.periode}</TableCell>
            </TableRow>
            <TableRow key="tonnage_achat_negoce">
              <TableCell className={classes.tablePortefeuilleRowTitle}>Tonnage Achat négoce</TableCell>
              <TableCell align="center">{synthese.achat.tonnage_achat_negoce.now}</TableCell>
              <TableCell align="center">{synthese.achat.tonnage_achat_negoce.m1}</TableCell>
              <TableCell align="center">{synthese.achat.tonnage_achat_negoce.mois}</TableCell>
              <TableCell align="center">{synthese.achat.tonnage_achat_negoce.periode}</TableCell>
            </TableRow>
            <TableRow key="tonnage_taf_entrant">
              <TableCell className={classes.tablePortefeuilleRowTitle}>Tonnage TAF Entrant</TableCell>
              <TableCell align="center">{synthese.achat.tonnage_taf_entrant.now}</TableCell>
              <TableCell align="center">{synthese.achat.tonnage_taf_entrant.m1}</TableCell>
              <TableCell align="center">{synthese.achat.tonnage_taf_entrant.mois}</TableCell>
              <TableCell align="center">{synthese.achat.tonnage_taf_entrant.periode}</TableCell>
            </TableRow>
            <TableRow key="nb_affaire_achat_exploitation">
              <TableCell className={classes.tablePortefeuilleRowTitle}>Nombre d'affaires Achat exploitation</TableCell>
              <TableCell align="center">{synthese.achat.nb_affaire_achat_exploitation.now}</TableCell>
              <TableCell align="center">{synthese.achat.nb_affaire_achat_exploitation.m1}</TableCell>
              <TableCell align="center">{synthese.achat.nb_affaire_achat_exploitation.mois}</TableCell>
              <TableCell align="center">{synthese.achat.nb_affaire_achat_exploitation.periode}</TableCell>
            </TableRow>
            <TableRow key="nb_affaire_negoce">
              <TableCell className={classes.tablePortefeuilleRowTitle}>Nombre d'affaires Négoce</TableCell>
              <TableCell align="center">{synthese.achat.nb_affaire_negoce.now}</TableCell>
              <TableCell align="center">{synthese.achat.nb_affaire_negoce.m1}</TableCell>
              <TableCell align="center">{synthese.achat.nb_affaire_negoce.mois}</TableCell>
              <TableCell align="center">{synthese.achat.nb_affaire_negoce.periode}</TableCell>
            </TableRow>
            <TableRow key="nb_fournisseurs_actifs">
              <TableCell className={classes.tablePortefeuilleRowTitle}>Nombre de fournisseurs actifs</TableCell>
              <TableCell align="center">{synthese.achat.nb_fournisseurs_actifs.now}</TableCell>
              <TableCell align="center">{synthese.achat.nb_fournisseurs_actifs.m1}</TableCell>
              <TableCell align="center">{synthese.achat.nb_fournisseurs_actifs.mois}</TableCell>
              <TableCell align="center">{synthese.achat.nb_fournisseurs_actifs.periode}</TableCell>
            </TableRow>
            <TableRow key="nombre_nc_achat_exploitation_taf_entrant">
              <TableCell className={classes.tablePortefeuilleRowTitle}>Nombre de NC achat exploitation et TAF entrant</TableCell>
              <TableCell align="center">{synthese.achat.nombre_nc_achat_exploitation_taf_entrant.now}</TableCell>
              <TableCell align="center">{synthese.achat.nombre_nc_achat_exploitation_taf_entrant.m1}</TableCell>
              <TableCell align="center">{synthese.achat.nombre_nc_achat_exploitation_taf_entrant.mois}</TableCell>
              <TableCell align="center">{synthese.achat.nombre_nc_achat_exploitation_taf_entrant.periode}</TableCell>
            </TableRow>
            <TableRow key="marge_negoce">
              <TableCell className={classes.tablePortefeuilleRowTitle}>Marge négoce</TableCell>
              <TableCell align="center">{synthese.achat.marge_negoce.now}</TableCell>
              <TableCell align="center">{synthese.achat.marge_negoce.m1}</TableCell>
              <TableCell align="center">{synthese.achat.marge_negoce.mois}</TableCell>
              <TableCell align="center">{synthese.achat.marge_negoce.periode}</TableCell>
            </TableRow>
            <TableRow key="nb_affaires_type_statut">
              <TableCell className={classes.tablePortefeuilleRowTitle}>Nombre d’affaires par type de statut (3/4/5/6)</TableCell>
              <TableCell align="center">{synthese.achat.nb_affaires_type_statut.now}</TableCell>
              <TableCell align="center">{synthese.achat.nb_affaires_type_statut.m1}</TableCell>
              <TableCell align="center">{synthese.achat.nb_affaires_type_statut.mois}</TableCell>
              <TableCell align="center">{synthese.achat.nb_affaires_type_statut.periode}</TableCell>
            </TableRow>
        </TableBody>
      </Table>
    );
  }

  getVenteTable() {
    const { homeStore, classes } = this.props;
    let { date_debut, date_fin, mois } = this.state.synthese_params.params;
    const { synthese } = homeStore.performances;

    const moment_mois = moment(mois, 'YYYY-MM');
    const moment_date_debut = moment(date_debut, 'YYYY-MM-DD');
    const moment_date_fin = moment(date_fin, 'YYYY-MM-DD');
    const now = moment();
    const diff_months = moment_mois.month()- now.month()- + (12 * (now.year() - moment_mois.year()));

    if (!homeStore.performances.synthese) {
      return (
        <div style={{ textAlign: "center" }}>
          <h5>Chargement des données...</h5>
          <CircularProgress />
        </div>
      );
    }

    return (
      <Table className={classes.table}>
        <TableHead>
          <TableRow>
            <TableCell></TableCell>
            <TableCell align="center" className={classes.tablePortefeuilleHeader}>Mois actuel</TableCell>
            <TableCell align="center" className={classes.tablePortefeuilleHeader}>M-1</TableCell>
            <TableCell align="center" className={classes.tablePortefeuilleHeader}>
              M {diff_months}
            </TableCell>
            <TableCell align="center">
              {moment_date_debut.format('DD/MM/YYYY')} - {moment_date_fin.format('DD/MM/YYYY')}
            </TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
            <TableRow key="tonnage_vente_exploitation">
              <TableCell className={classes.tablePortefeuilleRowTitle}>Tonnage Vente exploitation</TableCell>
              <TableCell align="center">{synthese.vente.tonnage_vente_exploitation.now}</TableCell>
              <TableCell align="center">{synthese.vente.tonnage_vente_exploitation.m1}</TableCell>
              <TableCell align="center">{synthese.vente.tonnage_vente_exploitation.mois}</TableCell>
              <TableCell align="center">{synthese.vente.tonnage_vente_exploitation.periode}</TableCell>
            </TableRow>
            <TableRow key="tonnage_vente_negoce">
              <TableCell className={classes.tablePortefeuilleRowTitle}>Tonnage Vente Négoce</TableCell>
              <TableCell align="center">{synthese.vente.tonnage_vente_negoce.now}</TableCell>
              <TableCell align="center">{synthese.vente.tonnage_vente_negoce.m1}</TableCell>
              <TableCell align="center">{synthese.vente.tonnage_vente_negoce.mois}</TableCell>
              <TableCell align="center">{synthese.vente.tonnage_vente_negoce.periode}</TableCell>
            </TableRow>
            <TableRow key="tonnage_taf_sortant">
              <TableCell className={classes.tablePortefeuilleRowTitle}>Tonnage TAF Sortant</TableCell>
              <TableCell align="center">{synthese.vente.tonnage_taf_sortant.now}</TableCell>
              <TableCell align="center">{synthese.vente.tonnage_taf_sortant.m1}</TableCell>
              <TableCell align="center">{synthese.vente.tonnage_taf_sortant.mois}</TableCell>
              <TableCell align="center">{synthese.vente.tonnage_taf_sortant.periode}</TableCell>
            </TableRow>
            <TableRow key="nb_affaire_vente_exploitation">
              <TableCell className={classes.tablePortefeuilleRowTitle}>Nombre d'affaires Vente Exploitation</TableCell>
              <TableCell align="center">{synthese.vente.nb_affaire_vente_exploitation.now}</TableCell>
              <TableCell align="center">{synthese.vente.nb_affaire_vente_exploitation.m1}</TableCell>
              <TableCell align="center">{synthese.vente.nb_affaire_vente_exploitation.mois}</TableCell>
              <TableCell align="center">{synthese.vente.nb_affaire_vente_exploitation.periode}</TableCell>
            </TableRow>
            <TableRow key="nb_affaire_vente_negoce">
              <TableCell className={classes.tablePortefeuilleRowTitle}>Nombre d'affaires Vente Négoce</TableCell>
              <TableCell align="center">{synthese.vente.nb_affaire_vente_negoce.now}</TableCell>
              <TableCell align="center">{synthese.vente.nb_affaire_vente_negoce.m1}</TableCell>
              <TableCell align="center">{synthese.vente.nb_affaire_vente_negoce.mois}</TableCell>
              <TableCell align="center">{synthese.vente.nb_affaire_vente_negoce.periode}</TableCell>
            </TableRow>
            <TableRow key="nb_affaire_vente_taf_sortant">
              <TableCell className={classes.tablePortefeuilleRowTitle}>Nombre d'affaires Vente TAF Sortant</TableCell>
              <TableCell align="center">{synthese.vente.nb_affaire_vente_taf_sortant.now}</TableCell>
              <TableCell align="center">{synthese.vente.nb_affaire_vente_taf_sortant.m1}</TableCell>
              <TableCell align="center">{synthese.vente.nb_affaire_vente_taf_sortant.mois}</TableCell>
              <TableCell align="center">{synthese.vente.nb_affaire_vente_taf_sortant.periode}</TableCell>
            </TableRow>
            <TableRow key="nb_clients_actifs">
              <TableCell className={classes.tablePortefeuilleRowTitle}>Nombre de clients actifs</TableCell>
              <TableCell align="center">{synthese.vente.nb_clients_actifs.now}</TableCell>
              <TableCell align="center">{synthese.vente.nb_clients_actifs.m1}</TableCell>
              <TableCell align="center">{synthese.vente.nb_clients_actifs.mois}</TableCell>
              <TableCell align="center">{synthese.vente.nb_clients_actifs.periode}</TableCell>
            </TableRow>
            <TableRow key="nombre_nc_vente_exploitation_taf_sortant">
              <TableCell className={classes.tablePortefeuilleRowTitle}>Nombre de NC Vente exploitation et TAF sortant</TableCell>
              <TableCell align="center">{synthese.vente.nombre_nc_vente_exploitation_taf_sortant.now}</TableCell>
              <TableCell align="center">{synthese.vente.nombre_nc_vente_exploitation_taf_sortant.m1}</TableCell>
              <TableCell align="center">{synthese.vente.nombre_nc_vente_exploitation_taf_sortant.mois}</TableCell>
              <TableCell align="center">{synthese.vente.nombre_nc_vente_exploitation_taf_sortant.periode}</TableCell>
            </TableRow>
        </TableBody>
      </Table>
    );
  }

  render() {
    return (
      <div>
        <Typography color="primary" variant="h5">ACHAT</Typography>
        {this.getAchatTable()}
        <Typography color="primary" variant="h5">VENTE</Typography>
        {this.getVenteTable()}
      </div>
    );
  }
}

Synthese = withStyles(SupervisionCss)(Synthese);

Synthese = connect((store) => ({
  homeStore: store.home,
}))(Synthese);

export default Synthese;
