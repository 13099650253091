import React, {Component} from 'react';
import {connect} from "react-redux";
import TextAreaCss from "./css/TextAreaCss";
import {withStyles} from "@material-ui/core/styles/index";

class TextArea extends Component {

  state = {
    value: '',
    force_remount: 0
  };

  static getDerivedStateFromProps(nextProps, prevState) {
    let newState = {...prevState};
    if (nextProps.value !== prevState.value) {
      newState = {
        ...newState,
        value: nextProps.value,
        force_remount: prevState.force_remount + 1
      }
    }

    return newState;
  }

  onChangeHandler(name, event) {
    this.setState({
      value: event.target.value
    });
    this.props.onChangeHandler(name, event)
  }

  /**
   * Retourne le message d'erreur pour le champ demandé
   * @param  {[type]} name [description]
   * @return {[type]}      [description]
   */
  getErrorMessage(name) {
    const {collectionStore, classes} = this.props;
    if (collectionStore && collectionStore.errors && collectionStore.errors[name]) {
      return (
        <div className={classes.errorContainer}>
          {collectionStore.errors[name].map((err, index) => <span style={{display: 'block'}}
                                                                    key={index}>{err}</span>)}
        </div>
      );
    } else return null;
  }

  render() {
    const {
      classes,
      name,
      label,
    } = this.props;

    const {
      force_remount,
      value
    } = this.state;

    let inputProps = Object.keys(this.props).filter(k => !['name', 'label', 'collectionStore', 'onChangeHandler', 'value', 'classes', 'dispatch'].includes(k)).reduce((obj, key) => {
      obj[key] = this.props[key];
      return obj;
    }, {});

    const errors = this.getErrorMessage(name);
    return (
      <div className={classes.formTextArea}>
        <label
          for={name}
          className={classes.textarealabel + ' ' + (errors ? classes.error : '')}
        >
          {label}
        </label>
        <textarea
          id={name}
          key={force_remount}
          className={classes.textarea}
          rows={4}
          {...inputProps}
          onBlur={this.onChangeHandler.bind(this, name)}
        >
          { value }
        </textarea>
        {errors}
      </div>
    )
  }
}

TextArea = connect((store) => {
  return {}
})(TextArea);
export default withStyles(TextAreaCss)(TextArea)
