import React, { Component } from "react";
import { withStyles } from "@material-ui/core/styles";
import PlanificationCss from "./css/PlanificationCss";
import { Grid, Tabs, Tab, CircularProgress } from "@material-ui/core";
import { connect } from "react-redux";
import Planning from "./Planning";
import Selecteur from "./Selecteur";
import moment from "moment";
import { hasRights } from "../../common/Tools/Tools";
import { axiosClient } from "../../../axios";
import PlanningCaseStorage from "./PlanningCaseStorage";

class Planification extends Component {
  state = {
    selectedTab: 0,
    selectedTabSiteHasCaseStorage: false,
    selectedDate: this.props.forModulePhoto
      ? moment().toDate()
      : moment().startOf("isoWeek").toDate(),
    internalSite: [],
    sites_loaded: false,
    showCaseStorage: false,
    admin:
      this.props.currentRouteConfig.topBarVisible &&
      hasRights("admin-planification", this.props.user),
    recapMensuelle: null,
  };

  componentDidMount() {
    axiosClient.get("/flux/getInternalSites").then((response) => {
      this.setState({
        internalSite: response.data,
        sites_loaded: true,
      },()=>{
        this.state.internalSite.map((site,index) => {
          if(site.label === "CAP ECO PUCEUL (PUCEUL)"){
            this.setState({selectedTab:index },()=>{
              this.handleClicTab(site.uuid)
              this.getRecapMensuel()
            })
          }
          return null;
        });
      });
    })
  }

  /**
   * Handler de changement de date
   */
  onChangeHandlerDate(date) {
    this.setState({
      selectedDate: date,
    },() => {this.getRecapMensuel()});
  }

  handleChangeTab(event, selectedTab) {
    this.setState({ selectedTab }, () => {this.getRecapMensuel()});
  }

  getPlanning() {
    const { selectedTab, showCaseStorage } = this.state;
    const selectedSite = this.getTabs()[selectedTab].key;

    if (showCaseStorage) return this.getCaseStockage();

    return (
      <Planning
        selectedDate={this.state.selectedDate}
        admin={this.state.admin}
        exterior={selectedSite === "externe"}
        site={selectedSite}
        forModulePhoto={this.props.forModulePhoto}
        callPhotoDialog={this.props.callPhotoDialog}
      />
    );
  }

  getCaseStockage() {
    const { selectedTab } = this.state;
    const selectedSite = this.getTabs()[selectedTab].key;
    return (
      <>
        <PlanningCaseStorage
          selectedDate={this.state.selectedDate}
          admin={this.state.admin}
          exterior={selectedSite === "externe"}
          site={selectedSite}
          forModulePhoto={this.props.forModulePhoto}
          callPhotoDialog={this.props.callPhotoDialog}
        />
      </>
    );
  }

  getTabTotaux() {
    const { classes, fluxStore, forModulePhoto } = this.props;
    if (fluxStore.fetching || !fluxStore.list) return null;
    const totaux = {
      R: null,
      E: null,
      T: null,
      D: null,
    };
    Object.keys(fluxStore.list).forEach((key) => {
      if (fluxStore.list[key].livraison) {
        if (!totaux.R) totaux.R = { nb: 0, poids: 0 };
        totaux.R.nb += fluxStore.list[key].livraison.nb;
        totaux.R.poids += fluxStore.list[key].livraison.poids;
      }
      if (fluxStore.list[key].enlevement) {
        if (!totaux.E) totaux.E = { nb: 0, poids: 0 };
        totaux.E.nb += fluxStore.list[key].enlevement.nb;
        totaux.E.poids += fluxStore.list[key].enlevement.poids;
      }
      if (fluxStore.list[key].total) {
        if (!totaux.T) totaux.T = { nb: 0, poids: 0 };
        totaux.T.nb += fluxStore.list[key].total.nb;
        totaux.T.poids += fluxStore.list[key].total.poids;
      }
      if (fluxStore.list[key].difference) {
        if (!totaux.D) totaux.D = { nb: 0, poids: 0 };
        totaux.D.nb += fluxStore.list[key].difference.nb;
        totaux.D.poids += fluxStore.list[key].difference.poids;
      }
    });
    totaux.R.poids = Math.round(totaux.R.poids * 100) / 100;
    totaux.E.poids = Math.round(totaux.E.poids * 100) / 100;
    totaux.T.poids = Math.round(totaux.T.poids * 100) / 100;
    totaux.D.poids = Math.round(totaux.D.poids * 100) / 100;
    if (forModulePhoto) {
      return <></>;
    }
    return (
      <div className={classes.totauxContainer}>
        <div className={classes.totaux}>
          {Object.keys(totaux).map((key) => {
            if (totaux[key].poids === 0) return null;
            return (
              <div className={classes.total}>
                <div className={classes.label}>{key}s :</div>
                <div className={classes.value}>
                  {totaux[key].nb} / {totaux[key].poids} T
                </div>
              </div>
            );
          })}
        </div>
      </div>
    );
  }

  getTabMensuel() {
    const { classes, forModulePhoto } = this.props;
    const { recapMensuelle } = this.state;

    if (!recapMensuelle || forModulePhoto ) return null;
    let totaux = {
      R: null,
      E: null,
      T: null,
      D: null,
      'D-1': null,
    };
    Object.keys(recapMensuelle).forEach((key) => {
      if (key ==="livraison") {
        if (!totaux.R) totaux.R = { nb: 0, poids: 0 };
        totaux.R.nb += recapMensuelle[key].nb;
        totaux.R.poids = Math.round(recapMensuelle[key].poids * 100) / 100;
      }
      if (key === "enlevement") {
        if (!totaux.E) totaux.E = { nb: 0, poids: 0 };
        totaux.E.nb += recapMensuelle[key].nb;
        totaux.E.poids = Math.round(recapMensuelle[key].poids * 100) / 100;
      }
      if (key === "total") {
        if (!totaux.T) totaux.T = { nb: 0, poids: 0 };
        totaux.T.nb += recapMensuelle[key].nb;
        totaux.T.poids = Math.round(recapMensuelle[key].poids * 100) / 100;
      }
      if (key === "difference") {
        if (!totaux.D) totaux.D = { nb: 0, poids: 0 };
        totaux.D.nb += recapMensuelle[key].nb;
        totaux.D.poids = Math.round(recapMensuelle[key].poids * 100) / 100;
      }
      if (key === "difference_m-1") {
        if (!totaux['D-1']) totaux['D-1'] = { nb: 0, poids: 0 };
        totaux['D-1'].nb += recapMensuelle[key].nb;
        totaux['D-1'].poids = Math.round(recapMensuelle[key].poids * 100) / 100;
      }
    });
    if(totaux.D?.poids){
    totaux.D.poids = Math.round(totaux.D.poids * 100) / 100;
    }
    return (
      <div className={classes.totauxContainer}>
        <div className={classes.totaux}>
          {Object.keys(totaux).map((key) => {
            if (totaux[key].poids === 0) return null;
            return (
              <div className={classes.total}>
                <div className={classes.label}>{key === 'D-1'? 'Dm-1 ' : key+'m ' } :</div>
                <div className={classes.value}>
                  {totaux[key].nb} / {totaux[key].poids} T
                </div>
              </div>
            );
          })}
        </div>
      </div>
    );
  }

  getRecapMensuel() {
    const { selectedTab, selectedDate } = this.state;
    const selectedSite = this.getTabs()[selectedTab].key;

    axiosClient
      .get(`flux/getRecapMensuelle`, {
        params: {
          date: selectedDate,
          site: selectedSite,
        },
      })
      .then((response) => {
        this.setState({ recapMensuelle: response.data });
      });
  }

  hasCaseStorage(site = null) {
    const { selectedTab } = this.state;
    let selectedSite = this.getTabs()[selectedTab].key;
    if (site) {
      selectedSite = site;
    }
    let hascase = null;
    if (selectedSite === "externe") {
      hascase = false;
    } else {
      hascase = this.state.internalSite.find(
        (site) => site.uuid === selectedSite
      ).has_case_stockage;
    }
    return hascase;
  }

  handleClicTab(key) {
    let hasCase = this.hasCaseStorage(key);
    this.setState({ selectedTabSiteHasCaseStorage: hasCase }, () => {
      if (hasCase === false) {
        this.setState({ showCaseStorage: false });
      }
    });
  }

  getTabs() {
    const tabs = this.state.internalSite.map((site) => {
      return (
        <Tab
          label={site.label}
          key={site.uuid}
          onClick={this.handleClicTab.bind(this,site.uuid)}
        />
      );
    });
    if (this.state.admin)
      tabs.push(
        <Tab
          label="Exterieur"
          key={"externe"}
          onClick={this.handleClicTab.bind(this,'externe')}
        />
      );
    return tabs;
  }

  handleChangeview() {
    this.setState({ showCaseStorage: !this.state.showCaseStorage });
  }

  displayStorageViewBtn() {
    const { classes, forModulePhoto } = this.props;
    const { selectedTabSiteHasCaseStorage } = this.state;
    if (forModulePhoto) return null;
    return (
      <button
        onClick={this.handleChangeview.bind(this)}
        disabled={!selectedTabSiteHasCaseStorage}
        className={classes.center}
      >
        {this.state.showCaseStorage
          ? "revenir au planning"
          : "Afficher la dispo des cases"}
      </button>
    );
  }

  render() {
    const { classes, forModulePhoto } = this.props;

    if (!this.state.sites_loaded) {
      return (
        <>
          <h4 className={classes.center}>Récupération des sites</h4>
          <CircularProgress />
        </>
      );
    }

    return (
      <Grid container>
        <Tabs
          value={this.state.selectedTab}
          onChange={this.handleChangeTab.bind(this)}
          indicatorColor="primary"
          textColor="primary"
          centered
          style={{ marginBottom: "1em" }}
          className={forModulePhoto ? classes.tabsFlexContainer : ""}
          flexContainerVertical={true}
        >
          {this.getTabs()}
          <Selecteur
            callback={this.onChangeHandlerDate.bind(this)}
            selectedDate={this.state.selectedDate}
            forModulePhoto={forModulePhoto}
          />
        </Tabs>
        <Grid item xs={1}>
          {this.getTabTotaux()}
        </Grid>
        <Grid item xs={2}>
          {this.getTabMensuel()}
        </Grid>
        {this.displayStorageViewBtn()}
        {this.getPlanning()}
      </Grid>
    );
  }
}

Planification = connect((store) => {
  return {
    currentRouteConfig: store.app.currentRouteConfig,
    fluxStore: store.collections.flux,
    user: store.auth.user,
    caseStockageStore: store.collections.caseStockage,
  };
})(Planification);

Planification = withStyles(PlanificationCss)(Planification);

export default Planification;
