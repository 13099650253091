import {themeComplement} from './theme';

export default theme => ({
  listItem: {
    borderLeft: '3px solid rgba(0, 0, 0, 0.08)',
    '&:hover': {
      borderLeft: '3px solid ' + theme.palette.primary.main,
    }
  },
  listItemCurrent: {
    backgroundColor: theme.palette.primary.main,
    borderLeft: '3px solid ' + theme.palette.primary.main,
    '&:hover': {
      backgroundColor: theme.palette.primary.main,
    }
  },
  iconButton: {
    color: themeComplement.palette.textColorLight
  },
  listItemIcon: {
    color: themeComplement.palette.textColorLight
  },
  listItemIconCurrent: {
    color: 'white'
  },
  listItemText: {
    color: themeComplement.palette.textColorLight
  },
  listItemTextCurrent: {
    color: 'white'
  }
})
