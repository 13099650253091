import Login from '../Login'
import PasswordLost from '../PasswordLost'
import PasswordReset from '../PasswordReset'

export const routes = [
  {
    path: '/auth',
    component: Login,
    exact: true,
    public: true,
    noContainer: true
  },
  {
    path: '/auth/password/lost',
    component: PasswordLost,
    exact: true,
    public: true,
    noContainer: true
  },
  {
    path: '/auth/password/reset/:token',
    component: PasswordReset,
    exact: true,
    public: true,
    noContainer: true
  },
  {
    path: '/auth/password/create/:token',
    component: PasswordReset,
    exact: true,
    public: true,
    noContainer: true
  },
];
