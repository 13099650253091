export default theme => ({
  root: {
    flexGrow: 1
  },
  button: {
    float: 'right'
  },
  addButton: {
    marginLeft: '2em',
    marginTop: '0.5em',
    marginRight: '0.5em'
  },
  addButtonIcon: {
    fontSize: '32px'
  },
  error: {
    color: theme.palette.error.main
  },
  selectContainer: {
    width: 'calc(100% - 1em)'
  },
  btnCancelDeplacement: {
    border: "solid 1px #9f0808",
    backgroundColor: "#db0707",
    color: "white",
    minWidth: 'auto',
    height: '1.5rem',
    width: '1.5rem',
    borderRadius: '50%',
    fontSize: '0.65rem',
    "&:hover": {
      backgroundColor: "#9f0808"
    }
  },
  deplacementContainer:{
    width: '35rem',
  },
  deplacementContainerTop:{
    display: 'flex',
    flexDirection: 'row',
    flexWrap: 'wrap',
  },
  itemDeplacementTop:{
    flex: 1,
  },
  cancelDeplacementParagrapheBottom:{
    marginBottom: '1rem',
    color: 'grey'
  },
  zonesContainer: {
    marginTop: '1em',
    display: 'flex',
    flexDirection: 'row',
    flexWrap: 'wrap',
  },
  card:{
    width: '10rem',
    height: '10rem',
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'center',
    alignItems: 'center',
    cursor: 'pointer',
    margin: '0.5rem',
    borderRadius: '0.5rem',
    position: 'relative',
    fontSize: '1.2rem',
    fontWeight: 'bold',
    color: '#fff',
  },
  buttonDelete:{
    position: 'absolute',
    top: '0.5rem',
    right: '0.5rem',
    color: theme.palette.error.main,
    cursor: 'pointer',
  },
  buttonAdd:{
    color: theme.palette.primary.main,
    cursor: 'pointer',
  },
  loadingContainer:{
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    height: '100%',
    width: '100%',
    flexDirection: 'column',
  },
  nbBB:{
    position: 'absolute',
    top: '0.5rem',
    right: '0.5rem',
    width: '1.5rem',
    height: '1.5rem',
    lineHeight: '1.5rem',
    borderRadius: '50%',
    backgroundColor: '#FFF',
    color: 'grey',
    textAlign: 'center',
    fontSize: '0.8rem',
  },
  nbBBHighlighted:{
    right: '0.5rem',
    backgroundColor: '#FFF300',
    color: 'red',
  },
  selectDiv:{
    position: 'absolute',
    top: '0.5rem',
    left: '0.5rem',
    '& div > label > span > span > svg':{
      color: 'white',
    }
  },
  selectedBigBagContainer:{
    display: 'flex',
    flexDirection: 'row',
    flexWrap: 'wrap',
    marginBottom: '1rem',
    marginTop: '1rem',
  },
  selectedBigBag:{
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'center',
    width: '10rem',
    cursor: 'pointer',
    border:'solid 1px lightgrey',
    marginLeft: '0.2rem',
    borderRadius: '0.5rem',
    position: 'relative',
  },
  selectedBigBagDelete:{
    top: 0,
    backgroundColor: theme.palette.error.main,
    right: 0,
    cursor: 'pointer',
    position: 'absolute',
    width: '1rem',
    height: '1rem',
    borderRadius: '50%',
    fontSize: '0.7rem',
    padding: '0.2rem',
    display: 'flex',
    justifyContent: 'center',
    color: '#FFF',
  },
})
