import React, { Component } from "react";
import { withStyles } from "@material-ui/core/styles";
import {
  Grid,
  Typography,
  Tabs,
  Tab,
  MenuItem,
  TextField,
  Button,
  DialogActions,
  Dialog,
  DialogTitle,
  DialogContent,
} from "@material-ui/core";
import { Settings } from "@material-ui/icons";
import TextInput from "../../common/Components/TextInput";
import NonConformiteCss from "./css/NonConformiteCss";
import CollectionCrud from "../../common/Components/CollectionCrud";
import {
  collectionActions,
  loadCollectionAttribute,
  validateNc,
} from "../../../reducers/collectionsReducer";
import { connect } from "react-redux";
import { axiosClient } from "../../../axios";
import DateInput from "../../common/Components/DateInput";
import CheckboxInput from "../../common/Components/CheckboxInput";
import Documents from "../Documents";
import { hasRights } from "../../common/Tools/Tools";
import EmailButton from "../Email";
import moment from "moment";
import TextArea from "../../common/Components/TextArea";
import { buildRoute } from "../../../router/Tools";

const TABS = {
  EXTERNES: "externes",
  INTERNES: "internes",
};

const LOCAL_STORAGE_KEY = "non-conformite-filters";

function TabContainer(props) {
  return (
    <Typography component="div" style={{ width: "100%" }}>
      {props.children}
    </Typography>
  );
}
class NonConformite extends Component {
  state = {
    criticites: [],
    selectedTab: this.getInitialTab(),
    entreprise_remonte_uuid: null,
    entreprise_responsable_uuid: null,
    current_filter: null,
    filters: {
      etat: ["all"],
      commercial_responsable_uuid_filter: ["all"],
      type_responsable_uuid_filter: ["all"],
      date_filter: ["all"],
      entreprise_responsable_filter_uuid: "all",
      entreprise_remontee_filter_uuid: "all",
      validation_qualite_filter: "all",
      etablissement_remontes_filter_uuid: ["all"],
      etablissement_responsable_filter_uuid: ["all"],
      closed_filter: this.props.defaultClotureActive ? "all" : "non",
    },
    openModalDatePicker: false,
    type_meg_filter: ["all"],
    date_filter_date_debut: "",
    date_filter_date_fin: "",
    is_capeco_responsable: false,
    is_capeco_remonte: false,
    liste_etablissements: [],
  };

  getInitialTab() {
    if (this.props.location?.pathname === "/non-conformites/internes") {
      return TABS.INTERNES;
    }
    if (this.props.location?.pathname === "/non-conformites/externes") {
      return TABS.EXTERNES;
    }
    return TABS.EXTERNES;
  }

  componentDidMount() {
    const { dispatch, typeNonConformitesStore, litigesStore } = this.props;

    loadCollectionAttribute(
      dispatch,
      "list",
      "type-non-conformites",
      typeNonConformitesStore
    );

    loadCollectionAttribute(dispatch, "list", "litiges", litigesStore);

    axiosClient.get("/non-conformites/criticiteList").then((response) => {
      this.setState({ criticites: response.data });
    });

    // initialisation des filtres
    const filters = JSON.parse(localStorage.getItem(LOCAL_STORAGE_KEY));
    if (filters) {
      this.setState({ filters:{
        ...this.state.filters,
        ...filters,
      }}, () => {
        this.loadAsyncData(this.state.filters);
      });
    }

    axiosClient.get('non-conformites/etablissements-remonte').then((response) => {
      this.setState({
        liste_etablissements: response.data,
      });
    });
  }

  componentWillUnmount() {
    const { dispatch } = this.props;
    collectionActions(dispatch, "non-conformites", "RESET_LIST");
  }


  onChangeHandlerFilters(name, e) {
    let values = e.target.value;
    const uniques_values_fieds = [
      'entreprise_responsable_filter_uuid',
      'closed_filter',
      'entreprise_remontee_filter_uuid',
      'validation_qualite_filter',
    ]
    if(uniques_values_fieds.includes(name)){
      values = values ? values : 'all';
    }else{
      /**
           * Gestion de la value all
           * si on clique dessus on vide la liste et ne garde que all
           * si on clique sur un autre element on enleve all
           * si on vide la liste on rajoute all
           */
      if(values.length > 0) {
        if(this.state.filters[name].indexOf('all') !== -1) {
          values = values.filter(value => value !== "all");
        }else if(values.indexOf('all') !== -1) {
          values = ['all'];
        }
      }else{
        values = ['all'];
      }
    }


    this.setState(
      {
        filters:{
          ...this.state.filters,
          [name]: values,
        }
      },
      () => {
        const exlude_filters = ['closed_filter'];
        const filters = Object.keys(this.state.filters).reduce((acc, key) => {
          if(exlude_filters.indexOf(key) === -1){
            acc[key] = this.state.filters[key];
          }
          return acc;
        }, {});
        localStorage.setItem(LOCAL_STORAGE_KEY, JSON.stringify(filters));
        this.loadAsyncData(this.state.filters, true);
      }
    );
  }

  generateCommercialOrResponsableSelect() {
    const { usersStore, classes } = this.props;

    if(this.state.selectedTab === TABS.INTERNES){
      return (
        <TextField
          label="Type responsable"
          margin="normal"
          onChange={this.onChangeHandlerFilters.bind(this, "type_responsable_uuid_filter")}
          className={classes.selectContainer}
          select
          SelectProps={{
            multiple: true,
            value: this.state.filters.type_responsable_uuid_filter,
          }}
        >
          {this.getOptionsType({is_capeco: true}, {},true)}
        </TextField>
      )

    }else{
      if (!usersStore.list ) return null;
      const commerciaux = [
        {
          uuid: "all",
          firstname: "Tous",
        },
        ...usersStore.list,
      ];
      const options = commerciaux.map((user) => {
        return (
          <MenuItem value={user.uuid} key={user.uuid}>
            {user.firstname}
          </MenuItem>
        );
      });
      return (
        <TextField
          label={"Commercial"}
          margin="normal"
          className={classes.selectContainer}
          onChange={this.onChangeHandlerFilters.bind(this, "commercial_responsable_uuid_filter")}
          SelectProps={{
            multiple: true,
            value: this.state.filters.commercial_responsable_uuid_filter,
          }}
          select
        >
          {options}
        </TextField>
      );
    }

  }

  getEtatFilter() {
    const { classes } = this.props;

    const etats = [
      {
        uuid: "all",
        nom: "Toutes",
      },
      {
        uuid: "0-PAS_ANOMALIE",
        nom: "PAS ANOMALIE",
      },
      {
        uuid: "1-CREE",
        nom: "CREE",
      },
      {
        uuid: "2-EDITEE",
        nom: "EDITEE",
      },
      {
        uuid: "3-VALIDEE_SANS_ENVOI",
        nom: "VALIDEE SANS ENVOI",
      },
      {
        uuid: "4-VALIDEE_AVEC_ENVOI",
        nom: "VALIDEE AVEC ENVOI",
      },
    ];
    const options = etats.map((etat) => {
      return (
        <MenuItem value={etat.uuid} key={etat.uuid}>
          {etat.nom}
        </MenuItem>
      );
    });

    return (
      <TextField
        label="Etat NC"
        margin="normal"
        onChange={this.onChangeHandlerFilters.bind(this, "etat")}
        className={classes.selectContainer}
        SelectProps={{
          multiple: true,
          value: this.state.filters.etat,
        }}
        select
      >
        {options}
      </TextField>
    );
  }

  onClickDatePeriode(nb_weeks) {
    var date_debut = moment().startOf('isoWeek').day(1).subtract(nb_weeks, "weeks");
    var date_fin = date_debut.clone().endOf('week').day(7);

    date_debut = date_debut.format("YYYY-MM-DD");
    date_fin = date_fin.format("YYYY-MM-DD");

    this.setState({
      date_filter_date_debut: date_debut,
      date_filter_date_fin: date_fin,
    });
  }

  getDateFilter() {
    const { classes } = this.props;
    const {
      filters,
      openModalDatePicker,
      date_filter_date_debut,
      date_filter_date_fin,
    } = this.state;

    const { date_filter } = filters;

    const date_filter_new =
      date_filter_date_debut === "" || date_filter_date_fin === ""
        ? ["all"]
        : [date_filter_date_debut, date_filter_date_fin];
    const dates_values = date_filter.filter((date) => date !== "all");
    return (
      <>
        <TextField
          id="date_filter"
          label="Date création"
          margin="normal"
          onClick={() => this.setState({ openModalDatePicker: true })}
          className={classes.selectContainer}
          value={
            dates_values.length > 0
              ? dates_values
                  .map((date) => moment(date).format("DD/MM/YYYY"))
                  .join(" au ")
              : "Toutes"
          }
        />
        <Dialog
          open={openModalDatePicker}
          onClose={() => {
            this.setState({ openModalDatePicker: false });
          }}
        >
          <DialogTitle key="title" id="alert-dialog-slide-title">
            <Typography>Sélectionner les Dates</Typography>
          </DialogTitle>
          <DialogContent>
            <Button
              color="primary"
              onClick={this.onClickDatePeriode.bind(this, 0)}
            >
              S
            </Button>
            <Button
              color="primary"
              onClick={this.onClickDatePeriode.bind(this, 1)}
            >
              S-1
            </Button>
            <Button
              color="primary"
              onClick={this.onClickDatePeriode.bind(this, 2)}
            >
              S-2
            </Button>
            <Button
              color="primary"
              onClick={this.onClickDatePeriode.bind(this, 3)}
            >
              S-3
            </Button>
            <Button
              color="primary"
              onClick={this.onClickDatePeriode.bind(this, 4)}
            >
              S-4
            </Button>
            <Button
              color="primary"
              onClick={() => {
                this.setState({
                  date_filter_date_debut: "",
                  date_filter_date_fin: "",
                });
              }}
            >
              Toutes
            </Button>
            <Grid container>
              <Grid item xs={6}>
                <TextField
                label="Date début"
                margin="normal"
                className={classes.dateInput}
                type="date"
                value={date_filter_date_debut}
                onChange={(e) => {
                  this.setState({ date_filter_date_debut: e.target.value });
                }}
                />
              </Grid>
              <Grid item xs={6}>
                <TextField
                  label="Date fin"
                  margin="normal"
                  className={classes.dateInput}
                  type="date"
                  value={date_filter_date_fin}
                  onChange={(e) => {
                    this.setState({ date_filter_date_fin: e.target.value });
                  }}
                />
              </Grid>
            </Grid>
          </DialogContent>
          <DialogActions>
            <Button
              onClick={() => this.setState({ openModalDatePicker: false })}
            >
              Annuler
            </Button>
            <Button
              onClick={() =>
                this.setState(
                  { openModalDatePicker: false },
                  () => {
                    this.onChangeHandlerFilters("date_filter", {
                      target: { value: date_filter_new },
                    });
                  }
                )
              }
              color="primary"
            >
              Valider
            </Button>
          </DialogActions>
        </Dialog>
      </>
    );
  }

  getEntrepriseResponsableFilter(){
    const { classes, nonConformiteStore } = this.props;
    const { filters } = this.state;
    const { entreprise_responsable_filter_uuid } = filters;
    return (
      <TextInput
          id="entreprise_responsable_filter_uuid"
          label="Entreprise responsable"
          className={classes.selectContainer}
          collectionStore={nonConformiteStore}
          value={entreprise_responsable_filter_uuid}
          margin="normal"
          name="entreprise_responsable_filter_uuid"
          onChangeHandler={(name, e) => this.onChangeHandlerFilters(name, e)}
          type="autocomplete"
          autocompleteProps={{
            collectionName: "entreprises",
          }}
          fullWidth
          clearable
      />
    )
  }

  getEntrepriseRemonteFilter(){
    const { classes, nonConformiteStore } = this.props;
    const { filters } = this.state;
    const { entreprise_remontee_filter_uuid } = filters;
    return (
      <TextInput
          id="entreprise_remontee_filter_uuid"
          label="Entreprise remontée"
          className={classes.selectContainer}
          collectionStore={nonConformiteStore}
          value={entreprise_remontee_filter_uuid}
          margin="normal"
          name="entreprise_remontee_filter_uuid"
          onChangeHandler={(name, e) => this.onChangeHandlerFilters(name, e)}
          type="autocomplete"
          autocompleteProps={{
            collectionName: "entreprises",
          }}
          fullWidth
          clearable
      />
    )
  }

  getIsClosedFilter(){
    const { classes } = this.props;
    return (
      <TextField
        label="NC cloturées"
        margin="normal"
        onChange={this.onChangeHandlerFilters.bind(this, "closed_filter")}
        className={classes.selectContainer}
        value={this.state.filters.closed_filter}
        select
      >
        <MenuItem value="all">Toutes</MenuItem>
        <MenuItem value="oui">Oui</MenuItem>
        <MenuItem value="non">Non</MenuItem>
      </TextField>
    );
  }
  getIsValidationQualiteFilter(){
    const { classes } = this.props;
    return (
      <TextField
        label="Validation qualité"
        margin="normal"
        onChange={this.onChangeHandlerFilters.bind(this, "validation_qualite_filter")}
        className={classes.selectContainer}
        value={this.state.filters.validation_qualite_filter}
        select
      >
        <MenuItem value="all">Toutes</MenuItem>
        <MenuItem value="oui">Oui</MenuItem>
        <MenuItem value="non">Non</MenuItem>
      </TextField>
    );
  }
  getFilters() {
    const { classes, withoutEntrepriseFilter } = this.props;
    return (
      <Grid container className={classes.filtersContainer}>
        <Grid item xs={12}>
          <Typography
            key="typo"
            variant="button"
            gutterBottom
            className={classes.crudTitle}
          >
            Filtres
          </Typography>
        </Grid>
        <Grid item xs={1}>
          {this.getEtatFilter()}
        </Grid>
        <Grid item xs={2}>
          {this.generateCommercialOrResponsableSelect()}
        </Grid>
        {
          withoutEntrepriseFilter ? null : (
            <Grid item xs={3}>
              {this.getEntrepriseFilter()}
            </Grid>
          )
        }
        <Grid item xs={2}>
          {this.geEtablissementRemontesUuidFilter()}
        </Grid>
        <Grid item xs={2}>
          {this.getDateFilter()}
        </Grid>
        <Grid item xs={1}>
          {this.getIsClosedFilter()}
        </Grid>
        <Grid item xs={1}>
          {this.getIsValidationQualiteFilter()}
        </Grid>
      </Grid>
    );
  }

  geEtablissementRemontesUuidFilter(){
    const { classes } = this.props;
    if(this.state.selectedTab === TABS.INTERNES){
      return (<TextField
        label="Site responsable"
        margin="normal"
        onChange={this.onChangeHandlerFilters.bind(this, "etablissement_responsable_filter_uuid")}
        className={classes.selectContainer}
        SelectProps={{
          multiple: true,
          value: this.state.filters.etablissement_responsable_filter_uuid,
        }}
        select
      >
        <MenuItem value="all">Tous</MenuItem>
        {
          this.state.liste_etablissements.map((etablissement) => {
            return (
              <MenuItem value={etablissement.uuid} key={etablissement.uuid}>
                {etablissement.nom_with_entreprise}
              </MenuItem>
            );
          })
        }
      </TextField>);
    }

    return (
      <TextField
        label="Site remonté"
        margin="normal"
        onChange={this.onChangeHandlerFilters.bind(this, "etablissement_remontes_filter_uuid")}
        className={classes.selectContainer}
        SelectProps={{
          multiple: true,
          value: this.state.filters.etablissement_remontes_filter_uuid,
        }}
        select
      >
        <MenuItem value="all">Tous</MenuItem>
        {
          this.state.liste_etablissements.map((etablissement) => {
            return (
              <MenuItem value={etablissement.uuid} key={etablissement.uuid}>
                {etablissement.nom_with_entreprise}
              </MenuItem>
            );
          })
        }
      </TextField>
    );
  }

  getEntrepriseFilter(){
    if(this.state.selectedTab === TABS.INTERNES){
      return this.getEntrepriseRemonteFilter();
    }
    return this.getEntrepriseResponsableFilter();
  }

  getDeleteModalTitle() {
    return (
      <div>
        <Settings />
        <span>{"Suppression d'une non conformité"}</span>
      </div>
    );
  }

  getDeleteModalContent() {
    return (
      <Typography>
        {"Êtes-vous sûr de vouloir supprimer cette non conformité ?"}
      </Typography>
    );
  }

  getCreateUpdateModalTitle(nc) {
    let title = !!nc
      ? "Modification de la non conformité : " + nc.reference
      : "Ajout d'une nouvelle non conformité";
    return (
      <div>
        <Settings />
        <span>{title}</span>
      </div>
    );
  }

  /**
   * Retourne les options de types de non conformités
   */
  getOptionsType(etablissement, nc, add_all = false) {
    if (!this.props.typeNonConformitesStore.list || !etablissement) {
      return [];
    }

    const all = add_all ? [{ uuid: "all", libelle: "Tous" }] : [];
    return all.concat(this.props.typeNonConformitesStore.list.filter(tcn => {
      return tcn.is_capeco === etablissement.is_capeco &&
        (tcn.is_enabled_front || [nc.type_non_conformites_responsable_uuid, nc.type_non_conformites_remonte_uuid].includes(tcn.uuid));
    }))
    .map((option) => {
      return (
        <MenuItem key={option.uuid} value={option.uuid}>
          {option.libelle}
        </MenuItem>
      );
    });
  }

  /**
   * Retourne les options de litiges
   */
  getOptionsLitiges() {
    if (!this.props.litigesStore.list) {
      return [];
    }
    return this.props.litigesStore.list.map((option) => {
      return (
        <MenuItem key={option.uuid} value={option.uuid} title={option.description}>
          {option.nom}
        </MenuItem>
      );
    });
  }

  /**
   * Retourne les options de criticite
   */
  getOptionsCriticites() {
    const criticites = ["PAS_ANOMALIE", "MINEUR", "MAJEUR", "CRITIQUE"];
    return criticites.map((option) => {
      return (
        <MenuItem key={option} value={option}>
          {option}
        </MenuItem>
      );
    });
  }

  /**
   * Retourne les articles de l'affaire
   */
  getOptionsArticleConcerne(lots) {
    if (!this.props.affaire && lots) {
      return lots.map((lot) => {
        return (
          <MenuItem key={lot.value} value={lot.value}>
            {lot.key}
          </MenuItem>
        );
      });
    }
    if (!this.props.affaire) {
      return [];
    }
    return this.props.affaire.lots.map((lot) => {
      return (
        <MenuItem key={lot.uuid} value={lot.uuid}>
          {lot.article_nom}
        </MenuItem>
      );
    });
  }

  /**
   * Permet de charger les sites suite au changement dans le formulaire d'autocomplete
   * @param  {[type]} entreprise_uuids
   * @return {[type]}
   */
  getSitesFromUuids(entreprise_uuids) {
    collectionActions(this.props.dispatch, "etablissements", "INDEX", {
      params: { entreprise_uuids },
    });
  }

  etablissementRemonteOptions(entreprise_remonte_uuid) {
    if (!entreprise_remonte_uuid) return [];

    if (this.state.entreprise_remonte_uuid !== entreprise_remonte_uuid) {
      this.setState({ entreprise_remonte_uuid: entreprise_remonte_uuid }, () =>
        this.getSitesFromUuids([
          this.state.entreprise_remonte_uuid,
          this.state.entreprise_responsable_uuid,
        ])
      );
    }

    if (!this.props.etablissementsStore.list) {
      return [];
    }
    // Fait une 'shallow copy' pour ne pas modifier 'this.props'
    let etablissements = this.props.etablissementsStore.list.slice();
    if (entreprise_remonte_uuid) {
      etablissements = etablissements.filter(
        (e) => e.entreprise_uuid === entreprise_remonte_uuid
      );
    }
    return etablissements.map((etablissement) => {
      return (
        <MenuItem value={etablissement.uuid} key={etablissement.uuid}>
          {etablissement.nom}
        </MenuItem>
      );
    });
  }

  etablissementResponsableOptions(entreprise_responsable_uuid) {
    if (!entreprise_responsable_uuid) return [];

    if (
      this.state.entreprise_responsable_uuid !== entreprise_responsable_uuid
    ) {
      this.setState(
        { entreprise_responsable_uuid: entreprise_responsable_uuid },
        () =>
          this.getSitesFromUuids([
            this.state.entreprise_remonte_uuid,
            this.state.entreprise_responsable_uuid,
          ])
      );
    }

    if (!this.props.etablissementsStore.list) {
      return [];
    }
    // Fait une 'shallow copy' pour ne pas modifier 'this.props'
    let etablissements = this.props.etablissementsStore.list.slice();
    if (entreprise_responsable_uuid) {
      etablissements = etablissements.filter(
        (e) => e.entreprise_uuid === entreprise_responsable_uuid
      );
    }

    return etablissements.map((etablissement) => {
      return (
        <MenuItem value={etablissement.uuid} key={etablissement.uuid}>
          {etablissement.nom}
        </MenuItem>
      );
    });
  }

  isDisabled(nc, field) {
    if (
     ( nc.criticite === "PAS_ANOMALIE" &&
      ( field === "edit" ||
        field === "validate_sans_envoi" ||
        field === "validate_avec_envoi" ||
        field === "lots_concernes_uuid" ||
        field === "poids_concernes" ||
        field === "details_client" ||
        field === "partie_trois" ))
        ||
        ( nc.criticite === "MINEUR" &&
        ( field === "partie_trois"))
    ) {
      return true;
    }
    return false;
  }

  getCreatedBy(nc){
    if(!nc.uuid){
      return null;
    }
    return (
     <Grid container style={{
      marginBottom: "20px",
     }}>
       <Grid item xs={2}>
          <TextInput
            name="created_by"
            label="Créé par"
            value={nc.created_by_nom_complet}
            disabled
            fullWidth
          />
        </Grid>
        <Grid item xs={2}>
          <TextInput
            name="created_at"
            label="Créé le"
            value={nc.created_at_formated}
            disabled
            fullWidth
          />
        </Grid>
        <Grid item xs={2}>
          <TextInput
            name="updated_by"
            label="Mis à jour par"
            value={nc.updated_by_nom_complet}
            disabled
            fullWidth
          />
        </Grid>
        <Grid item xs={2}>
          <TextInput
            name="updated_at"
            label="Mis à jour le"
            value={nc.updated_at_formated}
            disabled
            fullWidth
          />
        </Grid>
        <Grid item xs={2}>
          <TextInput
            name="validated_by"
            label="Validé par"
            value={nc.validated_by_nom_complet}
            disabled
            fullWidth
          />
        </Grid>
        <Grid item xs={2}>
          <TextInput
            name="validated_at"
            label="Validé le"
            value={nc.validated_at_formated}
            disabled
            fullWidth
          />
        </Grid>
     </Grid>
    )
  }

  getCheckBoxValidation(nc, onChangeHandler, ncsStore){
    if(!nc.validation_qualite_required ){
      return null;
    }
    return (
      <Grid item xs={4}>
          <CheckboxInput
            id="is_validation_qualite_completed"
            label="Validation Qualité"
            value={nc.is_validation_qualite_completed}
            margin="normal"
            collectionStore={ncsStore}
            name="is_validation_qualite_completed"
            onChangeHandler={onChangeHandler}
            fullWidth
            disabled={!hasRights(['admin-nc-verification-qualite'], this.props.user)}
          />
       </Grid>
    )

  }

  getSaveBtn(nc){
    const disable_save = this.isDisabledSave(nc);
    return (
      <Grid container>
        <Grid item xs={4}></Grid>
        <Grid item xs={4}>
          <Button
            variant="contained"
            color="primary"
            onClick={() => collectionActions(this.props.dispatch, "non-conformites", nc.uuid ? "UPDATE" : "CREATE", nc)}
            style={{
              marginBottom: "1rem",
            }}
            disabled={disable_save}
            fullWidth
          >
            Enregistrer
          </Button>
        </Grid>
        <Grid item xs={4}></Grid>
      </Grid>
    )
  }

  isDisabledSave(nc){
    const { nonConformiteStore } = this.props;

    const detailStore = nonConformiteStore.detail || {};
    const fields_followed = [
      'created_at',
      'date_livraison',
      'numero_affaire',
      'etat',
      'criticite',
      'entreprise_responsable_uuid',
      'entreprise_remonte_uuid',
      'details_interne',
      'poids_concernes',
      'consequences',
      'etablissement_remonte_uuid',
      'etablissement_responsable_uuid',
      'details_client',
      'is_consequence_declassement',
      'declassement_montant',
      'is_consequence_retrait',
      'retrait_tonne',
      'is_consequence_refacturation_dib',
      'refacturation_dib_tonne',
      'refacturation_dib_montant',
      'is_consequence_frais_traitement',
      'frais_traitement_tonne',
      'frais_traitement_montant',
      'is_consequence_reprise_lot',
      'affaire_uuid',
      'lots_concernes_uuid',
      'is_closed',
      'documents',
      'selected_documents_uuids',
      'is_validation_qualite_completed',
    ]

    const storeFollowed = fields_followed.reduce((acc, field) => {
      acc[field] = detailStore[field];
      return acc;
    }, {});

    const ncFollowed = fields_followed.reduce((acc, field) => {
      acc[field] = nc[field];
      return acc;
    }, {});

    return JSON.stringify(storeFollowed) === JSON.stringify(ncFollowed);
  }

  getValidationsButtons(nc, onChangeHandler, ncsStore) {
    if(!nc.uuid || !hasRights(['admin-nc-validation'], this.props.user)){
      return null;
    }

    const disable_save = this.isDisabledSave(nc);

    return (
      <Grid container>
        <Grid item xs={3}>
          <Button
            variant="contained"
            color="primary"
            disabled={!nc.is_validatable || nc.etat === '4-VALIDEE_AVEC_ENVOI' || nc.etat === '3-VALIDEE_SANS_ENVOI' || !disable_save}
            onClick={this.validateNcLocal.bind(this, nc)}
          >
            Valider sans envoi
          </Button>
        </Grid>
        {this.getEmailValidationBtn(nc, disable_save)}
        {this.getEmailPreviewBtn(nc, disable_save)}
        <Grid item xs={3}>
          <CheckboxInput
            id="is_closed"
            label="NC Cloturée"
            value={nc.is_closed}
            margin="normal"
            collectionStore={ncsStore}
            name="is_closed"
            onChangeHandler={onChangeHandler}
            fullWidth
            disabled={!hasRights(['admin-nc-validation'], this.props.user)
              || !(['3-VALIDEE_SANS_ENVOI', '4-VALIDEE_AVEC_ENVOI'].includes(nc.etat)
                    || nc.criticite === 'PAS_ANOMALIE'
                )
            }
          />
       </Grid>
      </Grid>
    )
  }
  getEmailPreviewBtn(nc, disable_save){
    if(!nc.emails_rendered['preview']) return (
      <Grid item xs={3}>
      <Button
        variant="contained"
        color="primary"
        disabled={true}
      >
        Version provisoire
      </Button>
    </Grid>
    );
    const emailStringlified = JSON.stringify(nc.emails_rendered['preview']);
    return (
      <Grid item xs={3}>
        <EmailButton
          email={nc.emails_rendered['preview']}
          key={nc.uuid + emailStringlified}
          label={"Version provisoire"}
          required=""
          disabled={(nc.etat === '4-VALIDEE_AVEC_ENVOI' || nc.etat === '3-VALIDEE_SANS_ENVOI') || !disable_save}
        />
      </Grid>
    )
  }
  getEmailValidationBtn(nc, disable_save){
    if(!nc.emails_rendered['email_validation']) return (
      <Grid item xs={3}>
        <Button
          variant="contained"
          color="primary"
          disabled={true}
        >
          Valider avec envoi
        </Button>
      </Grid>
    );
    const emailStringlified = JSON.stringify(nc.emails_rendered['email_validation']);
    return (
      <Grid item xs={3}>
        <EmailButton
          email={nc.emails_rendered['email_validation']}
          key={nc.uuid + emailStringlified + (nc.is_validatable ? 'validatable' : 'not-validatable')}
          label={"Valider avec envoi"}
          required=""
          disabled={!nc.is_validatable || nc.etat === '4-VALIDEE_AVEC_ENVOI' || nc.etat === '3-VALIDEE_SANS_ENVOI' || !disable_save}
          onSent={(email) => {
            if(email && email.send_at !== null && nc.is_validatable){
              this.validateNcLocal(nc);
            }
          }}
        />
      </Grid>
    )
  }

  validateNcLocal(nc){
    validateNc(this.props.dispatch, nc.uuid, (nc_updated) => {
    })
  }

  getCreateUpdateModalContent(nc, ncsStore, onChangeHandler) {
    let {
      created_at,
      date_livraison,
      numero_affaire,
      etat_label,
      entreprise_responsable_uuid,
      entreprise_remonte_uuid,
      details_interne,
      poids_concernes,
      consequences,
      etablissement_remonte_uuid,
      etablissement_responsable_uuid,
      details_client,
      is_consequence_declassement,
      declassement_montant,
      is_consequence_retrait,
      retrait_tonne,
      is_consequence_refacturation_dib,
      refacturation_dib_tonne,
      refacturation_dib_montant,
      is_consequence_frais_traitement,
      frais_traitement_tonne,
      frais_traitement_montant,
      is_consequence_reprise_lot,
      affaire_uuid,
      lots_concernes_uuid,
      articles_disponibles,
    } = nc;

    const disabled = nc.validated_by !== null && nc.uuid && !hasRights(['admin-nc-validation'], this.props.user);

    const {
      etablissementsStore,
    } = this.props;

    const disable_validationBtn = disabled ? 'NONE' : 'Enregistrer et quitter';
    if(disable_validationBtn !== this.state.disable_validationBtn){
      this.setState({
        disable_validationBtn: disable_validationBtn,
      })
    }

    const { is_capeco_remonte, is_capeco_responsable } = this.state;

    const etablissement_remonte = etablissementsStore && etablissementsStore.list ? etablissementsStore.list.find(e => e.uuid === etablissement_remonte_uuid) : null;
    const etablissement_responsable = etablissementsStore && etablissementsStore.list ?  etablissementsStore.list.find(e => e.uuid === etablissement_responsable_uuid) : null;

    if(etablissement_remonte && etablissement_remonte.is_capeco !== is_capeco_remonte){
      this.setState({ is_capeco_remonte: etablissement_remonte.is_capeco });
    }

    if(etablissement_responsable && etablissement_responsable.is_capeco !== is_capeco_responsable){
      this.setState({ is_capeco_responsable: etablissement_responsable.is_capeco });
    }

    const {
      classes,
    } = this.props;


    return (
      <Grid container className={classes.scrollAuto}>
        {this.getCreatedBy(nc)}
        <Grid item xs={3}>
          <DateInput
            label="Date NC"
            name="created_at"
            format="d MMMM yyyy"
            onChangeHandler={onChangeHandler}
            collectionStore={ncsStore}
            disabled
            value={created_at}
            fullWidth
          />
        </Grid>
        <Grid item xs={3}>
          <TextInput
            name="numero_affaire"
            label="N° affaire"
            value={numero_affaire}
            onChangeHandler={onChangeHandler}
            fullWidth
            disabled
          />
        </Grid>
        <Grid item xs={3}>
          <DateInput
            label="Date livraison"
            name="date_livraison"
            format="d MMMM yyyy"
            onChangeHandler={onChangeHandler}
            collectionStore={ncsStore}
            disabled
            value={date_livraison}
            fullWidth
          />
        </Grid>
        <Grid item xs={3}>
          <TextInput
            name="etat_label"
            label="Etat NC"
            value={etat_label}
            onChangeHandler={onChangeHandler}
            fullWidth
            disabled
          />
        </Grid>
        <Grid container>
          <Grid item xs={4}>
            <TextInput
              label="Entreprise responsable"
              className={classes.selectContainer}
              value={entreprise_responsable_uuid}
              margin="normal"
              collectionStore={ncsStore}
              name="entreprise_responsable_uuid"
              onChangeHandler={onChangeHandler}
              type="autocomplete"
              autocompleteProps={{
                collectionName: "entreprises",
              }}
              fullWidth
              disabled={disabled}
            />
          </Grid>
          <Grid item xs={4}>
            <TextField
              id="etablissement_responsable_uuid"
              label="Site responsable"
              margin="normal"
              onChange={(e) =>
                onChangeHandler("etablissement_responsable_uuid", e)
              }
              className={classes.selectContainer}
              SelectProps={{
                value: etablissement_responsable_uuid,
              }}
              select
              disabled={disabled || !entreprise_responsable_uuid}
            >
              {this.etablissementResponsableOptions(
                entreprise_responsable_uuid
              )}
            </TextField>
          </Grid>
          <Grid item xs={4}>
            <TextField
              id="type_non_conformites_responsable_uuid"
              label="Type responsable"
              margin="normal"
              onChange={(e) =>
                onChangeHandler("type_non_conformites_responsable_uuid", e)
              }
              className={classes.selectContainer}
              select
              value={nc.type_non_conformites_responsable_uuid}
              disabled={disabled || !etablissement_responsable}
            >
              {this.getOptionsType(etablissement_responsable,nc)}
            </TextField>
          </Grid>
        </Grid>
        {/* LIGNE TROIS */}
        <Grid container>
          <Grid item xs={4}>
            <TextInput
              label="Entreprise remontée"
              className={classes.selectContainer}
              value={entreprise_remonte_uuid}
              margin="normal"
              collectionStore={ncsStore}
              name="entreprise_remonte_uuid"
              onChangeHandler={onChangeHandler}
              type="autocomplete"
              autocompleteProps={{
                collectionName: "entreprises",
              }}
              fullWidth
              disabled={disabled}
            />
          </Grid>
          <Grid item xs={4}>
            <TextField
              id="etablissement_remonte_uuid"
              label="Site remontée"
              margin="normal"
              onChange={(e) => onChangeHandler("etablissement_remonte_uuid", e)}
              className={classes.selectContainer}
              SelectProps={{
                value: etablissement_remonte_uuid,
              }}
              select
              disabled={disabled || !entreprise_remonte_uuid}
            >
              {this.etablissementRemonteOptions(entreprise_remonte_uuid)}
            </TextField>
          </Grid>
          <Grid item xs={4}>
            <TextField
              id="type_non_conformites_remonte_uuid"
              label="Type remontée"
              margin="normal"
              onChange={(e) =>
                onChangeHandler("type_non_conformites_remonte_uuid", e)
              }
              className={classes.selectContainer}
              select
              value={nc.type_non_conformites_remonte_uuid}
              disabled={disabled || !etablissement_remonte}
            >
              {this.getOptionsType(etablissement_remonte,nc)}
            </TextField>
          </Grid>
        </Grid>
        {/* LIGNE 4 */}
        <Grid container>
          <Grid item xs={8}>
            <TextField
              id="litige_uuid"
              label="Motif NC"
              margin="normal"
              onChange={(e) => {
                onChangeHandler("litige_uuid", e);
                const litige = this.props.litigesStore.list.find(l => l.uuid === e.target.value);
                if (litige) {
                  setTimeout(() =>{
                    onChangeHandler("details_interne", { target: { value: litige.verbatim } });
                  }, 500)
                }
              }}
              value={nc.litige_uuid}
              className={classes.selectContainer}
              select
              disabled={disabled}
            >
              {this.getOptionsLitiges()}
            </TextField>
          </Grid>
        </Grid>
        {/* LIGNE 5 */}
        <Grid container className={classes.checkboxContainer}>
          <TextArea
            id="details_interne"
            label="Détails internes"
            value={details_interne}
            disabled={disabled}
            name="details_interne"
            onChangeHandler={onChangeHandler}
            collectionStore={ncsStore}
            rows={8}
          />
        </Grid>
        <hr className={classes.hr} />
        <Grid container>
          <Grid item xs={4}>
            <TextField
              id="criticite"
              label="Criticite"
              margin="normal"
              onChange={(e) => onChangeHandler("criticite", e)}
              className={classes.selectContainer}
              select
              value={nc.criticite}
              disabled={disabled}
            >
              {this.getOptionsCriticites()}
            </TextField>
          </Grid>
          <Grid item xs={4}>
            <TextField
              id="lots_concernes_uuid"
              label="Article(s) concerné(s)"
              margin="normal"
              disabled={this.isDisabled(nc, "lots_concernes_uuid") || disabled}
              className={classes.selectContainer}
              select
              SelectProps={{
                multiple: true,
                value: lots_concernes_uuid ?? [],
                onChange: (e) => onChangeHandler("lots_concernes_uuid", e),
              }}
            >
              {this.getOptionsArticleConcerne(articles_disponibles)}
            </TextField>
          </Grid>
          <Grid item xs={4}>
            <TextInput
              name="poids_concernes"
              label="Poids concerné (tonnes)"
              disabled={this.isDisabled(nc, "poids_concernes") || disabled}
              value={poids_concernes}
              className={classes.inputfield}
              onChangeHandler={onChangeHandler}
              fullWidth
            />
          </Grid>
        </Grid>
        {/* LIGNE 7 */}
        <Grid container>
          <TextArea
            id="details_client"
            label="Description affichée dans la NC"
            value={details_client}
            disabled={this.isDisabled(nc, "details_client") || disabled}
            name="details_client"
            onChangeHandler={onChangeHandler}
            collectionStore={ncsStore}
            rows={8}
          />
        </Grid>
        <hr className={classes.hr} />
        <Grid container>
          <Grid item className={classes.checkboxCase}>
            <CheckboxInput
              id="is_consequence_declassement"
              label="Déclassement prix matière de"
              disabled={this.isDisabled(nc, "partie_trois") || disabled}
              value={is_consequence_declassement}
              margin="normal"
              name="is_consequence_declassement"
              onChangeHandler={(name,e) => {
                this.onChangeHandlerConsequences(name, e, nc, onChangeHandler)
              }}
            />
          </Grid>
          <Grid item xs={4} className={classes.checkBoxInputField}>
            <TextInput
              name="declassement_montant"
              label="€/t"
              value={declassement_montant}
              className={classes.inputfield}
              disabled={!is_consequence_declassement || disabled}
              onChangeHandler={(name,e) => {
                this.onChangeHandlerConsequences(name, e, nc, onChangeHandler)
              }}
              fullWidth
            />
          </Grid>
        </Grid>
        <Grid container>
          <Grid item className={classes.checkboxCase}>
            <CheckboxInput
              id="is_consequence_retrait"
              label="Retrait poids matière de "
              value={is_consequence_retrait}
              disabled={this.isDisabled(nc, "partie_trois") || disabled}
              margin="normal"
              name="is_consequence_retrait"
              onChangeHandler={(name,e) => {
                this.onChangeHandlerConsequences(name, e, nc, onChangeHandler)
              }}
            />
          </Grid>
          <Grid item xs={4} className={classes.checkBoxInputField}>
            <TextInput
              name="retrait_tonne"
              label="t"
              value={retrait_tonne}
              className={classes.inputfield}
              disabled={!is_consequence_retrait || disabled}
              onChangeHandler={(name,e) => {
                this.onChangeHandlerConsequences(name, e, nc, onChangeHandler)
              }}
              fullWidth
            />
          </Grid>
        </Grid>

        <Grid container>
          <Grid item className={classes.checkboxCase}>
            <CheckboxInput
              id="is_consequence_refacturation_dib"
              label="Refacturation DIB"
              value={is_consequence_refacturation_dib}
              disabled={this.isDisabled(nc, "partie_trois") || disabled}
              margin="normal"
              name="is_consequence_refacturation_dib"
              onChangeHandler={(name,e) => {
                this.onChangeHandlerConsequences(name, e, nc, onChangeHandler)
              }}
            />
          </Grid>
          <Grid item xs={3} className={classes.checkBoxInputField}>
            <TextInput
              name="refacturation_dib_tonne"
              label="t"
              value={refacturation_dib_tonne}
              className={classes.inputfield}
              disabled={!is_consequence_refacturation_dib || disabled}
              onChangeHandler={(name,e) => {
                this.onChangeHandlerConsequences(name, e, nc, onChangeHandler)
              }}
              fullWidth
            />
          </Grid>
          <Grid item xs={3} className={classes.checkBoxInputField}>
            <TextInput
              name="refacturation_dib_montant"
              label="€/t"
              value={refacturation_dib_montant}
              className={classes.inputfield}
              disabled={!is_consequence_refacturation_dib || disabled}
              onChangeHandler={(name,e) => {
                this.onChangeHandlerConsequences(name, e, nc, onChangeHandler)
              }}
              fullWidth
            />
          </Grid>
        </Grid>

        <Grid container>
          <Grid item className={classes.checkboxCase}>
            <CheckboxInput
              id="is_consequence_frais_traitement"
              label="Refacturation frais de traitement"
              disabled={this.isDisabled(nc, "partie_trois") || disabled}
              value={is_consequence_frais_traitement}
              margin="normal"
              name="is_consequence_frais_traitement"
              onChangeHandler={(name,e) => {
                this.onChangeHandlerConsequences(name, e, nc, onChangeHandler)
              }}
            />
          </Grid>
          <Grid item xs={3} className={classes.checkBoxInputField}>
            <TextInput
              name="frais_traitement_tonne"
              label="t"
              value={frais_traitement_tonne}
              className={classes.inputfield}
              disabled={!is_consequence_frais_traitement || disabled}
              onChangeHandler={(name,e) => {
                this.onChangeHandlerConsequences(name, e, nc, onChangeHandler)
              }}
              fullWidth
            />
          </Grid>
          <Grid item xs={3} className={classes.checkBoxInputField}>
            <TextInput
              name="frais_traitement_montant"
              label="€/t"
              value={frais_traitement_montant}
              className={classes.inputfield}
              disabled={!is_consequence_frais_traitement || disabled}
              onChangeHandler={(name,e) => {
                this.onChangeHandlerConsequences(name, e, nc, onChangeHandler)
              }}
              fullWidth
            />
          </Grid>

          <Grid container className={classes.checkboxContainer}>
            <Grid item className={classes.checkboxCase}>
              <CheckboxInput
                id="is_consequence_reprise_lot"
                label="Reprise du lot à vos frais"
                disabled={this.isDisabled(nc, "partie_trois") || disabled}
                value={is_consequence_reprise_lot}
                margin="normal"
                name="is_consequence_reprise_lot"
                onChangeHandler={(name,e) => {
                this.onChangeHandlerConsequences(name, e, nc, onChangeHandler)
              }}
              />
            </Grid>
          </Grid>
        </Grid>
        <TextArea
          label="Conséquences"
          value={consequences}
          disabled={this.isDisabled(nc, "partie_trois") || disabled}
          name="consequences"
          onChangeHandler={onChangeHandler}
          collectionStore={ncsStore}
          rows={8}
        />
        <hr className={classes.hr} />
        <Grid item xs={12}>
          <Typography
            variant="button"
            gutterBottom
            className={classes.crudTitle}
          >
            Documents de l'affaire
          </Typography>
        </Grid>
        {this.getDocuments(nc, affaire_uuid, onChangeHandler)}

        {this.getCheckBoxValidation(nc, onChangeHandler, ncsStore)}

        {this.getSaveBtn(nc)}

        {this.getValidationsButtons(nc, onChangeHandler, ncsStore)}
      </Grid>
    );
  }

  getDocuments(nc, affaire_uuid, onChangeHandler) {
    return (
      <Documents
        documentable_type="App\Models\Affaire"
        documentable_uuid={affaire_uuid}
        selectable={true}
        select_callback={(document) => {
          let new_documents = [...nc.documents];
          if(nc.documents.find(d => d.filename === document.filename)){
            new_documents = nc.documents.filter(d => d.filename !== document.filename);
          }else{
            new_documents.push(document);
          }

          onChangeHandler("documents", { target: { value: new_documents } });
          setTimeout(() => {
            onChangeHandler("selected_documents_uuids", { target: { value: new_documents.map(d => d.uuid) } });
          }, 100);
        }}
        selected_documents={nc.documents}
        rowsPerPageOptions={[50]}
        rowsPerPage={50}
      />
    )
  }

  onChangeHandlerConsequences(name, e, nc, onChangeHandler) {
    onChangeHandler(name, e);
    const value = e.target.value;
    setTimeout(() => {
      onChangeHandler("consequences", { target: { value: this.generateConsquencesText({
        ...nc,
        [name]: value
      }) } });
    }, 500);
  }

  generateConsquencesText(nc) {
    let {
      is_consequence_declassement,
      declassement_montant,
      is_consequence_retrait,
      retrait_tonne,
      is_consequence_refacturation_dib,
      refacturation_dib_tonne,
      refacturation_dib_montant,
      is_consequence_frais_traitement,
      frais_traitement_tonne,
      frais_traitement_montant,
      is_consequence_reprise_lot,
    } = nc;

    let consequencesText = "";
    if (is_consequence_declassement) {
      consequencesText += `Déclassement prix matière de ${declassement_montant} €/t\n`;
    }
    if (is_consequence_retrait) {
      consequencesText += `Retrait poids matière de ${retrait_tonne} t\n`;
    }
    if (is_consequence_refacturation_dib) {
      consequencesText += `Refacturation DIB ${refacturation_dib_tonne} t à ${refacturation_dib_montant} €/t\n`;
    }
    if (is_consequence_frais_traitement) {
      consequencesText += `Refacturation frais de traitement ${frais_traitement_tonne} t à ${frais_traitement_montant} €/t\n`;
    }
    if (is_consequence_reprise_lot) {
      consequencesText += `Reprise du lot à vos frais\n`;
    }

    return consequencesText;
  }

  loadAsyncData(filters) {
    // eslint-disable-next-line
    if (filters !== undefined) this.state.current_filter = {
      ...this.state.current_filter,
      ...filters,
    };

    const { affaire } = this.props;

    return collectionActions(
      this.props.dispatch,
      "non-conformites",
      "INDEX",
      {
        params: {
          ...this.state.current_filter,
          affaire_uuid: affaire?.uuid,
          type: this.state.selectedTab,
          entreprise_responsable_uuid: this.props.entreprise_uuid,
          commercial_responsable_uuid: this.props.commercial_responsable_uuid,
        },
      },
      null,
      true
    );
  }

  getCellConfigExterne() {
    const { classes } = this.props;
    return [
      {
        datakey: "etat_label",
        title: "Etat",
        sortable: true,
        searchable: true,
      },
      {
        datakey: "criticite_label",
        title: "Criticité",
        sortable: true,
        searchable: true,
      },
      {
        datakey: "reference",
        title: "N° NC",
        sortable: true,
        searchable: true,
      },
      {
        datakey: "numero_affaire",
        title: "N° affaire",
        sortable: true,
        searchable: true,
        format: (nc) => {
          return (
            <a
              className={classes.link}
              href={buildRoute("/affaires/:uuid", {uuid: nc.affaire_uuid,})}
              target="_blank"
              rel="noopener noreferrer"
            >
              {nc.numero_affaire}
            </a>
          );
        },
      },
      {
        datakey: "entreprise_responsable_nom",
        title: "Responsable",
        sortable: true,
        searchable: true,
        format: (nc) => {
          return (
            <a
              className={classes.link}
              href={buildRoute("/entreprises/:uuid", {uuid: nc.entreprise_responsable_uuid,})}
              target="_blank"
              rel="noopener noreferrer"
            >
              {nc.entreprise_responsable_nom}
            </a>
          );
        },
      },
      {
        datakey: "etablissement_remonte_nom",
        title: "Remonté site",
        sortable: true,
        searchable: true,
      },
      {
        datakey: "litige_libelle",
        title: "Motif",
        sortable: true,
        searchable: true,
      },
      {
        datakey: "details_interne",
        title: "Détail interne",
        sortable: true,
        searchable: true,
      },
      {
        datakey: "commercial_responsable_nom_complet",
        title: "Commercial",
        sortable: true,
        searchable: true,
      },
      {
        datakey: "closed_at",
        title: "Cloturée",
        sortable: true,
        searchable: true,
        format: (obj) => (obj.closed_at ? "Oui" : "Non"),
      },
      {
        datakey: "is_validation_qualite_completed",
        title: "Validation Qualité",
        sortable: true,
        searchable: true,
        format: (obj) => (obj.is_validation_qualite_completed ? "Oui" : "Non"),
      }
    ];
  }

  getCellConfigInterne() {
    const { classes } = this.props;
    return [
      {
        datakey: "etat_label",
        title: "Etat",
        sortable: true,
        searchable: true,
      },
      {
        datakey: "criticite_label",
        title: "Criticité",
        sortable: true,
        searchable: true,
      },
      {
        datakey: "reference",
        title: "N° NC",
        sortable: true,
        searchable: true,
      },
      {
        datakey: "numero_affaire",
        title: "N° affaire",
        sortable: true,
        searchable: true,
        format: (nc) => {
          return (
            <a
              className={classes.link}
              href={buildRoute("/affaires/:uuid", {uuid: nc.affaire_uuid,})}
              target="_blank"
              rel="noopener noreferrer"
            >
              {nc.numero_affaire}
            </a>
          );
        },
      },
      {
        datakey: "entreprise_remonte_nom",
        title: "Remontée entreprise",
        sortable: true,
        searchable: true,
        format: (nc) => {
          return (
            <a
              className={classes.link}
              href={buildRoute("/entreprises/:uuid", {uuid: nc.etablissement_remonte_uuid,})}
              target="_blank"
              rel="noopener noreferrer"
            >
              {nc.entreprise_responsable_nom}
            </a>
          );
        },
      },
      {
        datakey: "litige_libelle",
        title: "Motif",
        sortable: true,
        searchable: true,
      },
      {
        datakey: "details_interne",
        title: "Detail interne",
        sortable: true,
        searchable: true,
      },
      {
        datakey: "type_non_conformites_responsable_libelle",
        title: "Responsable Type",
        sortable: true,
        searchable: true,
      },
      {
        datakey: "closed_at",
        title: "Cloturée",
        sortable: true,
        searchable: true,
        format: (obj) => (obj.closed_at ? "Oui" : "Non"),
      },
      {
        datakey: "is_validation_qualite_completed",
        title: "Validation Qualité",
        sortable: true,
        searchable: true,
        format: (obj) => (obj.is_validation_qualite_completed ? "Oui" : "Non"),
      }
    ];
  }

  listNonConformites(cellsConfig) {
    const { classes, createdDefaultValues, affaire } = this.props;
    return (
      <Grid container className={classes.container}>
        <CollectionCrud
          collectionName={"non-conformites"}
          showBtnEdit={true}
          showBtnAdd={!!affaire}
          showBtnDelete={true}
          disabledEnterModal={true}
          asynchrone={true}
          modalMaxWidth="lg"
          asynchroneCallbackLoadData={this.loadAsyncData.bind(this)}
          rights={{
            create: ["admin-nc-creation"],
            edit: ["admin-nc-creation"],
            delete: "admin-nc-suppression",
          }}
          deleteModalTitle={this.getDeleteModalTitle}
          deleteModalContent={this.getDeleteModalContent}
          createUpdateModalTitle={this.getCreateUpdateModalTitle}
          createUpdateModalContent={this.getCreateUpdateModalContent.bind(this)}
          hideScroll={true}
          datatableConfig={{
            showPagination: true,
            showSearch: true,
            defaultSort: "reference",
            sortType: "desc",
            rowsPerPageOptions: [25, 50],
          }}
          createUpdateModalSubmit={this.state.disable_validationBtn}
          cellsConfig={cellsConfig()}
          defaultValuesCallback={() => {
            return createdDefaultValues || {};
          }}
          getAsyncFilter={(filter) =>
            // eslint-disable-next-line
            (this.state.current_filter = {
              ...this.state.current_filter,
              ...filter,
            })
          }
          actionsCallback={(action, data) => {
            switch (action) {
              case "close":
                collectionActions(this.props.dispatch, "documents", "INDEX", {
                  params: {
                    documentable_type: "App\\Models\\Affaire",
                    documentable_uuid: this.props.affaire?.uuid,
                  },
                });
                this.setState({
                  is_capeco_responsable: false,
                  is_capeco_remonte: false,
                })
                break;
              default:
                break;
            }
          }}
          disableLoadCollectionFromDerivedState={true}
        />
      </Grid>
    );
  }

  handleChangeTab(event, selectedTab) {
    const { history } = this.props;
    this.setState({ selectedTab });
    history.push({
      pathname: `/non-conformites/${selectedTab}`,
      state: { selectedTab },
    });
  }

  getNonConformitesTabs() {
    return [
      <Tab label={TABS.EXTERNES} value={TABS.EXTERNES} />,
      <Tab label={TABS.INTERNES} value={TABS.INTERNES} />,
    ];
  }

  getNonConformitesList(selectedTab) {
    const config = {
      [TABS.EXTERNES]: this.getCellConfigExterne.bind(this),
      [TABS.INTERNES]: this.getCellConfigInterne.bind(this),
    };
    return (
      // L'attribut key est indispensable pour que la table soit rafraîchie au
      // changement d'onglet !
      <TabContainer key={selectedTab}>
        {this.listNonConformites(config[selectedTab])}
      </TabContainer>
    );
  }

  render() {
    if (this.props.affaire || this.props.entreprise_uuid || this.props.commercial_responsable_uuid) {
      return <>
        {this.getFilters()}
        {this.getNonConformitesList(TABS.EXTERNES)}
      </>;
    }
    const { selectedTab } = this.state;
    return (
      <>
        <Tabs value={selectedTab} onChange={this.handleChangeTab.bind(this)}>
          {this.getNonConformitesTabs()}
        </Tabs>
        {this.getFilters()}
        {this.getNonConformitesList(selectedTab ?? TABS.EXTERNES)}
      </>
    );
  }
}

NonConformite = withStyles(NonConformiteCss)(NonConformite);

NonConformite = connect((store) => {
  return {
    typeNonConformitesStore: store.collections["type-non-conformites"],
    litigesStore: store.collections["litiges"],
    nonConformiteStore: store.collections["non-conformites"],
    etablissementsStore: store.collections.etablissements,
    usersStore: store.collections.users,
    user: store.auth.user,
  };
})(NonConformite);

export default NonConformite;
