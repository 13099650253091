import React, { Component } from "react";
import { withStyles } from "@material-ui/core/styles";
import { Grid, Typography } from "@material-ui/core";
import { Settings } from "@material-ui/icons";
import TextInput from "../../common/Components/TextInput";
import ActiviteCss from "./css/ActiviteCss";
import CollectionCrud from "../../common/Components/CollectionCrud";
import CheckboxInput from "../../common/Components/CheckboxInput";

class Activite extends Component {
  getDeleteModalTitle() {
    return (
      <div>
        <Settings />
        <span>{"Suppression de l'activité"}</span>
      </div>
    );
  }

  getDeleteModalContent() {
    return (
      <Typography>
        {"Êtes-vous sûr de vouloir supprimer cette activité ?"}
      </Typography>
    );
  }

  getCreateUpdateModalTitle(activite) {
    let title = !!activite
      ? "Modification de l'activité : " + activite.nom
      : "Ajout d'une nouvelle activité";
    return (
      <div>
        <Settings />
        <span>{title}</span>
      </div>
    );
  }

  getCreateUpdateModalContent(activite, activitesStore, onChangeHandler) {
    let { nom, producteur_dechets } = activite;
    return (
      <Grid container>
        <Grid item xs={12}>
          <TextInput
            id="nom"
            label="Nom"
            value={nom}
            margin="normal"
            collectionStore={activitesStore}
            name="nom"
            onChangeHandler={onChangeHandler}
            fullWidth
          />
        </Grid>
        <Grid item xs={12}>
          <CheckboxInput
            id="producteur_dechets"
            label="Producteur de déchets"
            value={producteur_dechets}
            margin="normal"
            collectionStore={producteur_dechets}
            name="producteur_dechets"
            onChangeHandler={onChangeHandler}
            fullWidth
          />
        </Grid>
      </Grid>
    );
  }

  render() {
    const { classes } = this.props;
    return (
      <Grid container className={classes.container}>
        <CollectionCrud
          collectionName={"activites"}
          showBtnEdit={true}
          showBtnAdd={true}
          showBtnDelete={true}
          rights={{
            create: "admin-cud-parametres",
            edit: "admin-cud-parametres",
            delete: "admin-cud-parametres",
          }}
          deleteModalTitle={this.getDeleteModalTitle}
          deleteModalContent={this.getDeleteModalContent}
          createUpdateModalTitle={this.getCreateUpdateModalTitle}
          createUpdateModalContent={this.getCreateUpdateModalContent.bind(this)}
          datatableConfig={{
            showPagination: true,
            showSearch: true,
            defaultSort: "nom",
            sortType: "desc",
            rowsPerPageOptions: [10, 25, 50],
          }}
          cellsConfig={[
            {
              datakey: "nom",
              title: "Nom",
              sortable: true,
              searchable: true,
            },
            {
              datakey: "producteur_dechets",
              title: "Producteur de déchets",
              sortable: true,
              searchable: true,
              format: (obj, key) => (obj[key] ? "Oui" : "Non"),
            },
          ]}
        />
      </Grid>
    );
  }
}

Activite = withStyles(ActiviteCss)(Activite);

export default Activite;
