export default theme => ({
  container: {
    height: '100%',
    display: 'flex',
  },
  formControl: {
    alignSelf: 'end',
    marginBottom: '0.5em',
  },
  helper: {
    marginTop: '0',
    position: 'absolute',
    bottom: '-2em',
  },
})
