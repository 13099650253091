import React, {Component} from 'react'

import {Grid, Typography} from '@material-ui/core'
import {withStyles} from '@material-ui/core/styles'
import {Settings} from '@material-ui/icons'

import TextInput from '../../common/Components/TextInput'
import CollectionCrud from '../../common/Components/CollectionCrud'
import RaisonFacturationCss from './css/RaisonFacturationCss'
import CheckboxInput from '../../common/Components/CheckboxInput'

class RaisonFacturation extends Component {

  getDeleteModalTitle() {
    return (
      <div>
        <Settings/>
        <span>{"Suppression de la raison de facturation"}</span>
      </div>
    )
  }

  getDeleteModalContent() {
    return (
      <Typography>
        {'Êtes-vous sûr de vouloir supprimer cette raison de facturation ?'}
      </Typography>
    )
  }

  getCreateUpdateModalTitle(raison_facturation) {
    let title = !!raison_facturation
      ? "Modification de la raison de facturation : " + raison_facturation.libelle
      : "Ajout d'une nouvelle raison de facturation"
    return (
      <div>
        <Settings/>
        <span>{title}</span>
      </div>
    )
  }

  getCreateUpdateModalContent(raison_facturation, raison_facturationsStore, onChangeHandler) {
    let {libelle, is_taf} = raison_facturation;
    return (
      <Grid container>
        <Grid item xs={12}>
          <TextInput
            id="libelle"
            label="Libelle"
            value={libelle}
            margin="normal"
            collectionStore={raison_facturationsStore}
            name='libelle'
            onChangeHandler={onChangeHandler}
            fullWidth
          />
        </Grid>
        <Grid item xs={12}>
          <CheckboxInput
            id="is_taf"
            label="Type TAF"
            value={is_taf}
            margin="normal"
            collectionStore={raison_facturationsStore}
            name='is_taf'
            onChangeHandler={onChangeHandler}
            fullWidth
          />
        </Grid>
      </Grid>
    )
  }

  render() {
    const {classes} = this.props
    return (
      <Grid container className={classes.container}>
        <CollectionCrud
          collectionName={'raison_facturations'}
          showBtnEdit={true}
          showBtnAdd={true}
          showBtnDelete={true}
          rights={{
            create: 'admin-cud-parametres',
            edit: 'admin-cud-parametres',
            delete: 'admin-cud-parametres'
          }}
          deleteModalTitle={this.getDeleteModalTitle}
          deleteModalContent={this.getDeleteModalContent}
          createUpdateModalTitle={this.getCreateUpdateModalTitle}
          createUpdateModalContent={this.getCreateUpdateModalContent.bind(this)}
          datatableConfig={{
            showPagination: true,
            showSearch: true,
            defaultSort: "libelle",
            sortType: 'desc',
            rowsPerPageOptions: [10, 25, 50],
          }}
          cellsConfig={[
            {
              datakey: "libelle",
              title: "Libelle",
              sortable: true,
              searchable: true,
            },
            {
              datakey: "is_taf",
              title: "Type TAF",
              sortable: true,
              searchable: true,
              format: (obj) => {
                return obj.is_taf ? 'Oui' : 'Non'
              }
            },
        ]}
        />
      </Grid>
    )
  }
}

RaisonFacturation = withStyles(RaisonFacturationCss)(RaisonFacturation);

export default RaisonFacturation
