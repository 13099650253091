import React, {Component} from 'react'
import {connect} from 'react-redux'
import PropTypes from 'prop-types'

import {TextField} from '@material-ui/core'
import {withStyles} from '@material-ui/core/styles/index'

import NumberInputCss from './css/NumberInputCss'


class NumberInput extends Component {

  static defaultProps = {
    decimalDigits: 0,
    decimalSeparator: ',',
    thousandsSeparator: ' ',
  }

  static propTypes = {
    decimalDigits: PropTypes.number,
    decimalSeparator: PropTypes.string,
    thousandsSeparator: PropTypes.string,
  }
  state = {}

  static getDerivedStateFromProps(nextProps, prevState) {
    let newState = {...prevState}
    if (nextProps.name && nextProps.value !== prevState[nextProps.name]) {
      newState = {
        ...newState,
        // valeur brute pour le parent puis la base, ex. 1234.56
        [nextProps.name]: nextProps.value,
        // valeur mise en forme pour l'interface client
        formatted: NumberInput.formatNumber(nextProps.value),
      }
    }
    return newState
  }

  static formatNumber(number) {
    return !!number
      ? number.toString().replace('.', ',')
      : number
  }

  static flattenNumber(number) {
    return !!number
      ? number.toString().replace(',', '.')
      : number
  }

  getErrorMessage(name) {
    const {collectionStore} = this.props
    if (collectionStore.errors && collectionStore.errors[name]) {
      return collectionStore.errors[name]
        .map((err, index) =>
          <span style={{display: 'block'}} key={index}>{err}</span>
        )
    } else {
      return null
    }
  }


  onChangeHandler(name, event) {
    const formatted = NumberInput.formatNumber(event.target.value)
    const flat = NumberInput.flattenNumber(event.target.value)
    this.setState({[name]: flat, formatted: formatted})
    const dummy = {target: {value: flat}}
    this.props.onChangeHandler(name, dummy)
  }

  render() {
    const {collectionStore, name, value} = this.props
    const {formatted} = this.state
    const filteredProps = [
      'name', 'collectionStore', 'onChangeHandler', 'value',
      'dispatch',
      'decimalDigits', 'decimalSeparator', 'thousandsSeparator',
    ]
    let inputProps = Object.keys(this.props)
      .filter(k => !filteredProps.includes(k))
      .reduce(
        (obj, key) => {
          obj[key] = this.props[key]
          return obj
        },
        {}
      )
    const error = !!collectionStore.errors && !!collectionStore.errors[name]
    return (
      <TextField
        error={error}
        helperText={this.getErrorMessage(name)}
        value={value ? formatted : ''}
        onChange={this.onChangeHandler.bind(this, name)}
        {...inputProps}
        theme={{}}
      >
        {this.props.children}
      </TextField>
    )
  }

}

NumberInput = connect((store) => {
  return {}
})(NumberInput)

export default withStyles(NumberInputCss)(NumberInput)
