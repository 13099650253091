import React, { Component } from "react";

import { Grid, MenuItem, Typography } from "@material-ui/core";
import { withStyles } from "@material-ui/core/styles";
import { Settings } from "@material-ui/icons";

import CollectionCrud from "../../common/Components/CollectionCrud";
import TextInput from "../../common/Components/TextInput";
import MegCss from "./css/MegCss";

class MegModelTva extends Component {
  getDeleteModalTitle() {
    return (
      <div>
        <Settings />
        <span>{"Suppression du model Tva"}</span>
      </div>
    );
  }

  getDeleteModalContent() {
    return (
      <Typography>
        {"Êtes-vous sûr de vouloir supprimer ce model Tva ?"}
      </Typography>
    );
  }

  getCreateUpdateModalTitle(meg_tva_model) {
    let title = !!meg_tva_model
      ? "Modification du model Tva : " + meg_tva_model.type_pays_libelle + ' - ' + meg_tva_model.article_type_libelle
      : "Ajout d'un nouveau model Tva";
    return (
      <div>
        <Settings />
        <span>{title}</span>
      </div>
    );
  }

  getCreateUpdateModalContent(
    meg_tva_model,
    meg_tva_modelsStore,
    onChangeHandler
  ) {
    let { type_pays, tva_article, model_document, type_article } = meg_tva_model;
    return (
      <Grid container>
        <Grid item xs={12}>
          <TextInput
            id="type_pays"
            label="Type pays"
            value={type_pays}
            margin="normal"
            collectionStore={meg_tva_modelsStore}
            name="type_pays"
            onChangeHandler={onChangeHandler}
            select
            fullWidth
          >
            {this.getTypePaysOptions()}
          </TextInput>
        </Grid>
        <Grid item xs={12}>
          <TextInput
            id="type_article"
            label="Type article"
            value={type_article}
            margin="normal"
            collectionStore={meg_tva_modelsStore}
            name="type_article"
            onChangeHandler={onChangeHandler}
            select
            fullWidth
          >
            {this.getTypeArticlesOptions()}
          </TextInput>
        </Grid>
        <Grid item xs={12}>
          <TextInput
            id="model_document"
            label="Code modèle document"
            value={model_document}
            required={true}
            margin="normal"
            collectionStore={meg_tva_modelsStore}
            name="model_document"
            onChangeHandler={onChangeHandler}
            fullWidth
          />
        </Grid>
        <Grid item xs={12}>
          <TextInput
            id="tva_article"
            label="Code TVA"
            value={tva_article}
            required={true}
            margin="normal"
            collectionStore={meg_tva_modelsStore}
            name="tva_article"
            onChangeHandler={onChangeHandler}
            fullWidth
          />
        </Grid>
      </Grid>
    );
  }

  getTypePaysOptions(){
    return [
      <MenuItem key="FR" value="FR">France</MenuItem>,
      <MenuItem key="UE" value="UE">Union Européenne</MenuItem>,
      <MenuItem key="HORS_UE" value="HORS_UE">Hors Union Européenne</MenuItem>,
    ]
  }

  getTypeArticlesOptions(){
    return [
      <MenuItem key="en_etat" value="en_etat">En l'état</MenuItem>,
      <MenuItem key="broye" value="broye">Broyé</MenuItem>,
      <MenuItem key="granule" value="granule">Granulé</MenuItem>,
      <MenuItem key="facture_annexe" value="facture_annexe">Fature Annexe</MenuItem>,
    ]
  }

  render() {
    const { classes } = this.props;
    return (
      <Grid container className={classes.container}>
        <CollectionCrud
          collectionName={"meg-model-tvas"}
          showBtnEdit={true}
          showBtnAdd={true}
          showBtnDelete={true}
          rights={{
            create: "admin-crud-meg",
            edit: "admin-crud-meg",
            delete: "admin-crud-meg",
          }}
          deleteModalTitle={this.getDeleteModalTitle}
          deleteModalContent={this.getDeleteModalContent}
          createUpdateModalTitle={this.getCreateUpdateModalTitle}
          createUpdateModalContent={this.getCreateUpdateModalContent.bind(this)}
          datatableConfig={{
            showPagination: true,
            showSearch: true,
            defaultSort: "type_pays_libelle",
            sortType: "desc",
            rowsPerPageOptions: [10, 25, 50],
          }}
          cellsConfig={[
            {
              datakey: "type_pays_libelle",
              title: "Type Pays",
              sortable: true,
              searchable: true,
            },
            {
              datakey: "article_type_libelle",
              title: "Type article",
              sortable: true,
              searchable: true,
            },
            {
              datakey: "tva_article",
              title: "TVA article",
              sortable: true,
              searchable: true,
            },
            {
              datakey: "model_document",
              title: "Modèle document",
              sortable: true,
              searchable: true,
            },
          ]}
        />
      </Grid>
    );
  }
}

MegModelTva = withStyles(MegCss)(MegModelTva);

export default MegModelTva;
