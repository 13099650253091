import React, {Component} from 'react';
import ErrorOutline from '@material-ui/icons/ErrorOutline';
import {withStyles} from "@material-ui/core/styles/index";
import ErrorsPagesCss from './css/ErrorsPagesCss';
import {Grid, Paper, Typography} from '@material-ui/core';

class Error404 extends Component {
  render() {
    const {classes} = this.props;

    return (
      <Grid
        container
        alignItems='center'
        justifyContent='center'
        className={classes.errorContainer}
      >
        <Paper elevation={20} className={classes.paper}>
          <Grid
            container
            alignItems='center'
          >
            <Grid item lg={4}>
              <ErrorOutline
                className={classes.errorIcon}
                color='primary'
              />
            </Grid>
            <Grid item lg={8}>
              <Typography
                variant='display1'
              >
                404 Error
              </Typography>
              <Typography
                color='primary'
                variant='headline'
              >
                Page non trouvée
              </Typography>
            </Grid>
          </Grid>
        </Paper>
      </Grid>
    );
  }
}

Error404 = withStyles(ErrorsPagesCss,)(Error404);

export default Error404
