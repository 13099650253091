import {
  Grid,
  MenuItem,
  Typography,
  Button,
  Divider,
  Select,
} from "@material-ui/core";
import { withStyles } from "@material-ui/core/styles";
import {
  CheckCircleOutline,
  SystemUpdateAlt,
  FlipToFront,
  ImportExport,
} from "@material-ui/icons";

import Rating from "@material-ui/lab/Rating";

import PropTypes from "prop-types";
import React, { Component } from "react";
import { connect } from "react-redux";
import { axiosClient } from "../../../axios";
import {
  collectionActions,
  loadCollectionAttribute,
} from "../../../reducers/collectionsReducer";
import ArticleSelect from "../../common/Components/ArticleSelect";
import CollectionCrud from "../../common/Components/CollectionCrud";
import NumberInput from "../../common/Components/NumberInput";
import TextInput from "../../common/Components/TextInput";
import StockCss from "./css/StockCss";

class MatierePremiere extends Component {
  static propTypes = {
    modal: PropTypes.object,
    extradatasForm: PropTypes.object,
    handleLotModalActions: PropTypes.func,
    customContext: PropTypes.object,
  };

  state = {
    currentHistoMatiere: null,
    filter_status: "disponible",
    filter_etablissement: 'all',
    filter_traitement: 'all',
    asyncFilters: {},
    currentStock: null,
    aviablesEtablissements: [],
  };

  componentDidMount() {
    loadCollectionAttribute(
      this.props.dispatch,
      "list",
      "traitements",
      this.props.traitementStore
    );

    axiosClient.get('stock/matieres_premieres/etablissements').then((response) => {
      this.setState({aviablesEtablissements: response.data})
    })
  }

  loadAsyncData(filters) {
    // eslint-disable-next-line
    if (filters !== undefined) this.state.asyncFilters = filters;

    return collectionActions(
      this.props.dispatch,
      "stock/matieres_premieres",
      "INDEX",
      {
        params: {
          filter_status: this.state.filter_status,
          filter_etablissement: this.state.filter_etablissement,
          filter_traitement: this.state.filter_traitement,
          ...this.state.asyncFilters,
        },
      },
      null,
      true
    );
  }

  getCreateUpdateStockModalTitle(stock) {
    let title = !!stock
      ? "Modification du stock : " + stock.code_lot
      : "Ajout au stock";
    return (
      <div>
        <SystemUpdateAlt />
        <span>{title}</span>
      </div>
    );
  }

  getTraitementOptions() {
    const { list } = this.props.traitementStore;

    if (!list) return [];

    return list.map((traitement) => {
      return (
        <MenuItem value={traitement.uuid} key={traitement.uuid}>
          {traitement.libelle}
        </MenuItem>
      );
    });
  }

  getEtablissementOptions() {

    return this.state.aviablesEtablissements.map((etablissement) => {
      return (
        <MenuItem value={etablissement.uuid} key={etablissement.uuid}>
          {etablissement.nom}
        </MenuItem>
      );
    });
  }

  onChangeHandlerNoteCompacte(onChangeHandler, name, e) {
    const { value } = e.target,
      digits = value.split("").map((v) => parseInt(v));

    /**
     * Ne posez pas de question...
     * Pour éviter d'écraser les anciennes valeurs du state on doit décaller les appels
     */
    onChangeHandler(name, { target: { value } }, () => {
      onChangeHandler(
        "chargement",
        { target: { value: digits[0] ? digits[0] : 0 } },
        () => {
          onChangeHandler(
            "qualite",
            { target: { value: digits[1] ? digits[1] : 0 } },
            () => {
              onChangeHandler("tri", {
                target: { value: digits[2] ? digits[2] : 0 },
              });
            }
          );
        }
      );
    });
  }

  onChangeHandlerLettre(onChangeHandler, name, e) {
    const { value } = e.target;

    onChangeHandler("lettre", { target: { value } }, () => {
      onChangeHandler("code_lot", { target: { value } });
    });
  }

  onChangeTable(name, stock, e) {
    const { value } = e.target;

    collectionActions(
      this.props.dispatch,
      "stock/matieres_premieres",
      "UPDATE",
      {
        ...stock,
        [name]: value,
      }
    );
  }

  getCreateUpdateStockModalContent(
    stock,
    stockStore,
    onChangeHandler,
    init,
    context
  ) {
    const {
        poids_vente,
        lettre,
        traitement_uuid,
        note_compacte,
        chargement,
        qualite,
        tri,
        commentaire,
        statut,
        campagne,
        localisation,
        etablissement_uuid,
      } = stock,
      { classes } = this.props;

    return (
      <Grid container>
        {context.source === "stock" && !stock.uuid ? (
          <>
            <ArticleSelect
              onChangeHandler={onChangeHandler.bind(this)}
              store={stockStore}
              gridFamilleXs={12}
            />
            <Divider
              style={{ width: "100%", marginTop: "1em", marginBottom: "1em" }}
            />
          </>
        ) : null}
        {context.source === "stock" ? (
          <>
            <Grid item xs={12}>
              <NumberInput
                id="poids_vente"
                label="Poids (t)"
                value={poids_vente}
                required={true}
                margin="normal"
                collectionStore={stockStore}
                name="poids_vente"
                onChangeHandler={onChangeHandler.bind(this)}
                style={{ width: "100%" }}
              />
            </Grid>
            <Grid item xs={12}>
              <TextInput
                id="etablissement_uuid"
                label="Lieu de stockage"
                className={classes.selectContainer}
                value={etablissement_uuid}
                margin="normal"
                collectionStore={stockStore}
                name="etablissement_uuid"
                onChangeHandler={onChangeHandler.bind(this)}
                type="autocomplete"
                autocompleteProps={{
                  collectionName: "etablissements",
                }}
                fullWidth
              />
            </Grid>
          </>
        ) : null}
        <Grid item xs={12}>
          <TextInput
            id="statut"
            label="Statut"
            value={statut}
            required={true}
            margin="normal"
            collectionStore={stockStore}
            name="statut"
            onChangeHandler={onChangeHandler}
            className={classes.selectContainer}
            select
            style={{ width: "calc(100% - 5px)" }}
          >
            <MenuItem value={"disponible"} key={"disponible"}>
              Disponible
            </MenuItem>
            <MenuItem value={"traite"} key={"traite"}>
              Traité
            </MenuItem>
          </TextInput>
        </Grid>
        <Divider
          style={{ width: "100%", marginTop: "1em", marginBottom: "1em" }}
        />
        <Grid item xs={6}>
          <TextInput
            id="code_lot"
            label="Lettre"
            value={lettre}
            required={true}
            margin="normal"
            collectionStore={stockStore}
            name="code_lot"
            onChangeHandler={this.onChangeHandlerLettre.bind(
              this,
              onChangeHandler
            )}
            style={{ margin: "0 5px 0 0", width: "calc(100% - 5px)" }}
          />
        </Grid>
        <Grid item xs={6}>
          <TextInput
            id="traitement_uuid"
            label="Traitement"
            value={traitement_uuid}
            required={true}
            margin="normal"
            collectionStore={stockStore}
            name="traitement_uuid"
            onChangeHandler={onChangeHandler}
            className={classes.selectContainer}
            select
            style={{ margin: "0 0 0 5px", width: "calc(100% - 5px)" }}
          >
            {this.getTraitementOptions()}
          </TextInput>
        </Grid>
        <Divider
          style={{ width: "100%", marginTop: "1em", marginBottom: "1em" }}
        />
        <Grid item xs={12}>
          <NumberInput
            id="note_compacte"
            label="Note : Chargement | Qualité | Tri"
            value={note_compacte}
            margin="normal"
            collectionStore={stockStore}
            name="note_compacte"
            onChangeHandler={this.onChangeHandlerNoteCompacte.bind(
              this,
              onChangeHandler
            )}
            type="number"
            required={true}
            fullWidth
            style={{ margin: "0 0 16px 0", width: "100%" }}
          />
        </Grid>
        <Grid item xs={4}>
          <Typography component="legend">Chargement</Typography>
          <Rating name="disabled" value={chargement} disabled />
        </Grid>
        <Grid item xs={4}>
          <Typography component="legend">Qualité</Typography>
          <Rating name="disabled" value={qualite} disabled />
        </Grid>
        <Grid item xs={4}>
          <Typography component="legend">Tri</Typography>
          <Rating name="disabled" value={tri} disabled />
        </Grid>
        <Grid item xs={12}>
          <TextInput
            id="campagne"
            label="Campagne"
            value={campagne}
            margin="normal"
            collectionStore={stockStore}
            name="campagne"
            onChangeHandler={onChangeHandler.bind(this)}
            style={{ width: "100%" }}
          />
        </Grid>
        <Grid item xs={12}>
          <TextInput
            id="localisation"
            label="Localisation"
            value={localisation}
            margin="normal"
            collectionStore={stockStore}
            name="localisation"
            onChangeHandler={onChangeHandler.bind(this)}
            style={{ width: "100%" }}
          />
        </Grid>
        <Grid item xs={12}>
          <TextInput
            id="commentaire"
            label="Commentaire"
            value={commentaire}
            margin="normal"
            collectionStore={stockStore}
            name="commentaire"
            onChangeHandler={onChangeHandler.bind(this)}
            style={{ width: "100%" }}
          />
        </Grid>
      </Grid>
    );
  }

  getFilters() {
    return (
      <>
        <Grid item xs={2}>
          <label htmlFor="statut">Statut</label>
          <Select
            id="statut"
            value={this.state.filter_status}
            onChange={this.onChangeFilters.bind(this, "filter_status")}
            fullWidth
          >
            <MenuItem value="all">Tous</MenuItem>
            <MenuItem value="traite">Traité</MenuItem>
            <MenuItem value="disponible">Disponible</MenuItem>
          </Select>
        </Grid>
        <Grid item xs={2}>
        <label htmlFor="filter_etablissement">Lieu Stockage</label>
          <Select
            id="filter_etablissement"
            value={this.state.filter_etablissement}
            onChange={this.onChangeFilters.bind(this, "filter_etablissement")}
            fullWidth
          >
            <MenuItem value="all">Tous</MenuItem>
            {this.getEtablissementOptions()}
          </Select>


        </Grid>
        <Grid item xs={2}>
          <label htmlFor="filter_traitement">Traitement</label>
          <Select
            id="filter_traitement"
            value={this.state.filter_traitement}
            onChange={this.onChangeFilters.bind(this, "filter_traitement")}
            fullWidth
          >
            <MenuItem value="all">Tous</MenuItem>
            {this.getTraitementOptions()}
          </Select>
        </Grid>
      </>
    );
  }

  onChangeFilters(name, e) {
    this.setState(
      {
        [name]: e.target.value,
        asyncFilters: {
          ...this.state.asyncFilters,
          page: 1,
        },
      },
      () => {
        this.loadAsyncData();
      }
    );
  }

  formatStatut(stock) {
    const { classes } = this.props;
    switch (stock.statut) {
      case "traite":
        return (
          <div
            className={classes.statutLabel}
            style={{ color: "#5bd790" }}
            onClick={() => {
              this.onChangeTable("statut", stock, {
                target: { value: "disponible" },
              });
            }}
          >
            <CheckCircleOutline style={{ marginRight: "5px" }} />
            <p>Traité</p>
          </div>
        );
      case "disponible":
        return (
          <div
            className={classes.statutLabel}
            style={{ color: "grey" }}
            onClick={() => {
              this.onChangeTable("statut", stock, {
                target: { value: "traite" },
              });
            }}
          >
            <FlipToFront style={{ marginRight: "5px" }} />
            <p>Disponible</p>
          </div>
        );
      default:
        break;
    }
  }

  displayInput(field, stock) {
    const { matieresStore } = this.props;
    return (
      <TextInput
        id={field}
        label={field}
        value={
          this.state.currentStock?.uuid === stock.uuid
            ? this.state.currentStock[field]
            : stock[field]
        }
        margin="normal"
        collectionStore={matieresStore}
        name={field}
        onChangeHandler={(name, e) => {
          this.setState({
            currentStock: {
              ...this.state.currentStock,
              [name]: e.target.value,
            },
          });
        }}
        onFocus={(e) => {
          this.setState({ currentStock: stock });
        }}
        onBlur={() => {
          this.onChangeTable(field, stock, {
            target: { value: this.state.currentStock[field] },
          });
          this.setState({ currentStock: null });
        }}
      />
    );
  }

  getDate(string) {
    if (!string) {
      return "";
    }
    let date = new Date(string);
    let options = {
      day: "numeric",
      month: "numeric",
      year: "numeric",
    };
    return date.toLocaleDateString("fr-FR", options);
  }

  getCellsConfig() {
    return [
      {
        datakey: "code_lot",
        title: "Code Lot",
        sortable: true,
        searchable: true,
      },
      {
        datakey: "fournisseur_nom",
        title: "Fournisseur",
        sortable: true,
        searchable: true,
      },
      {
        datakey: "lot_date_livraison",
        title: "Date Livraison",
        sortable: true,
        searchable: true,
        isDate: true,
        format: (obj, key) => {
          return this.getDate(obj[key]);
        },
      },
      {
        datakey: "lieu_stockage_nom",
        title: "Lieu de stockage",
        sortable: true,
        searchable: true,
      },
      {
        datakey: "traitement_libelle",
        title: "Traitement",
        sortable: true,
        searchable: true,
      },
      {
        datakey: "article_famille_nom",
        title: "Famille",
        sortable: true,
        searchable: true,
      },
      {
        datakey: "conditionnement_nom",
        title: "Conditionnement",
        sortable: true,
        searchable: true,
      },
      {
        datakey: "article_nom",
        title: "Article",
        sortable: true,
        searchable: true,
      },
      {
        datakey: "poids_vente",
        title: "Poids (t)",
        sortable: true,
        searchable: true,
      },
      {
        datakey: "campagne",
        title: "Campagne",
        sortable: true,
        searchable: true,
        format: (stock, key) => {
          return this.displayInput(key, stock);
        },
      },
      {
        datakey: "localisation",
        title: "Localisation",
        sortable: true,
        searchable: true,
        format: (stock, key) => {
          return this.displayInput(key, stock);
        },
      },
      {
        datakey: "commentaire",
        title: "Commentaire",
        sortable: true,
        searchable: true,
        format: (stock, key) => {
          return this.displayInput(key, stock);
        },
      },
      {
        datakey: "note_compacte",
        title: "Note",
        sortable: false,
        searchable: false,
      },
      {
        datakey: "statut",
        title: "Statut",
        sortable: true,
        searchable: true,
        format: (obj, key) => {
          return this.formatStatut(obj);
        },
      },
    ];
  }

  getExtraButtons() {
    return (
      <>
        <Button
          color="primary"
          startIcon={<ImportExport />}
          variant="contained"
          href={
            process.env.REACT_APP_API_URL + "/stock/matieres_premieres/export"
          }
        >
          Export stock
        </Button>
      </>
    );
  }

  render() {
    const {
      classes,
      modal,
      extradatasForm,
      handleLotModalActions,
      customContext,
    } = this.props;
    let context = customContext ? customContext : { source: "stock" };

    return (
      <Grid container className={classes.container}>
        {modal ? null : this.getFilters()}
        <CollectionCrud
          loadDatas={false}
          asynchrone={true}
          cancelUpdateCheck={true}
          asynchroneCallbackLoadData={this.loadAsyncData.bind(this)}
          collectionName={"stock/matieres_premieres"}
          showBtnEdit={true}
          showBtnAdd={true}
          datatableConfig={{
            showPagination: true,
            showSearch: true,
            defaultSort: "code_lot",
            sortType: "desc",
            nodatalabel: "Aucun Stock",
            rowsPerPageOptions: [25, 50],
          }}
          defaultValues={{
            statut: "disponible",
          }}
          persistDatatableOptions={{}}
          cellsConfig={this.getCellsConfig()}
          createUpdateModalTitle={this.getCreateUpdateStockModalTitle.bind(
            this
          )}
          createUpdateModalContent={this.getCreateUpdateStockModalContent.bind(
            this
          )}
          actionsCallback={handleLotModalActions}
          extradatasForm={extradatasForm}
          dataTableExtraNodes={[
            {
              element: this.getExtraButtons(),
              position: "top-left",
            },
          ]}
          modal={modal}
          customContext={context}
        />
      </Grid>
    );
  }
}

MatierePremiere = withStyles(StockCss)(MatierePremiere);

MatierePremiere = connect((store) => {
  return {
    traitementStore: store.collections.traitements,
    matieresStore: store.collections["stock/matieres_premieres"],
  };
})(MatierePremiere);

export default MatierePremiere;
