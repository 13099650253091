export default (theme) => ({
  affairesCaseStorage: {
    marginTop: "0.5rem",
    marginBottom: "0.5rem",
    paddingLeft: "0",
    display: "flex",
    justifyContent: "space-between",
    fontSize: "0.8rem",
    color: "grey",
  },
  cases_liste_lots: {
    margin: "0",
  },
  flux_case_fournisseur: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  affairesCaseStorageContent: {
    paddingLeft: "0.5rem",
  },
  container: {
    display: "flex",
    alignItems: "stretch",
    flexDirection: "row",
    flexWrap: "nowrap",
    width: "calc(100% + 24px)",
  },
  caseColumn: {
    width: "18%",
  },
  caseColumnRef: {
    width: "5%",
    textAlign: "center",
  },
  tableCase: {
    borderCollapse: "collapse",
    width: "100%",
  },
  td: {
    textAlign: "left",
  },
  tr: {
    height: "40px",
    marginTop: "2px",
  },
  containerTable: {
    "& tr:nth-child(even)": {
      backgroundColor: "#E5E5E5",
    },
  },
  columnTitle: {
    width: "5%",
    textAlign: "center",
    paddingTop: "0.5rem",
    backgroundColor: "#d4d4d4",
  },
  selectContainer: {
    paddingTop: "0",
    marginTop: "0",
  },
  title: {
    marginBottom: 0,
    padding: '.5em',
    color: theme.palette.primary.main,
    display: 'block',
  },
});
