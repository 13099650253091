import React, { Component } from "react";
import FacturationCss from "./css/FacturationCss";
import {
  Grid,
  MenuItem,
  TextField,
  Typography,
  withStyles,
} from "@material-ui/core";
import { connect } from "react-redux";
import CollectionCrud from "../../common/Components/CollectionCrud";
import { DateInput, TextInput } from "@oniti/oniti-inputs";
import { Check, Settings } from "@material-ui/icons";
import { getFromLocalStorage, storeToLocalStorage } from "../../tools/Tools";
import { collectionActions } from "../../../reducers/collectionsReducer";

const LOCAL_STORAGE_KEY = "PROFORMA";
class Proforma extends Component {
  state = {
    current_filter: null,
    status_filter: ["open"],
    type_filter: ["all"],
    local_storage_key: LOCAL_STORAGE_KEY,
  };

  componentDidMount() {
    const { local_storage_key } = this.props;
    if (local_storage_key) {
      this.setState({ local_storage_key: local_storage_key });
    }
    let filter_localStorage = getFromLocalStorage(
      local_storage_key ? local_storage_key : LOCAL_STORAGE_KEY
    );
    if (filter_localStorage) {
      this.setState(filter_localStorage, () => {
        this.loadAsyncData(this.state.current_filter, true);
      });
    }
  }

  getDeleteModalTitle() {
    return (
      <div>
        <Settings />
        <span>{"Suppression du proforma"}</span>
      </div>
    );
  }

  getDeleteModalContent() {
    return (
      <Typography>
        {"Êtes-vous sûr de vouloir supprimer ce proforma ?"}
      </Typography>
    );
  }

  getCreateUpdateModalTitle(proforma) {
    let title = !!proforma
      ? "Modification du proforma : " + proforma.numero
      : "Ajout d'un nouveau proforma";
    return (
      <div>
        <Settings />
        <span>{title}</span>
      </div>
    );
  }

  getCreateUpdateModalContent(proforma, proformasStore, onChangeHandler) {
    let {
      status,
      type,
      affaire_uuid,
      number,
      montant,
      date_proforma,
      date_creation,
      facture_reference,
      date_facture,
      commentaire,
    } = proforma;
    const { classes } = this.props;
    return (
      <Grid container>
        <Grid item xs={4}>
          <TextInput
            id="status"
            label="Statut"
            value={status}
            margin="normal"
            collectionStore={proformasStore}
            name="status"
            onChangeHandler={onChangeHandler}
            fullWidth
            select
          >
            <MenuItem value={"open"}>Ouvert</MenuItem>
            <MenuItem value={"closed"}>Fermé</MenuItem>
          </TextInput>
        </Grid>
        <Grid item xs={4}>
          <TextInput
            id="type"
            label="Type"
            value={type}
            margin="normal"
            collectionStore={proformasStore}
            name="type"
            onChangeHandler={onChangeHandler}
            fullWidth
            select
          >
            <MenuItem value={"emited"}>Émise</MenuItem>
            <MenuItem value={"received"}>Reçue</MenuItem>
          </TextInput>
        </Grid>
        <Grid item xs={4}>
          <TextInput
            id="affaire_uuid"
            label="Affaire"
            className={classes.selectContainer}
            value={affaire_uuid}
            margin="normal"
            collectionStore={proformasStore}
            name="affaire_uuid"
            onChangeHandler={onChangeHandler.bind(this)}
            type="autocomplete"
            autocompleteProps={{
              collectionName: "affaires",
            }}
            fullWidth
          />
        </Grid>
        <Grid item xs={4}>
          <TextInput
            id="number"
            label="Ref PF"
            value={number}
            margin="normal"
            collectionStore={proformasStore}
            name="number"
            onChangeHandler={onChangeHandler}
            fullWidth
          />
        </Grid>
        <Grid item xs={4}>
        <DateInput
          id="date_creation"
          label="Crée le"
          value={date_creation}
          format="d MMMM yyyy"
          margin="normal"
          collectionStore={proformasStore}
          name="date_creation"
          onChangeHandler={onChangeHandler}
          fullWidth
          pickertype="date"
        />
        </Grid>
        <Grid item xs={4}>
          <TextInput
            id="montant"
            label="Montant"
            value={montant}
            margin="normal"
            collectionStore={proformasStore}
            name="montant"
            onChangeHandler={onChangeHandler}
            fullWidth
            type="number"
            inputProps={{
              step: "0.01",
              min: "0",
            }}
          />
        </Grid>
        <Grid item xs={4}>
          <DateInput
            id="date_proforma"
            label="PF date pmt"
            value={date_proforma}
            format="d MMMM yyyy"
            margin="normal"
            collectionStore={proformasStore}
            name="date_proforma"
            onChangeHandler={onChangeHandler}
            fullWidth
            pickertype="date"
          />
        </Grid>
        <Grid item xs={4}>
          <TextInput
            id="facture_reference"
            label="Référence facture"
            value={facture_reference}
            margin="normal"
            collectionStore={proformasStore}
            name="facture_reference"
            onChangeHandler={onChangeHandler}
            fullWidth
          />
        </Grid>
        <Grid item xs={4}>
          <DateInput
            id="date_facture"
            label="Date Facture"
            value={date_facture}
            format="d MMMM yyyy"
            margin="normal"
            collectionStore={proformasStore}
            name="date_facture"
            onChangeHandler={onChangeHandler}
            fullWidth
            pickertype="date"
          />
        </Grid>
        <Grid item xs={12}>
          <TextInput
            id="commentaire"
            label="Commentaire"
            value={commentaire}
            margin="normal"
            collectionStore={proformasStore}
            name="commentaire"
            onChangeHandler={onChangeHandler}
            fullWidth
          />
        </Grid>
      </Grid>
    );
  }

  getCellsConfig() {
    return [
      {
        datakey: "status",
        title: "Statut",
        sortable: true,
        searchable: true,
        format: (obj) => {
          return obj.status === "open" ? "Ouvert" : "Fermé";
        },
      },
      {
        datakey: "type",
        title: "Type",
        sortable: true,
        searchable: true,
        format: (obj) => {
          return obj.type === "emited" ? "Émise" : "Reçue";
        },
      },
      {
        datakey: "affaire_uuid",
        title: "Affaire",
        sortable: true,
        searchable: true,
        format: (obj) => {
          return (
            <a
              href={"/affaires/" + obj.affaire_uuid}
              target="_blank"
              rel="noopener noreferrer"
            >
              {obj.affaire_reference_interne}
            </a>
          );
        },
      },
      {
        datakey: "affaire_facture_fournisseur",
        title: "Fac. fournisseur",
        sortable: false,
        searchable: false,
        format: (obj) => (obj.affaire_facture_fournisseur ? <Check /> : null),
      },
      {
        datakey: "affaire_facture_client",
        title: "Fac client",
        sortable: false,
        searchable: false,
        format: (obj) => (obj.affaire_facture_client ? <Check /> : null),
      },
      {
        datakey: "destinataire",
        title: "Destinataire",
        sortable: false,
        searchable: false,
      },
      {
        datakey: "number",
        title: "ref PF",
        sortable: true,
        searchable: true,
      },
      {
        datakey: "date_creation",
        title: "PF créee le",
        sortable: true,
        searchable: true,
        format: (obj) => {
          return obj.date_creation_formated;
        },
      },
      {
        datakey: "montant",
        title: "Montant",
        sortable: true,
        searchable: true,
        format: (obj) => {
          return obj.montant_formated;
        },
      },
      {
        datakey: "date_proforma",
        title: "PF Date Pmt",
        sortable: true,
        searchable: true,
        format: (obj) => {
          return obj.date_proforma_formated;
        },
      },
      {
        datakey: "facture_reference",
        title: "Facture Réf",
        sortable: true,
        searchable: true,
      },
      {
        datakey: "date_facture",
        title: "Facture Date",
        sortable: true,
        searchable: true,
        format: (obj) => {
          return obj.date_facture_formated;
        },
      },
      {
        datakey: "commentaire",
        title: "Commentaire",
        sortable: true,
        searchable: true,
        format: (obj) => {
          return (
            obj.commentaire?.substring(0, 300) +
            (obj.commentaire?.length > 300 ? "..." : "")
          );
        },
      },
    ];
  }

  loadAsyncData(filters) {
    // eslint-disable-next-line
    if (filters !== undefined) this.state.current_filter = filters;
    return collectionActions(
      this.props.dispatch,
      "proformas",
      "INDEX",
      {
        params: {
          ...this.state.current_filter,
          status: this.state.status_filter,
          type: this.state.type_filter,
          entreprise_uuid: this.props.entreprise_uuid,
        },
      },
      null,
      true
    );
  }

  onChangeHandlerFilters(name, e) {
    let values = e.target.value;
    /**
     * Gestion de la value all
     * si on clique dessus on vide la liste et ne garde que all
     * si on clique sur un autre element on enleve all
     * si on vide la liste on rajoute all
     */
    if (values.length > 0) {
      if (this.state[name].indexOf("all") !== -1) {
        values = values.filter((value) => value !== "all");
      } else if (values.indexOf("all") !== -1) {
        values = ["all"];
      }
    } else {
      values = ["all"];
    }
    this.setState(
      {
        [name]: values,
      },
      () => {
        storeToLocalStorage(
          this.state.local_storage_key,
          {
            status_filter: this.state.status_filter,
            type_filter: this.state.type_filter,
          },
          60 * 12
        );
        // eslint-disable-next-line
        this.state.current_filter.page = 1; // On force la page 1
        this.loadAsyncData(this.state.current_filter, true);
      }
    );
  }

  getTypeAffaireFilter() {
    const { classes } = this.props;
    const { type_filter } = this.state;

    const typesAffaires = [
      {
        slug: "all",
        libelle: "Tous",
      },
      {
        slug: "emited",
        libelle: "Emise",
      },
      {
        slug: "received",
        libelle: "Reçu",
      },
    ];

    const options = typesAffaires.map((typeAffaire) => {
      return (
        <MenuItem value={typeAffaire.slug} key={typeAffaire.slug}>
          {typeAffaire.libelle}
        </MenuItem>
      );
    });

    return (
      <TextField
        id="type_affaire_filter"
        label="Type"
        margin="normal"
        onChange={this.onChangeHandlerFilters.bind(this, "type_filter")}
        className={classes.selectContainer}
        SelectProps={{
          multiple: true,
          value: type_filter,
        }}
        select
      >
        {options}
      </TextField>
    );
  }

  getStatusAffaireFilter() {
    const { classes } = this.props;
    const { status_filter } = this.state;

    const statusAffaires = [
      {
        slug: "all",
        libelle: "Tous",
      },
      {
        slug: "open",
        libelle: "Ouvert",
      },
      {
        slug: "closed",
        libelle: "Fermé",
      },
    ];

    const options = statusAffaires.map((statusAffaire) => {
      return (
        <MenuItem value={statusAffaire.slug} key={statusAffaire.slug}>
          {statusAffaire.libelle}
        </MenuItem>
      );
    });

    return (
      <TextField
        id="status_affaire_filter"
        label="Statut"
        margin="normal"
        onChange={this.onChangeHandlerFilters.bind(this, "status_filter")}
        className={classes.selectContainer}
        SelectProps={{
          multiple: true,
          value: status_filter,
        }}
        select
      >
        {options}
      </TextField>
    );
  }

  getFilters() {
    const { classes } = this.props;
    const typeAffaireFilter = this.getTypeAffaireFilter();
    const statusAffaireFilter = this.getStatusAffaireFilter();

    if (
      !typeAffaireFilter &&
      !statusAffaireFilter
    ) {
      return null;
    }

    return (
      <Grid container>
        <Grid item xs={12}>
          <Typography
            key="typo"
            variant="button"
            gutterBottom
            className={classes.crudTitle}
          >
            Filtres
          </Typography>
        </Grid>
        <Grid item xs={2}>
          {statusAffaireFilter}
        </Grid>
        <Grid item xs={2}>
          {typeAffaireFilter}
        </Grid>
      </Grid>
    );
  }

  render() {
    const { classes, entreprise_uuid } = this.props;
    return (
      <>
        {this.getFilters()}
        <Grid container className={classes.container}>
          <CollectionCrud
            collectionName={"proformas"}
            showBtnEdit={true}
            showBtnAdd={true}
            showBtnDelete={true}
            asynchrone={true}
            asynchroneCallbackLoadData={this.loadAsyncData.bind(this)}
            deleteModalTitle={this.getDeleteModalTitle}
            deleteModalContent={this.getDeleteModalContent}
            createUpdateModalTitle={this.getCreateUpdateModalTitle}
            createUpdateModalContent={this.getCreateUpdateModalContent.bind(
              this
            )}
            getAsyncFilter={(filter) =>
              // eslint-disable-next-line
              (this.state.current_filter = filter)
            }
            datatableConfig={{
              showPagination: true,
              showSearch: true,
              defaultSort: "status",
              sortType: "desc",
              rowsPerPageOptions: [10, 25, 50],
            }}
            cellsConfig={this.getCellsConfig()}
            defaultValues={{
              status: "open",
              type: "emited",
            }}
            persistDatatableOptions={{
              id: "proformas"+(entreprise_uuid ? '_entreprises' : ""),
              exipiration_minutes: 60 * 12, // 12 Heures
            }}

          />
        </Grid>
      </>
    );
  }
}

Proforma = withStyles(FacturationCss)(Proforma);

Proforma = connect((store) => {
  return {
    location: store.router.location,
    user: store.auth.user,
  };
})(Proforma);

export default Proforma;
