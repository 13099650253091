/**
 * Construit une route avec ses paramètres
 * @param  {[type]} path        [description]
 * @param  {[type]} parameters [description]
 * @return {[type]}             [description]
 */
export function buildRoute(path, parameters) {
  let parametersUsed = [];
  if (parameters && typeof parameters === 'object') {
    Object.keys(parameters).forEach((key) => {
      let newpath = path.replace(':' + key, parameters[key]);
      if (path !== newpath) parametersUsed.push(key);
      path = newpath
    });
  }
  parametersUsed.map(k => delete parameters[k]);
  let queryParams = encodeQueryData(parameters);
  return path.replace(/\(:[a-zA-Z]+\)/, '').replace(/\(\/:[a-zA-Z]+\)/, '').replace(/\(/, '').replace(/\)/, '') + (queryParams ? '?' + queryParams : '');
}

function encodeQueryData(data) {
  let ret = [];
  for (let d in data)
    ret.push(encodeURIComponent(d) + '=' + encodeURIComponent(data[d]));
  return ret.join('&');
}

/**
 * Regarde si deux routes correspondent entre elles
 * @param  {[type]}  routePathConfig [description]
 * @param  {[type]}  routePath       [description]
 * @return {Boolean}                 [description]
 */
export function isMatchingRoute(routePathConfig, routePath) {
  routePath = routePath.replace(/#/g, "");

  routePathConfig = routePathToArray(routePathConfig);
  routePath = routePathToArray(routePath);
  let matching = routePathConfig.length === routePath.length;
  routePathConfig.forEach((routePart, index) => {
    if (matching) {
      if (routePart.startsWith(':') && routePath[index]) matching = true;
      else if (routePath[index] === routePart) matching = true;
      else matching = false;
    }
  });
  return matching;
}

/**
 * Split un path sur les / pour travailler plus simplement avec
 * @param  {[type]} routePath [description]
 * @return {[type]}           [description]
 */
function routePathToArray(routePath) {
  return routePath.split('/').filter(routePart => routePart !== '' && !routePart.startsWith('?'));
}
