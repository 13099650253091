import React, { Component } from "react";
import {
  withStyles,
  Table,
  TableContainer,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  Grid,
  IconButton,
  MenuItem,
  DialogTitle,
  DialogContent,
} from "@material-ui/core";

import Modal from "../../common/Components/Modal";
import { connect } from "react-redux";
import Paper from "@material-ui/core/Paper";
import {
  collectionActions,
  loadCollectionAttribute,
} from "../../../reducers/collectionsReducer";
import PropTypes from "prop-types";
import { buildRoute } from "../../../router/Tools";
import PlanningCaseStorageCss from "./css/PlanningCaseStorageCss";
import { hasRights } from "../../common/Tools/Tools";
import ListItem from "@material-ui/core/ListItem";
import ListItemText from "@material-ui/core/ListItemText";
import Typography from "@material-ui/core/Typography";
import List from "@material-ui/core/List";
import LocalShipping from "@material-ui/icons/LocalShipping";
import { Eject, Store } from "@material-ui/icons";
import moment from "moment";
import TextInput from "../../common/Components/TextInput";
import DateInput from "../../common/Components/DateInput";

class PlanningCaseStorage extends Component {
  state = {
    openModalRelease: false,
  };

  componentDidMount() {
    const { site } = this.props;

    if (!this.props.flux) {
      this.loadPlanning();
    }
    if (hasRights("read-case-stockage", this.props.user)) {
      this.loadPlanningStorage();
      loadCollectionAttribute(
        this.props.dispatch,
        "list",
        "caseStockage",
        this.props.caseStockageStore,
        {
          params: {
            site_uuid: site,
          },
        }
      );
    }
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    const { site } = this.props;
    if (
      prevProps.site !== this.props.site ||
      prevProps.selectedDate !== this.props.selectedDate
    ) {
      if (hasRights("read-case-stockage", this.props.user)) {
        this.loadPlanningStorage();
        loadCollectionAttribute(
          this.props.dispatch,
          "list",
          "caseStockage",
          this.props.caseStockageStore,
          {
            params: {
              site_uuid: site,
            },
          },
          true
        );
      }
      if (!this.props.flux) {
        this.loadPlanning();
      }
    }
  }

  loadPlanning() {
    const { selectedDate, site } = this.props;
    const date =
      selectedDate.getFullYear() +
      "-" +
      (selectedDate.getMonth() + 1) +
      "-" +
      selectedDate.getDate() +
      " " +
      selectedDate.getHours() +
      ":" +
      selectedDate.getMinutes() +
      ":" +
      selectedDate.getSeconds();

    collectionActions(this.props.dispatch, "flux", "INDEX", {
      params: { date, site },
    });
  }

  getDaysContent(flux) {
    const { classes } = this.props;
    let content = [];
    if (flux.is_type_transport_case) {
      content.push(
        <>
          <span className={classes.reference} key={flux.reference_interne}>
            Réf :{" "}
            <a
              href={buildRoute("/affaires/:uuid", {
                uuid: flux.uuid,
              })}
            >
              {flux.reference_interne}
            </a>
          </span>
        </>
      );

      content.push(
        <ul className={classes.liste} key={"ul-" + flux.reference_interne}>
          {Object.keys(flux.lots).map((index, key) => {
            let lot = flux.lots[key];

            return (
              <li key={lot.uuid}>
                {lot.conditionnement_nom} / {lot.article_famille_nom} /{" "}
                {lot.article_nom}
              </li>
            );
          })}
        </ul>
      );

      content.push(
        <>
          <span
            key={new Date().getTime()}
            className={classes.transportNom}
            style={{ backgroundColor: flux.transport_planning_color }}
          >
            <LocalShipping
              className={classes.transportIcon}
              fontSize={"small"}
            />
            {flux.transporteur_nom} ({flux.transport_nom}){" "}
            {flux.stock_in_case_references}
          </span>
          {this.getCaseStockageInput(flux)}
        </>
      );
    }
    return (
      <>
        <span>{content}</span>
      </>
    );
  }

  getDays() {
    const { classes } = this.props;
    let days = [],
      nbrResultats = Object.keys(this.props.fluxStore.list).length,
      columnWidth = nbrResultats > 5 ? 100 / nbrResultats + "%" : "20%";

    days.push(
      Object.keys(this.props.fluxStore.list).map((key, index) => {
        let jour = this.props.fluxStore.list[key],
          flux = [];

        // Flux livraison/chargement
        flux.push(
          Object.keys(jour.flux)
            .filter((key) => {
              return jour.flux[key].is_type_transport_case;
            })
            .map((key, index) => {
              let date = new Date(jour.flux[key].date_flux),
                heure =
                  (date.getHours() < 10
                    ? "0" + date.getHours()
                    : date.getHours()) +
                  ":" +
                  (date.getMinutes() < 10
                    ? "0" + date.getMinutes()
                    : date.getMinutes()),
                result = null;

              result = (
                <div key={key}>
                  <ListItem
                    dense
                    disableGutters
                    divider
                    alignItems={"flex-start"}
                    className={classes.listItem}
                  >
                    <ListItemText
                      className={classes.listItemText}
                      primaryTypographyProps={{
                        className: classes.primaryTypography,
                      }}
                      secondaryTypographyProps={{
                        className: classes.secondaryTypography,
                      }}
                      primary={
                        <>
                          <span className={classes.heures}>{heure}</span>
                          <span className={classes.entreprise}>
                            {jour.flux[key].entreprise_flux}
                          </span>
                        </>
                      }
                      secondary={this.getDaysContent(jour.flux[key])}
                    />
                  </ListItem>
                </div>
              );

              return result;
            })
        );

        let commentVisible = false;
        let selectedCommentaires = [];

        // Colonne
        return (
          <Paper
            key={jour.label}
            className={classes.column}
            elevation={1}
            style={{ width: columnWidth, margin: "0.5rem" }}
          >
            <Typography
              variant="h5"
              component="h3"
              color="primary"
              className={classes.date}
            >
              {key}
            </Typography>
            <Typography
              variant="h5"
              component="h3"
              color="primary"
              className={classes.jour}
            >
              <div className={classes.libelleDay}>{jour.label}</div>
            </Typography>
            <div className={classes.mainContainer}>
              {this.props.admin ? (
                <div style={{ textAlign: "center" }}>
                  {commentVisible ? this.getComments(selectedCommentaires) : ""}
                </div>
              ) : (
                ""
              )}
              <List className={classes.root}>{flux}</List>
            </div>
          </Paper>
        );
      })
    );

    // Conteneur
    return (
      <div className={classes.container}>
        <div className={classes.columnTitle}>A stocker</div>
        {days}
      </div>
    );
  }

  loadPlanningStorage() {
    const { selectedDate, site, planningStockageStore, dispatch } = this.props;

    const date =
      selectedDate.getFullYear() +
      "-" +
      (selectedDate.getMonth() + 1) +
      "-" +
      selectedDate.getDate() +
      " " +
      selectedDate.getHours() +
      ":" +
      selectedDate.getMinutes() +
      ":" +
      selectedDate.getSeconds();
    loadCollectionAttribute(
      dispatch,
      "list",
      "planningStockage",
      planningStockageStore,
      {
        params: {
          site_uuid: site,
          date: date,
        },
      },
      true
    );
  }

  getTitle(affaire) {
    return (
      affaire.liste_lots.split(",").map((lot) => {
        return  `${lot}\n`
      })
    )
  }

  displayCaseAffaires(affaires) {
    const { classes } = this.props;
    return affaires.map((affaire) => {
      return (
        <>
          <Paper className={classes.affairesCaseStorage}>
            <div
              className={classes.affairesCaseStorageContent}
              title={this.getTitle(affaire)}
            >
              <span
                className={classes.reference}
                key={affaire.reference_interne}
              >
                Réf :{" "}
                <a
                  key={affaire.uuid}
                  href={buildRoute("affaires/:uuid", { uuid: affaire.uuid })}
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  {affaire.reference_interne}
                </a>
              </span>
              <div className={classes.flux_cases_fournisseur}>
                <Store className={classes.transportIcon} />{" "}
                {affaire.fournisseur}
              </div>
              <div>
                jusqu'au{" "}
                {affaire.date_sortie
                  ? moment(affaire.date_sortie).format("DD/MM/YYYY")
                  : "N/C"}
              </div>
            </div>
            <IconButton
              onClick={() =>
                this.setState({ openModalRelease: true, localAffaire: affaire })
              }
              color={"primary"}
              title="Occupation jusqu'à"
            >
              <Eject />
            </IconButton>
          </Paper>
        </>
      );
    });
  }

  displayCaseStockageRow(data, caseRef) {
    const { classes } = this.props;

    return (
      <TableRow
        key={caseRef}
        sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
        className={classes.caseRow}
      >
        <TableCell component="th" scope="row" className={classes.caseColumnRef}>
          {caseRef}
        </TableCell>
        <TableCell align="center" className={classes.caseColumn}>
          {this.displayCaseAffaires(data.lundi)}
        </TableCell>
        <TableCell align="center" className={classes.caseColumn}>
          {this.displayCaseAffaires(data.mardi)}
        </TableCell>
        <TableCell align="center" className={classes.caseColumn}>
          {this.displayCaseAffaires(data.mercredi)}
        </TableCell>
        <TableCell align="center" className={classes.caseColumn}>
          {this.displayCaseAffaires(data.jeudi)}
        </TableCell>
        <TableCell align="center" className={classes.caseColumn}>
          {this.displayCaseAffaires(data.vendredi)}
        </TableCell>
      </TableRow>
    );
  }

  displayCaseStockage(event, caseStockage) {
    const { classes } = this.props;

    if (!this.props.planningStockageStore.list) {
      return [];
    }
    let JourSemaine = [];
    let rows = [];
    Object.keys(this.props.planningStockageStore.list).map((key, index) => {
      const data = this.props.planningStockageStore.list[key];
      switch (key) {
        case "jours":
          JourSemaine = data;
          break;
        case "result":
          Object.keys(data).map((caseRef) => {
            rows.push(this.displayCaseStockageRow(data[caseRef], caseRef));
            return null;
          });
          break;

        default:
          throw new Error("Unexpected value in PlanningCaseStorage.js");
      }
      return null;
    });

    let jours = JourSemaine.map((jour) => {
      return (
        <TableCell>
          <Typography
            variant="h5"
            component="h3"
            color="primary"
            className={classes.jour}
          >
            <div className={classes.libelleDay}> {jour}</div>
          </Typography>
        </TableCell>
      );
    });

    return (
      <TableContainer component={Paper} className={classes.containerTable}>
        <Table
          sx={{ minWidth: 650 }}
          className={classes.tableCase}
          aria-label="simple table"
        >
          <TableHead>
            <TableRow>
              <TableCell>CASES</TableCell>
              {jours}
            </TableRow>
          </TableHead>
          <TableBody>{rows}</TableBody>
        </Table>
      </TableContainer>
    );
  }

  onChangeAffaireHandler(affaire, name, event) {
    const { dispatch } = this.props;
    const { value } = event.target;
    collectionActions(dispatch, "affaires", "UPDATE", {
      ...affaire,
      [name]: value,
    }).then(() => {
      this.loadPlanningStorage();
      this.loadPlanning();
    });
  }

  getCaseStockageInput(flux) {
    const { affairesStore, classes, user } = this.props;
    if (!hasRights("read-case-stockage", user)) return null;

    const hasEditRight =
      hasRights("admin-cud-affaires", user) &&
      hasRights("admin-cud-case-stockage", user);

    return (
      <TextInput
        label="Case Stockage"
        className={classes.selectContainer}
        disabled={!hasEditRight}
        margin="normal"
        collectionStore={affairesStore}
        name="stock_in_cases"
        onChangeHandler={this.onChangeAffaireHandler.bind(this, flux)}
        fullWidth
        SelectProps={{
          multiple: true,
          value: flux.stock_in_cases,
        }}
        select
      >
        {this.getCaseStockages()}
      </TextInput>
    );
  }

  /**
   * Retourne la liste des cases.
   */
  getCaseStockages() {
    if (!this.props.caseStockageStore.list) {
      return [];
    }
    return this.props.caseStockageStore.list.map((caseItem) => {
      return (
        <MenuItem value={caseItem.uuid} key={caseItem.uuid}>
          {caseItem.reference}
        </MenuItem>
      );
    });
  }

  /**
   * OnChange Handler
   */
  onChangeHandler(name, event) {
    const { value } = event.target;
    this.setState({
      localAffaire: { ...this.state.localAffaire, [name]: value },
    });
  }

  getModalContent() {
    const { affairesStore } = this.props;
    const { localAffaire } = this.state;
    return (
      <>
        <DialogTitle key="title" id="alert-dialog-slide-title">
          Libérer la case après cette date
        </DialogTitle>
        <DialogContent key="content-restore">
          <Typography>
            Choisir la date de sortie après laquelle la case est dispo
          </Typography>
          <DateInput
            id="date_sortie"
            label="Date sortie"
            format={"d MMMM yyyy"}
            value={localAffaire.date_sortie}
            margin="normal"
            collectionStore={affairesStore}
            name="date_sortie"
            onChangeHandler={this.onChangeHandler.bind(this)}
            fullWidth
          />
        </DialogContent>
      </>
    );
  }

  onCloseHandler(name) {
    this.setState({
      [name]: false,
    });
  }

  onSubmitHandler() {
    const { dispatch } = this.props;
    const { localAffaire } = this.state;
    collectionActions(
      dispatch,
      "affaires",
      "UPDATE",
      {
        ...localAffaire,
        free_case_stockage: {
          case_stockage_uuid: localAffaire.case_uuid,
          date_sortie: localAffaire.date_sortie,
        },
      },
      this.loadPlanningStorage.bind(this)
    );
    this.setState({
      openModalRelease: false,
    });
  }

  generateModal() {
    const { openModalRelease } = this.state;
    if (!openModalRelease) return null;
    return (
      <Modal
        openModal={openModalRelease}
        onCloseHandler={this.onCloseHandler.bind(this, "openModalRelease")}
        onSubmitHandler={this.onSubmitHandler.bind(this)}
        fullWidth={true}
        maxWidth={"sm"}
        disabledEnter={false}
      >
        {this.getModalContent()}
      </Modal>
    );
  }

  render() {
    const { classes } = this.props;

    if (
      this.props.fluxStore.fetching ||
      this.props.planningStockageStore.fetching
    )
      return null;

    return (
      <>
        <Grid container item className={classes.container}>
          {this.displayCaseStockage()}
        </Grid>
        <Grid container item className={classes.container}>
          {this.props.fluxStore.list ? this.getDays() : null}
        </Grid>
        {this.generateModal()}
      </>
    );
  }
}

PlanningCaseStorage = connect((store) => {
  return {
    fluxStore: store.collections.flux,
    user: store.auth.user,
    affairesStore: store.collections.affaires,
    planningStockageStore: store.collections.planningStockage,
    caseStockageStore: store.collections.caseStockage,
  };
})(PlanningCaseStorage);

PlanningCaseStorage.propTypes = {
  exterior: PropTypes.bool, // Détermine si on doit demander les flux extérieurs a CapEco auprès de l'API
  admin: PropTypes.bool, // Détermine si on doit afficher les commentaires
  selectedDate: PropTypes.instanceOf(Date).isRequired,
};

PlanningCaseStorage = withStyles(PlanningCaseStorageCss)(PlanningCaseStorage);

export default PlanningCaseStorage;
