import React, {Component} from 'react';
import {connect} from 'react-redux';
import {withRouter} from 'react-router-dom';
import PropTypes from 'prop-types';

import Modal from '../../common/Components/Modal';
import {DialogContent, DialogTitle, Grid, Link, MenuItem, Typography} from '@material-ui/core';
import {TrendingUp} from '@material-ui/icons';
import {withStyles} from '@material-ui/core/styles';

import {buildRoute} from '../../../router/Tools';

import {collectionActions} from '../../../reducers/collectionsReducer';
import CollectionCrud from '../../common/Components/CollectionCrud';

import PotentielCss from './css/PotentielCss.js';
import TextInput from "../../common/Components/TextInput";
import ArticleSelect from '../../common/Components/ArticleSelect';


class Potentiel extends Component {

  static defaultProps = {
    article: null,
    entreprise: null,
    open: false
  };

  static propTypes = {
    article: PropTypes.object,
    open: PropTypes.bool,
    onCloseHandler: PropTypes.func
  };

  state = {
    article_uuid: null,
    entreprise_uuid: null
  };

  static getDerivedStateFromProps(nextProps, prevState) {
    const {article, potentielStore, entreprise} = nextProps;
    let props_article_uuid = article ? article.uuid : null,
      props_entreprise_uuid = entreprise ? entreprise.uuid : null;

    if ((prevState.article_uuid !== props_article_uuid || prevState.entreprise_uuid !== props_entreprise_uuid) && !potentielStore.fetching) {
      let data = null;
      if (props_article_uuid) {
        data = {params: {article_uuid: props_article_uuid}}
      } else if (props_entreprise_uuid) {
        data = {params: {entreprise_uuid: props_entreprise_uuid}}
      }
      if (data) {
        collectionActions(
          nextProps.dispatch,
          'potentiels',
          'INDEX',
          data
        )
      }

      return {
        article_uuid: props_article_uuid,
        entreprise_uuid: props_entreprise_uuid
      }
    }
    return null
  }

  onSubmitHandler() {
    this.props.onCloseHandler()
  }

  onClickHandler(object, e) {
    e.preventDefault();
    if (this.props.onCloseHandler) this.props.onCloseHandler();

    this.props.history.push(buildRoute(
      '/entreprises/:uuid', {uuid: object.uuid}
    ))
  }

  getCellsConfig() {
    const {mode} = this.props
    let config = [
      {
        datakey: "type_entreprise",
        title: "Type",
        sortable: true,
        searchable: true,
        format: potentiel => potentiel.type_entreprise.charAt(0).toUpperCase() + potentiel.type_entreprise.slice(1)
      }
    ]
    if (mode === 'article') {
      config.push({
        datakey: "entreprise",
        title: "Entreprise",
        sortable: true,
        searchable: true,
        format: (potentiel) => <Link href='#' onClick={this.onClickHandler.bind(this, potentiel.entreprise)}>
          {potentiel.entreprise ? potentiel.entreprise.nom : null}
        </Link>
      })
    }
    if (mode === 'entreprise') {
      config.push({
        datakey: "article.famille_nom",
        title: "Famille",
        sortable: true,
        searchable: true
      })
      config.push({
        datakey: "article.nom",
        title: "Article",
        sortable: true,
        searchable: true
      })
    }

    config.push({
      datakey: "commentaires",
      title: "Commentaires",
      sortable: true,
      searchable: true
    })

    return config
  }

  getDeleteModalTitle() {
    return (
      <div>
        <TrendingUp/>
        <span>{"Suppression du potentiel"}</span>
      </div>
    )
  }

  getDeleteModalContent() {
    return (
      <Typography>
        {'Êtes-vous sûr de vouloir supprimer ce potentiel ?'}
      </Typography>
    )
  }

  getCreateUpdateModalTitle(potentiel) {
    let title = !!potentiel
      ? "Modification du potentiel : " + potentiel.commentaires
      : "Ajout d'un nouveau potentiel";
    return (
      <div>
        <TrendingUp/>
        <span>{title}</span>
      </div>
    )
  }

  getCreateUpdateModalContent(potentiel, potentielsStore, onChangeHandler) {
    const {mode, classes} = this.props;
    let {
      article,
      article_uuid,
      type_entreprise,
      entreprise_uuid,
      commentaires
    } = potentiel;

    if (this.state.article_uuid && mode === 'article') article_uuid = this.state.article_uuid;
    if (this.state.entreprise_uuid && mode === 'entreprise') entreprise_uuid = this.state.entreprise_uuid;

    return (
      <Grid container>
        <Grid item xs={2}>
          <TextInput
            id="type_entreprise"
            label="Type *"
            value={type_entreprise}
            margin="normal"
            collectionStore={potentielsStore}
            name='type_entreprise'
            onChangeHandler={onChangeHandler}
            className={classes.selectContainer}
            select
            fullWidth
            style={{marginLeft:0}}
          >
            <MenuItem value={'client'}>Client</MenuItem>
            <MenuItem value={'fournisseur'}>Fournisseur</MenuItem>
          </TextInput>
        </Grid>
        <Grid item xs={5}>
          <TextInput
            required
            disabled={mode === 'entreprise'}
            id="entreprise_uuid"
            label="Entreprise"
            className={classes.selectContainer}
            collectionStore={potentielsStore}
            value={entreprise_uuid}
            margin="normal"
            name='entreprise_uuid'
            onChangeHandler={onChangeHandler}
            type="autocomplete"
            autocompleteProps={{
              collectionName: "entreprises",
            }}
            fullWidth
          />
        </Grid>
        <ArticleSelect
          onChangeHandler={onChangeHandler.bind(this)}
          store={potentielsStore}
          famille_uuid={article ? article.famille_uuid : null}
          article_uuid={article_uuid}
          gridFamilleXs={12}
        />

        <Grid item xs={12}>
          <TextInput
            style={{marginLeft:0}}
            multiline
            rowsMax="10"
            rows="6"
            id="commentaires"
            label="Commentaires"
            value={commentaires}
            margin="normal"
            collectionStore={potentielsStore}
            name='commentaires'
            onChangeHandler={onChangeHandler}
            fullWidth
          />
        </Grid>
      </Grid>
    )
  }

  // Permets de filtrer l'affichage des boutons d'édition que sur les potentiels provenant de la table et non de la view
  showEditDelete(potentiel) {
    return !potentiel.isview
  }

  /**
   * renvoie les données complémentaires
   */
  getExtradataForm() {
    const {mode} = this.props;
    let data = null;
    if (mode === 'article') {
      data = {
        article_uuid: this.state.article_uuid
      }
    } else if (mode === 'entreprise') {
      data = {
        entreprise_uuid: this.state.entreprise_uuid
      }
    }
    return data;
  }

  getCrudTitle() {
    const {classes, mode} = this.props
    if (mode === 'article') return null;
    return [
      <Typography
        key="typo"
        variant="button"
        gutterBottom
        className={classes.crudTitle}
      >
        Potentiels
      </Typography>
    ]
  }

  /**
   * Renvoie le collectionCrud
   */
  getContent() {
    const {classes} = this.props;
    return (
      <CollectionCrud
        collectionName={'potentiels'}
        rights={{
          create: 'admin-cud-potentiels',
          edit: 'admin-cud-potentiels',
          delete: 'admin-cud-potentiels'
        }}
        showBtnEdit={true}
        showBtnAdd={true}
        showBtnEditCallBack={this.showEditDelete.bind(this)}
        showBtnDeleteCallBack={this.showEditDelete.bind(this)}
        showBtnDelete={true}
        deleteModalTitle={this.getDeleteModalTitle}
        deleteModalContent={this.getDeleteModalContent.bind(this)}
        createUpdateModalTitle={this.getCreateUpdateModalTitle}
        createUpdateModalContent={
          this.getCreateUpdateModalContent.bind(this)
        }
        modalMaxWidth='lg'
        datatableConfig={{
          showPagination: true,
          showSearch: true,
          sortType: "desc",
          nodatalabel: "Aucun potentiel",
          rowsPerPageOptions: [10, 20],
          rowClassName: potentiel => potentiel.isview ? classes.rowView : ''
        }}
        dataTableExtraNodes={[
          {
            element: this.getCrudTitle(),
            position: 'top-left',
          }
        ]}
        cellsConfig={this.getCellsConfig()}
        extradatasForm={this.getExtradataForm()}
      />
    )
  }

  getContentModal() {
    const {article, classes} = this.props;
    if (!article) return <></>;

    return (
      <>
        <DialogTitle key="title" id="alert-dialog-slide-title">
          <TrendingUp className={classes.ico}/> Potentiels de l'article : {article.nom}
        </DialogTitle>
        <DialogContent key="content">
          {this.getContent()}
        </DialogContent>
      </>
    )
  }

  render() {
    let {mode, open, onCloseHandler} = this.props;
    if (mode === 'article') {
      return (
        <Modal
          openModal={open}
          onCloseHandler={onCloseHandler}
          onSubmitHandler={this.onSubmitHandler.bind(this)}
          fullWidth={true}
          maxWidth={'lg'}
          children={this.getContentModal()}
        />
      )
    } else if (mode === 'entreprise') {
      return this.getContent();
    } else return null

  }
}

Potentiel = withStyles(PotentielCss)(Potentiel);
Potentiel = withRouter(Potentiel);

Potentiel = connect((store) => {
  return {
    potentielStore: store.collections.potentiels
  }
})(Potentiel);

export default Potentiel
