export default theme => ({
  sectionTitle: {
    marginLeft: '-1em',
    marginBottom: '1em',
    color: theme.palette.primary.main
  },
  error: {
    color: theme.palette.error.main
  },
  noborderbottom: {
    borderBottom: 'none',
  },
  crudClass: {
    minWidth: 0,
    paddingRight: '1em!important',
    padding: '1em',
  },
  crudTitle:{
    float: 'left',
    marginBottom: 0,
    padding: '8px 0',
    color: theme.palette.primary.main,
    width:'100%'
  },
  cursor:{
      cursor: 'pointer'
    },
  unpaid_factures: {
    color: theme.palette.error.main
  },
  spanTitle:{
    display: 'inline-block',
    width: '100%',
    paddingTop: '2em'
  },
  selectContainer: {
    width: 'calc(100% - 1em)'
  },
  dateselectTitle:{
    display: 'inline-block',
    width: '20%'
  },
  CommercialselectTitle:{
    display: 'inline-block',
    width: '20%'
  },
  button: {
    float: 'right',
    padding: 3
  },
  thSend:{
    width:'5%',
    textAlign: 'center'
  },
  thBASend:{
    width:'10%',
    textAlign: 'center'

  },
  thEnvoye:{
    width:'5%',
    textAlign: 'center'
  },
  thPdf:{
    width:'10%'
  },
  thNom:{
    width:'70%'
  },
  emailButton: {
    // width: '280px',
  },
  emailButtonSortant: {
    marginTop: '1em',
    fontSize: '10px',
    padding: '3px 5px',
  },
  EtatLabel: {
  },
  EtatContainer: {
    textAlign: 'center',
  },
  megAffairesSelectedList: {
    display: 'flex',
    flexDirection: 'row',
    with: '100%',
    flexWrap: 'wrap',
  },
  megAffairesSelectedItem: {
    padding: '0.2em',
    borderRadius: '5px',
    border: '1px solid #ccc',
    margin: '0.2em',
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'center',
  },
  megAffairesSelectedItemRemove: {
    cursor: 'pointer',
    marginLeft: '0.5em',
    color: theme.palette.error.main,
  },
  warning_code_client_meg: {
    color: theme.palette.error.main,
    marginRight: '0.5em',
  },
});
