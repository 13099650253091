export default theme => ({

  crudTitle: {
    color: theme.palette.primary.main,
    marginTop: '30px',
  },

  relanceNote: {
    color: 'red',
  },

  clotureIcon: {
    color: '#b51919',
  },

  actionIcon: {
    color: 'orange'
  },

  container: {
    '& th:nth-child(3)':{
      minWidth: '250px',
    },

    '& tr': {
      '& td:nth-child(3)': {
        minWidth: '250px'
      }
    },
  },
  flexBottom: {
    display: 'flex',
    alignItems: 'end',

  },
  button: {
    float: 'right',
    padding: 12
  },
  buttonAdd: {
    color: 'white',
  },
  selectContainer: {
    width: 'calc(100% - 1em)'
  },
});
