import {Checkbox, CircularProgress, Grid, IconButton, MenuItem, Typography, withStyles} from '@material-ui/core'
import {Delete, Print, Warning, CloudDownload, VerticalAlignBottom} from '@material-ui/icons'
import PropTypes from 'prop-types'
import React, {Component} from 'react'
import {connect} from 'react-redux'
import {collectionActions} from '../../../reducers/collectionsReducer'
import TextInput from '../../common/Components/TextInput'
import PackinglistCss from './css/PackinglistCss'
import { addNotification } from '../../../reducers/notificationReducer'
import { themeComplement } from "../AppAdmin/css/theme";
import { buildRoute } from '../../../router/Tools'
import { getApiUrl } from '../../tools/Tools'

class SelectProduitTransforme extends Component {

  state = {
    articles:[],
    familles:[],
  }

  static checkFilterCondition(objet, filterSlug, filterValue) {
    if (!filterValue) return true;
    else return objet[filterSlug] === filterValue;
  }

  static filterProduits(list, filters) {
    const config = {
      famille_uuid: "article",
      article_uuid: null,
    };
    return list.filter((produit) => {
      return Object.keys(filters).every((key) =>
        SelectProduitTransforme.checkFilterCondition(
          produit,
          config[key] ? config[key] + "_" + key : key,
          filters[key]
        )
      );
    });
  }

  static getDerivedStateFromProps(nextProps, prevState) {
    const { produitsTransformesStore } = nextProps;
    let result = {};

    // Met à jour les filtres à partir des props s'ils sont fournis
    if (
      (nextProps.famille_uuid &&
        nextProps.famille_uuid !== prevState.filters.famille_uuid) ||
      (nextProps.article_uuid &&
        nextProps.article_uuid !== prevState.filters.article_uuid)
    ) {
      result = {
        ...result,
        initialFilter: true,
        filters: {
          famille_uuid: nextProps.famille_uuid,
          article_uuid: nextProps.article_uuid,
        },
      };
      if (produitsTransformesStore.list) {
        result = {
          ...result,
          produits: SelectProduitTransforme.filterProduits(
            produitsTransformesStore.list,
            result.filters
          ),
        };
      }
    }

    // Filtrer la liste des produits une fois qu'elle est disponible
    if (produitsTransformesStore.list && !prevState.produits) {
      result = {
        ...result,
        produits: SelectProduitTransforme.filterProduits(
          produitsTransformesStore.list,
          prevState.filters
        ),
      };
    }

    return result;
  }

  state = {
    produits: null,
    initialFilter: false,
    initialAddDeletedProducts: false,
    filters: {
      famille_uuid: null,
      article_uuid: null,
    },
  };

  componentDidMount() {
    collectionActions(
      this.props.dispatch,
      "familles",
      "INDEX_WITHOUT_DISPATCH",
      { params: { produit_entree: true,broye_only:true } }
    ).then((response) => {
      this.setState({
        familles: response.data,
      });
    })
    collectionActions(
      this.props.dispatch,
      "articles",
      "INDEX_WITHOUT_DISPATCH",
      { params: { produit_entree: true,broye_only:true } }
    ).then((response) => {
      this.setState({
        articles: response.data,
      });
    })
    collectionActions(
      this.props.dispatch,
      "stock/produits_transformes",
      "INDEX",
      {
        params: {
          pslist_filters: true,
          packinglist_uuid: this.props.packinglist_uuid,
          silolist_uuid: this.props.silolist_uuid,
        },
      }
    );
  }

  componentWillUnmount() {
    collectionActions(
      this.props.dispatch,
      "stock/produits_transformes",
      "RESET_LIST"
    );
  }

  onChangeFiltersHandler(name, e) {
    let filters = {
      ...this.state.filters,
      [name]: e.target.value,
    };
    this.setState({
      filters,
      produits: SelectProduitTransforme.filterProduits(
        this.props.produitsTransformesStore.list,
        filters
      ),
    });
  }

  getFamilles() {
    if (
      !this.props.produitsTransformesStore.list
    )
      return [];

    return [{ uuid: null, nom: " " }]
      .concat(this.state.familles)
      .filter((famille) => famille && famille.hasOwnProperty('uuid')) // On filtre les familles qui n'ont pas de uuid
      .map((famille) => {
        return (
          <MenuItem
            style={{ minHeight: "36px" }}
            value={famille.uuid}
            key={famille.uuid}
            disabled={
              !!(
                this.props.produitsTransformesStore.list.findIndex(
                  (p) => p.article_famille_uuid === famille.uuid
                ) === -1 && famille.uuid
              )
            }
          >
            {famille.nom}
          </MenuItem>
        );
      });
  }

  getArticles(famille_uuid) {
    if (
      !this.state.articles ||
      !this.props.produitsTransformesStore.list
    )
      return [];

    let list = this.state.articles.slice();

    if (famille_uuid) {
      list = list.filter((a) => a.famille_uuid === famille_uuid);
    }
    /**
     * Filtre les derniers articles non présent dans les produits transformés.
     */
    list = list.filter((a) => this.props.produitsTransformesStore.list.findIndex((p) => p.article_uuid === a.uuid) > -1)
    return [{ uuid: null, nom: " " }].concat(list).map((article) => {
      return (
        <MenuItem
          style={{ minHeight: "36px" }}
          value={article.uuid}
          key={article.uuid}
        >
          {article.nom}
        </MenuItem>
      );
    });
  }

  getFilters() {
    const { article_uuid, produitsTransformesStore } = this.props;

    // Masque les filtres si l'article est déjà fourni
    if (article_uuid !== undefined) {
      return null;
    }

    let { filters } = this.state;

    return (
      <Grid container>
        <Grid item xs={4}>
          <TextInput
            id="famille_uuid"
            label="Famille"
            value={filters.famille_uuid}
            margin="normal"
            name="famille_uuid"
            collectionStore={produitsTransformesStore}
            onChangeHandler={this.onChangeFiltersHandler.bind(this)}
            select
            fullWidth
          >
            {this.getFamilles()}
          </TextInput>
        </Grid>
        <Grid item xs={4}>
          <TextInput
            id="article_uuid"
            label="Article"
            value={filters.article_uuid}
            margin="normal"
            name="article_uuid"
            collectionStore={produitsTransformesStore}
            onChangeHandler={this.onChangeFiltersHandler.bind(this)}
            fullWidth
            select
          >
            {this.getArticles(filters.famille_uuid)}
          </TextInput>
        </Grid>
      </Grid>
    );
  }

  getproduits() {
    if (this.props.produitsTransformesStore.fetching)
      return <CircularProgress />;

    let results = [];
    const { classes, disabled, selectCb, packingListsStore } = this.props;
    const packingList = packingListsStore.detail;

    if (this.state.produits)
      results.push(
        this.state.produits.map((produit) => {
          let titre = [];

          titre.push(produit.reference);
          if (produit.duplicate)
            titre.push(
              <Warning
                key={"duplicate_" + produit.uuid}
                className={classes.warning}
              />
            );
          const isSelected =
            this.props.selection.findIndex((p) => p.uuid === produit.uuid) !==
            -1;
          const isInPackinglist = isSelected && this.props.packinglist_uuid;
          const isInSilolist =
            isSelected && this.props.silolist_uuid !== undefined;
          const isIReserverDisponible =
            !isSelected &&
            produit.statut === "reserver_disponible" &&
            (!packingList ||
              !produit.PL_reserve_disponible.includes(packingList.reference));
          return (
            <div key={produit.uuid} className={classes.cardContainer}>
              <div
                className={
                  classes.card +
                  (disabled
                    ? " " + classes.cardDisabled
                    : " " + classes.cardHover) +
                  (isInPackinglist ? " " + classes.packinglist : "") +
                  (isIReserverDisponible
                    ? " " + classes.packinglist_disponible
                    : "") +
                  (isInSilolist ? " " + classes.silolist : "")
                }
                onClick={() =>
                  disabled !== true &&
                  this.preCallBackSelectProduct(
                    produit,
                    isIReserverDisponible,
                    selectCb
                  )
                }
                title={this.getProduitDetail(produit)}
              >
                {titre}
              </div>
            </div>
          );
        })
      );

    return results;
  }

  preCallBackSelectProduct(produit, isIReserverDisponible, selectCb) {
    if (isIReserverDisponible) {
      addNotification(this.props.dispatch, {
        message:
          "Merci de désélectionner au préalable le produit " +
          produit.reference +
          " de la packinglist " +
          this.getPlReserveDisponible(produit),
        bgColor: themeComplement.palette.notifications.error.color,
      });
    } else {
      selectCb(produit);
    }
  }

  getPlReserveDisponible(produit) {
    const { packingListsStore } = this.props;
    const packingList = packingListsStore.detail;
    return produit.PL_reserve_disponible.filter(
      (pl_reference) =>
        pl_reference !== (packingList ? packingList.reference : null)
    ).join(", ");
  }

  getProduitDetail(produit) {
    return Object.keys(produit.info_bulle).map((key) => {
      return `${key} : ${produit.info_bulle[key]}`
    }).join("\n")
  }

  getSelection() {
    const { classes, disabled,sesotec  } = this.props

    if (!this.props.selection) return null;
    let result = [];

    this.props.selection.map(p => {
      let sesotec_checkbox = null
      if(sesotec){
        if(!p.pivot){
          p.pivot = {
            sesotec: false
          }
        }
        sesotec_checkbox = <Checkbox
          checked={p.pivot.sesotec}
          onChange={(event) => this.onChangeSesotec(event, p)}
          color="primary"
          title='SESOTEC'
        />
      }
      return result.push(
       <div className={classes.selection_container}>
         {sesotec_checkbox}
          <div
            className={classes.selectionItem + (disabled ? (' ' + classes.selectionItemDisabled) : (' ' + classes.selectionItemHover))}
            key={p.uuid}
            onClick={() => disabled !== true && this.props.removeCb(p)}
            title={(disabled ? '' : 'Supprimer le produit\n\n') + this.getProduitDetail(p)}
          >
            <div className={classes.selectionItemTitle}><span
              className={classes.selectionItemTitleRef}>{p.reference}</span> {p.nom_famille + ' ' + p.article_nom}</div>
            <div className={classes.selectionItemIcon}><Delete fontSize="inherit"/></div>
          </div>
       </div>
      )
    })

    return result;
  }
  onChangeSesotec(event, produit) {
    const { sesotec_cb } = this.props
    sesotec_cb(produit, event.target.checked)
    produit.pivot.sesotec = event.target.checked
    this.forceUpdate() // force update to update checkbox
  }

  immpressionEnMasse(selection) {
   let  produitsUuid = selection.map((p) => p.uuid)
    window.open(
      buildRoute(getApiUrl() + "/stock/produits_transformes/etiquette", {
        listUuids:JSON.stringify(produitsUuid),
        packinglistReference:this.props.packingListsStore.detail.reference
      }),
      "_blank"
    );
  }

  exportProduits(selection) {
    let  produitsUuid = selection.map((p) => p.uuid)
     window.open(
       buildRoute(getApiUrl() + "/stock/produits_transformes/exports", {
         listUuids:JSON.stringify(produitsUuid),
         packinglistReference:this.props.packingListsStore.detail.reference
       }),
       "_blank"
     );
   }

  getSelectionTitle() {
    const { classes, maxWeight, selection, packingListsStore } = this.props;

    const packingList = packingListsStore.detail;

    let displaydowloadBtn = null

    if(packingList && packingList.uuid ){
      displaydowloadBtn = (
        <IconButton
        key={packingList.uuid}
        title={"Télécharger la packing list"}
        variant="contained"
        color="primary"
        href={
          process.env.REACT_APP_API_URL +
          "/packinglists/" +
          packingList.uuid +
          "/pdf"
        }
        className={this.props.classes.massImpression}
        target="_blank"
      >
        <VerticalAlignBottom />
    </IconButton>
      )
    }

    let poids = 0,
      qte = 0;

    if (selection) {
      qte = selection.length;
      selection.map((p) => (poids += parseFloat(p.poids)));
    }

    const isOverloaded = maxWeight > 0 && poids >= maxWeight;

    return (
      <>
      <div>
        <Typography
          key="typo"
          variant="button"
          gutterBottom
          className={this.props.classes.title}
        >
          Sélection
          <div className={this.props.classes.btnContainer}>
          <IconButton
              onClick={this.immpressionEnMasse.bind(this,selection)}
              color="primary"
              title="Imprimer toutes les étiquettes"
              className={this.props.classes.massImpression}
            >
              <Print />
          </IconButton>
          <IconButton
              onClick={this.exportProduits.bind(this,selection)}
              color="primary"
              title="Exporter les produits"
              className={this.props.classes.massImpression}
            >
              <CloudDownload />
          </IconButton>
          {displaydowloadBtn}
          {/* <IconButton
              key={packingList.uuid}
              title={"Télécharger la packing list"}
              variant="contained"
              color="primary"
              href={
                process.env.REACT_APP_API_URL +
                "/packinglists/" +
                packingList.uuid +
                "/pdf"
              }
              className={this.props.classes.massImpression}
              target="_blank"
            >
              <VerticalAlignBottom />
          </IconButton> */}
          </div>
        </Typography>

        </div>
        <div className={this.props.classes.infos}>
          Qté : {qte} |&nbsp;
          <span className={isOverloaded ? classes.overloaded : ""}>
            {poids}&nbsp;kg
          </span>
        </div>
      </>
    );
  }

  render() {
    const { classes, title } = this.props;

    return (
      <Grid container>
        <Grid item xs={10} className={classes.produitsContainer}>
          <Typography
            key="typo"
            variant="button"
            gutterBottom
            className={classes.title}
          >
            {title ? title : "Produits"}
          </Typography>
          {this.getFilters()}
          <Grid item xs={12} className={classes.produits}>
            <Grid container className={classes.selectorContainer}>
              {this.getproduits()}
            </Grid>
          </Grid>
        </Grid>
        <Grid item xs={2} className={classes.selected}>
          {this.getSelectionTitle()}
          <div className={classes.selectionItemContainer}>
            {this.getSelection()}
          </div>
        </Grid>
      </Grid>
    );
  }
}

SelectProduitTransforme = withStyles(PackinglistCss)(SelectProduitTransforme);

SelectProduitTransforme = connect((store) => {
  return {
    produitsTransformesStore: store.collections["stock/produits_transformes"],
    packingListsStore: store.collections.packinglists,
  };
})(SelectProduitTransforme);

SelectProduitTransforme.propTypes = {
  disabled: PropTypes.bool,
  maxWeight: PropTypes.number,
  removeCb: PropTypes.func.isRequired,
  selectCb: PropTypes.func.isRequired,
  selection: PropTypes.array.isRequired,
  silolist_uuid: PropTypes.string,
  title: PropTypes.string,
  detail_per_famille: PropTypes.object,
}

export default SelectProduitTransforme;
