import React, { Component } from "react";
import { connect } from "react-redux";
import {
  Button,
  Grid,
  Typography,
  Radio,
  RadioGroup,
  FormControlLabel,
  Dialog,
  DialogContent,
  DialogTitle,
  MenuItem,
  TextField,
} from "@material-ui/core";
import { withStyles } from "@material-ui/core/styles";
import {
  Assignment,
  Done,
  Business,
  EuroSymbol,
  Grain,
  GridOn,
  Person,
  History,
  Cached,
  AddShoppingCart,
  Warning,
} from "@material-ui/icons";

import HomeCss from "./css/ExportCss";

import TextInput from "../../common/Components/TextInput";
import DateInput from "../../common/Components/DateInput";
import { addNotification } from "../../../reducers/notificationReducer";
import { themeComplement } from "../AppAdmin/css/theme";
import { collectionActions } from "../../../reducers/collectionsReducer";
import ExportTransporteurGazoil from "../ExportTransporteurGazoil";

class Export extends Component {
  state = {
    entreprise_uuid: null,
    mois: new Date(),
    date_debut: new Date(),
    date_fin: new Date(),
    selectedValue: "mois",
    openModal: false,
    openModalMarge: false,
    showPeriodeAnterieur: false,
    href: null,
    selectedCommerciaux: [],
    date_debut_marge: null,
    date_fin_marge: null,
  };

  componentDidMount() {
    collectionActions(this.props.dispatch, "users", "INDEX", {
      params: {
        commerciaux: true,
      },
    });
  }

  /**
   * Partie des boutons d'export
   */
  getExportPartial() {
    const { classes } = this.props;

    return (
      <Grid
        item
        xs={12}
        className={classes.section}
        style={{ marginRight: "1em" }}
      >
        <Grid item xs={12} className={classes.section}>
          <Typography
            variant="button"
            gutterBottom
            className={classes.sectionTitle}
          >
            Exports
          </Typography>
        </Grid>
        <Button
          title={"Export des Affaires"}
          variant="contained"
          color="primary"
          className={classes.button + " " + classes.no_margin}
          onClick={() =>
            this.setState({
              openModal: true,
              showPeriodeAnterieur: true,
              href: process.env.REACT_APP_API_URL + "/affaires/export",
            })
          }
        >
          <EuroSymbol className={classes.file_ico} />
          Affaires
        </Button>
        <Button
          title={"Export des Entreprises"}
          variant="contained"
          color="primary"
          href={process.env.REACT_APP_API_URL + "/entreprises/export"}
          className={classes.button}
        >
          <Business className={classes.file_ico} />
          Entreprises
        </Button>
        <Button
          title={"Export des Contacts"}
          variant="contained"
          color="primary"
          href={process.env.REACT_APP_API_URL + "/contacts/export"}
          className={classes.button + " " + classes.no_margin}
        >
          <Person className={classes.file_ico} />
          Contacts
        </Button>
        <Button
          title={"Export des Produits Transformés"}
          variant="contained"
          color="primary"
          className={classes.button}
          onClick={() =>
            this.setState({
              showPeriodeAnterieur: true,
              openModal: true,
              href:
                process.env.REACT_APP_API_URL + "/produits_transformes/export",
            })
          }
        >
          <GridOn className={classes.file_ico} />
          Produits transformés
        </Button>
        <Button
          title={"Export des Silo lists"}
          variant="contained"
          color="primary"
          href={process.env.REACT_APP_API_URL + "/silolists/export"}
          className={classes.button + " " + classes.no_margin}
        >
          <Assignment className={classes.file_ico} />
          Silo lists
        </Button>

        <Button
          title={"Export des Factures annexes"}
          variant="contained"
          color="primary"
          className={classes.button}
          onClick={() =>
            this.setState({
              openModal: true,
              showPeriodeAnterieur: true,
              href: process.env.REACT_APP_API_URL + "/facture_annexes/export",
            })
          }
        >
          <EuroSymbol className={classes.file_ico} />
          Factures annexes
        </Button>

        <Button
          title={"Export du Registre des déchets de négoce"}
          variant="contained"
          color="primary"
          href={
            process.env.REACT_APP_API_URL + "/affaires/export/dechets-negoce"
          }
          className={classes.buttonSmall + " " + classes.no_margin}
        >
          <Grain className={classes.file_ico} />
          Déchets Négoce
        </Button>

        <Button
          title={"Export du Registre des déchets entrants"}
          variant="contained"
          color="primary"
          href={
            process.env.REACT_APP_API_URL + "/affaires/export/dechets-entrants"
          }
          className={classes.buttonSmall}
        >
          <Grain className={classes.file_ico} />
          Déchets Entrants
        </Button>
        <Button
          title={"Export du Registre des déchets sortants"}
          variant="contained"
          color="primary"
          href={
            process.env.REACT_APP_API_URL + "/affaires/export/dechets-sortants"
          }
          className={classes.buttonSmall}
        >
          <Grain className={classes.file_ico} />
          Déchets Sortants
        </Button>
        <Button
          title={"Export Marge commerciaux"}
          variant="contained"
          color="primary"
          className={classes.button + " " + classes.no_margin}
          onClick={() =>
            this.setState({
              openModalMarge: true,
              href:
                process.env.REACT_APP_API_URL +
                "/affaires/export/marge-commerciaux",
            })
          }
        >
          <Cached className={classes.file_ico} />
          Export Marge commerciaux
        </Button>
        <ExportTransporteurGazoil />
        <Button
          title={"Export des Mad"}
          variant="contained"
          color="primary"
          href={process.env.REACT_APP_API_URL + "/mise-a-dispositions/export"}
          className={classes.button + " " + classes.no_margin}
        >
          <AddShoppingCart className={classes.file_ico} />
          Mise à disposition
        </Button>
        <Button
          title={"Export des NCs"}
          variant="contained"
          color="primary"
          href={process.env.REACT_APP_API_URL + "/non-conformites/export"}
          className={classes.button}
        >
          <Warning className={classes.file_ico} />
          Non conformités
        </Button>
      </Grid>
    );
  }

  /**
   * OnChange Handler
   */
  onChangeHandler(name, event) {
    const { value } = event.target;
    this.setState({
      [name]: value,
    });
  }

  /**
   * Soumission du formulaire
   */
  onSubmitHandler() {
    let { mois, date_debut, date_fin, entreprise_uuid, selectedValue } =
        this.state,
      fields = { entreprise_uuid };
    if (!entreprise_uuid) {
      addNotification(this.props.dispatch, {
        message: "Fournisseur obligatoire",
        bgColor: themeComplement.palette.notifications.error.color,
      });
    } else {
      if (selectedValue === "mois") {
        mois =
          mois instanceof Date
            ? mois.getFullYear() +
              "-" +
              ((mois.getMonth() < 9 ? "0" : "") + (mois.getMonth() + 1)) +
              "-" +
              (mois.getDay() < 10 ? "0" : "") +
              mois.getDay()
            : mois;

        fields = {
          ...fields,
          mois,
        };
      } else {
        date_debut =
          date_debut instanceof Date
            ? date_debut.getFullYear() +
              "-" +
              ((date_debut.getMonth() < 9 ? "0" : "") +
                (date_debut.getMonth() + 1)) +
              "-" +
              (date_debut.getDate() < 10 ? "0" : "") +
              date_debut.getDate()
            : date_debut;

        date_fin =
          date_fin instanceof Date
            ? date_fin.getFullYear() +
              "-" +
              ((date_fin.getMonth() < 9 ? "0" : "") +
                (date_fin.getMonth() + 1)) +
              "-" +
              (date_fin.getDate() < 10 ? "0" : "") +
              date_fin.getDate()
            : date_fin;

        fields = {
          ...fields,
          date_debut,
          date_fin,
        };
      }

      let params = Object.entries({
          ...fields,
        })
          .map((kv) => (kv[1] ? kv.map(encodeURIComponent).join("=") : null))
          .join("&"),
        url =
          process.env.REACT_APP_API_URL +
          "/entreprises/bordereau-achat-groupe?" +
          params;

      window.open(url, "_blank");
    }
  }

  handleChange = (event) => {
    this.setState({ selectedValue: event.target.value });
  };

  getPeriodInputs() {
    const { affairesStore } = this.props;
    let { date_debut, date_fin, mois, selectedValue } = this.state;

    if (selectedValue === "mois") {
      return (
        <DateInput
          id="mois"
          label="Mois d'export"
          format={"MMMM yyyy"}
          value={mois}
          margin="normal"
          collectionStore={affairesStore}
          name="mois"
          onChangeHandler={this.onChangeHandler.bind(this)}
          fullWidth
        />
      );
    } else {
      return (
        <>
          <DateInput
            id="date_debut"
            label="Date de début d'export"
            value={date_debut}
            margin="normal"
            collectionStore={affairesStore}
            name="date_debut"
            onChangeHandler={this.onChangeHandler.bind(this)}
            fullWidth
          />
          <DateInput
            id="date_fin"
            label="Date de fin d'export"
            value={date_fin}
            margin="normal"
            collectionStore={affairesStore}
            name="date_fin"
            onChangeHandler={this.onChangeHandler.bind(this)}
            fullWidth
          />
        </>
      );
    }
  }

  /**
   * Partie Bordeau d'achat
   * @return {[type]} [description]
   */
  getBordereauAchatGroupePartial() {
    const { classes, affairesStore } = this.props;
    let { entreprise_uuid, selectedValue } = this.state;

    return (
      <Grid item xs={12} className={classes.section}>
        <Typography
          variant="button"
          gutterBottom
          className={classes.sectionTitle}
        >
          Bordereau d'achat groupé
        </Typography>
        <Grid item xs={12} style={{ marginRight: "1em" }}>
          <Grid item xs={12}>
            <TextInput
              id="entreprise_uuid"
              label="Fournisseur"
              className={classes.selectContainer}
              collectionStore={affairesStore}
              value={entreprise_uuid}
              margin="normal"
              name="entreprise_uuid"
              onChangeHandler={this.onChangeHandler.bind(this)}
              type="autocomplete"
              autocompleteProps={{
                collectionName: "entreprises",
              }}
              fullWidth
            />
            <RadioGroup
              name="dates"
              className={classes.group}
              value={selectedValue}
              onChange={this.handleChange}
              row
            >
              <FormControlLabel
                value="mois"
                control={<Radio color="primary" />}
                label="Mois"
              />
              <FormControlLabel
                value="periode"
                control={<Radio color="primary" />}
                label="Période"
              />
            </RadioGroup>
            {this.getPeriodInputs()}
            <Grid item xs={12}>
              <Button
                title="Valider les modifications"
                color="primary"
                variant="contained"
                size="small"
                onClick={this.onSubmitHandler.bind(this)}
                className={classes.doneButton}
              >
                <Done className={classes.buttonIcon} /> Valider
              </Button>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    );
  }

  getExportsAdmin() {
    const { user, classes } = this.props;

    if (!user) {
      return;
    }

    if (!user.is_admin) {
      return;
    }

    return (
      <Grid
        item
        xs={12}
        className={classes.section}
        style={{ marginRight: "1em" }}
      >
        <Grid item xs={12} className={classes.section}>
          <Typography
            variant="button"
            gutterBottom
            className={classes.sectionTitle}
          >
            Historique
          </Typography>
        </Grid>
        <Grid item xs={12}>
          <Button
            title={"Export de l'historique sur 30 jours"}
            variant="contained"
            color="primary"
            href={process.env.REACT_APP_API_URL + "/logs"}
            className={classes.buttonSimple + " " + classes.no_margin}
          >
            <History className={classes.file_ico} />
            Logs (30 jours)
          </Button>
        </Grid>
      </Grid>
    );
  }

  onChangeHandlerFilters(name, e) {
    let values = e.target.value;
    /**
     * Gestion de la value all
     * si on clique dessus on vide la liste et ne garde que all
     * si on clique sur un autre element on enleve all
     * si on vide la liste on rajoute all
     */
    if (values.length > 0) {
      if (this.state[name].indexOf("all") !== -1) {
        values = values.filter((value) => value !== "all");
      } else if (values.indexOf("all") !== -1) {
        values = ["all"];
      }
    } else {
      values = ["all"];
    }
    this.setState({
      [name]: values,
    });
  }

  generateCommercialSelect() {
    const { usersStore, classes } = this.props;
    if (!usersStore.list) return null;
    const { selectedCommerciaux } = this.state;
    const commerciaux = [...usersStore.list];
    const options = commerciaux.map((user) => {
      return (
        <MenuItem value={user.uuid} key={user.uuid}>
          {user.firstname}
        </MenuItem>
      );
    });

    return (
      <TextField
        id={"selectedCommerciaux"}
        label={"Commerciaux"}
        margin="normal"
        onChange={this.onChangeHandlerFilters.bind(this, "selectedCommerciaux")}
        className={classes.selectContainer}
        SelectProps={{
          multiple: true,
          value: selectedCommerciaux,
        }}
        select
      >
        <MenuItem value={"all"} key={"all"}>
          Tous
        </MenuItem>
        {options}
      </TextField>
    );
  }

  generateMargeCommercialUrl() {
    const { usersStore } = this.props;
    if (!usersStore.list) return null;

    const url_search_params = new URLSearchParams();
    const { selectedCommerciaux, date_debut_marge, date_fin_marge, href } = this.state;

    // SI all est selectionné on prend toute la liste de commerciaux
    if(selectedCommerciaux.indexOf("all") !== -1){
      const allCcommerciaux = [...usersStore.list];
      allCcommerciaux.forEach((commercial) => {
        url_search_params.append("commerciaux_uuid[]", commercial.uuid);
      });
    }
    else{
    selectedCommerciaux.forEach((commercial_uuid) => {
      url_search_params.append("commerciaux_uuid[]", commercial_uuid);
    });
    }
    if(date_debut_marge){
      url_search_params.append("date_debut", date_debut_marge);
    }
    if(date_fin_marge){
      url_search_params.append("date_fin", date_fin_marge);
    }

    return href + "?" + url_search_params.toString();
  }

  /*
   * Rendu Final
   */
  render() {
    const { openModal, openModalMarge } = this.state;
    const { classes, usersStore } = this.props;
    return (
      <Grid container>
        <Grid item xs={6}>
          {this.getExportPartial()}
        </Grid>
        <Grid item xs={4}>
          {this.getBordereauAchatGroupePartial()}
        </Grid>
        <Grid item xs={2}>
          {this.getExportsAdmin()}
        </Grid>

        <Dialog
          open={openModal}
          onClose={() => {
            this.setState({ openModal: false, href: null, showPeriodeAnterieur: false});
          }}
        >
          <DialogTitle key="title" id="alert-dialog-slide-title">
            <Typography>Export</Typography>
          </DialogTitle>
          <DialogContent key="content">
            {this.state.showPeriodeAnterieur && (
              <Button
                className={classes.btn}
                target="_blank"
                color="accent"
                href={this.state.href + "?periode_anterieur=1"}
              >
                Export Antérieur N -2
              </Button>
            )}
            <Button
              className={classes.btn}
              target="_blank"
              color="primary"
              href={this.state.href + "?periode=n-2"}
            >
              Export N -2
            </Button>
            <Button
              className={classes.btn}
              target="_blank"
              color="secondary"
              href={this.state.href}
            >
              Export Complet
            </Button>
          </DialogContent>
        </Dialog>

        <Dialog
          open={openModalMarge}
          onClose={() => {
            this.setState({
              openModalMarge: false,
              href: null,
              date_debut_marge: null,
              date_fin_marge: null,
              selectedCommerciaux: [],
            });
          }}
          maxWidth="md"
          fullWidth={true}
        >
          <DialogTitle key="title">
            <Typography>Export Marge commerciaux</Typography>
          </DialogTitle>
          <DialogContent key="content">
            <Grid container>
              <Grid item xs={12}>
                {this.generateCommercialSelect()}
              </Grid>
              <Grid item xs={6}>
                <DateInput
                  views={['year', 'month']}
                  label="Date de début"
                  name="date_debut_marge"
                  value={this.state.date_debut_marge}
                  onChangeHandler={this.onChangeHandler.bind(this)}
                  collectionStore={usersStore}
                  format="MM/yyyy"
                  fullWidth
                  autoOk={true}
                />
              </Grid>
              <Grid item xs={6}>
                <DateInput
                  views={['year', 'month']}
                  label="Date de fin"
                  name="date_fin_marge"
                  value={this.state.date_fin_marge}
                  onChangeHandler={this.onChangeHandler.bind(this)}
                  collectionStore={usersStore}
                  format="MM/yyyy"
                  fullWidth
                  autoOk={true}
                />
              </Grid>
              <Grid item xs={12}>
                <hr className={classes.margin_top} />
              </Grid>
              <Grid item xs={9}></Grid>
              <Grid item xs={3}>
                <Button
                  className={classes.btn}
                  target="_blank"
                  color="primary"
                  href={this.generateMargeCommercialUrl()}
                >
                  Export
                </Button>
                <Button
                  className={classes.btn}
                  target="_blank"
                  color="secondary"
                  onClick={() => {
                    this.setState({
                      openModalMarge: false,
                      href: null,
                      date_debut_marge: null,
                      date_fin_marge: null,
                      selectedCommerciaux: [],
                    });
                  }}
                >
                  Annuler
                </Button>
              </Grid>
            </Grid>
          </DialogContent>
        </Dialog>
      </Grid>
    );
  }
}

Export = withStyles(HomeCss)(Export);
Export = connect((store) => {
  return {
    affairesStore: store.collections.affaires,
    user: store.auth.user,
    usersStore: store.collections.users,
  };
})(Export);

export default Export;
