import React, {Component} from 'react';
import {withStyles} from '@material-ui/core/styles';
import {Grid, Typography} from '@material-ui/core';
import {Settings} from '@material-ui/icons';
import TextInput from '../../common/Components/TextInput';
import TraitementCss from './css/traitementCss';
import CollectionCrud from '../../common/Components/CollectionCrud';

class Traitement extends Component {

  getDeleteModalTitle() {
    return (
      <div>
        <Settings/>
        <span>{"Suppression du traitement"}</span>
      </div>
    );
  }

  getDeleteModalContent() {
    return (
      <Typography>
        {'Êtes-vous sûr de vouloir supprimer ce traitement ?'}
      </Typography>
    );
  }

  getCreateUpdateModalTitle(traitement) {
    let title = !!traitement
      ? "Modification d'un traitement : " + traitement.nom
      : "Ajout d'un nouveau traitement"
    return (
      <div>
        <Settings/>
        <span>{title}</span>
      </div>
    );
  }

  getCreateUpdateModalContent(traitement, traitementsStore, onChangeHandler) {
    let {libelle} = traitement;
    return (
      <Grid container>
        <Grid item xs={12}>
          <TextInput
            id="libelle"
            label="libelle"
            value={libelle}
            margin="normal"
            collectionStore={traitementsStore}
            name='libelle'
            onChangeHandler={onChangeHandler}
            fullWidth
          />
        </Grid>
      </Grid>
    );
  }

  render() {
    const {classes} = this.props;
    return (
      <Grid container className={classes.container}>
        <CollectionCrud
          collectionName={'traitements'}
          showBtnEdit={true}
          showBtnAdd={true}
          showBtnDelete={true}
          rights={{
            create: 'admin-cud-parametres',
            edit: 'admin-cud-parametres',
            delete: 'admin-cud-parametres'
          }}
          deleteModalTitle={this.getDeleteModalTitle}
          deleteModalContent={this.getDeleteModalContent}
          createUpdateModalTitle={this.getCreateUpdateModalTitle}
          createUpdateModalContent={this.getCreateUpdateModalContent.bind(this)}
          datatableConfig={{
            showPagination: true,
            showSearch: true,
            defaultSort: "nom",
            sortType: 'desc',
            rowsPerPageOptions: [10, 25, 50],
          }}
          cellsConfig={[{
            datakey: "libelle",
            title: "Libelle",
            sortable: true,
            searchable: true,
          }]}
        />
      </Grid>
    );
  }
}

Traitement = withStyles(TraitementCss)(Traitement);

export default Traitement
