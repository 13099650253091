import React, { Component } from 'react'
import PropTypes from 'prop-types'

import {
  Avatar,
  Checkbox,
  Chip,
  FormControl,
  FormHelperText,
  Input,
  InputLabel,
  ListItemText,
  MenuItem,
  Select,
} from '@material-ui/core'
import { withStyles } from '@material-ui/core/styles'

import UserSelectCss from './css/UserSelectCss'

class UserSelect extends Component {
  getErrorMessage(collectionStore, name) {
    if (collectionStore.errors && collectionStore.errors[name]) {
      return collectionStore.errors[name].map(
        (err, index) => (<span style={{display: 'block'}} key={index}>{err}</span>)
      );
    }
    return null;
  }

  onChange(event) {
    const { onChange } = this.props
    onChange(event.target.value)
  }

  render() {
    const { classes, collectionStore, disabled, name, onDelete, selected, users } = this.props
    const userUuids = selected
    const userList = users
    const ITEM_HEIGHT = 48
    const ITEM_PADDING_TOP = 8
    const MenuProps = {
      PaperProps: {
        style: {
          maxHeight: 4.5*ITEM_HEIGHT + ITEM_PADDING_TOP,
          width: 250,
        },
      },
    }
    const hasError = !!collectionStore.errors && !!collectionStore.errors[name]
    const errorMessage = this.getErrorMessage(collectionStore, name)
    return (
      <div className={classes.container}>
        <FormControl
          className={classes.formControl}
          error={hasError}
          fullWidth
        >
          <InputLabel htmlFor="select-multiple-checkbox">
            {this.props.label ?? 'Utilisateurs'}{this.props.required ? ' *' : ''}
          </InputLabel>
          <Select
            disabled={disabled}
            multiple
            value={userUuids}
            onChange={this.onChange.bind(this)}
            input={<Input id="select-multiple-checkbox"/>}
            renderValue={(selected) =>
              selected.map((uuid) => {
                const user = userList.find((user) => user.uuid === uuid);
                return user ? (
                  <Chip
                    key={user.uuid}
                    variant="outlined"
                    onDelete={() => onDelete(user.uuid)}
                    label={user.firstname + " " + user.lastname}
                    avatar={
                      <Avatar className={classes.avatar}>
                        {(
                          user.firstname.charAt(0) + user.lastname.charAt(0)
                        ).toUpperCase()}
                      </Avatar>
                    }
                    className={classes.chips}
                  />
                ) : null
              })
            }
            MenuProps={MenuProps}
          >
            {userList.map((user) => {
              let checked = undefined
              if (userUuids) {
                checked = userUuids.find((uuid) => {
                  return uuid === user.uuid;
                })
              }
              return (
                <MenuItem key={user.uuid} value={user.uuid}>
                  <Checkbox checked={checked !== undefined} color="primary" />
                  <ListItemText
                    primary={user.firstname + " " + user.lastname}
                  />
                </MenuItem>
              )
            })}
          </Select>
          <FormHelperText
            className={classes.helper}
            error={hasError}
          >
            {errorMessage}
          </FormHelperText>
        </FormControl>
      </div>
    )
  }
}

UserSelect.propTypes = {
  collectionStore: PropTypes.object,
  disabled: PropTypes.bool,
  label: PropTypes.string,
  name: PropTypes.string,
  onChange: PropTypes.func.isRequired,
  onDelete: PropTypes.func.isRequired,
  required: PropTypes.bool,
  selected: PropTypes.array.isRequired,  // [uuid]
  users: PropTypes.array.isRequired,  // [User]
}

UserSelect = withStyles(UserSelectCss)(UserSelect);

export default UserSelect
