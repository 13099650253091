export default theme => ({
  root: {
    flexGrow: 1
  },
  button: {
    float: 'right'
  },
  addButton: {
    marginLeft: '2em',
    marginTop: '0.5em',
    marginRight: '0.5em'
  },
  addButtonIcon: {
    fontSize: '32px'
  },
  error: {
    color: theme.palette.error.main
  },
  selectContainer: {
    width: 'calc(100% - 1em)'
  },
  formTextArea: {
    width: "calc(100% - 1em)",
    display: "flex",
    flexDirection: "column",
  },
  hr: {
    border: "0.5px solid #18b0ff",
    width: "100%",
  },
  inputfield: {
    marginTop: "1em",
  },
  checkboxContainer:{
    marginTop: "1em",
    marginBottom: "1em",
  },
  checkboxCase: {
    alignContent: "end",
  },
  checkBoxInputField: {
    paddingBottom: "0.5em",
  },
  btnContainer: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-around",
    marginTop: "1em",
  },
  btn:{
    display:'inline-block',
  },
  crudTitle: {
    color: theme.palette.primary.main,
    marginBottom: '1em'
  },
  filtersContainer: {
    alignItems: 'flex-end',
    marginTop: '1em'
  },
  scrollAuto: {
    overflow: 'auto'
  },
  link: {
    fontSize: '.875rem',
    textAlign: 'left',
    fontFamily: '"Roboto", "Helvetica", "Arial", sans-serif',
    fontWeight: '400',
    lineHeight: '1.43',
    cursor: 'pointer',
    color: theme.palette.primary.main,
    textDecoration: 'none',
    '&:hover': {
      textDecoration: 'underline',
    }
  },
});
