import React, {Component} from 'react';
import {withStyles} from '@material-ui/core/styles';
import {Grid, Typography} from '@material-ui/core';
import {Settings} from '@material-ui/icons';
import TextInput from '../../common/Components/TextInput';
import TypeNoteCss from './css/TypeNoteCss';
import CollectionCrud from '../../common/Components/CollectionCrud';
import CheckboxInput from '../../common/Components/CheckboxInput';

class TypeNote extends Component {

  getDeleteModalTitle() {
    return (
      <div>
        <Settings/>
        <span>{"Suppression du type de note"}</span>
      </div>
    );
  }

  getDeleteModalContent() {
    return (
      <Typography>
        {'Êtes-vous sûr de vouloir supprimer ce type de note ?'}
      </Typography>
    );
  }

  getCreateUpdateModalTitle(typenote) {
    let title = !!typenote
      ? "Modification du type de note : " + typenote.libelle
      : "Ajout d'un nouveau type de note"
    return (
      <div>
        <Settings/>
        <span>{title}</span>
      </div>
    );
  }

  getCreateUpdateModalContent(typenote, typenoteStore, onChangeHandler) {
    let {libelle, need_assign } = typenote;
    return (
      <Grid container>
        <Grid item xs={12}>
          <TextInput
            id="libelle"
            label="Libelle"
            value={libelle}
            margin="normal"
            collectionStore={typenoteStore}
            name='libelle'
            onChangeHandler={onChangeHandler}
            fullWidth
          />
        </Grid>
        <Grid item xs={4}>
          <CheckboxInput
            id="need_assign"
            label="Besoin assignation"
            value={need_assign}
            margin="normal"
            collectionStore={typenoteStore}
            name='need_assign'
            onChangeHandler={onChangeHandler}
            fullWidth
          />
        </Grid>
      </Grid>
    );
  }

  render() {
    const {classes} = this.props;
    return (
      <Grid container className={classes.container}>
        <CollectionCrud
          collectionName={'type_notes'}
          showBtnEdit={true}
          showBtnAdd={true}
          showBtnDelete={true}
          rights={{
            create: 'admin-cud-parametres',
            edit: 'admin-cud-parametres',
            delete: 'admin-cud-parametres'
          }}
          deleteModalTitle={this.getDeleteModalTitle}
          deleteModalContent={this.getDeleteModalContent}
          createUpdateModalTitle={this.getCreateUpdateModalTitle}
          createUpdateModalContent={this.getCreateUpdateModalContent.bind(this)}
          datatableConfig={{
            showPagination: true,
            showSearch: true,
            defaultSort: "libelle",
            sortType: 'desc',
            rowsPerPageOptions: [10, 25, 50],
          }}
          defaultValues={{
            need_assign: false
          }}
          cellsConfig={[
            {
              datakey: "libelle",
              title: "Libelle",
              sortable: true,
              searchable: true,
            },
            {
              datakey: "need_assign",
              title: "Besoin assignation",
              sortable: true,
              searchable: true,
              format: (obj,key) => {
                return obj[key] ? 'Oui' : 'Non';
              }
            }
        ]}
        />
      </Grid>
    );
  }
}

TypeNote = withStyles(TypeNoteCss)(TypeNote);

export default TypeNote
