export default theme => ({
  ico: {
    verticalAlign: 'middle',
    marginRight: '0.4em'
  },
  crudTitle: {
    color: theme.palette.primary.main,
    marginBottom: '1em'
  },
  formControl: {
    width: 'calc(100% - 1em)',
  },
  rowView: {
    backgroundColor: '#1874ff4a'
  }
});
