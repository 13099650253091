export default (theme) => {
  return {
    section: {
      marginBottom: "3em",
    },
    sectionTitle: {
      marginBottom: "1em",
      color: theme.palette.primary.main,
    },
    button: {
      float: 'right',
      padding: 12
    },
    dragrable: {
      maxWidth: '90%!important',
      margin: '0 auto',
      border: 'dashed 2px ' + theme.palette.primary.main+'!important',
    },
  };
};
