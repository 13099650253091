/**
 * Permet de joindre des classes dynamique pour en créer une chaîne de chars
 * @param  {[type]} classes [description]
 * @return {[type]}         [description]
 */
export function classesNames(classes) {
  return Object.keys(classes).filter(c => classes[c]).join(' ')
}

/**
 * Défini si on a les droits pour une route
 * @param  {[type]}  route [description]
 * @param  {[type]}  user  [description]
 * @return {Boolean}       [description]
 */
export function hasRightsForRoute(route, user) {
  if (!route) return false
  else if (route.right) return hasRights(route.right, user)
  else if (route.rights) return hasRights(route.rights, user)
}

/**
 * Défini si on a les droits nécessaires
 * @param  {[type]}  rights [description]
 * @param  {[type]}  user   [description]
 * @return {Boolean}        [description]
 */
export function hasRights(rights, user) {
  if (!user) return false
  else if (Array.isArray(rights)) return rights.some(r_slug => user.rights.indexOf(r_slug) > -1)
  else return user.rights.indexOf(rights) > -1
}
