import {
  Button,
  Grid,
  MenuItem,
  TextField,
  Typography,
  withStyles,
} from "@material-ui/core";
import React, { Component } from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import FacturationCss from "./css/FacturationCss";
import FacturationAnnexe from "../FactureAnnexe";
import { getFromLocalStorage, storeToLocalStorage } from "../../tools/Tools";
import { buildRoute } from "../../../router/Tools";
import FilterDateSelecteur from "../../common/Components/FilterDateSelecteur";
import TextInput from "../../common/Components/TextInput";
import { Close, Delete, Send, Warning } from "@material-ui/icons";
import { sendToMeg } from "../../../reducers/collectionsReducer";
import CheckboxInput from "../../common/Components/CheckboxInput";


const LOCAL_STORAGE_KEY = "facturation_annexe_filtres"

class FacturationAnnexeList extends Component {
  state = {
    type: "all",
    type_date_filter:["all"],
    etat_filter: ["all"],
    date_filter: null,
    selectedAFacturesMeg: [],
    megAction: null,
  };

  formatDate(string) {
    if (!string) {
      return "";
    }
    let date = new Date(string);
    let options = {
      day: "numeric",
      month: "long",
      year: "numeric",
    };
    return date.toLocaleDateString("fr-FR", options);
  }


  componentDidMount()
  {
    const filter_localStorage =  getFromLocalStorage(LOCAL_STORAGE_KEY)
    if(filter_localStorage){
      this.setState(filter_localStorage);
    }
    if(!filter_localStorage && this.props.etatsStore.list){
       this.props.etatsStore.list.map((etat) => {
         if(etat.order_number === 8){
          this.setState({etat_filter: [etat.uuid]});
         }
         return null;
        }
       )
    }
  }

  getMegFacturessSelected() {
    const { selectedAFacturesMeg, megAction } = this.state;
    const { classes, affairesStore } = this.props;
    if(selectedAFacturesMeg.length === 0) return null;

    return (
      <Grid container>
        <Grid item xs={12} style={{marginBottom:'1rem'}}>
          <div className={classes.megAffairesSelected}>
            <div className={classes.megAffairesSelectedTitle}>Meg Factures sélectionnées : </div>
            <div className={classes.megAffairesSelectedList}>
              {selectedAFacturesMeg.map(facture => {
                return (
                  <div className={classes.megAffairesSelectedItem} key={facture.uuid}>
                    <span className={classes.megAffairesSelectedItemRef}>{facture.affaire.reference_interne} - {facture.entreprise.nom} ({facture.montant}€)</span>
                    <span className={classes.megAffairesSelectedItemRemove} onClick={this.onChangeSelectMeg.bind(this, facture)}>
                      <Delete />
                    </span>
                  </div>
                )
              })}
            </div>
          </div>
        </Grid>
        <Grid item xs={4} style={{marginBottom:'1rem'}}>
          <TextInput
            label="Action Meg"
            value={megAction}
            onChangeHandler={(name, e) => {
              this.setState({megAction: e.target.value})
            }}
            collectionStore={affairesStore}
            select
            fullWidth
          >
            {this.getMegActionOptions()}
          </TextInput>
        </Grid>
        <Grid item xs={4} style={{marginBottom:'1rem'}}>
          <Button
            variant="contained"
            color="primary"
            onClick={this.sendMegAffaires.bind(this)}
          >
            <Send /> Envoyer
          </Button>
        </Grid>
      </Grid>
    )
  }

  getMegActionOptions(){
    return [
      <MenuItem key="brouillon" value="brouillon">Brouillon</MenuItem>,
      <MenuItem key="valider" value="valider">Valider</MenuItem>,
      <MenuItem key="envoyer" value="envoyer">Envoyer</MenuItem>,
    ]
  }

  sendMegAffaires(){
    const { dispatch } = this.props;
    sendToMeg(dispatch,{
      factures_annexes_uuid: this.state.selectedAFacturesMeg.map(facture => facture.uuid),
      action: this.state.megAction,
      meg_with_facture_annexe: this.state.meg_with_facture_annexe,
    },
    () => {
      this.setState({
        selectedAFacturesMeg: [],
        megAction: null,
        meg_with_facture_annexe: false,
      })
    })
  }

  getdetailAffaireBtn(obj) {
    return (<>
      <Button
      key="detail"
      aria-label="Afficher"
      color="primary"
      onClick={() => {
        this.props.history.push(
          buildRoute("/affaires/:uuid", { uuid: obj['affaire_uuid']})
        );
      }}
      title="Afficher"
    >
      {obj["affaire"]["reference_interne"]}
    </Button>
    </>)
  }

  isFactureSelectedMeg(facture) {
    const { selectedAFacturesMeg } = this.state;
    let index = -1;
    selectedAFacturesMeg.forEach((facture_selected, i) => {
      if (facture_selected.uuid === facture.uuid) {
        index = i;
      }
    })
    return index !== -1;
  }

  onChangeSelectMeg(facture) {
    const { selectedAFacturesMeg } = this.state;
    let newselectedAFacturesMeg = [];
    let index = -1;
    selectedAFacturesMeg.forEach((facture_selected, i) => {
      if (facture_selected.uuid === facture.uuid) {
        index = i;
      }
    })
    if (index === -1) {
      newselectedAFacturesMeg = [...selectedAFacturesMeg, facture];
    } else {
      selectedAFacturesMeg.splice(index, 1);
      newselectedAFacturesMeg = [...selectedAFacturesMeg];
    }
    this.setState({selectedAFacturesMeg: newselectedAFacturesMeg})
  }


  getStatusEntreprise(facture) {
    const { classes } = this.props;

    if(!facture.meg_facturation_auto){
      return (
        <span title="Facturation Automatique MEG désactivé"><Close className={classes.warning_code_client_meg} /></span>
      )
    }
    else if(!facture.code_client_meg){
      return (
        <span title="Code client MEG Absent"><Warning className={classes.warning_code_client_meg} /></span>
      )
    }else{
      return null;
    }

  }


  getCellsConfig() {
    return [
      {
        datakey: "fake_osef",
        title: "",
        sortable: false,
        searchable: false,
        format: (facture) => {
          return (
            <CheckboxInput
              id={"meg" + facture.uuid}
              value={this.isFactureSelectedMeg(facture)}
              margin="normal"
              disabled={!facture.can_send_to_meg}
              onChangeHandler={this.onChangeSelectMeg.bind(this, facture)}
            />
          )
        },
      },
      {
        datakey: "affaire.reference_interne",
        title: "Référence",
        sortable: true,
        searchable: true,
        useDataKeyOnSearch: true,
        format: (obj, key) => {
        return  !obj["affaire"] ? null : this.getdetailAffaireBtn(obj)
      },
      },
      {
        datakey: "affaire.date_enlevement",
        title: "Enlèvement",
        sortable: true,
        searchable: true,
        isDate: true,
        format: (obj, key) =>
          !obj["affaire"] ? null :this.formatDate(obj["affaire"]["date_enlevement"]),
      },
      {
        datakey: "affaire.etat_nom",
        title: "Statut",
        sortable: true,
        searchable: true,
      },
      {
        datakey: "type",
        title: "Type",
        sortable: true,
        searchable: true,
      },
      {
        datakey: "entreprise.nom",
        title: "Entreprise",
        sortable: true,
        searchable: true,
        useDataKeyOnSearch: true,
        format: (facture) => {
          return (<div style={{position:'relative'}}>
            { this.getStatusEntreprise(facture) }
            <span>{facture.entreprise.nom}</span>
          </div>);
        },
      },

      {
        datakey: "raison.libelle",
        title: "Raison",
        sortable: true,
        searchable: true,
      },
      {
        datakey: "reference_affaire",
        title: "Référence",
        sortable: true,
        searchable: true,
      },
      {
        datakey: "commentaire_affaire",
        title: "Comm Fact Affaire",
        sortable: true,
        searchable: true,
      },
      {
        datakey: "commentaire_facturation",
        title: "Commentaire Facturation",
        sortable: true,
        searchable: true,
      },
      {
        datakey: "commentaire_interne",
        title: "Commentaire Interne",
        sortable: true,
        searchable: true,
      },
      {
        datakey: "quantite",
        title: "Quantité",
        sortable: true,
        searchable: true,
      },
      {
        datakey: "prix_unitaire",
        title: "Prix unitaire",
        sortable: true,
        searchable: true,
        format: (facture) => {
          const inf = new Intl.NumberFormat("fr-FR", {
            minimumFractionDigits: 2,
            maximumFractionDigits: 2,
          });
          return inf.format(facture.prix_unitaire) + " €";
        },
      },
      {
        datakey: "montant",
        title: "Montant HT",
        sortable: true,
        searchable: true,
        useDataKeyOnSearch: true,
        format: (facture) => {
          const inf = new Intl.NumberFormat("fr-FR", {
            minimumFractionDigits: 2,
            maximumFractionDigits: 2,
          });
          return inf.format(facture.montant) + " €";
        },
      },
      {
        datakey: "meg_status",
        title: "MEG",
        sortable: true,
        searchable: false,
        format: (facture) => {
          if(facture.meg_status === "brouillon"){
            return (
              <span title="Statut Brouillon">B</span>
            )
          }
          else if(facture.meg_status === "validée"){
            return (
              <span title="Statut Validé">V</span>
            )
          }
          else if(facture.meg_status === "envoyée"){
            return (
              <span title="Statut Envoyé">E</span>
            )
          }
          return null;
        },
      },
    ];
  }

  onChangeHandlerFilters(name, e) {
    let values = e.target.value;
    /**
     * Gestion de la value all uniquement pour les filtre multiple (ici Etat)
     * si on clique dessus on vide la liste et ne garde que all
     * si on clique sur un autre element on enleve all
     * si on vide la liste on rajoute all
     */
    if (name === 'etat_filter' && values.length > 0) {
      if (this.state[name].indexOf("all") !== -1) {
        values = values.filter((value) => value !== "all");
      } else if (values.indexOf("all") !== -1) {
        values = ["all"];
      }
    }
    const filters = { [name]: values };
    const filter_localStorage =  getFromLocalStorage(LOCAL_STORAGE_KEY)
    const new_filters = {...filter_localStorage, ...filters}
    storeToLocalStorage(LOCAL_STORAGE_KEY, new_filters, 60 * 12);
    this.setState(filters);
  }

  getEtatsFilter() {
    const { etatsStore, classes } = this.props;
    const { etat_filter } = this.state;
    if (!etatsStore.list ) return null;
    const etats = [
      {
        uuid: "all",
        nom: "Tous",
      },
      ...etatsStore.list,
    ];
    const options = etats.map((etat) => {
      return (
        <MenuItem value={etat.uuid} key={etat.uuid}>
          {etat.nom}
        </MenuItem>
      );
    });

    return (
      <TextField
        id="etat_filter_affaire"
        label="État"
        margin="normal"
        onChange={this.onChangeHandlerFilters.bind(this, "etat_filter")}
        className={classes.selectContainer}
        SelectProps={{
          multiple: true,
          value: etat_filter,
        }}
        select
      >
        {options}
      </TextField>
    );
  }

  getFilters() {
    const { classes } = this.props;

    const options = [
      { value: "all", label: "Toutes" },
      { value: "sortante", label: "Sortante" },
      { value: "entrante", label: "Entrante" },
    ].map((type) => {
      return (
        <MenuItem value={type.value} key={type.value}>
          {type.label}
        </MenuItem>
      );
    });

    return (
      <Grid container>
        <Grid item xs={12}>
          <Typography
            key="typo"
            variant="button"
            gutterBottom
            className={classes.crudTitle}
          >
            Filtres
          </Typography>
        </Grid>
        <Grid item xs={3}>
          <TextField
            id="type_affaire_filter"
            label="Type Facture"
            value={this.state.type}
            margin="normal"
            onChange={this.onChangeHandlerFilters.bind(this, "type")}
            className={classes.selectContainer}
            select
          >
            {options}
          </TextField>
        </Grid>
        <Grid item xs={3}>
            {this.getEtatsFilter()}
        </Grid>
        <Grid item xs={3}>
        <FilterDateSelecteur onChangeHandlerFilters={this.onChangeHandlerFilters.bind(this)} localStorageKey={LOCAL_STORAGE_KEY}   date_filter={this.state.date_filter}
          type_date_filter={this.state.type_date_filter}/>
          </Grid>
      </Grid>
    );
  }

  render() {
    return (
      <>
        {this.getFilters()}
        {this.getMegFacturessSelected()}
        <FacturationAnnexe
          showActionsBtn={false}
          cellsConfig={this.getCellsConfig()}
          typeFacture={this.state.type}
          onlyUnPaid={true}
          rowsPerPage={50}
          entreprise_uuid={this.props.entreprise_uuid}
          date_filter={this.state.date_filter}
          type_date_filter={this.state.type_date_filter}
          etat_filter={this.state.etat_filter}
          cancelUpdateCheck={true}
        />
      </>
    );
  }
}
FacturationAnnexeList = withStyles(FacturationCss)(FacturationAnnexeList);
FacturationAnnexeList = withRouter(FacturationAnnexeList);
FacturationAnnexeList = connect((store) => {
  return {
    bordereauAchatStore: store.collections.bordereau_achat,
    etatsStore: store.collections.etats,
  };
})(FacturationAnnexeList);

export default FacturationAnnexeList;
