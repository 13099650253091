import React, {Component} from 'react';
import {Button, IconButton, Slide, Snackbar} from '@material-ui/core';
import {connect} from 'react-redux';
import CloseIcon from '@material-ui/icons/Close';
import {clearNotification} from '../../../reducers/notificationReducer';

const DefaultTransition = React.forwardRef((props, ref) => (
  <Slide direction='right' {...props} ref={ref} />
))

class Notification extends Component {
  /**
   * State de base
   */
  state = {
    snackbar_open: false,
    snackbar_vertical: 'bottom',
    snackbar_horizontal: 'left',
    snackbar_autohide: 6000,
    message: null
  };

  /**
   * Retourne le nouveau state en fonction des nextProps
   * @param  {[type]} nextProps [description]
   * @param  {[type]} prevState [description]
   * @return {[type]}           [description]
   */
  static getDerivedStateFromProps(nextProps, prevState) {
    if (!!nextProps.notifications && nextProps.notifications.message !== prevState.message) {
      return {
        ...prevState,
        message: nextProps.notifications.message,
        snackbar_open: !!nextProps.notifications.message
      };
    }
    return null;
  }

  /**
   * Handler sur la fermeture de la snackbar
   */
  handleCloseSnackbar() {
    clearNotification(this.props.dispatch)
  }

  /**
   * Permet de retourner le bouton d'annulation sur la snackbar
   */
  getUndoSnackBar() {
    if (!!this.props.notifications.cancelCallback) {
      return (
        <Button key="undo" color="secondary" size="small" onClick={this.props.notifications.cancelCallback}>
          Annuler
        </Button>)
    }
    return null
  }

  /**
   * Fonction de rendu final du composant
   */
  render() {
    const {snackbar_vertical, snackbar_horizontal, snackbar_open, snackbar_autohide} = this.state;

    return (
      <Snackbar
        anchorOrigin={{vertical: snackbar_vertical, horizontal: snackbar_horizontal}}
        open={snackbar_open}
        autoHideDuration={snackbar_autohide}
        onClose={this.handleCloseSnackbar.bind(this)}
        ContentProps={{
          'aria-describedby': 'message-id',
          style: {
            backgroundColor: this.props.notifications.bgColor
          }
        }}
        TransitionComponent={DefaultTransition}
        message={<span id="message-id">{this.props.notifications.message}</span>}
        action={[
          this.getUndoSnackBar(),
          <IconButton
            key="close"
            aria-label="Close"
            color="inherit"
            onClick={this.handleCloseSnackbar.bind(this)}
          >
            <CloseIcon/>
          </IconButton>,
        ]}
      />

    );
  }
}


Notification = connect((store) => {
  return {
    notifications: store.notifications
  }
})(Notification);

export default Notification
