import {Button, Dialog, DialogActions, DialogContent, DialogTitle, withStyles} from '@material-ui/core'
import {BarChart} from '@material-ui/icons'
import PropTypes from 'prop-types'
import React, {Component} from 'react'
import Chart from "react-apexcharts"
import StockCss from './css/StockCss'

class ProduitTransformeEtat extends Component {
  state = {
    options: {
      colors: [
        '#2E93fA',
        '#66DA26',
        '#546E7A',
        '#E91E63',
        '#FF9800',
        '#df95e4',
        '#4a5a64',
        '#7cd8c1',
        '#bd3123',
        '#47a39b',
        '#19609b',
        '#7741f6',
        '#b7bd28',
        '#0c1e34',
        '#971c2a',
      ],
      legend: {
        position: 'right',
      },
    },
  }

  componentDidMount() {
    if (this.props.data) this.setState({
      options: {
        ...this.state.options,
        ...this.props.data.options,
      },
    })
  }

  getChart() {
    const {classes, data} = this.props

    if (data && this.state.options) return <Chart
      className={classes.chart}
      options={this.state.options}
      series={data.series}
      type="bar"
      height="500"
      width="1200"
    />
  }

  render() {
    return <Dialog
      open={this.props.open}
      onClose={this.props.onClose.bind(this)}
      aria-labelledby="form-dialog-title"
      maxWidth={"lg"}
    >
      <DialogTitle id="form-dialog-title">
        <div><BarChart/> <span>État des produits transformés</span></div>
      </DialogTitle>
      <DialogContent>
        {this.getChart()}
      </DialogContent>
      <DialogActions>
        <Button onClick={this.props.onClose.bind(this)} color="primary">
          Fermer
        </Button>
      </DialogActions>
    </Dialog>
  }
}

ProduitTransformeEtat = withStyles(StockCss)(ProduitTransformeEtat)

ProduitTransformeEtat.propTypes = {
  open: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  data: PropTypes.object.isRequired,
}

export default ProduitTransformeEtat
